export const upgradeWallet = (baseUrl: string): string => `${baseUrl}/users/v1/wallet/upgrade`;

export const upgradeWalletInit = (baseUrl: string): string => `${upgradeWallet(baseUrl)}/init`;

export const upgradeWalletPersonalData = (flowId: string, baseUrl: string): string =>
  `${upgradeWallet(baseUrl)}/${flowId}/personalData`;

export const upgradeWalletAddress = (flowId: string, baseUrl: string): string =>
  `${upgradeWallet(baseUrl)}/${flowId}/address`;

export const upgradeWalletSecurityQuestions = (flowId: string, baseUrl: string): string =>
  `${upgradeWallet(baseUrl)}/${flowId}/securityQuestions`;

export const upgradeWalletIdPhoto = (flowId: string, baseUrl: string): string =>
  `${upgradeWallet(baseUrl)}/${flowId}/idPhoto`;
