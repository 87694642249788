export enum EVENT {
  COMMON_PAGE_LOAD = 'commonpageload',
  LINK_CLICK = 'linkclick',
  ERROR = 'errortracking',
  FILE_DOWNLOAD = 'filedownload',

  WALLET_PAGE = 'allwalletpage',
  WALLET_SHARE_SUCCESS = 'sharesuccess',
  WALLET_REFUND_SUCCESS = 'refundsuccess',
  WALLET_PAY_START = 'paystart',
  WALLET_PAY_AUTHENTICATE = 'authenticatetransaction',
  WALLET_PAY_SUCCESS = 'paysuccess',
  WALLET_REQUEST_MONEY_START = 'requestmoneystart',
  WALLET_REQUEST_MONEY_SUCCESS = 'requestmoneysuccess',
  WALLET_RECEIVE_MONEY_SUCCESS = 'receivemoneysuccess',
  WALLET_ADD_MONEY_START = 'addmoneystart',
  WALLET_ADD_MONEY_SUCCESS = 'addmoneysuccess',

  BANKING_PERSONAL_LOAN_START = 'personalloanstart',
  BANKING_PERSONAL_LOAN_FINANCIAL_INFO = 'loanfinancialinfo',
  BANKING_PERSONAL_LOAN_TAX_OBLIGATIONS = 'personalloantaxobligations',
  BANKING_PERSONAL_LOAN_BANK_DETAILS = 'personalloanbankdetails',
  BANKING_PERSONAL_LOAN_CONSENT = 'personalloanconsent',
  BANKING_PERSONAL_LOAN_OFFER = 'personalloanoffer',
  BANKING_PERSONAL_LOAN_DETAIL = 'personalloandetail',
  BANKING_PERSONAL_LOAN_DECLARATION = 'personalloandeclaration',
  BANKING_PERSONAL_LOAN_SUCCESS = 'personalloansuccess',
  BANKING_PERSONAL_LOAN_DECLINE = 'personalloandecline',
  BANKING_PERSONAL_LOAN_RESULT_EXISTING = 'personalloanexisting',

  SHOPPING_CART_ADD = 'scadd',
  SHOPPING_CART_REMOVE = 'scremove',
  CART_CHECKOUT_CLICK = 'checkoutbutton',
  CART_PAYMENT = 'cartpayment',
  ORDER_SERVICE = 'orderaservices',

  FORM_INITIATED = 'forminitiated',
  FORM_COMPLETE = 'formcomplete',

  ONBOARDING_STEP_UP = 'onboardingstepup',
  ONBOARDING_SIGNUP = 'onboardingsignup',
  ONBOARDING_SIGNUP_CONFIRMATION = 'onboardingsignupconfirmation',
  ONBOARDING_END_STEP = 'onboardingendstep',
  ONBOARDING_OTP = 'onboardingotp',
  ONBOARDING_SET_LOGIN = 'onboardingsetlogin',
  ONBOARDING_FIRST_NAME = 'onboardingfirstname',
  ONBOARDING_WALLET_SUCCESS = 'onboardingwalletsuccess',
  ONBOARDING_WALLET_IN_PROGRESS = 'onboardingwalletinprogress',
  ONBOARDING_PERSONAL_DATA = 'onboardingpersonaldata',
  ONBOARDING_RESIDENTIAL_ADDRESS = 'onboardingresidential',
  ONBOARDING_SOCIAL_START = 'onboardingsocialstart',
  ONBOARDING_BASIC = 'onboardingbasic',
  ONBOARDING_OUTSTANDING_TCS = 'onboardingoutstandingtcs',
  ONBOARDING_ID_NUMBER = 'idnumber',
  POST_ONBOARDING_BUSINESS_DETAILS = 'postonboardingbusinessdetails',
  POST_ONBOARDING_BUSINESS_ADDRESS = 'postonboardingbusinessaddress',
  POST_ONBOARDING_TRADING_NAME = 'postonboardingtradingname',
  POST_ONBOARDING_UPDATE_CATEGORY = 'postonboardingupdatecategory',

  LOGIN_SOCIAL = 'loginsocial',
  LOGIN_USERNAME = 'loginusername',
  LOGIN_PASSWORD = 'loginpassword',
  LOGIN_OTP = 'loginotp',

  PROFILE_PAGE = 'customerprofile',
  PROFILE_EVENT = 'cusprofileevent',
  PROFILE_SETTINGS = 'customerprofilesetting',
  PROFILE_OTHER_EVENT = 'cusprofileotherevent',

  GROCERIES = 'shopgroceries',

  RATE_US = 'rateus',

  // Merchant events
  REJECT_QUOTE = 'rejectquote',
}

export interface IEventOptions {
  viewName: string;
  siteSection?: string;
  subSiteSection?: SUBSECTION;
  additionalObject?: any;
}

export enum TARGET_FUNCTION {
  TRIGGER_VIEW = 'triggerView',
  GET_OFFER = 'getOffer',
  APPLY_OFFER = 'applyOffer',
}

export enum SUBSECTION {
  // MERCHANT
  MANAGE_QUOTES = 'Manage Quotes',
  QUOTES_REQUESTED = 'Quotes Requested',
  QUOTES_RECEIVED = 'Quotes Received',
  QUOTES_REJECTED = 'Quotes Rejected',
}
