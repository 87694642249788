export class UrlRouter {
  public static urls = {
    landing: '',
    b2c: {
      landing: '/b2c',
      digitalMall: '/b2c/digital-mall',
      onlineShops: '/b2c/online-shops',
      takeaways: '/b2c/takeaways',
      liquors: '/b2c/liquors',
      homeServices: '/b2c/home-services',
    },
    b2b: {
      landing: '/b2bselling',
      deliveryCosts: '/b2bselling/delivery-costs',
      notDeliveredItems: '/b2bselling/not-delivered-items',
    },
    businessBuying: {
      landing: '/business-buying',
    },
    support: '/support',
    donations: '/donations',
    capital: '/working-capital',
  };
}
