import { Injectable } from '@angular/core';
import { BrowserStorageService } from '../browser-storage/browser-storage.service';
import get from 'lodash-es/get';

interface IGuestAuthToken {
  customerToken: string;
  tokenExpirationDate: string;
}

@Injectable()
export class GuestAuthorizationService {
  private customerKey = 'customerToken';

  constructor(private webStorage: BrowserStorageService) {}

  isGuestAuthorized = (): boolean => {
    if (this.isCustomerTokenExpired()) this.removeCustomerToken();
    return !!this.getCustomerToken() && !!this.getToken() && !this.isCustomerTokenExpired();
  };

  getCustomerToken = (): IGuestAuthToken => this.webStorage.get(this.customerKey);

  getToken = (): string => {
    const accessToken = this.getCustomerToken();
    if (this.isCustomerTokenExpired()) {
      return null;
    }
    if (accessToken && accessToken.customerToken) {
      return accessToken.customerToken;
    }
    return get(this.getCustomerToken(), 'customerToken');
  };

  removeCustomerToken = (): void => {
    this.webStorage.remove(this.customerKey);
  };

  setCustomerToken = (customerToken: IGuestAuthToken): void => {
    this.webStorage.set(this.customerKey, customerToken);
  };

  isCustomerTokenExpired = (): boolean => {
    const expirationDate = this.getCustomerToken()?.tokenExpirationDate;
    if (!expirationDate) return true;
    return new Date(expirationDate) < new Date();
  };
}
