import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AccountLoginType, IZenAuthOnboardingAccountExistInfo } from '@wakanda/zenauth';
import { IStatusTranslate } from '@wakanda/zentity-translate-store';
import { AdobeService } from '@wakanda/adobe-analytics';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'wakanda-account-exists-modal',
  templateUrl: './account-exists-modal.component.html',
  styleUrls: ['./account-exists-modal.component.scss'],
})
export class AccountExistsModalComponent {
  @Input() accountInfo: IZenAuthOnboardingAccountExistInfo;
  @Input() showGoToHomepage?: boolean = false;
  @Output() onButtonClick = new EventEmitter();
  @Output() onContactUs = new EventEmitter();
  @Output() onGoToHomepage? = new EventEmitter();

  adobeService: AdobeService;
  ModalType = ModalType;

  typeTranslate: IStatusTranslate = {
    [AccountLoginType.AVO_LOGIN]: 'onboarding.account_exists.avo_login',
    [AccountLoginType.APPLE_ID]: 'onboarding.account_exists.apple_id',
    [AccountLoginType.FB]: 'onboarding.account_exists.facebook',
    [AccountLoginType.GOOGLE]: 'onboarding.account_exists.google',
    [AccountLoginType.MHL]: 'onboarding.account_exists.mhl',
    [AccountLoginType.MONEY_APP]: 'onboarding.account_exists.money_app',
    [AccountLoginType.MONEY_PWA]: 'onboarding.account_exists.money_pwa',
    [AccountLoginType.GREENBACKS]: 'onboarding.account_exists.greenbacks',
    [AccountLoginType.SIMPLYBIZ]: 'onboarding.account_exists.simplybiz',
    [AccountLoginType.NPW]: 'onboarding.account_exists.npw',
  };

  constructor(injector: Injector) {
    this.adobeService = injector.get(AdobeService);
  }

  onContact = (): void => {
    this.adobeService.executeAdobeClickEvent('onboarding:accountExists contactUs');
    this.onContactUs.emit();
  };

  onLogin = (): void => {
    this.adobeService.executeAdobeClickEvent('onboarding:accountExists login');
    this.onButtonClick.emit();
  };
}
