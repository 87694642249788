<div class="questions-container">
  <wakanda-avo-business-faq-panel
    *ngFor="let question of questions; let last = last"
    [title]="question.question | translate"
    [isSmall]="true"
    [last]="last"
  >
    <ng-container *ngIf="question?.answers">
      <p *ngFor="let answer of question?.answers" class="light text-align-left faq-answer" zen-translate>
        {{ answer }}
      </p>
    </ng-container>
    <p *ngIf="question?.answer" class="light text-align-left" zen-translate>{{ question.answer }}</p>
  </wakanda-avo-business-faq-panel>
</div>
