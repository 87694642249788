import { Constants } from './constants';

export class SaidUtil {
  public static saidSplit(value: string, maxLength: number): string {
    let pattern = Constants.patterns.splitSaidLessEleven;
    if (value.length < 11) {
      return value.replace(pattern, '$1 $2');
    } else {
      value = value.substring(0, maxLength);
      pattern = Constants.patterns.splitSaidMoreEleven;
      return value.replace(pattern, '$1 $2 $3');
    }
  }
}
