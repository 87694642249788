import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApplicationFacade, AuthenticationService, IAuthenticators } from '@wakanda/wakanda-core';
import {
  IZenAuthNextStepTransactionSigningResponse,
  IZenAuthTransactionSigningAuthorizationHeaders,
  IZenAuthTransactionSigningAuthorizeRequest,
  NextStepTransactionSigning,
  ZenAuthFacade,
} from '@wakanda/zenauth';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-authorization-dialog',
  template: `
    <ng-container *ngIf="showDialog">
      <ng-container *ngIf="nextStep?.method === signingMethods.OTP">
        <ui-authorization-dialog-otp
          [nextStep]="nextStep"
          [transactionAuthId]="transactionAuthId"
          (onCancel)="onCancel($event)"
          (onConfirm)="onAuthorize($event, false)"
          (onSucceed)="onSucceedAuthorization($event)"
        ></ui-authorization-dialog-otp>
      </ng-container>
      <ng-container *ngIf="nextStep?.method === signingMethods.OUTSTANDING_TCS">
        <ui-authorization-dialog-tcs
          [nextStep]="nextStep"
          [transactionAuthId]="transactionAuthId"
          (onCancel)="onCancel($event)"
          (onConfirm)="onAuthorize($event, false)"
          (onSucceed)="onSucceedAuthorization($event)"
        ></ui-authorization-dialog-tcs>
      </ng-container>
      <ng-container *ngIf="nextStep?.method === signingMethods.APPROVEIT">
        <ui-authorization-dialog-approveit
          [nextStep]="nextStep"
          [transactionAuthId]="transactionAuthId"
          (onCancel)="onCancel($event)"
          (onConfirm)="onAuthorize($event, true)"
          (onSucceed)="onSucceedAuthorization($event)"
        ></ui-authorization-dialog-approveit>
      </ng-container>
    </ng-container>
  `,
})
export class AuthorizationDialogComponent implements OnInit, OnDestroy {
  @Input() merchant: boolean;
  @Input() taskId: string;
  @Input() transactionAuthId: string;
  @Output() onClose = new EventEmitter();
  @Output() onSwimSwapDetected = new EventEmitter();
  //
  signingMethods = NextStepTransactionSigning;
  nextStep: IZenAuthNextStepTransactionSigningResponse;
  showDialog = true;
  //
  subscriptions = new Subscription();

  constructor(
    private facade: ZenAuthFacade,
    private applicationFacade: ApplicationFacade,
    private authenticationService: AuthenticationService,
  ) {
    this.subscriptions.add(this.facade.getTransactionNextStep$.subscribe(nextStep => (this.nextStep = nextStep)));
  }

  onCancel = (): void => {
    this.facade.transactionSigningCancel(this.transactionAuthId, () => this.onClose.emit());
  };

  onAuthorize(request: IZenAuthTransactionSigningAuthorizeRequest, silently: boolean): void {
    this.facade.transactionSigningAuthorize(request, silently);
  }

  onSucceedAuthorization = (authHeaders: IZenAuthTransactionSigningAuthorizationHeaders): void => {
    this.showDialog = false;
    this.subscriptions.add(
      this.applicationFacade.getErrorById$(this.taskId).subscribe(task => {
        if (!task) return;
        if (!!authHeaders && !!authHeaders.signingToken && !!authHeaders.transactionAuthId) {
          const authenticators: IAuthenticators = {
            xAuthTXId: authHeaders.transactionAuthId,
            xAuthTXToken: authHeaders.signingToken,
          };
          this.facade.saveTransactionSigningAuthenticators(authenticators);
        }
        this.applicationFacade.repeatTask(task);
      }),
    );
  };

  ngOnInit(): void {
    this.facade.transactionSigningStart(this.transactionAuthId, this.onSucceedAuthorization);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
