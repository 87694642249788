import { Injectable } from '@angular/core';

declare let FB: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookLoginService {
  initOptions: any = {
    scope: 'email,public_profile',
    locale: 'en_US',
    fields: 'name,email,picture,first_name,last_name',
    version: 'v8.0',
  };

  protected init(appId: string): Promise<void> {
    return new Promise(resolve =>
      this.loadScript('FACEBOOK', 'https://connect.facebook.net/en_US/sdk.js', () => {
        this.scriptOnLoaded(appId).then(() => {
          resolve();
        });
      }),
    );
  }

  protected scriptOnLoaded(appId: string): Promise<void> {
    return new Promise(resolve => {
      FB.init({
        appId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v8.0',
      });
      resolve();
    });
  }

  protected loadScript(id: string, src: string, onload: any, parentElement = null): void {
    // get document if platform is only browser
    if (typeof document !== 'undefined' && !document.getElementById(id)) {
      const signInJS = document.createElement('script');

      signInJS.async = true;
      signInJS.src = src;
      signInJS.onload = onload;

      if (!parentElement) {
        parentElement = document.head;
      }

      parentElement.appendChild(signInJS);
    }
  }

  signIn(appId: string): Promise<string> {
    return this.init(appId)
      .then(this.getStatus)
      .then(token => {
        if (token) {
          return Promise.resolve(token);
        } else {
          return this.login();
        }
      });
  }

  protected login(): Promise<any> {
    return new Promise((resolve, reject) => {
      FB.login(response => {
        if (response.authResponse) {
          resolve(response.authResponse.accessToken);
        } else {
          reject('User cancelled login or did not fully authorize.');
        }
      });
    });
  }

  getStatus(): Promise<any> {
    return new Promise(resolve => {
      FB.getLoginStatus(response => {
        if (response.authResponse && response.status === 'connected') {
          resolve(response.authResponse.accessToken);
        } else {
          resolve(null);
        }
      });
    });
  }

  logout(): Promise<any> {
    return new Promise(resolve => {
      FB.logout(response => {
        resolve(response);
      });
    });
  }
}
