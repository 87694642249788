import { NgModule } from '@angular/core';
import { TranslateService } from './services/translate.service';
import { HttpClientModule } from '@angular/common/http';
import { TranslatePipe } from './pipes/translate.pipe';
import { TranslateVariablesPipe } from './pipes/translate-variables.pipe';

@NgModule({
  declarations: [TranslatePipe, TranslateVariablesPipe],
  providers: [TranslatePipe, TranslateService],
  exports: [TranslatePipe, TranslateVariablesPipe],
  imports: [HttpClientModule],
})
export class AvoTranslateModule {}
