import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@wakanda/environment-merchant/environment';
import { IApplicationMaintenanceResponse } from '../../models/application.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(private httpClient: HttpClient) {}

  checkMaintenance(): Observable<IApplicationMaintenanceResponse> {
    return this.httpClient.get<IApplicationMaintenanceResponse>(environment.maintenanceUrl);
  }
}
