export enum AddressType {
  CustomerLocation = 'CUSTOMER_LOCATION',
  Search = 'SEARCH',
  StaticUsingMap = 'SEARCH_USING_MAP',
  StaticCurrentLocation = 'STATIC_CURRENT_LOCATION',
  StaticManage = 'STATIC_MANAGE',
  SelectAll = 'SELECT_ALL',
}

export interface Search {
  query: string;
  result: GoogleResult[];
}

export interface GoogleResult {
  description: string;
  id: string;
  matched_substrings: Matchedsubstring[];
  place_id: string;
  reference: string;
  structured_formatting: Structuredformatting;
  terms: Term[];
  types: string[];
}

export interface Term {
  offset: number;
  value: string;
}

export interface Structuredformatting {
  main_text: string;
  main_text_matched_substrings: Matchedsubstring[];
  secondary_text: string;
}

interface Matchedsubstring {
  length: number;
  offset: number;
}

export interface IGeolocationResponse {
  LocationItems: ILocationItem[];
}

export interface ILocationItem {
  type: LocationItemType;
  latitude: string;
  longitude: string;
  name: string;
  country: LocationItemCountry;
  address: string;
  phoneNumber?: string;
  email?: string;
  merchant?: string;
  open?: boolean;
  tradingHours?: string;
  note?: string;
}

export enum LocationItemCountry {
  SouthAfrica = 'South Africa',
}

export enum LocationItemType {
  ATM = 'ATM',
  Branch = 'BRANCH',
  CovidTesting = 'COVID_TESTING',
  Groceries = 'GROCERIES',
  Pharmacy = 'PHARMACY',
}

export interface IPosition {
  latitude: number;
  longitude: number;
  locationId?: string;
}

export interface IAddressLocation extends ILocationV3 {
  icon?: string;
  type?: AddressType;
}
export interface ILocationFormV3 {
  location: IPosition;
  postalCode?: string;
  addressString?: string;
  contactPerson?: string;
  streetName: string;
  streetNumber?: string;
  city?: string;
  complex?: string;
  country: string;
  stateOrProvince: string;
  suburb?: string;
  name?: string;
  geocodingData?: any;
  houseNumber?: string;
  building?: string;
  estate?: string;
  unitNumber?: string;
  addressType: LocationV3AddressType;
  instructions?: string;
}

export interface INewLocationV3 {
  name?: string;
  instructions?: string;
  location?: IPosition;
  address: INewLocationV3Address;
  billingAddress?: boolean;
  geocodingData?: string;
}

export interface INewLocationV3Address {
  addressString?: string;
  contactPerson?: string;
  streetName: string;
  streetNumber?: string;
  suburb?: string;
  city?: string;
  postalCode?: string;
  complex?: string;
  stateOrProvince: string;
  country: string;
  houseNumber?: string;
  building?: string;
  estate?: string;
  unitNumber?: string;
  addressType: LocationV3AddressType;
  instructions?: string;
}

export interface ILocationV3 {
  id?: string;
  name?: string;
  instructions?: string;
  location?: IPosition;
  address: ILocationV3Address;
  billingAddress?: boolean;
  valid?: boolean;
}

export interface ILocationV3Address {
  addressString: string;
  contactPerson: string;
  streetName: string;
  streetNumber?: string;
  suburb: string;
  city?: string;
  postalCode?: string;
  complex?: string;
  stateOrProvince: string;
  country: string;
  houseNumber?: string;
  building?: string;
  estate?: string;
  unitNumber?: string;
  type: LocationV3Type;
  addressType: LocationV3AddressType;
  instructions?: string;
}

export enum LocationV3Type {
  BILLTO = 'BILLTO',
  SHIPTO = 'SHIPTO',
  PRIMARY = 'PRIMARY',
  OTHER = 'OTHER',
  REGISTRED = 'REGISTRED',
  TRADING = 'TRADING',
  BUSINESS = 'BUSINESS',
}

export enum LocationV3AddressType {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  BUSINESSPARK = 'TOWNHOUSECOMPLEX',
  ESTATE = 'ESTATE',
}

export interface IAddressForm {
  locationId?: string;
  streetName?: string;
  streetNumber?: number;
  suburb?: string;
  stateOrProvince?: string;
  complex?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  locality?: string;
  addressString: string;
  contactPerson?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  houseNo?: string;
  houseNumber?: string;
  building?: string;
  estate?: string;
  unitNumber?: string;
  addressType?: LocationV3AddressType;
}

export enum LocationRedirectSource {
  TAKEAWAY_UPDATE = 'TAKEAWAY_UPDATE',
  PROFILE_LOCATIONS = 'PROFILE_LOCATIONS',
  SHOPPING_DELIVERY = 'SHOPPING_DELIVERY',
  SHOPPING_BILLING = 'SHOPPING_BILLING',
  RMA = 'RMA',
  HEYNED_CHAT = 'HEYNED_CHAT',
  GROCERIES = 'GROCERIES',
}

export interface ISetLocationMapChange {
  locationForm: IAddressForm;
  id: string;
  type: AddressType;
  isDraggable?: boolean;
  geocodingData?: any;
}
