import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * usage: [@SlideInOut]="'slider'"
 */
export const HorizontalSlideInOutAnimation = trigger('SlideInOut', [
  state('slider', style({ transform: 'translateX(0)' })),
  transition(':enter', [style({ transform: 'translateX(100%)' }), animate('250ms linear')]),
  transition(':leave', [animate('250ms linear', style({ transform: 'translateX(100%)' }))]),
]);
/**
 * Usage: [@fadeOut]="condition"
 * example: [@fadeOut]="!!hideSkeleton"
 */
export const FadeOutAnimation = trigger('fadeOut', [
  state(
    'true',
    style({
      opacity: 1,
    }),
  ),
  transition(':leave', [
    animate(
      '.5s ease-out',
      style({
        opacity: 0,
      }),
    ),
  ]),
]);
/**
 * Usage: [@expandAnimation]="element === expandedItem ? state.EXPANDED : state.COLLAPSED"
 */
export enum ExpandAnimationState {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
}
export const ExpandAnimation = trigger('expandAnimation', [
  state(ExpandAnimationState.COLLAPSED, style({ height: '0px', minHeight: '0', display: 'none' })),
  state(ExpandAnimationState.EXPANDED, style({ height: '*' })),
  transition(
    `${ExpandAnimationState.EXPANDED} <=> ${ExpandAnimationState.COLLAPSED}`,
    animate('100ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
  ),
]);
/**
 * Usage: [@fadeInOut]
 */
export const FadeInOutAnimation = trigger('fadeInOut', [
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  transition('void <=> *', animate(350)),
]);

/**
 * Usage: [@fadeInOut]
 */
export const FadeInOutLongAnimation = trigger('fadeInOut', [
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  transition('void <=> *', animate(600)),
]);

/**
 * Usage: [@fadeIn]
 */
export const FadeInAnimation = trigger('fadeIn', [
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  transition(':enter', animate(500)),
]);

/**
 * Usage: [@fadeInShort]
 */
export const FadeInShortAnimation = trigger('fadeInShort', [
  state(
    'void',
    style({
      opacity: 0,
    }),
  ),
  transition(':enter', animate(200)),
]);

/**
 * usage: [@slideDown]="state ? 'initial' : 'final'"
 */
export const SlideDownAnimation = trigger('slideDown', [
  state(
    'initial',
    style({
      transform: 'translateY(-100%)',
    }),
  ),
  state(
    'final',
    style({
      transform: 'translateY(0)',
    }),
  ),
  transition('final=>initial', animate('700ms')),
  transition('initial=>final', animate('250ms')),
]);
