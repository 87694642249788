import { Component, EventEmitter, Output } from '@angular/core';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-cookies-modal',
  template: `
    <ui-modal [modal]="ModalTypes.SLIDE_IN_UP" [template]="cookiesTemplate" [isVisible]="true" [noTopPadding]="true">
      <ng-template #cookiesTemplate>
        <div class="text-align-center column">
          <h3 zen-translate>cookies.title</h3>
          <ui-divider [compact]="true"></ui-divider>
          <p class="small light" [style.width]="'75%'" zen-translate>cookies.info_message</p>
          <ui-divider></ui-divider>
          <div class="row center">
            <ui-button
              [width]="'9rem'"
              [selectButton]="false"
              [callToActionButton]="true"
              [content]="'cookies.learn_more' | translate"
              (onClick)="onLink.emit()"
            ></ui-button>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <ui-button
              [width]="'9rem'"
              [callToActionButton]="true"
              [selectButton]="true"
              [content]="'cookies.accept_button' | translate"
              (onClick)="this.onCoookies.emit()"
            ></ui-button>
          </div>
          <ui-divider [compact]="true"></ui-divider>
        </div>
      </ng-template>
    </ui-modal>
  `,
})
export class CookiesModalComponent {
  @Output() onCoookies = new EventEmitter();
  @Output() onLink = new EventEmitter();
  ModalTypes = ModalType;
}
