<div
  [@toggle]="isOpen"
  id="header-sidemenu-slide-content"
  class="header-sidemenu-container"
  [class.collapsed]="isOpen === menuVisibility.HIDDEN"
>
  <div class="menu-close">
    <ui-hamburger [isOpen]="true" [isDark]="true" (onClick)="handleClose()"> </ui-hamburger>
  </div>
  <div class="inner">
    <ui-divider></ui-divider>
    <ng-container *ngIf="isOpen === menuVisibility.OPEN">
      <div class="padded-content">
        <wakanda-avo-business-sidebar-menu-item
          [isExpandable]="true"
          [isOpen]="
            router.url.includes(routes.b2c.landing) ||
            (router.url.includes(routes.b2b.landing) && !router.url.includes(routes.b2b.businessBuying))
          "
          [item]="{ title: 'merchant.pre_onboarding.sell_on_avo', subitems: sellingOnAvoMenu }"
          (clickItem)="onMenuItemClick($event)"
        ></wakanda-avo-business-sidebar-menu-item>

        <wakanda-avo-business-sidebar-menu-item
          [item]="{ title: 'merchant.pre_onboarding.buy_on_avo', link: routes.b2b.businessBuying }"
          (clickItem)="onMenuItemClick($event)"
        ></wakanda-avo-business-sidebar-menu-item>

        <wakanda-avo-business-sidebar-menu-item
          [item]="{ title: 'merchant.pre_onboarding.support', link: routes.support }"
          (clickItem)="onMenuItemClick($event)"
        ></wakanda-avo-business-sidebar-menu-item>
      </div>

      <div class="column center">
        <ui-divider></ui-divider>
        <ui-button
          [callToActionButton]="true"
          [selectButton]="true"
          aligned="bottom"
          [content]="'merchant.pre_onboarding.register' | translate"
          (onClick)="openRegisterModal()"
        ></ui-button>
        <ui-divider></ui-divider>
        <ui-button
          [callToActionButton]="true"
          [selectButton]="false"
          aligned="bottom"
          [content]="'merchant.pre_onboarding.log_in' | translate"
          (onClick)="navigateToLogin()"
        ></ui-button>
      </div>

      <ui-divider></ui-divider>
    </ng-container>
    <ui-divider></ui-divider>
  </div>
</div>
