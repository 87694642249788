import { createFeatureSelector, createSelector } from '@ngrx/store';
import take from 'lodash-es/take';
import { IMyRewardsState, MY_REWARDS_FEATURE_KEY } from './my-rewards.reducer';

const state = createFeatureSelector<IMyRewardsState>(MY_REWARDS_FEATURE_KEY);

export const getTransactions = createSelector(state, ({ transactions }) => transactions);

export const getPoints = createSelector(state, ({ points }) => points);

export const getCouponDetail = createSelector(state, ({ couponDetail }) => couponDetail);

export const getCoupons = createSelector(state, ({ coupons }) => coupons);

export const getLimitedCoupons = createSelector(state, ({ coupons }) => {
  if (!coupons) return null;
  return take(coupons, 5);
});

export const getPartners = createSelector(state, ({ partners }) => partners);

export const getLinkedPartners = createSelector(state, ({ partners }) =>
  partners ? partners.filter(p => p.linked) : null,
);

export const getUnlinkedPartners = createSelector(state, ({ partners }) =>
  partners ? partners.filter(p => !p.linked) : null,
);

export const getLinkResponse = createSelector(state, ({ linkResponse }) => linkResponse);

export const getQuoteResponse = createSelector(state, ({ quoteResponse }) => quoteResponse);

export const getConvertRequest = createSelector(state, ({ convertRequest }) => convertRequest);

export const getConvertResponse = createSelector(state, ({ convertResponse }) => convertResponse);

export const getBalance = createSelector(state, ({ balance }) => balance);
