export enum EVENTS {
  ADVERTISING_BANNER_REQUEST = 'ADVERTISING/BANNER/REQUEST',
  ADVERTISING_BANNER_RECEIVED = 'ADVERTISING/BANNER/RECEIVED',

  ADVERTISING_BANNER_CLICK_REQUEST = 'ADVERTISING/BANNER/CLICK/REQUEST',
  ADVERTISING_BANNER_CLICK_RECEIVED = 'ADVERTISING/BANNER/CLICK/RECEIVED',

  ADVERTISING_BANNER_FORM_REQUEST = 'ADVERTISING/BANNER/FORM/REQUEST',
  ADVERTISING_BANNER_FORM_RECEIVED = 'ADVERTISING/BANNER/FORM/RECEIVED',
}
