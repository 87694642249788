import { Component, OnDestroy } from '@angular/core';
import { DialogData, DialogService } from '@wakanda/wakanda-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ui-dialog-handler',
  styles: ['div.modal-mody { text-align: center; padding: 1.5em; }'],
  template: `
    <ng-container *ngIf="!!dialog">
      <ui-modal
        [modal]="dialog?.type"
        [title]="dialog?.title"
        [closeButton]="true"
        [isVisible]="true"
        (onCloseClick)="onClose()"
      >
        <div class="modal-mody flex flex-col items-center">
          <ng-container *ngIf="!!dialog?.icon">
            <ui-icon [icon]="dialog?.icon" size="4.5rem"></ui-icon>
            <ui-divider [compact]="true"></ui-divider>
          </ng-container>

          <ng-container *ngIf="!!dialog?.text">
            <ui-divider [compact]="true"></ui-divider>
            <h3>{{ dialog?.text }}</h3>
          </ng-container>

          <ng-container *ngIf="!!dialog?.text2">
            <ui-divider [compact]="true"></ui-divider>
            <p class="light small">{{ dialog?.text2 }}</p>
          </ng-container>

          <ng-container *ngIf="dialog?.showConfirmButton">
            <ui-divider></ui-divider>
            <ui-button
              [callToActionButton]="true"
              (onClick)="service.confirmDialog()"
              [selectButton]="true"
              [content]="dialog?.confirmButtonText"
            >
            </ui-button>
          </ng-container>

          <ng-container *ngIf="dialog?.showCloseButton">
            <ui-divider [compact]="true"></ui-divider>
            <ui-button
              [callToActionButton]="true"
              (onClick)="onClose()"
              [content]="dialog?.closeButtonText"
            ></ui-button>
          </ng-container>
        </div>
      </ui-modal>
    </ng-container>
  `,
})
export class DialogHandlerComponent implements OnDestroy {
  dialog: DialogData;
  subscriptions = new Subscription();

  constructor(public service: DialogService) {
    this.subscriptions.add(this.service.getDialog().subscribe(dialog => (this.dialog = dialog)));
  }

  onClose(): void {
    this.service.closeDialog();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
