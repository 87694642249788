export const icon = {
  category: {
    arrow: {
      forward: {
        blue: './assets/Icon/Category/Arrow/Forward/Blue.svg',
        darkPurple: './assets/Icon/Category/Arrow/Forward/Dark purple.svg',
        olive: './assets/Icon/Category/Arrow/Forward/Olive.svg',
        peach: './assets/Icon/Category/Arrow/Forward/Peach.svg',
        pink: './assets/Icon/Category/Arrow/Forward/Pink.svg',
        red: './assets/Icon/Category/Arrow/Forward/Red.svg',
        salmon: './assets/Icon/Category/Arrow/Forward/Salmon.svg',
        scarlet: './assets/Icon/Category/Arrow/Forward/Scarlet .svg',
        ultramarine: './assets/Icon/Category/Arrow/Forward/Ultramarine.svg',
        yellow: './assets/Icon/Category/Arrow/Forward/Yellow.svg',
        lightBlue: './assets/Icon/Category/Arrow/Forward/light blue.svg',
      },
    },
    auto: './assets/Icon/Category/Auto.svg',
    banking: './assets/Icon/Category/Banking.svg',
    groceries: './assets/Icon/Category/Groceries.svg',
    health: './assets/Icon/Category/Health.svg',
    liquor: './assets/Icon/Category/Liquor.svg',
    rewards: './assets/Icon/Category/Rewards.svg',
    services: './assets/Icon/Category/Services.svg',
    shop: './assets/Icon/Category/Shop.svg',
    takeaways: './assets/Icon/Category/Takeaways.svg',
    travel: './assets/Icon/Category/Travel.svg',
    vouchersAndPrePaids: './assets/Icon/Category/VouchersAndPrePaids.svg',
  },
  duoTone: {
    accountInfo: { dark: './assets/Icon/DuoTone/AccountInfo/Dark.svg' },
    auto: './assets/Icon/DuoTone/Auto.svg',
    call: {
      dark: './assets/Icon/DuoTone/Call/Dark.svg',
      light: './assets/Icon/DuoTone/Call/Light.svg',
    },
    chat: {
      dark: './assets/Icon/DuoTone/Chat/Dark.svg',
      light: './assets/Icon/DuoTone/Chat/Light.svg',
    },
    communication: { dark: './assets/Icon/DuoTone/Communication/Dark.svg' },
    electricity: './assets/Icon/DuoTone/Electricity.svg',
    email: {
      dark: './assets/Icon/DuoTone/Email/Dark.svg',
      light: './assets/Icon/DuoTone/Email/Light.svg',
    },
    heart: {
      dark: './assets/Icon/DuoTone/Heart/Dark.svg',
      light: './assets/Icon/DuoTone/Heart/Light.svg',
    },
    history: { dark: './assets/Icon/DuoTone/History/Dark.svg' },
    location: { stroke: './assets/Icon/DuoTone/Location/Stroke.svg' },
    nedbankLogo: './assets/Icon/DuoTone/Nedbank logo.svg',
    order: { dark: './assets/Icon/DuoTone/Order/Dark.svg' },
    promotion: { dark: './assets/Icon/DuoTone/Promotion/Dark.svg' },
    rewards: './assets/Icon/DuoTone/Rewards.svg',
    scanToPay: './assets/Icon/DuoTone/Scan to pay.svg',
    send: {
      dark: './assets/Icon/DuoTone/Send/Dark.svg',
      light: './assets/Icon/DuoTone/Send/Light.svg',
    },
    share: { dark: './assets/Icon/DuoTone/Share/Dark.svg' },
    star: { dark: './assets/Icon/DuoTone/Star/Dark.svg' },
    subscription: { dark: './assets/Icon/DuoTone/Subscription/Dark.svg' },
    tuner: { dark: './assets/Icon/DuoTone/Tuner/Dark.svg' },
    users: { dark: './assets/Icon/DuoTone/Users/Dark.svg' },
    vouchers: './assets/Icon/DuoTone/Vouchers.svg',
  },
  filled: {
    leaf: {
      dark: './assets/Icon/Filled/Leaf/Dark.svg',
      green: './assets/Icon/Filled/Leaf/Green.svg',
      white: './assets/Icon/Filled/Leaf/White.svg',
    },
    location: {
      dark: './assets/Icon/Filled/Location/Dark.svg',
      green: './assets/Icon/Filled/Location/Green.svg',
      white: './assets/Icon/Filled/Location/White.svg',
    },
    mall: {
      dark: './assets/Icon/Filled/Mall/Dark.svg',
      white: './assets/Icon/Filled/Mall/White.svg',
    },
    medal: { dark: './assets/Icon/Filled/Medal/Dark.svg' },
    star: {
      dark: './assets/Icon/Filled/Star/Dark.svg',
      white: './assets/Icon/Filled/Star/White.svg',
    },
  },
  food: {
    asian: './assets/Icon/Food/Asian.svg',
    chicken: './assets/Icon/Food/Chicken.svg',
    dessert: './assets/Icon/Food/Dessert.svg',
    fish: './assets/Icon/Food/Fish.svg',
    frozen: './assets/Icon/Food/Frozen.svg',
    hamburger: './assets/Icon/Food/Hamburger.svg',
    liquor: './assets/Icon/Food/Liquor.svg',
    pizza: './assets/Icon/Food/Pizza.svg',
    preCooked: './assets/Icon/Food/PreCooked.svg',
    sushi: './assets/Icon/Food/Sushi.svg',
  },
  navigation: {
    discover: {
      default: './assets/Icon/Navigation/Discover/Default.svg',
      selected: './assets/Icon/Navigation/Discover/Selected.svg',
    },
    hom: { selected: './assets/Icon/Navigation/Hom/Selected.svg' },
    home: { default: './assets/Icon/Navigation/Home/Default.svg' },
    inbox: {
      default: './assets/Icon/Navigation/Inbox/Default.svg',
      selected: './assets/Icon/Navigation/Inbox/Selected.svg',
    },
    location: './assets/Icon/Navigation/Location.svg',
    profile: {
      default: './assets/Icon/Navigation/Profile/Default.svg',
      selected: './assets/Icon/Navigation/Profile/Selected.svg',
    },
    wallet: {
      default: './assets/Icon/Navigation/Wallet/Default.svg',
      selected: './assets/Icon/Navigation/Wallet/Selected.svg',
    },
  },
  social: {
    facebook: './assets/Icon/Social/Facebook.svg',
    instagram: './assets/Icon/Social/Instagram.svg',
    twitter: './assets/Icon/Social/Twitter.svg',
  },
  system: {
    add: {
      dark: './assets/Icon/System/Add/Dark.svg',
      white: './assets/Icon/System/Add/White.svg',
    },
    arrow: {
      back: {
        dark: './assets/Icon/System/Arrow/Back/Dark.svg',
        white: './assets/Icon/System/Arrow/Back/White.svg',
      },
      diagonalUpLeft: {
        dark: './assets/Icon/System/Arrow/DiagonalUpLeft/Dark.svg',
        white: './assets/Icon/System/Arrow/DiagonalUpLeft/White.svg',
      },
      diagonalUpRight: {
        dark: './assets/Icon/System/Arrow/DiagonalUpRight/Dark.svg',
        white: './assets/Icon/System/Arrow/DiagonalUpRight/White.svg',
      },
      forward: {
        dark: './assets/Icon/System/Arrow/Forward/Dark.svg',
        white: './assets/Icon/System/Arrow/Forward/White.svg',
      },
      white: './assets/Icon/System/Arrow/White.svg',
    },
    bankDetails: { dark: './assets/Icon/System/BankDetails/Dark.svg' },
    bed: {
      dark: './assets/Icon/System/Bed/Dark.svg',
      light: './assets/Icon/System/Bed/Light.svg',
    },
    bin: {
      dark: './assets/Icon/System/Bin/Dark.svg',
      red: './assets/Icon/System/Bin/Red.svg',
      white: './assets/Icon/System/Bin/White.svg',
    },
    biometric: {
      dark: './assets/Icon/System/Biometric/Dark.svg',
      white: './assets/Icon/System/Biometric/White.svg',
    },
    bookmark: {
      dark: './assets/Icon/System/Bookmark/Dark.svg',
      white: './assets/Icon/System/Bookmark/White.svg',
    },
    box: {
      dark: './assets/Icon/System/Box/Dark.svg',
      white: './assets/Icon/System/Box/White.svg',
    },
    building: { dark: './assets/Icon/System/Building/Dark.svg' },
    calculator: {
      dark: './assets/Icon/System/Calculator/Dark.svg',
      light: './assets/Icon/System/Calculator/Light.svg',
    },
    calendar: {
      dark: './assets/Icon/System/Calendar/Dark.svg',
      white: './assets/Icon/System/Calendar/White.svg',
    },
    camera: {
      dark: './assets/Icon/System/Camera/Dark.svg',
      white: './assets/Icon/System/Camera/White.svg',
    },
    car: {
      dark: './assets/Icon/System/Car/Dark.svg',
      light: './assets/Icon/System/Car/Light.svg',
    },
    cart: {
      dark: './assets/Icon/System/Cart/Dark.svg',
      white: './assets/Icon/System/Cart/White.svg',
    },
    check: {
      dark: './assets/Icon/System/Check/Dark.svg',
      green: './assets/Icon/System/Check/Green.svg',
      white: './assets/Icon/System/Check/White.svg',
    },
    chevron: {
      backward: {
        dark: './assets/Icon/System/Chevron/Backward/Dark.svg',
        white: './assets/Icon/System/Chevron/Backward/White.svg',
      },
      downward: {
        dark: './assets/Icon/System/Chevron/Downward/Dark.svg',
        white: './assets/Icon/System/Chevron/Downward/White.svg',
      },
      forward: {
        dark: './assets/Icon/System/Chevron/Forward/Dark.svg',
        white: './assets/Icon/System/Chevron/Forward/White.svg',
      },
      upward: {
        dark: './assets/Icon/System/Chevron/Upward/Dark.svg',
        white: './assets/Icon/System/Chevron/Upward/White.svg',
      },
    },
    close: {
      dark: './assets/Icon/System/Close/Dark.svg',
      white: './assets/Icon/System/Close/White.svg',
    },
    cloud: {
      dark: './assets/Icon/System/Cloud/Dark.svg',
      peach: './assets/Icon/System/Cloud/Peach.svg',
    },
    communications: {
      dark: './assets/Icon/System/Communications/Dark.svg',
      white: './assets/Icon/System/Communications/White.svg',
    },
    copyToClip: {
      dark: './assets/Icon/System/CopyToClip/Dark.svg',
      light: './assets/Icon/System/CopyToClip/Light.svg',
    },
    delivery: {
      dark: './assets/Icon/System/Delivery/Dark.svg',
      white: './assets/Icon/System/Delivery/White.svg',
    },
    device: {
      dark: './assets/Icon/System/Device/Dark.svg',
      white: './assets/Icon/System/Device/White.svg',
    },
    doc: {
      dark: './assets/Icon/System/Doc/Dark.svg',
      white: './assets/Icon/System/Doc/White.svg',
    },
    download: {
      dark: './assets/Icon/System/Download/Dark.svg',
      white: './assets/Icon/System/Download/White.svg',
    },
    edit: {
      dark: './assets/Icon/System/Edit/Dark.svg',
      white: './assets/Icon/System/Edit/White.svg',
    },
    exchange: {
      dark: './assets/Icon/System/Exchange/Dark.svg',
      light: './assets/Icon/System/Exchange/Light.svg',
    },
    eye: {
      closed: {
        dark: './assets/Icon/System/Eye/Closed/Dark.svg',
        white: './assets/Icon/System/Eye/Closed/White.svg',
      },
      open: {
        dark: './assets/Icon/System/Eye/Open/Dark.svg',
        white: './assets/Icon/System/Eye/Open/White.svg',
      },
    },
    favourite: {
      dark: './assets/Icon/System/Favourite/Dark.svg',
      white: './assets/Icon/System/Favourite/White.svg',
    },
    filter: {
      dark: './assets/Icon/System/Filter/Dark.svg',
      white: './assets/Icon/System/Filter/White.svg',
    },
    fingerprint: {
      dark: './assets/Icon/System/Fingerprint/Dark.svg',
      minimal: { dark: './assets/Icon/System/Fingerprint/Minimal/Dark.svg' },
      white: './assets/Icon/System/Fingerprint/White.svg',
    },
    flower: {
      dark: './assets/Icon/System/Flower/Dark.svg',
      light: './assets/Icon/System/Flower/Light.svg',
    },
    furniture: {
      dark: './assets/Icon/System/Furniture/Dark.svg',
      light: './assets/Icon/System/Furniture/Light.svg',
    },
    gallery: {
      dark: './assets/Icon/System/Gallery/Dark.svg',
      white: './assets/Icon/System/Gallery/White.svg',
    },
    gift: {
      dark: './assets/Icon/System/Gift/Dark.svg',
      light: './assets/Icon/System/Gift/Light.svg',
    },
    globe: {
      dark: './assets/Icon/System/Globe/Dark.svg',
      white: './assets/Icon/System/Globe/White.svg',
    },
    gridView: {
      dark: './assets/Icon/System/GridView/Dark.svg',
      white: './assets/Icon/System/GridView/White.svg',
    },
    hamburger: {
      dark: './assets/Icon/System/Hamburger/Dark.svg',
      white: './assets/Icon/System/Hamburger/White.svg',
    },
    home: {
      dark: './assets/Icon/System/Home/Dark.svg',
      white: './assets/Icon/System/Home/White.svg',
    },
    info: {
      dark: './assets/Icon/System/Info/Dark.svg',
      white: './assets/Icon/System/Info/White.svg',
    },
    kebab: {
      dark: './assets/Icon/System/Kebab/Dark.svg',
      light: './assets/Icon/System/Kebab/Light.svg',
    },
    language: {
      dark: './assets/Icon/System/Language/Dark.svg',
      white: './assets/Icon/System/Language/White.svg',
    },
    list: {
      dark: './assets/Icon/System/List/Dark.svg',
      red: './assets/Icon/System/List/Red.svg',
    },
    listView: {
      dark: './assets/Icon/System/ListView/Dark.svg',
      white: './assets/Icon/System/ListView/White.svg',
    },
    loading: {
      dark: './assets/Icon/System/Loading/Dark.svg',
      light: './assets/Icon/System/Loading/Light.svg',
    },
    location: {
      dark: './assets/Icon/System/Location/Dark.svg',
      white: './assets/Icon/System/Location/White.svg',
    },
    locationPin: {
      dark: './assets/Icon/System/LocationPin/Dark.svg',
      white: './assets/Icon/System/LocationPin/White.svg',
    },
    manageProfile: {
      dark: './assets/Icon/System/ManageProfile/Dark.svg',
      white: './assets/Icon/System/ManageProfile/White.svg',
    },
    minus: {
      dark: './assets/Icon/System/Minus/Dark.svg',
      white: './assets/Icon/System/Minus/White.svg',
    },
    noDoc: {
      dark: './assets/Icon/System/NoDoc/Dark.svg',
      white: './assets/Icon/System/NoDoc/White.svg',
    },
    noImage: {
      dark: './assets/Icon/System/NoImage/Dark.svg',
      light: './assets/Icon/System/NoImage/Light.svg',
    },
    notes: {
      dark: './assets/Icon/System/Notes/Dark.svg',
      white: './assets/Icon/System/Notes/White.svg',
    },
    odo: {
      dark: './assets/Icon/System/Odo/Dark.svg',
      violet: './assets/Icon/System/Odo/Violet.svg',
      white: './assets/Icon/System/Odo/White.svg',
    },
    order: {
      dark: './assets/Icon/System/Order/Dark.svg',
      white: './assets/Icon/System/Order/White.svg',
    },
    phonebook: {
      dark: './assets/Icon/System/Phonebook/Dark.svg',
      white: './assets/Icon/System/Phonebook/White.svg',
    },
    plus: {
      dark: './assets/Icon/System/Plus/Dark.svg',
      white: './assets/Icon/System/Plus/White.svg',
    },
    profile: {
      blue: './assets/Icon/System/Profile/Blue.svg',
      green: './assets/Icon/System/Profile/Green.svg',
    },
    prohibit: {
      dark: './assets/Icon/System/Prohibit/Dark.svg',
      light: './assets/Icon/System/Prohibit/Light.svg',
    },
    retry: {
      dark: './assets/Icon/System/Retry/Dark.svg',
      white: './assets/Icon/System/Retry/White.svg',
    },
    rewards: {
      dark: './assets/Icon/System/Rewards/Dark.svg',
      peach: './assets/Icon/System/Rewards/Peach.svg',
      white: './assets/Icon/System/Rewards/White.svg',
    },
    sQ: {
      dark: './assets/Icon/System/SQ/Dark.svg',
      light: './assets/Icon/System/SQ/Light.svg',
    },
    save: {
      dark: './assets/Icon/System/Save/Dark.svg',
      light: './assets/Icon/System/Save/Light.svg',
    },
    scooter: {
      dark: './assets/Icon/System/Scooter/Dark.svg',
      white: './assets/Icon/System/Scooter/White.svg',
    },
    search: {
      dark: './assets/Icon/System/Search/Dark.svg',
      white: './assets/Icon/System/Search/White.svg',
    },
    shield: { dark: './assets/Icon/System/Shield/Dark.svg' },
    shower: {
      dark: './assets/Icon/System/Shower/Dark.svg',
      light: './assets/Icon/System/Shower/Light.svg',
    },
    signIn: {
      dark: './assets/Icon/System/SignIn/Dark.svg',
      white: './assets/Icon/System/SignIn/White.svg',
    },
    signOut: {
      dark: './assets/Icon/System/SignOut/Dark.svg',
      white: './assets/Icon/System/SignOut/White.svg',
    },
    stopwatch: {
      dark: './assets/Icon/System/Stopwatch/Dark.svg',
      peach: './assets/Icon/System/Stopwatch/Peach.svg',
    },
    subscription: {
      dark: './assets/Icon/System/Subscription/Dark.svg',
      white: './assets/Icon/System/Subscription/White.svg',
    },
    thumbsDown: {
      dark: './assets/Icon/System/ThumbsDown/Dark.svg',
      white: './assets/Icon/System/ThumbsDown/White.svg',
    },
    thumbsUp: {
      dark: './assets/Icon/System/ThumbsUp/Dark.svg',
      light: './assets/Icon/System/ThumbsUp/Light.svg',
    },
    tooltip: {
      dark: './assets/Icon/System/Tooltip/Dark.svg',
      white: './assets/Icon/System/Tooltip/White.svg',
    },
    tooltipThin: { dark: './assets/Icon/System/TooltipThin/Dark.svg' },
    truck: {
      dark: './assets/Icon/System/Truck/Dark.svg',
      peach: './assets/Icon/System/Truck/Peach.svg',
      white: './assets/Icon/System/Truck/White.svg',
    },
    umbrella: './assets/Icon/System/Umbrella.svg',
    underage: './assets/Icon/System/Underage.svg',
    unlock: {
      dark: './assets/Icon/System/Unlock/Dark.svg',
      green: './assets/Icon/System/Unlock/Green.svg',
      white: './assets/Icon/System/Unlock/White.svg',
    },
    upload: {
      dark: './assets/Icon/System/Upload/Dark.svg',
      green: './assets/Icon/System/Upload/Green.svg',
      white: './assets/Icon/System/Upload/White.svg',
    },
    user: { dark: './assets/Icon/System/User/Dark.svg' },
    work: {
      dark: './assets/Icon/System/Work/Dark.svg',
      white: './assets/Icon/System/Work/White.svg',
    },
    lock: {
      closed: {
        dark: './assets/Icon/System/lock/Closed/Dark .svg',
        white: './assets/Icon/System/lock/Closed/White .svg',
      },
      open: {
        dark: './assets/Icon/System/lock/Open/Dark.svg',
        white: './assets/Icon/System/lock/Open/White.svg',
      },
    },
    greenbacks_discount_logo: { dark: './assets/Icon/Greenbacks/gb-discount-logo.svg' },
  },
};
