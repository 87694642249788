import { environment } from '@avo/environment/customer/environment';
import { SocialUser, BaseLoginProvider } from '@abacritt/angularx-social-login';

declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'APPLE';

  protected auth2: any;

  constructor(private clientId: string, private scope: string = 'email') {
    super();
  }

  initialize(): Promise<void> {
    return new Promise(resolve => {
      this.loadScript(
        AppleLoginProvider.PROVIDER_ID,
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
        () => {
          this.auth2 = AppleID.auth.init({
            clientId: environment.appleClientId || this.clientId,
            scope: this.scope,
            redirectURI: location.origin,
            usePopup: true,
          });

          resolve();
        },
      );
    });
  }

  signIn(): Promise<SocialUser> {
    return AppleID.auth.signIn().then(data => {
      const user: SocialUser = new SocialUser();

      user.id = data.authorizedData?.userId;
      user.authToken = data.authorization?.id_token;

      return Promise.resolve(user);
    });
  }

  getLoginStatus(): Promise<SocialUser> {
    return Promise.resolve(null);
  }
  signOut(): Promise<void> {
    return new Promise(resolve => {
      resolve();
    });
  }
}
