import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import * as actions from './email-verification.actions';
import * as events from './email-verification.events';
import { EmailVerificationService } from '../../services/email-verification/email-verification.service';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';
import { ApplicationFacade } from '../application/application.facade';

@Injectable()
export class EmailVerificationEffects {
  fetchCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.EmailVerificationEmailVerifyReequestAction>(events.EMAIL_VERIFICATINO_VERIFY_EMAIL_REQUEST),
      switchMap(({ taskId, token, onError }) =>
        this.service.verifyEmail(token).pipe(
          map(() => succeed(new actions.EmailVerificationEmailVerifySucceedAction(taskId))),
          catchError(error => {
            onError();
            return of(failed(new HttpErrorAction(taskId, error, true)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private appFacade: ApplicationFacade,
    private service: EmailVerificationService,
  ) {}
}
