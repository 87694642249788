<ui-modal
  [modal]="ModalType.SLIDE_IN_UP"
  [title]="'customer.update_address.title' | translate"
  [isVisible]="true"
  [closeButton]="true"
  (onCloseClick)="onClose.emit()"
  class="text-align-center"
>
  <div class="flex flex-col max-w-sm">
    <ui-icon icon="cart_warning"></ui-icon>
    <ui-divider></ui-divider>
    <p class="text-h4" zen-translate>customer.update_address.text</p>

    <ui-divider [large]="true"></ui-divider>
    <ui-button
      [selectButton]="true"
      [callToActionButton]="true"
      aligned="bottom"
      [content]="'customer.update_address.button' | translate"
      (onClick)="onUpdateAddress.emit(addressId)"
    ></ui-button>
  </div>
</ui-modal>
