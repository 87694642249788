import { Component, Injector, Input, OnInit } from '@angular/core';
import { CommonComponent, TimerService } from '@wakanda/wakanda-core';
import { ISellingToBusiness } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-avo-business-selling-to-business',
  templateUrl: './selling-to-business.component.html',
  styleUrls: ['./selling-to-business.component.scss'],
})
export class SellingToBusinessComponent extends CommonComponent implements OnInit {
  @Input() sellingToBusiness: ISellingToBusiness;
  @Input() isGreenContainer = false;
  activeItem = 0;
  timer: TimerService;

  constructor(injector: Injector) {
    super(injector);
  }

  handlePanel = (index: number): void => {
    this.activeItem = index;
  };

  ngOnInit(): void {
    this.resetTimer();
  }

  resetTimer = (): void => {
    this.timer = new TimerService();
    this.timer.start({
      timer: 5250,
      callback: () => {
        if (this.activeItem < this.sellingToBusiness.items?.length - 1) {
          this.activeItem = this.activeItem + 1;
        } else {
          this.activeItem = 0;
        }
      },
    });
  };
}
