<div class="ui calendar-monthly-picker">
  <div class="wrapper">
    <span
      class="item"
      [ngClass]="{
        selected: selected === item
      }"
      *ngFor="let item of rollValues"
      (click)="onSelect(item)"
    >
      <p>{{ item }}</p>
    </span>
  </div>
</div>
