import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigFacade } from '../store/app-config/app-config.facade';
import { LayoutFacade } from '../store/layout/layout.facade';

@Pipe({
  name: 'resizeImageUrl',
})
export class ResizeImageUrlPipe implements PipeTransform, OnDestroy {
  subscriptions = new Subscription();
  resizableDomains: string[];
  currentDevice: string;

  sizeMappings = {
    banners: {
      mobile: 580,
      tablet: 580,
      desktop: 580,
    },
    catalog: {
      mobile: 420,
      tablet: 420,
      desktop: 420,
    },
    productDetail: {
      mobile: 450,
      tablet: 450,
      desktop: 580,
    },
  };

  constructor(private appConfigFacade: AppConfigFacade, private layoutFacade: LayoutFacade) {
    this.subscriptions.add(
      this.appConfigFacade.getResizableDomains$.subscribe(domains => (this.resizableDomains = domains)),
    );
    this.subscriptions.add(
      this.layoutFacade.getCurrentDevice$.subscribe(data => {
        this.currentDevice = data;
      }),
    );
  }

  transform(url: string): string {
    if (!url) {
      return null;
    }

    let imageSize = window.screen.width;
    const href = window.location.href;

    // todo(lukas.jurygacek): rework into type instead of url
    if (!href.includes('ecommerce/store/detail')) {
      if (href.includes('ecommerce/product-detail')) {
        imageSize = this.sizeMappings.productDetail[this.currentDevice];
      } else if (href.includes('ecommerce')) {
        imageSize = this.sizeMappings.catalog[this.currentDevice];
      } else if (location.pathname === '/') {
        imageSize = this.sizeMappings.banners[this.currentDevice];
      }
    }

    return this.transformImageUrl(url, imageSize);
  }

  transformImageUrl(url: string, imageSize: number): string {
    if (this.resizableDomains) {
      for (const domain of this.resizableDomains) {
        if (
          url.includes(domain) &&
          !url.includes('cdn-cgi/image') &&
          (url.toLowerCase().includes('.jpg') ||
            url.toLowerCase().includes('.jpeg') ||
            url.toLowerCase().includes('.png'))
        ) {
          const resizeModification = `${domain}/cdn-cgi/image/w=${imageSize}`;
          return url.replace(domain, resizeModification);
        }
      }
    }
    return url;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
