import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;

  private removeTimer(): void {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  transform(value: string | number): string {
    this.removeTimer();
    let valueTimestampD: dayjs.Dayjs;
    if (typeof value === 'number') {
      const valueLength = value.toString().length;
      if (valueLength === 13) {
        value = value / 1000;
      }
      valueTimestampD = dayjs.unix(value);
    } else {
      valueTimestampD = dayjs(value);
    }

    return `${valueTimestampD.fromNow()}`;
  }

  ngOnDestroy(): void {
    this.removeTimer();
  }
}
