import { coreErrors } from '@wakanda/wakanda-core';
import { TranslateFacade } from '@wakanda/zentity-translate-store';
import { AppInitService } from '../services/init/app-init.service';
import { environment } from '@wakanda/environment-avo-business/environment';

export function appConfigFactory(appInitService: AppInitService, translateFacade: TranslateFacade): () => Promise<any> {
  return (): Promise<any> =>
    appInitService
      .init()
      .then(
        () =>
          new Promise<void>((resolve: any): any => {
            environment.translations?.forEach(translation => {
              translateFacade.fetchNewTranslations(translation, error => {
                console.error(error);
              });
            });
            translateFacade.getData$.subscribe(translateData => {
              if (translateData) {
                resolve();
              }
            });
          }),
      )
      .catch(() => {
        return Promise.reject(`${coreErrors.initApp.errorMessage}: error code: ${coreErrors.initApp.errorCode}`);
      });
}
