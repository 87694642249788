import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import last from 'lodash-es/last';

export interface IChainedHeaderTitle {
  title: string;
  onClick?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ChainedHeaderTitleService {
  chains: IChainedHeaderTitle[];
  private prevUrl: string;
  private nextUrl: string;

  constructor(private router: Router, private title: Title) {
    this.nextUrl = this.router.url;
    /**
     * After each page change, not params change,
     * @function clearChains is called
     */
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        distinctUntilChanged(),
      )
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.prevUrl = this.nextUrl?.split('?')[0];
          this.nextUrl = event.url?.split('?')[0];
        }

        if (this.prevUrl !== this.nextUrl) this.clearChains();
      });
  }

  setChainsTitles = (chains: IChainedHeaderTitle[], pageTitle?: string): void => {
    if (!chains?.length) return;

    if (!pageTitle) this.title.setTitle(last(chains)?.title);
    else this.title.setTitle(pageTitle);
    this.chains = chains;
  };

  clearChains = (): void => {
    this.chains = undefined;
  };
}
