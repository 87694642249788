import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TRANSACTIONS_FEATURE_KEY, TransactionsState } from './transactions.reducer';

export const getTransactionsState = createFeatureSelector<TransactionsState>(TRANSACTIONS_FEATURE_KEY);

export const getLastTransactionId = createSelector(getTransactionsState, state => state.lastTransactionId);

export const getTransactionSigningNextStep = createSelector(
  getTransactionsState,
  state => state.transactionSigning?.nextStep,
);

export const getTransactionSigningAuthenticators = createSelector(
  getTransactionsState,
  state => state.transactionSigning?.authenticators,
);
