import { Action } from '@ngrx/store';
import {
  IChatAttachmentRequest,
  IChatGetMessagesRequest,
  IChatGetRoomMessagesResponse,
  IChatHeyNedEnabledCheckResponse,
  IChatHeyNedRoom,
  IChatHeyNedRoomRequest,
  IChatHeyNedSubscriptionCheckResponse,
  IChatRoom,
  IChatRoomId,
  IChatSendEventRequest,
} from '../model/chat.model';
import * as EVENT from './chat.events';
import { HttpErrorAction, IChatHistoryRoom, IChatSendMessage, IWalletCheckPayResponse } from '@wakanda/wakanda-core';

// GetChatRoomIdForSupportRequestAction
export class GetChatRoomIdForSupportRequestAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_ID_SUPPORT_REQUEST;
  constructor(public taskId: string, public onSucceed?: (response: IChatRoomId) => void) {}
}
export class GetChatRoomIdForSupportResponseAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_ID_SUPPORT_RECEIVED;
  constructor(public taskId: string, public data: IChatRoomId) {}
}

// GetChatRoomIdForConciergeRequestAction
export class GetChatRoomIdForConciergeRequestAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_ID_CONCIERGE_REQUEST;
  constructor(public taskId: string, public onSucceed?: (response: IChatRoomId) => void) {}
}
export class GetChatRoomIdForConciergeResponseAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_ID_CONCIERGE_RECEIVED;
  constructor(public taskId: string, public data: IChatRoomId) {}
}

// CreateChatRoomIdForConciergeRequestAction
export class CreateChatRoomIdForConciergeRequestAction implements Action {
  readonly type = EVENT.CHAT_CREATE_ROOM_ID_CONCIERGE_REQUEST;
  constructor(public taskId: string, public onSucceed?: (response: IChatRoomId) => void) {}
}
export class CreateChatRoomIdForConciergeResponseAction implements Action {
  readonly type = EVENT.CHAT_CREATE_ROOM_ID_CONCIERGE_RECEIVED;
  constructor(public taskId: string, public data: IChatRoomId) {}
}

// GetChatRoomIdForJobRequestAction
export class GetChatRoomIdForJobRequestAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_ID_JOB_REQUEST;
  constructor(public taskId: string, public jobId: string) {}
}
export class GetChatRoomIdForJobResponseAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_ID_JOB_RECEIVED;
  constructor(public taskId: string, public data: IChatRoomId) {}
}

// GetChatRoomIdForJobAndMerchantRequestAction
export class GetChatRoomIdForJobAndMerchantRequestAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_ID_JOB_MERCHANT_REQUEST;
  constructor(
    public taskId: string,
    public jobId: string,
    public merchantId: string,
    public onSucceed: (data: IChatRoomId) => void,
  ) {}
}
export class GetChatRoomIdForJobAndMerchantResponseAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_ID_JOB_MERCHANT_RECEIVED;
  constructor(public taskId: string, public data: IChatRoomId) {}
}

// GetChatRoomRequestAction
export class GetChatRoomRequestAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_REQUEST;
  constructor(public taskId: string, public roomId: string) {}
}

export class GetChatRoomResponseAction implements Action {
  readonly type = EVENT.CHAT_GET_ROOM_RECEIVED;
  constructor(public taskId: string, public data: IChatRoom) {}
}

// ChatSendEventRequestAction
export class ChatSendEventRequestAction implements Action {
  readonly type = EVENT.CHAT_SEND_EVENT_REQUEST;
  constructor(public taskId: string, public request: IChatSendEventRequest) {}
}

export class ChatSendEventResponseAction implements Action {
  readonly type = EVENT.CHAT_SEND_EVENT_RECEIVED;
  constructor(public taskId: string, public data: string) {}
}

// FetchMessagesByRoomIdRequest
export class FetchMessagesByRoomIdRequest implements Action {
  readonly type = EVENT.CHAT_GET_MESSAGES_REQUEST;
  constructor(public taskId: string, public request: IChatGetMessagesRequest) {}
}

export class FetchMessagesByRoomIdReceived implements Action {
  readonly type = EVENT.CHAT_GET_MESSAGES_RECEIVED;
  constructor(public taskId: string, public data: IChatGetRoomMessagesResponse) {}
}

// ChatClearDataAction
export class ChatClearDataAction implements Action {
  readonly type = EVENT.CHAT_CLEAR_DATA;
}

// ChatUploadImageRequestAction
export class ChatUploadImageRequestAction implements Action {
  readonly type = EVENT.CHAT_UPLOAD_IMAGE_REQUEST;
  constructor(public taskId: string, public image: any, public onSucceed: (response: any) => void) {}
}
export class ChatUploadImageResponseAction implements Action {
  readonly type = EVENT.CHAT_UPLOAD_IMAGE_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

// ChatUploadImageResponseAction
export class ChatGetInfPaymentStatusRequestAction implements Action {
  readonly type = EVENT.CHAT_INF_PAYMENT_STATUS_REQUEST;
  constructor(
    public taskId: string,
    public jobId: string,
    public quoteId: string,
    public onSucceed: (response: any) => void,
  ) {}
}
export class ChatGetInfPaymentStatusResponseAction implements Action {
  readonly type = EVENT.CHAT_INF_PAYMENT_STATUS_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

// SendWebSocketMessageRequest
export class SendWebSocketMessageRequest implements Action {
  readonly type = EVENT.CHAT_SEND_WEBSOCKET_MESSAGE_REQUEST;
  constructor(public taskId: string, public message: IChatSendMessage) {}
}
export class SendWebSocketMessageSucceeded implements Action {
  readonly type = EVENT.CHAT_SEND_WEBSOCKET_MESSAGE_SUCCEEDED;
  constructor(public taskId: string) {}
}

// SendWebSocketAttachmentRequest
export class SendWebSocketAttachmentRequest implements Action {
  readonly type = EVENT.CHAT_SEND_WEBSOCKET_ATTACHMENT_REQUEST;
  constructor(public taskId: string, public roomId: string, public payload: IChatAttachmentRequest) {}
}
export class SendWebSocketAttachmentSucceeded implements Action {
  readonly type = EVENT.CHAT_SEND_WEBSOCKET_ATTACHMENT_SUCCEEDED;
  constructor(public taskId: string, public roomId: string) {}
}

// SendPayment
export class ChatSendPaymentRequestAction implements Action {
  readonly type = EVENT.CHAT_SEND_PAYMENT_REQUEST;
  constructor(public taskId: string, public roomId: string, public paymentId: string, public usedPoints: number) {}
}
export class ChatSendPaymentSucceededAction implements Action {
  readonly type = EVENT.CHAT_SEND_PAYMENT_RECEIVED;
  constructor(public taskId: string, public roomId: string) {}
}

// FetchPayCheck
export class ChatFetchPayCheckRequestAction implements Action {
  readonly type = EVENT.CHAT_FETCH_PAYCHECK_REQUEST;
  constructor(
    public taskId: string,
    public roomId: string,
    public paymentId: string,
    public onSucceed: (response: IWalletCheckPayResponse) => void,
  ) {}
}
export class ChatFetchPayCheckSucceededAction implements Action {
  readonly type = EVENT.CHAT_FETCH_PAYCHECK_RECEIVED;
  constructor(public taskId: string) {}
}

// ChatCreateHeyNedRoom
export class ChatCreateHeyNedRoomRequestAction implements Action {
  readonly type = EVENT.CHAT_CREATE_HEYNED_ROOM_REQUEST;
  constructor(
    public taskId: string,
    public request: IChatHeyNedRoomRequest,
    public onSucceed: (response: IChatHeyNedRoom) => void,
  ) {}
}
export class ChatCreateHeyNedRoomResponseAction implements Action {
  readonly type = EVENT.CHAT_CREATE_HEYNED_ROOM_RECEIVED;
  constructor(public taskId: string, public data: IChatHeyNedRoom) {}
}

// ChatCreateHeyNedHealthRoom
export class ChatCreateHeyNedHealthRoomRequestAction implements Action {
  readonly type = EVENT.CHAT_CREATE_HEYNED_HEALTH_ROOM_REQUEST;
  constructor(
    public taskId: string,
    public request: IChatHeyNedRoomRequest,
    public onSucceed: (response: IChatHeyNedRoom) => void,
  ) {}
}
export class ChatCreateHeyNedHealthRoomResponseAction implements Action {
  readonly type = EVENT.CHAT_CREATE_HEYNED_HEALTH_ROOM_RECEIVED;
  constructor(public taskId: string, public data: IChatHeyNedRoom) {}
}

// ChatCheckHeyNedSubscription
export class ChatCheckHeyNedSubscriptionRequestAction implements Action {
  readonly type = EVENT.CHECK_HEYNED_SUBSCRIPTION_REQUEST;
  constructor(public taskId: string, public onSucceed: (response: IChatHeyNedSubscriptionCheckResponse) => void) {}
}
export class ChatCheckHeyNedSubscriptionResponseAction implements Action {
  readonly type = EVENT.CHECK_HEYNED_SUBSCRIPTION_RECEIVED;
  constructor(public taskId: string, public data: IChatHeyNedSubscriptionCheckResponse) {}
}

// ChatCheckHeyNedSubscription
export class ChatCheckHeyNedEnabledRequestAction implements Action {
  readonly type = EVENT.CHECK_HEYNED_ENABLED_REQUEST;
  constructor(public taskId: string, public onSucceed: (response: IChatHeyNedEnabledCheckResponse) => void) {}
}
export class ChatCheckHeyNedEnabledResponseAction implements Action {
  readonly type = EVENT.CHECK_HEYNED_ENABLED_RECEIVED;
  constructor(public taskId: string, public data: IChatHeyNedEnabledCheckResponse) {}
}

// ChatHistoryOngoing
export class ChatHistoryOngoingRequestAction implements Action {
  readonly type = EVENT.CHAT_HISTORY_ONGOING_REQUEST;
  constructor(
    public taskId: string,
    public onSucceed: (response: IChatHistoryRoom[]) => void,
    public page?: number,
    public size?: number,
  ) {}
}
export class ChatHistoryOngoingResponseAction implements Action {
  readonly type = EVENT.CHAT_HISTORY_ONGOING_RECEIVED;
  constructor(public taskId: string, public data: IChatHistoryRoom[]) {}
}

// ChatHistoryCompleted
export class ChatHistoryCompletedRequestAction implements Action {
  readonly type = EVENT.CHAT_HISTORY_COMPLETED_REQUEST;
  constructor(
    public taskId: string,
    public onSucceed: (response: IChatHistoryRoom[]) => void,
    public page?: number,
    public size?: number,
  ) {}
}
export class ChatHistoryCompletedResponseAction implements Action {
  readonly type = EVENT.CHAT_HISTORY_COMPLETED_RECEIVED;
  constructor(public taskId: string, public data: IChatHistoryRoom[]) {}
}

// ChatHistorySearch
export class ChatHistorySearchRequestAction implements Action {
  readonly type = EVENT.CHAT_HISTORY_SEARCH_REQUEST;
  constructor(public taskId: string, public query: string, public onSucceed: (response: IChatHistoryRoom[]) => void) {}
}
export class ChatHistorySearchResponseAction implements Action {
  readonly type = EVENT.CHAT_HISTORY_SEARCH_RECEIVED;
  constructor(public taskId: string, public data: IChatHistoryRoom[]) {}
}

// ChatCloseChat
export class ChatCloseChatRequestAction implements Action {
  readonly type = EVENT.CHAT_CLOSE_CHAT_REQUEST;
  constructor(public taskId: string, public chatId: string, public onSucceed: () => void) {}
}
export class ChatCloseChatResponseAction implements Action {
  readonly type = EVENT.CHAT_CLOSE_CHAT_RECEIVED;
  constructor(public taskId: string) {}
}

// ChatErrorAction
export class ChatErrorAction extends HttpErrorAction {
  override readonly type = EVENT.CHAT_FAILED;
}

// ChatSaveHeyNedOriginRoomIdAction
export class ChatSaveHeyNedOriginRoomIdAction implements Action {
  readonly type = EVENT.CHAT_SAVE_HEYNED_ORIGIN_ROOM_ID;
  constructor(public taskId: string, public data: string) {}
}

// ChatClearHeyNedOriginRoomIdAction
export class ChatClearHeyNedOriginRoomIdAction implements Action {
  readonly type = EVENT.CHAT_CLEAR_HEYNED_ORIGIN_ROOM_ID;
  constructor(public taskId: string) {}
}

// HeyNedOpenedTaskRequestAction
export class HeyNedOpenedTaskRequestAction implements Action {
  readonly type = EVENT.CHAT_HEYNED_OPENED_TASK_REQUEST;
  constructor(public taskId: string, public onSucceed: (data: IChatHeyNedRoom) => void) {}
}
export class HeyNedOpenedTaskResponseAction implements Action {
  readonly type = EVENT.CHAT_HEYNED_OPENED_TASK_RECEIVED;
  constructor(public taskId: string, public data: IChatRoomId) {}
}
