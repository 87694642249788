import { createAction, props } from '@ngrx/store';

export const fetchTranslationsRequestAction = createAction(
  '[Translate/API] fetch translation request',
  props<{ name: string; onSuccess: () => void }>(),
);

export const fetchTranslationsResponseAction = createAction(
  '[Translate/API] fetch translation response',
  props<{ data: any }>(),
);
