<div
  class="ui-item"
  (click)="onClick.emit(data)"
  [class]="class"
  [ngClass]="{
    'has-shadow': !!hasShadow,
    'round-corners': !!roundCorners,
    'border-dotted': border === 'dotted',
    'border-solid': border === 'solid',
    'border-pink': border === 'pink',
    'small': size === 'small',
    'medium': size === 'medium',
    'has-checkbox': !!hasCheckBox,
    'has-favourite': !!data && data?.favourite,
    'has-share': !!data && data?.share,
    'has-close': !!hasClose,
    'compact': !!compact,
    'full-width': !!fullWidth,
    'has-chat': !!data && !!data?.canChat,
    'has-reviews': !!data && !!data?.reviewsNumber,
    'icon-right': iconAlign === 'right',
    'has-note-count': !!data && !!data?.notes,
    'no-background': !!noBackground
  }"
>
  <div class="item-wrapper">
    <ng-container *ngIf="hasCheckBox">
      <ui-icon *ngIf="checkBoxSelected" class="checkbox" [icon]="'check_blue'" width="1.5rem" height="1.5rem"></ui-icon>
      <ui-icon
        *ngIf="!checkBoxSelected"
        class="checkbox"
        [icon]="'check_grey'"
        width="1.5rem"
        height="1.5rem"
      ></ui-icon>
    </ng-container>

    <ng-container *ngIf="!!hasClose">
      <ui-icon icon="cross" class="close" size="1rem"></ui-icon>
    </ng-container>

    <div class="inner" [ngClass]="{ 'has-header': !!header, 'no-pointer': !pointer }">
      <ng-container *ngIf="!!header">
        <div class="header">
          <h4>{{ header }}</h4>
        </div>
      </ng-container>
      <ng-container *ngIf="!!data?.image || !!data?.icon || !!image">
        <div class="avatar-wrapper" [style.width]="iconSize ? iconSize : '3.125rem'">
          <img *ngIf="!!data && data?.image" class="avatar" [alt]="data?.image" [src]="data?.image" />
          <img *ngIf="!!image" class="avatar" [alt]="image" [src]="image" />
          <ui-icon *ngIf="data?.icon" [icon]="data?.icon" [size]="iconSize ? iconSize : '2.188rem'"></ui-icon>
        </div>
      </ng-container>
      <div
        class="description"
        [ngClass]="{
          'description-only': !data || (!!data && !data?.title && !!data?.description && !data?.rating)
        }"
      >
        <ng-container *ngIf="!!data">
          <div *ngIf="!!data?.title" class="title-row">
            <h4 *ngIf="!!data?.title">{{ data.title }}</h4>
            <ng-container *ngIf="data?.canChat || data?.notes || data?.favourite || data?.share">
              <div class="action-items">
                <ui-icon *ngIf="!!data?.canChat" icon="chat" size="1rem"></ui-icon>
                <a *ngIf="!!data?.notes" class="note-count">{{ data.notes }}</a>
                <ui-icon
                  *ngIf="!!data?.favourite"
                  [icon]="data?.favourite ? 'heart_3' : 'heart_1'"
                  size="1rem"
                ></ui-icon>
                <ui-icon *ngIf="!!data?.share" icon="share_2" size="1rem"></ui-icon>
              </div>
            </ng-container>
          </div>
          <p *ngIf="!!data?.description" class="small light">{{ data.description }}</p>
          <div *ngIf="!!data?.rating" class="rating-overview">
            <ui-rating [readOnly]="true" size="mini" [rating]="data?.rating" [id]="data?.id"></ui-rating>
            <h4 *ngIf="data?.reviewsNumber">{{ data.reviewsNumber }} Reviews</h4>
          </div>
        </ng-container>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
