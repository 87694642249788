import { Action } from '@ngrx/store';
import {
  IUpgradeWalletAddressStepRequest,
  IUpgradeWalletIdPhotoStepRequest,
  IUpgradeWalletNextStepResponse,
  IUpgradeWalletPersonalDataStepRequest,
  IUpgradeWalletSecurityQuestionsStepRequest,
} from '../../models/upgrade-wallet.model';
import { EVENT } from './upgrade-wallet.events';

export class UpgradeWalletInitRequestAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_INIT_REQUESTED;
  constructor(
    public taskId: string,
    public onSucceed: (response: IUpgradeWalletNextStepResponse) => void,
    public onError: () => void,
  ) {}
}

export class UpgradeWalletInitReceivedAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_INIT_RECEIVED;
  constructor(public taskId: string, public response: IUpgradeWalletNextStepResponse) {}
}

export class UpgradeWalletPersonalDataRequestAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_PERSONAL_DATA_REQUESTED;
  constructor(
    public taskId: string,
    public flowId: string,
    public request: IUpgradeWalletPersonalDataStepRequest,
    public onSucceed: (response: IUpgradeWalletNextStepResponse) => void,
  ) {}
}

export class UpgradeWalletPersonalDataReceivedAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_PERSONAL_DATA_RECEIVED;
  constructor(public taskId: string, public response: IUpgradeWalletNextStepResponse) {}
}

export class UpgradeWalletAddressRequestAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_ADDRESS_REQUESTED;
  constructor(
    public taskId: string,
    public flowId: string,
    public request: IUpgradeWalletAddressStepRequest,
    public onSucceed: (response: IUpgradeWalletNextStepResponse) => void,
  ) {}
}

export class UpgradeWalletAddressReceivedAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_ADDRESS_RECEIVED;
  constructor(public taskId: string, public response: IUpgradeWalletNextStepResponse) {}
}

export class UpgradeWalletSecurityQuestionsRequestAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_SECURITY_QUESTIONS_REQUESTED;
  constructor(
    public taskId: string,
    public flowId: string,
    public request: IUpgradeWalletSecurityQuestionsStepRequest,
    public onSucceed: (response: IUpgradeWalletNextStepResponse) => void,
  ) {}
}

export class UpgradeWalletSecurityQuestionsReceivedAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_SECURITY_QUESTIONS_RECEIVED;
  constructor(public taskId: string, public response: IUpgradeWalletNextStepResponse) {}
}

export class UpgradeWalletIdPhotoRequestAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_ID_PHOTO_REQUESTED;
  constructor(
    public taskId: string,
    public flowId: string,
    public request: IUpgradeWalletIdPhotoStepRequest,
    public onSucceed: (response: IUpgradeWalletNextStepResponse) => void,
  ) {}
}

export class UpgradeWalletIdPhotoReceivedAction implements Action {
  readonly type = EVENT.UPGRADE_WALLET_ID_PHOTO_RECEIVED;
  constructor(public taskId: string, public response: IUpgradeWalletNextStepResponse) {}
}

export type UpgradeWalletActions =
  | UpgradeWalletInitRequestAction
  | UpgradeWalletInitReceivedAction
  | UpgradeWalletPersonalDataRequestAction
  | UpgradeWalletPersonalDataReceivedAction
  | UpgradeWalletAddressRequestAction
  | UpgradeWalletAddressReceivedAction
  | UpgradeWalletSecurityQuestionsRequestAction
  | UpgradeWalletSecurityQuestionsReceivedAction
  | UpgradeWalletIdPhotoRequestAction
  | UpgradeWalletIdPhotoReceivedAction;
