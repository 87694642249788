import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as actions from './websockets.actions';
import { IWebsocketsPartialState } from './websockets.reducer';
import * as selectors from './websockets.selectors';

@Injectable({
  providedIn: 'root',
})
export class WebsocketsFacade {
  getEndpointWithToken$ = this.store.pipe(select(selectors.getEndpointWithToken));

  constructor(private store: Store<IWebsocketsPartialState>) {}

  fetchToken = (endpoint: string): void => {
    this.store.dispatch(new actions.WebsocketsChatFetchTokenRequest(endpoint));
  };
}
