export enum NavigationUrls {
  PREPAIDS = '/prepaids',
  REWARDS = '/rewards',
  VOUCHERS = '/vouchers',
  BANKING = '/banking',
  HOMELOANS = '/home-loans',
  PROPERTYREPORT = '/property-report',
  PROPERTYSEARCH = '/property-search',
  AFFORDABILITYCHECK = '/affordability-check',
}

export const UrlRoutes = {
  devtools: '/devtools',
  maintenance: '/maintenance',
  dashboard: {
    landing: '/',
    discoverAvo: '/discover-avo',
    primers: '/primers',
  },
  auth: {
    login: {
      landing: '/auth/login',
      otp: '/auth/login/otp',
      profileData: '/auth/login/profile-data',
      async: '/auth/login/async',
      logout: '/auth/login/logout',
      finish: '/auth/login/finish',
      linkAccounts: '/auth/login/link-account',
      linkCurrentProfile: '/auth/login/link-current-profile',
      nedbank: {
        landing: '/auth/login/nedbank',
        approveIt: '/auth/login/nedbank/approve-it',
      },
      moneyapp: {
        landing: '/auth/login/moneyapp',
      },
    },
  },
  profile: {
    manageProfile: {
      landing: '/profile',
      accountInfo: '/profile/account-info',
      addresses: '/profile/saved-addresses',
      preferences: '/profile/preferences',
      security: '/profile/security',
      manageDevices: '/profile/manage-devices',
      communications: '/profile/communications',
      profilePicture: '/profile/profile-picture',
      legal: '/profile/legal',
    },
    subscriptions: {
      landing: '/profile/subscriptions',
      detail: '/profile/subscriptions/detail/{0}',
      paymentMethods: '/profile/subscriptions/payment-methods',
    },
    activityHistory: {
      landing: '/avo/my-profile/activity-history',
    },
    unsubscribeEmail: {
      landing: '/profile/unsubscribe-marketing',
      success: '/profile/unsubscribe-marketing/success',
    },
  },
  ecommerce: {
    landing: '/ecommerce',
    search: {
      resultList: '/ecommerce/search/result-list',
      globalResultList: '/ecommerce/search/global-result-list',
      categoryList: '/ecommerce/search/category-list/{0}/{1}',
    },
    productDetail: `/ecommerce/{0}/{1}`,
    store: {
      list: '/ecommerce/store/list',
      detail: '/ecommerce/store/detail/{0}/{1}',
    },
    groceries: {
      landing: '/ecommerce/groceries',
      cart: {
        checkout: '/ecommerce/groceries/cart/checkout',
        delivery: '/ecommerce/groceries/cart/delivery',
        promoCode: '/ecommerce/groceries/cart/promo-code',
      },
    },
    liquor: {
      landing: '/ecommerce/liquor',
    },
    takeaway: {
      landing: '/ecommerce/takeaway',
    },
    cart: {
      landing: '/ecommerce/cart',
      detail: '/ecommerce/cart/{0}',
      checkout: '/ecommerce/cart/{0}/checkout',
      avo2: {
        landing: '/avo/shopping/mycart/{0}',
      },
      tvLicense: {
        landing: '/ecommerce/cart/{0}/tv-license',
        documentId: '/ecommerce/cart/{0}/tv-license/document-id',
        uploadIdDocument: '/ecommerce/cart/{0}/tv-license/upload-id-document',
      },
    },
    orderDetail: '/avo/my-profile/activity-history/product/{0}',
    pastBuysList: '/ecommerce/past-buys',
    pastBuysDetail: '/ecommerce/past-buys/detail/{0}',
  },
  prepaids: {
    home: NavigationUrls.PREPAIDS,
    activityHistory: {
      prepaid: '/avo/my-profile/activity-history/prepaid/{0}',
      voucher: '/avo/my-profile/activity-history/voucher/{0}/{1}',
    },
    providers: {
      landing: `${NavigationUrls.PREPAIDS}/providers`,
      products: `${NavigationUrls.PREPAIDS}/{0}/products`,
      product: `${NavigationUrls.PREPAIDS}/product-detail/{0}`,
      subcategory: `${NavigationUrls.PREPAIDS}/{0}/subcategory/{1}`,
      subcategoryProduct: `${NavigationUrls.PREPAIDS}/{0}/subcategory/{1}/product/{2}`,
      subcategoryChild: `${NavigationUrls.PREPAIDS}/{0}/subcategory/{1}/{2}`,
      subcategoryChildProduct: `${NavigationUrls.PREPAIDS}/{0}/subcategory/{1}/{2}/product/{3}`,
    },
  },
  rewards: {
    home: NavigationUrls.REWARDS,
    partners: {
      list: `${NavigationUrls.REWARDS}/partners`,
      convert: `${NavigationUrls.REWARDS}/convert/{0}`,
      link: `${NavigationUrls.REWARDS}/link/{0}`,
      saaLogin: `${NavigationUrls.REWARDS}/saa-login`,
    },
    onboarding: `/avo/onboarding/check-bank`,
  },
  payments: {
    home: '/payments',
    allocate: '/payments/allocate',
    success: '/payments/success',
    callbacks: {
      addCard: '/wallet/callback/add-card?type={0}',
      payCard: '/payments/callback/pay-card?type={0}',
      payFlex: '/payments/callback/pay-payflex?type={0}',
      paySwitchPay: '/payments/callback/pay-switchpay?type={0}',
    },
  },
  vouchers: {
    home: `${NavigationUrls.PREPAIDS}/${NavigationUrls.VOUCHERS}`,
    detail: `${NavigationUrls.PREPAIDS}${NavigationUrls.VOUCHERS}/{0}`,
    categories: `${NavigationUrls.PREPAIDS}/vouchers`,
  },
  banking: {
    landing: NavigationUrls.BANKING,
    funeral: {
      home: `${NavigationUrls.BANKING}/funeral`,
      cover: `${NavigationUrls.BANKING}/funeral/cover`,
      education: `${NavigationUrls.BANKING}/funeral/education`,
      order: `${NavigationUrls.BANKING}/funeral/cover-order`,
      addBeneficiary: `${NavigationUrls.BANKING}/funeral/add-beneficiary`,
      addDependant: `${NavigationUrls.BANKING}/funeral/add-dependant`,
      terms: `${NavigationUrls.BANKING}/funeral/terms`,
      success: `${NavigationUrls.BANKING}/funeral/success`,
      decline: `${NavigationUrls.BANKING}/funeral/decline`,
      error: `${NavigationUrls.BANKING}/funeral/error`,
      // policyDetails: `${NavigationUrls.BANKING}/funeral/policy-details`,
    },
    homeLoans: {
      landing: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}`,
      propertyReport: {
        landing: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYREPORT}`,
        search: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYREPORT}/search`,
        detail: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYREPORT}/detail`,
        success: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYREPORT}/success`,
        limitReached: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYREPORT}/limit-reached`,
        failed: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYREPORT}/failed`,
      },
      propertySearch: {
        landing: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYSEARCH}`,
        explore: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYSEARCH}/explore`,
        detail: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYSEARCH}/detail`,
        results: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.PROPERTYSEARCH}/results`,
      },
      affordabilityCheck: {
        landing: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}`,
        applicantDetails: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/applicant-details/{0}/{1}`,
        applicantDetailsList: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/applicant-details-list`,
        cashBack: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/cash-back`,
        cashBackTermConditions: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/cash-back-term-conditions`,
        declined: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/declined`,
        done: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/done`,
        granted: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/granted`,
        grantedDetails: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/granted-details`,
        loanNeeds: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/loan-needs`,
        personalDetails: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/personal-details`,
        propertyDetail: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/property-detail`,
        propertyDetailSuburb: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/property-detail?suburb=1`,
        propertyDetailAccount: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}${NavigationUrls.AFFORDABILITYCHECK}/property-detail?account=1`,
      },
      termConditions: `${NavigationUrls.BANKING}${NavigationUrls.HOMELOANS}/term-conditions`,
    },
  },
  travel: {
    landing: '/avo/shopping/product/list/TRAVEL9',
  },
  services: {
    landing: '/avo/home/services',
  },
  health: {
    landing: '/health',
  },
  newToProduct: {
    landing: `/avo/new-to-product`,
    preInitiation: `/avo/new-to-product/pre-initiation`,
    start: `/avo/new-to-product/start`,
  },
  personalLoans: {
    landing: `/avo/personal-loans`,
    preInitiation: `/avo/personal-loans/pre-initiation`,
    start: `/avo/personal-loans/start`,
  },
  onboarding: {
    start: '/avo/onboarding',
    address: '/avo/onboarding/address',
    checkBank: '/avo/onboarding/check-bank',
    checkLogin: '/avo/onboarding/check-login',
    addMerchant: '/avo/onboarding/add-merchant',
    startMoneyApp: '/avo/onboarding/moneyapp/start',
    otp: '/avo/onboarding/otp',
    lightFica: '/avo/onboarding/light-fica',
    personalDetails: '/avo/onboarding/personal-details',
    setLogin: '/avo/onboarding/set-login',
    success: '/avo/onboarding/success',
    endScreen: '/avo/onboarding/end-screen',
    nedbank: '/avo/onboarding/nedbank',
    firstNameLastName: '/avo/onboarding/firstname-lastname',
    businessCategory: '/avo/onboarding/business-category',
    businessPartner: '/avo/onboarding/business-partner',
    businessFinish: '/avo/onboarding/business-finish',
    businessDetail: '/avo/onboarding/business-details',
    businessAddress: '/avo/onboarding/business-address',
    businessTradingName: '/avo/onboarding/business-trading-name',
    multidirector: '/avo/onboarding/multidirector',
    stepup: `avo/onboarding/stepup`,
    stepupSuccess: '/avo/onboarding/stepup-success',
    stepupend: '/avo/onboarding/stepupend',
    approveIt: '/avo/onboarding/approve-it',
    approveItResend: '/avo/onboarding/approve-it/resend',
    progress: '/avo/onboarding/progress',
    return: '/avo/onboarding/return',
    bankOtp: '/avo/onboarding/bank-otp',
    bankphoneDifferent: '/avo/onboarding/bankphone-different',
    securityQuestions: '/avo/onboarding/security-questions',
    uploadDocument: '/avo/onboarding/upload-document',
    mobiMoney: '/avo/onboarding/mobi-money',
    basicData: '/avo/onboarding/basic-data',
    businessBasicData: '/avo/onboarding/business-basic-data',
    idPassport: '/avo/onboarding/identification',
    outstandingTcs: '/avo/onboarding/outstanding-tcs',
    tcs: '/avo/onboarding/tcs',
    selectAccount: '/avo/onboarding/select-account',
    selectOtp: '/avo/onboarding/select-otp',
    selectSocial: '/avo/onboarding/select-social',
    selectNedbank: '/avo/onboarding/select-nedbank',
    linkAccount: '/avo/onboarding/link-account',
    moneyAppSignup: '/avo/onboarding/moneyapp/signup',
    finish: '/avo/onboarding/finish',
    simSwapDetected: '/avo/onboarding/sim-swap-detected',
    login: '/avo/onboarding/login',
    legalEntity: '/avo/onboarding/legal-entity',
    businessLegalEntity: '/avo/onboarding/business-legal-entity',
    termsAndConditionsView: '/avo/onboarding/tc/{0}',
  },
  inbox: {
    landing: '/inbox',
    room: '/inbox/{0}',
  },
  wallet: {
    landing: '/wallet',
    manageCard: '/wallet/manage-card',
    transactionList: '/wallet/transaction-list',
  },
};
