import { ModalSize, ModalType } from '../modal/modal.component';
import { BusinessChoiceType } from '@wakanda/avo-business-model';
import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AdobeService } from '@wakanda/adobe-analytics';
import { Validators } from '@angular/forms';
import { CommonComponent } from '@wakanda/wakanda-core';

@Component({
  selector: 'wakanda-avo-business-register-modal',
  styleUrls: ['./register-business-modal.component.scss'],
  template: `
    <ui-modal
      [modal]="modalType"
      [size]="modalType === ModalType.FADE_IN ? ModalSize.SMALL : null"
      [template]="registerBusinessModalTemplate"
      [closeButton]="true"
      [isVisible]="true"
      (onCloseClick)="onClose.emit()"
    >
      <ng-template #registerBusinessModalTemplate>
        <div class="padded-content">
          <div class="text-align-left">
            <h2 zen-translate>merchant.register_preonboarding.title</h2>
            <ui-divider></ui-divider>
            <ui-radio
              [custom]="true"
              [name]="BusinessChoiceType.BUYER"
              [checked]="getFormValue('choice') === BusinessChoiceType.BUYER"
              (onChange)="onRadioChange($event.name)"
            >
              <p [class.bold]="getFormValue('choice') === BusinessChoiceType.BUYER">
                {{ 'merchant.register_preonboarding.buy_avo' | translate }}
              </p>
            </ui-radio>
            <ng-container *ngIf="getFormValue('choice') === BusinessChoiceType.BUYER">
              <div class="radio-details">
                <p zen-translate>merchant.register_preonboarding_need.following</p>
                <ui-divider [compact]="true"></ui-divider>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_text1</p>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_point2</p>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_point3</p>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_point4</p>
              </div>
              <ui-divider [compact]="true"></ui-divider>
              <ui-divider [solid]="true"></ui-divider>
            </ng-container>
            <ui-divider [compact]="true"></ui-divider>
            <ui-radio
              [custom]="true"
              [name]="BusinessChoiceType.B2B"
              [checked]="getFormValue('choice') === BusinessChoiceType.B2B"
              (onChange)="onRadioChange($event.name)"
            >
              <p [class.bold]="getFormValue('choice') === BusinessChoiceType.B2B">
                {{ 'merchant.register_preonboarding.sell_customers' | translate }}
              </p>
            </ui-radio>
            <ng-container *ngIf="getFormValue('choice') === BusinessChoiceType.B2B">
              <div class="radio-details">
                <p zen-translate>merchant.register_preonboarding_need.following</p>
                <ui-divider [compact]="true"></ui-divider>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_text1</p>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_point2</p>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_point3</p>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_point4</p>
              </div>
              <ui-divider [compact]="true"></ui-divider>
              <ui-divider [solid]="true"></ui-divider>
            </ng-container>
            <ui-divider [compact]="true"></ui-divider>
            <ui-radio
              [custom]="true"
              [name]="BusinessChoiceType.B2C"
              [checked]="getFormValue('choice') === BusinessChoiceType.B2C"
              (onChange)="onRadioChange($event.name)"
            >
              <p [class.bold]="getFormValue('choice') === BusinessChoiceType.B2C">
                {{ 'merchant.register_preonboarding.sell_business' | translate }}
              </p>
            </ui-radio>
            <ng-container *ngIf="getFormValue('choice') === BusinessChoiceType.B2C">
              <div class="radio-details">
                <p zen-translate>merchant.register_preonboarding_need.following</p>
                <ui-divider [compact]="true"></ui-divider>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_point6</p>
                <p class="light" zen-translate>merchant.register_preonboarding_need.following_point7</p>
                <ui-divider [compact]="true"></ui-divider>
              </div>
              <ui-divider [compact]="true"></ui-divider>
              <ui-divider [solid]="true"></ui-divider>
            </ng-container>
            <ui-divider [compact]="true"></ui-divider>
          </div>
          <ui-divider></ui-divider>
          <ui-button
            (onClick)="onRegister.emit(getFormValue('choice'))"
            [disabled]="!form.valid"
            [content]="'merchant.register_preonboarding.cta_button' | translate"
            [selectButton]="true"
            [callToActionButton]="true"
          ></ui-button>
        </div>
      </ng-template>
    </ui-modal>
  `,
})
export class RegisterBusinessModalComponent extends CommonComponent {
  adobeService: AdobeService;
  @Output() onRegister = new EventEmitter<BusinessChoiceType>();
  @Output() onClose = new EventEmitter();
  @Input() modalType = ModalType.FADE_IN;
  ModalSize = ModalSize;
  ModalType = ModalType;
  BusinessChoiceType = BusinessChoiceType;

  constructor(injector: Injector) {
    super(injector);
    this.adobeService = injector.get(AdobeService);

    this.createForm([{ name: 'choice', initialValue: '', validators: [Validators.required] }]);
    this.adobeService.executeAdobeLoadEvent({
      viewName: 'M1.0a_Homepage_RegistrationPopup',
      siteSection: this.adobeService.siteSections.AVO_BUSINESS,
    });
  }

  onRadioChange = (name: string): void => {
    this.adobeService.executeAdobeClickEvent(`registration: ${name}`);
    this.setForm({
      choice: name,
    });
  };
}
