import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs';
import * as TasksActions from './tasks.actions';
import { ITask, ITaskAction, TaskType } from '../models/store.models';

@Injectable()
export class TasksEffects {
  taskHandler$ = createEffect(() =>
    this.actions$.pipe(
      filter<ITaskAction>(
        action =>
          !!action.taskMetadata && [TaskType.STARTED, TaskType.STARTED_FULLSCREEN].includes(action.taskMetadata.type),
      ),
      map(action => {
        const type = action.taskMetadata.type;
        const taskData: ITask = {
          taskId: action.taskMetadata.taskId,
          type,
          action,
        };
        return TasksActions.addTaskAction({ data: taskData });
      }),
    ),
  );

  taskRepeat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TasksActions.repeatTaskAction),
      map(action => action.data.action as ITaskAction),
    ),
  );

  constructor(private readonly actions$: Actions<ITaskAction>) {}
}
