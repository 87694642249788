<div #offset class="benefit-panel" [class.no-margin]="last">
  <div class="benefit-panel-title row align-center between" [style.background-color]="backgroundColor">
    <h3>{{ benefit?.title | translate }}</h3>
    <ui-icon (click)="handlePanel()" icon="{{ isOpen ? 'plus3' : 'down' }}" size="1.2rem"></ui-icon>
  </div>
  <div *ngIf="isOpen" class="benefit-panel-content">
    <div class="row align-stretch wrap">
      <div *ngFor="let item of benefit?.items" class="benefit-panel-content-item row center left basis-50">
        <div *ngIf="item?.comingSoon" class="coming-soon-tag">
          <b class="small" zen-translate>merchant.pre_onboarding_sell_business.coming_soon</b>
        </div>
        <ui-icon icon="{{ item?.icon }}" size="2.5rem"></ui-icon>
        <ui-divider [vertical]="true" [compact]="true"></ui-divider>
        <p zen-translate>{{ item?.text }}</p>
        <ui-divider [vertical]="true"></ui-divider>
      </div>
    </div>
    <ng-container *ngIf="benefit?.image">
      <ui-divider></ui-divider>
      <img [src]="'assets/images/' + benefit.image" alt="Benefit illustration" />
    </ng-container>
    <ng-content></ng-content>
  </div>
</div>
