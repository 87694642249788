import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { ChatMessageType, FilesUtil, IChatSendMessageContent, IFileUpload } from '@wakanda/wakanda-core';
import debounce from 'lodash-es/debounce';
import { IChatAttachmentRequest } from '@wakanda/wakanda-chat';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-chat-input',
  styleUrls: ['./chat-input.component.scss'],
  template: `
    <ng-container *ngIf="!hide">
      <div class="ui-chat-input">
        <ui-icon *ngIf="!hideAttachmentPicker" icon="plus3" size="1rem" (click)="handleUploadPhotoDialog()"></ui-icon>

        <input
          id="main-chat-input"
          type="text"
          [name]="'chat-input'"
          [value]="value || ''"
          (input)="handleChange($event?.target)"
          (keyup.enter)="handleSend()"
          [disabled]="disable"
        />

        <ui-icon
          [class.disabled]="disable"
          icon="send"
          id="send-message"
          size="1.2rem"
          (click)="handleSend()"
        ></ui-icon>
      </div>

      <ui-modal
        [modal]="ModalType.SLIDE_IN_UP"
        [title]="'chat.upload.title' | translate"
        [template]="uploadDialog"
        [closeButton]="true"
        [isVisible]="showUploadDialog"
        (onCloseClick)="handleUploadPhotoDialog()"
      >
        <ng-template #uploadDialog>
          <div class="text-align-left">
            <div class="upload-chat-wrapper">
              <div class="item" (click)="handleCamera()">
                <ui-icon icon="camera" size="2.5rem"></ui-icon>
                <ui-divider [compact]="true"></ui-divider>
                <b zen-translate>chat.upload.camera</b>
              </div>
              <div class="item">
                <wakanda-image-upload [large]="true" (onChange)="uploadFromGallery($event)"></wakanda-image-upload>
                <ui-divider [compact]="true"></ui-divider>
                <b zen-translate>chat.upload.gallery</b>
              </div>
              <div class="item">
                <wakanda-image-upload
                  [large]="true"
                  [icon]="'document'"
                  [acceptDocuments]="true"
                  (onChange)="uploadDocument($event)"
                ></wakanda-image-upload>
                <ui-divider [compact]="true"></ui-divider>
                <b zen-translate>chat.upload.document</b>
              </div>
            </div>
          </div>
        </ng-template>
      </ui-modal>

      <ui-modal
        [modal]="ModalType.SLIDE_IN_UP"
        [title]="'TAKE A PHOTO'"
        [template]="takeAPhoto"
        [closeButton]="true"
        [isVisible]="showCamera"
        (onCloseClick)="handleCamera()"
      >
        <ng-template #takeAPhoto>
          <div style="height: 400px; display: flex">
            <ui-camera (onChange)="uploadWebcamImage($event)"></ui-camera>
          </div>
        </ng-template>
      </ui-modal>
    </ng-container>
  `,
})
export class ChatInputComponent {
  value: string;
  //
  showUploadDialog = false;
  showCamera = false;
  //
  @Output() onSend = new EventEmitter<IChatSendMessageContent>();
  @Output() onAttachmentUpload = new EventEmitter<IChatAttachmentRequest>();
  @Input() disable: boolean;
  @Input() hide: boolean;
  @Input() hideAttachmentPicker: boolean;
  ModalType = ModalType;

  private handleWritingIndicatorChange = debounce(
    (): void => {
      if (this.value) this.onSend.emit({ msgtype: ChatMessageType.notifyWriting });
    },
    250,
    { maxWait: 1000 },
  );

  handleChange = ({ value }): void => {
    this.handleWritingIndicatorChange();
    this.value = value;
  };

  handleSend = (): void => {
    if (this.value) {
      const message: IChatSendMessageContent = {
        body: this.value,
        msgtype: ChatMessageType.text,
      };
      this.onSend.emit(message);
      this.handleClear();
    }
  };

  handleClear = (): void => {
    this.value = '';
  };

  handleUploadPhotoDialog = (): void => {
    this.showUploadDialog = !this.showUploadDialog;
  };

  handleCamera = (): void => {
    this.showCamera = !this.showCamera;
    this.showUploadDialog = false;
  };

  uploadWebcamImage(image: WebcamImage): void {
    this.showUploadDialog = false;
    this.showCamera = false;
    const file = FilesUtil.handleWebcamImage(image);
    const request: IChatAttachmentRequest = {
      name: 'webcamImage.jpeg',
      data: file,
      messageType: ChatMessageType.image,
    };
    this.onAttachmentUpload.emit(request);
  }

  uploadFromGallery(image: IFileUpload): void {
    this.showUploadDialog = false;
    this.showCamera = false;
    const file = FilesUtil.handleFile(image);
    const request: IChatAttachmentRequest = {
      name: image.name,
      data: file,
      messageType: ChatMessageType.image,
    };
    this.onAttachmentUpload.emit(request);
  }

  uploadDocument(document: IFileUpload): void {
    this.showUploadDialog = false;
    const file = FilesUtil.handleFile(document);
    const request: IChatAttachmentRequest = {
      name: document.name,
      data: file,
      messageType: ChatMessageType.document,
    };
    this.onAttachmentUpload.emit(request);
  }
}
