import { Component, Injector } from '@angular/core';
import { CommonComponent } from '@wakanda/wakanda-core';

@Component({
  selector: 'wakanda-avo-business-delivery-by-avo',
  templateUrl: './delivery-by-avo.component.html',
  styleUrls: ['./delivery-by-avo.component.scss'],
})
export class DeliveryByAvoComponent extends CommonComponent {
  constructor(injector: Injector) {
    super(injector);
  }
}
