import { Component, Input } from '@angular/core';
import { IMatrixMessage, IMessageEventDocument } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-document',
  template: `
    <div class="chat-widget">
      <div class="ui-chat-message" [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }">
        <div class="row">
          <ui-icon icon="Pdf" size="1.75rem"></ui-icon>
          <ui-divider [vertical]="true" [compact]="true"></ui-divider>
          <a [href]="message?.content?.url | secureImage | async" target="_blank" [style.word-break]="'break-word'">{{
            message?.content?.filename || message?.content?.body
          }}</a>
        </div>
      </div>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
  `,
})
export class ChatWidgetDocumentComponent {
  @Input() message: IMatrixMessage<IMessageEventDocument>;
}
