import { Pipe, PipeTransform } from '@angular/core';
import { IStatusTranslate } from '../model/translate.model';
import { TranslateFacade } from '../state/translate.facade';

import { Observable, of } from 'rxjs';

@Pipe({
  name: 'statusTranslate',
  pure: true,
})
export class StatusTranslatePipe implements PipeTransform {
  constructor(private translateFacade: TranslateFacade) {}
  transform(value: string, statusData: IStatusTranslate): Observable<string> {
    if (!value || !statusData) return of(value);
    return this.translateFacade.getTranslateByKey$(statusData[value]);
  }
}
