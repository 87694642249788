import { Injectable } from '@angular/core';
import { AppConfig, CommonUtility, RandomStringUtil } from '@wakanda/wakanda-core';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(private appConfig: AppConfig) {}

  init(): Promise<any> {
    return fetch('configuration.json')
      .then(response => response.json())
      .then(configuration => {
        const sessionId = `PP${RandomStringUtil.generateRandomString()}`;
        this.appConfig.appType = 'P';
        this.appConfig.backendConfig = {
          apiaryUrl: null,
          authUrl: null,
          onboardingUrl: null,
          sessionId,
          transactionSigningUrl: null,
          apiUrl: configuration.apiUrl,
          translateUrl: CommonUtility.getTranslateUrl(configuration.apiUrl),
        };
        // This is requirement from business
        console.log('Session ID: ', sessionId);

        return Promise.resolve(configuration);
      });
  }
}
