export interface IRecoveryReducerState {
  recoveryFlowId?: string;
  nextStep?: NextStepRecovery;
  username?: string;
  otpSentTo?: string;
  phoneNumber?: string;
  emailAddress?: string;
}

export interface IRecoveryStartRequest {
  clientId?: string;
  intent: RecoveryTypeEnum;
}

export enum RecoveryTypeEnum {
  USERNAME = 'USERNAME',
  PASSWORD = 'PASSWORD',
  PIN = 'PIN',
}

export interface IRecoveryUsernameRequest {
  recoveryFlowId: string;
  phoneNumber: string;
  emailAddress?: string;
}

export interface IRecoveryPasswordRequest {
  recoveryFlowId?: string;
  userName: string;
}

export interface IVerifyOTPRequest {
  recoveryFlowId?: string;
  userName: string;
  otp?: string;
  resend: boolean;
}

export interface INewPasswordRequest {
  recoveryFlowId?: string;
  newPassword: string;
}

export interface IRecoveryNextStepResponse {
  recoveryFlowId?: string;
  nextStep: NextStepRecovery;
  otpSentTo?: string;
}

export enum NextStepRecovery {
  EMAIL_PHONE = 'EMAIL_PHONE',
  USERNAME = 'USERNAME',
  PASSWORD = 'PASSWORD',
  OTP = 'OTP',
  NEW_PASSWORD = 'NEW_PASSWORD',
  RESET_PIN = 'RESET_PIN',
  FACEMAP = 'FACEMAP',
  HOME = 'HOME',
  OTP_EMAIL = 'OTP_EMAIL',
}
