import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[preventDoubleClick]',
})
export class PreventDoubleClickDirective implements OnDestroy {
  @Input() throttleTime = 700;
  @Output() throttledClick = new EventEmitter();

  private clicks = new Subject();
  private subscription = new Subscription();

  constructor() {
    this.subscription.add(
      this.clicks.pipe(throttleTime(this.throttleTime)).subscribe(e => this.throttledClick.emit(e)),
    );
  }

  @HostListener('click', ['$event'])
  clickEvent = (event): void => {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
