import split from 'lodash-es/split';
import { Constants } from './constants';

export class AmountUtility {
  /*  */
  public static amountSplit(value, splitBy): any {
    const pattern = Constants.patterns.split;
    return value.replace(pattern, splitBy);
  }
  public static amountOnly(value): any {
    const pattern = Constants.patterns.amountOnly;
    return value.replace(pattern, '');
  }
  public static removeDecimal(value): any {
    const pattern = Constants.patterns.removeDecimal;
    return value.replace(pattern, '');
  }

  public static splitAmount(amount: number): string[] {
    if (typeof amount === 'undefined' || typeof amount !== 'number') {
      return ['0'];
    }
    return split(amount?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$& '), '.');
  }
}
