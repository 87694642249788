import { Directive, Injector, OnInit } from '@angular/core';
import {
  BrowserStorageService,
  CommonComponent,
  CommonUtility,
  ResponsiveService,
  ResponsiveType,
} from '@wakanda/wakanda-core';
import { AvoBusinessFacade, ModalService, UrlRouter } from '@wakanda/avo-business-core';
import { BusinessChoiceType, IContactSupportRequest, IMenuItem } from '@wakanda/avo-business-model';
import { TranslateFacade } from '@wakanda/zentity-translate-store';
import { AdobeService } from '@wakanda/adobe-analytics';

export enum HeaderMenuVisibility {
  OPEN = 'OPEN',
  HIDDEN = 'HIDDEN',
}

@Directive()
export class LayoutBaseContainer extends CommonComponent implements OnInit {
  menuVisibility = HeaderMenuVisibility;
  screenType: ResponsiveType;
  routes = UrlRouter.urls;
  //
  responsiveService: ResponsiveService;
  modalService: ModalService;
  localStorageService: BrowserStorageService;
  translateFacade: TranslateFacade;
  avoBusinessFacade: AvoBusinessFacade;
  adobeService: AdobeService;
  //
  sellingOnAvoMenu: IMenuItem[] = [
    {
      title: 'merchant.pre_onboarding.sell_to_consumers',
      link: UrlRouter.urls.b2c.landing,
      subitems: [
        /*{
          title: 'merchant.pre_onboarding.digital_malls',
          link: UrlRouter.urls.b2c.digitalMall,
        },*/
        {
          title: 'merchant.pre_onboarding.restaurants',
          link: UrlRouter.urls.b2c.takeaways,
        },
        {
          title: 'merchant.pre_onboarding.liquor_stores',
          link: UrlRouter.urls.b2c.liquors,
        },
        {
          title: 'merchant.pre_onboarding.online_shops',
          link: UrlRouter.urls.b2c.onlineShops,
        },
      ],
    },
    {
      title: 'merchant.pre_onboarding.sell_to_business',
      link: UrlRouter.urls.b2b.landing,
    },
  ];

  constructor(injector: Injector) {
    super(injector);
    this.responsiveService = injector.get(ResponsiveService);
    this.modalService = injector.get(ModalService);
    this.localStorageService = injector.get(BrowserStorageService);
    this.translateFacade = injector.get(TranslateFacade);
    this.avoBusinessFacade = injector.get(AvoBusinessFacade);
    this.adobeService = injector.get(AdobeService);
    this.title.setTitle('Avo Business');
  }

  navigateToHome = (): void => {
    this.navigateTo(UrlRouter.urls.landing);
  };

  navigateToB2c = (): void => {
    this.navigateTo(UrlRouter.urls.b2c.landing);
  };

  navigateToB2b = (): void => {
    this.navigateTo(UrlRouter.urls.b2b.landing);
  };

  navigateToBusinessBuying = (): void => {
    this.navigateTo(UrlRouter.urls.businessBuying.landing);
  };

  navigateToB2BCapital = (): void => {
    this.navigateTo(UrlRouter.urls.capital);
  };

  navigateToSupport = (): void => {
    this.navigateTo(UrlRouter.urls.support);
  };

  openTandCs = (): void => {
    window.open('/assets/pdfs/Avo_Business_T&C_3Feb.pdf', '_blank');
  };

  openCampaignTandCs = (): void => {
    window.open('/assets/pdfs/Avo_B2B_Spend_Incentive_Promotion_T&Cs.pdf', '_blank');
  };

  navigateToDonations = (): void => {
    this.navigateTo(UrlRouter.urls.donations);
  };

  navigateToMarketPlace = (): void => {
    const env = CommonUtility.getEnvironment();
    const loginUrl = 'https://' + (env === 'prod' ? '' : env + '-') + 'merchant.avo.africa/shopping';
    window.open(loginUrl, '_blank');
  };

  navigateToLogin = (): void => {
    this.adobeService.executeAdobeClickEvent('homepage: login');
    const env = CommonUtility.getEnvironment();
    const loginUrl = 'https://' + (env === 'prod' ? '' : env + '-') + 'merchant.avo.africa/login/signin';
    window.open(loginUrl, '_blank');
  };

  openRegisterModal = (): void => {
    this.adobeService.executeAdobeClickEvent('homepage: registration');
    this.modalService.showRegisterModal(true);
  };

  onRegister = (businessType?: BusinessChoiceType): void => {
    this.adobeService.executeAdobeClickEvent('registration: continue');
    this.modalService.showRegisterModal(false);
    const env = CommonUtility.getEnvironment();
    let loginUrl = 'https://' + (env === 'prod' ? '' : env + '-') + 'merchant.avo.africa/login/credentials';
    if (businessType) loginUrl = loginUrl + '?journey=' + businessType;
    window.open(loginUrl, '_blank');
  };

  onSendContactEmail = (request: IContactSupportRequest): void => {
    this.avoBusinessFacade.sendContactSupportEmail(request, () => {
      this.modalService.showSupportModal(false);
    });
  };

  ngOnInit(): void {
    this.responsiveService.checkWidth();
  }

  scrollToTop = (): void => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 500);
  };

  getTranslatedKey = (key: string): string => {
    let result: string;
    this.translateFacade.getTranslateByKey$(key).subscribe(translatedKey => (result = translatedKey));
    return result;
  };
}
