import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { started } from '@wakanda/wakanda-core';
import { v4 as uuidv4 } from 'uuid';
import * as actions from './avo-business.actions';
import { IContactSupportRequest } from '@wakanda/avo-business-model';

@Injectable()
export class AvoBusinessFacade {
  constructor(private store: Store) {}

  sendContactSupportEmail = (request: IContactSupportRequest, onSucceed?: (response: any) => any): void => {
    const taskId = `send-contact-support-email-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.AvoBusinessSendContactEmailRequestAction(taskId, request, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };
}
