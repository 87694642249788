import { Pipe, PipeTransform } from '@angular/core';
import round from 'lodash-es/round';

@Pipe({
  name: 'roundNumber',
})
export class RoundNumberPipe implements PipeTransform {
  public transform(value: number, precision = 2): number {
    if (!value) return null;
    return round(value, precision);
  }
}
