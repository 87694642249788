<ng-container *ngIf="images?.length < 1">
  <h3>{{ title }}</h3>
  <ui-divider [compact]="true"></ui-divider>
  <wakanda-image-upload
    [merchant]="merchant"
    [large]="large"
    (onChange)="onFileSelected($event)"
  ></wakanda-image-upload>
</ng-container>
<ng-container *ngIf="images?.length > 0">
  <div class="horizontal-content">
    <wakanda-image-upload
      [merchant]="merchant"
      [large]="large"
      (onChange)="onFileSelected($event)"
    ></wakanda-image-upload>

    <ui-divider [vertical]="true" [compact]="true"></ui-divider>

    <ng-container *ngFor="let image of images; let i = index">
      <ui-image-preview [image]="image" (onDeleteClick)="onDeleteFile($event)"></ui-image-preview>
      <ui-divider *ngIf="i + 1 < images?.length" [vertical]="true"></ui-divider>
    </ng-container>
  </div>
</ng-container>
