import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { HeaderMenuVisibility, LayoutBaseContainer } from '../layout.base.container';

@Component({
  selector: 'wakanda-avo-business-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  animations: [
    trigger('toggle', [
      state(HeaderMenuVisibility.HIDDEN, style({ right: '-100vw', left: '100vw' })),
      state(HeaderMenuVisibility.OPEN, style({ right: '0', left: '2.5rem' })),
      transition(
        `${HeaderMenuVisibility.HIDDEN} => ${HeaderMenuVisibility.OPEN}`,
        animate('170ms cubic-bezier(.4,.67,.54,.83)'),
      ),
    ]),
  ],
})
export class SidemenuComponent extends LayoutBaseContainer {
  @Input() isOpen: HeaderMenuVisibility;
  @Output() closeMenu = new EventEmitter();

  constructor(injector: Injector) {
    super(injector);
  }

  handleClose = (callback?: () => void) => {
    this.closeMenu.emit();
    if (callback) callback();
  };

  onMenuItemClick(link: string): void {
    this.navigateTo(link);
    this.handleClose();
  }
}
