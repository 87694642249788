import { Action } from '@ngrx/store';
import * as EVENT from './assets.events';
import { AssetsBucketsEnum, IAssetDMZUploadInfo, IAssetStatusResponse } from '../../models/assets.model';

// upload asset
export class AssetsUploadFileRequestAction implements Action {
  readonly type = EVENT.ASSETS_UPLOAD_FILE_REQUEST;
  constructor(
    public taskId: string,
    public data: FormData,
    public bucket: AssetsBucketsEnum,
    public onSucceed?: (response: IAssetStatusResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class AssetsUploadFileResponseAction implements Action {
  readonly type = EVENT.ASSETS_UPLOAD_FILE_RECEIVED;
  constructor(public taskId: string, public response: IAssetDMZUploadInfo) {}
}

// upload asset with progress
export class AssetsUploadFileProgressRequestAction implements Action {
  readonly type = EVENT.ASSETS_UPLOAD_FILE_PROGRESS_REQUEST;
  constructor(
    public taskId: string,
    public data: FormData,
    public bucket: AssetsBucketsEnum,
    public onSucceed: (response: IAssetStatusResponse) => void,
    public onError?: (error: any) => void,
    public onProgress?: (percentage: number) => void,
  ) {}
}

export class AssetsUploadFileProgressReceivedAction implements Action {
  readonly type = EVENT.ASSETS_UPLOAD_FILE_PROGRESS_RECEIVED;
  constructor(public taskId: string, public response: IAssetDMZUploadInfo) {}
}

// file status
export class AssetStatusFileRequestAction implements Action {
  readonly type = EVENT.ASSETS_STATUS_FILE_REQUEST;
  constructor(
    public taskId: string,
    public id: string,
    public bucket: AssetsBucketsEnum,
    public onSucceed: (response: IAssetStatusResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class AssetStatusFileResponseAction implements Action {
  readonly type = EVENT.ASSETS_STATUS_FILE_RECEIVED;
  constructor(public taskId: string, public response: IAssetStatusResponse) {}
}

// Failed
export class AssetsFailed implements Action {
  readonly type = EVENT.ASSETS_FAILED;
  constructor(public taskId: string, public error: any, public hidden?: boolean) {}
}

export type AssetsActions =
  | AssetsUploadFileRequestAction
  | AssetsUploadFileResponseAction
  | AssetsUploadFileProgressRequestAction
  | AssetsUploadFileProgressReceivedAction
  | AssetStatusFileRequestAction
  | AssetStatusFileResponseAction;
