import { Action } from '@ngrx/store';
import {
  IShoppingBuyerCart,
  IShoppingBuyerCartDetail,
  IShoppingBuyerCartPaymentDetails,
  IShoppingBuyerCartSummary,
  IShoppingBuyPickupLocationResponse,
  IShoppingBuyPromoResponse,
  IShoppingCart,
  IShoppingDeliveryInfoResponse,
  IShoppingFulfillmentDeliveryOptions,
  IShoppingOrderDetailResponse,
  IShoppingOrderPaymentResponse,
  IShoppingPaymentInfoResponse,
  IShoppingPayWalletResponse,
  IShoppingProductCards,
  IShoppingReserveInfoResponse,
  IUrlInfoResponse,
} from '../../models/shopping.model';
import { IPayByCardResponse } from '../../models/wallet.model';
import { ShoppingBuyActions } from './shopping-buy.actions';
import * as EVENT from './shopping-buy.events';

export const SHOPPING_BUY_FEATURE_KEY = 'shoppingBuy';

export interface IShoppingBuyState {
  cart?: IShoppingCart;
  promoCodes?: IShoppingBuyPromoResponse[];
  pickupLocations?: IShoppingBuyPickupLocationResponse[];
  deliveryInfo?: IShoppingDeliveryInfoResponse;
  reserveInfo?: IShoppingReserveInfoResponse;
  returnPolicyUrl?: IUrlInfoResponse;
  termsAndConditionsUrl?: IUrlInfoResponse;
  paymentReservationUrl?: IUrlInfoResponse;
  deliveryOptions?: IShoppingFulfillmentDeliveryOptions[];
  savedItems?: IShoppingProductCards[];
  payByWalletResponse?: IShoppingPayWalletResponse;
  payByCardResponse?: IPayByCardResponse;
  placedOrder?: IShoppingOrderDetailResponse;
  orderInfo?: IShoppingOrderPaymentResponse;
  paymentInfo?: IShoppingPaymentInfoResponse;

  cartSummary?: IShoppingBuyerCartSummary;
  carts?: IShoppingBuyerCart[];
  cartDetail?: IShoppingBuyerCartDetail;
  cartPaymentDetails?: IShoppingBuyerCartPaymentDetails;
}

export interface IShoppingCartPartialState {
  readonly [SHOPPING_BUY_FEATURE_KEY]: IShoppingBuyState;
}

export function shoppingBuyReducer(state: IShoppingBuyState = {}, actions: Action): IShoppingBuyState {
  const action = actions as ShoppingBuyActions;
  switch (action.type) {
    case EVENT.BUY_CART_APPLY_PROMO_RECEIVED:
    case EVENT.BUY_CART_REMOVE_PROMO_RECEIVED:
    case EVENT.BUY_CART_UPDATE_CART_ITEM_RECEIVED:
    case EVENT.BUY_CART_UPDATE_SAVED_CART_ITEM_RECEIVED:
    case EVENT.BUY_CART_DELETE_CART_ITEM_RECEIVED:
    case EVENT.ADD_TV_LICENCE_PHOTO_ID_SUCCEEDED:
    case EVENT.BUY_CART_CONTENT_RECEIVED:
    case EVENT.BUY_CART_SET_TERM_IN_MONTHS_RECEIVED: {
      return Object.assign({}, state, {
        cart: {
          ...action.data,
          selectedLoanTermRepayment:
            action.data?.loanTerms?.availableMonthlyRepayments?.find(
              term => term.termInMonths === action.data.loanTerms.selectedTermInMonths,
            ) ?? undefined,
        },
      });
    }

    case EVENT.BUYER_CART_SUMMARY_RECEIVED:
    case EVENT.BUYER_CART_ADD_CART_ITEM_RECEIVED:
      return Object.assign({}, state, {
        cartSummary: {
          ...action.data,
        },
      });

    case EVENT.BUYER_CART_DELIVERY_OPTION_RECEIVED:
    case EVENT.BUYER_CART_DELIVERY_ADDRESS_RECEIVED:
    case EVENT.BUYER_CART_ITEM_QUANTITY_RECEIVED:
    case EVENT.BUYER_CART_DETAIL_RECEIVED:
      return Object.assign({}, state, { cartDetail: action.data });

    case EVENT.BUYER_CARTS_RECEIVED:
      return Object.assign({}, state, { carts: action.data });

    case EVENT.BUYER_PAY_CART_RECEIVED:
      return Object.assign({}, state, { cartPaymentDetails: action.data });

    case EVENT.BUY_CART_GET_PROMO_RECEIVED:
      return Object.assign({}, state, { promoCodes: action.data });

    case EVENT.BUY_CART_GET_PICKUP_LOCATION_RECEIVED:
      return Object.assign({}, state, { pickupLocations: action.data });

    case EVENT.BUY_CART_GET_DELIVER_LOCATION_RECEIVED:
      return Object.assign({}, state, { deliveryLocations: action.data });

    case EVENT.BUY_CART_DELIVERY_INFO_RECEIVED:
      return Object.assign({}, state, { cart: action.data });

    case EVENT.BUY_CART_PAY_BY_WALLET_RECEIVED:
      return Object.assign({}, state, { payByWalletResponse: action.data });

    case EVENT.BUY_CART_PAY_BY_CARD_RECEIVED:
      return Object.assign({}, state, { payByCardResponse: action.data });

    case EVENT.BUY_CART_CREATE_ORDER_RECEIVED:
      return Object.assign({}, state, { placedOrder: action.data });

    case EVENT.BUY_CART_ORDER_INFO_RECEIVED:
      return Object.assign({}, state, { orderInfo: action.data });

    case EVENT.BUY_CART_ORDER_DETAIL_RECEIVED:
      return Object.assign({}, state, { placedOrder: action.data });

    case EVENT.BUY_CART_PAYMENT_INFO_RECEIVED:
      return Object.assign({}, state, { paymentInfo: action.data });

    case EVENT.BUY_CART_SAVE_CART_ITEM_RECEIVED:
    case EVENT.BUY_CART_MOVE_CART_ITEM_RECEIVED:
    case EVENT.BUY_CART_DELETE_SAVED_CART_ITEM_RECEIVED:
    case EVENT.BUY_CART_SAVED_ITEMS_RECEIVED: {
      return Object.assign({}, state, { savedItems: action.data });
    }

    case EVENT.BUY_CART_RESERVE_INFO_RECEIVED:
      return Object.assign({}, state, { reserveInfo: action.data });

    case EVENT.BUY_CART_SET_BILLING_ADDRESS:
      return Object.assign({}, state, { billingAddress: action.data });

    case EVENT.BUY_CART_RETURN_POLICY_INFO_RECEIVED:
      return Object.assign({}, state, { returnPolicyUrl: action.data });

    case EVENT.BUY_CART_TERMS_AND_CONDITIONS_INFO_RECEIVED:
      return Object.assign({}, state, { termsAndConditionsUrl: action.data });

    case EVENT.BUY_CART_PAYMENT_RESERVATION_INFO_RECEIVED:
      return Object.assign({}, state, { paymentReservationUrl: action.data });

    case EVENT.BUY_CART_DELIVERY_OPTIONS_RECEIVED:
      return Object.assign({}, state, { deliveryOptions: action.data });

    case EVENT.BUY_CART_CLEAN:
      return Object.assign({}, state, { cart: null, cartDetail: null });

    case EVENT.BUY_CART_CLEAR_RECEIVED:
      return Object.assign({}, state, { cart: action.data });

    default:
      return state;
  }
}
