import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-table-header-cell',
  template: `
    <div class="table-header-cell" [ngClass]="{ sortable: !!sortable }" (click)="headerSelected()">
      <ng-container *ngIf="!checkbox">
        {{ content }}
        <span class="icon-wrapper">
          <ng-container *ngIf="isActive">
            <ui-icon [icon]="sortAsc ? 'table_up' : 'table_down'" size=".75rem"></ui-icon>
          </ng-container>
        </span>
      </ng-container>
      <ng-container *ngIf="checkbox">
        <ui-checkbox [rounded]="true" [checked]="isChecked" (onChange)="onClick.emit(checkbox)"></ui-checkbox>
      </ng-container>
    </div>
  `,
})
export class TableHeaderCellComponent {
  @Input() content: string;
  @Input() checkbox: boolean;
  @Input() isActive: boolean;
  @Input() sortAsc: boolean;
  @Input() isChecked: boolean;
  @Input() sortable: boolean;

  @Output() onClick = new EventEmitter<boolean>();

  headerSelected(): void {
    if (this.sortable) {
      this.onClick.emit(this.checkbox);
    }
  }
}
