import { Action } from '@ngrx/store';
import { ITranslateReducerState } from '../model/translate.model';
import { TranslateAction } from './translate.actions';
import { EVENT } from './translate.events';

export const TRANSLATE_FEATURE_KEY = 'translate2';

export interface ITranslatePartialState {
  readonly [TRANSLATE_FEATURE_KEY]: ITranslateReducerState;
}

export function translateReducer(state: ITranslateReducerState = {}, action: Action): ITranslateReducerState {
  const translateAction = action as TranslateAction;
  if (translateAction.type === EVENT.RECEIVED) return Object.assign({}, state, { data: translateAction.payload });
  if (translateAction.type === EVENT.RECEIVED_NEW) {
    return Object.assign({}, state, { data: { ...state.data, ...translateAction.payload } });
  } else return state;
}
