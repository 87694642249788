import { image } from './image';
import { icon } from './icon';

export class Assets {
  /** Generated icon object from DSM (run in console: npm run generate:icon) */
  public static icon = icon;

  /** Static images object (built manually) */
  public static image = image;
}

export const getIconListRecursively = (list: object): string[] =>
  Object.values(list).reduce(
    (acc, item) => {
      if (typeof item === 'object') {
        return [...acc, ...getIconListRecursively(item)];
      } else {
        return [...acc, item];
      }
    },
    [''],
  );
