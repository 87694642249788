import { Pipe, PipeTransform } from '@angular/core';
import { IWalletCheckPayResponse, WalletTopupType } from '@wakanda/wakanda-core';

export interface ITopupOption {
  id: string;
  label: string;
  type: WalletTopupType;
}

@Pipe({ name: 'getAccountsAndCards', pure: true })
export class AccountsAndCardsPipe implements PipeTransform {
  transform = (payCheckResponse: IWalletCheckPayResponse): ITopupOption[] => {
    const listOfSources: ITopupOption[] = [];
    if (!payCheckResponse) {
      return listOfSources;
    }
    if (payCheckResponse.topUpCards) {
      const cards = payCheckResponse.topUpCards.map(card => ({
        id: card.cardId,
        label: `${card.cardName} (**** **** **** ${card.cardLast4})`,
        type: WalletTopupType.CARD,
      }));
      listOfSources.push(...cards);
    }
    if (payCheckResponse.topUpAccounts) {
      const accounts = payCheckResponse.topUpAccounts.map(account => ({
        id: account.sourceAccountId,
        label: `${account.accountNumber}`,
        type: WalletTopupType.ACCOUNT,
      }));
      listOfSources.push(...accounts);
    }

    return listOfSources;
  };
}
