import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, of, switchMap } from 'rxjs';
import * as TransactionsActions from './transactions.actions';
import { ITaskAction, TaskType } from '../models/store.models';
import { TransactionSigningService } from '../../services/transaction-signing.service';
import get from 'lodash-es/get';
import { TasksFacade } from '../tasks/tasks.facade';
import { httpErrorAction } from '../application/application.actions';

@Injectable()
export class TransactionsEffects {
  transactionSigningStart = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionsActions.transactionSigningStartRequestAction),
      mergeMap(({ transactionAuthId, taskMetadata, onSucceed }) =>
        this.transactionSigningService.startTransaction(transactionAuthId).pipe(
          map(data => {
            if (data.authorized) {
              onSucceed({ signingToken: data.signingToken, transactionAuthId });
            }
            return TransactionsActions.transactionSigningStartResponseAction({ data });
          }),
          catchError(error => {
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  transactionSigningAuthorize = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionsActions.transactionSigningAuthorizeRequestAction),
      switchMap(({ taskMetadata, payload: { transactionAuthId, authRequest, onSucceed, onData, onFailure } }) =>
        this.transactionSigningService.authorizeTransaction(transactionAuthId, authRequest).pipe(
          map(data => {
            if (data.authorized) {
              onSucceed({ signingToken: data.signingToken, transactionAuthId });
            } else {
              if (onData) onData(data);
            }
            return TransactionsActions.transactionSigningAuthorizeResponseAction({ data });
          }),
          catchError(error => {
            if (onFailure) onFailure(error);
            const errorCode = get(error, ['error', 'error', 'code']);
            const errorMessage = get(error, ['error', 'error', 'messageCode']);
            if (errorCode === 'simswap_error_blocked' || errorMessage === 'error.onboarding.simswap') {
              return of(TransactionsActions.transactionSigningAuthorizeResponseAction({ data: null }));
            }
            const otpHandledErrorCodes = [
              'otp_wrong_code',
              'otp_wrong_code_too_many_attempts',
              'otp_expired_new_sms_sent',
              'otp_failed',
            ];
            const taskType = otpHandledErrorCodes.includes(errorCode) ? TaskType.FAILED_SILENT : TaskType.FAILED;
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: taskType },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  transactionSigningCancel = createEffect(() =>
    this.actions$.pipe(
      ofType(TransactionsActions.transactionSigningCancelRequestAction),
      mergeMap(({ transactionAuthId, onSucceed, taskMetadata }) =>
        this.transactionSigningService.cancelTransaction(transactionAuthId).pipe(
          map(() => {
            onSucceed();
            return TransactionsActions.transactionSigningCancelResponseAction({
              taskMetadata: { type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );

  private readonly DEFAULT_TITLE = 'Avo';

  constructor(
    private readonly actions$: Actions<ITaskAction>,
    private tasksFacade: TasksFacade,
    private transactionSigningService: TransactionSigningService,
  ) {}
}
