import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limit',
})
export class LimitPipe implements PipeTransform {
  transform<T>(value: T[], count: number): T[] {
    if (!count) return value;
    return value?.slice(0, count);
  }
}
