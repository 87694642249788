import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wakanda-password-validator',
  templateUrl: './password-validator.component.html',
})
export class PasswordValidatorComponent {
  @Input() control: UntypedFormControl;
}
