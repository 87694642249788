import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ApplicationEventTypes, ApplicationFacade, ITaskAction } from '@wakanda/wakanda-core';
import { Subscription } from 'rxjs';
import { FadeInShortAnimation } from '../../utils/animations';

@Component({
  selector: 'ui-tasks-handler',
  template: `
    <ng-container *ngIf="runningTasks?.length > 0 && isSpinnerVisible">
      <div
        class="absolute z-20 bg-background-grey left-0 top-0 w-full h-full flex justify-center items-center"
        *ngIf="isAvo3; else avo2SpinnerTemplate"
      >
        <ui-spinner></ui-spinner>
      </div>
      <ng-template #avo2SpinnerTemplate>
        <ui-modal [spinner]="true" [isVisible]="true" [agentChat]="agentChat" [tasks]="runningTasks"></ui-modal>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="tasks?.length > 0">
      <ng-container *ngFor="let task of tasks">
        <ng-container [ngSwitch]="task[type.prefix]?.type">
          <ui-failure-dialog
            *ngSwitchCase="type.FAIL"
            [merchant]="merchant"
            [agentChat]="agentChat"
            [isAvo3]="isAvo3"
            [task]="task"
            [taskId]="task?.taskId"
            (onClose)="navigate($event.errorCode, $event.taskId)"
            (onHandleError)="navigate($event, task?.taskId)"
          ></ui-failure-dialog>
          <ui-push-notification
            *ngSwitchCase="type.PUSH_NOTIFICATION"
            [text]="task?.text"
            [taskId]="task?.taskId"
            (onEventClean)="facade.onEventClean($event)"
            @fadeInShort
            [@.disabled]="!merchant"
          ></ui-push-notification>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-content></ng-content>
  `,
  animations: [FadeInShortAnimation],
})
export class TasksHandlerComponent implements OnDestroy {
  type = ApplicationEventTypes;
  //
  tasks: ITaskAction[];
  runningTasks: ITaskAction[];
  //
  subscription = new Subscription();
  //
  @Input() agentChat: boolean;
  @Input() merchant: boolean;

  // todo(lukas.jurygacek): remove when not needed
  @Input() isAvo3 = false;
  @Input() isSpinnerVisible = true;

  @Output() onHandleError = new EventEmitter<string>();

  constructor(public facade: ApplicationFacade) {
    this.subscription.add(this.facade.getApplicationTasks$.subscribe(data => (this.tasks = data)));
    this.subscription.add(this.facade.getIsApplicationLoading$.subscribe(data => (this.runningTasks = data)));
  }

  navigate = (errorCode: string, taskId: string): void => {
    this.facade.onEventClean(taskId);
    this.onHandleError.emit(errorCode);
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
