import { Component, Injector, Input } from '@angular/core';
import { CommonComponent } from '@wakanda/wakanda-core';

@Component({
  selector: 'wakanda-avo-business-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss'],
})
export class BusinessCardComponent extends CommonComponent {
  @Input() imageUrl: string;
  @Input() primaryText: string;
  @Input() secondaryText: string;

  constructor(injector: Injector) {
    super(injector);
  }
}
