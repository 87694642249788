import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'wakanda-change-address-modal',
  templateUrl: './change-address-modal.component.html',
  styleUrls: ['./change-address-modal.component.scss'],
})
export class ChangeAddressModalComponent {
  @Input() addressId: string;
  @Output() onClose = new EventEmitter();
  @Output() onUpdateAddress = new EventEmitter<string>();
  ModalType = ModalType;
}
