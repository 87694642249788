import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  ChatSendMessageId,
  IChatHistoryRoom,
  IChatSendMessage,
  IChatSendMessageInner,
  IWalletCheckPayResponse,
  started,
} from '@wakanda/wakanda-core';
import { v4 as uuidv4 } from 'uuid';
import {
  IChatAttachmentRequest,
  IChatGetMessagesRequest,
  IChatHeyNedEnabledCheckResponse,
  IChatHeyNedRoom,
  IChatHeyNedRoomRequest,
  IChatHeyNedSubscriptionCheckResponse,
  IChatRoomId,
  IChatSendEventRequest,
} from '../model/chat.model';
import * as actions from './chat.actions';
import { IChatPartialState } from './chat.reducer';
import * as selector from './chat.selectors';

@Injectable({ providedIn: 'root' })
export class ChatFacade {
  getActiveRoom$ = this.store.pipe(select(selector.getActiveRoom));
  getActiveRoomMessages$ = this.store.pipe(select(selector.getActiveRoomMessages));
  getHeyNedRoom$ = this.store.pipe(select(selector.getHeyNedRoom));
  getHeyNedSubscriptionCheck$ = this.store.pipe(select(selector.getHeyNedSubscriptionCheck));
  getHeyNedEnabled$ = this.store.pipe(select(selector.getHeyNedEnabled));
  getChatHistoryOngoing$ = this.store.pipe(select(selector.getChatHistoryOngoing));
  getChatHistoryCompleted$ = this.store.pipe(select(selector.getChatHistoryCompleted));
  getChatHistorySearchResults$ = this.store.pipe(select(selector.getChatHistorySearchResults));
  getEventSent$ = this.store.pipe(select(selector.getEventSent));
  getHeyNedOriginRoomId$ = this.store.pipe(select(selector.getHeyNedOriginRoomId));

  constructor(private store: Store<IChatPartialState>) {}

  sendMessage = (messageInner: IChatSendMessageInner): void => {
    const taskId = `send-websocket-message-${uuidv4()}`;

    const message: IChatSendMessage = {
      id: ChatSendMessageId.SENDMESSAGE,
      message: messageInner,
    };

    this.store.dispatch(
      started(new actions.SendWebSocketMessageRequest(taskId, message), 'Please wait a moment', true),
    );
  };

  sendAttachment = (roomId: string, request: IChatAttachmentRequest): void => {
    const taskId = `send-websocket-attachment-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.SendWebSocketAttachmentRequest(taskId, roomId, request), 'Please wait a moment', true),
    );
  };

  sendPayment = (roomId: string, paymentId: string, usedPoints: number, silently = false): void => {
    const taskId = `send-payment-${paymentId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ChatSendPaymentRequestAction(taskId, roomId, paymentId, usedPoints),
        'Please wait a moment',
        silently,
      ),
    );
  };

  fetchPayCheck = (
    roomId: string,
    paymentId: string,
    onSucceed: (response: IWalletCheckPayResponse) => void,
    silently = false,
  ): void => {
    const taskId = `fetck-paycheck-${roomId}-${paymentId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ChatFetchPayCheckRequestAction(taskId, roomId, paymentId, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  fetchRoomById = (roomId: string): void => {
    const taskId = `fetch-room-${roomId}-${uuidv4()}`;
    this.store.dispatch(new actions.GetChatRoomRequestAction(taskId, roomId));
  };

  fetchSupportChat = (onSucceed?: (response: IChatRoomId) => void, silently = false): void => {
    const taskId = `fetch-support-chat-roomId-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.GetChatRoomIdForSupportRequestAction(taskId, onSucceed), 'Please wait a moment', silently),
    );
  };

  fetchConciergeChat = (onSucceed?: (response: IChatRoomId) => void): void => {
    const taskId = `fetch-concierge-chat-roomId-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.GetChatRoomIdForConciergeRequestAction(taskId, onSucceed), 'Please wait a moment'),
    );
  };

  createConciergeChat = (onSucceed?: (response: IChatRoomId) => void): void => {
    const taskId = `create-concierge-chat-roomId-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.CreateChatRoomIdForConciergeRequestAction(taskId, onSucceed), 'Please wait a moment'),
    );
  };

  fetchJobAndMerchantChatRoomId = (jobId: string, merchantId: string, onSucceed: (data: IChatRoomId) => void): void => {
    const taskId = `fetch-job-and-chat-merchant-chat-room-id-${jobId}-${merchantId}`;
    this.store.dispatch(
      started(
        new actions.GetChatRoomIdForJobAndMerchantRequestAction(taskId, jobId, merchantId, onSucceed),
        'Fetching room',
      ),
    );
  };

  sendEvent = (request: IChatSendEventRequest): void => {
    const taskId = `send_event-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(started(new actions.ChatSendEventRequestAction(taskId, request), 'Please wait a moment', true));
  };

  fetchMessagesByRoomId = (request: IChatGetMessagesRequest): void => {
    const taskId = `fetch-messages-${request}`;
    this.store.dispatch(started(new actions.FetchMessagesByRoomIdRequest(taskId, request), 'Fetching messages', true));
  };

  uploadImage = (image: any, onSucceed: (response: any) => void): void => {
    const taskId = `upload-image-${uuidv4()}`;
    this.store.dispatch(started(new actions.ChatUploadImageRequestAction(taskId, image, onSucceed)));
  };

  createHeyNedRoom = (request: IChatHeyNedRoomRequest, onSucceed: (response: IChatHeyNedRoom) => void): void => {
    const taskId = `create-heyned-room-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(started(new actions.ChatCreateHeyNedRoomRequestAction(taskId, request, onSucceed), '', true));
  };

  createHeyNedHealthRoom = (request: IChatHeyNedRoomRequest, onSucceed: (response: IChatHeyNedRoom) => void): void => {
    const taskId = `create-heyned-health-room-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ChatCreateHeyNedHealthRoomRequestAction(taskId, request, onSucceed), '', true),
    );
  };

  heyNedOpenedTask = (onSucceed: (data: IChatHeyNedRoom) => void): void => {
    const taskId = `heyned-opened-task-${uuidv4()}`;
    this.store.dispatch(started(new actions.HeyNedOpenedTaskRequestAction(taskId, onSucceed), '', true));
  };

  checkHeyNedSubscription = (onSucceed: (response: IChatHeyNedSubscriptionCheckResponse) => void): void => {
    const taskId = `check-heyned-subcsription-${uuidv4()}`;
    this.store.dispatch(started(new actions.ChatCheckHeyNedSubscriptionRequestAction(taskId, onSucceed)));
  };

  checkHeyNedEnabled = (onSucceed?: (response: IChatHeyNedEnabledCheckResponse) => void): void => {
    const taskId = `check-heyned-enabled-${uuidv4()}`;
    this.store.dispatch(started(new actions.ChatCheckHeyNedEnabledRequestAction(taskId, onSucceed)));
  };

  clearChatData = (): void => {
    this.store.dispatch(new actions.ChatClearDataAction());
  };

  fetchInfPaymentStatus = (jobId: string, quoteId: string, onSucceed: (response: any) => void): void => {
    const taskId = `fetching-inf-payment-status-for-${jobId}-${quoteId}`;
    this.store.dispatch(
      started(
        new actions.ChatGetInfPaymentStatusRequestAction(taskId, jobId, quoteId, onSucceed),
        'Fetching information merchant widget',
        true,
      ),
    );
  };

  fetchChatHistoryOngoing = (
    onSucceed: (response: IChatHistoryRoom[]) => void,
    silently: boolean,
    page: number,
    size: number,
  ): void => {
    const taskId = `fetch-chat-history-ongoing-${page}-${size}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ChatHistoryOngoingRequestAction(taskId, onSucceed, page, size), '', silently),
    );
  };

  fetchChatHistoryCompleted = (
    onSucceed: (response: IChatHistoryRoom[]) => void,
    silently: boolean,
    page: number,
    size: number,
  ): void => {
    const taskId = `fetch-chat-history-completed-${page}-${size}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ChatHistoryCompletedRequestAction(taskId, onSucceed, page, size), '', silently),
    );
  };

  fetchChatHistorySearch = (query: string, onSucceed: (response: IChatHistoryRoom[]) => void): void => {
    const taskId = `fetch-chat-history-search-${query}-${uuidv4()}`;
    this.store.dispatch(started(new actions.ChatHistorySearchRequestAction(taskId, query, onSucceed), '', true));
  };

  closeChat = (chatId: string, onSucceed: () => void): void => {
    const taskId = `close-chat-${chatId}-${uuidv4()}`;
    this.store.dispatch(started(new actions.ChatCloseChatRequestAction(taskId, chatId, onSucceed), '', true));
  };

  saveHeyNedOriginRoomId = (originRoomId: string): void => {
    const taskId = `save-heyned-origin-room-id-${originRoomId}-${uuidv4()}`;
    this.store.dispatch(new actions.ChatSaveHeyNedOriginRoomIdAction(taskId, originRoomId));
  };

  clearHeyNedOriginRoomId = (): void => {
    const taskId = `clear-heyned-origin-room-id-${uuidv4()}`;
    this.store.dispatch(new actions.ChatClearHeyNedOriginRoomIdAction(taskId));
  };
}
