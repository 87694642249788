import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPrepaidElectricityReducerState, PREPAID_ELECTRICITY_FEATURE_KEY } from './prepaid-electricity.reducer';

const state = createFeatureSelector<IPrepaidElectricityReducerState>(PREPAID_ELECTRICITY_FEATURE_KEY);

export const getRecent = createSelector(state, ({ recent }) => recent);

export const getIsMeterValidated = createSelector(state, ({ meterValidated }) => meterValidated);

export const getPlacedOrderElectricity = createSelector(state, ({ placedOrder }) => placedOrder);

export const getData = createSelector(state, ({ data }) => data);

export const getIsWakandaUser = createSelector(state, ({ wakandaUser }) => wakandaUser);
