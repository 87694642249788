import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AddressType,
  IAddressForm,
  IAddressLocation,
  ICommonOnChangeOutput,
  ILocationV3,
  IPosition,
  ISetLocationMapChange,
} from '@wakanda/wakanda-core';
import get from 'lodash-es/get';
import size from 'lodash-es/size';
import { GoogleMapBaseContainer } from '../google-map-base-container/google-map.base.container';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'ui-make-search-map',
  template: `
    <ng-container *ngIf="isMapsApiReady">
      <ui-select
        bindLabel="name"
        bindValue="id"
        [placeholder]="'customer.settings.locations.set.search_hint' | translate"
        [showAsMapSelect]="true"
        [isOpen]="isOpen"
        [autofocus]="!!query"
        [value]="query"
        [loading]="isLoading"
        [openOnFocus]="true"
        [options]="!!query ? searched : mapOptions"
        (onSelect)="onSelect($event)"
        (onChange)="onSearch($event.value)"
        (onClear)="onClear()"
        (onClick)="isOpen = true"
      ></ui-select>
    </ng-container>

    <div #makeGoogleMap class="ui-google-map"></div>

    <ng-container *ngIf="isMapsApiReady">
      <div *ngIf="!!query && !!selecetedItem" class="make-search-map content">
        <ui-button
          [callToActionButton]="true"
          [selectButton]="true"
          [content]="'customer.settings.locations.on_map.set_location_button' | translate"
          (onClick)="handleSubmit()"
        ></ui-button>
      </div>
    </ng-container>
  `,
})
export class MakeSearchMapComponent extends GoogleMapBaseContainer implements OnInit {
  selecetedItem: IAddressLocation;
  //
  map: google.maps.Map;
  dragEndMarker: google.maps.Marker;
  @ViewChild('makeGoogleMap', { static: true }) mapElement: ElementRef;
  //
  query: string;
  isSearching: boolean;
  mapOptions: IAddressLocation[];
  isOpen: boolean;
  //
  @Input() initialPosition: IPosition;
  @Input() initialZoom = 8;
  @Input() initialDragable = false;
  @Input() isAuthorized = true;
  @Input() showInitialBrowserPosition: boolean;
  @Input() isMerchant: boolean;
  @Input() presetCurrentLocation = true;
  @Input() countryCodes: string[];
  @Output() onSubmitButton = new EventEmitter<ILocationV3>();
  @Output() onManageLocations = new EventEmitter<void>();
  @Output() onChange = new EventEmitter<ISetLocationMapChange>();

  constructor(injector: Injector) {
    super(injector);
  }

  handleSubmit = (): void => {
    this.onSubmitButton.emit(this.selecetedItem);
  };

  private setPositionOnMap = (
    latitude: number,
    longitude: number,
    zoomNumber: number,
    isDraggable = true,
    icon = 'location_marker',
  ): void => {
    if (this.map) {
      this.map.setCenter({ lat: latitude, lng: longitude });
      if (zoomNumber) {
        this.map.setZoom(zoomNumber);
      }
    } else {
      this.createMap(latitude, longitude, zoomNumber);
    }

    if (this.dragEndMarker) {
      this.dragEndMarker.setPosition({ lat: latitude, lng: longitude });
      this.dragEndMarker.setDraggable(isDraggable);
    } else {
      this.createMarker(latitude, longitude, isDraggable, icon);
    }
  };

  private createMap(latitude: number, longitude: number, zoomNumber: number): void {
    const mapOptions = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: zoomNumber,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      draggable: true,
    };
    this.map = new google.maps.Map(get(this.mapElement, 'nativeElement'), mapOptions);
  }

  private createMarker(latitude: number, longitude: number, isDraggable: boolean, icon: string): void {
    const dragEndIcon: google.maps.ReadonlyIcon = {
      url: `assets/icons/${icon}.svg`,
      scaledSize: new google.maps.Size(36, 36),
      anchor: new google.maps.Point(18, 42),
    };

    const dragEndMarkerOptions: google.maps.ReadonlyMarkerOptions = {
      position: {
        lat: latitude,
        lng: longitude,
      },
      icon: dragEndIcon,
      map: this.map,
      clickable: true,
      draggable: isDraggable,
    };

    this.dragEndMarker = new google.maps.Marker(dragEndMarkerOptions);

    google.maps.event.addListener(this.dragEndMarker, 'dragend', this.onDragEnd);
  }

  private onDragEnd = (event: any): void => {
    const location = {
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    };
    this.locationFacade.findPlaceByPosition(location, locationForm => {
      this.handleSelectComponent(locationForm.addressString, false);
      this.setPositionOnMap(locationForm.latitude, locationForm.longitude, null, true, 'location2');
      this.onChange.emit({
        locationForm,
        id: 'dragEnd',
        type: AddressType.StaticUsingMap,
      });
    });
  };

  private setPositionToCurrentLocation = (item: IAddressLocation): void => {
    const onSucceed = (address: IAddressForm): void => {
      this.handleSelectComponent(address.addressString, false);
      this.setPositionOnMap(address.latitude, address.longitude, null, true, 'location2');
      this.onChange.emit({
        locationForm: address,
        id: item.id,
        type: item.type,
      });
    };

    if (!this.currentLocation) {
      this.locationFacade.fetchNearestLocation(
        {
          latitude: this.location.latitude,
          longitude: this.location.longitude,
        },
        onSucceed,
      );
      return;
    }

    this.locationFacade.findPlaceByPosition(this.currentLocation.location, onSucceed);
  };

  handleSelectComponent = (query: string, isOpen: boolean): void => {
    this.query = query;
    this.isOpen = isOpen;
  };

  onSearch = (value: string): void => {
    this.handleSelectComponent(value, true);

    if (!value || !size(value) || value === '') return;

    if (!this.isSearching) {
      this.isSearching = true;
      this.locationFacade.fetchSearchLocationByQuery(
        value,
        () => {
          this.isSearching = false;
        },
        this.countryCodes,
      );
    }
  };

  onSelect = (locationData: ICommonOnChangeOutput): void => {
    this.selecetedItem = get(locationData, 'item');
    if (!this.selecetedItem) return;

    // set location from existing
    if (this.selecetedItem.type === AddressType.CustomerLocation) {
      this.locationFacade.findPlaceByPosition(this.selecetedItem.location, locationForm => {
        this.setPositionOnMap(locationForm.latitude, locationForm.longitude, 18, true, 'location2');
        this.handleSelectComponent(locationForm.addressString, false);
        this.onChange.emit({
          locationForm: {
            ...locationForm,
            name: locationForm.name || this.selecetedItem.name || '',
          },
          id: this.selecetedItem.id,
          type: this.selecetedItem.type,
        });
        this.handleSubmit();
      });
      return;
    }

    // set location using maps
    if (this.selecetedItem.type === AddressType.StaticUsingMap) {
      if (!this.presetCurrentLocation) {
        if (this.currentLocation?.location) {
          this.showCurrentLocation();
        } else {
          this.showDefaultPosition();
        }
      }

      const location = {
        latitude: this.dragEndMarker.getPosition().lat(),
        longitude: this.dragEndMarker.getPosition().lng(),
      };
      this.locationFacade.findPlaceByPosition(location, locationForm => {
        this.setPositionOnMap(locationForm.latitude, locationForm.longitude, 18, true, 'location2');
        this.handleSelectComponent('Set location using maps', false);
        this.onChange.emit({
          locationForm: {
            ...locationForm,
            name: locationForm.name || this.selecetedItem.name || '',
          },
          id: this.selecetedItem.id,
          type: this.selecetedItem.type,
        });
      });
      return;
    }

    // current position
    if (this.selecetedItem.type === AddressType.StaticCurrentLocation) {
      this.setPositionToCurrentLocation(this.selecetedItem);
      return;
    }

    // manage your locations
    if (this.selecetedItem.type === AddressType.StaticManage) {
      this.onManageLocations.emit();
      return;
    }

    // show all locations
    if (this.selecetedItem.type === AddressType.SelectAll) {
      this.mapOptions = this.processAddresses(null, this.isMerchant);
      this.isOpen = true;
      return;
    }

    // on select from suggestions
    if (this.selecetedItem.type === AddressType.Search && !!get(this.selecetedItem, ['address', 'addressString'])) {
      this.handleSelectComponent(null, false);
      this.locationFacade.findPlaceByAddressString(get(this.selecetedItem, 'address.addressString'), locationForm => {
        this.setPositionOnMap(locationForm.latitude, locationForm.longitude, 18, true, 'location2');
        this.handleSelectComponent(locationForm.addressString, false);
        this.onChange.emit({
          locationForm: {
            ...locationForm,
            name: locationForm.name || this.selecetedItem.name || '',
          },
          id: this.selecetedItem.id,
          type: this.selecetedItem.type,
        });
        this.handleSubmit();
      });
    }
  };

  onClear = (): void => {
    this.handleSelectComponent(null, true);
    this.mapOptions = this.processAddresses(3, this.isMerchant, this.isAuthorized);
    this.onChange.emit();
  };

  showInitialPosition(): void {
    this.setPositionOnMap(
      this.initialPosition.latitude,
      this.initialPosition.longitude,
      this.initialZoom,
      this.initialDragable,
      'location2',
    );
    this.locationFacade.findPlaceByPosition(this.initialPosition, (data: IAddressForm) => {
      this.query = data.addressString;
    });
  }

  showBrowserPosition(): void {
    this.getBrowserPosition((latitude: number, longitude: number): void => {
      this.setPositionOnMap(latitude, longitude, this.initialZoom, this.initialDragable, 'location2');
      this.locationFacade.findPlaceByPosition({ latitude, longitude }, (data: IAddressForm) => {
        this.query = data.addressString;
      });
    });
  }

  showCurrentLocation(): void {
    this.setPositionOnMap(
      this.currentLocation.location.latitude,
      this.currentLocation.location.longitude,
      this.initialZoom,
      this.initialDragable,
      'location2',
    );
    this.query = this.currentLocation.address.addressString;
  }

  showDefaultPosition(): void {
    this.setPositionOnMap(
      this.defaultPosition.latitude,
      this.defaultPosition.longitude,
      this.initialZoom,
      this.initialDragable,
      'location2',
    );
    this.locationFacade.findPlaceByPosition(this.defaultPosition, (data: IAddressForm) => {
      this.query = data.addressString;
    });
  }

  ngOnInit(): void {
    if (this.isAuthorized) {
      this.locationFacade.fetchLocations(true);
      if (!this.currentLocation && !this.isMerchant) {
        this.locationFacade.fetchCurrentLocation();
      }
    }

    if (this.initialPosition) {
      this.showInitialPosition();
    } else if (this.showInitialBrowserPosition) {
      this.showBrowserPosition();
    } else if (this.presetCurrentLocation && this.currentLocation?.location) {
      this.showCurrentLocation();
    }

    // todo(lukas.jurygacek): temporary workaround
    this.subscriptions.add(
      merge(this.locationFacade.getLocations$, this.locationFacade.getMerchantLocations$)
        .pipe(filter(data => !!data))
        .subscribe(() => {
          this.mapOptions = this.processAddresses(3, this.isMerchant, this.isAuthorized);
        }),
    );
  }
}
