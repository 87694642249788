import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { PhoneTransformPipe } from '../pipe/phone-transform.pipe';

@Directive({
  selector: '[appPhoneFormatMask]',
})
export class PhoneFormatDirective implements AfterContentInit {
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();
  @Input() prefixValue?: string;
  @Input() maxLength: number;
  code: number;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private phoneTransform: PhoneTransformPipe,
  ) {}

  ngAfterContentInit(): void {
    if (this.elementRef.nativeElement.value) {
      const formatted_value = this.phoneTransform.transform(this.elementRef.nativeElement.value.toString(), {
        prefix: this.prefixValue,
        maxLength: this.maxLength,
      });
      this.renderer.setValue(this.elementRef, formatted_value);
      this.ngModelChange.emit(formatted_value);
    }
  }

  @HostListener('keydown', ['$event'])
  onkeydown(event): void {
    this.code = event.keyCode || event.charCode;
  }
  @HostListener('input', ['$event.target.value'])
  onInput(value): void {
    const formatted_value = this.phoneTransform.transform(value.toString(), {
      prefix: this.prefixValue,
      maxLength: this.maxLength,
    });
    const parsed_value = this.phoneTransform.parse(formatted_value, this.prefixValue);
    this.elementRef.nativeElement.value = formatted_value;
    this.ngModelChange.emit(parsed_value);
  }
}
