import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../init/app.config';
import {
  ITransactionSigningAuthorizeRequestPayload,
  ITransactionSigningNextStepResponse,
} from '../store/models/store.models';

@Injectable({
  providedIn: 'root',
})
export class TransactionSigningService {
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {}

  startTransaction(transactionAuthId: string): Observable<ITransactionSigningNextStepResponse> {
    return this.httpClient.post<ITransactionSigningNextStepResponse>(
      `${this.appConfig.apiUrl}/login/v1/transaction/${transactionAuthId}/start`,
      {},
    );
  }

  authorizeTransaction(
    transactionAuthId: string,
    payload: ITransactionSigningAuthorizeRequestPayload,
  ): Observable<ITransactionSigningNextStepResponse> {
    return this.httpClient.post<ITransactionSigningNextStepResponse>(
      `${this.appConfig.apiUrl}/login/v1/transaction/${transactionAuthId}/authorize`,
      payload,
    );
  }

  cancelTransaction(transactionAuthId: string): Observable<any> {
    return this.httpClient.delete(`${this.appConfig.apiUrl}/login/v1/transaction/${transactionAuthId}`);
  }

  /* fakeTx(id?: string, token?: string): Observable<any> {
    const obj = {
      txType: 'TEST',
      amount: 20,
      name: 'Peter',
    };

    if (!token || !id) return this.api.post(transactionTest(this.appConfig.backendConfig.apiUrl), obj);

    const headers = new HttpHeaders({
      'X-AUTH-TX-ID': id,
      'X-AUTH-TX-TOKEN': token,
    });

    return this.api.post(transactionTest(this.appConfig.backendConfig.apiUrl), obj, headers);
  }*/
}
