import { Action } from '@ngrx/store';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import { IApplicationMaintenanceResponse, IInfoStepupPrep } from '../../models/application.model';
import { ITaskAction } from './task.actions';
import {
  CLEAN,
  FAIL,
  FINALIZE,
  ORIGINAL_ACTION,
  prefix,
  PUSH_NOTIFICATION,
  SET_INFO_STEPUP_PREP,
  SET_SCROLL_POSITION,
  SILENT_START,
  STARTED,
  SUCCESS,
} from './task.events';
import { MAINTENANCE_CHECK_MAINTENANCE_ERROR, MAINTENANCE_CHECK_MAINTENANCE_RECEIVED } from './maintenance.events';

export const APPLICATION_FEATURE_KEY = 'application2';

export interface IApplicationState {
  stackTrace?: ITaskAction[];
  tasks?: ITaskAction[];
  infoStepupPrep?: IInfoStepupPrep;
  scrollPosition?: number;
  maintenance?: IApplicationMaintenanceResponse;
}

export const ApplicationEventTypes = {
  prefix,
  CLEAN,
  FAIL,
  STARTED,
  PUSH_NOTIFICATION,
  SUCCESS,
  SILENT_START,
};

export const applicationInitialState = {
  stackTrace: [],
  tasks: [],
  infoStepupPrep: null,
  scrollPosition: null,
};

export interface IApplicationPartialState {
  readonly [APPLICATION_FEATURE_KEY]: IApplicationState;
}

const ACTION_HANDLERS = {
  /**
   * In this case, we initialize a application spinner by adding the action to the tasks.
   * @property tasks will be iterated in ui-task-handle component,
   * which will display the application spinner.
   */
  [STARTED]: (state: IApplicationState, action: ITaskAction): IApplicationState => {
    const tasks = [...state.tasks, action];
    return Object.assign({}, state, { tasks });
  },

  /**
   * If the STARTED action have
   * @property silently, the application loader is hidden,
   * and the mini silent loader appears in the upper right corner.
   */

  [SILENT_START]: (state: IApplicationState, action: ITaskAction): IApplicationState => {
    const tasks = [...state.tasks, action];
    return Object.assign({}, state, { tasks });
  },

  /**
   * @event SUCCESS is handled immediately after a completed action.
   * First we want to clear all STARTED tasks and then errors, both by action's taskId.
   * This completes the action.
   */
  [SUCCESS]: (state: IApplicationState, action: ITaskAction): IApplicationState => {
    const tasks = filter(state.tasks, task => get(task, 'taskId') !== get(action, 'taskId'));
    const stackTrace = filter(state.stackTrace, task => get(task, 'taskId') !== get(action, 'taskId'));
    return Object.assign({}, state, { tasks, stackTrace });
  },

  /**
   * It's exactly the same as the SUCCEESS event.
   */
  [PUSH_NOTIFICATION]: (state: IApplicationState, action: ITaskAction): IApplicationState => {
    const tasks = [...state.tasks, action];
    return Object.assign({}, state, { tasks });
  },

  /**
   * @event FAIL is handled immediately after a failed action.
   * @function clearOriginalStackAction & clearOriginal - will remove the running action from the tasks
   */
  [FAIL]: (state: IApplicationState, action: ITaskAction): IApplicationState => {
    /**
     * @const hasAnyUnauthorizedAction - serves as marker for a unauthorized action which ended with the code 401
     */
    const hasAnyUnauthorizedAction = state?.stackTrace?.length > 0 && state?.stackTrace?.every(s => s?.unauthorized);

    /**
     * @const stackedAction - finds the START action belonging action's taskId
     */
    const stackedAction = find(state.tasks, task => get(task, 'taskId') === get(action, 'taskId'));

    /**
     * Unauthorized action has been received and no one has been set ye
     */
    if (action?.error?.status === 401 && !hasAnyUnauthorizedAction) {
      /**
       * We need to create a new stacktrace object.
       * The object will not change, until user clear this task (by closing sign up dialogs)
       */
      const unauthorizedStackTrace = Object.assign(
        {},
        { [ORIGINAL_ACTION]: stackedAction },
        Object.assign({}, action, { unauthorized: true }),
      );
      const clearOriginalStackAction = filter(state.tasks, task => get(task, 'taskId') !== get(action, 'taskId'));
      const clearedTasks = [...clearOriginalStackAction, action];
      return Object.assign({}, state, { tasks: clearedTasks, stackTrace: [unauthorizedStackTrace] });
    }

    const stackTrace = [...state.stackTrace, Object.assign({}, { [ORIGINAL_ACTION]: stackedAction }, action)];

    /**
     * If the FAILURE action have
     * @property hidden
     * ui-task-handle compnent hide the failure dialog popup
     * alse when we have
     * @const hasAnyUnauthorizedAction, then ignore all incoming actions.
     */
    if (action?.hidden || hasAnyUnauthorizedAction || action?.error?.error?.postAction === 'CUSTOMER_DATA_REQUIRED') {
      const cleanTasks = state?.tasks?.filter(task => get(task, 'taskId') !== get(action, 'taskId'));
      if (hasAnyUnauthorizedAction) {
        return { tasks: cleanTasks, stackTrace: state?.stackTrace };
      }
      return Object.assign({}, state, { tasks: cleanTasks, stackTrace: state?.stackTrace });
    }

    const clearOriginal = filter(state.tasks, task => get(task, 'taskId') !== get(action, 'taskId'));
    const tasks = [...clearOriginal, action];

    return Object.assign({}, state, { tasks, stackTrace });
  },

  /**
   * @event CLEAR, serves as a manual action cleaner.
   */
  [CLEAN]: (state: IApplicationState, action: ITaskAction): IApplicationState => {
    const hasAnyUnathorizedAction = state?.stackTrace?.length > 0 && state?.stackTrace?.every(s => s?.unauthorized);
    const tasks = filter(state.tasks, task => get(task, 'taskId') !== get(action, 'taskId'));

    if (hasAnyUnathorizedAction) return applicationInitialState;
    return Object.assign({}, state, { tasks });
  },

  /**
   * @event FINALIZE - completes action's life-cycle.
   */
  [FINALIZE]: (state: IApplicationState, action: ITaskAction): IApplicationState => {
    const hasFailedActions = filter(state.stackTrace, task => get(task, 'taskId') === get(action, 'taskId'));
    if (hasFailedActions.length > 0) return state;
    const tasks = filter(
      state.tasks,
      task => get(task, 'taskId') !== get(action, 'taskId') || get(task[prefix], 'type') === PUSH_NOTIFICATION,
    );
    return Object.assign({}, state, { tasks });
  },
};

export function applicationReducer(
  state: IApplicationState = applicationInitialState,
  action: Action,
): IApplicationState {
  if (action.type === SET_INFO_STEPUP_PREP) {
    return Object.assign({}, state, { infoStepupPrep: action['data'] });
  }
  if (action.type === SET_SCROLL_POSITION) {
    return Object.assign({}, state, { scrollPosition: action['data'] });
  }
  if (action.type === MAINTENANCE_CHECK_MAINTENANCE_RECEIVED) {
    return Object.assign({}, state, { maintenance: action['data'] });
  }
  if (action.type === MAINTENANCE_CHECK_MAINTENANCE_ERROR) {
    return Object.assign({}, state, { maintenance: undefined });
  }

  const taskAction = action[prefix];
  if (!taskAction) return state;
  const handler = ACTION_HANDLERS[taskAction['type']];
  return handler ? handler(state, action) : state;
}
