<div
  class="ui select-wrapper"
  [ngClass]="{ 'map': showAsMapSelect, 'map-search': showAsMapSearch }"
  [ngStyle]="{ 'min-width': minWidth }"
>
  <div class="ui select" [ngClass]="{ focused: isFocused | async }" (click)="onClick.emit($event)">
    <input
      type="text"
      tabindex="0"
      [style]="style"
      autocomplete="off"
      [attr.id]="placeholder | hashString: hashType.TEXT"
      [applyFocus]="autofocus"
      appFocus
      [disabled]="disabled"
      [readonly]="readOnly"
      [placeholder]="placeholder"
      [name]="name"
      [value]="value | getValue"
      (keyup)="handleChange($event.target['value'])"
      (keypress)="handleEnter($event)"
      (blur)="handleInputBlur()"
      (focus)="handleFocus()"
    />
    <ui-icon
      *ngIf="!readOnly && !loading && clearable && value"
      icon="cross"
      size=".9rem"
      (click)="handleClear()"
    ></ui-icon>
    <ui-icon
      *ngIf="!readOnly && !loading && (!value || !clearable)"
      [icon]="showAsMapSelect && (isSelectOpen | async) ? 'up' : 'search'"
      (click)="showAsMapSelect && handleBlur()"
      size="1.5rem"
    ></ui-icon>
    <div *ngIf="loading" class="mini-task-spinner"></div>
  </div>

  <!-- Basic select menu -->
  <ng-container *ngIf="!showAsMapSelect && !forceClose && (isSelectOpen | async) && !!menuItems">
    <div class="select-menu">
      <ng-container *ngIf="menuItems?.length > 0">
        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="!item?.categories">
            <div class="select-item">
              <p (click)="handleSelect(item[bindValue])" [innerHTML]="item[bindLabel] | getHighlightedText: value"></p>
              <ui-icon *ngIf="showOnRemoveButton" icon="close" size=".9rem" (click)="onRemove.emit()"></ui-icon>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!!menuItems?.categories">
        <ng-container *ngFor="let c of menuItems?.categories">
          <ng-container *ngIf="c.name !== 'ProductCategory' && c?.items?.length > 0">
            <div class="select-item" (click)="onViewAll(c?.name)">
              <div class="row between" [style.width]="'100%'">
                <h3 [style.textDecoration]="openCategory === c.name ? 'underline' : ''">
                  {{ 'search_suggestions.category$' | translate: { $1: c?.name } }}
                </h3>
                <h3 [style.textDecoration]="openCategory === c.name ? 'underline' : ''">
                  {{ 'search_suggestions.view_all' | translate }}
                </h3>
              </div>
            </div>
            <ng-container *ngFor="let item of c?.items; let i = index">
              <ng-container *ngIf="openCategory === c.name || (openCategory !== c.name && i + 1 <= 5)">
                <div class="select-item">
                  <p (click)="handleCategorySelect(item, c, i)" [innerHTML]="item | getHighlightedText: value"></p>
                  <ui-icon *ngIf="showOnRemoveButton" icon="close" size=".9rem" (click)="onRemove.emit()"></ui-icon>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <!-- Map menu -->
  <div
    *ngIf="showAsMapSelect && (isSelectOpen | async) && !forceClose && !!menuItems && menuItems?.length !== 0"
    class="select-menu map"
  >
    <div
      *ngFor="let item of menuItems; trackBy: trackByMapOptionId"
      class="select-item map"
      (click)="handleMapSelect(item[bindValue] || item?.address?.addressString || item?.name)"
    >
      <ui-icon [icon]="item?.icon || 'location_marker'" size="1rem"></ui-icon>
      <span class="item-description">
        <b
          class="light"
          *ngIf="
            item?.type === addressTypes.CustomerLocation || item?.type === addressTypes.StaticCurrentLocation;
            else basicTextTemplate
          "
          [innerHTML]="item[bindLabel]"
        ></b>
        <ng-template #basicTextTemplate>
          <p [innerHTML]="item[bindLabel] | getHighlightedText: value"></p>
        </ng-template>
        <p
          *ngIf="item?.address?.addressString"
          class="small light"
          [innerHTML]="item?.address?.addressString | getHighlightedText: value"
        ></p>
      </span>
    </div>
  </div>
</div>
