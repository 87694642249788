import { IAmount, ILocationV3Address, IPosition } from '@wakanda/wakanda-core';
import { SelectSocialType } from './requests.model';

export interface IError {
  code: string;
  message: string;
  messageCode: string;
}

export interface IZenAuthError {
  debug: any;
  error: IError;
}

export interface IZenAuthAuthenticateError {
  status: number;
  message: string;
}

export interface IZenAuthAuthenticateResponse {
  accessToken?: IZenAuthAccessToken;
  redirectUri?: string;
  nextStep?: IAuthMethod | NextStepOnboarding;
  temporaryAccessToken?: string;
}

export interface IZenAuthAccessToken {
  access_token: string;
  expires_in: number;
  refresh_token?: string;
  scope?: string;
  token_type: string;
}
export interface ISecretPayload {
  secretPayload: string;
}

export interface IAuthMethod {
  username?: any;
  password?: any;
  smsOtp?: any;
  authAppMethod: IAuthAppMethod;
  directMethod: IDirectMethod;
  qrCodeMethod: IQrCodeMethod;
  smsOtpMethod: ISmsOtpMethod;
  usernamePasswordMethod: IUsernamePasswordMethod;
  usernamePasswordResetMethod: IUsernamePasswordResetMethod;
}

export interface IAuthAppMethod extends ISecretPayload {
  allowedKeyTypes: AllowedKeyTypes[];
  challenge: string;
  secretPayload: string;
}

export interface IDirectMethod extends ISecretPayload {
  secretPayload: string;
}

export interface IGridMethod extends ISecretPayload {
  coordinates: ICoordinate;
}

export interface IQrCodeMethod extends ISecretPayload {
  qrCode: string;
}

export interface ISmsOtpMethod extends ISecretPayload {
  resent: boolean;
}

export interface IUsernamePasswordMethod extends ISecretPayload {
  passwordMethod: string;
}

export interface IUsernamePasswordResetMethod extends ISecretPayload {
  passwordResetType: string;
  redirectUri: string;
  secretPayload: string;
  status: any;
  username: string;
}

export interface IZenAuthOnboardingBusinessDetails {
  businessRegisteredNameRequired: boolean;
  businessRegisteredNameShown: boolean;
  businessRegisteredName?: string;
  businessPhoneNumberRequired: boolean;
  businessPhoneNumber?: string;
  natureOfBusinessRequired: boolean;
  natureOfBusiness?: string;
  industryRequired: boolean;
  industries: IZenAuthOnboardingBusinessDetailsItem[];
  industryId?: string;
  sourceOfFundsRequired: boolean;
  sourceOfFundsId?: string;
  sourceOfFunds: IZenAuthOnboardingBusinessDetailsItem[];
  turnovers: IZenAuthOnboardingBusinessDetailsItem[];
  countryOfOperationRequired: boolean;
  tradingNameRequired: boolean;
  tradingNameShown: boolean;
  tradingName?: string;
}

export interface IZenAuthOnboardingBusinessDetailsItem {
  id: string;
  name: string;
}
export interface IZenAuthOnboardingSuccessInfo {
  unlimited: boolean;
  type: ZenAuthOnboardingSuccessInfoType;
  name: string;
  email: string;
  dailyLimit: IAmount;
  maxBalance: IAmount;
  isReferral?: boolean;
}

export enum ZenAuthOnboardingSuccessInfoType {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
  WAIT = 'WAIT',
  NO_WALLET = 'NO_WALLET',
  WALLET_LINKED = 'WALLET_LINKED',
  WALLET_UPGRADED = 'WALLET_UPGRADED',
  ACCOUNT_LINKED = 'ACCOUNT_LINKED',
  MANUAL_REGISTRATION = 'MANUAL_REGISTRATION',
  SOCIAL_MEDIA_REGISTRATION = 'SOCIAL_MEDIA_REGISTRATION',
}

export interface IZenAuthOnboardingBusinessPartner {
  id: string;
  name: string;
}

export interface IZenAuthOnboardingBusinessCategoryInfo {
  recommendedRestriction: number;
  categories: IZenAuthOnboardingBusinessCategoryInfoBusinessCategory[];
}

export interface IZenAuthOnboardingBusinessCategoryInfoBusinessCategory {
  id: string;
  name: string;
  subCategories: IZenAuthOnboardingBusinessCategoryInfoBusinessSubCategory[];
}

export interface IZenAuthOnboardingBusinessCategoryInfoBusinessSubCategory {
  id: string;
  name: string;
}

export interface IOnboardingCategory {
  categoryId: string;
  subCategories: string[];
}

export interface IOnboardingCategoryLocation {
  location: IPosition;
  radius: number;
}

export interface IZenAuthOnboardingPersonalInfo {
  occupationRequired: boolean;
  occupations: IZenAuthOnboardingOccupation[];
  fetcaRequired: boolean;
  emailRequired: boolean;
  sourceOfIncomeRequired: boolean;
  industryRequired: boolean;
  countryOfBirthRequired: boolean;
  industries: IZenAuthOnboardingIndustry[];
  sourceOfIncomes: IZenAuthOnboardingSourceOfIncome[];
  countries?: IZenAuthOnboardingCountry[];
  defaultSourceOfIncomeId: string;
  defaultIndustryId: string;
  defaultOccupationId: string;
  defaultCountryOfBirthId?: string;
}

export interface IZenAuthOnboardingIndustry {
  id: string;
  name: string;
}

export interface IZenAuthOnboardingSourceOfIncome {
  id: string;
  name: string;
}

export interface IZenAuthOnboardingOccupation {
  id: string;
  name: string;
}

export interface IZenAuthOnboardingCountry {
  id: string;
  name: string;
}

export interface ICoordinate {
  x: string;
  y: number;
}

export interface IZenAuthOnboardingAvoClientInfo {
  id: string;
  accessToken?: string;
  expiresIn?: number;
  linkedToBank: boolean;
  loginCreated: boolean;
  walletCreated: boolean;
  walletSeen: boolean;
  displayName: string;
  name?: string;
  surname?: string;
  phone?: string;
  email?: string;
  photo?: string;
  newlyCreated?: boolean;
  autoReg?: boolean;
  nationality?: string;
  saId?: string;
  addressLine1: string;
  country: string;
}

export interface IZenAuthPersonalInformationEditEmailRequest {
  email: string;
}

export interface IZenAuthPersonalInformationResponse {
  avatarUrl: string;
  nickname: string;
  email: string;
  emailVerified: boolean;
  mobile: string;
  mobileVerified: boolean;
}

export interface IZenAuthOnboardingBankClientInfo {
  walletAvailable: boolean;
  upgradeWallet: boolean;
  residentialAddress: ILocationV3Address;
}

export interface IZenAuthUserInfo {
  avoClientInfo: IZenAuthOnboardingAvoClientInfo;
  bankClientInfo: IZenAuthOnboardingBankClientInfo;
}

export interface IZenAuthOnboardingNextStepResponse {
  onboardingId: string;
  accessToken?: IZenAuthOnboardingAccessTokenResponse;
  nextStep: NextStepOnboarding;
  externalAuthUrl?: string;
  personalDataInfo?: IZenAuthOnboardingPersonalInfo;
  businessDetailsInfo?: IZenAuthOnboardingBusinessDetails;
  successInfo?: IZenAuthOnboardingSuccessInfo;
  businessPartners?: IZenAuthOnboardingBusinessPartner[];
  businessCategoryInfo?: IZenAuthOnboardingBusinessCategoryInfo;
  timeToWait?: number;
  securityQuestionsInfo?: IZenAuthSecurityQuestionsInfo;
  errorMessageLocalisationKey?: string;
  estimatedTime?: number;
  sentToNumber?: string;
  messageLocalisationKey?: string;
  basicDataInfo?: IZenAuthOnboardingBasicInfo;
  businessBasicDataInfo?: IZenAuthOnboardingBusinessBasicInfo;
  progressBar?: IZenAuthProgressBar;
  progressStep?: IZenAuthProgressStep;
  maskedBankPhoneNumber?: string;
  businessAddressOffer?: IZenAuthOnboardingBusinessAdddressOffer;
  accountExistInfo?: IZenAuthOnboardingAccountExistInfo;
  waitLightFica?: IZenAuthOnboardingwaitLightFicaInfo;
  outstandingTcs?: IZenAuthOnboardingOutstandingTCs;
  tcs?: IZenAuthOnboardingTCs;
  linkAccountData?: IZenAuthOnboardingLinkAccountData;
  selectOtpData?: IZenAuthOnboardingSelectOtpData;
  selectSocialData?: IZenAuthOnboardingSelectSocialData;
  selectNedbankData?: IZenAuthOnboardingSelectNedbankData;
  setLoginData?: IZenAuthOnboardingSetLoginData;
  checkBankInfo?: IZenAuthOnboardingCheckBankInfoData;
  idPassportNumberInfo?: IZenAuthOnboardingPassportNumberInfoData;
  linkLoginData?: IZenAuthOnboardingLinkLoginData;
}

export interface IZenAuthOnboardingCheckBankInfoData {
  storeId?: string;
}
export interface IZenAuthOnboardingSetLoginData {
  userEmail: string;
}

export interface IZenAuthOnboardingSelectOtpData {
  email: string;
}

export interface IZenAuthOnboardingSelectSocialData {
  available: SelectSocialType[];
}

export interface IZenAuthOnboardingSelectNedbankData {
  available: SelectNedbankType[];
}

export interface IZenAuthOnboardingLinkAccountData {
  accountData: IZenAuthOnboardingAccountData[];
}

export interface IZenAuthOnboardingLinkLoginData {
  redirectedFrom: SocialOnboardingType;
  availableLoginMethods: AvailableLoginMethods;
}

export interface AvailableLoginMethods {
  manual: boolean;
  nedbankChannels: boolean;
  facebook: boolean;
  google: boolean;
  apple: boolean;
}

export enum SocialOnboardingType {
  APPLE = 'APPLE',
  APPLECODE = 'APPLECODE',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  MHL = 'MHL',
  AMP = 'AMP',
}

export interface IZenAuthOnboardingAccountData {
  accountId: string;
  accountType: string;
  accountNumber: string;
  accountName: string;
}

export interface IZenAuthOnboardingOutstandingTCs {
  tcsRequired: boolean;
  tcsLink?: string;
  consentRequired: boolean;
  consentLink?: string;
}

export interface IZenAuthOnboardingTCs {
  tcsRequired: boolean;
  tcsLink?: string;
  consentRequired: boolean;
  consentLink?: string;
}

export interface IZenAuthOnboardingAccountExistInfo {
  accountLoginTypes: AccountLoginType[];
}

export interface IZenAuthOnboardingwaitLightFicaInfo {
  name: string;
}

export enum AccountLoginType {
  AVO_LOGIN = 'AVO_LOGIN',
  APPLE_ID = 'APPLE_ID',
  GOOGLE = 'GOOGLE',
  FB = 'FB',
  MHL = 'MHL',
  MONEY_APP = 'MONEY_APP',
  NPW = 'NPW',
  MONEY_PWA = 'MONEY_PWA',
  GREENBACKS = 'GREENBACKS',
  SIMPLYBIZ = 'SIMPLYBIZ',
}

export enum SelectNedbankType {
  NEDID = 'NEDID',
  MONEYAPP = 'MONEYAPP',
  MOBIMONEYPIN = 'MOBIMONEYPIN',
}

export interface IZenAuthOnboardingBusinessAdddressOffer {
  tradingAddress: ILocationV3Address;
  postalSameAsTrading: boolean;
  postalAddress?: ILocationV3Address;
  registeredSameAsTrading: boolean;
  registeredAddress?: ILocationV3Address;
}

export interface IZenAuthProgressBar {
  descriptionLocKeys?: string[];
  progressIndex?: number;
}

export interface IZenAuthProgressStep {
  titleLocalisationKey: string;
  messageLocalisationKey: string;
  progressScreenType: ZenAuthProgressScreenType;
  completionBar: number;
}

export enum ZenAuthProgressScreenType {
  WALLET = 'WALLET',
  DEALS = 'DEALS',
  SECURITY = 'SECURITY',
  REWARDS = 'REWARDS',
  SPINNER = 'SPINNER',
}

export interface IZenAuthOnboardingBasicInfo {
  displayNameRequired?: boolean;
  phoneNumberRequired?: boolean;
  emailRequired?: boolean;
  consentsRequired?: boolean;
  displayName?: string;
  phoneNumber?: string;
  email?: string;
}

export interface IZenAuthOnboardingBusinessBasicInfo {
  displayNameRequired?: boolean;
  phoneNumberRequired?: boolean;
  emailRequired?: boolean;
  consentsRequired?: boolean;
  displayName?: string;
  phoneNumber?: string;
  email?: string;
}

export interface IZenAuthSecurityQuestionsInfo {
  questions: IZenAuthSecurityQuestion[];
}

export interface IZenAuthSecurityQuestion {
  id: string;
  text: string;
  options: IZenAuthSecurityQuestionOption[];
}

export interface IZenAuthSecurityQuestionOption {
  id: string;
  text: string;
}

export interface IZenAuthOnboardingAddressSuggestResponse {
  city: string;
  postalCode: string;
  suburb: string;
}

export interface IAddressSuggestModel {
  id: string;
  label: string;
  city: string;
  postalCode: string;
  suburb: string;
}

export interface IZenAuthOnboardingAccessTokenResponse {
  accessToken: string;
  expiresIn: number;
  tokenType: string;
  refreshToken?: string;
}

export enum AllowedKeyTypes {
  DEVICE,
  PASSWORD,
  BIOMETRIC,
}

export enum NextStepOnboarding {
  START_INFO = 'START_INFO',
  OTP = 'OTP',
  PERSONAL_DATA = 'PERSONAL_DATA',
  SET_LOGIN = 'SET_LOGIN',
  EXTERNAL_AUTH = 'EXTERNAL_AUTH',
  ADDRESS = 'ADDRESS',
  SECURITY_QUESTIONS = 'SECURITY_QUESTIONS',
  SUCCESS = 'SUCCESS',
  HOME = 'HOME',
  FIRSTNAME_LASTNAME_AUTH = 'FIRSTNAME_LASTNAME_AUTH',
  BUSINESS_TRADING_NAME = 'BUSINESS_TRADING_NAME',
  ACCOUNT_EXISTS = 'ACCOUNT_EXISTS',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  BUSINESS_ADDRESS = 'BUSINESS_ADDRESS',
  BUSINESS_CATEGORY = 'BUSINESS_CATEGORY',
  BUSINESS_PARTNER = 'BUSINESS_PARTNER',
  MULTIDIRECTOR = 'MULTIDIRECTOR',
  STEPUP_SUCCESS = 'STEPUP_SUCCESS',
  STEPUPEND = 'STEPUPEND',
  APPROVEIT = 'APPROVEIT',
  APPROVEIT_RESENT = 'APPROVEIT_RESENT',
  IDPASSPORT_NUMBER = 'IDPASSPORT_NUMBER',
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  BUSINESS_LEGAL_ENTITY = 'BUSINESS_LEGAL_ENTITY',
  BASIC_DATA = 'BASIC_DATA',
  BUSINESS_BASIC_DATA = 'BUSINESS_BASIC_DATA',
  BANKPHONE_DIFFERENT = 'BANKPHONE_DIFFERENT',
  OUTSTANDING_TCS = 'OUTSTANDING_TCS',
  TCS = 'TCS',
  WAIT_LIGHT_FICA = 'WAIT_LIGHT_FICA',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  SELECT_OTP = 'SELECT_OTP',
  SELECT_SOCIAL = 'SELECT_SOCIAL',
  SELECT_NEDBANK = 'SELECT_NEDBANK',
  LINK_ACCOUNT = 'LINK_ACCOUNT',
  MONEYAPP_SIGNUP = 'MONEYAPP_SIGNUP',
  LOGIN = 'LOGIN',
  BANKOTP = 'BANKOTP',
  ID_PHOTO = 'ID_PHOTO',
  MOBIMONEY = 'MOBIMONEY',
  PROGRESS = 'PROGRESS',
  RETURN = 'RETURN',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  END_SCREEN = 'END_SCREEN',
  END = 'END',
}

export interface IZenAuthNextStepTransactionSigningResponse {
  authorized: boolean;
  signingToken?: string;
  method: NextStepTransactionSigning;
  pinMethod?: IZenAuthTransactionSigningAppMethod;
  deviceMethod?: IZenAuthTransactionSigningAppMethod;
  otpMethod?: IZenAuthTransactionSigningOtpMethod;
  tcsMethod?: IZenAuthTransactionSigningTcsMethod;
  approveItMethod?: IZenAuthTransactionSigningApproveitMethod;
}

export enum NextStepTransactionSigning {
  PIN = 'PIN',
  DEVICE = 'DEVICE',
  OTP = 'OTP',
  OUTSTANDING_TCS = 'OUTSTANDING_TCS',
  APPROVEIT = 'APPROVEIT',
}

export enum TransactionSigningOtpChannel {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  BANK_SMS = 'BANK_SMS',
}

export interface IZenAuthTransactionSigningApproveitMethod {
  timeToWait: number;
  sentToNumber: string;
  tryAgain?: boolean;
}

export interface IZenAuthTransactionSigningAppMethod {
  challenge: string;
  allowedKeyTypes: KeyTypeTransactionSigning[];
}

export interface IZenAuthTransactionSigningOtpMethod {
  sentTo: string;
  resendAvailableAt: string;
  channel: TransactionSigningOtpChannel;
}

export interface IZenAuthTransactionSigningTcsMethod {
  documents: IZenAuthTcsDocument[];
}

export interface IZenAuthTcsDocument {
  id: string;
  url: string;
  label: string;
  required: boolean;
}

export interface IZenAuthTcsDocumentExtended extends IZenAuthTcsDocument {
  checkboxLabel: string;
  name: string;
}

export enum KeyTypeTransactionSigning {
  DEVICE = 'DEVICE',
  PASSWORD = 'PASSWORD',
  BIOMETRIC = 'BIOMETRIC',
}

export interface IZenGuestLoginResponse {
  customerToken: string;
  tokenExpirationDate: string;
}

export interface IZenAuthSignupInfoResponse {
  manualSignUpAvailable: boolean;
  socialSignUpAvailable: boolean;
  nedbankSignUpAvailable: boolean;
  signInAvailable?: boolean;
}

export interface IZenAuthOnboardingPassportNumberInfoData {
  passportNumberEnabled: boolean;
}

export interface IZenAuthInfoStepUpRequest {
  stepupType: string;
}

//v3
export interface IAuthNextStep {
  sessionId: string;
  nextStep: AuthNextStepType;
  nextStepData?: IAuthNextStepOtpData | IAuthNextStepProfileData;
  token: IAuthAccessToken;
}

export interface IAuthNextStepOtpData {
  otpSentTo: string;
  allowSwitchToEmail: boolean;
  allowSwitchToPhone: boolean;
}

export interface IAuthNextStepProfileData {
  requestNickname: boolean;
  requestPhone: boolean;
  requestEmail: boolean;
  requirePhone: boolean;
  requireEmail: boolean;
  prefillNickname: string;
  prefillEmail: string;
  prefillPhone: string;
}

export interface IAuthAccessToken {
  accessToken: string;
  expiresIn: number;
  expiresAt: number;
  refreshToken?: string;
  idToken?: string;
  tokenType: string;
}

export enum AuthNextStepType {
  OTP = 'OTP',
  FINISHED = 'FINISHED',
  PROFILE_DATA = 'PROFILE_DATA',
  REDIRECT = 'REDIRECT',
  WAITING = 'WAITING',
  WELCOME_EXTERNAL = 'WELCOME_EXTERNAL',
  LINK_ACCOUNTS = 'LINK_ACCOUNTS',
  LINK_ACCOUNTS_SUCCESS = 'LINK_ACCOUNTS_SUCCESS',
}
