export const prefix = '@@TASKS';
export const STARTED = `${prefix}/STARTED`;
export const SILENT_START = `${prefix}/SILENT/START`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const FAIL = `${prefix}/FAILED`;
export const PUSH_NOTIFICATION = `${prefix}/PUSH-NOTIFICATION`;
export const CLEAN = `${prefix}/EVENT-CLEAN`;
export const FINALIZE = `${prefix}/FINALIZE`;
export const ORIGINAL_ACTION = '@@ORIGINAL';
export const SET_INFO_STEPUP_PREP = `SET-INFO-STEPUP-PREP`;
export const HTTP_ERROR = `HTTP-ERROR`;
export const SET_SCROLL_POSITION = `SET-SCROLL_POSITION`;
