import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import get from 'lodash-es/get';
import last from 'lodash-es/last';
import size from 'lodash-es/size';

import {
  IChatPaymentRequestContent,
  IChatRatingRequested,
  IMatrixMessage,
  IMessageEventUserWriting,
  IPaymentRequestClick,
  MessageEventType,
} from '@wakanda/agent-chat-model';
import { IChatSendMessageContent, IWebsocketMessageEvent, WebsocketMessageEventType } from '@wakanda/wakanda-core';
import { IChatAttachmentRequest } from '@wakanda/wakanda-chat';

@Component({
  selector: 'ui-chat-content',
  template: `
    <div
      class="ui-container chat compact"
      #chatScrollingContent
      id="chatScrollingContent"
      (scroll)="handleScroll()"
      [ngClass]="{
        'borderless': borders === 'none',
        'with-navigation': !!withNavigation,
        'left': !!leftAlign,
        'right': !!rightAlign,
        'no-bottom-padding': !!noBottomPadding,
        'no-top-padding': !!noTopPadding,
        'transparent': !!transparent,
        'hey-ned': !!heyNedTheme
      }"
    >
      <div class="subheader" *ngIf="!!showCloseButton || !!title">
        <ui-divider [compact]="true"></ui-divider>
        <div class="row between padded-content">
          <div></div>
          <b *ngIf="!!title" class="light">{{ title }}</b>
          <div>
            <ui-icon (click)="onCloseChat.emit()" *ngIf="!!showCloseButton" icon="cross" size="1rem"></ui-icon>
          </div>
        </div>
        <ui-divider [compact]="true"></ui-divider>
      </div>
      <div class="container-content" [ngClass]="{ 'with-header': !!showCloseButton || !!title }">
        <ui-skeleton-screen
          [canShow]="!!messages"
          [list]="true"
          [times]="30"
          [ngClass]="{ 'padded-content': !compact }"
        >
          <ng-container
            *ngFor="
              let m of messages | groupDataByDate: 'origin_server_ts':'D MMM YYYY';
              trackBy: trackByDateTitle;
              let lastGroup = last
            "
          >
            <div class="date-title">
              <p class="small">{{ m?.dateTitle }}</p>
            </div>

            <ng-container *ngFor="let message of m?.data; trackBy: trackByIdentity; let lastMessage = last">
              <ng-container *ngIf="canShowChatMessage(message)">
                <ui-chat-message [message]="message" [heyNedTheme]="heyNedTheme"></ui-chat-message>
              </ng-container>

              <ng-container *ngIf="!canShowChatMessage(message)">
                <ui-chat-widgets
                  [message]="message"
                  [messages]="messages"
                  [heyNedTheme]="heyNedTheme"
                  [hasWallet]="hasWallet"
                  (onReserve)="onReserve.emit($event)"
                  (buttonSelect)="onWidget.emit($event)"
                  (setNewLocation)="setNewLocation.emit($event)"
                  (locationSet)="onWidget.emit($event)"
                  (uploadFiles)="onWidget.emit($event)"
                  (onPayment)="onPayment.emit($event)"
                  (onRating)="onRating.emit($event)"
                  (onCreateWallet)="onCreateWallet.emit($event)"
                  (onProductClick)="onProductClick.emit($event)"
                  (onJobDetail)="onJobDetail.emit($event)"
                ></ui-chat-widgets>
              </ng-container>

              <ng-container *ngIf="lastGroup && lastMessage">
                <ng-container *ngIf="userWritingIndicator?.length > 0">
                  <div class="row left typing indicator">
                    <img alt="typing_indicator" src="assets/images/message_typing_indicator.gif" />
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="showIndicator">
            <div class="new-messages-indicator">
              <div class="inner">
                <p class="small">NEW</p>
              </div>
            </div>
          </ng-container>
        </ui-skeleton-screen>
      </div>
    </div>

    <ui-chat-input
      (onSend)="onSend.emit($event)"
      (onAttachmentUpload)="onAttachmentUpload.emit($event)"
      [hideAttachmentPicker]="chatType === 'OAS' || !isAttachmentPickerVisible"
      [hide]="messages | chatInputEndChat: chatType"
      [disable]="messages | chatInputDisabled"
    ></ui-chat-input>
  `,
})
export class ChatContentComponent implements OnInit, AfterViewChecked, OnChanges {
  showIndicator: boolean;
  userWritingIndicator: number[] = [];
  //
  disableAutoScrollDown: boolean;
  @ViewChild('chatScrollingContent', { static: true }) private scrollingContent: ElementRef;
  //
  @Input() compact: boolean;
  @Input() borders: string;
  @Input() leftAlign: boolean;
  @Input() rightAlign: boolean;
  @Input() noBottomPadding: boolean;
  @Input() noTopPadding: boolean;
  @Input() transparent: boolean;
  @Input() withNavigation: boolean;
  @Input() messages: IMatrixMessage[];
  @Input() chatType: string;
  @Input() heyNedTheme: boolean;
  @Input() isAttachmentPickerVisible: boolean;
  @Input() title: string;
  @Input() showCloseButton: boolean;
  @Input() hasWallet: boolean;

  @Output() onReserve = new EventEmitter<IPaymentRequestClick>();
  @Output() onSend = new EventEmitter<IChatSendMessageContent>();
  @Output() onAttachmentUpload = new EventEmitter<IChatAttachmentRequest>();
  @Output() onWidget = new EventEmitter();
  @Output() onPayment = new EventEmitter<IChatPaymentRequestContent>();
  @Output() onRating = new EventEmitter<IChatRatingRequested>();
  @Output() onCloseChat = new EventEmitter<any>();
  @Output() setNewLocation = new EventEmitter<string>();
  @Output() onCreateWallet = new EventEmitter<void>();
  @Output() onProductClick = new EventEmitter<string>();
  @Output() onJobDetail = new EventEmitter<string>();

  @Input() set wsMessage(value: IWebsocketMessageEvent) {
    if (!value) return;

    switch (value.websocketChatEventType) {
      case WebsocketMessageEventType.USER_WRITING: {
        this.handleTypingAnimation(value);
        break;
      }

      case WebsocketMessageEventType.NEW_MESSAGE: {
        this.userWritingIndicator = [];
        break;
      }
    }
  }

  private scrollToBottom = (): void => {
    if (this.disableAutoScrollDown) return;
    const { nativeElement } = this.scrollingContent;
    nativeElement.scrollTo({ top: nativeElement.scrollHeight, behavior: 'smooth' });
  };

  private forceTheScrollIfNeeded = (): void => {
    const authorOfLastMessage = get(last(this.messages), 'sender_self');
    if (authorOfLastMessage === true) {
      this.scrollToBottom();
    }
  };

  private showIndicatorOfNewMessagesIfNeeded = (): void => {
    if (!this.disableAutoScrollDown || !this.messages || !size(this.messages) || size(this.userWritingIndicator)) {
      return;
    }
    this.showIndicator = true;
  };

  private handleTypingAnimation = (messageEvent: IWebsocketMessageEvent): void => {
    const arr = this.userWritingIndicator;
    const message: IMessageEventUserWriting = JSON.parse(messageEvent.content);
    if (!message.showForSeconds) return;

    arr.push(size(arr) + 1);
    setTimeout(() => {
      if (arr?.length > 0) arr.shift();
    }, message?.showForSeconds * 1000);

    // todo TEMP solution START (move scrollable wrapper when typing indicator is displayed)
    setTimeout(() => {
      this.scrollToBottom();
    }, 1000);
    // todo TEMP SOLUTION END
  };

  canShowChatMessage(message: IMatrixMessage): boolean {
    if (!message || !message.content) {
      return false;
    }

    return message.content.msgtype === MessageEventType.TEXT || message.content.msgtype === MessageEventType.IMAGE;
  }

  handleScroll = (): void => {
    const { nativeElement } = this.scrollingContent;
    const atBottom = nativeElement.scrollHeight - nativeElement.scrollTop === nativeElement.clientHeight;

    if (this.disableAutoScrollDown && atBottom) {
      this.disableAutoScrollDown = false;
      this.showIndicator = false;
      return;
    }

    this.disableAutoScrollDown = true;
  };

  trackByIdentity(index: number, item: any): string {
    return item.event_id;
  }

  trackByDateTitle(index: number, item: any): string {
    return item.dateTitle;
  }

  ngOnInit(): void {
    this.scrollToBottom();
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes, 'messages.previousValue') === get(changes, 'messages.currentValue')) return;

    this.forceTheScrollIfNeeded();
    this.showIndicatorOfNewMessagesIfNeeded();
  }
}
