import { Pipe, PipeTransform } from '@angular/core';
import round from 'lodash-es/round';
import { ILoyaltyPoint } from '../models/wallet.model';
import { AvoPointsToZARPipe } from './avo-points.pipe';

export interface IEnoughBalanceData {
  walletBalance: number;
  usedPoints: number;
  loyaltyPoints: ILoyaltyPoint;
}

@Pipe({
  name: 'enoughBalance',
})
export class AvoEnoughBalancePipe implements PipeTransform {
  constructor(private avoPointsToZarPipe: AvoPointsToZARPipe) {}
  public transform(price: number, params: IEnoughBalanceData): boolean {
    if (!price || !params) return true;
    const pointsInZAR = this.avoPointsToZarPipe.transform(params?.usedPoints, params?.loyaltyPoints) || 0;
    return round(params?.walletBalance + pointsInZAR, 2) >= round(price, 2);
  }
}
