import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import jsQR from 'jsqr';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { interval, Observable, Subject, Subscription } from 'rxjs';
@Component({
  selector: 'ui-qr-code-camera',
  template: `
    <ui-container [compact]="true" [noTopPadding]="true">
      <ng-container *ngIf="showWebcam">
        <webcam
          [height]="728"
          [width]="1025"
          [trigger]="triggerObservable"
          (imageCapture)="handleImage($event)"
          [allowCameraSwitch]="allowCameraSwitch"
          [switchCamera]="nextWebcamObservable"
          [videoOptions]="videoOptions"
          [captureImageData]="true"
          (cameraSwitched)="cameraWasSwitched($event)"
          (initError)="handleInitError($event)"
        ></webcam>
      </ng-container>

      <ui-divider></ui-divider>

      <div class="bottom-content" [class.padded-content]="padded">
        <ui-button
          *ngIf="showSwitchCameraButton"
          [callToActionButton]="true"
          content="Switch Camera"
          (onClick)="showNextWebcam(true)"
        ></ui-button>
        <ui-divider [compact]="true"></ui-divider>
        <ui-button
          [callToActionButton]="true"
          [selectButton]="true"
          aligned="bottom"
          content="Cancel"
          (onClick)="onCancel.emit()"
        >
        </ui-button>
      </div>
    </ui-container>
  `,
})
export class QrCodeCameraComponent implements OnInit, OnDestroy {
  @Output() onChange = new EventEmitter<string>();
  @Output() onCancel = new EventEmitter();
  @Input() showSwitchCameraButton = false;
  @Input() padded = false;

  // toggle webcam on/off
  showWebcam = true;
  allowCameraSwitch = true;
  multipleWebcamsAvailable = false;
  deviceId: string;
  videoOptions: MediaTrackConstraints = {
    width: 1024,
    height: 576,
    facingMode: 'environment',
  };
  intervalValue = 200;
  interval = interval(this.intervalValue);
  intervalSubscription: Subscription;
  errors: WebcamInitError[] = [];

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  triggerSnapshot = (): void => this.trigger.next();

  toggleWebcam = (): void => {
    this.showWebcam = !this.showWebcam;
  };

  handleInitError = (error: WebcamInitError): void => {
    this.errors.push(error);
  };

  showNextWebcam = (directionOrDeviceId: boolean | string): void => {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  };

  handleImage = (webcamImage: WebcamImage): void => {
    const code = jsQR(webcamImage.imageData.data, webcamImage.imageData.width, webcamImage.imageData.height, {
      inversionAttempts: 'dontInvert',
    });
    if (code) {
      this.onChange.emit(code.data);
    }
  };

  cameraWasSwitched = (deviceId: string): void => {
    this.deviceId = deviceId;
  };

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  ngOnInit(): void {
    this.showNextWebcam(false);

    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => (this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1),
    );
    this.intervalSubscription = this.interval.subscribe(() => {
      this.triggerSnapshot();
    });
  }
  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }
}
