import configuration from './../../apps/wakanda-merchant/src/configurations/test/configuration.json';

import packageJson from '@wakanda/package';

const getEnv = () => {
  if (window.location.hostname.indexOf('localhost') >= 0) {
    return {
      baseUrl: 'http://localhost:3001',
      maintenanceUrl: '#',
      production: false,
      adobe: 'https://assets.adobedtm.com/launch-ENde9f48d2f6f44912a449087f18f5f021-development.min.js',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
      chatDefaultRefreshTime: 30000,
      chatAutoRefresh: true,
      chatContainerRefrestTime: 3000,
      showEnrollment: false,
      clientId: 'd2052053-6a34-4b4d-8b5d-8b5f620a0373',
      scopes: 'services',
      responseType: 'token',
      translateApplicationName: 'wakanda',
      cardTopupSuccessCallbackUrl: 'callback/card-topup?type=SUCCESS',
      cardTopupFailCallbackUrl: 'callback/card-topup?type=FAIL',
      cardTopupTryAgainCallbackUrl: 'callback/card-topup?type=TRY_AGAIN',
      cardTopupErrorCallbackUrl: 'callback/card-topup?type=ERROR',
      addCardTopupSuccessCallbackUrl: 'callback/card?type=SUCCESS',
      addCardTopupFailCallbackUrl: 'callback/card?type=FAIL',
      addCardTopupTryAgainCallbackUrl: 'callback/card?type=TRY_AGAIN',
      addCardTopupErrorCallbackUrl: 'callback/card?type=ERROR',
      cardPaymentSuccessCallbackUrl: 'callback/card-payment?type=SUCCESS',
      cardPaymentFailCallbackUrl: 'callback/card-payment?type=FAIL',
      cardPaymentTryAgainCallbackUrl: 'callback/card-payment?type=TRY_AGAIN',
      cardPaymentErrorCallbackUrl: 'callback/card-payment?type=ERROR',
      adobeApp: 'merchant',
      apiaryBaseUrl: 'https://private-48f6f7-wakandamerchant.apiary-mock.com/stage',
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
      },
      appType: 'M',
      nedbankMoneyAndroidStoreLink: 'https://play.google.com/store/apps/details?id=za.co.nedbank&hl=en_US',
      nedbankMoneyIosStoreLink: 'https://apps.apple.com/za/app/nedbank-money/id1260981758',
      nedbankMoneyHuawaiGalleryStoreLink:
        'https://appgallery.cloud.huawei.com/uowap/index.html#/detailApp/C101769191?detailType=&subsource=&referrer=&s=&shareFrom=&v=&shareTo=&source=&id=&locale=&channelId=',
      facebookClientId: '2638723816386653',
      appleClientId: 'test-merchant.avo.africa',
      googleClientId: '181910611316-kdnlju4ek3j4nt8286c8gggssp10m57j.apps.googleusercontent.com',
      appVersion: packageJson.version,
      datadog: {
        applicationId: 'beca7293-0c9c-4baa-b34c-0fc4dc027ee7',
        clientToken: 'pub037a67def93f0e20734e068c9b565f7e',
        site: 'datadoghq.eu',
        service: 'pwa-merchant',
      },
      translations: ['https://test-localisation-cdn.avo.africa/en/merchant.json'],
    };
  } else if (window.location.hostname.indexOf('test') >= 0) {
    return {
      baseUrl: configuration.baseUrl,
      maintenanceUrl: 'https://test-localisation-cdn.avo.africa/maintenance-main-on.json',
      production: true,
      adobe: 'https://assets.adobedtm.com/6422e0f550a2/b86c62b26357/launch-f0d1df836558-development.min.js',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
      chatDefaultRefreshTime: 30000,
      chatAutoRefresh: true,
      chatContainerRefrestTime: 3000,
      showEnrollment: false,
      clientId: 'd2052053-6a34-4b4d-8b5d-8b5f620a0373',
      scopes: 'services',
      responseType: 'token',
      translateApplicationName: 'wakanda',
      cardTopupSuccessCallbackUrl: 'callback/card-topup?type=SUCCESS',
      cardTopupFailCallbackUrl: 'callback/card-topup?type=FAIL',
      cardTopupTryAgainCallbackUrl: 'callback/card-topup?type=TRY_AGAIN',
      cardTopupErrorCallbackUrl: 'callback/card-topup?type=ERROR',
      addCardTopupSuccessCallbackUrl: 'callback/card?type=SUCCESS',
      addCardTopupFailCallbackUrl: 'callback/card?type=FAIL',
      addCardTopupTryAgainCallbackUrl: 'callback/card?type=TRY_AGAIN',
      addCardTopupErrorCallbackUrl: 'callback/card?type=ERROR',
      cardPaymentSuccessCallbackUrl: 'callback/card-payment?type=SUCCESS',
      cardPaymentFailCallbackUrl: 'callback/card-payment?type=FAIL',
      cardPaymentTryAgainCallbackUrl: 'callback/card-payment?type=TRY_AGAIN',
      cardPaymentErrorCallbackUrl: 'callback/card-payment?type=ERROR',
      adobeApp: 'merchant',
      apiaryBaseUrl: 'https://private-48f6f7-wakandamerchant.apiary-mock.com/stage',
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
      },
      appType: 'M',
      nedbankMoneyAndroidStoreLink: 'https://play.google.com/store/apps/details?id=za.co.nedbank&hl=en_US',
      nedbankMoneyIosStoreLink: 'https://apps.apple.com/za/app/nedbank-money/id1260981758',
      nedbankMoneyHuawaiGalleryStoreLink:
        'https://appgallery.cloud.huawei.com/uowap/index.html#/detailApp/C101769191?detailType=&subsource=&referrer=&s=&shareFrom=&v=&shareTo=&source=&id=&locale=&channelId=',
      facebookClientId: '2638723816386653',
      appleClientId: 'test-merchant.avo.africa',
      googleClientId: '181910611316-kdnlju4ek3j4nt8286c8gggssp10m57j.apps.googleusercontent.com',
      appVersion: packageJson.version,
      datadog: {
        applicationId: 'beca7293-0c9c-4baa-b34c-0fc4dc027ee7',
        clientToken: 'pub037a67def93f0e20734e068c9b565f7e',
        site: 'datadoghq.eu',
        service: 'pwa-merchant',
      },
      translations: ['https://test-localisation-cdn.avo.africa/en/merchant.json'],
    };
  } else if (window.location.hostname.indexOf('stage') >= 0) {
    return {
      baseUrl: 'https://stage-merchant.avo.africa',
      maintenanceUrl: 'https://test-localisation-cdn.avo.africa/maintenance-main-on.json',
      production: true,
      adobe: 'https://assets.adobedtm.com/launch-EN34387d63f59742e88f905196d037bdcb-staging.min.js',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
      chatDefaultRefreshTime: 30000,
      chatAutoRefresh: true,
      chatContainerRefrestTime: 3000,
      showEnrollment: false,
      clientId: 'd2052053-6a34-4b4d-8b5d-8b5f620a0373',
      scopes: 'services',
      responseType: 'token',
      translateApplicationName: 'wakanda',
      cardTopupSuccessCallbackUrl: 'callback/card-topup?type=SUCCESS',
      cardTopupFailCallbackUrl: 'callback/card-topup?type=FAIL',
      cardTopupTryAgainCallbackUrl: 'callback/card-topup?type=TRY_AGAIN',
      cardTopupErrorCallbackUrl: 'callback/card-topup?type=ERROR',
      addCardTopupSuccessCallbackUrl: 'callback/card?type=SUCCESS',
      addCardTopupFailCallbackUrl: 'callback/card?type=FAIL',
      addCardTopupTryAgainCallbackUrl: 'callback/card?type=TRY_AGAIN',
      addCardTopupErrorCallbackUrl: 'callback/card?type=ERROR',
      cardPaymentSuccessCallbackUrl: 'callback/card-payment?type=SUCCESS',
      cardPaymentFailCallbackUrl: 'callback/card-payment?type=FAIL',
      cardPaymentTryAgainCallbackUrl: 'callback/card-payment?type=TRY_AGAIN',
      cardPaymentErrorCallbackUrl: 'callback/card-payment?type=ERROR',
      adobeApp: 'merchant',
      apiaryBaseUrl: 'https://private-48f6f7-wakandamerchant.apiary-mock.com/stage',
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
      },
      appType: 'M',
      nedbankMoneyAndroidStoreLink: 'https://play.google.com/store/apps/details?id=za.co.nedbank&hl=en_US',
      nedbankMoneyIosStoreLink: 'https://apps.apple.com/za/app/nedbank-money/id1260981758',
      nedbankMoneyHuawaiGalleryStoreLink:
        'https://appgallery.cloud.huawei.com/uowap/index.html#/detailApp/C101769191?detailType=&subsource=&referrer=&s=&shareFrom=&v=&shareTo=&source=&id=&locale=&channelId=',
      facebookClientId: '2638723816386653',
      appleClientId: 'stage-merchant.avo.africa',
      googleClientId: '181910611316-kdnlju4ek3j4nt8286c8gggssp10m57j.apps.googleusercontent.com',
      appVersion: packageJson.version,
      datadog: {
        applicationId: 'beca7293-0c9c-4baa-b34c-0fc4dc027ee7',
        clientToken: 'pub037a67def93f0e20734e068c9b565f7e',
        site: 'datadoghq.eu',
        service: 'pwa-merchant',
      },
      translations: ['https://test-localisation-cdn.avo.africa/en/merchant.json'],
    };
  } else if (window.location.hostname.indexOf('preview') >= 0) {
    return {
      baseUrl: 'https://preview-merchant.avo.africa',
      maintenanceUrl: 'https://localisation-cdn.avo.africa/maintenance-main-on.json',
      production: true,
      adobe: 'https://assets.adobedtm.com/launch-ENbb81158c301a48b8b88ee04c0abeba3f.min.js',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
      chatDefaultRefreshTime: 30000,
      chatAutoRefresh: true,
      chatContainerRefrestTime: 3000,
      showEnrollment: false,
      clientId: 'd2052053-6a34-4b4d-8b5d-8b5f620a0373',
      scopes: 'services',
      responseType: 'token',
      translateApplicationName: 'wakanda',
      cardTopupSuccessCallbackUrl: 'callback/card-topup?type=SUCCESS',
      cardTopupFailCallbackUrl: 'callback/card-topup?type=FAIL',
      cardTopupTryAgainCallbackUrl: 'callback/card-topup?type=TRY_AGAIN',
      cardTopupErrorCallbackUrl: 'callback/card-topup?type=ERROR',
      addCardTopupSuccessCallbackUrl: 'callback/card?type=SUCCESS',
      addCardTopupFailCallbackUrl: 'callback/card?type=FAIL',
      addCardTopupTryAgainCallbackUrl: 'callback/card?type=TRY_AGAIN',
      addCardTopupErrorCallbackUrl: 'callback/card?type=ERROR',
      cardPaymentSuccessCallbackUrl: 'callback/card-payment?type=SUCCESS',
      cardPaymentFailCallbackUrl: 'callback/card-payment?type=FAIL',
      cardPaymentTryAgainCallbackUrl: 'callback/card-payment?type=TRY_AGAIN',
      cardPaymentErrorCallbackUrl: 'callback/card-payment?type=ERROR',
      adobeApp: 'merchant',
      apiaryBaseUrl: '',
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
      },
      appType: 'M',
      nedbankMoneyAndroidStoreLink: 'https://play.google.com/store/apps/details?id=za.co.nedbank&hl=en_US',
      nedbankMoneyIosStoreLink: 'https://apps.apple.com/za/app/nedbank-money/id1260981758',
      nedbankMoneyHuawaiGalleryStoreLink:
        'https://appgallery.cloud.huawei.com/uowap/index.html#/detailApp/C101769191?detailType=&subsource=&referrer=&s=&shareFrom=&v=&shareTo=&source=&id=&locale=&channelId=',
      facebookClientId: '2638723816386653',
      appleClientId: 'merchant.avo.africa',
      googleClientId: '181910611316-kdnlju4ek3j4nt8286c8gggssp10m57j.apps.googleusercontent.com',
      appVersion: packageJson.version,
      datadog: {
        applicationId: 'beca7293-0c9c-4baa-b34c-0fc4dc027ee7',
        clientToken: 'pub037a67def93f0e20734e068c9b565f7e',
        site: 'datadoghq.eu',
        service: 'pwa-merchant',
      },
      translations: ['https://localisation-cdn.avo.africa/en/merchant.json'],
    };
  } else {
    return {
      baseUrl: 'https://merchant.avo.africa',
      maintenanceUrl: 'https://localisation-cdn.avo.africa/maintenance-main-on.json',
      production: true,
      adobe: 'https://assets.adobedtm.com/launch-ENbb81158c301a48b8b88ee04c0abeba3f.min.js',
      googleMapApiKey: 'AIzaSyDEwMl4vnPnQ0vXEGjKh2VHxeoXvcvGU1Q',
      chatDefaultRefreshTime: 30000,
      chatAutoRefresh: true,
      chatContainerRefrestTime: 3000,
      showEnrollment: false,
      clientId: 'd2052053-6a34-4b4d-8b5d-8b5f620a0373',
      scopes: 'services',
      responseType: 'token',
      translateApplicationName: 'wakanda',
      cardTopupSuccessCallbackUrl: 'callback/card-topup?type=SUCCESS',
      cardTopupFailCallbackUrl: 'callback/card-topup?type=FAIL',
      cardTopupTryAgainCallbackUrl: 'callback/card-topup?type=TRY_AGAIN',
      cardTopupErrorCallbackUrl: 'callback/card-topup?type=ERROR',
      addCardTopupSuccessCallbackUrl: 'callback/card?type=SUCCESS',
      addCardTopupFailCallbackUrl: 'callback/card?type=FAIL',
      addCardTopupTryAgainCallbackUrl: 'callback/card?type=TRY_AGAIN',
      addCardTopupErrorCallbackUrl: 'callback/card?type=ERROR',
      cardPaymentSuccessCallbackUrl: 'callback/card-payment?type=SUCCESS',
      cardPaymentFailCallbackUrl: 'callback/card-payment?type=FAIL',
      cardPaymentTryAgainCallbackUrl: 'callback/card-payment?type=TRY_AGAIN',
      cardPaymentErrorCallbackUrl: 'callback/card-payment?type=ERROR',
      adobeApp: 'merchant',
      apiaryBaseUrl: '',
      firebase: {
        apiKey: 'AIzaSyDW26cKeCFPBldWKOBAaB0s2xDuIBNqUbY',
        authDomain: 'avoafrica.firebaseapp.com',
        databaseURL: 'https://avoafrica.firebaseio.com',
        projectId: 'avoafrica',
        storageBucket: 'avoafrica.appspot.com',
        messagingSenderId: '181910611316',
        appId: '1:181910611316:web:e55bf4cf2676d0ee207821',
        measurementId: 'G-L0WNE5SPJK',
      },
      appType: 'M',
      nedbankMoneyAndroidStoreLink: 'https://play.google.com/store/apps/details?id=za.co.nedbank&hl=en_US',
      nedbankMoneyIosStoreLink: 'https://apps.apple.com/za/app/nedbank-money/id1260981758',
      nedbankMoneyHuawaiGalleryStoreLink:
        'https://appgallery.cloud.huawei.com/uowap/index.html#/detailApp/C101769191?detailType=&subsource=&referrer=&s=&shareFrom=&v=&shareTo=&source=&id=&locale=&channelId=',
      facebookClientId: '2638723816386653',
      appleClientId: 'merchant.avo.africa',
      googleClientId: '181910611316-kdnlju4ek3j4nt8286c8gggssp10m57j.apps.googleusercontent.com',
      appVersion: packageJson.version,
      datadog: {
        applicationId: 'beca7293-0c9c-4baa-b34c-0fc4dc027ee7',
        clientToken: 'pub037a67def93f0e20734e068c9b565f7e',
        site: 'datadoghq.eu',
        service: 'pwa-merchant',
      },
      translations: ['https://localisation-cdn.avo.africa/en/merchant.json'],
    };
  }
};
export const environment = getEnv();
