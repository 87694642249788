import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrowserStorageService } from '../services/browser-storage.service';
import { StorageKeys } from '../store/models/store.models';

@Injectable()
export class SessionIdInterceptor implements HttpInterceptor {
  constructor(private storageService: BrowserStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    const sessionId = this.storageService.get(StorageKeys.SESSION_ID);

    if (!request.url.includes('loc-saas') && !request.url.includes('configuration.json')) {
      request = request.clone({
        headers: request.headers.set('X-Avo-SessionId', sessionId),
      });
    }

    return next.handle(request);
  }
}
