import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe as LegacyCurrencyPipe } from '@angular/common';

// temporary duplicated pipe to use in avo 2. Remove when avo 2 is not used anymore.
@Pipe({
  name: 'avoCurrency',
})
export class AvoCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: LegacyCurrencyPipe) {}

  transform(value: number | string, inputCountryCode: string | undefined): string {
    const currencyCode = inputCountryCode === 'NAD' ? 'N$' : 'R';
    // this is a fix that removes string spaces due to a bug
    const valueWithoutSpaces = `${value}`.replace(/\s/g, '');
    return this.currencyPipe.transform(valueWithoutSpaces, currencyCode);
  }
}
