export class Constants {
  public static patterns = {
    split: /\B(?=(\d{3})+(?!\d))/g,
    splitPhoneLessSix: /(\d{2})(\d+)/g,
    splitPhoneMoreSix: /(\d{2})(\d{3})(\d+)/g,
    splitSaidLessEleven: /(\d{6})(\d+)/g,
    splitSaidMoreEleven: /(\d{6})(\d{4})(\d+)/g,
    amountOnly: /[^0-9.]/g,
    removeDecimal: /\./g,
  };

  public static keyCode = {
    delete: 46,
  };
}
