import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrowserStorageService } from '../services/browser-storage.service';
import { StorageKeys } from '../store/models/store.models';

@Injectable()
export class CountryCodeInterceptor implements HttpInterceptor {
  constructor(private storageService: BrowserStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    const countryCode = this.storageService.get(StorageKeys.COUNTRY_CODE);

    if (!request.url.includes('/configuration.json') && countryCode) {
      request = request.clone({
        headers: request.headers.set('X-AVO-CC', countryCode),
      });
    }

    return next.handle(request);
  }
}
