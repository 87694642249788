import { HttpErrorResponse } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs';
import { ITranslateVariables } from '../model/model';
import { TranslateFacade } from '../store/translate.facade';

@Pipe({
  name: 'translateVariables',
})
export class TranslateVariablesPipe implements PipeTransform {
  constructor(private translateFacade: TranslateFacade) {}

  transform(data: ITranslateVariables): string {
    let translatedString = '';
    this.translateFacade
      .getTranslateByKey$(data.messageCode)
      .pipe(take(1))
      .subscribe({
        next: (stringWithVariables: string) => {
          translatedString = this.getTranslatedVariables(stringWithVariables, data);
        },
        error: (error: HttpErrorResponse) => {
          translatedString = data.messageCode;
          console.error(`Cannot translate key ${data.messageCode}. Error: ${error.message}`);
        },
      });
    return translatedString;
  }

  private getTranslatedVariables(stringWithVariables: string, data: ITranslateVariables): string {
    return stringWithVariables.replace(/\$\w+/g, match => {
      const variableNameWithout$ = match.slice(1);
      return String(data.variables[variableNameWithout$] ?? match);
    });
  }
}
