import { Component, Input } from '@angular/core';
import { IMatrixMessage, IMessageEventJobCard, MessageEventType } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-job-card',
  styleUrls: ['./chat-widget-job-card.component.scss'],
  template: `
    <ui-item>
      <div class="row top align-stretch">
        <span class="flex-1 text-align-left">
          <p class="small light" zen-translate>chat.job_card.service</p>
          <b class="small">{{ message.content.jobName }}</b>
        </span>
        <ui-divider [dotted]="true" [vertical]="true"></ui-divider>
        <span class="flex-1 text-align-left">
          <p class="small light" zen-translate>chat.job_card.type</p>
          <b class="small">{{ message.content.urgent ? ('general.urgent' | translate) : 'Normal' }}</b>
        </span>
        <ui-divider [dotted]="true" [vertical]="true"></ui-divider>
        <span class="flex-1 text-align-left">
          <p class="small light" zen-translate>chat.job_card.location</p>
          <b class="small">{{ message.content.locationName }}</b>
        </span>
      </div>
      <ui-divider [solid]="true" [compact]="true"></ui-divider>
      <div class="row text-align-left">
        <p class="small light">{{ message.content.description }}</p>
      </div>
    </ui-item>
    <ui-divider [compact]="true"></ui-divider>
  `,
})
export class ChatWidgetJobCardComponent {
  @Input() message: IMatrixMessage<IMessageEventJobCard>;
  MessageEventType = MessageEventType;
}
