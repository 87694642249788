import { Pipe, PipeTransform } from '@angular/core';
import { UiSpinnerSize } from './ui-spinner.model';

interface IUiSpinnerDimensions {
  width: string;
  height: string;
}

@Pipe({
  name: 'lottieDimensions',
})
export class LottieDimensionsPipe implements PipeTransform {
  private readonly DIMENSIONS: Record<UiSpinnerSize, IUiSpinnerDimensions> = {
    [UiSpinnerSize.LARGE]: {
      width: '11.25rem',
      height: '11.25rem',
    },
    [UiSpinnerSize.SMALL]: {
      width: '2.5rem',
      height: '2.5rem',
    },
  };

  transform(size: UiSpinnerSize): IUiSpinnerDimensions {
    return this.DIMENSIONS[size];
  }
}
