import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iconSrc',
})
export class IconSrcPipe implements PipeTransform {
  transform(url: string, baseUrl: string): string {
    if (!url) {
      return '';
    }
    if (url.startsWith('http') || url.startsWith('www') || url.startsWith('./assets')) {
      return url;
    }
    return `${baseUrl}${url}`;
  }
}
