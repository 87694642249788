import { Pipe, PipeTransform } from '@angular/core';
import { ISaIdPipeParam } from '../models/common.model';
import { SaidUtil } from '../utils/said.util';

@Pipe({ name: 'saIdTransform', pure: false })
export class SaIdTransformPipe implements PipeTransform {
  maxLength: number;
  THOUSANDS_SEPARATOR: string;
  constructor() {
    this.maxLength = 15;
    this.THOUSANDS_SEPARATOR = ' ';
  }

  transform(value: string, param?: ISaIdPipeParam): string {
    if (param) {
      this.maxLength = param.maxLength || this.maxLength;
    }
    value = value || '';
    if (value === '') {
      return '';
    }
    const formattedValue = SaidUtil.saidSplit(value, this.maxLength);
    return formattedValue;
  }

  parse(value: string): string {
    value = value || '';
    value = value.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');
    return value;
  }
}
