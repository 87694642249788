import { Component, Input } from '@angular/core';
import { FadeInOutLongAnimation } from '../../utils/animations';

@Component({
  selector: 'ui-skeleton-screen',
  animations: [FadeInOutLongAnimation],
  template: `
    <ng-content [@fadeInOut] *ngIf="canShow; else skeletonScreenTemplate"></ng-content>

    <ng-template #skeletonScreenTemplate>
      <div
        *ngIf="!dashboard"
        class="ui-skeleton-screen-wrapper"
        [ngClass]="{
          'padded-content': !!padded
        }"
        [style.width]="width || 'initial'"
        [style.height]="height || 'initial'"
      >
        <div
          class="ui-skeleton-screen"
          [ngClass]="{
            compact: !!compact
          }"
        >
          <div *ngIf="!!line && !lineRows" class="line"></div>
          <div *ngIf="!!card && !cardRows" class="card"></div>
          <div *ngIf="!!rect && !rectColumns" class="rect"></div>

          <div *ngIf="!!rectColumns" class="rects-row">
            <span class="rect"></span>
            <span class="rect"></span>
            <span class="rect"></span>
            <span class="rect"></span>
          </div>
          <ng-container *ngIf="!!line && !!lineRows">
            <div *ngFor="let index of lineRows | skeletonScreenPipe; let i = index" [id]="i" class="line"></div>
          </ng-container>
          <ng-container *ngIf="!!list && !!times">
            <div *ngFor="let index of times | skeletonScreenPipe; let i = index" [id]="i" class="list"></div>
          </ng-container>
          <ng-container *ngIf="!!card && !!cardRows">
            <div *ngFor="let index of cardRows | skeletonScreenPipe; let i = index" [id]="i" class="card"></div>
          </ng-container>
        </div>
      </div>
      <div
        *ngIf="!!dashboard"
        class="ui-skeleton-screen-dashboard-wrapper"
        [ngClass]="{
          'padded-content': !!padded
        }"
      >
        <ng-container *ngIf="!!categories">
          <div
            class="text-align-center column center padded-content"
            [ngClass]="{
              background: !!background
            }"
          >
            <ui-divider [compact]="true"></ui-divider>
            <span class="loaderV2 label-medium"></span>
            <ui-divider [compact]="true"></ui-divider>
            <div class="row between" [style.width]="'100%'">
              <span class="loaderV2 rect-small rounded"></span>
              <span class="loaderV2 rect-small rounded"></span>
              <span class="loaderV2 rect-small rounded"></span>
              <span class="loaderV2 rect-small rounded"></span>
            </div>
            <ui-divider [compact]="true"></ui-divider>
            <div class="row between" [style.width]="'100%'">
              <span class="loaderV2 label-small"></span>
              <span class="loaderV2 label-small"></span>
              <span class="loaderV2 label-small"></span>
              <span class="loaderV2 label-small"></span>
            </div>
            <ui-divider [compact]="true"></ui-divider>
            <span class="loaderV2 half-circle"></span>
          </div>
        </ng-container>

        <ng-container *ngIf="!!widgetV1">
          <div
            class="padded-content text-align-left column left"
            [ngClass]="{
              background: !!background
            }"
          >
            <ui-divider [compact]="true"></ui-divider>

            <div class="row between" [style.width]="'100%'">
              <span class="loaderV2 label-medium"></span>
              <span class="loaderV2 label-small"></span>
            </div>
            <ui-divider [compact]="true"></ui-divider>
            <div class="row left">
              <span class="loaderV2 rect-large rounded"></span>
              <ui-divider [compact]="true" [vertical]="true"></ui-divider>
              <span class="loaderV2 rect-large rounded"></span>
              <ui-divider [compact]="true" [vertical]="true"></ui-divider>
              <span class="loaderV2 rect-large rounded"></span>
            </div>
            <ui-divider></ui-divider>
          </div>
        </ng-container>

        <ng-container *ngIf="!!widgetV2">
          <div
            class=" padded-content text-align-left column left"
            [ngClass]="{
              background: !!background
            }"
          >
            <ui-divider [compact]="true"></ui-divider>
            <span class="loaderV2 label-medium"></span>
            <ui-divider [compact]="true"></ui-divider>
            <div class="row left">
              <span class="loaderV2 rect-medium rounded"></span>
              <ui-divider [compact]="true" [vertical]="true"></ui-divider>
              <span class="loaderV2 rect-medium rounded"></span>
              <ui-divider [compact]="true" [vertical]="true"></ui-divider>
              <span class="loaderV2 rect-medium rounded"></span>
            </div>
            <ui-divider></ui-divider>
          </div>
        </ng-container>
        <ng-container *ngIf="!!widgetV3">
          <div
            class=" padded-content column"
            [ngClass]="{
              background: !!background
            }"
          >
            <ui-divider></ui-divider>
            <span class="loaderV2 rect-full rounded"></span>
            <ui-divider></ui-divider>
          </div>
        </ng-container>
      </div>
    </ng-template>
  `,
})
export class SkeletonScreenComponent {
  @Input() canShow: boolean;

  @Input() rect: boolean;
  @Input() line: boolean;
  @Input() card: boolean;
  @Input() list: boolean;
  @Input() dashboard: boolean;
  @Input() categories: boolean;
  @Input() widgetV1: boolean;
  @Input() widgetV2: boolean;
  @Input() widgetV3: boolean;
  @Input() background: boolean;

  @Input() compact: boolean;
  @Input() padded: boolean;

  @Input() times: string | number;
  @Input() rectColumns: boolean;
  @Input() lineRows: string | number;
  @Input() cardRows: string | number;

  @Input() width: string;
  @Input() height: string;
}
