import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[viewportDetector]',
})
export class ViewportDetectorDirective implements AfterViewInit, OnDestroy {
  @Input() viewportDetectorOptions: IntersectionObserverInit;

  /** Flag if viewport detector is enabled. */
  @Input() viewportDetector: boolean;

  /** Required intersection percentage to be marked as in viewport. Set value from 0 to 1. */
  @Input() viewportDetectorIntersectionRatio = 0;

  /** Flag if intersection callback should be called only once. Observer is destroyed then. */
  @Input() viewportDetectorOnce = false;

  @Output() inViewportChange = new EventEmitter<boolean>();

  private intersectionObserver: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (!this.viewportDetector) return;

    this.intersectionObserver = new IntersectionObserver(
      this.intersectionObserverCallback.bind(this),
      this.viewportDetectorOptions,
    );
    this.intersectionObserver.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  private intersectionObserverCallback = (entries: IntersectionObserverEntry[]): void =>
    entries.forEach(entry => {
      const inViewport = entry.isIntersecting && entry.intersectionRatio >= this.viewportDetectorIntersectionRatio;
      this.inViewportChange.emit(inViewport);
      if (inViewport && this.viewportDetectorOnce) this.intersectionObserver.disconnect();
    });
}
