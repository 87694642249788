import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { ModalSize, ModalType } from '@wakanda/wakanda-ui';
import { CommonComponent, CustomValidators } from '@wakanda/wakanda-core';
import { Validators } from '@angular/forms';
import { IContactSupportRequest } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-avo-business-contact-support-modal',
  styleUrls: ['./contact-support-modal.component.scss'],
  template: `
    <ui-modal
      [modal]="modalType"
      [size]="modalType === ModalType.FADE_IN ? ModalSize.SMALL : null"
      [template]="contactSupportModalTemplate"
      [closeButton]="true"
      [isVisible]="true"
      (onCloseClick)="closeModal.emit()"
    >
      <ng-template #contactSupportModalTemplate>
        <div class="padded-content">
          <div class="text-align-left">
            <h2 zen-translate>mechant.preonboarding_support.business_sales_team</h2>
            <ui-divider [compact]="true"></ui-divider>
            <p class="light" zen-translate>mechant.preonboarding_support.48hours_message</p>
            <ui-divider></ui-divider>

            <div class="row">
              <ui-labeled-input
                name="organisationName"
                [value]="getFormValue('organisationName')"
                (onChange)="setFormValue($event.name, $event.value)"
                [title]="'mechant.preonboarding_support.business_name' | translate"
              ></ui-labeled-input>
              <ui-divider [vertical]="true"></ui-divider>
              <ui-labeled-input
                name="contactName"
                [value]="getFormValue('contactName')"
                (onChange)="setFormValue($event.name, $event.value)"
                [title]="'mechant.preonboarding_support.contact_name' | translate"
              ></ui-labeled-input>
            </div>
            <ui-divider [compact]="true"></ui-divider>

            <div class="row">
              <ui-labeled-input
                name="email"
                [value]="getFormValue('email')"
                (onChange)="setFormValue($event.name, $event.value)"
                [title]="'mechant.preonboarding_support.email' | translate"
              ></ui-labeled-input>
              <ui-divider [vertical]="true"></ui-divider>
              <ui-labeled-input
                name="phone"
                [value]="getFormValue('phone')"
                (onChange)="setFormValue($event.name, $event.value)"
                [title]="'mechant.preonboarding_support.contact_number' | translate"
              ></ui-labeled-input>
            </div>
            <ui-divider [compact]="true"></ui-divider>

            <ui-labeled-input
              [title]="'mechant.preonboarding_support.comment_hint' | translate"
              [textarea]="true"
              name="text"
              [value]="getFormValue('text')"
              (onChange)="setFormValue($event.name, $event.value)"
            ></ui-labeled-input>
          </div>
          <ui-divider></ui-divider>
          <ui-button
            (onClick)="submitForm()"
            [disabled]="!form.valid"
            [content]="'mechant.preonboarding_support.confirm_button' | translate"
            [selectButton]="true"
            [callToActionButton]="true"
          ></ui-button>
        </div>
      </ng-template>
    </ui-modal>
  `,
})
export class ContactSupportModalComponent extends CommonComponent {
  @Output() formSubmit = new EventEmitter<IContactSupportRequest>();
  @Output() closeModal = new EventEmitter();
  @Input() modalType = ModalType.FADE_IN;
  ModalSize = ModalSize;
  ModalType = ModalType;

  constructor(injector: Injector) {
    super(injector);

    this.createForm([
      {
        name: 'organisationName',
        validators: [Validators.required],
      },
      {
        name: 'contactName',
        validators: [Validators.maxLength(64)],
      },
      {
        name: 'phone',
        validators: [
          Validators.required,
          CustomValidators.patternValidator(CustomValidators.valalidators.phoneWithCode, CustomValidators.errors.phone),
        ],
      },
      {
        name: 'email',
        validators: [
          CustomValidators.patternValidator(CustomValidators.valalidators.email, CustomValidators.errors.email),
        ],
      },
      {
        name: 'text',
        validators: [Validators.maxLength(1000)],
      },
    ]);
  }

  submitForm = (): void => {
    const request: IContactSupportRequest = this.getForm();
    this.formSubmit.emit(request);
  };
}
