import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-calendar-monthly-picker',
  templateUrl: './calendar-monthly-picker.component.html',
})
export class CalendarMonthlyPickerComponent implements AfterViewInit {
  @Input() rollValues = [3, 6, 9, 12, 18, 24, 36, 54];

  @Input() selected = 3;

  @Output() onChange = new EventEmitter<number>();

  private getSelectedNode = (): any => document.querySelector('.item.selected');

  onSelect = (value: number): void => {
    this.selected = value;
    setTimeout(() => {
      // get referenced node
      const node = this.getSelectedNode();
      // element is not found
      if (!node) return;
      this.onChange.emit(value);
      node.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' });
    }, 200);
  };

  ngAfterViewInit(): void {
    setTimeout(() => {
      // get referenced node
      const node = this.getSelectedNode();
      // element is not found
      if (!node) return;
      node.scrollIntoView({ inline: 'center', block: 'center', behavior: 'smooth' });
    }, 1000);
  }
}
