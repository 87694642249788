import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { UiSpinnerSize, UiSpinnerType } from './ui-spinner.model';

@Component({
  selector: 'ui-spinner',
  templateUrl: './ui-spinner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UiSpinnerComponent {
  /** Spinner color */
  @Input() type = UiSpinnerType.DARK;

  /** Spinner size (color file is resized) */
  @Input() size = UiSpinnerSize.LARGE;

  @HostBinding('class') classes = 'inline-block';
}
