<div class="pre-onboarding-footer" [class.border-radius]="borderRadius">
  <div class="column page-container">
    <div
      *ngIf="screenType === ResponsiveType.tablet || screenType === ResponsiveType.mobile"
      class="column top left basis-15 avo-width"
    >
      <b zen-translate>merchant.pre_onboarding_footer.know_more</b>
      <ui-divider [compact]="true"></ui-divider>
      <ui-button
        [content]="'merchant.pre_onboarding.watch_video_button' | translate"
        [selectButton]="true"
        [callToActionButton]="true"
      ></ui-button>
      <ui-divider></ui-divider>
    </div>
    <div class="row">
      <div
        [ngClass]="
          screenType === ResponsiveType.largeScreen || screenType === ResponsiveType.fullScreen
            ? 'indent-left basis-15'
            : 'basis-25'
        "
      >
        <b>B2C</b>
      </div>
      <ui-divider [vertical]="true"></ui-divider>
      <div class="basis-15">
        <b>B2B</b>
      </div>
    </div>

    <ui-divider [compact]="true"></ui-divider>
    <ui-divider [solid]="true"></ui-divider>
    <ui-divider [compact]="true"></ui-divider>

    <div class="row align-stretch links">
      <div
        *ngIf="screenType === ResponsiveType.largeScreen || screenType === ResponsiveType.fullScreen"
        class="column top left basis-15 avo-width"
      >
        <b zen-translate>merchant.pre_onboarding_footer.know_more</b>
        <ui-divider [compact]="true"></ui-divider>
        <ui-button
          [content]="'merchant.pre_onboarding.watch_video_button' | translate"
          [selectButton]="true"
          [callToActionButton]="true"
        ></ui-button>
        <ui-divider></ui-divider>
      </div>
      <div
        class="column top left"
        [ngClass]="
          screenType === ResponsiveType.largeScreen || screenType === ResponsiveType.fullScreen
            ? 'basis-15'
            : 'basis-25'
        "
      >
        <b (click)="scrollToTop(); navigateToB2c()" class="light" zen-translate>
          merchant.pre_onboarding.sell_to_consumers_bottom
        </b>
        <ng-container *ngFor="let item of sellingOnAvoMenu[0].subitems">
          <ui-divider [compact]="true"></ui-divider>
          <p (click)="scrollToTop(); navigateTo(item.link)" class="light">
            {{ item.title | translate }}
          </p>
        </ng-container>
      </div>
      <ui-divider [vertical]="true"></ui-divider>
      <div class="column top left basis-15">
        <b (click)="scrollToTop(); navigateToBusinessBuying()" zen-translate
          >merchant.pre_onboarding.buy_on_avo_bottom</b
        >
        <ui-divider [compact]="true"></ui-divider>
        <b (click)="navigateToB2b(); scrollToTop()" class="light" zen-translate>
          merchant.pre_onboarding.sell_to_business_bottom
        </b>

        <ui-divider [compact]="true"></ui-divider>
        <a [href]="'assets/images/Avo_returns_cancellation_policy.pdf'" target="_blank">{{
          'merchant.pre_onboarding.return_policy_bottom' | translate
        }}</a>

        <ui-divider [compact]="true"></ui-divider>
        <a [class.active]="router.url === routes.donations" (click)="navigateToDonations()" zen-translate>
          merchant.pre-onboarding.social_partners
        </a>
      </div>
      <ui-divider [vertical]="true"></ui-divider>
      <div class="column top left basis-15">
        <b (click)="scrollToTop(); navigateToSupport()" zen-translate>merchant.pre_onboarding.support</b>

        <ui-divider [vertical]="true"></ui-divider>
        <div class="column top left basis-15">
          <b (click)="openTandCs()">Avo Business T's & C's</b>
          <ui-divider [compact]="true"></ui-divider>
          <b (click)="openCampaignTandCs()" class="light"> Promotional Campaign T's & C's </b>
        </div>
      </div>
    </div>

    <ui-divider [compact]="true"></ui-divider>
    <ui-divider [solid]="true"></ui-divider>
    <ui-divider [compact]="true"></ui-divider>

    <div class="row between">
      <p zen-translate>©{{ year }}</p>
      <div class="row contact">
        <a zen-translate>merchant.pre_onboarding_footer_email</a>
        <a zen-translate>merchant.pre_onboarding_footer_phone</a>
        <a zen-translate>merchant.pre_onboarding_footer_address</a>
      </div>
    </div>
  </div>
</div>
