import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  show = (errorMessage: string, errorCode: string): void => {
    alert(`${errorMessage}: error code: ${errorCode}`);
  };
}
