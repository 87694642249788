import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalType } from '../modal/modal.component';
import { Assets } from '@avo/shared/ui';
import { Router } from '@angular/router';
import { UrlRoutes } from '@avo/shared/core';

// todo(lukas.jurygacek): remove avo 3 logic when not needed
@Component({
  selector: 'ui-sign-in-dialog',
  template: `
    <ui-modal
      [modal]="ModalType.ERROR"
      [isVisible]="true"
      [closeButton]="true"
      [template]="signInDialogTemplate"
      (onCloseClick)="onClose.emit()"
    >
      <ng-template #signInDialogTemplate>
        <ng-container *ngIf="isAvo3; else avo2Template">
          <div class="flex flex-col items-center gap-4 md:px-8 md:pb-8">
            <ui-icon [src]="Assets.image.errors.unauthorized" [size]="'100%'"></ui-icon>
            <div class="text-h4 font-bold md:text-h2 md:font-medium text-center md:text-left">
              You'll need to sign in to your Avo profile to proceed
            </div>
            <div class="text-h5 md:text-h4 mb-8 text-center md:text-left">
              Plus you'll earn points on your Avo account for every item purchased
            </div>
            <ui-button (onClick)="onAvo3Login()" content="Login" [selectButton]="true"></ui-button>
          </div>
        </ng-container>

        <ng-template #avo2Template>
          <ui-icon icon="experience" size="6rem"></ui-icon>

          <ui-divider></ui-divider>
          <ui-divider></ui-divider>

          <p zen-translate>guest.dialog.message</p>

          <ui-divider></ui-divider>
          <ui-divider></ui-divider>

          <ui-button
            [callToActionButton]="true"
            [selectButton]="true"
            aligned="bottom"
            [content]="'guest.dialog.yes_button' | translate"
            (onClick)="onLogin.emit()"
          ></ui-button>

          <ui-divider [compact]="true"></ui-divider>

          <ui-button
            [callToActionButton]="true"
            aligned="bottom"
            [content]="'guest.dialog.no_button' | translate"
            (onClick)="onClose.emit()"
          ></ui-button>
        </ng-template>
      </ng-template>
    </ui-modal>
  `,
})
export class SignInDialogComponent {
  readonly ModalType = ModalType;
  readonly Assets = Assets;

  @Input() isAvo3 = false;
  @Input() journeyType: string;

  @Output() onLogin = new EventEmitter();
  @Output() onClose = new EventEmitter();

  constructor(private router: Router) {}

  onAvo3Login(): void {
    this.router.navigate([UrlRoutes.auth.login.landing], {
      queryParams: { returnUrl: this.router.url, journeyType: this.journeyType },
    });
  }
}
