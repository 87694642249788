import { Action } from '@ngrx/store';
import * as EVENT from './maintenance.events';
import { IApplicationMaintenanceResponse } from '../../models/application.model';

// Check maintenance
export class CheckMaintenanceRequest implements Action {
  readonly type = EVENT.MAINTENANCE_CHECK_MAINTENANCE_REQUEST;

  constructor(public taskId: string) {}
}

export class CheckMaintenanceReceived implements Action {
  readonly type = EVENT.MAINTENANCE_CHECK_MAINTENANCE_RECEIVED;

  constructor(public taskId: string, public data: IApplicationMaintenanceResponse) {}
}

export class CheckMaintenanceError implements Action {
  readonly type = EVENT.MAINTENANCE_CHECK_MAINTENANCE_ERROR;

  constructor(public taskId: string) {}
}

export type MaintenanceActions = CheckMaintenanceRequest | CheckMaintenanceReceived;
