import get from 'lodash-es/get';
import { WebcamImage } from 'ngx-webcam';

export interface IFileUpload {
  name: string;
  data: File;
}

export class FilesUtil {
  public static dataURItoBlob = (dataURI: string): Blob => {
    // convert base64 to raw binary data held in a string
    const byteString = atob(get(dataURI.split(','), 1));

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const viewIntoTheBuffer = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      viewIntoTheBuffer[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  };

  public static handleWebcamImage = (photo: WebcamImage): FormData => {
    const blob = FilesUtil.dataURItoBlob(photo.imageAsDataUrl);
    const file = new FormData();
    file.append('file', blob);
    return file;
  };

  public static handleFile = (image: IFileUpload): FormData => {
    const file = new FormData();
    file.append('file', image.data, image.name);
    return file;
  };

  public static getBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
}
