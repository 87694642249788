import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IZenAuthNextStepTransactionSigningResponse,
  IZenAuthTransactionSigningAuthorizationHeaders,
  IZenAuthTransactionSigningAuthorizeRequest,
  TransactionSigningOtpChannel,
} from '@wakanda/zenauth';
import get from 'lodash-es/get';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-authorization-dialog-otp',
  template: `
    <ui-modal
      [modal]="modalTypes.SLIDE_IN_UP"
      title="Authorization"
      [isVisible]="true"
      [template]="authorizationDialogTemplate"
    >
      <ng-template #authorizationDialogTemplate>
        <ng-container *ngIf="nextStep?.otpMethod as method">
          <ui-icon icon="PIN" size="7rem"></ui-icon>

          <ui-divider></ui-divider>

          <h2 class="text-align-center" zen-translate>enroll.otp.title</h2>
          <ui-divider [compact]="true"></ui-divider>
          <p
            class="text-align-center small light"
            *ngIf="method?.channel === channelTypes.SMS || method?.channel === channelTypes.BANK_SMS"
          >
            {{ 'enroll.otp.message$' | translate : { $1: method?.sentTo } }}
          </p>
          <p class="text-align-center small light" *ngIf="method?.channel === channelTypes.EMAIL">
            {{ 'enroll.otp.message_email$' | translate : { $1: method?.sentTo } }}
          </p>

          <ui-pin
            [pin]="pin"
            [digits]="method?.channel === channelTypes.BANK_SMS ? 6 : 5"
            [showAllDigits]="true"
            (onChange)="onOTPChange($event)"
            [errorMessage]="otpErrorMessage | translate"
            [incorrectPin]="!!otpErrorMessage"
          ></ui-pin>
          <a
            class="text-align-center"
            *ngIf="method?.channel !== channelTypes.BANK_SMS"
            (click)="onResendOTP()"
            zen-translate
            >enroll.otp.resend</a
          >

          <ui-divider></ui-divider>
          <ui-divider></ui-divider>

          <ui-button
            aligned="bottom"
            [disabled]="method?.channel === channelTypes.BANK_SMS ? otp?.length !== 6 : otp?.length !== 5"
            [callToActionButton]="true"
            (onClick)="onConfirmOTP()"
            [selectButton]="true"
            [content]="'enroll.otp.verify_button' | translate"
          >
          </ui-button>
          <ui-divider [compact]="true"></ui-divider>
          <a class="text-align-center" (click)="onCancel.emit()">{{ 'general.cancel' | translate }}</a>
        </ng-container>
      </ng-template>
    </ui-modal>
  `,
})
export class AuthorizationDialogOtpComponent {
  @Input() nextStep: IZenAuthNextStepTransactionSigningResponse;
  @Input() transactionAuthId: string;
  @Output() onCancel = new EventEmitter();
  @Output() onConfirm = new EventEmitter<IZenAuthTransactionSigningAuthorizeRequest>();
  @Output() onSucceed = new EventEmitter<IZenAuthTransactionSigningAuthorizationHeaders>();
  @Output() onSwimSwapDetected = new EventEmitter();
  //
  otp: string;
  otpErrorMessage: string;
  pin: any = {};
  simSwapCode = 'simswap_error_blocked';
  simSwapMessageCode = 'error.onboarding.simswap';
  modalTypes = ModalType;
  channelTypes = TransactionSigningOtpChannel;

  checkForSimSwapError = (error: any): void => {
    if (
      get(error, ['error', 'error', 'messageCode']) === this.simSwapMessageCode ||
      get(error, ['error', 'error', 'code']) === this.simSwapCode
    ) {
      this.onSwimSwapDetected.emit();
    }
  };

  onOTPChange = (otp: string[]): void => {
    if (!otp || !otp.length) return;
    this.otpErrorMessage = null;
    this.otp = otp.join('');
  };

  onResendOTP(): void {
    const request: IZenAuthTransactionSigningAuthorizeRequest = {
      transactionAuthId: this.transactionAuthId,
      authRequest: {
        otpAuth: {
          otp: '',
          resend: true,
        },
      },
      onSucceed: (): void => {},
      onData: (): void => {},
      onFailure: (error?: any): void => {
        this.checkForSimSwapError(error);
        this.otpErrorMessage = error.error.error.message;
      },
    };
    this.onConfirm.emit(request);
  }

  onConfirmOTP = (): void => {
    const request: IZenAuthTransactionSigningAuthorizeRequest = {
      transactionAuthId: this.transactionAuthId,
      authRequest: {
        otpAuth: {
          otp: this.otp,
          resend: false,
        },
      },
      // extra call back after succeeded authorization, which call original action enriched with headers
      onSucceed: (authHeaders: IZenAuthTransactionSigningAuthorizationHeaders): void =>
        this.onSucceed.emit(authHeaders),
      onData: (): void => {
        this.pin = {};
      },
      onFailure: (error?: any): void => {
        this.pin = {};
        if (!error) {
          return;
        }
        this.checkForSimSwapError(error);
        this.otpErrorMessage = error.error.error.messageCode;
      },
    };
    this.onConfirm.emit(request);
  };
}
