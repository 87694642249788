<div class="row align-stretch between steps">
  <ng-container *ngFor="let item of steps; let last = last">
    <div class="step-item column top left text-align-left basis-25">
      <b class="light" zen-translate>{{ item?.step }}</b>
      <span class="line"></span>
      <ui-divider [compact]="true"></ui-divider>
      <h3 zen-translate>{{ item?.title }}</h3>
      <ui-divider [compact]="true"></ui-divider>
      <p class="light" zen-translate>{{ item?.text }}</p>
    </div>
    <ui-divider *ngIf="!last" [vertical]="true"></ui-divider>
  </ng-container>
</div>
