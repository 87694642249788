import { Component, Input } from '@angular/core';
import { IMatrixMessage, IMessageEventMap } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-map',
  template: `
    <div class="chat-widget">
      <div
        class="ui-chat-message cursor-pointer"
        [ngClass]="{ 'incoming': !message?.sender_self, 'outgoing': message?.sender_self, 'hey-ned': heyNedTheme }"
        (click)="messageClick()"
      >
        <div class="column">
          <img class="message-img" [alt]="message?.content?.address" [src]="message?.content?.imageUrl" />
          <b>{{ message?.content?.address }}</b>
          <p class="small light">
            {{ 'chat.map.tap' | translate }}
          </p>
        </div>
      </div>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
  `,
})
export class ChatWidgetMapComponent {
  @Input() message: IMatrixMessage<IMessageEventMap>;
  @Input() heyNedTheme: boolean;

  messageClick(): void {
    const lat = parseFloat(this.message?.content?.latitude);
    const lon = parseFloat(this.message?.content?.longitude);
    window.open(`http://www.google.com/maps/place/${lat},${lon}`, '_blank');
  }
}
