import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderDate' })
export class OrderDatePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    return formatDate(new Date(value), 'dd MMM YYYY', 'en-US');
  }
}
