import { Action } from '@ngrx/store';
import {
  ICoupon,
  ILoyaltyBalance,
  ILoyaltyFxConvert,
  ILoyaltyFxConvertResponse,
  ILoyaltyFxLinkResponse,
  ILoyaltyFxPartner,
  ILoyaltyFxQuoteRequest,
  ILoyaltyFxQuoteResponse,
  ILoyaltyPoints,
  ILoyaltyWalletTransaction,
} from '../../models/reward.model';
import { EVENTS } from './my-rewards.events';

export class MyRewardsGetTransactionsRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_TRANSACTIONS_REQUEST;
  constructor(public taskId: string, public walletId: string) {}
}

export class MyRewardsGetTransactionsResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_TRANSACTIONS_RECEIVED;
  constructor(public taskId: string, public data: ILoyaltyWalletTransaction[]) {}
}

export class MyRewardsGetCouponsRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_COUPONS_REQUEST;
  constructor(public taskId: string) {}
}

export class MyRewardsGetCouponsResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_COUPONS_RECEIVED;
  constructor(public taskId: string, public data: ICoupon[]) {}
}

export class MyRewardsGetCouponDetailRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_COUPON_DETAIL_REQUEST;
  constructor(public taskId: string, public couponId: string) {}
}

export class MyRewardsGetCouponDetailResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_COUPON_DETAIL_RECEIVED;
  constructor(public taskId: string, public data: ICoupon) {}
}

export class MyRewardsGetPointsRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_POINTS_REQUEST;
  constructor(public taskId: string) {}
}

export class MyRewardsGetPointsResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_POINTS_RECEIVED;
  constructor(public taskId: string, public data: ILoyaltyPoints) {}
}

export class MyRewardsGetBalanceRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_BALANCE_REQUEST;
  constructor(public taskId: string, public walletId: string) {}
}

export class MyRewardsGetBalanceResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_BALANCE_RECEIVED;
  constructor(public taskId: string, public data: ILoyaltyBalance) {}
}

export class MyRewardsGetLoyaltyFXRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_REQUEST;
  constructor(public taskId: string, public OnSucceeded: (data: ILoyaltyFxPartner[]) => void) {}
}

export class MyRewardsGetLoyaltyFXResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_RECEIVED;
  constructor(public taskId: string, public data: ILoyaltyFxPartner[]) {}
}

export class MyRewardsGetLoyaltyFXLinkRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_LINK_REQUEST;
  constructor(
    public taskId: string,
    public programId: string,
    public OnSucceeded: (data: ILoyaltyFxLinkResponse) => void,
  ) {}
}

export class MyRewardsGetLoyaltyFXLinkResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_LINK_RECEIVED;
  constructor(public taskId: string, public data: ILoyaltyFxLinkResponse) {}
}

export class MyRewardsGetLoyaltyFXUnlinkRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_UNLINK_REQUEST;
  constructor(public taskId: string, public programId: string, public OnSucceeded: () => void) {}
}

export class MyRewardsGetLoyaltyFXUnlinkResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_UNLINK_RECEIVED;
  constructor(public taskId: string) {}
}

export class MyRewardsGetLoyaltyFXConvertRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_CONVERT_REQUEST;
  constructor(
    public taskId: string,
    public programId: string,
    public payload: ILoyaltyFxConvert,
    public OnSucceeded: (data: ILoyaltyFxConvertResponse) => void,
  ) {}
}

export class MyRewardsGetLoyaltyFXConvertResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_CONVERT_RECEIVED;
  constructor(public taskId: string, public data: ILoyaltyFxConvertResponse) {}
}

export class MyRewardsGetLoyaltyFXQuoteRequestAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_QUOTE_REQUEST;
  constructor(
    public taskId: string,
    public programId: string,
    public payload: ILoyaltyFxQuoteRequest,
    public OnSucceeded: (data: ILoyaltyFxQuoteResponse) => void,
  ) {}
}

export class MyRewardsGetLoyaltyFXQuoteResponseAction implements Action {
  readonly type = EVENTS.MY_REWARDS_LOYALTY_FX_QUOTE_RECEIVED;
  constructor(public taskId: string, public data: ILoyaltyFxQuoteResponse) {}
}
