import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ILocationState, LOCATION_FEATURE_KEY } from './location.reducer';

const locationState = createFeatureSelector<ILocationState>(LOCATION_FEATURE_KEY);

export const getSearchedData = createSelector(locationState, ({ searched }) => searched);

export const getLocations = createSelector(locationState, ({ locations }) => locations);

export const getMerchantLocations = createSelector(locationState, ({ merchantLocations }) => merchantLocations);

export const getValidMerchantLocations = createSelector(locationState, ({ merchantLocations }) =>
  merchantLocations?.filter(l => l.address.valid),
);

export const getCurrentLocation = createSelector(locationState, ({ currentLocation }) => currentLocation);

export const getGooglePlaceSearch = createSelector(locationState, ({ googlePlaceSearch }) => googlePlaceSearch);
