import * as dayjs from 'dayjs';

enum WeekDays {
  MONDAY = 1,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
}

export class DateFormats {
  public static getFormatedData(date: any, format = 'DD-MM-YYYY'): string {
    return dayjs(date).format(format);
  }

  public static getTodayDate = (format?: string): string => dayjs().format(format);

  public static getStartOfDate = (unitOfTime: any, format?: string): string =>
    dayjs().startOf(unitOfTime).format(format);

  public static getEndOfDate = (unitOfTime: any, format?: string): string => dayjs().endOf(unitOfTime).format(format);

  public static getFirstDayOfWeekDate = (format?: string, weekFirstDay: number = WeekDays.MONDAY): string =>
    dayjs().startOf('week').day(weekFirstDay).format(format);

  public static getLastDayOfWeekDate = (format?: string, weekLastDay: number = WeekDays.SUNDAY): string =>
    dayjs().endOf('week').day(weekLastDay).format(format);

  public static getThisMonthDate = (format: string): string =>
    `${dayjs().startOf('month').format(format)} - ${dayjs().endOf('month').format(format)}`;

  public static getValidDate = (date: string, format: string): string => dayjs(date, format).format();
}
