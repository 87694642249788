import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { UiSpinnerSize, UiSpinnerType } from '@avo/shared/ui/spinner';
import { HashStringPrefix, ICommonOnChangeOutput } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-button',
  template: `
    <button
      *ngIf="!actionButton"
      preventDoubleClick
      #buttonRef
      [attr.type]="type"
      [attr.name]="name"
      [attr.id]="content | hashString : hashType.BUTTON"
      [class]="class + ' ' + (!!theme ? theme : '')"
      [style.width]="width"
      [style.height]="height"
      [ngClass]="{
        content: !!content,
        disabled: !!disabled,
        selected: !!selectButton,
        selectedBlue: !!selectBlueButton,
        selectedBlack: !!selectBlackButton,
        selectedWhite: !!selectWhiteButton,
        fluid: !!fluid,
        borderless: !!borderless,
        padded: !!padded,
        alignedTop: aligned === 'top',
        alignedBottom: aligned === 'bottom',
        compact: !!compact,
        callToAction: !!callToActionButton,
        small: !!small
      }"
      (throttledClick)="handleClick()"
      (focus)="handleFocus($event)"
    >
      <ui-spinner
        [type]="selectButton ? UiSpinnerType.LIGHT : UiSpinnerType.DARK"
        [size]="UiSpinnerSize.SMALL"
        *ngIf="spinner; else noSpinnerTemplate"
        class="mx-auto"
      ></ui-spinner>
      <ng-template #noSpinnerTemplate>
        <span *ngIf="content" class="button-content row center">
          <ng-container *ngIf="!!icon && !dropdown">
            <ui-icon [icon]="icon" [size]="iconSize || '1.25rem'"></ui-icon>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
          </ng-container>

          {{ content }}

          <ng-container *ngIf="!!dropdown">
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <ui-icon [icon]="icon || 'down'" [size]="iconSize || '0.7rem'"></ui-icon>
          </ng-container>
        </span>
        <ng-content></ng-content>
        <div class="badge-label" *ngIf="!!badgeLabel">
          <p class="small">{{ badgeLabel }}</p>
        </div>
      </ng-template>
    </button>

    <div *ngIf="!!actionButton" [class]="'action-button ' + (!!class ? class : '') + ' ' + (!!theme ? theme : '')">
      <button
        preventDoubleClick
        [attr.type]="type"
        [attr.name]="name"
        [attr.id]="content | hashString : hashType.BUTTON"
        [ngClass]="{
          disabled: !!disabled
        }"
        (throttledClick)="handleClick()"
        (focus)="handleFocus($event)"
        [style.width]="width"
      >
        <ui-spinner
          [type]="selectButton ? UiSpinnerType.LIGHT : UiSpinnerType.DARK"
          [size]="UiSpinnerSize.SMALL"
          *ngIf="spinner; else noSpinnerTemplate"
          class="mx-auto"
        ></ui-spinner>
        <ng-template #noSpinnerTemplate>
          <ui-icon *ngIf="!!icon" [icon]="icon" [size]="iconSize || '3.3rem'"></ui-icon>
          <img *ngIf="!!imageSrc" [alt]="imageSrc" [src]="imageSrc" />
          <ng-content></ng-content>
        </ng-template>
      </button>
      <div *ngIf="!!description" class="button-description">
        {{ description }}
      </div>
      <div class="badge-label" *ngIf="!!badgeLabel">
        <p class="small">{{ badgeLabel }}</p>
      </div>
    </div>

    <!-- dropdown menu -->
    <div #dropdownWrapperRef [class]="'dropdown-wrapper ' + direction">
      <div *ngIf="!!dropdown && !!isOpen" class="dropdown-menu" [style.top]="upPosition">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class ButtonComponent {
  hashType = HashStringPrefix;
  UiSpinnerType = UiSpinnerType;
  UiSpinnerSize = UiSpinnerSize;
  @Input() spinner = false;
  @Input() class: string;
  @Input() type: string;
  @Input() name: string;
  @Input() value: any;
  @Input() icon: string;
  @Input() iconSize: string;
  @Input() imageSrc: string;
  @Input() content: string;
  @Input() width: string;
  @Input() height: string;
  @Input() aligned: string;
  @Input() theme: string;
  @Input() fluid: boolean;
  @Input() padded: boolean;
  @Input() borderless: boolean;
  @Input() compact: boolean;
  @Input() badgeLabel: string;
  @Input() dropdown: boolean;
  @Input() small: boolean;

  // disabled
  @Input() disabled: boolean;

  // action button
  @Input() actionButton: boolean;
  @Input() description: string;

  // selected button
  @Input() selectButton: boolean;
  @Input() selectBlueButton: boolean;
  @Input() selectBlackButton: boolean;
  @Input() selectWhiteButton: boolean;

  // call to action (CTA)
  @Input() callToActionButton: boolean;

  @Output() onClick = new EventEmitter<ICommonOnChangeOutput>();
  @Output() onFocus = new EventEmitter<any>();

  @ViewChild('dropdownWrapper') dropdownWrapperRef: ElementRef;
  @ViewChild('buttonRef') buttonRef: ElementRef;

  /** Flag if button is used on dark background */
  @Input() isOnDarkBackground = false;

  @HostBinding('class')
  get classes(): string[] {
    const output = ['ui2_button'];

    if (this.fluid) {
      output.push('is-fluid');
    }

    if (this.isOnDarkBackground && this.disabled) {
      output.push('is-on-dark-background');
    }

    return output;
  }

  direction = 'down';
  upPosition = '0px';
  isOpen: boolean;

  handleFocus = (event: MouseEvent): void => {
    if (this.disabled === true) return;
    this.onFocus.emit(event);
  };

  handleClick = (): void => {
    if (this.disabled === true) return;

    if (this.dropdown) {
      this.isOpen = !this.isOpen;
    }
    this.onClick.emit({ name: this.name, value: this.value });
  };
}
