import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'avo-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.scss'],
})
export class ExternalComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() url: string | undefined;
  @Input() fullscreen = true;
  @Output() iframeEvent = new EventEmitter<any>();
  @ViewChild('iframe', { static: true }) iframe: ElementRef;

  ngOnInit(): void {
    if (this.url) {
      this.iframe.nativeElement.contentWindow.location.replace(this.url);
    }
  }

  onLoad = (): void => {
    this.iframeEvent.emit();
  };

  handleIframeMessage = (e: MessageEvent): void => {
    this.iframeEvent.emit(e);
  };

  ngAfterViewInit(): void {
    window.addEventListener('message', this.handleIframeMessage);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleIframeMessage);
  }
}
