import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ErrorUtility } from '@wakanda/wakanda-core';
import { ZenAuthFacade } from '@wakanda/zenauth';
import { TranslateFacade } from '@wakanda/zentity-translate-store';
import { Observable, Subscription, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AdobeService } from '../services/adobe.service';

@Injectable()
export class AdobeErrorInterceptor implements HttpInterceptor, OnDestroy {
  private hiddenAdobeUrls = [
    /\/v\d\/customer\/currentLocation/,
    /chat\/v\d\/client\/sync/,
    /dashboard\/v\d\/searchClick/,
    /maintenance-main-on\.json/i,
  ];
  private isAuthorized: boolean;
  private authFacadeSubscription: Subscription;

  constructor(
    private adobeService: AdobeService,
    private translateFacade: TranslateFacade,
    private authFacade: ZenAuthFacade,
  ) {
    this.authFacadeSubscription = this.authFacade.isAuthorized$.subscribe(isAuth => (this.isAuthorized = isAuth));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = ErrorUtility.getErrorMessageFromApiResponse(error);
        const isOtpError = error?.status === 428;
        const isEmittedUrl = this.hiddenAdobeUrls?.some(regex => regex.test(error?.url));
        const isLoggedOut401 = error?.status === 401 && !this.isAuthorized;
        if (!isOtpError && !isEmittedUrl && !isLoggedOut401) {
          let translate = '';
          const key = errorMessage;
          this.translateFacade
            .getTranslateByKey$(key)
            .pipe(
              take(1),
              catchError((error: HttpErrorResponse) => {
                console.error(`An error has occurred with key ${key}. Error message: ${error.message}`);
                return key;
              }),
            )
            .subscribe(response => {
              if (response === key) console.warn(`Missing translation: ${key}`);
              translate = response;
            });

          // TODO: the next line is used to catch and debug error data anomalies (2023-01-24)
          if (translate === 'Unspecified') translate = `Unspecified (v2 ${request?.url || 'missing request details'})`;
          this.adobeService.executeAdobeErrorEvent(translate, request, error);
        }

        return throwError(() => error);
      }),
    );
  }

  ngOnDestroy(): void {
    this.authFacadeSubscription.unsubscribe();
  }
}
