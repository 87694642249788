import { Action } from '@ngrx/store';
import {
  INewPasswordRequest,
  IRecoveryNextStepResponse,
  IRecoveryPasswordRequest,
  IRecoveryStartRequest,
  IRecoveryUsernameRequest,
  IVerifyOTPRequest,
} from '../../models/recovery.model';
import { EVENT } from './recovery.events';

export class RecoveryStartRequestAction implements Action {
  readonly type = EVENT.RECOVERY_START_REQUEST;
  constructor(
    public taskId: string,
    public payload: IRecoveryStartRequest,
    public onSucceeded: (start: IRecoveryNextStepResponse) => void,
  ) {}
}

export class RecoveryStartResponseAction implements Action {
  readonly type = EVENT.RECOVERY_START_RECEIVED;
  constructor(public taskId: string, public payload: IRecoveryNextStepResponse) {}
}

export class RecoveryUsernameRequestAction implements Action {
  readonly type = EVENT.RECOVERY_USERNAME_REQUEST;
  constructor(
    public taskId: string,
    public payload: IRecoveryUsernameRequest,
    public onSucceeded?: (nextStep: IRecoveryNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class RecoveryUsernameResponseAction implements Action {
  readonly type = EVENT.RECOVERY_USERNAME_RECEIVED;
  constructor(public taskId: string, public payload: IRecoveryNextStepResponse) {}
}

export class RecoveryPasswordRequestAction implements Action {
  readonly type = EVENT.RECOVERY_PASSWORD_REQUEST;
  constructor(
    public taskId: string,
    public payload: IRecoveryPasswordRequest,
    public onSucceeded: (nextStep: IRecoveryNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class RecoveryPasswordResponseAction implements Action {
  readonly type = EVENT.RECOVERY_PASSWORD_RECEIVED;
  constructor(public taskId: string, public payload: IRecoveryNextStepResponse) {}
}

export class RecoveryPasswordVerifyOtpRequestAction implements Action {
  readonly type = EVENT.RECOVERY_PASSWORD_VERIFY_OTP_REQUEST;
  constructor(
    public taskId: string,
    public payload: IVerifyOTPRequest,
    public onSucceeded: (nextStep: IRecoveryNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class RecoveryPasswordVerifyOtpResponseAction implements Action {
  readonly type = EVENT.RECOVERY_PASSWORD_VERIFY_OTP_RECEIVED;
  constructor(public taskId: string, public payload: IRecoveryNextStepResponse) {}
}

export class RecoveryPasswordNewPasswordRequestAction implements Action {
  readonly type = EVENT.RECOVERY_PASSWORD_NEW_REQUEST;
  constructor(
    public taskId: string,
    public payload: INewPasswordRequest,
    public notificationText: string,
    public onSucceeded: (nextStep: IRecoveryNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class RecoveryPasswordNewPasswordResponseAction implements Action {
  readonly type = EVENT.RECOVERY_PASSWORD_NEW_RECEIVED;
  constructor(public taskId: string, public payload: IRecoveryNextStepResponse) {}
}

export class RecoveryClearAction implements Action {
  readonly type = EVENT.RECOVERY_CLEAR;
}

export type RecoveryActions =
  | RecoveryStartRequestAction
  | RecoveryStartResponseAction
  | RecoveryUsernameRequestAction
  | RecoveryUsernameResponseAction
  | RecoveryPasswordRequestAction
  | RecoveryPasswordResponseAction
  | RecoveryPasswordVerifyOtpRequestAction
  | RecoveryPasswordVerifyOtpResponseAction
  | RecoveryPasswordNewPasswordRequestAction
  | RecoveryPasswordNewPasswordResponseAction
  | RecoveryClearAction;

export const recoveryActions = {
  RecoveryStartRequestAction,
  RecoveryStartResponseAction,
  RecoveryUsernameRequestAction,
  RecoveryUsernameResponseAction,
  RecoveryPasswordRequestAction,
  RecoveryPasswordResponseAction,
  RecoveryPasswordVerifyOtpRequestAction,
  RecoveryPasswordVerifyOtpResponseAction,
  RecoveryPasswordNewPasswordRequestAction,
  RecoveryPasswordNewPasswordResponseAction,
  RecoveryClearAction,
};
