import { Pipe, PipeTransform } from '@angular/core';
import { IMatrixMessage, MessageEventType } from '@wakanda/agent-chat-model';

@Pipe({ name: 'chatInputEndChat', pure: true })
export class ChatInputEndChatPipe implements PipeTransform {
  transform(messages: IMatrixMessage[], chatType = 'default'): boolean {
    if (chatType === 'default') return false;
    if (!messages || messages.length === 0) return false;
    const endChat = messages.find(m => m.content.msgtype === MessageEventType.ENDCHAT);
    return !!endChat;
  }
}
