import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAgentChat } from '@wakanda/agent-chat-model';

enum ChatItemType {
  AGENT = 'agent',
  ASSOCIATED = 'associated',
}

@Component({
  selector: 'ui-chat-item',
  template: `
    <div class="ui-chat-item" (click)="onClick.emit(data)">
      <ng-container [ngTemplateOutlet]="showAs === type.ASSOCIATED && chatAssociatedItemTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="showAs === type.AGENT && chatAgentItemTemplate"></ng-container>
    </div>

    <ng-template #chatAssociatedItemTemplate>
      <b>{{ data?.owner?.name }}</b>
    </ng-template>

    <ng-template #chatAgentItemTemplate>
      <div>
        <b>{{ data?.name }}</b>
        <i>{{ data?.status?.status === 'free' ? 'online' : 'busy' }}</i>
      </div>
    </ng-template>
  `,
})
export class ChatItemComponent {
  type = ChatItemType;
  @Input() data: any;
  @Input() showAs: ChatItemType | string;
  @Output() onClick = new EventEmitter<IAgentChat>();
}
