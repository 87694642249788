import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WakandaCoreModule } from '@wakanda/wakanda-core';
import {
  DeliveryTimeTranslatePipe,
  StatusTranslatePipe,
  TranslateDirective,
  TranslatePipe,
} from '@wakanda/zentity-translate-store';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddImagesComponent } from './components/add-images/add-images.component';
import { AuthorizationDialogApproveitComponent } from './components/authorization-dialog-approveit/authorization-dialog-approveit.component';
import { AuthorizationDialogOtpComponent } from './components/authorization-dialog-otp/authorization-dialog-otp.component';
import { AuthorizationDialogTcsComponent } from './components/authorization-dialog-tcs/authorization-dialog-tcs.component';
import { WebcamModule } from 'ngx-webcam';
import { AuthorizationDialogComponent } from './components/authorization-dialog/authorization-dialog.component';
import { AvailableBalanceComponent } from './components/available-balance/available-balance.component';
import { ButtonItemComponent } from './components/button/button-item.component';
import { ButtonComponent } from './components/button/button.component';
import { CalendarMonthlyPickerComponent } from './components/calendar/calendar-monthly-picker.component';
import { CameraComponent } from './components/camera/camera.component';
import { CardComponent } from './components/card/card.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ChatFileUploadComponent } from './components/chat-file-upload/chat-file-upload.component';
import { ChatInputDisabledPipe } from './components/chat-input/chat-input-disabled.pipe';
import { ChatInputEndChatPipe } from './components/chat-input/chat-input-end-chat.pipe';
import { ChatInputComponent } from './components/chat-input/chat-input.component';
import { ChatMessageDatePipe } from './components/chat-message/chat-message-date.pipe';
import { ChatMessageEndMessageComponent } from './components/chat-message/chat-message-end-message.component';
import { ChatMessageInfPaymentComponent } from './components/chat-message/chat-message-inf-payment.component';
import { ChatMessagePaymentDonePipe } from './components/chat-message/chat-message-payment-done.pipe';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { ChatItemComponent } from './components/chat-panel/chat-item.component';
import { ChatPanelComponent } from './components/chat-panel/chat-panel.component';
import { ChatPreviewComponent } from './components/chat-preview/chat-preview.component';
import { ChatWidgetAudioComponent } from './components/chat-widgets/chat-widget-audio.component';
import { ChatWidgetButtonComponent } from './components/chat-widgets/chat-widget-buttons.component';
import { ChatWidgetDocumentComponent } from './components/chat-widgets/chat-widget-document.component';
import { ChatWidgetJobCardComponent } from './components/chat-widgets/chat-widget-job-card.component';
import { ChatWidgetLocationSetComponent } from './components/chat-widgets/chat-widget-location-set.component';
import { ChatWidgetLocationComponent } from './components/chat-widgets/chat-widget-location.component';
import { ChatWidgetMapComponent } from './components/chat-widgets/chat-widget-map.component';
import { ChatWidgetMerchantsComponent } from './components/chat-widgets/chat-widget-merchants.component';
import { ChatWidgetPaymentRequestComponent } from './components/chat-widgets/chat-widget-payment-request.component';
import { ChatWidgetPlatformMessageComponent } from './components/chat-widgets/chat-widget-platform-message.component';
import { ChatWidgetProductsComponent } from './components/chat-widgets/chat-widget-products.component';
import { ChatWidgetRatingRequestComponent } from './components/chat-widgets/chat-widget-rating-request.component';
import { ChatWidgetRatingComponent } from './components/chat-widgets/chat-widget-rating.component';
import { ChatWidgetShowJobCardComponent } from './components/chat-widgets/chat-widget-show-job-card.component';
import { ChatWidgetUploadComponent } from './components/chat-widgets/chat-widget-upload.component';
import { ChatWidgetsComponent } from './components/chat-widgets/chat-widgets.component';
import { ChatContentComponent } from './components/chat/chat-content.component';
import { ChatComponent } from './components/chat/chat.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ContainerComponent } from './components/container/container.component';
import { CookiesModalComponent } from './components/cookies-modal/cookies-modal.component';
import { CreateWalletModalComponent } from './components/create-wallet-modal/create-wallet-modal.component';
import { CustomerLimitedUserWidgetComponent } from './components/customer-limited-user-widget/customer-limited-user-widget.component';
import { CustomerMobiMoneyWalletInlineWidgetComponent } from './components/customer-mobi-money-wallet-inline-widget/customer-mobi-money-wallet-inline-widget.component';
import { DesktopDeviceDialogComponent } from './components/desktop-device-dialog/desktop-device-dialog.component';
import { DialogHandlerComponent } from './components/dialog-handler/dialog-handler.component';
import { DividerComponent } from './components/divider/divider.component';
import { ExpandComponent } from './components/expand/expand.component';
import { FailureDialogComponent } from './components/failure-dialog/failure-dialog.component';
import { GetFailureDialogError } from './components/failure-dialog/get-failure-dialog-error.pipe';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { CommonVariantImageComponent } from './components/icon/common-variant-image.component';
import { IconComponent } from './components/icon/icon.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { InlineLocationComponent } from './components/inline-location/inline-location.component';
import { InputErrorPipe } from './components/input/input-error.pipe';
import { LabeledInputComponent } from './components/input/labeledInput.component';
import { ItemComponent } from './components/item/item.component';
import { LetterAvatarComponent } from './components/letter-avatar/letter-avatar.component';
import { MakeSearchMapComponent } from './components/make-search-map/make-search-map.component';
import { MakeSearchSelectComponent } from './components/make-search-select/make-search-select.component';
import { MerchantMobiMoneyWalletInlineWidgetComponent } from './components/merchant-mobi-money-wallet-inline-widget/merchant-mobi-money-wallet-inline-widget.component';
import { ModalComponent } from './components/modal/modal.component';
import { PinComponent } from './components/pin/pin.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PushNotificationComponent } from './components/push-notification/push-notification.component';
import { QrCodeCameraComponent } from './components/qr-code-camera/qr-code-camera.component';
import { QuantityPickerComponent } from './components/quantity-picker/quantity-picker.component';
import { RadioComponent } from './components/radio/radio.component';
import { RatingComponent } from './components/rating/rating.component';
import { SegmentComponent } from './components/segment/segment.component';
import { SelectLocationComponent } from './components/select-location/select-location.component';
import { GetHighlightedTextPipe } from './components/select/get-highlighted-text.pipe';
import { GetValuePipe } from './components/select/get-value.pipe';
import { HandleBlurDirective } from './components/select/handle-blur.directive';
import { SelectComponent } from './components/select/select.component';
import { SetLocationComponent } from './components/set-location/set-location.component';
import { SkeletonScreenComponent } from './components/skeleton-screen/skeleton-screen.component';
import { SkeletonScreenPipe } from './components/skeleton-screen/skeleton-screen.pipe';
import { SliderSimpleComponent } from './components/slider-simple/slider-simple.component';
import { StepIconPipe } from './components/step-progress-bar/step-icon.pipe';
import { StepProgressBarComponent } from './components/step-progress-bar/step-progress-bar.component';
import { SwitchButtonComponent } from './components/switch-button/switch-button.component';
import { TabComponent } from './components/tab/tab.component';
import { TableHeaderCellComponent } from './components/table/table-header-cell.component';
import { CdkTableModule } from '@angular/cdk/table';
import { TableValueComponent } from './components/table/table-value.component';
import { TableComponent } from './components/table/table.component';
import { TableHeaderPipe } from './components/table/table.pipe';
import { TasksHandlerComponent } from './components/tasks-handler/tasks-handler.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UpdateDialogComponent } from './components/update-dialog/update-dialog.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { AccountExistsModalComponent } from './components/account-exists-modal/account-exists-modal.component';
import { ChangeAddressModalComponent } from './components/change-address-modal/change-address-modal.component';
import { PasswordValidatorComponent } from './components/password-validator/password-validator.component';
import { AccountsAndCardsPipe } from './components/accounts-and-cards-pipe/account-and-cards-list.pipe';
import { SignInDialogComponent } from './components/sign-in-dialog/sign-in-dialog.component';
import { RegisterBusinessModalComponent } from './components/business-register-modal/register-business-modal.component';
import { UiCardLinkModule } from '@avo/shared/ui/card-link';
import { UiSpinnerModule } from '@avo/shared/ui/spinner';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    WakandaCoreModule,
    WebcamModule,
    CdkTableModule,
    NgxPaginationModule,
    CdkAccordionModule,
    UiCardLinkModule,
    UiSpinnerModule,
  ],
  declarations: [
    TranslateDirective,
    TranslatePipe,
    DeliveryTimeTranslatePipe,
    StatusTranslatePipe,
    ButtonComponent,
    ModalComponent,
    LabeledInputComponent,
    CheckboxComponent,
    RatingComponent,
    ContainerComponent,
    PinComponent,
    SwitchButtonComponent,
    AvailableBalanceComponent,
    ToggleComponent,
    SkeletonScreenComponent,
    FailureDialogComponent,
    SelectComponent,
    GetValuePipe,
    GetHighlightedTextPipe,
    HandleBlurDirective,
    CardComponent,
    CarouselComponent,
    LetterAvatarComponent,
    QuantityPickerComponent,
    RadioComponent,
    CalendarMonthlyPickerComponent,
    DividerComponent,
    ItemComponent,
    IconComponent,
    ChatPreviewComponent,
    StepProgressBarComponent,
    ChatMessageComponent,
    MakeSearchMapComponent,
    ImagePreviewComponent,
    PushNotificationComponent,
    TasksHandlerComponent,
    GetFailureDialogError,
    CameraComponent,
    QrCodeCameraComponent,
    ChatInputComponent,
    ImageUploadComponent,
    ImageGalleryComponent,
    CustomerMobiMoneyWalletInlineWidgetComponent,
    SliderSimpleComponent,
    MerchantMobiMoneyWalletInlineWidgetComponent,
    DialogHandlerComponent,
    AuthorizationDialogComponent,
    AuthorizationDialogOtpComponent,
    AuthorizationDialogTcsComponent,
    AuthorizationDialogApproveitComponent,
    InlineLocationComponent,
    SegmentComponent,
    SkeletonScreenPipe,
    ChatPanelComponent,
    ChatItemComponent,
    TooltipComponent,
    HamburgerComponent,
    ChatMessageInfPaymentComponent,
    MakeSearchSelectComponent,
    SetLocationComponent,
    CustomerLimitedUserWidgetComponent,
    InputErrorPipe,
    CookiesModalComponent,
    AddImagesComponent,
    ChatContentComponent,
    ChatWidgetsComponent,
    ChatMessageEndMessageComponent,
    TabComponent,
    TableComponent,
    TableValueComponent,
    TableHeaderPipe,
    TableHeaderCellComponent,
    ExpandComponent,
    ChatInputEndChatPipe,
    ChatMessageDatePipe,
    ChatMessagePaymentDonePipe,
    DesktopDeviceDialogComponent,
    ChatWidgetButtonComponent,
    ChatWidgetUploadComponent,
    ChatWidgetLocationComponent,
    ChatWidgetLocationSetComponent,
    ChatWidgetJobCardComponent,
    ChatFileUploadComponent,
    ChatWidgetShowJobCardComponent,
    ChatWidgetMerchantsComponent,
    ChatWidgetPlatformMessageComponent,
    ChatWidgetMapComponent,
    ChatWidgetDocumentComponent,
    ChatWidgetPaymentRequestComponent,
    ChatWidgetRatingRequestComponent,
    ChatWidgetRatingComponent,
    ChatWidgetAudioComponent,
    ChatWidgetProductsComponent,
    ButtonItemComponent,
    ChatInputDisabledPipe,
    StepIconPipe,
    CommonVariantImageComponent,
    ProgressBarComponent,
    ChatComponent,
    SelectLocationComponent,
    CreateWalletModalComponent,
    UpdateDialogComponent,
    AccountExistsModalComponent,
    ChangeAddressModalComponent,
    PasswordValidatorComponent,
    AccountsAndCardsPipe,
    SignInDialogComponent,
    RegisterBusinessModalComponent,
  ],
  exports: [
    TranslateDirective,
    TranslatePipe,
    DeliveryTimeTranslatePipe,
    StatusTranslatePipe,
    ButtonComponent,
    ModalComponent,
    LabeledInputComponent,
    CheckboxComponent,
    RatingComponent,
    ContainerComponent,
    PinComponent,
    SwitchButtonComponent,
    AvailableBalanceComponent,
    ToggleComponent,
    FailureDialogComponent,
    SelectComponent,
    GetValuePipe,
    GetHighlightedTextPipe,
    HandleBlurDirective,
    CardComponent,
    CarouselComponent,
    LetterAvatarComponent,
    QuantityPickerComponent,
    RadioComponent,
    CalendarMonthlyPickerComponent,
    DividerComponent,
    ItemComponent,
    IconComponent,
    ChatPreviewComponent,
    StepProgressBarComponent,
    ChatMessageComponent,
    MakeSearchMapComponent,
    ImagePreviewComponent,
    PushNotificationComponent,
    TasksHandlerComponent,
    GetFailureDialogError,
    CameraComponent,
    QrCodeCameraComponent,
    ChatInputComponent,
    ImageUploadComponent,
    ImageGalleryComponent,
    CustomerMobiMoneyWalletInlineWidgetComponent,
    SliderSimpleComponent,
    MerchantMobiMoneyWalletInlineWidgetComponent,
    DialogHandlerComponent,
    AuthorizationDialogComponent,
    InlineLocationComponent,
    SegmentComponent,
    SkeletonScreenComponent,
    SkeletonScreenPipe,
    ChatPanelComponent,
    ChatItemComponent,
    TooltipComponent,
    HamburgerComponent,
    ChatMessageInfPaymentComponent,
    MakeSearchSelectComponent,
    SetLocationComponent,
    CustomerLimitedUserWidgetComponent,
    InputErrorPipe,
    CookiesModalComponent,
    AddImagesComponent,
    ChatContentComponent,
    ChatWidgetsComponent,
    ChatMessageEndMessageComponent,
    TabComponent,
    TableComponent,
    TableValueComponent,
    TableHeaderCellComponent,
    ExpandComponent,
    DesktopDeviceDialogComponent,
    ChatWidgetButtonComponent,
    ChatWidgetUploadComponent,
    ChatWidgetLocationComponent,
    ChatWidgetLocationSetComponent,
    ChatWidgetJobCardComponent,
    ChatFileUploadComponent,
    ChatWidgetShowJobCardComponent,
    ChatWidgetMerchantsComponent,
    ChatWidgetMapComponent,
    ChatWidgetDocumentComponent,
    ChatWidgetPaymentRequestComponent,
    ChatWidgetRatingRequestComponent,
    ChatWidgetAudioComponent,
    ChatWidgetRatingComponent,
    ChatWidgetProductsComponent,
    ButtonItemComponent,
    CommonVariantImageComponent,
    ProgressBarComponent,
    ChatComponent,
    SelectLocationComponent,
    ChatMessagePaymentDonePipe,
    CreateWalletModalComponent,
    UpdateDialogComponent,
    AccountExistsModalComponent,
    ChangeAddressModalComponent,
    PasswordValidatorComponent,
    AccountsAndCardsPipe,
    SignInDialogComponent,
    RegisterBusinessModalComponent,
  ],
  providers: [
    GetValuePipe,
    GetHighlightedTextPipe,
    SkeletonScreenPipe,
    GetFailureDialogError,
    InputErrorPipe,
    TableHeaderPipe,
    ChatInputEndChatPipe,
    ChatInputDisabledPipe,
    ChatMessageDatePipe,
    ChatMessagePaymentDonePipe,
    AccountsAndCardsPipe,
  ],
})
export class WakandaUiModule {}
