import { Action } from '@ngrx/store';
import {
  IPaymentRequestStatementFilter,
  IRequestDetailResponse,
  IRequestFilter,
  IRequestPayDeclineResponse,
} from '../../models/request.model';
import * as EVENT from './request.events';

export class RequestsListRequestAction implements Action {
  readonly type = EVENT.REQUESTS_REQUESTED;
  constructor(public taskId: string, public filter: IRequestFilter) {}
}

export class RequestListResponseAction implements Action {
  readonly type = EVENT.REQUESTS_RECEIVED;
  constructor(public taskId: string, public data: IRequestDetailResponse[]) {}
}

export class RequestDetailRequestAction implements Action {
  readonly type = EVENT.REQUEST_DETAIL_REQUESTED;
  constructor(public taskId: string, public id: string) {}
}

export class RequestDetailResponseAction implements Action {
  readonly type = EVENT.REQUEST_DETAIL_RECEIVED;
  constructor(public taskId: string, public data: IRequestDetailResponse) {}
}

export class RequestPayRequestAction implements Action {
  readonly type = EVENT.REQUEST_PAY_REQUESTED;
  constructor(
    public taskId: string,
    public paymentRequestId: string,
    public walletId: string,
    public onSucceed?: (response: IRequestPayDeclineResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class RequestPayResponseAction implements Action {
  readonly type = EVENT.REQUEST_PAY_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

export class RequestDeclineRequestAction implements Action {
  readonly type = EVENT.REQUEST_DECLINE_REQUESTED;
  constructor(
    public taskId: string,
    public paymentRequestId: string,
    public walletId: string,
    public onSucceed?: (response: IRequestPayDeclineResponse) => void,
  ) {}
}

export class RequestDeclineResponseAction implements Action {
  readonly type = EVENT.REQUEST_DECLINE_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

export class RequestDownloadStatementRequestAction implements Action {
  readonly type = EVENT.REQUEST_DOWNLOAD_REQUESTED;
  constructor(
    public taskId: string,
    public filter: IPaymentRequestStatementFilter,
    public onSucceed: (data: any) => void,
  ) {}
}

export class RequestDownloadStatementResponseAction implements Action {
  readonly type = EVENT.REQUEST_DOWNLOAD_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

export type RequestActions =
  | RequestsListRequestAction
  | RequestListResponseAction
  | RequestDetailRequestAction
  | RequestDetailResponseAction
  | RequestDownloadStatementRequestAction
  | RequestDownloadStatementResponseAction
  | RequestPayResponseAction
  | RequestPayRequestAction
  | RequestDeclineRequestAction
  | RequestDeclineResponseAction;

export const requestBaseActions = {
  RequestsListRequestAction,
  RequestListResponseAction,
  RequestDetailRequestAction,
  RequestDetailResponseAction,
  RequestDownloadStatementRequestAction,
  RequestDownloadStatementResponseAction,
  RequestPayResponseAction,
  RequestPayRequestAction,
  RequestDeclineRequestAction,
  RequestDeclineResponseAction,
};
