import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskType } from '../models/store.models';
import { TASKS_FEATURE_KEY, TasksState } from './tasks.reducer';

export const getTasksState = createFeatureSelector<TasksState>(TASKS_FEATURE_KEY);

export const getTasks = createSelector(getTasksState, state => state.tasks);
export const getErrors = createSelector(getTasksState, state => state.errors);
export const hasFullscreenTasks = createSelector(
  getTasksState,
  state => !!state.tasks?.find(t => t.type == TaskType.STARTED_FULLSCREEN),
);
