/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Pipe, PipeTransform } from '@angular/core';
import orderBy from 'lodash-es/orderBy';

@Pipe({
  name: 'orderBy',
  pure: true,
})
export class OrderByPipe implements PipeTransform {
  transform<T>(data: T[], by: string, orders = 'asc'): T[] {
    if (!data) return [];
    if (orders === 'desc') {
      // @ts-ignore
      return orderBy(data, [by], ['desc']);
    }
    // @ts-ignore
    return orderBy(data, [by], ['asc']);
  }
}
