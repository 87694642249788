import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { AuthEntity, IAuthAccessToken, IAuthDevice } from './auth.models';

export const AUTH_FEATURE_KEY = 'auth';

export interface State extends EntityState<AuthEntity> {
  token?: IAuthAccessToken;
  device?: IAuthDevice;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: State;
}

export const authAdapter: EntityAdapter<AuthEntity> = createEntityAdapter<AuthEntity>();

export const initialState: State = authAdapter.getInitialState({});

const _authReducer = createReducer(
  initialState,

  on(AuthActions.saveTokenAction, (state, { data }) => ({
    ...state,
    token: data,
  })),

  on(AuthActions.logoutAction, state => ({
    ...state,
    token: undefined,
    device: undefined,
  })),

  on(AuthActions.currentDeviceResponse, (state, { data }) => ({
    ...state,
    device: data,
  })),
);

export function authReducer(state: State | undefined, action: Action) {
  return _authReducer(state, action);
}
