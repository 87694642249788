import { Pipe, PipeTransform } from '@angular/core';
import { countryPhoneCodes } from '../utils/common-utility';

@Pipe({
  name: 'phoneTransform',
})
export class PhoneTransformPipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) {
      return null;
    }
    const prefix = countryPhoneCodes.find(p => value.startsWith(p.id))?.id || '';
    let phoneValue = value;
    if (prefix) {
      phoneValue = phoneValue.replace(prefix, '');
    }
    if (phoneValue.length < 6) {
      const splitPhoneLessSix = /(\d{2})(\d+)/g;
      return phoneValue.replace(splitPhoneLessSix, `${prefix} $1 $2`);
    } else {
      const splitPhoneMoreSix = /(\d{2})(\d{3})(\d+)/g;
      phoneValue = phoneValue.replace(/\s/g, '').substring(0, 9);
      return phoneValue.replace(splitPhoneMoreSix, `${prefix} $1 $2 $3`);
    }
  }
}
