import { Component, Input } from '@angular/core';
import { IStatusTranslate } from '@wakanda/zentity-translate-store';
import get from 'lodash-es/get';
import { IUiTableItem, UiTableItemType } from '@wakanda/wakanda-core';
import { IDropdownOption } from '../input/labeledInput.component';

@Component({
  selector: 'ui-table-value',
  template: `
    <div class="table-item" [ngStyle]="{ width: columnWidth }" [ngClass]="{ ellipsisOverflow: ellipsis }">
      <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="tableType.h1">
          <h1 (click)="handleClick(item)">{{ item[cell] }}</h1>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.h2">
          <h2 (click)="handleClick(item)">{{ item[cell] }}</h2>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.h3">
          <h3 (click)="handleClick(item)">{{ item[cell] }}</h3>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.a">
          <ng-container *ngIf="item[cell]?.onClick; else withoutOnClick">
            <a (click)="handleClick(item)">{{ item[cell]?.content }}</a>
          </ng-container>
          <ng-template #withoutOnClick>
            <p>{{ item[cell]?.content }}</p>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.aSmall">
          <a class="small" (click)="handleClick(item)">{{ item[cell]?.content }}</a>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.i">
          <i (click)="handleClick(item)">{{ item[cell] }}</i>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.p">
          <p (click)="handleClick(item)">{{ item[cell] }}</p>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.pSmall">
          <p class="small" (click)="handleClick(item)">{{ item[cell] }}</p>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.date">
          <p class="small" (click)="handleClick(item)">{{ item[cell] | date : 'medium' }}</p>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.paymentDueDate">
          <p class="small" style="color: red" *ngIf="item[cell].value">{{ item[cell].value | date : 'medium' }}</p>
          <p class="small" *ngIf="!item[cell].value">{{ item[cell].defaultValue }}</p>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.dateTime">
          <b (click)="handleClick(item)">{{ item[cell] | date : 'mediumDate' }}</b>
          <p class="text-grey" (click)="handleClick(item)">{{ item[cell] | date : 'mediumTime' }}</p>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.translateStatus">
          <p class="small" (click)="handleClick(item)">{{ item[cell] | statusTranslate : translateData | async }}</p>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.b">
          <b (click)="handleClick(item)">{{ item[cell] }}</b>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.bSmall">
          <b class="small" (click)="handleClick(item)">{{ item[cell] }}</b>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.color">
          <span *ngIf="item[cell]" class="color" [style.background-color]="item[cell]"></span>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.icon">
          <ng-container *ngIf="item[cell]?.onClick; else iconWithoutOnClick">
            <ng-container *ngIf="!!item[cell]?.icon">
              <ui-icon
                [style.width]="item[cell]?.width"
                [icon]="item[cell]?.icon"
                [size]="item[cell]?.size"
                [height]="item[cell]?.height"
                [width]="item[cell]?.width"
                (click)="handleClick(item)"
              ></ui-icon>
            </ng-container>
          </ng-container>
          <ng-template #iconWithoutOnClick>
            <ng-container *ngIf="!!item[cell]?.icon">
              <ui-icon
                [style.width]="item[cell]?.width"
                [icon]="item[cell]?.icon"
                [size]="item[cell]?.size"
                [height]="item[cell]?.height"
                [width]="item[cell]?.width"
              ></ui-icon>
            </ng-container>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.price">
          <div class="row" (click)="handleClick(item)">
            <ui-available-balance
              [data]="{ amount: item[cell]?.amount, currencyCode: item[cell]?.currencyCode || item[cell]?.currency }"
              [renderAs]="item[cell]?.renderAs"
            ></ui-available-balance>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.priceWithIcon">
          <ng-container *ngIf="item?.['stockPriceUpdate'] === true ; else showPriceTemplate">
            <div class="row padded-content content-stock">
              <h3>{{ 'seller.table.edit_price' | translate }}</h3>
              <ui-divider [vertical]="true" [compact]="true"></ui-divider>
              <ui-quantity-picker
                type="number"
                [value]="item?.['prices'].basePrice"
                [minValue]="0"
                (onChange)="changePrice(item, $event.value)"
              ></ui-quantity-picker>
              <ui-divider [vertical]="true" [compact]="true"></ui-divider>
              <ui-button
                [selectButton]="true"
                [content]="'seller.stock.save_button' | translate"
                (onClick)="onStockPriceSave(item?.['tableEditMethod'],item, item?.['sku']?.content || item?.['sku'], false)"
              ></ui-button>
              <ui-divider [vertical]="true" [compact]="true"></ui-divider>
              <ng-container *ngIf="!!item[cell]?.icon">
                <ui-icon
                  [style.width]="item[cell]?.width"
                  [icon]="item[cell]?.icon"
                  [size]="item[cell]?.size"
                  [height]="item[cell]?.height"
                  [width]="item[cell]?.width"
                  (click)="handleClick(item)"
                ></ui-icon>
              </ng-container>
            </div>
            <ng-container *ngIf="item?.['stockMode'] !== 'DO_NOT_TRACK'; else notTrackedTemplate">
              <ng-container *ngIf="item?.['stock']?.length > 0; else noStockTemplate"></ng-container>
              <ng-container *ngFor="let stock of item?.['stock']">
                <div class="row padded-content content-stock">
                  <h3>{{ 'seller.table.edit_stock' | translate }} {{ stock?.shopLocationName }}</h3>
                  <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                  <ui-quantity-picker
                    type="number"
                    [value]="stock?.availableQuantity"
                    [minValue]="0"
                    (onChange)="changeQuantity(stock, $event.value)"
                  ></ui-quantity-picker>
                  <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                  <ui-button
                    [selectButton]="true"
                    [content]="'seller.stock.save_button' | translate"
                    (onClick)="onStockPriceSave(item?.['tableEditMethod'], stock, item?.['sku']?.content || item?.['sku'],true)"
                  ></ui-button>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #noStockTemplate>
              <p class="padded-content" zen-translate>seller.stock.no_stock</p>
            </ng-template>
            <ng-template #notTrackedTemplate>
              <p class="padded-content" zen-translate>seller.stock.not_tracked</p>
            </ng-template>
          </ng-container>
          <ng-template #showPriceTemplate>
            <div class="row">
              <ui-available-balance
                [data]="{
                  amount: item[cell]?.content.amount,
                  currencyCode: item[cell]?.content.currencyCode || item[cell]?.content.currency
                }"
                [renderAs]="item[cell]?.renderAs"
              ></ui-available-balance>
              <ui-divider [vertical]="true" [compact]="true"></ui-divider>
              <ng-container *ngIf="!!item[cell]?.icon">
                <ui-icon
                  [style.width]="item[cell]?.width"
                  [icon]="item[cell]?.icon"
                  [size]="item[cell]?.size"
                  [height]="item[cell]?.height"
                  [width]="item[cell]?.width"
                  (click)="handleClick(item)"
                ></ui-icon>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.thumbnail">
          <ng-container *ngIf="!!item[cell]?.url">
            <span class="table-thumbnail" (click)="handleClick(item)">
              <img
                [src]="item[cell]?.url | resizeImageUrl"
                [alt]="item[cell]?.id"
                [width]="'2.5rem'"
                [height]="'2.5rem'"
              />
            </span>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.thumbnailSecure">
          <ng-container *ngIf="!!item[cell]?.url">
            <span class="table-thumbnail" (click)="handleClick(item)">
              <img
                [src]="
                  isSecureImage(item[cell]?.url)
                    ? (item[cell]?.url | resizeImageUrl | secureImage | async)
                    : (item[cell]?.url | resizeImageUrl)
                "
                [alt]="item[cell]?.id"
                [width]="'2.5rem'"
                [height]="'2.5rem'"
              />
            </span>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.checkbox">
          <ui-checkbox
            [rounded]="true"
            [checked]="item[cell]?.checked"
            [label]="item[cell]?.label"
            (onChange)="handleClick(item)"
          ></ui-checkbox>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.radio">
          <ui-radio
            [checked]="item[cell]?.selected"
            [label]="item[cell]?.label"
            (onChange)="handleClick(item)"
          ></ui-radio>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.toggle">
          <ui-switch-button
            [isSmall]="true"
            [labels]="item[cell]?.labels"
            [active]="item[cell]?.active"
            (onChange)="handleClick(item)"
          ></ui-switch-button>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.action">
          <div class="action" style="padding: 0 1rem; cursor: pointer" (click)="handleClick(item)">
            <ui-icon [icon]="item[cell]?.icon" height="2rem"></ui-icon>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.setOfActions">
          <div class="set-of-actions">
            <ng-container *ngFor="let i of item[cell]">
              <div
                *ngIf="i?.content !== null"
                class="action-item text-align-center"
                [class.green-bg]="i?.greenButton"
                (click)="handleActionClick(i?.onClick, item)"
              >
                <b class="small">{{ i?.content }}</b>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.setOfIcons">
          <div class="row set-of-icons">
            <ng-container *ngFor="let i of item[cell]">
              <ng-container *ngIf="i?.onClick; else iconWithoutOnClick">
                <ng-container *ngIf="!!i?.icon">
                  <ui-icon
                    [icon]="i?.icon"
                    [size]="i?.size"
                    [height]="i?.height"
                    [width]="i?.width"
                    (click)="handleActionClick(i?.onClick, item)"
                  ></ui-icon>
                </ng-container>
              </ng-container>
              <ng-template #iconWithoutOnClick>
                <ng-container *ngIf="!!i?.icon">
                  <ui-icon [icon]="i?.icon" [size]="i?.size" [height]="i?.height" [width]="i?.width"></ui-icon>
                </ng-container>
              </ng-template>
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.dropdown">
          <div class="table-item-dropdown-wrapper" *ngIf="!item[cell]?.disabled">
            <ui-labeled-input
              bindValue="key"
              bindLabel="label"
              [title]="dropdownTitle"
              [value]="item[cell]?.optionKey"
              [options]="dropdownOptions"
              [dropdown]="true"
              (onChange)="handleDropdownChange(item, $event.value)"
            ></ui-labeled-input>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.orderDetailsStore">
          <a (click)="handleClick(item)">{{ item[cell]?.id }}</a>
          <ng-container *ngFor="let item of item[cell]?.orderItems; let last = last">
            <div class="row">
              <b>{{ item.name }}</b>
            </div>
            <div class="row">
              <p class="text-grey">
                <span>{{ 'seller.order_detail.quantity' | translate }}:</span> {{ item.quantity }}
              </p>
            </div>
            <div class="row">
              <div class="column">
                <p class="text-grey">
                  <span>{{ 'seller.order_detail.product_id' | translate }}:</span> {{ item.productId }}
                </p>
              </div>
              <ui-divider [vertical]="true" [compact]="true" [solid]="true"></ui-divider>
              <div class="column">
                <p class="text-grey">
                  <span>{{ 'seller.order_detail.sku' | translate }}:</span> {{ item.productSku }}
                </p>
              </div>
            </div>
            <ui-divider *ngIf="!last" [compact]="true"></ui-divider>
          </ng-container>
          <div class="row">
            <div class="column">
              <p class="text-grey">
                <span>{{ 'seller.order_detail.customer_name' | translate }}:</span> {{ item[cell].customerName }}
              </p>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.quoteDetailsSeller">
          <b>{{ item[cell]?.productName }}</b>
          <div class="row">
            <div class="column">
              <p class="text-grey">
                <span>{{ 'seller.quotes_list.quantity' | translate }}:</span> {{ item[cell]?.quantity }}
              </p>
              <p class="text-grey">
                <span>{{ 'seller.quotes_list.buyer' | translate }}:</span> {{ item[cell]?.buyerName }}
              </p>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.quoteDetailsBuyer">
          <ng-container *ngFor="let product of item[cell]">
            <b>{{ product.title }}</b>
            <div class="row">
              <div class="column">
                <p class="text-grey">
                  <span>{{ 'buyer.quotes_list.quantity' | translate }}:</span> {{ product.quantity }}
                </p>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.orderDetailsRestaurant">
          <a (click)="handleClick(item)">{{ item[cell]?.id }}</a>
          <ng-container *ngFor="let item of item[cell]?.orderItems; let last = last">
            <div class="row">
              <b>{{ item.name }}</b>
            </div>
            <div class="row">
              <p class="text-grey">
                <span>{{ 'takeaways.order_list_item.qty' | translate }}:</span> {{ item.quantity }}
              </p>
            </div>
            <div class="row">
              <div class="column">
                <p class="text-grey">
                  <span>{{ 'takeaways.order_list.order_product_id' | translate }}:</span> {{ item.productId }}
                </p>
              </div>
              <ui-divider [vertical]="true" [compact]="true" [solid]="true"></ui-divider>
              <div class="column">
                <p class="text-grey">
                  <span>{{ 'takeaways.order_list.order_product_sku' | translate }}:</span> {{ item.productSku }}
                </p>
              </div>
            </div>
            <ui-divider *ngIf="!last" [compact]="true"></ui-divider>
          </ng-container>
          <div class="row">
            <div class="column">
              <p class="text-grey">
                <span>{{ 'takeaways.order_list.order.customer_name' | translate }}:</span> {{ item[cell].customerName }}
              </p>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="tableType.html">
          <div [innerHTML]="item[cell]"></div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{ item[cell] }}
        </ng-container>
      </ng-container>
    </div>
  `,
})
export class TableValueComponent<DK> {
  @Input() type: UiTableItemType;
  @Input() item: IUiTableItem;
  @Input() cell: string | any;
  @Input() capitalized: boolean;
  @Input() translateData: IStatusTranslate;
  @Input() ellipsis: boolean;
  @Input() columnWidth: string;
  @Input() dropdownTitle: string;
  @Input() dropdownOptions: IDropdownOption<DK>[];

  tableType = UiTableItemType;
  showStockPriceUpdate: boolean;

  handleClick = (item: IUiTableItem): void => {
    const onClick = get(item, [this.cell, 'onClick']);

    if (item['inTableEditMode']) {
      if (this.cell == UiTableItemType.price) {
        this.showStockPriceUpdate = !this.showStockPriceUpdate;
        item['stockPriceUpdate'] = this.showStockPriceUpdate;
      }
    }

    if (typeof onClick === 'function') onClick(item);
  };

  handleActionClick = (execute: (item: IUiTableItem) => void, item: IUiTableItem): void => {
    if (typeof execute === 'function') execute(item);
  };

  changeQuantity = (stock, value: number): void => {
    stock.availableQuantity = value;
  };
  changePrice = (item, value: number): void => {
    item.prices.basePrice = value;
  };

  onStockPriceSave = (
    execute: (item: IUiTableItem, productId: string, stockUpdate: boolean) => void,
    item: IUiTableItem,
    productId: string,
    stockUpdate: boolean,
  ): void => {
    if (typeof execute === 'function') execute(item, productId, stockUpdate);
  };

  handleDropdownChange = (item: IUiTableItem, optionKey: string): void => {
    const onChange = get(item, [this.cell, 'onChange']);
    if (onChange) onChange(item, optionKey);
  };

  isSecureImage = (url: string): boolean => {
    if (url.includes('cdn.')) return false;
    return true;
  };
}
