export enum AssetUploadStatus {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export interface IAssetStatusResponse {
  uploadId: string;
  status: AssetUploadStatus;
  documentId: string;
  uploadedFile: IAssetUploadedFile;
  error: string;
}

export interface IAssetUploadedFile {
  id: string;
  url: string;
}

export interface IAssetDMZUploadInfo {
  dmzFileId: string;
}

export enum AssetsBucketsEnum {
  PROFILE_PICTURE = 'profile-pictures',
  SELLER = 'seller',
  JOB_IMAGES = 'job-images',
  TAKEAWAYS = 'takeaways',
  UPLOADS = 'uploads',
  CHAT = 'chat',
}
