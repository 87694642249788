import { IShoppingPickupPoint } from './shopping.model';
import { ILocationV3Address } from './location.model';
import { IImageFileResponse } from './image.model';
import { IActivityHistoryReturn } from './activity-history.model';

export enum RmaType {
  RETURN = 'RETURN',
  CANCEL = 'CANCEL',
}

export interface IRmaCancelReason {
  id: string;
  requiresMessage: boolean;
}

export interface IRmaCancelReasonSelectable {
  key: string;
  label: string;
}

export interface IRmaItemRequest {
  itemId: string;
  quantity: number;
  reason?: string;
  message?: string;
  images?: IImageFileResponse[];
  valid: boolean;
  checked: boolean;
}

export interface IRmaItemRequestDraft {
  lineItemId: string;
  quantity: number;
  reasonCode?: string;
  message?: string;
  images?: string[];
}

export interface IRmaRequest {
  items: IRmaItemRequestDraft[];
  pickupAddressId?: string;
}

export interface IRmaMyReturnItem {
  fulfilmentId: string;
  rma: IActivityHistoryReturn;
}

export interface IRmaDetail {
  active: boolean;
  chatRoomId: string;
  id: string;
  items: IRmaDetailItem[];
  lockerbox?: IShoppingPickupPoint;
  lockerboxPin?: string;
  pickupAddress?: ILocationV3Address;
  returnMethod: RmaReturnMethod;
  fulfilmentId: string;
  orderNumber: string;
  returnedItemsTotal: IRmaTaxPrice;
}

export interface IRmaDetailItem {
  id: number;
  lineItemId: number;
  name?: string;
  quantity: number;
  refunded: boolean;
  returnReason: string;
  state: RmaDetailItemState;
}

export enum RmaDetailItemState {
  REQUESTED = 'REQUESTED',
  AWAITING_DELIVERY = 'AWAITING_DELIVERY',
  ITEMS_RECEIVED = 'ITEMS_RECEIVED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  REQUEST_CANCELLED = 'REQUEST_CANCELLED',
}

export enum RmaReturnMethod {
  LOCKERBOX = 'LOCKERBOX',
  STORE_DROPOFF = 'STORE_DROPOFF',
  PICKUP = 'PICKUP',
}

export interface IRmaTaxPrice {
  withoutTax: number;
  withTax: number;
  tax: number;
}
