import { WalletDashboardWidgetType } from '../../models/wallet.model';

export const wallet = (baseUrl: string): string => `${baseUrl}/wallet/v1/wallet`;
export const customerWallet = (baseUrl: string): string => `${baseUrl}/customer-wallet/v1/wallets`;
export const wallets = (baseUrl: string): string => `${baseUrl}/customer-wallet/v1/wallets`;
export const bankingCoreServices = (baseUrl: string): string => `${baseUrl}/banking-core-services/v1/wallet`;
export const creditLoan = (baseUrl: string): string => `${baseUrl}/wallet/v1/credit-loan`;
export const payments = (baseUrl: string): string => `${baseUrl}/payments/v1`;
export const paymentsDefault = (baseUrl: string): string => `${baseUrl}/payments`;
export const referenceData = (baseUrl: string): string => `${baseUrl}/wallet/v1/referencedata`;
export const requestData = (baseUrl: string): string => `${baseUrl}/wallet/v1/request`;
export const loyalty = (baseUrl: string): string => `${baseUrl}/wallet/v1/loyalty`;
export const walletById = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}`;
export const customerWalletById = (walletId: string, baseUrl: string): string =>
  `${customerWallet(baseUrl)}/${walletId}`;
export const transactions = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/transaction`;
export const settlement = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/settlement`;
export const subscription = (baseUrl: string): string => `${baseUrl}/wallet/v1/subscription`;
export const subscriptionMerchant = (baseUrl: string): string => `${baseUrl}/wallet/v1/merchant/subscription`;
export const dashboard = (baseUrl: string): string => `${baseUrl}/wallet/v1/dashboard`;
export const nedbank = (baseUrl: string): string => `${baseUrl}/users/v1/nedbank`;
export const ecommerce = (baseUrl: string): string => `${baseUrl}/ecommerce/v1`;

export const transactionSearch = (walletId: string, baseUrl: string, query?: string): string =>
  `${wallet(baseUrl)}/${walletId}/transaction/search?query=${query}`;

export const transactionRecent = (walletId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/transaction/search/recent`;

export const transactionFilter = (walletId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/transaction/filter`;

export const transactionStatement = (walletId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/transaction/statement`;

export const transactionDetail = (walletId: string, transactionId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/transaction/${transactionId}`;

export const reminder = (walletId: string, transactionId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/transaction/${transactionId}/reminder`;

export const refund = (walletId: string, transactionId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/transaction/${transactionId}/refund`;

export const phonebook = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/phonebook`;

export const recent = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/pay/recent`;

export const phone = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/pay/phone`;

export const pay = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/pay`;

export const payRecentRecipients = (walletId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/pay/recent`;

export const payByQrCode = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/pay/qr`;

export const request = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/request`;

export const externalAccounts = (walletId: string, destination: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/externalaccount?destination=${destination}`;

export const cardWithDestination = (walletId: string, destination: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/card?destination=${destination}`;

export const validateNewExternalAccount = (walletId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/externalaccount/validate`;

export const addNewExternalAccount = (walletId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/externalaccount`;

export const settlementFilter = (baseUrl: string, params: any): string =>
  `${payments(baseUrl)}/seller/settlement/?${new URLSearchParams(params).toString()}`;

export const settlementFilterExport = (baseUrl: string, storeId: string): string =>
  `${payments(baseUrl)}/seller/settlement/${storeId}/export`;

export const transactionsFilterExport = (baseUrl: string, storeId: string, params: any): string =>
  `${payments(baseUrl)}/seller/settlement/${storeId}/transaction/export?${new URLSearchParams(params).toString()}`;

export const taxInvoices = (baseUrl: string, storeId: string, params: any): string =>
  `${payments(baseUrl)}/seller/tax-invoices/${storeId}?${new URLSearchParams(params).toString()}`;

export const taxInvoiceById = (taxInvoiceId: number, storeId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/tax-invoices/${storeId}/${taxInvoiceId}`;

export const generateRemittance = (storeId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/remittance/${storeId}/generate`;

export const taxInvoiceDownload = (taxInvoiceId: string, storeId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/tax-invoices/${storeId}/${taxInvoiceId}/download`;

export const sellerStatement = (baseUrl: string, storeId: string, params?: any): string => {
  return `${payments(baseUrl)}/seller/statement/${storeId}${
    params ? '?' + new URLSearchParams(params).toString() : ''
  }`;
};

export const generateSellerStatement = (baseUrl: string, storeId: string): string => {
  return `${payments(baseUrl)}/seller/statement/${storeId}/generate`;
};

export const sellerStatementDownload = (statementNumber: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/statement/${statementNumber}/download`;

export const statementDownload = (statementNumber: string, storeId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/seller/statement/${storeId}/${statementNumber}/download`;

export const getTaskFile = (taskId: string, baseUrl: string): string =>
  `${paymentsDefault(baseUrl)}/user-tasks/files/${taskId}`;

export const getTaskDetail = (taskId: string, baseUrl: string): string =>
  `${paymentsDefault(baseUrl)}/user-tasks/${taskId}`;

export const filterPayoutTransactions = (baseUrl: string, storeId: string, params: any): string =>
  `${payments(baseUrl)}/seller/settlement/${storeId}/transaction/filter?${new URLSearchParams(params).toString()}`;

export const addSettlementAccount = (baseUrl: string): string => `${payments(baseUrl)}/settlement/account`;

export const settlementAccount = (accountId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/settlement/account/${accountId}`;

export const fetchSettlementAccount = (baseUrl: string): string => `${payments(baseUrl)}/settlement/account`;

export const settlementSettings = (baseUrl: string) => `${payments(baseUrl)}/settlement/settings`;

export const removeExternalAccount = (walletId: string, accountId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/externalaccount/${accountId}`;

export const cardsAndAccountById = (walletId: string, externalAccountId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/externalaccount/${externalAccountId}`;

export const onRecentRemove = (walletId: string, searchId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/transaction/search/recent/${searchId}`;

export const withdraw = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/atm-withdraw`;

export const atms = (baseUrl: string): string => `${wallet(baseUrl)}/atm`;
export const sendPaymentLink = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/link`;

export const qrCode = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/qr`;
export const decodeQrCode = (baseUrl: string): string => `${wallet(baseUrl)}/decodeQr`;
export const card = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/card`;
export const editDeleteCard = (walletId: string, cardId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/card/${cardId}`;
export const topupCard = (walletId: string, cardId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/card/${cardId}/topup`;

export const checkTopUpCard = (walletId: string, cardId: string, topUpId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/card/${cardId}/topup/${topUpId}`;

export const settle = (walletId: string, baseUrl: string): string => `${settlement(walletId, baseUrl)}`;

export const banks = (baseUrl: string): string => `${referenceData(baseUrl)}/banks`;
export const nedbankBanks = (baseUrl: string): string => `${nedbank(baseUrl)}/filtered-banks`;
export const outgoingRequest = (baseUrl: string, filter: string): string =>
  `${requestData(baseUrl)}/outgoing?status=${filter}`;
export const outgoingPaymentRequestDetail = (baseUrl: string, paymentRequestId: string): string =>
  `${requestData(baseUrl)}/outgoing/${paymentRequestId}`;

export const fetchPaymentLink = (paymentLink: string, baseUrl: string): string =>
  `${baseUrl}/wallet/v1/payment-link/${paymentLink}`;

export const acceptPaymentLink = (walletId: string, paymentId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/payment/${paymentId}/accept`;

export const loyaltyPoints = (baseUrl: string): string => `${loyalty(baseUrl)}/points`;

export const payCheck = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/payCheck`;

export const topupAccount = (walletId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/topupAccount`;

export const topupAccountStatus = (walletId: string, topupId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/topupAccount/${topupId}`;

export const topupCardStatus = (walletId: string, cardId: string, topupId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/card/${cardId}/topup/${topupId}`;

export const partyAccounts = (baseUrl: string): string => `${bankingCoreServices(baseUrl)}/partyAccounts`;

export const creditLoanType = (baseUrl: string): string => `${creditLoan(baseUrl)}/loan-type`;

export const setWalletAsMain = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/setMain`;
export const renameWallet = (walletId: string, baseUrl: string): string => `${wallet(baseUrl)}/${walletId}/name`;

export const subscriptionAvailable = (baseUrl: string): string => `${baseUrl}/wallet/v1/availableSubscription`;
export const subscriptionAvailableMerchant = (baseUrl: string): string =>
  `${baseUrl}/wallet/v1/merchant/availableSubscription`;
export const subscriptionActivate = (subscriptionId: string, baseUrl: string): string =>
  `${subscription(baseUrl)}/${subscriptionId}/activate`;
export const subscriptionDeactivate = (subscriptionId: string, baseUrl: string): string =>
  `${subscription(baseUrl)}/${subscriptionId}/deactivate`;

export const subscriptionMerchantDeactivate = (subscriptionId: string, baseUrl: string): string =>
  `${subscriptionMerchant(baseUrl)}/${subscriptionId}/deactivate`;

export const fetchDashboardWallet = (baseUrl: string, type: WalletDashboardWidgetType) =>
  `${dashboard(baseUrl)}/${type}`;
export const fetchPayInfo = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}`;
export const payByCard = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-card`;
export const payByPointsOnly = (flowId: string, baseUrl: string): string =>
  `${payments(baseUrl)}/${flowId}/pay-points-only`;
export const payByWallet = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-wallet`;
export const payForFree = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-free`;
export const payByEft = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-eft`;
export const payByAmex = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-amex`;
export const payByCredit = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/pay-credit`;
export const topUpAndPay = (flowId: string, baseUrl: string): string => `${payments(baseUrl)}/${flowId}/topup-and-pay`;

export const fetchWalletSubscription = (baseUrl: string): string => `${baseUrl}/wallet/v1/subscription`;
