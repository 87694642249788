import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import * as TransactionsActions from './transactions.actions';
import { ApplicationEntity as TransactionsEntity, ITransactionSigning } from '../models/store.models';

export const TRANSACTIONS_FEATURE_KEY = 'transactions';

export interface TransactionsState extends EntityState<TransactionsEntity> {
  transactionSigning?: ITransactionSigning;
  lastTransactionId?: string;
}

export interface TransactionsPartialState {
  readonly [TRANSACTIONS_FEATURE_KEY]: TransactionsState;
}

export const transactionsAdapter: EntityAdapter<TransactionsEntity> = createEntityAdapter<TransactionsEntity>();

export const transactionsInitialState: TransactionsState = transactionsAdapter.getInitialState({
  tasks: [],
  errors: [],
  transactionSigning: null,
});

const _transactionsReducer = createReducer(
  transactionsInitialState,
  on(TransactionsActions.transactionSigningStartRequestAction, (state, { transactionAuthId }) => {
    const transactionSigning = Object.assign({}, state.transactionSigning, {
      transactionAuthId,
    });
    return { ...state, transactionSigning, lastTransactionId: transactionAuthId };
  }),

  on(TransactionsActions.transactionSigningStartResponseAction, (state, { data }) => {
    const transactionSigning = Object.assign({}, state.transactionSigning, { nextStep: data });
    return { ...state, transactionSigning };
  }),

  on(TransactionsActions.transactionSigningAuthorizeResponseAction, (state, { data }) => {
    const transactionSigning = Object.assign({}, state.transactionSigning, { nextStep: data });
    return { ...state, transactionSigning };
  }),

  on(TransactionsActions.transactionSaveAuthenticatorsAction, (state, { data }) => {
    const transactionSigning = Object.assign({}, state.transactionSigning, { authenticators: data });
    return { ...state, transactionSigning };
  }),

  on(TransactionsActions.transactionSigningCancelResponseAction, state => {
    const transactionSigning = null;
    return { ...state, transactionSigning };
  }),
);

export function transactionsReducer(state: TransactionsState | undefined, action: Action) {
  return _transactionsReducer(state, action);
}
