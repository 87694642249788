import { Action } from '@ngrx/store';
import * as actions from './search.actions';
import * as EVENT from './search.events';
import {
  ICategory,
  IHomeSearchSuggestion,
  ISearchB2BStoreResponse,
  ISearchInit,
  ISearchProductResponse,
  ISearchQuery,
  ISearchResults,
} from '../../models/search.model';

export const SEARCH_FEATURE_KEY = 'search';

export interface ISearchReducerState {
  init?: ISearchInit;
  results?: ISearchResults;
  suggestions?: IHomeSearchSuggestion;
  servicePopular?: ICategory[];
  searchCategoryId?: string;
  query: ISearchQuery;
  searchProducts?: ISearchProductResponse;
  searchNamedProducts?: ISearchProductResponse;
  b2bStoresSearch?: ISearchB2BStoreResponse;
}

export interface ISearchPartialState {
  readonly [SEARCH_FEATURE_KEY]: ISearchReducerState;
}

const ACTION_HANDLERS = {
  [EVENT.INIT_REQUEST]: (state: ISearchReducerState): ISearchReducerState =>
    Object.assign({}, state, { init: null, suggestions: null, results: null }),
  [EVENT.INIT_RECEIVED]: (state: ISearchReducerState, action: actions.SearchInitReceived): ISearchReducerState =>
    Object.assign({}, state, { init: action.data }),
  [EVENT.RESULTS_REQUEST]: (state: ISearchReducerState): ISearchReducerState =>
    Object.assign({}, state, { results: null }),
  [EVENT.RESULTS_RECEIVED]: (state: ISearchReducerState, action: actions.SearchResultsReceived): ISearchReducerState =>
    Object.assign({}, state, { results: action.data }),
  [EVENT.SEARCH_PRODUCT_REQUEST]: (state: ISearchReducerState): ISearchReducerState =>
    Object.assign({}, state, { searchProducts: null }),
  [EVENT.SEARCH_B2B_STORE_REQUEST]: (state: ISearchReducerState): ISearchReducerState =>
    Object.assign({}, state, { b2bStoresSearch: null }),
  [EVENT.SEARCH_PRODUCT_NAMED_RECEIVED]: (
    state: ISearchReducerState,
    action: actions.SearchProductReceived,
  ): ISearchReducerState => Object.assign({}, state, { searchNamedProducts: action.data }),
  [EVENT.SEARCH_PRODUCT_RECEIVED]: (
    state: ISearchReducerState,
    action: actions.SearchProductReceived,
  ): ISearchReducerState => Object.assign({}, state, { searchProducts: action.data }),
  [EVENT.SEARCH_B2B_STORE_RECEIVED]: (
    state: ISearchReducerState,
    action: actions.SearchB2BStoreReceived,
  ): ISearchReducerState => Object.assign({}, state, { b2bStoresSearch: action.data }),
  [EVENT.SUGGESTIONS_RECEIVED]: (
    state: ISearchReducerState,
    action: actions.SearchSuggestionsReceived,
  ): ISearchReducerState =>
    Object.assign({}, state, {
      suggestions: action.data,
    }),
  [EVENT.SERVICE_POPULAR_RECEIVED]: (
    state: ISearchReducerState,
    action: actions.SearchInitReceived,
  ): ISearchReducerState => Object.assign({}, state, { servicePopular: action.data }),
  [EVENT.SET_QUERY]: (state: ISearchReducerState, action: actions.SearchSetQuery): ISearchReducerState =>
    Object.assign({}, state, { query: action.query }),
  [EVENT.SET_SEARCH_CATEGORY_ID_DASHBOARD]: (
    state: ISearchReducerState,
    action: actions.SetSearchCategoryId,
  ): ISearchReducerState => Object.assign({}, state, { searchCategoryId: action.categoryId }),
};

export function searchReducer(state: ISearchReducerState = { query: {} }, action: Action): ISearchReducerState {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
