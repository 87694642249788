import find from 'lodash-es/find';
import get from 'lodash-es/get';
import head from 'lodash-es/head';
import includes from 'lodash-es/includes';
import map from 'lodash-es/map';
import split from 'lodash-es/split';
import toNumber from 'lodash-es/toNumber';
import { AddressType, GoogleResult, IAddressForm, IAddressLocation, ILocationV3 } from '../models/location.model';

const getAddrComponent = (place, type: string | string[], long?: boolean): any =>
  get(
    find(place.address_components, p => includes(p.types, type)),
    long ? 'long_name' : 'short_name',
  );

const getStreet = (place): string => getAddrComponent(place, 'route', true);

const getCity = (place): string => getAddrComponent(place, 'locality', true);

const getCountry = (place): string => getAddrComponent(place, 'country');

const getStateOrProvinec = (place): string => getAddrComponent(place, 'administrative_area_level_1', true);

const getSuburb = (place): string => getAddrComponent(place, 'sublocality_level_1', true);

const getFullAddress = (place): string => place.formatted_address;

const getPostCode = (place): string => {
  const postalCode = getAddrComponent(place, 'postal_code');
  return postalCode ? postalCode.replace(/ /g, '') : null;
};

const getLatitude = (place): number => {
  const latitude = get(place, 'geometry.location');
  return toNumber(latitude.lat());
};

const getLongitude = (place): number => {
  const longitude = get(place, 'geometry.location');
  return toNumber(longitude.lng());
};

const getStreetNumber = (place): number => {
  const streetNumber = getAddrComponent(place, 'street_number');
  const premiseStreetNumer = getAddrComponent(place, 'premise');

  if (!streetNumber && premiseStreetNumer) {
    return premiseStreetNumer;
  }

  return streetNumber;
};

export const getAddressForm = (response: google.maps.GeocoderResult[]): IAddressForm => {
  const place = get(response, [0, 0]);

  if (!place) return { addressString: '' };

  return {
    addressString: getFullAddress(place),
    streetName: getStreet(place),
    stateOrProvince: getStateOrProvinec(place),
    postalCode: getPostCode(place),
    city: getCity(place),
    country: getCountry(place),
    streetNumber: getStreetNumber(place),
    latitude: getLatitude(place),
    longitude: getLongitude(place),
    suburb: getSuburb(place),
  };
};

export const getAddressByPlace = (
  response: google.maps.places.AutocompletePrediction[],
): IAddressLocation[] | boolean[] =>
  map(response, (item: GoogleResult) => ({
    id: item.place_id,
    name: head(split(get(item, 'description'), ',')),
    icon: 'location_marker',
    type: AddressType.Search,
    address: {
      addressString: item.description,
    },
  }));

export const convertLocationToAddressForm = (data: ILocationV3): IAddressForm => ({
  locationId: get(data, 'id'),
  latitude: get(data, 'location.latitude'),
  longitude: get(data, 'location.longitude'),
  addressString: get(data, 'address.addressString'),
  postalCode: get(data, 'address.postalCode'),
  city: get(data, 'address.city'),
  complex: get(data, 'address.complex'),
  country: get(data, 'address.country'),
  streetName: get(data, 'address.streetName'),
  streetNumber: get(data, 'address.streetNumber') as any,
  stateOrProvince: get(data, 'address.stateOrProvince'),
  suburb: get(data, 'address.suburb'),
  name: get(data, 'name'),
  addressType: get(data, 'address.addressType'),
});
