<div class="row align-stretch between steps">
  <ng-container style="width: 50rem;"  *ngFor="let item of steps; let index = index; let last = last">
    <div class="step-item column top left text-align-left basis-25">
      <ui-icon [icon]="item?.mainIcon" size="2.75rem"></ui-icon>
      <div *ngIf="!last" class="progress-icon">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot" [class.light]="index === 0"></div>
        <div class="dot" [class.light]="index === 0 || index === 1"></div>
      </div>
      <ui-divider [compact]="true"></ui-divider>
      <h3 zen-translate>{{ item?.title }}</h3>
      <ui-divider [compact]="true"></ui-divider>
      <p class="light" zen-translate>{{ item?.text }}</p>
    </div>
    <ui-divider *ngIf="!last" [vertical]="true"></ui-divider>
  </ng-container>
</div>
