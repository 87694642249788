import packageFile from '@wakanda/package';
import * as data from './../../apps/avo-business/src/configurations/test/configuration.json';

const getEnv = () => {
  if (window.location.hostname.indexOf('localhost') >= 0) {
    return {
      baseUrl: 'http://localhost:3003',
      production: false,
      translateApplicationName: 'wakanda',
      environment: 'test',
      adobeApp: 'avo-business',
      translations: ['https://test-localisation-cdn.avo.africa/en/merchant.json'],
      appVersion: packageFile.version,
    };
  } else if (window.location.hostname.indexOf('test') >= 0) {
    return {
      baseUrl: data?.['baseUrl'],
      production: true,
      translateApplicationName: 'wakanda',
      environment: 'test',
      adobeApp: 'avo-business',
      domainUrl: 'test.avobusiness.africa',
      buildNumberUrl: 'https://test.avobusiness.africa/build.html',
      translations: ['https://test-localisation-cdn.avo.africa/en/merchant.json'],
      appVersion: packageFile.version,
    };
  } else if (window.location.hostname.indexOf('stage') >= 0) {
    return {
      baseUrl: 'https://stage.avobusiness.africa',
      production: true,
      translateApplicationName: 'wakanda',
      environment: 'stage',
      adobeApp: 'avo-business',
      domainUrl: 'stage.avobusiness.africa',
      buildNumberUrl: 'https://stage.avobusiness.africa/build.html',
      translations: ['https://stage-localisation-cdn.avo.africa/en/merchant.json'],
      appVersion: packageFile.version,
    };
  } else if (window.location.hostname.indexOf('preview') >= 0) {
    return {
      baseUrl: 'https://preview.avobusiness.africa',
      production: true,
      translateApplicationName: 'wakanda',
      environment: 'stage',
      adobeApp: 'avo-business',
      domainUrl: 'preview.avobusiness.africa',
      buildNumberUrl: 'https://preview.avobusiness.africa/build.html',
      translations: ['https://localisation-cdn.avo.africa/en/merchant.json'],
      appVersion: packageFile.version,
    };
  } else {
    return {
      baseUrl: 'https://avobusiness.africa',
      production: true,
      translateApplicationName: 'wakanda',
      environment: 'production',
      adobeApp: 'avo-business',
      domainUrl: 'avobusiness.africa',
      buildNumberUrl: 'https://avobusiness.africa/build.html',
      translations: ['https://localisation-cdn.avo.africa/en/merchant.json'],
      appVersion: packageFile.version,
    };
  }
};
export const environment = getEnv();
