import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, take } from 'rxjs/operators';
import { firstValueFrom, map, of } from 'rxjs';
import { environment } from '@avo/environment/customer/environment';

@Injectable({
  providedIn: 'root',
})
export class BuildNumberService {
  shellBuildNumber: string;
  constructor(private http: HttpClient) {}

  fetchBuildNumber(mfeName: string, buildNumberUrl?: string): Promise<string> {
    const request = this.http.get(buildNumberUrl ?? environment.buildNumberUrl, { responseType: 'text' }).pipe(
      take(1),
      map(response => {
        const buildNumber = this.parseBuildNumber(response);
        if (mfeName && mfeName === 'shell') this.shellBuildNumber = buildNumber;
        return buildNumber;
      }),
      catchError(() => of(null)),
    );

    return firstValueFrom(request);
  }

  parseBuildNumber(text: string): string {
    try {
      return /Build #: (([a-z]+_|)[0-9]+)/.exec(text)[1];
    } catch (e) {
      return null;
    }
  }
}
