import { Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';

export enum SWMESSAGE_TYPE {
  FCM_MESSAGE = 'fcm_message',
}

@Injectable()
export class ServiceWorkerMessagingService {
  constructor(private notificationService: NotificationService) {}

  registerListeners(): void {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', event => {
        const data = event.data;
        if (data) {
          switch (data.type) {
            case SWMESSAGE_TYPE.FCM_MESSAGE:
              this.notificationService.backgroundMessageReceived(data.payload);
              break;
            default:
              break;
          }
        }
      });
    }
  }
}
