import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 } from 'uuid';

@Injectable()
export class TranslateService {
  constructor(private httpClient: HttpClient) {}

  fetchTranslates(name: string): Observable<any> {
    return this.httpClient.get(`${name}?t=${v4()}`);
  }
}
