import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(value: string, suffix = true): string {
    if (!value) return '';

    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

    if (seconds > 0 && seconds < 300) return 'just now';

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    let counter: string | number;

    for (const i in intervals) {
      if (Object.prototype.hasOwnProperty.call(intervals, i)) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0 && suffix) return counter === 1 ? `${counter} ${i} ago` : `${counter} ${i}s ago`;
        if (counter > 0 && !suffix) return counter === 1 ? `${counter} ${i}` : `${counter} ${i}s`;
      }
    }

    return '';
  }
}
