import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ILocationDTO } from '../model/shared.model';
import { ApplicationFacade } from '../store/application/application.facade';

@Injectable()
export class LocationInterceptor implements HttpInterceptor, OnDestroy {
  subscriptions = new Subscription();
  location: ILocationDTO;

  constructor(private appFacade: ApplicationFacade) {
    this.subscriptions.add(this.appFacade.getCurrentLocation$.subscribe(data => (this.location = data)));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    if (
      request.url.endsWith('configuration.json') ||
      request.url.endsWith('wallet/v2/subscription') || // avo 2 endpoint
      request.url.endsWith('wallet/v2/availableSubscription') // avo 2 endpoint
    ) {
      return next.handle(request);
    }

    if (this.location?.id) {
      request = request.clone({
        headers: request.headers.set('X-Avo-LocationId', this.location.id),
      });
    }

    if (this.location?.position) {
      const { latitude, longitude } = this.location.position;

      const sendCoordinates =
        request.url.includes('search/product') ||
        request.url.includes('search/stores') ||
        request.url.includes('search/category');

      if (sendCoordinates && latitude && longitude) {
        request = request.clone({
          headers: request.headers.set('X-Avo-Coordinates', `${latitude},${longitude}`),
        });
      }
    }

    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
