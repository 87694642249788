export enum ModalTypes {
  ADD_AGENT = 'ADD_AGENT',
  EVENTS_LOG = 'EVENTS_LOG',
  QUEUE_WARNING = 'QUEUE_WARNING',
  CHAT_INVITATION = 'CHAT_INVITATION',
  CHANGE_SOUND_SETTINGS = 'CHANGE_SOUND_SETTINGS',
  CHANGE_STATUS = 'CHANGE_STATUS',
  CREATE_CRM_CASE = 'CREATE_CRM_CASE',
  ADD_INFORMAL_MERCHANT = 'ADD_INFORMAL_MERCHANT',
  EDIT_INFORMAL_MERCHANT = 'EDIT_INFORMAL_MERCHANT',
  SEND_MESSAGE = 'SEND_MESSAGE',
  NOT_FOUND = 'NOT_FOUND',
  WELCOME = 'WELCOME',
  START_OAS_CHATBOT = 'START_OAS_CHATBOT',
  START_HEYNED_CHAT = 'START_HEYNED_CHAT',
  CREATE_NEW_JOB = 'CREATE_NEW_JOB',
  MERCHANTS_LOOKUP = 'MERCHANTS_LOOKUP',
  PRODUCTS_LOOKUP = 'PRODUCTS_LOOKUP',
  CHAT_PARTICIPANTS = 'CHAT_PARTICIPANTS',
  EDIT_CHAT_NAME = 'EDIT_CHAT_NAME',
}

export interface IEditChatModalData {
  chatId: string;
  roomLabel: string;
}
