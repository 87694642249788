import { Pipe, PipeTransform } from '@angular/core';
import { IMatrixMessage, IMessageEventPaymentRequest, MessageEventType } from '@wakanda/agent-chat-model';

@Pipe({ name: 'isPaymentDone', pure: true })
export class ChatMessagePaymentDonePipe implements PipeTransform {
  lockInput: boolean;
  transform(messages: IMatrixMessage[], paymentRequestMessage: IMatrixMessage<IMessageEventPaymentRequest>): boolean {
    if (!messages?.length || !paymentRequestMessage) return false;
    const actionId = paymentRequestMessage.content?.actionId;
    return !!messages.find(
      message => message.content?.msgtype === MessageEventType.PAYMENT_DONE && message.content['actionId'] === actionId,
    );
  }
}
