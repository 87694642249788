<div
  class="ui-divider"
  *ngIf="!vertical"
  [ngClass]="{
    compact: !!compact,
    large: !!large,
    padded: !!padded,
    'full-width': !!fullWidth
  }"
>
  <ng-container *ngTemplateOutlet="uiDividerLine"></ng-container>
</div>

<span
  class="ui-divider vertical"
  *ngIf="!!vertical"
  [ngClass]="{
    compact: !!compact,
    large: !!large,
    padded: !!padded
  }"
>
  <ng-container *ngTemplateOutlet="uiDividerLine"></ng-container>
</span>

<ng-template #uiDividerLine>
  <div
    class="line"
    [ngClass]="{
      dotted: !!dotted,
      solid: !!solid,
      compact: !!compact,
      large: !!large,
      padded: !!padded
    }"
  ></div>
</ng-template>
