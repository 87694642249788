import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService implements OnDestroy {
  protected destroy$: Subject<void> = new Subject<void>();

  constructor(private http: HttpClient) {}
  public get<T>(
    url: string,
    query: any = {},
    headers?: HttpHeaders | { [header: string]: string | string[] },
    responseType: any = 'json',
  ): Observable<T> {
    const options = {
      params: this.createQueryParams(query),
      headers,
      responseType,
    };
    return this.http.get<T>(url, options).pipe(takeUntil(this.destroy$));
  }

  public post<Payload>(
    url: string,
    payload?: Payload,
    responseType: any = 'json',
    headers?: HttpHeaders | { [header: string]: string | string[] },
    params?: any,
  ): Observable<any> {
    return this.http.post<Payload>(url, payload, { responseType, headers, ...params }).pipe(map(response => response));
  }

  public put<T>(
    url: string,
    payload: T,
    headers?: HttpHeaders | { [header: string]: string | string[] },
  ): Observable<any> {
    return this.http.put<T>(url, payload, { headers }).pipe(takeUntil(this.destroy$));
  }

  public patch<T>(
    url: string,
    payload: T,
    headers?: HttpHeaders | { [header: string]: string | string[] },
  ): Observable<any> {
    return this.http.patch<T>(url, payload, { headers }).pipe(takeUntil(this.destroy$));
  }
  public remove<T>(
    url: string,
    payload?: T,
    headers?: HttpHeaders | { [header: string]: string | string[] },
  ): Observable<any> {
    const options = payload && !headers ? payload : { headers, ...payload };
    return this.http.delete(url, options).pipe(takeUntil(this.destroy$));
  }

  private createQueryParams(query: any): HttpParams {
    let params = new HttpParams();

    Object.keys(query ?? {}).forEach(key => (params = params.append(key, query[key])));

    return params;
  }

  public getConfigFile(path: string): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('ngsw-bypass', 'true');
    return this.http.get(path, { headers: header }).pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy(): void {
    // This aborts all HTTP requests.
    this.destroy$.next();
    // This completes the subject properlly.
    this.destroy$.complete();
  }
}
