import { Action } from '@ngrx/store';
import { IUpgradeWalletNextStepResponse } from '../../models/upgrade-wallet.model';
import { UpgradeWalletActions } from './upgrade-wallet.actions';
import { EVENT } from './upgrade-wallet.events';

export const UPGRADE_WALLET_FEATURE_KEY = 'upgradeWallet';

export interface UpgradeWalletState {
  nextStep?: IUpgradeWalletNextStepResponse;
}

export function upgradeWalletReducer(state: UpgradeWalletState = {}, actions: Action): UpgradeWalletState {
  const action = actions as UpgradeWalletActions;
  switch (action.type) {
    case EVENT.UPGRADE_WALLET_INIT_RECEIVED:
    case EVENT.UPGRADE_WALLET_ADDRESS_RECEIVED:
    case EVENT.UPGRADE_WALLET_SECURITY_QUESTIONS_RECEIVED:
    case EVENT.UPGRADE_WALLET_ID_PHOTO_RECEIVED:
    case EVENT.UPGRADE_WALLET_PERSONAL_DATA_RECEIVED:
      return Object.assign({}, state, { nextStep: action.response });
    default:
      return state;
  }
}
