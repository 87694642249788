/// <reference types="@types/googlemaps" />
import { Inject, Injectable } from '@angular/core';
import { bindCallback, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { IPosition } from '../../models/location.model';
import { HttpClient } from '@angular/common/http';
import { IEnvironment } from '../../models/common.model';
import { ENVIRONMENT } from '../../common/tokens';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsPredictionService {
  private autocompleteService: google.maps.places.AutocompleteService;
  private geocoder: google.maps.Geocoder;

  constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

  mapsApi$ = this.httpClient
    .jsonp(
      `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.environment?.googleMapApiKey}`,
      'callback',
    )
    .pipe(
      map(() => {
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.geocoder = new google.maps.Geocoder();
        return true;
      }),
      shareReplay(1),
    );

  getPlaceByQuery(query: string, countryCodes?: string[]): Observable<any> {
    const country = countryCodes ?? ['ZA', 'NA'];
    const request: google.maps.places.AutocompletionRequest = {
      input: query,
      componentRestrictions: { country },
    };

    return this.callMapsApi((callback: any) => this.autocompleteService.getPlacePredictions(request, callback));
  }

  getPlaceByAddress(address: string): Observable<any> {
    const request: google.maps.GeocoderRequest = {
      address,
    };

    return this.callMapsApi((callback: any) => this.geocoder.geocode(request, callback));
  }

  getPlaceByLocations(position: IPosition): Observable<any> {
    if (!position || !position.latitude || !position.longitude) return of([]);

    const request: google.maps.GeocoderRequest = {
      location: {
        lat: position.latitude,
        lng: position.longitude,
      },
    };

    return this.callMapsApi((callback: any) => this.geocoder.geocode(request, callback));
  }

  private callMapsApi(callback: any): Observable<unknown> {
    if (!this.geocoder) {
      return this.mapsApi$.pipe(switchMap(() => bindCallback(callback)()));
    } else {
      return bindCallback(callback)();
    }
  }
}
