import { ChartDataset } from 'chart.js';
import { IUiTableHeaderItem } from './table.model';

export enum ChartValueFormat {
  NUMBER = 'NUMBER',
  AMOUNT = 'AMOUNT',
  PERCENT = 'PERCENT',
}

export interface IChartDataItem {
  label?: string;
  tooltipLabels?: string[];
  y?: number;
  x?: number; // bar horizontal stacked value
}

export interface IChartData<T> {
  datasets: ChartDataset<any>[];
  labels: string[];
}

export interface IChartTableData {
  headers: IUiTableHeaderItem[];
  columns: string[];
  data: unknown[];
}

export interface ISellerAnalytics {
  summaryWidgets: ISummaryWidget[];
  chartsWidgets: ISellerAnalyticsWidget[];
}

export interface ISummaryWidget {
  summaryType: SummaryType;
  value?: number;
  valuePreviousPeriod?: number;
  valuePreviousPeriodRatio?: number;
}

export interface ISellerAnalyticsWidget {
  insightType: InsightType;
  widgetType: WidgetType;
  chart?: IChart;
  tableChart?: ITableChart;
}

export interface ILabel {
  verbatim: string;
  placeholder: string;
}

export interface IChart {
  seriesList: IChartSeries[];
  dateTimeLabels?: string[];
  stringLabels?: ILabel[];
  labelType?: LabelType;
}

export interface IChartSeries {
  label: ILabel;
  multilineLabel?: ILabel[];
  values: IChartValue[];
}

export interface IChartValue {
  label?: ILabel;
  tooltipLabels?: string[];
  value: number;
}

export interface ITableChart {
  headers: ILabel[];
  rows: ITableRow[];
}

export interface ITableRow {
  headers: ILabel[];
  cells: ITableValue[];
}

export interface ITableValue {
  label: ILabel;
  tooltipLabels?: ILabel[];
  value?: number;
}

export enum LabelType {
  STRING = 'STRING',
  DATETIME = 'DATETIME',
  NONE = 'NONE',
}

export enum WidgetType {
  LINE = 'LINE',
  BAR = 'BAR',
  HORIZONTAL_BAR = 'HORIZONTAL_BAR',
  DOUGHNUT = 'DOUGHNUT',
  STACKED_BAR = 'STACKED_BAR',
  TABLE = 'TABLE',
}

export enum InsightType {
  REVENUE_BY_TIME_OF_DAY = 'REVENUE_BY_TIME_OF_DAY',
  BESTSELLING_PRODUCTS = 'BESTSELLING_PRODUCTS',
  NUMBER_OF_ORDERS_AND_ITEMS_PER_ORDER = 'NUMBER_OF_ORDERS_AND_ITEMS_PER_ORDER',
  TOP_ITEMS = 'TOP_ITEMS',
  REVENUE_AND_ORDERS = 'REVENUE_AND_ORDERS',
  CUSTOMER_TYPE_SUMMARY_MARKETING = 'CUSTOMER_TYPE_SUMMARY_MARKETING',
  ORDERS_RECEIVED_VS_COMPLETED = 'ORDERS_RECEIVED_VS_COMPLETED',
  NUMBER_OF_ORDERS_PER_TIME_OF_DAY = 'NUMBER_OF_ORDERS_PER_TIME_OF_DAY',
  NUMBER_OF_ORDERS_PER_DELIVERY_METHOD = 'NUMBER_OF_ORDERS_PER_DELIVERY_METHOD',
  TOTAL_ITEMS_SOLD_PER_CATEGORY = 'TOTAL_ITEMS_SOLD_PER_CATEGORY',
  NOT_COMPLETED_ORDERS = 'NOT_COMPLETED_ORDERS',
  SLA_BREACHES = 'SLA_BREACHES',
  ITEMS_BY_CATEGORIES = 'ITEMS_BY_CATEGORIES',
  REVENUE_FROM_DEALS_PER_DAY = 'REVENUE_FROM_DEALS_PER_DAY',
  REVENUE_BY_CATEGORIES = 'REVENUE_BY_CATEGORIES',
  CITY_WISE_MARKET_SHARE = 'CITY_WISE_MARKET_SHARE',
  REVENUE_BY_CUSTOMER_SEGMENT = 'REVENUE_BY_CUSTOMER_SEGMENT',
  CITY_WISE_ORDERS = 'CITY_WISE_ORDERS',
  CITY_WISE_REVENUE = 'CITY_WISE_REVENUE',
  CUSTOMER_TYPE_FINANCE = 'CUSTOMER_TYPE_FINANCE',
  CUSTOMER_SPEND_ANALYSIS_ORDERS = 'CUSTOMER_SPEND_ANALYSIS_ORDERS',
  CUSTOMER_SPEND_ANALYSIS_REVENUE = 'CUSTOMER_SPEND_ANALYSIS_REVENUE',
  REVENUE_BY_CATEGORY = 'REVENUE_BY_CATEGORY',
  MARKET_SHARE_BY_REGION_ORDERS = 'MARKET_SHARE_BY_REGION_ORDERS',
  MARKET_SHARE_BY_REGION_REVENUE = 'MARKET_SHARE_BY_REGION_REVENUE',
}

export enum SummaryType {
  TOTAL_ORDERS = 'TOTAL_ORDERS',
  TOTAL_REVENUE = 'TOTAL_REVENUE',
  CANCELLED_ORDERS = 'CANCELLED_ORDERS',
  OVERALL_RATING = 'OVERALL_RATING',
  NEW_VISITORS = 'NEW_VISITORS',
  MISSED_REVENUE = 'MISSED_REVENUE',
  AVERAGE_ORDER_VALUE = 'AVERAGE_ORDER_VALUE',
  TOTAL_SALES = 'TOTAL_SALES',
  TOTAL_ITEMS = 'TOTAL_ITEMS',
  MISSED_ORDERS = 'MISSED_ORDERS',
  AVERAGE_ITEMS_PER_ORDER = 'AVERAGE_ITEMS_PER_ORDER',
  DELAYED_ORDERS = 'DELAYED_ORDERS',
}
