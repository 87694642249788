import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrowserStorageService } from '../services/browser-storage.service';
import { StorageKeys } from '../store/models/store.models';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private storageService: BrowserStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    const token = this.storageService.get(StorageKeys.TOKEN, null);
    if (!token) {
      return next.handle(request);
    }
    const tokenData = token['access_token'] || token['accessToken'];
    if (token && tokenData && !request.url.includes('configuration.json')) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${tokenData}`),
      });
    }

    return next.handle(request);
  }
}
