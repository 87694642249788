import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { IRecoveryNextStepResponse } from '../../models/recovery.model';
import { RecoveryService } from '../../services/recovery/recovery.service';
import { ApplicationFacade } from '../application/application.facade';
import { failed, HttpErrorAction, pushNotification, succeed } from '../application/task.actions';
import * as actions from './recovery.actions';
import { EVENT } from './recovery.events';

@Injectable()
export class RecoveryEffects {
  recoveryStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RecoveryStartRequestAction>(EVENT.RECOVERY_START_REQUEST),
      switchMap(payload =>
        this.recoveryService.startRecovery(payload.payload).pipe(
          map((data: IRecoveryNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.RecoveryStartResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  recoveryUsername$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RecoveryUsernameRequestAction>(EVENT.RECOVERY_USERNAME_REQUEST),
      switchMap(payload =>
        this.recoveryService.usernameRecovery(payload.payload).pipe(
          map((data: IRecoveryNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.RecoveryUsernameResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload?.onError) {
              payload.onError(error);
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  recoveryPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RecoveryPasswordRequestAction>(EVENT.RECOVERY_PASSWORD_REQUEST),
      switchMap(payload =>
        this.recoveryService.passwordRecovery(payload.payload).pipe(
          map((data: IRecoveryNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.RecoveryPasswordResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            const errorCode = error?.error?.error?.code || error?.error?.code;
            if (payload.onError && errorCode === 'integrated_channel_username') {
              payload.onError(error);
              return of(failed(new HttpErrorAction(payload.taskId, error, true)));
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  recoveryPasswordOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RecoveryPasswordVerifyOtpRequestAction>(EVENT.RECOVERY_PASSWORD_VERIFY_OTP_REQUEST),
      switchMap(payload =>
        this.recoveryService.passwordVerifyOtpRecovery(payload.payload).pipe(
          map((data: IRecoveryNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.RecoveryPasswordVerifyOtpResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload?.onError) {
              payload.onError(error);
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );
  recoveryPassworNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RecoveryPasswordNewPasswordRequestAction>(EVENT.RECOVERY_PASSWORD_NEW_REQUEST),
      switchMap(payload =>
        this.recoveryService.passwordNewPasswordRecovery(payload.payload).pipe(
          map((data: IRecoveryNextStepResponse) => {
            payload.onSucceeded(data);
            return pushNotification(
              new actions.RecoveryPasswordNewPasswordResponseAction(payload.taskId, data),
              payload.notificationText,
            );
          }),
          catchError(error => {
            if (payload?.onError) {
              payload.onError(error);
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private appFacade: ApplicationFacade,
    private recoveryService: RecoveryService,
  ) {}
}
