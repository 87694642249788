import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'leadingZeroesFormat',
  pure: true,
})
export class LeadingZeroesFormatPipe implements PipeTransform {
  transform(value: string, size?: number, replaceWith?: string): string {
    if (!value) return value;
    return value.trim().padStart(size || 10, replaceWith || '0');
  }
}
