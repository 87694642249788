import { Action } from '@ngrx/store';
import { EVENT } from './app-config.events';

export class AppConfigRequestAction implements Action {
  readonly type = EVENT.APP_CONFIG_REQUEST;
  constructor(public taskId: string) {}
}

export class AppConfigReceivedAction implements Action {
  readonly type = EVENT.APP_CONFIG_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

export type AppConfigActions = AppConfigRequestAction | AppConfigReceivedAction;
