import { Injectable } from '@angular/core';
import { AssetsBucketsEnum, IAssetDMZUploadInfo, IAssetStatusResponse } from '../../models/assets.model';
import * as endpoints from './assets.endpoints';
import { Observable } from 'rxjs';
import { HttpEvent } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { AppConfig } from '../../configs/app.config';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(private apiService: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string => {
    let url = useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiV3Url;

    // agent chat app
    if (!url) url = this.appConfig.backendConfig.apiUrl;

    return url;
  };

  public postAsset(useMockApi: boolean, file: FormData, bucket: AssetsBucketsEnum): Observable<IAssetDMZUploadInfo> {
    return this.apiService.post<FormData>(endpoints.postAsset(this.getApiUrl(useMockApi), bucket), file);
  }

  public postAssetWithProgress(
    useMockApi: boolean,
    file: FormData,
    bucket: AssetsBucketsEnum,
  ): Observable<HttpEvent<IAssetDMZUploadInfo>> {
    return this.apiService.post<FormData>(endpoints.postAsset(this.getApiUrl(useMockApi), bucket), file, 'json', null, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public getAssetStatus(
    useMockApi: boolean,
    dmzFilesId: string,
    bucket: AssetsBucketsEnum,
  ): Observable<IAssetStatusResponse> {
    return this.apiService.get<IAssetStatusResponse>(
      endpoints.getAssetStatus(this.getApiUrl(useMockApi), dmzFilesId, bucket),
    );
  }
}
