import { ModuleWithProviders, NgModule } from '@angular/core';
import { AdobeConfig } from './configs';
import { AdobeService } from './services';
import { ADOBE_CONFIG } from './tokens';

@NgModule()
export class AdobeModule {
  static forRoot(config: AdobeConfig): ModuleWithProviders<AdobeModule> {
    return {
      ngModule: AdobeModule,
      providers: [
        AdobeService,
        {
          provide: ADOBE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
