import { Injectable } from '@angular/core';
import { ApiService, AppConfig } from '@wakanda/wakanda-core';
import { Observable } from 'rxjs';
import * as endpoints from './avo-business.endpoints';
import { IContactSupportRequest } from '@wakanda/avo-business-model';

@Injectable({
  providedIn: 'root',
})
export class AvoBusinessService {
  constructor(private apiService: ApiService, private appConfig: AppConfig) {}

  public sendContactSupportEmail = (request: IContactSupportRequest): Observable<IContactSupportRequest> =>
    this.apiService.post(endpoints.sendContactSupportEmail(this.appConfig.backendConfig.apiUrl), request);
}
