export enum EVENT {
  RECOVERY_START_REQUEST = 'RECOVERY/START/REQUEST',
  RECOVERY_START_RECEIVED = 'RECOVERY/START/RECEIVED',
  RECOVERY_USERNAME_REQUEST = 'RECOVERY/USERNAME/REQUEST',
  RECOVERY_USERNAME_RECEIVED = 'RECOVERY/USERNAME/RECEIVED',
  RECOVERY_PASSWORD_REQUEST = 'RECOVERY/PASSWORD/REQUEST',
  RECOVERY_PASSWORD_RECEIVED = 'RECOVERY/PASSWORD/RECEIVED',
  RECOVERY_PASSWORD_VERIFY_OTP_REQUEST = 'RECOVERY/PASSWORD/OTP/REQUEST',
  RECOVERY_PASSWORD_VERIFY_OTP_RECEIVED = 'RECOVERY/PASSWORD/OTP/RECEIVED',
  RECOVERY_PASSWORD_NEW_REQUEST = 'RECOVERY/PASSWORD/NEW/REQUEST',
  RECOVERY_PASSWORD_NEW_RECEIVED = 'RECOVERY/PASSWORD/NEW/RECEIVED',
  RECOVERY_CLEAR = 'RECOVERY/CLEAR',
}
