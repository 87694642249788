import { IAmount } from './common.model';
import { IImageFileResponse } from './image.model';

export interface IJobCardMerchantFee {
  feeType: JobCardMerchantFeeType;
  amount: IAmount;
}

export enum JobCardMerchantFeeType {
  CALLOUT = 'CALLOUT',
  HOURLY = 'HOURLY',
}

export interface IJobCardJobOffer {
  id: string;
  name: string;
  picture: string;
  description: string;
  details: string;
}

export interface IJobCardMerchantPhoneNumber {
  primary: boolean;
  phoneNumber: string;
  phoneNumberDisplay: string;
  description?: string;
}

export interface IRateUsRequest {
  stars: number;
  recommendation: number;
  rateUsType: RateUsType;
  description?: string;
}

export enum RateUsType {
  SUCCESSFUL_ORDER_DETAILS = 'SUCCESSFUL_ORDER_DETAILS',
  SUCCESSFUL_ORDER_EXIT = 'SUCCESSFUL_ORDER_EXIT',
  MOBILE_APP = 'MOBILE_APP',
  MERCHANT = 'MERCHANT',
  RESTAURANT = 'RESTAURANT',
  DELIVERY_EXPERIENCE = 'DELIVERY_EXPERIENCE',
  PICKUP_EXPERIENCE = 'PICKUP_EXPERIENCE',
}

export interface IMerchantDetail {
  id: string;
  name: string;
  picture: string;
  rating: number;
  numberOfReviews: number;
  description: string;
  offers?: ICustomerJobOffer;
  pictures?: IImageFileResponse;
}

export interface ICustomerJobOffer {
  id: string;
  name: string;
  picture: string;
  description: string;
  details: string;
}

export interface IRateMerchant {
  rating: number;
  reviewText?: string;
  images?: string[];
}
