import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WakandaCoreModule } from '@wakanda/wakanda-core';
import { LayoutRoutingModule } from './layout-routing.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { SidemenuComponent } from './sidebar-menu/sidemenu.component';
import { HeaderSellingOnAvoMenuComponent } from './header-selling-on-avo-menu/header-selling-on-avo-menu.component';
import { ContactSupportModalComponent } from './contact-support-modal/contact-support-modal.component';
import { SidebarMenuItemComponent } from './sidebar-menu-item/sidebar-menu-item.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    FooterComponent,
    HeaderComponent,
    HeaderSellingOnAvoMenuComponent,
    SidemenuComponent,
    SidebarMenuItemComponent,
    ContactSupportModalComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, WakandaCoreModule, LayoutRoutingModule, SharedModule],
})
export class LayoutModule {}
