import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'notificationDateFormat',
  pure: true,
})
export class NotificationDateFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

    if (seconds > 86400) return dayjs(value).format('DD.MM.YY HH:mm');
    if (seconds > 0 && seconds < 300) return 'just now';

    const intervals = {
      hour: 3600,
      minute: 60,
      second: 1,
    };

    let counter: string | number;

    for (const i in intervals) {
      if (Object.prototype.hasOwnProperty.call(intervals, i)) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) return counter === 1 ? `${counter} ${i} ago` : `${counter} ${i}s ago`;
      }
    }

    return '';
  }
}
