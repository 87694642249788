import { Injectable } from '@angular/core';
import { IReferralCode } from '../../models/referral.model';

@Injectable()
export class ReferralService {
  referralCode: IReferralCode;
  isDialogOpen: boolean;

  removeReferralLink = (): void => {
    this.referralCode = null;
  };

  setReferralLink = (referralLink: IReferralCode): void => {
    this.referralCode = referralLink;
  };

  handleReferralOnboarding = (unsuccessful?: boolean): void => {
    if (unsuccessful || this.isDialogOpen) {
      this.isDialogOpen = false;
      this.removeReferralLink();
      return;
    }

    this.isDialogOpen = true;
  };
}
