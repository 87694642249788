import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui2-tooltip',
  template: `
    <div class="ui-tooltip tooltip-container">
      <div class="tooltip" [class.bottom]="alignBottom">
        <p class="small" [ngStyle]="{ 'max-width': maxWidth }">{{ title }}</p>
      </div>
      <ng-content></ng-content>
    </div>
  `,
})
export class TooltipComponent {
  @Input() title: string;
  @Input() alignBottom = false;
  @Input() maxWidth: string;
}
