import { Action } from '@ngrx/store';
import {
  ICoupon,
  ILoyaltyBalance,
  ILoyaltyFxConvert,
  ILoyaltyFxConvertResponse,
  ILoyaltyFxLinkResponse,
  ILoyaltyFxPartner,
  ILoyaltyFxQuoteResponse,
  ILoyaltyPoints,
  ILoyaltyWalletTransaction,
} from '../../models/reward.model';
import * as actions from './my-rewards.actions';
import { EVENTS } from './my-rewards.events';

export const MY_REWARDS_FEATURE_KEY = 'myRewards';

export interface IMyRewardsState {
  points?: ILoyaltyPoints;
  transactions?: ILoyaltyWalletTransaction[];
  coupons?: ICoupon[];
  couponDetail?: ICoupon;
  partners?: ILoyaltyFxPartner[];
  linkResponse?: ILoyaltyFxLinkResponse;
  quoteResponse?: ILoyaltyFxQuoteResponse;
  convertRequest?: ILoyaltyFxConvert;
  convertResponse?: ILoyaltyFxConvertResponse;
  balance?: ILoyaltyBalance;
}

export interface IMyRewardsPartialState {
  readonly [MY_REWARDS_FEATURE_KEY]: IMyRewardsState;
}

const ACTION_HANDLERS = {
  [EVENTS.MY_REWARDS_TRANSACTIONS_RECEIVED]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetTransactionsResponseAction,
  ): IMyRewardsState => {
    const transactions: ILoyaltyWalletTransaction[] = action.data;
    return Object.assign({}, state, { transactions });
  },

  [EVENTS.MY_REWARDS_COUPONS_RECEIVED]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetCouponsResponseAction,
  ): IMyRewardsState => {
    const coupons: ICoupon[] = action.data;
    return Object.assign({}, state, { coupons });
  },
  [EVENTS.MY_REWARDS_COUPON_DETAIL_RECEIVED]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetCouponDetailResponseAction,
  ): IMyRewardsState => {
    const couponDetail: ICoupon = action.data;
    return Object.assign({}, state, { couponDetail });
  },
  [EVENTS.MY_REWARDS_POINTS_RECEIVED]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetPointsResponseAction,
  ): IMyRewardsState => {
    const points: ILoyaltyPoints = action.data;
    return Object.assign({}, state, { points });
  },
  [EVENTS.MY_REWARDS_LOYALTY_FX_RECEIVED]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetLoyaltyFXResponseAction,
  ): IMyRewardsState => {
    const partners: ILoyaltyFxPartner[] = action.data;
    return Object.assign({}, state, { partners });
  },
  [EVENTS.MY_REWARDS_LOYALTY_FX_LINK_RECEIVED]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetLoyaltyFXLinkResponseAction,
  ): IMyRewardsState => {
    const linkResponse: ILoyaltyFxLinkResponse = action.data;
    return Object.assign({}, state, { linkResponse });
  },
  [EVENTS.MY_REWARDS_LOYALTY_FX_QUOTE_RECEIVED]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetLoyaltyFXQuoteResponseAction,
  ): IMyRewardsState => {
    const quoteResponse: any = action.data;
    return Object.assign({}, state, { quoteResponse });
  },
  [EVENTS.MY_REWARDS_LOYALTY_FX_CONVERT_REQUEST]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetLoyaltyFXConvertRequestAction,
  ): IMyRewardsState => {
    const convertRequest: ILoyaltyFxConvert = action.payload;
    return Object.assign({}, state, { convertRequest });
  },
  [EVENTS.MY_REWARDS_LOYALTY_FX_CONVERT_RECEIVED]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetLoyaltyFXConvertResponseAction,
  ): IMyRewardsState => {
    const convertResponse: ILoyaltyFxConvertResponse = action.data;
    return Object.assign({}, state, { convertResponse });
  },
  [EVENTS.MY_REWARDS_BALANCE_RECEIVED]: (
    state: IMyRewardsState,
    action: actions.MyRewardsGetBalanceResponseAction,
  ): IMyRewardsState => {
    const balance: ILoyaltyBalance = action.data;
    return Object.assign({}, state, { balance });
  },
};

export function myRewardsReducer(state: IMyRewardsState = {}, action: Action): IMyRewardsState {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
