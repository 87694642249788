import { Injectable } from '@angular/core';
import { AppConfig } from '@wakanda/wakanda-core';
import { Observable } from 'rxjs';
import { localizations, localizations_new } from '../endpoints/translate.endpoint';
import { ApiService } from './api.service';

@Injectable()
export class TranslateService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  public fetchTranslates(): Observable<any> {
    return this.api.get<any>(localizations(this.appConfig.backendConfig.translateUrl, 'wakanda'));
  }

  public fetchNewTranslates(url: string): Observable<any> {
    return this.api.get<any>(localizations_new(url));
  }
}
