import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  IFilterPayoutTransactionRequest,
  IFilterSettlementRequest,
  IGenerateRemittanceRequest,
  IMyMoneyWalletState,
  IPayByCardRequest,
  IPayByCardResponse,
  IPayByCreditRequest,
  IPayByCreditResponse,
  IPayByWalletRequest,
  IPaymentRemittanceExport,
  ISellerStatementExport,
  ISellerStatementExportRequest,
  ISellerStatementRequest,
  ISettlementExport,
  ITaskDetail,
  ITaxInvoice,
  ITaxInvoiceRequest,
  ITopUpAndPayRequest,
  ITopUpAndPayResponse,
  IWallet,
  IWalletAddCardResponse,
  IWalletChangeSettlementSettingsRequest,
  IWalletCheckPayRequest,
  IWalletEditCardRequest,
  IWalletExternalAccount,
  IWalletPartyAccount,
  IWalletPaymentLinkResponse,
  IWalletPaymentRequest,
  IWalletPayResponse,
  IWalletQrCodePaymentRequest,
  IWalletRequest,
  IWalletSettlementAccount,
  IWalletSettlementAccountRequest,
  IWalletSettlementAccountTypeRequest,
  IWalletSettlementSettings,
  IWalletStatementFilter,
  IWalletSubscription,
  IWalletSubscriptionAvailable,
  IWalletSubscriptionRequest,
  IWalletTopUpAccountRequest,
  IWalletTopUpAccountResponse,
  IWalletTopUpAddCardRequest,
  IWalletTopUpCardResponse,
  IWalletTopUpPayRequest,
  IWalletTopUpStatusResponse,
  IWalletWithdrawRequest,
  MY_MONEY_WALLET_FEATURE_KEY,
  SellerPayoutTransactions,
  WalletDashboardWidgetType,
} from '../../models/wallet.model';
import { v4 as uuidv4 } from 'uuid';
import * as generalSelector from './wallet.selectors';
import { pushNotification, started } from '../application/task.actions';
import { baseActions } from './wallet.actions';
import { Observable } from 'rxjs';
import { IShoppingAvailablePaymentMethod } from '../../models/shopping.model';
import { IBuyer } from '../../models/buyer.model';

export interface IMyMoneyWalletPartialState {
  readonly [MY_MONEY_WALLET_FEATURE_KEY]: IMyMoneyWalletState;
}

@Injectable({ providedIn: 'root' })
export class WalletFacade {
  getWallets$ = this.store.pipe(select(generalSelector.getWallets));
  getWallet$ = this.store.pipe(select(generalSelector.getWallet));
  getWalletTransactions$ = this.store.pipe(select(generalSelector.getTransactions));
  getWalletRecentTransactions$ = this.store.pipe(select(generalSelector.getRecentTransactions));
  getWalletTransactionDetail$ = this.store.pipe(select(generalSelector.getTransactionDetail));
  getMainWallet$ = this.store.pipe(select(generalSelector.getMainWallet));
  getPaymentMethod$ = this.store.pipe(select(generalSelector.getPaymentMethod));
  getQRData$ = this.store.pipe(select(generalSelector.getQRData));
  getQRCode$ = this.store.pipe(select(generalSelector.getQRCode));
  getQRCodePayRequest$ = this.store.pipe(select(generalSelector.getQRCodePayRequest));
  getQRCodePayRequestOriginalString$ = this.store.pipe(select(generalSelector.getQRCodePayRequestOriginalString));
  getPayRecentRecipient$ = this.store.pipe(select(generalSelector.getPayRecentRecipient));
  getExternalAccounts$ = this.store.pipe(select(generalSelector.getExternalAccounts));
  getSavedExternalAccount$ = this.store.pipe(select(generalSelector.getSavedExternalAccount));
  getBanks$ = this.store.pipe(select(generalSelector.getBanks));
  getFilteredBanks$ = this.store.pipe(select(generalSelector.getFilteredBanks));
  getAddCard$ = this.store.pipe(select(generalSelector.getAddCard));
  getCardTopUp$ = this.store.pipe(select(generalSelector.getCardTopUp));
  getSelectedCardTopUp$ = this.store.pipe(select(generalSelector.getSelectedCardTopUp));
  getFilter$ = this.store.pipe(select(generalSelector.getFilter));
  getPaymentLinkResponse$ = this.store.pipe(select(generalSelector.getPaymentLinkResponse));
  getPayTopUp$ = this.store.pipe(select(generalSelector.getPayTopUp));
  getLoyaltyPoints$ = this.store.pipe(select(generalSelector.getLoyaltyPoints));
  getPartyAccounts$ = this.store.pipe(select(generalSelector.getPartyAccounts));
  getSubscriptions$ = this.store.pipe(select(generalSelector.getSubscriptions));
  getAvailableSubscriptions$ = this.store.pipe(select(generalSelector.getAvailableSubscriptions));
  getWithdrawResponse$ = this.store.pipe(select(generalSelector.getWithdrawResponse));
  getNedbankBanks$ = this.store.pipe(select(generalSelector.getNedbankBanks));
  getDebitAccount$ = this.store.pipe(select(generalSelector.getDebitAccount));
  /** Active buyer can be empty. */
  getActiveBuyer$ = this.store.pipe(select(generalSelector.getActiveBuyer));
  getDashboardWidget$ = this.store.pipe(select(generalSelector.getDashboardWidget));
  getHasBuyerStandardSubscription$ = this.store.pipe(select(generalSelector.getHasBuyerStandardSubscription));
  getSellerPayoutTransactions$ = this.store.pipe(select(generalSelector.getSellerPayoutTransactions));
  getSellerPayoutTransactionItem$ = this.store.pipe(select(generalSelector.getSellerPayoutTransactionItem));
  getTaxInvoices$ = this.store.pipe(select(generalSelector.getTaxInvoices));
  getSellerStatements$ = this.store.pipe(select(generalSelector.getSellerStatements));
  getBankAccountsFiltered$ = (isDebit?: boolean): Observable<IWalletSettlementAccount[]> =>
    this.store.pipe(select(generalSelector.getBankAccountsFiltered(isDebit)));

  constructor(private store: Store<IMyMoneyWalletPartialState>) {}

  fetchWallets = (onSucceed?: (data: IWallet[]) => void, onError?: (error: any) => void, silently?: boolean): void => {
    const taskId = `fetch-wallets-${uuidv4()}`;
    this.store.dispatch(
      started(new baseActions.MyMoneyWalletsRequestAction(taskId, onSucceed, onError), 'Fetching wallets', silently),
    );
  };

  fetchWalletTransactions = (walletId: string, silently?: boolean, onSucceed?: () => void): void => {
    const taskId = `fetch-transactions-by-id-${walletId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTransactionsRequestAction(taskId, walletId, onSucceed),
        'Fetching wallet transactions',
        silently,
      ),
    );
  };

  fetchWalletTransactionDetail = (walletId: string, transactionId: string, silently?: boolean): void => {
    const taskId = `fetch-transaction-detail-${walletId}-${transactionId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTransactionDetailRequestAction(taskId, walletId, transactionId),
        'Fetching wallet transaction detail',
        silently,
      ),
    );
  };

  fetchWalletTransactionSearch = (walletId: string, query: string, onSucceed?: () => void): void => {
    const taskId = `fetch-transaction-search-${walletId}-${query}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTransactionSeaarchRequestAction(taskId, walletId, query, onSucceed),
        'Fetching search results',
        true,
      ),
    );
  };

  fetchWalletTransactionRecent = (walletId: string, silent?: boolean): void => {
    const taskId = `fetch-recent-transactions-for-wallet-${walletId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTransactionRecentRequestAction(taskId, walletId),
        'Fetching recent searchs',
        silent,
      ),
    );
  };

  fetchWalletTransactionsByFilter = (
    walletId: string,
    statementFilter: IWalletStatementFilter,
    onSucceed?: () => void,
    silently?: boolean,
  ): void => {
    const taskId = `fetch-wallet-filter-${walletId}-${JSON.stringify(statementFilter)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTransactionFilterRequestAction(taskId, walletId, statementFilter, onSucceed),
        'Fetching search results',
        silently,
      ),
    );
  };

  downloadStatement = (
    walletId: string,
    statementFilter: IWalletStatementFilter,
    onSucceed: (data: any) => void,
  ): void => {
    const taskId = `download-transaction-statement-${walletId}-${JSON.stringify(statementFilter)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTransactionDownloadStatementRequestAction(taskId, walletId, statementFilter, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  sendMyMoneyPay = (
    walletId: string,
    payRequest: IWalletPaymentRequest,
    onSucceed: (response) => void,
    onWalletError: (error) => void,
  ): void => {
    const taskId = `send-pay-request-${walletId}-${JSON.stringify(payRequest)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayRequestAction(taskId, walletId, payRequest, onSucceed, onWalletError),
        'Please wait a moment',
      ),
    );
  };

  fetchWalletPayRecentRecipients = (walletId: string, silently?: boolean): void => {
    const taskId = `fetch-wallet-recent-recipients-${walletId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayRecentRecipientsRequestAction(taskId, walletId),
        'Fetching recent payment recipients',
        silently,
      ),
    );
  };

  sendMyMoneyPayByQrCode = (
    walletId: string,
    payRequest: IWalletQrCodePaymentRequest,
    onSucceed: (response) => void,
    onWalletError: (error) => void,
  ): void => {
    const taskId = `send-pay-request-by-qrcode-${walletId}-${JSON.stringify(payRequest)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByQrCodeRequestAction(taskId, walletId, payRequest, onSucceed, onWalletError),
        'Please wait a moment',
      ),
    );
  };

  withdrawMoney = (
    walletId: string,
    withdrawRequest: IWalletWithdrawRequest,
    onSucceed: () => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `withdraw-money-${walletId}-${JSON.stringify(withdrawRequest)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyWithdrawRequestAction(taskId, walletId, withdrawRequest, onSucceed, onError),
        'Please wait a moment',
      ),
    );
  };

  selectMainWallet = (): void => {
    this.store.dispatch(new baseActions.MyMoneySelectMainWalletAction());
  };

  decodeQrCode = (data: string, onSucceed: () => void): void => {
    const taskId = `decode-qr-code-${data}`;
    this.store.dispatch(
      started(new baseActions.MyMoneyDecodeQrCodeRequestAction(taskId, data, onSucceed), 'Please wait a moment'),
    );
  };

  clearQrPaymentRequest = (): void => {
    const taskId = `clear-qr-payment`;
    this.store.dispatch(new baseActions.MyMoneyClearQrCodePaymentDataAction(taskId));
  };

  postPaymentRequest = (
    walletId: string,
    request: IWalletRequest,
    onSucceed: () => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `send-payment-request-${walletId}-${JSON.stringify(request)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyCreatePaymentRequestAction(taskId, walletId, request, onSucceed, onError),
        'Please wait a moment',
      ),
    );
  };

  copyTextNotification = (text: string): void => {
    this.store.dispatch(
      pushNotification(new baseActions.MyMoneyCopyLinkSucceedAction(), `${text} has been copied to clipboard!`),
    );
  };

  addCard = (
    walletId: string,
    addCardRequest: IWalletTopUpAddCardRequest,
    onSucceed: (data: IWalletAddCardResponse) => void,
  ): void => {
    const taskId = `addCard-${walletId}-${JSON.stringify(addCardRequest)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyAddCardRequestAction(taskId, walletId, addCardRequest, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  editCard = (
    walletId: string,
    cardId: string,
    request: IWalletEditCardRequest,
    onSucceed: (data: any) => void,
  ): void => {
    const taskId = `editCard-${walletId}-${cardId}-${JSON.stringify(request)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyEditCardRequestAction(taskId, walletId, cardId, request, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  fetchExternalAccounts = (
    walletId: string,
    destination: string,
    onSucceed?: (response: IWalletExternalAccount[]) => void,
    onError?: (error: any) => void,
    silently?: boolean,
  ): void => {
    const taskId = `fetchExternalAccounts-${walletId}-${destination}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyExternalAccountsRequestAction(taskId, walletId, destination, onSucceed, onError),
        'Fetching external accounts',
        silently,
      ),
    );
  };

  fetchBanks = (): void => {
    const taskId = `fetchBanks`;
    this.store.dispatch(started(new baseActions.MyMoneyBanksRequestAction(taskId), 'Fetching bank details'));
  };

  saveExternalAccount = (externalAccount: IWalletExternalAccount): void => {
    const taskId = `saveExternalAccount-${JSON.stringify(externalAccount)}`;
    this.store.dispatch(new baseActions.MyMoneySaveDefaultExternalAccountAction(taskId, externalAccount));
  };

  removeExternalAccount = (walletId: string, externalAccountId: string, onSucceed: () => void): void => {
    const taskId = `removeExternalAccount-${externalAccountId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyRemoveExternalAccountRequestAction(taskId, walletId, externalAccountId, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  fetchCardTopup = (
    walletId: string,
    destination: string,
    onSucceed?: (response: IWalletTopUpCardResponse[]) => void,
    silently?: boolean,
  ): void => {
    const taskId = `fetchCardTopup-${walletId}-${destination}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyCardWithDestinationRequestAction(taskId, walletId, destination, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  deleteCardTopup = (walletId: string, cardId: string, destination: string, onSucceed: () => void): void => {
    const taskId = `deleteCardTopup-${walletId}-${cardId}-${destination}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyDeleteCardRequestAction(taskId, walletId, cardId, destination, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  payTopUp = (
    walletId: string,
    cardId: string,
    pay: IWalletTopUpPayRequest,
    onSucceed: (data: IWalletAddCardResponse) => void,
  ): void => {
    const taskId = `payTopUp-${walletId}-${cardId}-${JSON.stringify(pay)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayTopUpRequestAction(taskId, walletId, cardId, pay, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  fetchPartyAccounts = (onSucceed: (data: IWalletPartyAccount[]) => void): void => {
    const taskId = `fetch-party-accounts`;
    this.store.dispatch(
      started(new baseActions.MyMoneyPartyAccountsRequestAction(taskId, onSucceed), 'Please wait a moment'),
    );
  };

  fetchPaymentLink = (
    paymentLink: string,
    onSucceed?: (data: IWalletPaymentLinkResponse) => void,
    onError?: () => void,
  ): void => {
    const taskId = `fetch-payment-link-${paymentLink}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyFetchPaymentLinkRequestAction(taskId, paymentLink, onSucceed, onError),
        'Please wait a moment',
      ),
    );
  };

  acceptPaymentLink = (
    walletId: string,
    paymentLinkId: string,
    onSucceed: () => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `accept-payment-link-${walletId}-${paymentLinkId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyAcceptPaymentLinkRequestAction(taskId, walletId, paymentLinkId, onSucceed, onError),
        'Please wait a moment',
      ),
    );
  };

  sendReminder = (walletId: string, paymentRequestId: string, message: string, onSucceed?: () => void): void => {
    const taskId = `send-reminder-${walletId}-${paymentRequestId}-${message}`;
    this.store.dispatch(
      started(new baseActions.MyMoneyReminderRequestAction(taskId, walletId, paymentRequestId, message, onSucceed)),
    );
  };

  fetchLoyaltyPoints = (silently?: boolean): void => {
    const taskId = `fetch-loyalty-points-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyLoyaltyPointsRequestAction(taskId),
        'Fetching Loyalty Points. Please wait a moment.',
        silently,
      ),
    );
  };

  payCheck = (
    walletId: string,
    payload: IWalletCheckPayRequest,
    onSucceed: (data: any) => void,
    silently = true,
  ): void => {
    const taskId = `pay-check-${walletId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTopupPayCheckRequestAction(taskId, walletId, payload, onSucceed),
        'Checking payment',
        silently,
      ),
    );
  };

  topupAccount = (
    walletId: string,
    payload: IWalletTopUpAccountRequest,
    onSucceed: (data: IWalletTopUpAccountResponse) => void,
  ): void => {
    const taskId = `topup-account-${walletId}-${JSON.stringify(payload)}`;
    this.store.dispatch(
      started(new baseActions.MyMoneyTopupAccountRequestAction(taskId, walletId, payload, onSucceed)),
    );
  };

  topupAccountStatus = (
    walletId: string,
    topupId: string,
    onSucceed: (data: IWalletTopUpStatusResponse) => void,
    loadLabel: string,
    silently?: boolean,
  ): void => {
    const taskId = `topup-account-status-${walletId}-${topupId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTopupAccountStatusRequestAction(taskId, walletId, topupId, onSucceed),
        loadLabel,
        silently,
      ),
    );
  };

  topupCardStatus = (
    walletId: string,
    cardId: string,
    topupId: string,
    onSucceed: (data: IWalletTopUpStatusResponse) => void,
    loadLabel: string,
    silently?: boolean,
  ): void => {
    const taskId = `topup-card-status-${walletId}-${cardId}-${topupId}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTopupCardStatusRequestAction(taskId, walletId, cardId, topupId, onSucceed),
        loadLabel,
        silently,
      ),
    );
  };

  fetchMerchantSubscriptions = (
    onSucceed?: (data: IWalletSubscription[]) => void,
    onError?: (error: any) => void,
    silently = false,
  ): void => {
    const taskId = `fetch-merchant-subscriptions-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyFetchMerchantSubscriptionsRequestAction(taskId, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  fetchAvailableMerchantSubscriptions = (
    onSucceed?: (data: IWalletSubscriptionAvailable[]) => void,
    silently = false,
  ): void => {
    const taskId = `fetch-available-merchant-subscriptions-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyFetchAvailableMerchantSubscriptionsRequestAction(taskId, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  addMerchantSubscription = (
    request: IWalletSubscriptionRequest,
    onSucceed?: (data: IWalletSubscription) => void,
    silently = false,
  ): void => {
    const taskId = `add-merchant-subscription-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyAddMerchantSubscriptionsRequestAction(taskId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payByCard = (
    flowId: string,
    request: IPayByCardRequest,
    onSucceed?: (response: IPayByCardResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-card-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByCardRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payByPointsOnly = (flowId: string, onSucceed: () => void, silently = false): void => {
    const taskId = `pay-by-points-only-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByPointsOnlyRequestAction(taskId, flowId, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payByWallet = (
    flowId: string,
    request: IPayByWalletRequest,
    onSucceed?: (response: IWalletPayResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-wallet-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByWalletRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payForFree = (flowId: string, onSucceed: () => void, silently = false): void => {
    const taskId = `pay-for-free-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayForFreeRequestAction(taskId, flowId, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  topUpAndPay = (
    flowId: string,
    request: ITopUpAndPayRequest,
    onSucceed?: (response: ITopUpAndPayResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-wallet-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyTopUpAndPayRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  clearPaymentInfo = (): void => {
    const taskId = `clear-payment-info-${uuidv4()}`;
    this.store.dispatch(new baseActions.MyMoneyClearPayInfoAction(taskId));
  };

  /**
   * Set active buyer for whole application.
   *
   * @access This method should be called only from {@link BusinessEntityService}
   * @param buyer New active buyer
   */
  setActiveBuyer = (buyer: IBuyer): void => {
    const taskId = `set-active-buyer-${JSON.stringify(buyer)}-${uuidv4()}`;
    this.store.dispatch(new baseActions.MyMoneySetActiveBuyerAction(taskId, buyer));
  };

  fetchDashboardWidget = (type: WalletDashboardWidgetType, onError?: (error: any) => void, silently = false): void => {
    const taskId = `fetch-dashboard-widget-${type}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyFetchDashboardWidgetRequest(taskId, type, onError),
        'Please wait a moment',
        silently,
      ),
    );
  };

  fetchSettlementAccount = (onSucceed?: (response: IWalletSettlementAccount[]) => void): void => {
    const taskId = `fetch-settlement-account-${uuidv4()}`;
    this.store.dispatch(
      started(new baseActions.MyMoneyFetchSettlementAccountRequestAction(taskId, onSucceed), 'Please wait a moment'),
    );
  };

  fetchSettlementSettings = (onSucceed?: (data: IWalletSettlementSettings) => void): void => {
    const taskId = `fetch-settlement-settings-${uuidv4()}`;
    this.store.dispatch(
      started(new baseActions.MyMoneyFetchSettlementSettingsRequestAction(taskId, onSucceed), 'Please wait a moment'),
    );
  };

  changeSettlementSettings = (
    request: IWalletChangeSettlementSettingsRequest,
    onSucceed?: (response: IWalletSettlementSettings) => void,
    silently?: boolean,
  ): void => {
    const taskId = `change-settlement-settings-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyChangeSettlementSettingsRequestAction(taskId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  addSettlementAccount = (
    settlementAccountRequest: IWalletSettlementAccountRequest,
    onSucceed?: (response: IWalletSettlementAccount) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `addSettlementAccount-${JSON.stringify(settlementAccountRequest)}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyAddSettlementAccountRequestAction(taskId, settlementAccountRequest, onSucceed, onError),
        'Please wait a moment',
      ),
    );
  };

  updateSettlementAccountType = (
    accountId: string,
    request: IWalletSettlementAccountTypeRequest,
    onSucceed?: (response: IWalletSettlementAccount) => void,
  ): void => {
    const taskId = `update-settlement-account-type-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyUpdateSettlementAccountTypeRequestAction(taskId, accountId, request, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  filterPayoutTransactions = (
    storeId: string,
    filter: IFilterPayoutTransactionRequest,
    pageSize = 20,
    pageNumber = 0,
    onSucceed?: (response: SellerPayoutTransactions) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `filter-payout-transactions-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.FilterPayoutTransactionsRequestAction(
          taskId,
          storeId,
          filter,
          pageSize,
          pageNumber,
          onSucceed,
          onError,
        ),
        'Please wait a moment',
      ),
    );
  };

  filterSettlementsExport = (
    filter: IFilterSettlementRequest,
    storeId: string,
    onSucceed?: (response: ISettlementExport) => void,
  ): void => {
    const taskId = `filter-settlements-export-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.FilterSettlementsExportRequestAction(taskId, filter, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  filterTransactionsExport = (
    filter: IFilterPayoutTransactionRequest,
    storeId: string,
    onSucceed?: (response: ISettlementExport) => void,
  ): void => {
    const taskId = `filter-transactions-export-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.FilterTransactionsExportRequestAction(taskId, filter, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  fetchTaskFile = (fetchTaskId: string, onSucceed?: (response: string) => void): void => {
    const taskId = `fetch-task-file-${JSON.stringify(fetchTaskId)}`;
    this.store.dispatch(
      started(new baseActions.FetchTaskFileRequestAction(taskId, fetchTaskId, onSucceed), 'Please wait a moment', true),
    );
  };

  fetchTaskDetail = (fetchTaskId: string, onSucceed?: (response: ITaskDetail) => void): void => {
    const taskId = `fetch-task-detail-${JSON.stringify(fetchTaskId)}`;
    this.store.dispatch(
      started(
        new baseActions.FetchTaskDetailRequestAction(taskId, fetchTaskId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  fetchTaxInvoices = (
    filter: ITaxInvoiceRequest,
    storeId: string,
    pageSize = 20,
    pageNumber = 1,
    onSucceed?: (response: ITaxInvoice) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `fetch-tax-invoices-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.FetchTaxInvoicesRequestAction(
          taskId,
          filter,
          storeId,
          pageSize,
          pageNumber,
          onSucceed,
          onError,
        ),
        'Please wait a moment',
      ),
    );
  };

  downloadTaxInvoice = (taxInvoiceId: string, storeId: string, onSucceed?: (response: any) => void): void => {
    const taskId = `download-tax-invoice-${JSON.stringify(taxInvoiceId)}`;
    this.store.dispatch(
      started(
        new baseActions.DownloadTaxInvoiceRequestAction(taskId, taxInvoiceId, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  fetchSellerStatements = (
    request: ISellerStatementRequest,
    storeId: string,
    pageSize = 20,
    page = 1,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `fetch-seller-statements-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.FetchSellerStatementRequest(taskId, request, storeId, pageSize, page, onError),
        'Fetching Your data. Please wait a moment',
        false,
      ),
    );
  };

  downloadSellerStatement = (statementNumber: string, storeId: string, onSucceed?: (response: any) => void): void => {
    const taskId = `download-seller-statement-${JSON.stringify(statementNumber)}`;
    this.store.dispatch(
      started(
        new baseActions.DownloadSellerStatementRequestAction(taskId, statementNumber, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  generateSellerStatement = (
    filter: ISellerStatementExportRequest,
    storeId: string,
    onSucceed?: (response: ISellerStatementExport) => void,
  ): void => {
    const taskId = `generate-seller-statement-export-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.GenerateSellerStatementRequest(taskId, filter, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  generatePaymentRemittance = (
    storeId: string,
    filter: IGenerateRemittanceRequest,
    onSucceed?: (response: IPaymentRemittanceExport) => void,
  ): void => {
    const taskId = `generate-payment-remittance-export-${JSON.stringify(filter)}`;
    this.store.dispatch(
      started(
        new baseActions.GeneratePaymentRemittanceRequestAction(taskId, filter, storeId, onSucceed),
        'Please wait a moment',
        true,
      ),
    );
  };

  fetchNedbankBanks = (): void => {
    const taskId = `fetchNedbankBanks`;
    this.store.dispatch(started(new baseActions.MyMoneyNedbankBanksRequestAction(taskId), 'Fetching Nedbank banks'));
  };

  payByEft = (flowId: string, onSucceed: () => void, silently = false): void => {
    const taskId = `pay-by-eft-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByEftRequestAction(taskId, flowId, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payByAmex = (
    flowId: string,
    request: IPayByCardRequest,
    onSucceed: (response: IPayByCardResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-amex-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByAmexRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  payByCredit = (
    flowId: string,
    request: IPayByCreditRequest,
    onSucceed: (response: IPayByCreditResponse) => void,
    silently = false,
  ): void => {
    const taskId = `pay-by-credit-${flowId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new baseActions.MyMoneyPayByCreditRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  setPaymentMethod = (paymentMethod: IShoppingAvailablePaymentMethod): void => {
    const taskId = `set-payment-method-${uuidv4()}`;
    this.store.dispatch(new baseActions.MyMoneySetPaymentMethodAction(taskId, paymentMethod));
  };
}
