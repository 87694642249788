<wakanda-avo-business-header></wakanda-avo-business-header>
<!-- Main Content -->
<router-outlet></router-outlet>

<wakanda-avo-business-footer></wakanda-avo-business-footer>

<!-- Register business modal-->
<wakanda-avo-business-register-modal
  *ngIf="isRegisterModalVisible$ | async"
  [modalType]="modalType"
  (onRegister)="onRegister($event)"
  (onClose)="modalService.showRegisterModal($event)"
></wakanda-avo-business-register-modal>

<!-- Contact support modal-->
<wakanda-avo-business-contact-support-modal
  *ngIf="isSupportModalVisible$ | async"
  [modalType]="modalType"
  (formSubmit)="onSendContactEmail($event)"
  (closeModal)="modalService.showSupportModal($event)"
></wakanda-avo-business-contact-support-modal>
