import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiService, TranslateService } from './services';
import { TranslateFacade } from './state/translate.facade';

@NgModule()
export class TranslateStoreModule {
  static forRoot(): ModuleWithProviders<TranslateStoreModule> {
    return {
      ngModule: TranslateStoreModule,
      providers: [ApiService, TranslateService, TranslateFacade],
    };
  }
}
