import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IChatPaymentRequestContent, IMatrixMessage, IMessageEventPaymentRequest } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-payment-request',
  styleUrls: ['./chat-widget-payment-request.component.scss'],
  template: `
    <div class="chat-widget" *ngIf="hasWallet">
      <div class="row between text-align-left">
        <div class="column">
          <b zen-translate>customer.chat.payment_request.description</b>
          <p class="light">{{ message?.content?.description }}</p>
        </div>
        <ui-divider [dotted]="true" [vertical]="true"></ui-divider>
        <div class="column">
          <b zen-translate>customer.chat.payment_request.amount</b>
          <p class="light">{{ message?.content?.amount?.amount | amountTransform: { isLabel: true } }}</p>
        </div>
      </div>
      <ui-divider></ui-divider>
      <div>
        <ui-button
          *ngIf="!isPaid"
          [selectButton]="true"
          [content]="'customer.chat.payment_request.send_payment' | translate"
          (onClick)="sendPayment()"
        >
        </ui-button>
        <ui-button *ngIf="!!isPaid" [selectButton]="false" [content]="'Payment done' | translate" [disabled]="true">
        </ui-button>
      </div>
      <ui-divider [compact]="true"></ui-divider>
    </div>
    <div class="chat-widget padded-content" *ngIf="hasWallet === false">
      <ui-divider [compact]="true"></ui-divider>
      <p class="light" zen-translate>customer.chat.payment_request.create_wallet_message</p>
      <ui-divider [compact]="true"></ui-divider>
      <ui-button
        [selectButton]="true"
        [content]="'customer.chat.payment_request.create_wallet_button' | translate"
        (onClick)="onCreateWallet.emit()"
      >
      </ui-button>
      <ui-divider [compact]="true"></ui-divider>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
  `,
})
export class ChatWidgetPaymentRequestComponent {
  @Input() message: IMatrixMessage<IMessageEventPaymentRequest>;
  @Input() isPaid: boolean;
  @Input() hasWallet: boolean;

  @Output() onPayment = new EventEmitter<IChatPaymentRequestContent>();
  @Output() onCreateWallet = new EventEmitter<void>();

  sendPayment(): void {
    this.onPayment.emit({
      paymentId: this.message?.content?.actionId,
      amount: this.message?.content?.amount,
    });
  }
}
