import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs';
import { TranslateService } from '../services/translate.service';

import * as TranslateActions from './translate.actions';

@Injectable()
export class TranslateEffects {
  fetchTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TranslateActions.fetchTranslationsRequestAction),
      mergeMap(({ name, onSuccess }) =>
        this.translateService.fetchTranslates(name).pipe(
          map((data: any) => {
            if (onSuccess) onSuccess();
            return TranslateActions.fetchTranslationsResponseAction({ data });
          }),
        ),
      ),
    ),
  );

  constructor(private readonly actions$: Actions, private translateService: TranslateService) {}
}
