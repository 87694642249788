import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as TranslateActions from './translate.actions';
import { TranslateEntity } from './translate.models';

export const TRANSLATE_FEATURE_KEY = 'translate';

export interface State extends EntityState<TranslateEntity> {
  data?: any;
}

export interface TranslatePartialState {
  readonly [TRANSLATE_FEATURE_KEY]: State;
}

export const translateAdapter: EntityAdapter<TranslateEntity> = createEntityAdapter<TranslateEntity>();

export const initialState: State = translateAdapter.getInitialState({ data: {} });

const _translateReducer = createReducer(
  initialState,
  on(TranslateActions.fetchTranslationsResponseAction, (state, { data }) => ({
    ...state,
    data: { ...state.data, ...data },
  })),
);

export function translateReducer(state: State | undefined, action: Action) {
  return _translateReducer(state, action);
}
