export enum SharedDatePipeEnum {
  TIME = 'h:mm a',
  TIME24 = 'HH:mm',
  DATE = 'd MMM y',
  LONG_DATE = 'd MMMM y',
  SHORT_DATE_TIME = 'd MMM, h:mm a',
  DATE_TIME = 'd MMM yy, h:mm a',
  LONG_DATE_TIME = 'd MMMM y, h:mm a',
  SHORT_DATE = 'yyyy/MM/dd',
  DAY_MONTH = 'd MMM',
}
