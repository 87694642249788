import { Pipe, PipeTransform } from '@angular/core';
import toString from 'lodash-es/toString';

@Pipe({
  name: 'homeWalletWidget',
})
export class HomeWalletWidgetPipe implements PipeTransform {
  transform(points: number | string): string {
    if (!points && points !== 0 && points !== '0') return '0.00';
    if (points === 0 || points === '0') return '0.00';
    return parseFloat(toString(points)).toFixed(2).toString();
  }
}
