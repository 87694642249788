<ui-container [compact]="true" [noTopPadding]="true">
  <webcam
    [height]="400"
    [width]="400"
    [trigger]="triggerObservable"
    (imageCapture)="handleImage($event)"
    *ngIf="showWebcam"
    [allowCameraSwitch]="allowCameraSwitch"
    [switchCamera]="nextWebcamObservable"
    [videoOptions]="videoOptions"
    (cameraSwitched)="cameraWasSwitched($event)"
    (initError)="handleInitError($event)"
  ></webcam>

  <ui-divider [compact]="true"></ui-divider>

  <div class="bottom-content">
    <ng-container *ngIf="multipleWebcamsAvailable">
      <ui-button [callToActionButton]="true" content="Switch Camera" (onClick)="showNextWebcam(true)"></ui-button>
      <ui-divider [compact]="true"></ui-divider>
    </ng-container>
    <ui-button
      [callToActionButton]="true"
      [selectButton]="true"
      content="Take Picture"
      (onClick)="triggerSnapshot()"
    ></ui-button>
  </div>
</ui-container>

<div *ngIf="isLoading" class="ui-camera-loader-wrapper">
  <div class="dot-bricks-spinner"></div>
  <ui-divider></ui-divider>
  <p>Please wait while your photo is being uploaded</p>
</div>
