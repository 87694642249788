import { TranslateFacade } from '@avo/shared/avo-translate';
import isEmpty from 'lodash-es/isEmpty';
import { of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import {
  AppConfigFacade,
  ApplicationFacade,
  AuthFacade,
  BrowserStorageService,
  CommonUtility,
  StorageKeys,
} from '../..';
import { AppInitService } from '../services/app-init.service';
import { LoggerService } from '../services/logger.service';
import jwt_decode from 'jwt-decode';
import { v4 } from 'uuid';

export function appConfigFactory(
  translateFacade: TranslateFacade,
  appFacade: ApplicationFacade,
  appConfigFacade: AppConfigFacade,
  appInitService: AppInitService,
  storageService: BrowserStorageService,
  loggerService: LoggerService,
  authFacade: AuthFacade,
): () => Promise<any> {
  const setSessionId = (): void => {
    const tempSessionId = storageService.get(StorageKeys.TMP_SESSION_ID, true, null);
    if (tempSessionId) {
      storageService.remove(StorageKeys.TMP_SESSION_ID, true);
    }
    const sessionId = tempSessionId || window['sessionId'] || `PC${CommonUtility.generateRandomString()}`;
    window['sessionId'] = sessionId;
    storageService.set(StorageKeys.SESSION_ID, sessionId);

    // This is requirement from business
    console.log('Session ID: ', sessionId);
  };

  const setCmpId = (): void => {
    const cmpid = new URLSearchParams(window.location.search).get('cmpid') || getCookie('cmpid');
    console.log('Campaign ID: ', cmpid);
    if (cmpid) appFacade.setCmpidValue(cmpid);
  };

  const setCountryId = (): void => {
    const countryCode = storageService.get(StorageKeys.COUNTRY_CODE);
    if (countryCode) {
      appFacade.setCountryCode(countryCode);
    } else {
      appFacade.fetchCountryCode();
    }
  };

  const setDeviceId = (): void => {
    let deviceId = storageService.get(StorageKeys.DEVICE_ID);
    if (!deviceId) {
      deviceId = v4();
      storageService.set(StorageKeys.DEVICE_ID, deviceId);
    }

    return deviceId;
  };

  return (): Promise<any> => {
    return new Promise<void>((resolve: any): any => {
      setDeviceId();
      setSessionId();
      setCmpId();

      loggerService.fetchConfigAndStartDatadog(() => {
        appInitService
          .fetchConfig()
          .pipe(
            take(1),
            map(config => {
              appFacade.checkMaintenance();

              const initCallback = (): void => {
                setCountryId();
                appConfigFacade.fetchAppConfig();
                config.translations?.forEach(translation => {
                  translateFacade.fetchTranslations(translation, () => resolve());
                });
              };

              authFacade.init(initCallback, initCallback);
            }),
            catchError(error => {
              console.error(`App init error: `, error);
              return of(false);
            }),
          )
          .subscribe();
      });
    });
  };
}

export function isJwtTokenValid(token: any): boolean {
  if (!token) return false;

  const tokenData = token['access_token'] || token['accessToken'];
  if (tokenData && !isEmpty(token)) {
    const decodedToken: any = jwt_decode(tokenData);
    const expiration = decodedToken?.exp * 1000;
    const currentTime = new Date().getTime();
    if (currentTime > expiration) {
      console.log('accessToken expired');
      return false;
    }
    return true;
  }
  return false;
}

function getCookie(name: string): string {
  const cookies = {};

  document.cookie.split(';').forEach(cookie => {
    const [key, value] = cookie.split('=');
    cookies[key.trim()] = value;
  });

  return cookies[name] || null;
}
