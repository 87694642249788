import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { v4 } from 'uuid';
import { TaskType } from '../models/store.models';
import * as actions from './app-config.actions';
import * as selectors from './app-config.selectors';

@Injectable({
  providedIn: 'root',
})
export class AppConfigFacade {
  getAppConfig$ = this.store.pipe(select(selectors.getAppConfig));
  getVoucher$ = this.store.pipe(select(selectors.getVoucher));
  getTravel$ = this.store.pipe(select(selectors.getTravel));
  getGeolocationUrl$ = this.store.pipe(select(selectors.getGeolocationUrl));
  getHealthcare$ = this.store.pipe(select(selectors.getHealthcare));
  getCovidUrl$ = this.store.pipe(select(selectors.getCovidUrl));
  getResizableDomains$ = this.store.pipe(select(selectors.getResizableDomains));
  getReferralsEnabled$ = this.store.pipe(select(selectors.getReferralsEnabled));
  getReferralsManual$ = this.store.pipe(select(selectors.getReferralsManual));
  getCampaigns$ = this.store.pipe(select(selectors.getCampaigns));
  getGifting$ = this.store.pipe(select(selectors.getGifting));
  getHeyNedPilot$ = this.store.pipe(select(selectors.getHeyNedPilot));
  getEnableNedbankChannels$ = this.store.pipe(select(selectors.getEnableNedbankChannels));
  getEnableMobimoneyButton$ = this.store.pipe(select(selectors.getEnableMobimoneyButton));
  getEnableMoneyAppButton$ = this.store.pipe(select(selectors.getEnableMoneyAppButton));
  getEnableNedIdButton$ = this.store.pipe(select(selectors.getEnableNedIdButton));
  getEnableMerchantUserManagement$ = this.store.pipe(select(selectors.getEnableMerchantUserManagement));
  getVersionCheckAllowed$ = this.store.pipe(select(selectors.getVersionCheckAllowed));

  constructor(private store: Store) {}

  fetchAppConfig(onSuccess?: () => void, onError?: () => void): void {
    this.store.dispatch(
      actions.fetchAppConfigRequestAction({
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
        onSuccess,
        onError,
      }),
    );
  }
}
