import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum ResponsiveType {
  mobile = 'mobile',
  tablet = 'tablet',
  largeScreen = 'largeScreen',
  fullScreen = 'fullSCreen',
}

@Injectable()
export class ResponsiveService {
  type = ResponsiveType;

  private screenType = new Subject<ResponsiveType>();

  getActiveScreenType = (): Observable<ResponsiveType> => this.screenType.asObservable();

  checkWidth = (): void => {
    const width = window.innerWidth;

    if (width > 1366) {
      this.screenType.next(ResponsiveType.fullScreen);
    }

    if (width > 1024 && width <= 1366) {
      this.screenType.next(ResponsiveType.largeScreen);
    }

    if (width > 768 && width <= 1024) {
      this.screenType.next(ResponsiveType.tablet);
    }

    if (width <= 768) {
      this.screenType.next(ResponsiveType.mobile);
    }
  };
}
