import { Pipe, PipeTransform } from '@angular/core';
import map from 'lodash-es/map';

interface IOptionItem {
  id: string;
  value: string;
}

/**
 * Prepare string options for input multiple dropdown.
 */
@Pipe({
  name: 'stringOptions',
})
export class StringOptionsPipe implements PipeTransform {
  transform(list: string[]): IOptionItem[] {
    return map(list, k => ({ id: k, value: k }));
  }
}
