import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  ILoyaltyFxConvert,
  ILoyaltyFxConvertResponse,
  ILoyaltyFxLinkResponse,
  ILoyaltyFxPartner,
  ILoyaltyFxQuoteRequest,
  ILoyaltyFxQuoteResponse,
} from '../../models/reward.model';
import { started } from '../application/task.actions';
import * as actions from './my-rewards.actions';
import { IMyRewardsPartialState } from './my-rewards.reducer';
import * as selectors from './my-rewards.selectors';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class MyRewardsFacade {
  getCouponDetail$ = this.store.pipe(select(selectors.getCouponDetail));
  getCoupons$ = this.store.pipe(select(selectors.getCoupons));
  getLimitedCoupons$ = this.store.pipe(select(selectors.getLimitedCoupons));
  getPoints$ = this.store.pipe(select(selectors.getPoints));
  getTransactions$ = this.store.pipe(select(selectors.getTransactions));
  getPartners$ = this.store.pipe(select(selectors.getPartners));
  getLinkedPartners$ = this.store.pipe(select(selectors.getLinkedPartners));
  getUnlinkedPartners$ = this.store.pipe(select(selectors.getUnlinkedPartners));
  getConvertRequest$ = this.store.pipe(select(selectors.getConvertRequest));
  getConvertResponse$ = this.store.pipe(select(selectors.getConvertResponse));
  getQuoteResponse$ = this.store.pipe(select(selectors.getQuoteResponse));
  getLinkResponse$ = this.store.pipe(select(selectors.getLinkResponse));
  getBalance$ = this.store.pipe(select(selectors.getBalance));

  constructor(private store: Store<IMyRewardsPartialState>) {}

  fetchRewardTransactions = (walletId: string, silently?: boolean): void => {
    const taskId = `fetching-reward-transactions-${walletId}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.MyRewardsGetTransactionsRequestAction(taskId, walletId), 'Fetching transactions', silently),
    );
  };

  fetchCoupons = (silently?: boolean): void => {
    const taskId = `fetch-coupons-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.MyRewardsGetCouponsRequestAction(taskId), 'Please wait a moment', silently),
    );
  };

  fetchCouponDetail = (couponId: string, silently?: boolean): void => {
    const taskId = `fetch-coupon-detail-${couponId}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.MyRewardsGetCouponsRequestAction(taskId), 'Please wait a moment', silently),
    );
  };

  fetchPoints = (silently?: boolean): void => {
    const taskId = `fetch-points-${uuidv4()}`;
    this.store.dispatch(started(new actions.MyRewardsGetPointsRequestAction(taskId), 'Please wait a moment', silently));
  };

  fetchBalance = (walletId: string, silently?: boolean): void => {
    const taskId = `fetch-balance-${walletId}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.MyRewardsGetBalanceRequestAction(taskId, walletId), 'Please wait a moment', silently),
    );
  };

  fetchPartners = (OnSucceed: (data: ILoyaltyFxPartner[]) => void, silently?: boolean): void => {
    const taskId = `fetch-partners-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.MyRewardsGetLoyaltyFXRequestAction(taskId, OnSucceed), 'Please wait a moment', silently),
    );
  };

  linkPartner = (programId: string, OnSucceed: (data: ILoyaltyFxLinkResponse) => void, silently?: boolean): void => {
    const taskId = `link-partner-${programId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.MyRewardsGetLoyaltyFXLinkRequestAction(taskId, programId, OnSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  unlinkPartner = (programId: string, OnSucceed: () => void, silently?: boolean): void => {
    const taskId = `unlink-partner-${programId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.MyRewardsGetLoyaltyFXUnlinkRequestAction(taskId, programId, OnSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  convertPoints = (
    programId: string,
    payload: ILoyaltyFxConvert,
    OnSucceed: (data: ILoyaltyFxConvertResponse) => void,
    silently?: boolean,
  ): void => {
    const taskId = `convert-points-${JSON.stringify(payload)}-${programId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.MyRewardsGetLoyaltyFXConvertRequestAction(taskId, programId, payload, OnSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };

  quotePoints = (
    programId: string,
    payload: ILoyaltyFxQuoteRequest,
    OnSucceed: (data: ILoyaltyFxQuoteResponse) => void,
    silently?: boolean,
  ): void => {
    const taskId = `quote-points-${JSON.stringify(payload)}-${programId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.MyRewardsGetLoyaltyFXQuoteRequestAction(taskId, programId, payload, OnSucceed),
        'Please wait a moment',
        silently,
      ),
    );
  };
}
