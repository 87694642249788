import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../configs/app.config';
import {
  IClaimElectricityRequest,
  IOrderDetailForPayment,
  IMeterNumberValidateRequest,
  IRecentElectricityClaimResponse,
} from '../../../models/prepaids.model';
import { ApiService } from '../../api/api.service';
import * as endpoints from './prepaid-electricity.endpoints';

@Injectable({
  providedIn: 'root',
})
export class PrepaidElectricityService {
  constructor(private apiService: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiUrl;

  public recent(useMockApi = false): Observable<IRecentElectricityClaimResponse[]> {
    return this.apiService.get<IRecentElectricityClaimResponse[]>(endpoints.recent(this.getApiUrl(useMockApi)));
  }

  public validateMeter(number: IMeterNumberValidateRequest, useMockApi = false): Observable<any> {
    return this.apiService.post<IMeterNumberValidateRequest>(endpoints.validate(this.getApiUrl(useMockApi)), number);
  }

  public placeOrder(data: IClaimElectricityRequest, useMockApi = false): Observable<IOrderDetailForPayment> {
    return this.apiService.post<IClaimElectricityRequest>(endpoints.placeOrder(this.getApiUrl(useMockApi)), data);
  }
}
