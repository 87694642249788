import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IFileUpload, IImage } from '@wakanda/wakanda-core';

@Component({
  selector: 'wakanda-add-images',
  templateUrl: './add-images.component.html',
  styleUrls: ['./add-images.component.scss'],
})
export class AddImagesComponent {
  @Input() title: string;
  @Input() images: IImage[];
  @Input() merchant: boolean;
  @Input() large: boolean;
  @Output() onUpload = new EventEmitter<IFileUpload>();
  @Output() onDelete = new EventEmitter<IImage>();

  onFileSelected = (image: IFileUpload): void => {
    this.onUpload.emit(image);
  };

  onDeleteFile = (image: IImage): void => {
    this.onDelete.emit(image);
  };
}
