import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { AppConfig } from '../init/app.config';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(private appConfig: AppConfig, private http: HttpClient) {}

  fetchConfig(url = 'configuration.json'): Observable<AppConfig> {
    if (Object.keys(this.appConfig).length) {
      return of(this.appConfig);
    }

    return this.http.get<AppConfig>(url).pipe(
      tap(configuration => {
        if (!configuration) throw new Error(`Error fetching configuration.json from ${url}`);

        this.appConfig.name = configuration.name;
        this.appConfig.mfe = configuration.mfe;
        this.appConfig.apiUrl = configuration.apiUrl;
        this.appConfig.apiV2Url = configuration.apiV2Url;
        this.appConfig.apiV3Url = configuration.apiV3Url;
        this.appConfig.apiVersion = configuration.apiVersion;
        this.appConfig.websocketUrl = configuration.websocketUrl;
        this.appConfig.translations = configuration.translations;
        this.appConfig.prepaidElectricitySku = configuration.prepaidElectricitySku;
      }),
      catchError(err => {
        throw new Error(`HTTP Error fetching configuration.json from ${err?.url}`);
      }),
    );
  }
}
