import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ApplicationFacade } from '../store/application/application.facade';
import { environment } from '@avo/environment/customer/environment';

@Injectable()
export class CmpidInterceptor implements HttpInterceptor, OnDestroy {
  private cmpid: string;
  private subscription = new Subscription();

  constructor(private applicationFacade: ApplicationFacade) {
    this.subscription.add(this.applicationFacade.getCmpidValue$.subscribe(val => (this.cmpid = val)));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    if (environment.domainUrl === 'preview-v3.avo.africa' || environment.domainUrl === 'preview.avo.africa') {
      request = request.clone({
        headers: request.headers.set('X-Canary', 'always'),
      });
    }

    if (!request.url.includes('/configuration.json') && this.cmpid)
      request = request.clone({ headers: request.headers.set('cmpid', this.cmpid) });
    else if (!request.url.includes('/configuration.json') && !this.cmpid)
      request = request.clone({ headers: request.headers.set('cmpid', 'organic-web') });
    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
