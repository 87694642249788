<img
  class="item-preview"
  [ngClass]="{ 'read-only': readOnly }"
  [alt]="image?.url"
  [src]="image?.url | secureImage | async"
/>
<ui-icon
  *ngIf="!readOnly"
  icon="ui-delete"
  class="delete-image"
  size="2rem"
  (click)="onDeleteClick.emit(image)"
></ui-icon>
