import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetsBucketsEnum, IAssetStatusResponse } from './assets.models';
import * as AssetsActions from './assets.actions';
import { TaskType } from '../models/store.models';

@Injectable({ providedIn: 'root' })
export class AssetsFacade {
  constructor(private readonly store: Store) {}

  upload(
    data: FormData,
    bucket: AssetsBucketsEnum,
    onSuccess: (response: IAssetStatusResponse) => void,
    onError?: () => void,
  ): void {
    this.store.dispatch(
      AssetsActions.uploadAssetRequest({
        data,
        bucket,
        onSuccess,
        onError,
        taskMetadata: { type: TaskType.STARTED },
      }),
    );
  }

  uploadWithProgress(
    data: FormData,
    bucket: AssetsBucketsEnum,
    onSuccess: (response: IAssetStatusResponse) => void,
    onProgress: (percentage: number) => void,
    onError?: () => void,
  ): void {
    this.store.dispatch(
      AssetsActions.uploadAssetProgressRequest({
        data,
        bucket,
        onSuccess,
        onProgress,
        onError,
        taskMetadata: { type: TaskType.STARTED },
      }),
    );
  }
}
