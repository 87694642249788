import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatFacade } from '@wakanda/wakanda-chat';
import { IMatrixMessage, IMessageEventJobInfPayment, IPaymentRequestClick } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-message-inf-payment',
  template: `
    <div class="ui-chat-message-inf-payment" [ngClass]="{ disabled: paymentStatus?.reserved }">
      <p class="light">Reservation Request</p>
      <div class="text-align-left">
        <i>{{ message?.content?.body }}</i>
      </div>

      <ui-divider [compact]="true"></ui-divider>

      <ui-available-balance
        [data]="{ amount: message?.content?.amount, currencyCode: message?.content?.currency || 'ZAR' }"
      ></ui-available-balance>
      <p class="small">{{ message?.content?.merchantName }}</p>

      <ng-container
        *ngIf="!!paymentStatus && !paymentStatus?.reserved && !!paymentStatus?.paymentId; else reservedTemplate"
      >
        <ui-divider [compact]="true"></ui-divider>
        <ui-button
          [selectButton]="true"
          content="{{ 'RESERVE ' + 'R' + message?.content?.amount }}"
          (onClick)="handleReserve(message?.content?.jobId, message?.content?.quoteId, paymentStatus?.paymentId)"
        ></ui-button>
      </ng-container>

      <ui-divider [compact]="true"></ui-divider>
    </div>

    <ng-container *ngIf="message?.origin_server_ts as date">
      <div *ngIf="!!date" class="ui-chat-message-date incoming">
        <p class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>

    <ng-template #reservedTemplate>
      <p class="small">Payment Reserved</p>
    </ng-template>
  `,
})
export class ChatMessageInfPaymentComponent implements OnInit {
  @Input() message: IMatrixMessage<IMessageEventJobInfPayment>;
  @Output() onReserve = new EventEmitter<IPaymentRequestClick>();
  //
  paymentStatus: any;

  constructor(private chatFacade: ChatFacade) {}

  onSuccessStatus = (data: any): void => {
    if (!data) return;
    this.paymentStatus = data;
  };

  handleReserve = (jobId: string, quoteId: string, paymentId: string): void => {
    const request: IPaymentRequestClick = {
      jobId,
      paymentId,
      quoteId,
    };

    this.onReserve.emit(request);
  };

  ngOnInit(): void {
    if (!this.message) return;
    const { jobId, quoteId } = this.message.content;
    this.chatFacade.fetchInfPaymentStatus(jobId, quoteId, this.onSuccessStatus);
  }
}
