import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IMatrixMessage,
  IMessageEventButtonPressed,
  IMessageEventLocationSet,
  IMessageEventUploadDocuments,
  IPaymentRequestClick,
  IChatPaymentRequestContent,
  MessageEventType,
  IChatRatingRequested,
} from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widgets',
  template: `
    <ng-container [ngSwitch]="message.content.msgtype">
      <ng-container *ngSwitchCase="MessageEventType.JOB_INF_PAYMENT">
        <ui-chat-message-inf-payment
          [message]="message"
          (onReserve)="onReserve.emit($event)"
        ></ui-chat-message-inf-payment>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.ENDCHAT">
        <ui-chat-end-message></ui-chat-end-message>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.BUTTONS">
        <ui-chat-widget-button
          [message]="message"
          (buttonSelect)="buttonSelect.emit($event)"
          [heyNedTheme]="heyNedTheme"
        ></ui-chat-widget-button>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.UPLOAD">
        <ui-chat-widget-upload
          [message]="message"
          (uploadResult)="uploadFiles.emit($event)"
          [heyNedTheme]="heyNedTheme"
        ></ui-chat-widget-upload>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.LOCATION">
        <ui-chat-widget-location
          [message]="message"
          (locationSet)="locationSet.emit($event)"
          (setNewLocation)="setNewLocation.emit($event)"
          [heyNedTheme]="heyNedTheme"
        ></ui-chat-widget-location>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.LOCATION_SET">
        <ui-chat-widget-location-set [message]="message"></ui-chat-widget-location-set>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.JOB_CARD">
        <ui-chat-widget-job-card [message]="message"></ui-chat-widget-job-card>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.MERCHANTS">
        <ui-chat-widget-merchants [message]="message" [heyNedTheme]="heyNedTheme"></ui-chat-widget-merchants>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.SHOW_JOB_CARD">
        <ui-chat-widget-show-job-card
          [message]="message"
          (onJobDetail)="onJobDetail.emit($event)"
        ></ui-chat-widget-show-job-card>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.PLATFORM_MESSAGE">
        <ui-chat-widget-platform-message [message]="message"></ui-chat-widget-platform-message>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.PAYMENT_REQUEST">
        <ui-chat-widget-payment-request
          [hasWallet]="hasWallet"
          [message]="message"
          [isPaid]="messages | isPaymentDone: message"
          (onPayment)="onPayment.emit($event)"
          (onCreateWallet)="onCreateWallet.emit($event)"
        ></ui-chat-widget-payment-request>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.MAP">
        <ui-chat-widget-map [message]="message" [heyNedTheme]="heyNedTheme"></ui-chat-widget-map>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.RATING_REQUEST">
        <ui-chat-widget-rating-request
          [message]="message"
          (onRating)="onRating.emit($event)"
        ></ui-chat-widget-rating-request>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.RATING">
        <ui-chat-widget-rating [message]="message" [heyNedTheme]="heyNedTheme"></ui-chat-widget-rating>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.DOCUMENT">
        <ui-chat-widget-document [message]="message"></ui-chat-widget-document>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.AUDIO">
        <ui-chat-widget-audio [message]="message"></ui-chat-widget-audio>
      </ng-container>
      <ng-container *ngSwitchCase="MessageEventType.PRODUCTS">
        <ui-chat-widget-products
          [message]="message"
          (onProductClick)="onProductClick.emit($event)"
        ></ui-chat-widget-products>
      </ng-container>
      <!--<ng-container *ngSwitchCase="MessageEventType.PAYMENT_RESERVATION_REQUEST">
        TODO
      </ng-container> -->
    </ng-container>
  `,
})
export class ChatWidgetsComponent {
  @Input() message: IMatrixMessage;
  @Input() messages: IMatrixMessage[];
  @Input() heyNedTheme: boolean;
  @Input() hasWallet: boolean;

  @Output() onReserve = new EventEmitter<IPaymentRequestClick>();
  @Output() buttonSelect = new EventEmitter<IMessageEventButtonPressed>();
  @Output() uploadFiles = new EventEmitter<IMessageEventUploadDocuments>();
  @Output() locationSet = new EventEmitter<IMessageEventLocationSet>();
  @Output() onPayment = new EventEmitter<IChatPaymentRequestContent>();
  @Output() onRating = new EventEmitter<IChatRatingRequested>();
  @Output() setNewLocation = new EventEmitter<string>();
  @Output() onCreateWallet = new EventEmitter<void>();
  @Output() onProductClick = new EventEmitter<string>();
  @Output() onJobDetail = new EventEmitter<string>();

  MessageEventType = MessageEventType;
}
