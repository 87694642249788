import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'getHighlightedText', pure: true })
export class GetHighlightedTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  private getText = (text: string, value: string, additionalClass: string): string => {
    if (!text) return '';
    if (!value || value === '') return text;
    return text.replace(
      new RegExp(value, 'gi'),
      '<span class="searched-text' + (additionalClass ? ' ' + additionalClass : '') + '">$&</span>',
    );
  };
  transform = (text: string, value: string, additionalClass?: string): SafeHtml =>
    this.sanitizer.bypassSecurityTrustHtml(this.getText(text, value, additionalClass));
}
