<div
  *ngIf="!!type ? !!type : true"
  class="ui-card"
  [attr.id]="getImageName(imageSrc) || '' | hashString: hashType.DEFAULT"
  [ngClass]="{
    basic: !type && !info,
    info: !!info,
    active: active === true,
    inactive: active === false,
    compact: !!compact,
    'product-horizontal-big': type === 'PRODUCT_HORIZONTAL_BIG',
    'product-horizontal-small': type === 'PRODUCT_HORIZONTAL_SMALL',
    'blog-horizontal': type === 'BLOG_HORIZONTAL',
    'brand-horizontal': type === 'BRAND_HORIZONTAL',
    'filter-vertical': type === 'FILTER_VERTICAL',
    clickable: !!clickable
  }"
>
  <!-- title -->
  <div *ngIf="!!info && !!title" class="title-content" (click)="onCard.emit($event)">
    <b class="small">{{ title }}</b>
  </div>
  <!-- title -->

  <!-- content -->
  <div class="basic-content" *ngIf="!type" (click)="onCard.emit($event)">
    <ng-content></ng-content>
  </div>

  <div class="content" *ngIf="!!type && type !== 'FILTER_VERTICAL'" (click)="onCard.emit($event)">
    <div class="discount-flag" *ngIf="!!discount && discount > 0">
      <div class="discount-flag-content">
        <b>{{ discount }}%</b>
        <p class="small">OFF</p>
      </div>
      <div class="discount-corners">
        <div class="discount-corner left"></div>
        <div class="discount-corner right"></div>
      </div>
    </div>
    <img [alt]="imageSrc" [src]="imageSrc" />
  </div>

  <div class="content" *ngIf="!!type && type === 'FILTER_VERTICAL' && name" (click)="onCard.emit($event)">
    <img [alt]="imageSrc" [src]="imageSrc" />
    <h2>{{ name }}</h2>
  </div>

  <!-- product | brand description -->
  <div class="description" *ngIf="!!type && type !== 'FILTER_VERTICAL'">
    <div class="with-favourite" *ngIf="favourite !== undefined">
      <p *ngIf="!!name">{{ name }}</p>
      <ui-icon [icon]="!!favourite ? 'heart1_fill' : 'heart_1'" size=".938rem" (click)="onFavourite.emit()"></ui-icon>
    </div>
    <p *ngIf="!!name && favourite === undefined">{{ name }}</p>
    <div class="price" *ngIf="!!price || !!priceBefore">
      <ui-available-balance *ngIf="!!price" [data]="price" renderAs="h3"></ui-available-balance>
      <ui-available-balance
        *ngIf="!!priceBefore && priceBefore.amount > price.amount"
        [data]="priceBefore"
        [isCrossed]="true"
        renderAs="h3"
      ></ui-available-balance>
    </div>
    <h3 *ngIf="!!description">{{ description }}</h3>
  </div>

  <!-- footer -->
  <div *ngIf="!!footer" class="footer">
    <i>{{ footer }}</i>
  </div>
</div>
