<div class="container submerged green">
  <div class="page-container">
    <div class="row">
      <div class="basis-50">
        <img [src]="'assets/images/delivery_by_avo.png'" alt="Delivery by Avo illustration" />
      </div>

      <div class="basis-50 text-align-left">
        <h1 zen-translate>merchant.pre_onboarding_product_offering.avo_delivery</h1>
        <ui-divider [compact]="true"></ui-divider>

        <p class="light" zen-translate>merchant.pre_onboarding_product_offering.confrim_order_text</p>
        <ui-divider [compact]="true"></ui-divider>
        <p class="light" zen-translate>merchant.pre_onboarding_product_offering.return_text</p>

        <ui-divider></ui-divider>

        <h1 zen-translate>merchant.pre_onboarding_product_offering.avo_lounge</h1>
        <ui-divider [compact]="true"></ui-divider>

        <p class="light" zen-translate>merchant.pre_onboarding_product_offering.avo_lounge_text</p>
      </div>
    </div>
  </div>
</div>
