import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UPGRADE_WALLET_FEATURE_KEY, UpgradeWalletState } from './upgrade-wallet.reducer';

const upgradeWalletState = createFeatureSelector<UpgradeWalletState>(UPGRADE_WALLET_FEATURE_KEY);

const getNextStep = createSelector(upgradeWalletState, ({ nextStep }) => nextStep);

export const upgradeWalletQuery = {
  getNextStep,
};
