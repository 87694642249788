import { Component, Input } from '@angular/core';
import { IFaqQuestion } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-avo-business-faq-questions',
  templateUrl: './faq-questions.component.html',
  styleUrls: ['./faq-questions.component.scss'],
})
export class FaqQuestionsComponent {
  @Input() questions: IFaqQuestion[];
}
