import { ErrorUtility } from '@wakanda/wakanda-core';

export const parseCategories = (breadcrumbs: string[]): string => {
  if (!breadcrumbs) return '';
  return breadcrumbs
    .slice(0, 5)
    .map(c => c.replace(/[\s,]/g, '-'))
    .map((c, i, all) => all.slice(0, i + 1).join('_'))
    .join(',');
};

export const parseApiResponse = (apiResponse: any): string => {
  if (apiResponse === 'Success') return 'Success';
  return ErrorUtility.getErrorMessageFromApiResponse(apiResponse) || 'Error';
};
