import { Injectable } from '@angular/core';

export interface IShare {
  title: string;
  text?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  webNavigator: any = null;
  constructor() {
    this.webNavigator = window.navigator;
  }

  share({ title, text, url }: IShare): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (this.webNavigator !== null && this.webNavigator.share !== undefined) {
        if ((text === undefined || text === null) && (url === undefined || url === null)) {
          console.warn(`text and url both can't be empty, at least provide either text or url`);
        } else {
          try {
            await this.webNavigator.share({
              title,
              text,
              url,
            });
            resolve({
              shared: true,
            });
          } catch (error) {
            reject({
              shared: false,
              error,
            });
          }
        }
      } else {
        reject({
          shared: false,
          error: `This service/api is not supported in your Browser`,
        });
      }
    });
  }
}
