import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as endpoints from './activity-history.endpoints';
import { ApiService } from '../api/api.service';
import { AppConfig } from '../../configs/app.config';
import {
  ActivityHistoryType,
  IActivityHistoryOrderDetail,
  IActivityHistoryCancelReasonsResponse,
  IActivityHistoryCancelRequest,
  IActivityHistoryRatingRequest,
  IOrderDetailRequest,
} from '../../models/activity-history.model';
import { IRmaCancelReason, IRmaDetail, IRmaRequest, RmaType } from '../../models/rma.model';

@Injectable({
  providedIn: 'root',
})
export class ActivityHistoryService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiV3Url;

  public fetchActivityHistoryOngoing(
    page?: number,
    size?: number,
    useMockApi = false,
  ): Observable<IActivityHistoryOrderDetail[]> {
    return this.api.get<IActivityHistoryOrderDetail[]>(
      endpoints.activityHistoryOngoing(this.getApiUrl(useMockApi), page, size),
    );
  }

  public fetchActivityHistoryCompleted(
    page?: number,
    size?: number,
    useMockApi = false,
  ): Observable<IActivityHistoryOrderDetail[]> {
    return this.api.get<IActivityHistoryOrderDetail[]>(
      endpoints.activityHistoryCompleted(this.getApiUrl(useMockApi), page, size),
    );
  }

  public fetchActivityHistorySearch(query: string, useMockApi = false): Observable<IActivityHistoryOrderDetail[]> {
    return this.api.get<IActivityHistoryOrderDetail[]>(
      endpoints.fetchActivityHistorySearch(this.getApiUrl(useMockApi), query),
    );
  }

  public fetchActivityHistoryDetail(
    request: IOrderDetailRequest,
    useMockApi = false,
  ): Observable<IActivityHistoryOrderDetail> {
    return this.api.get<IActivityHistoryOrderDetail>(
      endpoints.activityHistoryDetail(this.getApiUrl(useMockApi), request.orderId),
    );
  }

  public postRating(
    request: IActivityHistoryRatingRequest,
    orderId: string,
    fulfillmentId: string,
    useMockApi = false,
  ): Observable<any> {
    return this.api.post<IActivityHistoryRatingRequest>(
      endpoints.activityHistoryPostRating(this.getApiUrl(useMockApi), orderId, fulfillmentId),
      request,
    );
  }

  public fetchCancelReasons(
    orderType: ActivityHistoryType,
    useMockApi = false,
  ): Observable<IActivityHistoryCancelReasonsResponse> {
    return this.api.get<IActivityHistoryCancelReasonsResponse>(
      endpoints.activityHistoryCancelReasons(this.getApiUrl(useMockApi), orderType),
    );
  }

  public fetchRmaCancelReasons(
    orderId: string,
    fulfilmentId: string,
    useMockApi = false,
  ): Observable<IRmaCancelReason[]> {
    return this.api.get<IRmaCancelReason[]>(
      endpoints.rmaCancelReasons(this.getApiUrl(useMockApi), orderId, fulfilmentId),
    );
  }

  public cancelOrder(request: IActivityHistoryCancelRequest, orderId: string, useMockApi = false): Observable<any> {
    return this.api.post<IActivityHistoryCancelRequest>(
      endpoints.activityHistoryCancelOrder(this.getApiUrl(useMockApi), orderId),
      request,
    );
  }

  public rma(
    orderId: string,
    fulfilmentId: string,
    type: RmaType,
    request: IRmaRequest,
    useMockApi = false,
  ): Observable<any> {
    return this.api.post<IRmaRequest>(endpoints.rma(this.getApiUrl(useMockApi), orderId, fulfilmentId, type), request);
  }

  public releasePayment(orderId: string, fulfilmentId: string, useMockApi = false): Observable<void> {
    return this.api.post<void>(
      endpoints.activityHistoryReleasePayment(this.getApiUrl(useMockApi), orderId, fulfilmentId),
    );
  }

  public fetchRmaDetail(orderId: string, fulfilmentId: string, rmaId: string): Observable<IRmaDetail> {
    return this.api.get(endpoints.fetchRmaDetail(this.getApiUrl(false), orderId, fulfilmentId, rmaId));
  }

  public fetchRmaList(activeOnly: boolean, pageSize: number, next: number): Observable<IRmaDetail[]> {
    return this.api.get(endpoints.fetchRmaList(this.getApiUrl(false), { activeOnly, pageSize, next }));
  }
}
