import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICommonOnChangeOutput } from '@wakanda/wakanda-core';
import debounce from 'lodash-es/debounce';

@Component({
  selector: 'ui-make-search-select',
  template: `
    <ui-select
      [ngClass]="{ 'padded-content': padded }"
      [autofocus]="autofocus"
      [bindValue]="bindValue"
      [bindLabel]="bindLabel"
      [name]="name"
      [placeholder]="placeholder"
      [value]="value"
      [isOpen]="!!value"
      [forceClose]="forceClose"
      [options]="options"
      (onClear)="onClear.emit($event)"
      (onSelect)="onSelectItem($event)"
      (onEnter)="onSelectItem($event)"
      (onChange)="onSelectChange($event)"
    ></ui-select>
  `,
})
export class MakeSearchSelectComponent {
  @Input() padded: boolean;
  @Input() placeholder: string;
  @Input() useDebounce = true;
  //
  @Input() bindValue: string;
  @Input() bindLabel: string;
  //
  @Input() name: string;
  @Input() value: string;
  @Input() options: any;
  //
  @Input() loading: boolean;
  @Input() showOnRemoveButton: boolean;
  @Input() forceClose: boolean;
  @Input() autofocus = true;
  //
  @Output() onChange = new EventEmitter<ICommonOnChangeOutput>();
  @Output() onSelect = new EventEmitter<ICommonOnChangeOutput>();
  @Output() onClear = new EventEmitter();

  onSelectChangeDebounced = debounce(value => this.onChange.emit(value), 500, { maxWait: 4000 });

  onSelectChange = (event: any): void => {
    if (this.useDebounce) {
      this.onSelectChangeDebounced(event);
    } else {
      this.onChange.emit(event);
    }
  };

  onSelectItem = ({ value, name, item }): void => {
    if (!value) return;
    this.onSelect.emit({ value, item, name });
  };
}
