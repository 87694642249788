import { Pipe, PipeTransform } from '@angular/core';
import { CommonUtility } from '../utils/common-utility';

@Pipe({
  name: 'linkFormat',
})
export class LinkFormatPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    return CommonUtility.format(value, ...args);
  }
}
