import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { TRANSLATE_FEATURE_KEY, State } from './translate.reducer';
// force update 1
const countryCode = JSON.parse(window.localStorage.getItem('countryCode'));

// Lookup the 'Translate' feature state managed by NgRx
export const getTranslateState = createFeatureSelector<State>(TRANSLATE_FEATURE_KEY);

export const getTranslates = createSelector(getTranslateState, (state: State) => state.data);

export const getTranslateByKey = (
  translateKey: string,
  defaultTranslate?: string,
): MemoizedSelector<object, any, DefaultProjectorFn<any>> =>
  createSelector(getTranslateState, state => {
    const countryTranslateKey = countryCode === 'NA' ? translateKey + '.na' : translateKey;
    if (state.data[countryTranslateKey]) return state.data[countryTranslateKey];
    if (!state.data[translateKey]) return defaultTranslate ?? translateKey;
    return state.data[translateKey];
  });
