import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePipeEnum, IImage } from '@wakanda/wakanda-core';
import first from 'lodash-es/first';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'wakanda-image-gallery',
  template: `
    <ng-container *ngIf="images?.length > 0">
      <div class="profile gallery" [ngClass]="{ smallThumbnails: !!smallThumbnails }">
        <img
          class="gallery-item"
          *ngFor="let img of images"
          [alt]="img?.id"
          [src]="img?.url | secureImage | async"
          (click)="onImage(img)"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="imagesUrls?.length > 0">
      <div class="profile gallery" [ngClass]="{ smallThumbnails: !!smallThumbnails }">
        <img class="gallery-item" *ngFor="let img of imagesUrls" [alt]="img" [src]="img" (click)="onImage(img)" />
      </div>
    </ng-container>

    <ui-modal
      [modal]="ModalType.SLIDE_IN_UP"
      [template]="imageDetail"
      [closeButton]="true"
      [isVisible]="!!selectedImage"
      (onCloseClick)="onImage(null)"
    >
      <ng-template #imageDetail>
        <div class="gallery-modal-detail-wrapper">
          <ng-container *ngIf="images?.length > 0">
            <h3 *ngIf="!!selectedImage?.Title">{{ selectedImage?.Title }}</h3>
            <div class="image-wrapper" *ngIf="!!selectedImage?.url">
              <img
                class="detail-item"
                *ngIf="isSecure"
                [alt]="selectedImage?.id"
                [src]="selectedImage?.url | secureImage | async"
              />
              <img class="detail-item" *ngIf="!isSecure" [alt]="selectedImage?.id" [src]="selectedImage?.url" />
              <ui-icon
                *ngIf="!hideRemoveButton"
                [icon]="merchant ? 'cross_circle' : 'delete'"
                size="2.5rem"
                (click)="onRemoveImage(selectedImage?.id)"
              ></ui-icon>
            </div>
            <p *ngIf="!!selectedImage?.date" class="small light">
              Added on {{ selectedImage?.date | date: DatePipeEnum.SHORT_DATE }}
            </p>
          </ng-container>

          <ng-container *ngIf="imagesUrls?.length > 0">
            <div class="image-wrapper" *ngIf="!!selectedImage">
              <img
                class="detail-item"
                *ngIf="isSecure"
                [alt]="selectedImage"
                [src]="selectedImage | secureImage | async"
              />
              <img class="detail-item" *ngIf="!isSecure" [alt]="selectedImage" [src]="selectedImage" />
              <ui-icon
                *ngIf="!hideRemoveButton"
                [icon]="merchant ? 'cross_circle' : 'delete'"
                size="2.5rem"
                (click)="onRemoveImage(selectedImage)"
              ></ui-icon>
            </div>
          </ng-container>

          <ui-divider [compact]="true"></ui-divider>

          <ng-container *ngIf="images?.length > 0">
            <div class="galery-overview">
              <img
                class="gallery-overview-item"
                *ngFor="let img of images"
                [alt]="img?.id"
                [src]="img?.url"
                (click)="onImage(img)"
              />
            </div>
          </ng-container>

          <ng-container *ngIf="imagesUrls?.length > 0">
            <div class="galery-overview">
              <img
                class="gallery-overview-item"
                *ngFor="let img of imagesUrls"
                [alt]="img"
                [src]="img"
                (click)="onImage(img)"
              />
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ui-modal>
  `,
})
export class ImageGalleryComponent {
  selectedImage: IImage;
  @Input() merchant: boolean;
  @Input() smallThumbnails: boolean;
  @Input() images: IImage[];
  @Input() imagesUrls: string[];
  @Input() hideRemoveButton: boolean;
  @Input() isSecure = false;
  @Output() onRemove = new EventEmitter<string>();
  @Output() onClose = new EventEmitter();
  ModalType = ModalType;
  DatePipeEnum = DatePipeEnum;

  @Input()
  public set open(open: boolean) {
    if (open) {
      this.onImage(this.images[0]);
    }
  }

  onImage = (image: IImage): void => {
    if (image === null) {
      this.onClose.emit();
    }
    this.selectedImage = image;
  };

  onRemoveImage = (id: string): void => {
    this.selectedImage = first(this.images);
    this.onRemove.emit(id);
  };
}
