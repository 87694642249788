import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiCardLinkComponent } from './ui-card-link.component';
import { UiIconModule } from '../icon';
import { UiSpinnerModule } from '../spinner';

@NgModule({
  declarations: [UiCardLinkComponent],
  exports: [UiCardLinkComponent],
  imports: [CommonModule, UiIconModule, UiSpinnerModule],
})
export class UiCardLinkModule {}
