import { Pipe, PipeTransform } from '@angular/core';
import { CustomValidators } from '../validators/custom-validators';

@Pipe({ name: 'maskEmail', pure: false })
export class MaskEmailPipe implements PipeTransform {
  transform(value: string): string {
    const emailReg: RegExp = CustomValidators.valalidators.email;
    const emailValid = emailReg.test(value);
    if (emailValid) {
      const slitted = value.split('@');
      let part1 = slitted[0];
      const avg = part1.length / 2;
      part1 = part1.substring(0, part1.length - avg);
      const part2 = slitted[1];
      return part1 + '***@' + part2;
    }
    return value;
  }
}
