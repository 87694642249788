import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IInfoStepupPrep } from '../../models/application.model';
import {
  CLEAN,
  FAIL,
  FINALIZE,
  HTTP_ERROR,
  prefix,
  PUSH_NOTIFICATION,
  SET_INFO_STEPUP_PREP,
  SET_SCROLL_POSITION,
  SILENT_START,
  STARTED,
  SUCCESS,
} from './task.events';

export interface ITaskAction extends Action {
  taskId?: string;
  error?: HttpErrorResponse;
  text?: string;
  hidden?: boolean;
  unauthorized?: boolean;
}

export const started = (action: ITaskAction, text?: string, silently?: boolean): any =>
  Object.assign(
    {},
    {
      [prefix]: {
        type: silently ? SILENT_START : STARTED,
      },
    },
    { text },
    action,
  );

export const succeed = (action: ITaskAction): any =>
  Object.assign(
    {},
    {
      [prefix]: {
        type: SUCCESS,
      },
    },
    action,
  );

export const failed = (action: Action, requestParams?: any): any =>
  Object.assign(
    {},
    {
      [prefix]: {
        type: FAIL,
      },
    },
    action,
    requestParams,
  );

export const pushNotification = (action: ITaskAction, text: string): any =>
  Object.assign(
    {},
    {
      [prefix]: {
        type: PUSH_NOTIFICATION,
      },
    },
    { text },
    action,
  );

export const clean = (action: ITaskAction): any =>
  Object.assign(
    {},
    {
      [prefix]: {
        type: CLEAN,
      },
    },
    action,
  );

export const finalize = (action: ITaskAction): any =>
  Object.assign(
    {},
    {
      [prefix]: {
        type: FINALIZE,
      },
    },
    action,
  );

export class SetInfoStepupPrepAction implements ITaskAction {
  readonly type = SET_INFO_STEPUP_PREP;
  constructor(public taskId: string, public data: IInfoStepupPrep) {}
}

/**
 * General error action for ordinary usage in project. It handles tasks in case of HTTP error.
 *
 * **IMPORTANT**:
 * Create custom error action if you need to react to some errors differently.
 * Do not use general HttpErrorAction in reducers, because it is triggered by all catchErrors in project.
 * Create new action which extends HttpErrorAction and use it in your reducer.
 */
export class HttpErrorAction implements Action {
  type = HTTP_ERROR;
  isHttpError = true;

  constructor(public taskId: string, public error: HttpErrorResponse, public hidden?: boolean) {}
}

export class SetScrollPositionAction implements ITaskAction {
  readonly type = SET_SCROLL_POSITION;
  constructor(public taskId: string, public data: number) {}
}
