import { IZenAuthNextStepTransactionSigningResponse } from './responses.model';

type IAuthToken =
  | IZenAuthUsernamePasswordToken
  | IZenAuthUsernameToken
  | IZenAuthPasswordToken
  | IZenAuthSmsOtpToken
  | IZenAuthResendSmsOtpToken;

export interface IZenAuthAuthenticateRequest {
  authToken: IAuthToken;
  temporaryAccessToken: string;
  onSucceeded?: (nextStep: any) => void;
  onError?: (error: any) => void;
  onLogin?: () => void;
}

export interface IZenAuthStartRequest {
  clientId: string;
  responseType: string;
  scope: string;
  redirectUri?: string;
  state?: string;
}

export interface IZenAuthIdPassportNumberRequest {
  onboardingId: string;
  govId: string;
  passportNumber: string;
  passportCountryCode: string;
}

export interface IZenAuthLegalEntityRequest {
  onboardingId: string;
  govId?: string;
  passportNumber?: string;
  passportCountryCode?: string;
  businessRegistrationNumber?: string;
  tradeInRestrictedCountriesChecked: boolean;
  notUnderAdministrationChecked: boolean;
  soleOwnerChecked: boolean;
}

export interface IZenAuthBusinessLegalEntityRequest {
  onboardingId: string;
  businessRegistrationNumber?: string;
  businessTradingName?: string;
  tradeInRestrictedCountriesChecked: boolean;
  notUnderAdministrationChecked: boolean;
  soleOwnerChecked: boolean;
}

export interface IZenAuthBasicDataRequest {
  onboardingId: string;
  displayName?: string;
  phoneNumber?: string;
  email?: string;
  termsConditionsChecked?: boolean;
  marketingChecked?: boolean;
}

export interface IZenAuthBusinessBasicDataRequest {
  onboardingId: string;
  displayName?: string;
  phoneNumber?: string;
  email?: string;
  termsConditionsChecked?: boolean;
  marketingChecked?: boolean;
}

export interface IZenAuthUsernameToken {
  username: IZentAuthUsername;
}

export interface IZenAuthPasswordToken {
  password: IZenAuthPassword;
}

export interface IZenAuthLoginToken {
  username: IZentAuthUsernamePassword;
}

export interface IZenAuthSmsOtpToken {
  smsOtp: IZenAuthSmsOtp;
}

export interface IZentAuthUsername {
  username: string;
}

export interface IZenAuthPassword {
  password: string;
}

export interface IZentAuthUsernamePassword {
  username: string;
  password: string;
}

export interface IZenAuthSmsOtp {
  otp: string;
}

export interface IZenAuthResendSmsOtpToken {
  smsOtp: IZenAuthResendSmsOtp;
}
export interface IZenAuthResendSmsOtp {
  resend: boolean;
}

export interface IZenAuthUsernamePasswordToken {
  usernamePasswordToken: IZenAuthUsernamePassword;
}

export interface IZenAuthUsernamePassword {
  username: string;
  password: string;
  secretPayload?: string;
}

export interface IZenAuthAutenticateRequest {
  authToken:
    | IZenAuthUsernamePasswordToken
    | IZenAuthUsernameToken
    | IZenAuthPasswordToken
    | IZenAuthSmsOtpToken
    | IZenAuthResendSmsOtpToken;
  temporaryAccessToken: string;
}

export interface IZenAuthOnboardingStartRequest {
  email: string;
  govId: string;
  clientId?: string;
  phoneNumber?: string;
  businessRegistrationNumber?: string;
  termsConditionsChecked: boolean;
  tradeInRestrictedCountriesChecked: boolean;
  notUnderAdministrationChecked: boolean;
  soleOwnerChecked: boolean;
  personalInfoChecked?: boolean;
  marketingChecked: boolean;
  splitOnboarding?: boolean;
  referralCode?: string;
}

export interface IZenAuthOnboardingStartInfoRequest {
  onboardingId: string;
  email: string;
  termsConditionsChecked: boolean;
  marketingChecked: boolean;
  tradeInRestrictedCountriesChecked: boolean;
  notUnderAdministrationChecked: boolean;
  soleOwnerChecked: boolean;
}

export interface IZenAuthOnboardingStartMoneyAppRequest {
  code: string;
  clientId?: string;
  termsConditionsChecked?: boolean;
  marketingChecked?: boolean;
  async?: boolean;
  channelId?: string;
}

export interface IZenAuthOnboardingStartSocialRequest {
  code: string;
  type: any;
  clientId?: string;
}

export interface IZenAuthOnboardingStartManualRequest {
  clientId?: string;
  referralCode?: string;
}

export enum SocialNetworkType {
  APPLE = 'APPLE',
  FB = 'FB',
  GOOGLE = 'GOOGLE',
}

export interface IZenAuthOnboardingOtpRequest {
  onboardingId: string;
  otp?: string;
  resend?: boolean;
}

export interface IZenAuthOnboardingExternalAuthRequest {
  onboardingId: string;
  code?: string;
  error?: string;
  errorDescription?: string;
  callbackURL?: string;
}

export interface IZenAuthOnboardingLinkAccountRequest {
  onboardingId: string;
  accountId: string;
}

export interface IZenAuthOnboardingStartLinkAccountRequest {
  onboardingId: string;
}

export interface IZenAuthOnboardingLoginRequest {
  onboardingId: string;
  username: string;
  password: string;
}

export interface IZenAuthOnboardingPersonalDataRequest {
  onboardingId: string;
  occupation?: string;
  occupationOtherDetail?: string;
  email?: string;
  industry?: string;
  sourceOfIncome?: string;
  fetcaUsCitizen?: boolean;
  fetcaTaxObligation?: boolean;
  countryOfBirth?: string;
}

export interface IZenAuthOnboardingAddressRequest {
  onboardingId: string;
  flowId?: string;
  streetName: string;
  city: string;
  houseNumber: string;
  postalCode: string;
  suburb: string;
}

export interface IZenAuthOnboardingSecurityQuestionsRequest {
  onboardingId: string;
  answers: IZenAuthSecurityQuestionAnswer[];
}

export interface IZenAuthSecurityQuestionAnswer {
  questionId?: string;
  answerId: string;
}

export interface IZenAuthOnboardingFirstNameLastNameAuthRequest {
  onboardingId: string;
  firstName: string;
  lastName: string;
}

export interface IZenAuthOnboardingBusinessTradingNameRequest {
  onboardingId: string;
  tradingName: string;
}

export interface IZenAuthLoginRequest {
  onboardingId: string;
  username?: string;
  password?: string;
  nedbankLoginType?: NedbankLoginType;
}

export enum NedbankLoginType {
  NEDBANK_ID = 'NEDBANK_ID',
  MOBIMONEY = 'MOBIMONEY',
  NEDBANK_CHANNELS = 'NEDBANK_CHANNELS',
}

export interface IZenAuthOnboardingBusinessDetailsRequest {
  onboardingId: string;
  tradingName?: string;
  registeredName?: string;
  businessPhone: string;
  industry: string;
  sourceOfFunds: string;
  natureOfBusiness: string;
  operateOutsideSA: boolean;
  countriesOfOperation: string[];
}

export interface IZenAuthOnboardingBankphoneDifferentRequest {
  onboardingId: string;
}

export interface IZenAuthOnboardingSuccessRequest {
  onboardingId: string;
}

export interface IZenAuthTransactionSigningAuthorizeRequest {
  transactionAuthId: string;
  authRequest: IZenAuthTransactionSigningAuthorizeRequestPayload;
  onSucceed: (data?: IZenAuthTransactionSigningAuthorizationHeaders) => void;
  onData: (data?: IZenAuthNextStepTransactionSigningResponse) => void;
  onFailure: (error?: any) => void;
}

export interface IZenAuthTransactionSigningAuthorizationHeaders {
  signingToken: string;
  transactionAuthId: string;
}

export interface IZenAuthOnboardingBusinessAddress {
  houseNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  postalCode: string;
}

export interface IZenAuthOnboardingBusinessDetailsRequestDocument {
  id: string;
}

export interface IZenAuthOnboardingBusinessPartnerRequest {
  onboardingId: string;
  partner?: string;
}

export interface IZenAuthOnboardingBusinessCategoryInfoRequest {
  onboardingId: string;
  category: string;
  subCategories: string[];
  location: ILocation;
  restrict: number;
}

export interface ILocation {
  latitude: number;
  longitude: number;
}

export interface IZenAuthTransactionSigningAuthorizeRequestPayload {
  pinAuth?: IZenAuthTransactionSigningAppAuth;
  otpAuth?: IZenAuthTransactionSigningOtpAuth;
  deviceAuth?: IZenAuthTransactionSigningAppAuth;
  passwordAuth?: IZenAuthTransactionSigningPasswordAuth;
  tcsAuth?: IZenAuthTransactionSigningTcsAuth;
  approveItAuth?: IZenAuthTransactionSigningApproveitAuth;
}

export interface IZenAuthTransactionSigningAppAuth {
  hmac: string;
  activationId: string;
  keyType: string;
}

export interface IZenAuthTransactionSigningOtpAuth {
  otp?: string;
  resend: boolean;
}

export interface IZenAuthTransactionSigningPasswordAuth {
  password: string;
}

export interface IZenAuthTransactionSigningTcsAuth {
  itemsChecked: IZenAuthTcsItem[];
}

export interface IZenAuthTransactionSigningApproveitAuth {
  requestStatus: boolean;
}

export interface IZenAuthTcsItem {
  id: string;
  confirmed: string;
}

export interface IZenAuthOnboardingApproveItRequest {
  onboardingId: string;
}

export interface IZenAuthOnboardingProgressRequest {
  onboardingId: string;
}

export interface IZenAuthOnboardingSentApproveItRequest {
  onboardingId: string;
}

export interface IZenAuthOnboardingMobiMoneyRequest {
  onboardingId: string;
}

export interface IZenAuthBankOtpRequest {
  onboardingId: string;
  otp?: string;
  resend?: boolean;
}

export interface IZenAuthCheckBankRequest {
  restraurantId?: string;
  storeId?: string;
}

export interface IZenAuthOnboardingIdPhotoRequest {
  onboardingId: string;
  photosBase64: string[];
  type: IZenAuthOnboardingIdPhotoTypeEnum;
}

export enum IZenAuthOnboardingIdPhotoTypeEnum {
  RSA = 'RSA',
  GREENBARCODEID = 'GREENBARCODEID',
}

export interface IZenAuthBusinessAddressRequest {
  onboardingId: string;
  flowId?: string;
  tradingAddress: IZenAuthOnboardingBusinessAddress;
  postalAddress: IZenAuthOnboardingBusinessAddress;
  registeredAddress: IZenAuthOnboardingBusinessAddress;
}

export interface IZenAuthOnboardingReferralDetail {
  senderName: string;
  pointsForOnboardee: number;
}

export interface IZenAuthOnboardingStepupRequest {
  journeyCode?: ZenAuthStepupJourneyType;
}

export enum ZenAuthStepupJourneyType {
  PL_NTP = 'PL_NTP',
  PL_GHOST = 'PL_GHOST',
  HL_PREAPPROVAL = 'HL_PREAPPROVAL',
  HEALTHCARE = 'HEALTHCARE',
}

export interface IZenAuthOnboardingOutstandingTCsRequest {
  onboardingId: string;
  termsConditionsChecked?: boolean;
  marketingChecked?: boolean;
}

export interface IZenAuthOnboardingTCsRequest {
  onboardingId: string;
  termsConditionsChecked?: boolean;
  marketingChecked?: boolean;
}

export interface IZenAuthOnboardingSelectOtpRequest {
  onboardingId: string;
  phoneNumber?: string;
  email?: string;
}

export interface IZenAuthOnboardingSelectSocialRequest {
  onboardingId: string;
  name?: string;
  socialType: SelectSocialType;
  code: string;
}

export interface IZenAuthOnboardingSelectNedbankRequest {
  onboardingId: string;
  nedbankType: string;
}

export enum SelectOTPMethod {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum SelectSocialType {
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
}

export interface IZenAuthOnboardingSignupManualRequest {
  clientId?: string;
  displayName: string;
  email: string;
  newPassword: string;
  userTag?: string;
}

export interface IZenAuthOnboardingSignupRequest {
  clientId?: string;
  userTag?: string;
}

export interface IZenAuthSocialBy {
  nickname: string;
  email: string;
  phoneNumber: string;
  isReservation?: boolean;
}

export interface IZenAuthSocialByRequest extends IZenAuthSocialBy {
  termsConditionsChecked: boolean;
  marketingChecked: boolean;
}

//v3
export interface IAuthPasswordlessLoginStartRequest {
  username: string;
}

export interface IAuthOTPRequest {
  sessionId?: string;
  otp: string;
}

export interface IAuthOTPResendRequest {
  sessionId?: string;
}

export interface IAuthRefreshTokenStartRequest {
  refreshToken: string;
}

export interface IAuthProfileRequest {
  sessionId: string;
  name?: string;
  email?: string;
  phone?: string;
}

export interface IAuthSocialLoginStartRequest {
  socialIdToken: string;
  socialAccessToken: string;
}
