import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-create-wallet-modal',
  template: `
    <ui-modal
      [modal]="ModalTypes.SLIDE_IN_UP"
      [template]="createWalletModalTemplate"
      [closeButton]="true"
      [isVisible]="true"
      (onCloseClick)="onClose.emit()"
    >
      <ng-template #createWalletModalTemplate>
        <div class="padded-content">
          <ui-icon icon="wallet" size="5.5rem"></ui-icon>
          <ui-divider></ui-divider>
          <div class="text-align-center">
            <h2 zen-translate>wallet.card_payment.create_wallet.title</h2>
            <ui-divider [compact]="true"></ui-divider>
            <p class="light small" zen-translate>wallet.card_payment.create_wallet.title_message</p>
          </div>
          <ui-divider></ui-divider>
          <div class="row">
            <ui-icon icon="dollar" size="2rem"></ui-icon>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <p class="text-align-left" zen-translate>wallet.card_payment.create_wallet.message1</p>
          </div>
          <ui-divider [compact]="true"></ui-divider>
          <div class="row">
            <ui-icon icon="wallet_info" size="2rem"></ui-icon>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <p class="text-align-left" zen-translate>wallet.card_payment.create_wallet.message2</p>
          </div>
          <ui-divider [compact]="true"></ui-divider>
          <div class="row">
            <ui-icon icon="wallet3" size="2rem"></ui-icon>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <p class="text-align-left" zen-translate>wallet.card_payment.create_wallet.message3</p>
          </div>
          <ui-divider [compact]="true"></ui-divider>
          <div class="row">
            <ui-icon icon="discount" size="2rem"></ui-icon>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <p class="text-align-left" zen-translate>wallet.card_payment.create_wallet.message4</p>
          </div>
          <ui-divider [compact]="true"></ui-divider>
          <div class="row">
            <ui-icon icon="eshop_payment_r" size="2rem"></ui-icon>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <p class="text-align-left" zen-translate>wallet.card_payment.create_wallet.message5</p>
          </div>
          <ui-divider></ui-divider>
          <ui-button
            [callToActionButton]="true"
            [selectButton]="true"
            aligned="bottom"
            [content]="'wallet.card_payment.create_wallet.create_wallet' | translate"
            (onClick)="onCheckBank.emit()"
          ></ui-button>

          <ui-divider [compact]="true"></ui-divider>
          <a *ngIf="showContinueWithCardButton" (click)="onContinueCreditCard.emit()" class="small" zen-translate
            >wallet.card_payment.create_wallet.continue</a
          >
        </div>
      </ng-template>
    </ui-modal>
  `,
})
export class CreateWalletModalComponent {
  @Input() showContinueWithCardButton: boolean;
  @Output() onCheckBank = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onContinueCreditCard = new EventEmitter();
  ModalTypes = ModalType;
}
