import { createFeatureSelector, createSelector } from '@ngrx/store';
import get from 'lodash-es/get';
import { APP_CONFIG_FEATURE_KEY, AppConfigState } from './app-config.reducer';

const appConfigState = createFeatureSelector<AppConfigState>(APP_CONFIG_FEATURE_KEY);

export const getAppConfig = createSelector(appConfigState, state => get(state, 'appConfig'));

export const getResizableDomains = createSelector(appConfigState, state => get(state, 'appConfig.resizable-domains'));

export const getVoucher = createSelector(appConfigState, state => get(state, 'config.category-id.vouchers'));

export const getTravel = createSelector(appConfigState, state => get(state, 'config.category-id.travel'));

export const getGifting = createSelector(appConfigState, state => get(state, 'config.category-id.gifting'));

export const getHealthcare = createSelector(appConfigState, state => get(state, 'config.category-id.healthcare'));

export const getCovidUrl = createSelector(appConfigState, state => get(state, 'config.covid-site-url'));

export const getGeolocationUrl = createSelector(appConfigState, state => get(state, 'config.geolocation-url'));

export const getReferralsEnabled = createSelector(appConfigState, state => get(state, 'config.referrals-enabled'));

export const getReferralsManual = createSelector(appConfigState, state => get(state, 'config.manual-referral'));

export const getCampaigns = createSelector(appConfigState, state => get(state, 'config.campaigns'));

export const getHeyNedPilot = createSelector(appConfigState, state => get(state, 'config.heyned-pilot-only'));
export const getEnableNedbankChannels = createSelector(appConfigState, state =>
  get(state, 'config.enable-nedbank-channels'),
);

export const getEnableMobimoneyButton = createSelector(appConfigState, state =>
  get(state, 'config.enable-mobimoney-button'),
);
export const getEnableMoneyAppButton = createSelector(appConfigState, state =>
  get(state, 'config.enable-money-app-button'),
);
export const getEnableNedIdButton = createSelector(appConfigState, state => get(state, 'config.enable-ned-id-button'));

export const getEnableMerchantUserManagement = createSelector(appConfigState, state =>
  get(state, 'config.merchant-user-management'),
);

export const getVersionCheckAllowed = createSelector(appConfigState, state =>
  get(state, 'config.version-check-allowed'),
);
