<div class="item html">
  <h2>{{ timeLeft }}</h2>

  <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <g>
      <title>Layer 1</title>
      <circle
        id="circle"
        [style.stroke-dashoffset]="stroke"
        class="circle_animation"
        r="12.5"
        cy="14.5"
        cx="14.5"
        stroke-width="2"
        stroke="#00A74E"
        fill="none"
      />
    </g>
  </svg>
</div>
