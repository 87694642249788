import { IAmount, ICommonImage } from './common.model';
import { ISearchVerticalEnum } from './search.model';

// todo(lukas.jurygacek): rename to start with IDashboardXXXXX !

export interface IHomepageDashboardV2Response {
  widgets: IHomepageWidget[];
  popup?: IHomepagePopup;
  primerId: string;
  categories?: IDashboardCategory[];
}

export interface IDashboardCategory {
  icon: string;
  label: string;
  link: string;
  order: number;
}

export interface IHomepageWidget {
  id: string;
  type: HomepageWidgetTypeV2;
  label?: string;
  order?: number;
  image?: IHomepageImage;
  services?: IHomepageServiceItem[];
  eshopCategory?: IHomepageEshopWidget;
  groceryCategory?: IHomepageEshopWidget;
  sellers?: IHomepageSeller[];
  grocery?: IHomepageGrocery;
  categories?: IHomepageCategoryItem[];
  brands?: IHomepageBrand;
  vouchers?: IHomepageVoucherItem[];
  banner?: IHomepageBannerWidget;
  rewards?: IHomepageRewardsWidget;
  onecart?: IHomepageOnecartWidget;
  grocerySwitchboard?: IHomepageGrocerySwitchboardWidgegt[];
  takeawayMenu?: IHomepageTakeawayMenuWidget;
  smallCarousel?: IHomepageSmallCarouselItem[];
  customWidget?: IHomepageCustomWidget;
  topOffer?: IHomepageTopOfferWidget;
  switchboardOptions?: ISwitchboardOption[];
  questions?: IQuestion[];
  genericCarousel?: IHomepageGenericCarousel;
  groceryV2?: IGroceryV2;
  brandedDashboards?: IHomepageBrandedDashboardWidget[];
  plPortal?: IHomepagePlPortalWidget;
  searchWidget?: ISearchWidget;
  productListData?: IProductListWidget;
  namedSearchData?: INamedSearchWidget;
  contentCardCarouselData?: IContentCardCarouselWidgetData;
  storesListData?: IStoresListWidgetData;
}

export interface IContentCardCarouselWidgetData {
  timeToChange: number;
  cards: IContentCardItem[];
}

export interface IStoresListWidgetData {
  label?: string;
  items: IStoreListWidget[];
}

export interface IStoreListWidget {
  image: ICommonImage;
  storeId: string;
  comingSoon: boolean;
}

export interface IContentCardItem {
  cardImageId: string;
  cardImageURL: string;
  contentCardType?: ContentCardType;
  navigationUrl?: string;
  adsSourceURL?: string;
  adsPlacementParam?: string;
  adsMaxCount?: number;
}

export enum ContentCardType {
  STATIC_CARD = 'STATIC_CARD',
  ADS_CARD = 'ADS_CARD',
}

export interface IHomepageWidgetV3 {
  type: HomepageWidgetTypeV2;
  label?: string;
  order?: number;
  imageData?: IHomepageImageV3;
  services?: IHomepageServiceItem[];
  eshopCategory?: IHomepageEshopWidget;
  groceryCategory?: IHomepageEshopWidget;
  sellers?: IHomepageSeller[];
  grocery?: IHomepageGrocery;
  categories?: IHomepageCategoryItem[];
  brands?: IHomepageBrand;
  vouchers?: IHomepageVoucherItem[];
  banner?: IHomepageBannerWidget;
  rewards?: IHomepageRewardsWidget;
  onecart?: IHomepageOnecartWidget;
  grocerySwitchboard?: IHomepageGrocerySwitchboardWidgegt[];
  takeawayMenu?: IHomepageTakeawayMenuWidget;
  smallCarousel?: IHomepageSmallCarouselItem[];
  customWidget?: IHomepageCustomWidget;
  topOffer?: IHomepageTopOfferWidget;
  switchboardOptions?: ISwitchboardOption[];
  questions?: IQuestion[];
  genericCarousel?: IHomepageGenericCarousel;
  groceryV2?: IGroceryV2;
  brandedDashboards?: IHomepageBrandedDashboardWidget[];
  plPortal?: IHomepagePlPortalWidget;
  searchWidget?: ISearchWidget;
}

export interface IGroceryV2 {
  image: ICommonImage;
}

export interface IHomepageBrandedDashboardWidget {
  featured: boolean;
  id: string;
  logo: ICommonImage;
  banner?: ICommonImage;
  name: string;
}

export interface IHomepageCustomWidget {
  backgroundImage: ICommonImage;
  buttonText: string;
  message: string;
  title: string;
  url: string;
  urlKey: string;
  icon?: ICommonImage;
  tag?: string;
}

export interface IProductListWidget {
  query: string;
  storeId: string;
  category: string;
  vertical: ISearchVerticalEnum;
  tag: string;
  filters: IProductListWidgetFilter[];
}

export interface INamedSearchWidget {
  filterName: string;
}

export interface IProductListWidgetFilter {
  key: string;
  values: string[];
}

export interface IHomepageSmallCarouselItem {
  categoryId?: string;
  heading?: string;
  description?: string;
  headingColor?: string;
  descriptionColor?: string;
  image?: ICommonImage;
  tag?: string;
}

export interface IHomepageGenericCarousel {
  genericCarouselItem?: IHomepageGenericCarouselItem[];
  viewAllVisible?: boolean;
  viewAllUrl?: string;
}

export interface IHomepageGenericCarouselItem {
  heading?: string;
  description?: string;
  headingColor?: string;
  descriptionColor?: string;
  image?: ICommonImage;
  url?: string;
  urlKey?: string;
  tag?: string;
}

export interface IHomepageTakeawayMenuWidget {
  chainId: string;
  restaurantId: string;
  image: ICommonImage;
  title: string;
}

export interface IHomepageGrocerySwitchboardWidgegt {
  image?: ICommonImage;
  text?: string;
  stores?: IHomepageGrocerySwitchboardWidgegtStore[];
}

export interface IHomepageGrocerySwitchboardWidgegtStore {
  storeId: string;
  image: ICommonImage;
  title: string;
  description: string;
}

export interface IHomepageOnecartWidget {
  image: ICommonImage;
  message: string;
}

export interface IHomepagePlPortalWidget {
  heading: string;
  text: string;
  url: string;
  urlKey: string;
}

export interface IHomepageRewardsWidget {
  avoGiftVoucherUrl: string;
  avoGiftVoucherUrlKey: string;
}

export interface IHomepageImage {
  image: ICommonImage;
  url?: string;
  urlKey?: string;
}

export interface IHomepageImageV3 {
  image: ICommonImage;
  url: string;
}

export interface IHomepagePopup {
  label: string;
  image: ICommonImage;
  boldText?: string;
  text: string;
  buttons: IHomepageButton[];
}

export interface IHomepageButton {
  label: string;
  url: string;
  urlKey: string;
}

export interface IHomepageServiceItem {
  serviceId: string;
  heading: string;
  description: string;
  textColor: string;
  image: ICommonImage;
}

export interface IHomepageEshopWidget {
  tag?: string;
  categoryId: string;
}

export interface IHomepageSeller {
  categoryId: string;
  image: ICommonImage;
  name: string;
}

export interface IHomepageGrocery {
  image: ICommonImage;
  grocerySellers: IHomepageGrocerySeller[];
}

export interface IHomepageGrocerySeller {
  type: HomepageGrocerySellerType;
  image: ICommonImage;
}

export interface IHomepageCategoryItem {
  name: string;
  image: ICommonImage;
  url: string;
  urlKey: string;
}

export interface ISwitchboardOption {
  iconLabel: string;
  icon: ICommonImage;
  url: string;
  urlKey: string;
}

export interface IQuestion {
  question: string;
  answer: string;
}

export interface IHomepageBrand {
  image: ICommonImage;
  brands: IHomepageBrandItem[];
}

export interface IHomepageBrandItem {
  categoryId: HomepageGrocerySellerType;
  image: ICommonImage;
}

export interface IHomepageVoucherItem {
  sku: string;
  image: ICommonImage;
  description: string;
  provider: string;
}

export interface IHomepageBannerWidget {
  positionName: string; // TODO wrong tiping
}

export enum HomepageGrocerySellerType {
  ONECART = 'ONECART',
}

export interface IHomepageTopOfferWidget {
  tag?: string;
  categoryId: string;
}

export interface IHomepageWalletDetail {
  walletId: string;
  balance: IAmount;
  upgradeWallet: boolean;
  walletLabel: string;
}

export interface IHomepageV2WalletResponse {
  createWallet: boolean;
  summaryBold?: string;
  summaryText?: string;
  wallets?: IHomepageWalletDetail[];
  avoBalance?: IAmount;
  avoZarRate?: number;
}

export enum HomepageWidgetTypeV2 {
  LOGIN = 'LOGIN',
  UPGRADE_LOGIN = 'UPGRADE_LOGIN',
  WALLET = 'WALLET',
  BANNER = 'BANNER',
  IMAGE = 'IMAGE',
  LOAN = 'LOAN',
  AVOBOARD = 'AVOBOARD',
  SERVICES = 'SERVICES',
  RESTAURANTS_NEARBY = 'RESTAURANTS_NEARBY',
  MALL = 'MALL',
  LIQUOR_TAKEAWAYS = 'LIQUOR_TAKEAWAYS',
  SELLERS = 'SELLERS',
  GROCERIES = 'GROCERIES',
  REWARDS = 'REWARDS',
  ESHOP_CATEGORY = 'ESHOP_CATEGORY',
  GROCERY_CATEGORY = 'GROCERY_CATEGORY',
  RECOMMENDED_PRODUCTS = 'RECOMMENDED_PRODUCTS',
  CATEGORIES = 'CATEGORIES',
  BRANDS = 'BRANDS',
  VOUCHERS = 'VOUCHERS',
  ADOBE_RECENTLY_VIEWED = 'ADOBE_RECENTLY_VIEWED',
  ADOBE_MOST_POPULAR = 'ADOBE_MOST_POPULAR',
  GROCERY_SWITCHBOARD = 'GROCERY_SWITCHBOARD',
  ONECART = 'ONECART',
  TAKEAWAY_MENU = 'TAKEAWAY_MENU',
  SMALL_CAROUSEL = 'SMALL_CAROUSEL',
  CUSTOM_WIDGET = 'CUSTOM_WIDGET',
  LINK_ACCOUNT = 'LINK_ACCOUNT',
  BANKING_SERVICES = 'BANKING_SERVICES',
  SELLER_RESTAURANT = 'SELLER_RESTAURANT',
  SELLER_STORE = 'SELLER_STORE',
  TOP_OFFERS = 'TOP_OFFERS',
  BUYER_INFORMATION = 'BUYER_INFORMATION',
  BUYER_ORDERS = 'BUYER_ORDERS',
  GET_QUOTE = 'GET_QUOTE',
  WISHLIST = 'WISHLIST',
  REGISTER = 'REGISTER',
  MERCHANT_JOBS = 'MERCHANT_JOBS',
  MERCHANT_PREPAIDS = 'MERCHANT_PREPAIDS',
  SELLER_ORDERS = 'SELLER_ORDERS',
  UPGRADE_ACCOUNT = 'UPGRADE_ACCOUNT',
  EXPLORE_MARKETPLACE = 'EXPLORE_MARKETPLACE',
  ADD_PRODUCTS = 'ADD_PRODUCTS',
  INVENTORY_MANAGEMENT = 'INVENTORY_MANAGEMENT',
  SWITCHBOARD = 'SWITCHBOARD',
  CONTACT = 'CONTACT',
  QUESTIONS = 'QUESTIONS',
  CONSULTATION_HISTORY = 'CONSULTATION_HISTORY',
  GENERIC_CAROUSEL = 'GENERIC_CAROUSEL',
  DOCTORS_GROUP = 'DOCTORS_GROUP',
  CONSULTATION_RECONNECTING = 'CONSULTATION_RECONNECTING',
  GROCERIES_CATEGORIES = 'GROCERIES_CATEGORIES',
  SEARCH = 'SEARCH',
  GROCERIES_STORES = 'GROCERIES_STORES',
  GROCERIES_MALLS = 'GROCERIES_MALLS',
  BRANDED_DASHBOARDS = 'BRANDED_DASHBOARDS',
  PL_PORTAL = 'PL_PORTAL',
  RECENTLY_ORDERED = 'RECENTLY_ORDERED',
  ACTIVE_ORDERS = 'ACTIVE_ORDERS',
  TRANSACTIONS = 'TRANSACTIONS',
  BECOME_SELLER = 'BECOME_SELLER',
  FEATURED_PRODUCTS = 'FEATURED_PRODUCTS',
  TOP_DEALS = 'TOP_DEALS',
  TOP_OFFERS_SMALL_BUSINESSES = 'TOP_OFFERS_SMALL_BUSINESSES',
  RECENTLY_ADDED_DEALS = 'RECENTLY_ADDED_DEALS',
  PRODUCT_LIST = 'PRODUCT_LIST',
  NAMED_SEARCH = 'NAMED_SEARCH',
  CONTENT_CARD_CAROUSEL = 'CONTENT_CARD_CAROUSEL',
  STORES_LIST = 'STORES_LIST',
}

export interface IHomepageGroceryCategoryResponse {
  products: IHomepageProduct[];
  categoryId: string;
}

export interface IHomepageEshopCategoryResponse {
  products: IHomepageProduct[];
}

export interface IHomepageProduct {
  sku: string;
  name: string;
  image: ICommonImage;
  price: IAmount;
  priceBeforeDiscount?: IAmount;
  discount?: number;
  variantsCount: number;
  favourite: boolean;
  lowStockCount?: number;
  b2bMinPurchasedUnits?: number;
  b2bMinPrice?: IAmount;
  b2bMaxPrice?: IAmount;
}

export interface ISearchWidget {
  categoryId: string;
}

export enum AppType {
  customer = 'customer',
  merchant = 'merchant',
}

export enum DashboardProductsListType {
  featured = 'featured',
  recentlyAddedDeals = 'recentlyAddedDeals',
  topDeals = 'topDeals',
  topOffersSmallBusinesses = 'topOffersSmallBusinesses',
}

export interface IHomepageLoyalityPointsResponse {
  points: number;
  pointsRate: number;
}

export interface IPersonalLoanWidgetResponse {
  id: string;
  isApproved: boolean;
  amount?: IAmount;
  creditMonthlyRepaymentAmount?: IAmount;
}

export interface IPopularCategoriesResponse {
  categories: IPopularCategoriesCategory[];
}

export interface IPopularCategoriesCategory {
  id: string;
  name: string;
  description: string;
  icon: ICommonImage;
}

//v3
export enum SellerDashboardKeyEnum {
  SELLER_DASHBOARD = 'SELLER_DASHBOARD',
  SELLER_STORE_DASHBOARD = 'SELLER_STORE_DASHBOARD',
  BUYER_MARKETPLACE = 'BUYER_MARKETPLACE',
  BUYER_DASHBOARD = 'BUYER_DASHBOARD',
}
