import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class ModalService {
  private registerModalVisibility = new BehaviorSubject<boolean>(false);
  private supportModalVisibility = new BehaviorSubject<boolean>(false);

  showRegisterModal = (show: boolean): void => {
    this.registerModalVisibility.next(show);
  };

  showSupportModal = (show: boolean): void => {
    this.supportModalVisibility.next(show);
  };

  getRegisterModalVisibility = (): Observable<boolean> => this.registerModalVisibility.asObservable();
  getSupportModalVisibility = (): Observable<boolean> => this.supportModalVisibility.asObservable();
}
