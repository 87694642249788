import get from 'lodash-es/get';
import { CommonUtility } from './commonUtility';

export class ErrorUtility {
  public static handleOtpErrorMessage = (error): string => {
    const errorCode = get(error, ['error', 'error', 'code']);
    let translate = 'error.otp.wrong_attempt'; // otp_wrong_code
    switch (errorCode) {
      case 'otp_expired_new_sms_sent':
        translate = 'error.otp.otp_expired_new_sms_sent';
        break;
      case 'otp_failed':
        translate = 'error.otp.too_many_attempts';
        break;
      default:
        break;
    }
    return translate;
  };

  public static getMessageFromErrorMessage = (errorMessage: string): string => {
    const regex = /Message(.*)"/g;
    const regexResult = CommonUtility.findString(errorMessage, regex);
    if (regexResult?.length > 0) {
      const cutMessageData = regexResult[0].split('":"');
      if (cutMessageData?.length > 0) {
        return cutMessageData[1]?.substr(0, cutMessageData[1]?.length - 1);
      }
    }
    return errorMessage;
  };

  public static getErrorMessageFromApiResponse = (apiResponse: any): string => {
    let errorMessage: any;
    if (apiResponse && apiResponse.error) {
      if (apiResponse.error.error) {
        if (apiResponse.error.error.messageCode) errorMessage = apiResponse.error.error.messageCode;
        else if (apiResponse.error.error.message) errorMessage = apiResponse.error.error.message;
      } else {
        if (apiResponse.error.messageCode) errorMessage = apiResponse.error.messageCode;
        else if (apiResponse.error.message) errorMessage = apiResponse.error.message;
        else if (typeof apiResponse.error === 'string') errorMessage = apiResponse.error;
      }
    }
    return errorMessage;
  };
}
