<ng-container *ngIf="!isExpandable; else subitemsTemplate">
  <div (click)="clickItem.emit(item.link)" class="row">
    <ui-divider [vertical]="true" [compact]="true"></ui-divider>
    <span class="description">
      <p [class.active]="router.url === item.link" (click)="clickItem.emit(item.link)" zen-translate>
        {{ item.title }}
      </p>
    </span>
  </div>
</ng-container>
<ng-template #subitemsTemplate>
  <div (click)="handleItem()" class="row">
    <ui-divider [vertical]="true" [compact]="true"></ui-divider>
    <span class="description">
      <p [class.active]="isOpen" zen-translate>{{ item.title }}</p>
    </span>
    <ui-icon icon="{{ isOpen ? 'up' : 'down' }}" size="1.2rem"></ui-icon>
  </div>
</ng-template>
<div *ngIf="isOpen" class="sub-items">
  <ng-container *ngFor="let item of item.subitems">
    <h3 [class.active]="router.url === item.link" (click)="clickItem.emit(item.link)" zen-translate>
      {{ item.title }}
    </h3>
    <ui-divider [compact]="true"></ui-divider>
    <ng-container *ngFor="let subitem of item?.subitems">
      <a [class.active]="router.url === subitem.link" (click)="clickItem.emit(subitem.link)" zen-translate>
        {{ subitem.title }}
      </a>
      <ui-divider [compact]="true"></ui-divider>
    </ng-container>
  </ng-container>
</div>
<ui-divider [dotted]="true"></ui-divider>
