import { Pipe, PipeTransform } from '@angular/core';
import round from 'lodash-es/round';
import { ILoyaltyPoint } from '../models/wallet.model';

@Pipe({
  name: 'avoPointsToZAR',
})
export class AvoPointsToZARPipe implements PipeTransform {
  public transform(value: number, pointsResponse: ILoyaltyPoint): number {
    if (!value) return 0;
    const rate = pointsResponse?.pointsRate ?? 1;
    return round(value * rate, 2);
  }
}
