import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AdobeService } from '@wakanda/adobe-analytics';
import { ModalType } from '../modal/modal.component';

export interface ILimitedUserLabels {
  screenTitle?: string;
  title?: string;
  message?: string;
  buttonTitle?: string;
}

@Component({
  selector: 'ui-customer-limited-user-widget',
  template: `
    <ui-modal
      [modal]="ModalType.SLIDE_IN_UP"
      [closeButton]="true"
      [title]="labels?.screenTitle | translate"
      [isVisible]="true"
      [template]="createWalletTemplate"
      (onCloseClick)="closeDialog()"
    >
      <ng-template #createWalletTemplate>
        <p class="text-align-center small">{{ labels?.message | translate }}</p>

        <ui-divider></ui-divider>
        <ui-divider></ui-divider>

        <ui-button
          aligned="bottom"
          [compact]="true"
          [callToActionButton]="true"
          (onClick)="onCheckOnboarding()"
          [selectButton]="true"
          [content]="labels?.buttonTitle | translate"
        >
        </ui-button>
      </ng-template>
    </ui-modal>
  `,
})
export class CustomerLimitedUserWidgetComponent {
  readonly ModalType = ModalType;

  @Input() labels: ILimitedUserLabels = {
    screenTitle: 'limited_user.dialog.screen_title1',
    message: 'limited_user.dialog.description1',
    buttonTitle: 'limited_user.dialog.cta_button',
  };

  @Output() onCloseDialog = new EventEmitter();
  @Output() onCreateWallet = new EventEmitter();

  private readonly adobeService: AdobeService;

  constructor(injector: Injector) {
    this.adobeService = injector.get(AdobeService);
  }

  onCheckOnboarding(): void {
    this.adobeService.executeAdobeClickEvent('wallet: creation');
    this.onCreateWallet.emit();
  }

  closeDialog(): void {
    this.onCloseDialog.emit();
  }
}
