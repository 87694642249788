import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMatrixMessage, IMessageEventProduct } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-products',
  styleUrls: ['./chat-widget-products.component.scss'],
  template: `
    <div>
      <div class="h-scroll-content compact">
        <ng-container *ngFor="let item of message?.content?.products; let last = last">
          <div class="widget-item" (click)="onProductClick.emit(item.sku)">
            <img *ngIf="item.thumbnail" [alt]="item.name" [src]="item.thumbnail | resizeImageUrl" />
            <div *ngIf="!item.thumbnail" class="placeholder"></div>

            <ui-divider [compact]="true"></ui-divider>
            <div class="padded-content">
              <div class="row between">
                <b class="text-align-left">{{ item.name }}</b>
                <ui-icon [icon]="item.favourite ? 'heart1_fill' : 'heart_1'" size=".938rem"></ui-icon>
              </div>
              <div class="row between">
                <ng-container *ngIf="item.variantsCount >= 2">
                  <p class="small light">
                    {{ 'general.variants$' | translate: { $1: item.variantsCount } }}
                  </p>

                  <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                </ng-container>
                <p class="error" *ngIf="item.lowStockCount">
                  {{ 'general.items_left$' | translate: { $1: item.lowStockCount } }}
                </p>
              </div>
              <div class="row between">
                <ui-available-balance [data]="item.price" renderAs="h3"></ui-available-balance>
                <ui-divider [vertical]="true" [compact]="true"></ui-divider>
                <ui-available-balance
                  *ngIf="!!item.priceBeforeDiscount"
                  [data]="item.priceBeforeDiscount"
                  [isCrossed]="true"
                  renderAs="small"
                ></ui-available-balance>
              </div>
              <ui-divider [compact]="true"></ui-divider>
            </div>
          </div>
          <ui-divider *ngIf="!last" [vertical]="true" [compact]="true"></ui-divider>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
  `,
})
export class ChatWidgetProductsComponent {
  @Input() message: IMatrixMessage<IMessageEventProduct>;
  @Input() heyNedTheme: boolean;
  @Output() onProductClick = new EventEmitter<string>();
}
