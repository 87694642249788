import { Directive, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { IFileUpload } from '../models/image.model';

@Directive({
  selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  @HostListener('dragover', ['$event']) onDragOver(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const images: IFileUpload[] = [];
    Array.from(evt?.dataTransfer?.files)?.forEach(f => {
      const file: File = f as File;
      const image: IFileUpload = {
        name: file?.name,
        data: file,
      };
      images.push(image);
    });

    this.fileDropped.emit(images);
  }
}
