import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICommonOnChangeOutput } from '@wakanda/wakanda-core';
import { FadeInOutLongAnimation } from '../../utils/animations';

@Component({
  selector: 'ui-switch-button',
  animations: [FadeInOutLongAnimation],
  template: `
    <div class="ui-switch-button" [ngClass]="{ 'with-title': !!title, 'small': !!isSmall, 'medium': isMedium }">
      <h4 *ngIf="!!title" class="title">{{ title }}</h4>
      <div
        [@fadeInOut]
        class="switch-buttons"
        *ngIf="labels?.length === 2"
        [ngClass]="{ 'full-width': !!fullWidth }"
        [ngStyle]="width && { width: width }"
      >
        <span
          class="first-tab"
          [ngClass]="{ active: active !== undefined ? active === labels[0] : true }"
          (click)="onChange.emit({ name: name, value: labels[0] })"
        >
          <p>{{ labels[0] }}</p>
        </span>
        <span
          class="second-tab"
          [ngClass]="{ active: active !== undefined ? active === labels[1] : 0 }"
          (click)="onChange.emit({ name: name, value: labels[1] })"
        >
          <p>{{ labels[1] }}</p>
        </span>
      </div>
    </div>
  `,
})
export class SwitchButtonComponent {
  @Input() labels: string[];
  @Input() name: string;
  @Input() active: string;
  @Input() title: string;
  @Input() isSmall: boolean;
  @Input() isMedium: boolean;
  @Input() fullWidth: boolean;
  @Input() width: string;
  @Output() onChange = new EventEmitter<ICommonOnChangeOutput>();
}
