import { Action } from '@ngrx/store';
import { IAdvertisingBannerResponse } from '../../models/advertising.model';
import { AdvertisingActions } from './advertising.actions';
import { EVENTS } from './advertising.events';

export const ADVERTISING_FEATURE_KEY = 'advertising';

export interface IAdvertisingState {
  banner?: IAdvertisingBannerResponse;
}

export interface IAdvertisingPartialState {
  readonly [ADVERTISING_FEATURE_KEY]: IAdvertisingState;
}

export function advertisingReducer(state: IAdvertisingState = {}, action: Action): IAdvertisingState {
  const advertisingAction = action as AdvertisingActions;

  switch (advertisingAction.type) {
    case EVENTS.ADVERTISING_BANNER_RECEIVED: {
      const banner: IAdvertisingBannerResponse = advertisingAction.data;
      return Object.assign({}, state, { banner });
    }
    default:
      return state;
  }
}
