import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMatrixMessage, IMessageEventMerchant } from '@wakanda/agent-chat-model';
@Component({
  selector: 'ui-chat-widget-merchants',
  styleUrls: ['./chat-widget-merchants.component.scss'],
  template: `
    <div
      class="ui-chat-message"
      [ngClass]="{ 'incoming': !message?.sender_self, 'outgoing': message?.sender_self, 'hey-ned': heyNedTheme }"
    >
      <ng-container *ngFor="let merchant of message.content?.merchants">
        <div class="column" (click)="onJobDetail.emit(message?.content?.jobId)">
          <img *ngIf="!!merchant.picture" [src]="merchant.picture | secureImage | async" />
          <p>{{ merchant.name }}</p>
          <ui-rating [rating]="merchant.rating" [readOnly]="true"></ui-rating>
          <p *ngIf="!!merchant.numberOfReviews">
            <span>{{ 'chat.merchants.reviews$' | translate: { $1: merchant.numberOfReviews } }}</span>
          </p>
          <p>{{ merchant.locationDescription }}</p>
        </div>
        <ui-divider [compact]="true"></ui-divider>
      </ng-container>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
  `,
})
export class ChatWidgetMerchantsComponent {
  @Input() message: IMatrixMessage<IMessageEventMerchant>;
  @Input() heyNedTheme: boolean;
  @Output() onJobDetail = new EventEmitter<string>();
}
