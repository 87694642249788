import { Pipe, PipeTransform } from '@angular/core';
import { AmountUtility } from '../utils/amount';

export interface IAmountTransformPipeParam {
  hideSign?: boolean;
  hidePrefix?: boolean;
  noDecimal?: boolean;
  isLabel?: boolean;
  labelFractionDigits?: number;
  defaultValue?: number;
  prefixValue?: string;
  isInput?: boolean;
}

@Pipe({ name: 'amountTransform', pure: false })
export class AmountTransformPipe implements PipeTransform {
  private PREFIX = 'R';
  private DECIMAL_SEPARATOR = '.';
  private THOUSANDS_SEPARATOR = ' ';
  private SUFFIX = '';
  private PRECISION = 2;
  private IS_INPUT = false;

  transform(value: string | number, param?: IAmountTransformPipeParam, setEmptyToZero = false): string {
    let formattedValue: any;
    let hideSign: boolean;
    let hidePrefix: boolean;
    let noDecimal: boolean;
    let isLabel: boolean;
    if (param) {
      hideSign = param.hideSign || false;
      hidePrefix = param.hidePrefix || false;
      noDecimal = param.noDecimal || false;
      isLabel = param.isLabel || false;
      this.PRECISION = !!param.labelFractionDigits || param.labelFractionDigits === 0 ? param.labelFractionDigits : 2;
      this.PREFIX = hidePrefix ? '' : param.prefixValue || this.PREFIX;
      this.IS_INPUT = param.isInput;
    }
    const sign = parseFloat(value + '') < 0 ? '- ' : '';
    value = value || '';
    if (value === '' && !param?.defaultValue) {
      const formattedValue = noDecimal || isLabel || setEmptyToZero ? Number(0).toFixed(this.PRECISION) : '';
      return this.PREFIX + formattedValue + this.SUFFIX;
    }

    if (value === '' && !!param?.defaultValue) {
      return this.PREFIX + param.defaultValue + this.SUFFIX;
    }
    let onlyNumber = AmountUtility.amountOnly(value.toString());
    if (!onlyNumber) {
      return this.PREFIX + this.SUFFIX;
    }
    if (isLabel) {
      onlyNumber = parseFloat(onlyNumber).toFixed(this.PRECISION);
    }

    if (noDecimal) {
      onlyNumber = parseInt(onlyNumber, 10).toString();
      onlyNumber = onlyNumber === 'NaN' ? '' : onlyNumber;
    }

    if (onlyNumber.indexOf(this.DECIMAL_SEPARATOR) >= 0) {
      const twoDecimalNumber = onlyNumber.substr(0, onlyNumber.indexOf(this.DECIMAL_SEPARATOR) + this.PRECISION + 1);
      let integerPart = twoDecimalNumber.substr(0, twoDecimalNumber.indexOf(this.DECIMAL_SEPARATOR));
      integerPart = AmountUtility.amountSplit(integerPart, this.THOUSANDS_SEPARATOR);
      let decimalPart = twoDecimalNumber.substr(
        twoDecimalNumber.indexOf(this.DECIMAL_SEPARATOR) + 1,
        twoDecimalNumber.length + 1,
      );
      decimalPart = AmountUtility.removeDecimal(decimalPart);
      if (this.IS_INPUT) {
        formattedValue = integerPart + this.DECIMAL_SEPARATOR + decimalPart;
      } else {
        formattedValue = integerPart + this.DECIMAL_SEPARATOR + decimalPart?.padEnd(this.PRECISION, '0');
      }
    } else {
      if (this.IS_INPUT) {
        formattedValue = AmountUtility.amountSplit(onlyNumber, this.THOUSANDS_SEPARATOR);
      } else {
        formattedValue = Number(onlyNumber).toFixed(this.PRECISION);
        formattedValue = AmountUtility.amountSplit(formattedValue, this.THOUSANDS_SEPARATOR);
      }
    }
    if (!formattedValue || formattedValue === '0') {
      return this.PREFIX + this.SUFFIX;
    } else {
      return (hideSign ? '' : sign) + this.PREFIX + formattedValue + this.SUFFIX;
    }
  }

  parse(value: string, currencyCode: string): string {
    this.PREFIX = currencyCode;
    value = value || '';
    value = AmountUtility.amountOnly((value || '').toString());
    value = value.replace(this.PREFIX, '').replace(this.SUFFIX, '');

    let [integer, fraction = ''] = value.split(this.DECIMAL_SEPARATOR);
    integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');
    fraction =
      parseInt(fraction, 10) > 0 && this.PRECISION > 0
        ? this.DECIMAL_SEPARATOR + fraction.substring(0, this.PRECISION)
        : '';

    if (!(integer + fraction)) {
      return '0';
    } else {
      return integer + fraction;
    }
  }

  parseBlur(value: string, hidePrefix: boolean, currencyCode: string): string {
    value = this.parse(value, currencyCode);
    if (hidePrefix) {
      return value;
    } else {
      if (value === '0') {
        return this.PREFIX;
      }
      const result = this.PREFIX + value;
      return result;
    }
  }
}
