import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApplicationFacade, failed, HttpErrorAction, succeed } from '@wakanda/wakanda-core';
import { mergeMap, of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '../services/translate.service';
import {
  TranslateNewRequestAction,
  TranslateNewResponseAction,
  TranslateRequestAction,
  TranslateResponseAction,
} from './translate.actions';
import { EVENT } from './translate.events';

@Injectable({ providedIn: 'root' })
export class TranslateEffects {
  translate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TranslateRequestAction>(EVENT.REQUEST),
      switchMap(({ taskId, errorCallback }) =>
        this.service.fetchTranslates().pipe(
          map((data: any) => succeed(new TranslateResponseAction(taskId, data))),
          catchError(error => {
            errorCallback(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  translateNew$ = createEffect(() =>
    this.actions$.pipe(
      ofType<TranslateNewRequestAction>(EVENT.REQUEST_NEW),
      mergeMap(({ taskId, url, errorCallback }) =>
        this.service.fetchNewTranslates(url).pipe(
          map((data: any) => succeed(new TranslateNewResponseAction(taskId, data))),
          catchError(error => {
            errorCallback(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  constructor(private service: TranslateService, private actions$: Actions, private appFacade: ApplicationFacade) {}
}
