import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IImage } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-image-preview',
  templateUrl: './image-preview.component.html',
})
export class ImagePreviewComponent {
  @Input() image: IImage;
  @Input() readOnly = false;
  @Output() onDeleteClick: EventEmitter<IImage> = new EventEmitter();
}
