import { Injectable, Injector } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Observable } from 'rxjs';

/**
 * Takes care about app shell versioning.
 * Does not touch MFEs.
 */
@Injectable({
  providedIn: 'root',
})
export class CheckForUpdateService {
  private readonly swUpdate: SwUpdate;

  constructor(injector: Injector) {
    this.swUpdate = injector.get(SwUpdate);
  }

  /**
   * Returns a VersionEvent observable to the service worker version updates.
   * the consumer can listen for the specific events that they are interested in
   */
  versionUpdates(): Observable<VersionEvent> {
    return this.swUpdate.versionUpdates;
  }

  /**
   * Returns a promise that indicates if an update is available for activation.
   */
  async checkForUpdate() {
    if (!this.swUpdate.isEnabled) return false;
    return await this.swUpdate.checkForUpdate();
  }
}
