import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import get from 'lodash-es/get';

export class CustomValidators {
  public static valalidators = {
    phone: /^\d{9}$/,
    phoneWithCode: /^(\+27|0)[6-8][0-9]{8}$/,
    saId: /^[0-9]\d{12}$|^[1-9]\d{13}$/,
    email:
      /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    capitalCase: /[A-Z]/,
    smallCase: /[a-z]/,
    alphabetCharacter: /[a-zA-Z]/,
    alphanumericCharacter: /^([a-zA-Z0-9 ]+)$/,
    specialCharacters: /[ !@#$%^&*()_`~\\[\]{};':"\\|,.<>\\/?]/,
    noSpace: /^\S*$/,
    password: /^[^+--]*$/,
    businessDetailName: /^[^{}]*$/,
    businessRegistrationNumber: /^\d{4}\d{6,7}\d{2}$/,
    natureOfBusinessSpecialCharacter: /^[\w\s]*$/,
    natureOfBusinessMinLength: /\s*(?:[\w\\.]\s*){10,}$/,
    natureOfBusinessMaxLength: /^.{0,4000}$/,
    passwordMinLength: /^.{8,}$/,
    passwordMaxLength: /^.{0,99}$/,
    numbersOnly: /^\d+$/,
    postalCode: /^[0-9]{4}$/,
    avoEmail: /nedbank.co.za|mfc.co.za|nedbankprivatewealth.co.za|nedbankinsurance.co.za|nedgroupinvestments.co.za/i,
    numbers: /^[0-9]/,
    numbersWithDot: /^[0-9]*\.?[0-9]*/,
    color: /^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
    username: /^([a-zA-Z0-9\\@\\.\\_\\–\\−\\–\\-\\-]+)$/,
    usernameLogin: /^\S*$/,
    productId: /^([a-zA-Z0-9-/\\ ]+)$/,
    vatNumber: /^\d{10}$/,
  };
  public static errors = {
    numbers: {
      valueIsNotANUmber: true,
    },
    color: {
      invalidHexColor: true,
    },
    phone: {
      invalidPhoneNumber: true,
    },
    saId: {
      invalidSaId: true,
    },
    email: {
      invalidEmail: true,
    },
    capitalCase: {
      invalidCapitalCase: true,
    },
    smallCase: {
      invalidSmallCase: true,
    },
    specialCharacters: {
      invalidSpecialCharacters: true,
    },
    noSpace: {
      invalidNoSpace: true,
    },
    password: {
      invalidPassword: true,
    },
    businessRegistrationNumber: {
      invalidBusinessRegistrationNumber: true,
    },
    natureOfBusinessSpecialCharacter: {
      invalidNatureOfBusiness: true,
    },
    natureOfBusinessMinLength: {
      invalidNatureOfBusinessMinLength: true,
    },
    natureOfBusinessMaxLength: {
      invalidNatureOfBusinessMaxLength: true,
    },
    passwordMinLength: {
      invalidPasswordMinLength: true,
    },
    passwordMaxLength: {
      invalidPasswordMaxLength: true,
    },
    numbersOnly: {
      notANumber: true,
    },
    numbersWithDotOnly: {
      notANumberWithDot: true,
    },
    avoEmail: {
      invalidAvoEmail: {
        text: '',
      },
    },
    alphabetCharacter: {
      invalidAlphabet: true,
    },
    alphanumericCharacter: {
      alphanumericCharactersOnly: true,
    },
    businessDetailName: {
      invalidBusinessDetailName: true,
    },
    postalCode: {
      invalidPostalCode: true,
    },
    username: {
      invalidUsername: true,
    },
    productId: {
      invalidProductId: true,
    },
    ownPhone: {
      ownPhone: true,
    },
    displayNameEmpty: {
      displayNameEmpty: true,
    },
    displayNameShort: {
      displayNameShort: true,
    },
    displayNameLong: {
      displayNameLong: true,
    },
    vatNumber: {
      invalidVatNumber: true,
    },
  };

  public static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  public static moreThanFieldValidator(minFieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control) {
        const numberValue = Number.parseFloat(control.value);
        const minNumber = Number.parseFloat(get(control.parent, ['value', minFieldName]));
        if (!isNaN(numberValue) && !isNaN(minNumber)) {
          return numberValue >= minNumber ? null : { moreThan: { valid: false, value: control.value, min: minNumber } };
        }
      }
      return null;
    };
  }

  public static lessThanFieldValidator(maxFieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control) {
        const numberValue = Number.parseFloat(control.value);
        const maxNumber = Number.parseFloat(get(control.parent, ['value', maxFieldName]));
        if (!isNaN(maxNumber) && !isNaN(numberValue)) {
          return numberValue <= maxNumber ? null : { lessThan: { valid: false, value: control.value, max: maxNumber } };
        }
      }
      return null;
    };
  }

  public static numberMoreThanZeroValidator(customLabel?: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control) {
        const numb = Number.parseFloat(control.value);
        if (!isNaN(numb)) {
          return numb > 0 ? null : { invalidNumber: { valid: false, value: control.value, customLabel } };
        }
      }
      return null;
    };
  }

  public static passwordMatchValidator(control: AbstractControl, errorLabel?: string): void {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors(errorLabel ? { customError: errorLabel } : { noPasswordMatch: true });
    }
  }

  public static noWhitespaceValidator(minLength = 0): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const isWhitespace = (control.value || '').trim().length < minLength;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  public static notAllowedValidator(illegalValues: string[], error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const valid = !illegalValues.includes(control.value);
      return valid ? null : error;
    };
  }

  public static displayNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const length = (control.value || '')?.trim().length;
      if (length === 0) {
        return { displayNameEmpty: true };
      } else if (length < 6) {
        return { displayNameShort: true };
      } else if (length > 35) {
        return { displayNameLong: true };
      } else {
        return null;
      }
    };
  }

  public static dateCompareValidator(minFieldName: string, maxFieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) return null;
      const dateValue = new Date(control.value);
      const minValue = get(control.parent, ['value', minFieldName]);
      const maxValue = get(control.parent, ['value', maxFieldName]);
      if (minValue && maxValue) {
        return minValue <= dateValue && dateValue <= maxValue ? null : { valid: 'false' };
      } else if (minValue) {
        return dateValue > minValue ? null : { valid: 'false' };
      } else if (maxValue) {
        return dateValue < maxValue ? null : { valid: 'false' };
      } else return null;
    };
  }
}
