import { ActivityHistoryType } from '../../models/activity-history.model';
import { RmaType } from '../../models/rma.model';

const ecommerce = (baseUrl: string): string => `${baseUrl}/ecommerce/v2`;

export const activityHistoryOngoing = (baseUrl: string, page, size): string =>
  `${baseUrl}/eshop/v2/activityhistory/ongoing${
    page !== undefined && size !== undefined ? `?page=${page}&pageSize=${size}` : ''
  }`;
export const activityHistoryCompleted = (baseUrl: string, page, size): string =>
  `${baseUrl}/eshop/v2/activityhistory/completed${
    page !== undefined && size !== undefined ? `?page=${page}&pageSize=${size}` : ''
  }`;
export const activityHistoryDetail = (baseUrl: string, orderId: string): string =>
  `${baseUrl}/eshop/v2/activityhistory/order/${orderId}`;

export const fetchActivityHistorySearch = (baseUrl: string, query: string): string =>
  `${baseUrl}/eshop/v2/activityhistory/search?query=${query}`;

export const activityHistoryPostRating = (baseUrl: string, orderId: string, fulfillmentId: string): string =>
  `${baseUrl}/eshop/v2/activityhistory/order/${orderId}/${fulfillmentId}/rating`;

export const activityHistoryCancelOrder = (baseUrl: string, orderId: string): string =>
  `${baseUrl}/eshop/v2/activityhistory/order/${orderId}/cancel`;

export const activityHistoryReleasePayment = (baseUrl: string, orderId: string, fulfilmentId: string): string =>
  `${ecommerce(baseUrl)}/order/${orderId}/${fulfilmentId}/release`;

export const activityHistoryCancelReasons = (baseUrl: string, orderType: ActivityHistoryType): string =>
  `${baseUrl}/eshop/v2/activityhistory/cancelReasons?orderType=${orderType}`;

export const rmaCancelReasons = (baseUrl: string, orderId: string, fulfilmentId: string): string =>
  `${ecommerce(baseUrl)}/order/${orderId}/${fulfilmentId}/rma/cancel-reasons`;

export const rma = (baseUrl: string, orderId: string, fulfilmentId: string, type: RmaType): string =>
  `${ecommerce(baseUrl)}/order/${orderId}/${fulfilmentId}/rma/${type.toLowerCase()}`;

export const fetchRmaDetail = (baseUrl: string, orderId: string, fulfilmentId: string, rmaId: string): string =>
  `${ecommerce(baseUrl)}/order/${orderId}/${fulfilmentId}/rma/${rmaId}`;

export const fetchRmaList = (baseUrl: string, params: any): string =>
  `${ecommerce(baseUrl)}/order/rma?${new URLSearchParams(params).toString()}`;
