import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import { IGeolocationResponse } from '../../models/location.model';
import { ApiService } from '../api/api.service';
import * as endpoints from './app-config.endpoints';

@Injectable()
export class AppConfigService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  public fetchAppConfig(): Observable<any> {
    return this.api.get<any>(endpoints.appConfig(this.appConfig.backendConfig.apiUrl));
  }

  public fetchGeolocationsByUrl(url: string): Observable<IGeolocationResponse> {
    return this.api.get<IGeolocationResponse>(url);
  }
}
