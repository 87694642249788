import { Action } from '@ngrx/store';

import * as EVENT from './airtimedata.events';
import {
  IAirtimeDataProvider,
  IOrderDetailForPayment,
  IPurchaseAirtimeDataCurrentOrder,
  IPurchaseAirtimeDataRequest,
  IRecentAirtimedataPurchase,
} from '../../../models/prepaids.model';

export class AirtimeDataRecentTransactionsRequest implements Action {
  readonly type = EVENT.RECENT_TRANSACTIONS_REQUEST;
  constructor(public taskId: string) {}
}

export class AirtimeDataRecentTransactionsResponse implements Action {
  readonly type = EVENT.RECENT_TRANSACTIONS_RECEIVED;
  constructor(public taskId: string, public payload: IRecentAirtimedataPurchase[]) {}
}

export class AirtimeDataProvidersRequest implements Action {
  readonly type = EVENT.ORDER_DETAIL_PROVIDERS_REQUEST;
  constructor(public taskId: string, public onSucceed?: (data: IAirtimeDataProvider[]) => void) {}
}

export class AirtimeDataProvidersResponse implements Action {
  readonly type = EVENT.ORDER_DETAIL_PROVIDERS_RECEIVED;
  constructor(public taskId: string, public payload: IAirtimeDataProvider[]) {}
}

export class AirtimeDataCurrentOrderEdit implements Action {
  readonly type = EVENT.CREATE_ORDER_EDIT;
  constructor(public taskId: string, public payload: IPurchaseAirtimeDataCurrentOrder) {}
}

export class AirtimeDataPlaceOrderRequest implements Action {
  readonly type = EVENT.PLACE_ORDER_REQUEST;
  constructor(public taskId: string, public payload: IPurchaseAirtimeDataRequest, public onSucceed: () => void) {}
}

export class AirtimeDataPlaceOrderResponse implements Action {
  readonly type = EVENT.PLACE_ORDER_RECEIVED;
  constructor(public taskId: string, public payload: IOrderDetailForPayment) {}
}

export type AirtimeDataActions =
  | AirtimeDataRecentTransactionsRequest
  | AirtimeDataRecentTransactionsResponse
  | AirtimeDataProvidersRequest
  | AirtimeDataProvidersResponse
  | AirtimeDataPlaceOrderRequest
  | AirtimeDataPlaceOrderResponse
  | AirtimeDataCurrentOrderEdit;
