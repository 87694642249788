import { Component, Input } from '@angular/core';
import { IMatrixMessage, MessageEventType } from '@wakanda/agent-chat-model';
import { ModalType } from '../modal/modal.component';

interface IMatrixMessageImageClick {
  url: string;
  name: string;
}

@Component({
  selector: 'ui-chat-message',
  template: `
    <ng-container *ngIf="message?.content as content">
      <div
        class="ui-chat-message"
        [ngClass]="{ 'incoming': !message?.sender_self, 'outgoing': message?.sender_self, 'hey-ned': heyNedTheme }"
      >
        <ng-container *ngIf="content?.msgtype === MessageEventType.IMAGE">
          <img
            [alt]="content?.body || ''"
            [src]="content?.url | secureImage | async"
            class="ui-chat-message-img"
            (click)="onImage({ url: content?.url, name: content?.body })"
          />
        </ng-container>
        <p class="small word-break">{{ content?.body }}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>

    <ui-modal
      [modal]="ModalType.SLIDE_IN_UP"
      [closeButton]="true"
      (onCloseClick)="onImage()"
      [title]="'IMAGE'"
      [isVisible]="!!showImageModalFor"
      class="text-align-center"
    >
      <ui-divider></ui-divider>
      <img
        [alt]="showImageModalFor?.name || ''"
        [src]="showImageModalFor?.url | secureImage | async"
        [style.maxWidth]="'100%'"
      />
      <ui-divider [compact]="true"></ui-divider>
      <p>{{ showImageModalFor?.name || '' }}</p>
      <ui-divider></ui-divider>
    </ui-modal>
  `,
})
export class ChatMessageComponent {
  @Input() message: IMatrixMessage;
  @Input() heyNedTheme: boolean;
  ModalType = ModalType;

  MessageEventType = MessageEventType;
  showImageModalFor: IMatrixMessageImageClick;

  onImage = (image?: IMatrixMessageImageClick): void => {
    this.showImageModalFor = image;
  };
}
