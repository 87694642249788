import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DialogData } from '../../models/dialog-data.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private subject = new Subject<DialogData>();
  private closeSubject = new Subject<boolean>();
  private confirmSubject = new Subject<boolean>();

  public openDialog(data: DialogData): void {
    this.subject.next(data);
  }

  public closeDialog(): void {
    this.subject.next(null);
    this.confirmSubject.next(false);
    this.closeSubject.next(true);
  }

  public confirmDialog(): void {
    this.subject.next(null);
    this.confirmSubject.next(true);
  }

  public getDialog(): Observable<DialogData> {
    return this.subject.asObservable();
  }

  public onCloseDialog(): Observable<boolean> {
    return this.closeSubject.asObservable();
  }

  public onConfirmDialog(): Observable<boolean> {
    return this.confirmSubject.asObservable();
  }
}
