import { Action } from '@ngrx/store';
import { IResponseError } from '../../models/application.model';
import { IImageFileResponse } from '../../models/image.model';
import { IPosition } from '../../models/location.model';
import {
  IShoppingAddress,
  IShoppingBuyDeliveryInfoRequest,
  IShoppingBuyerAddItemRequest,
  IShoppingBuyerAddItemResponse,
  IShoppingBuyerCart,
  IShoppingBuyerCartDeliveryAddressRequest,
  IShoppingBuyerCartDeliveryOptionRequest,
  IShoppingBuyerCartDetail,
  IShoppingBuyerCartItemQuantityRequest,
  IShoppingBuyerCartPaymentDetails,
  IShoppingBuyerCartSummary,
  IShoppingBuyPickupLocationResponse,
  IShoppingBuyPromoResponse,
  IShoppingCart,
  IShoppingFulfillmentDeliveryOptions,
  IShoppingFulfillmentPatch,
  IShoppingOrderDetailResponse,
  IShoppingOrderPaymentResponse,
  IShoppingPayCardRequest,
  IShoppingPaymentInfoResponse,
  IShoppingPayWalletRequest,
  IShoppingPayWalletResponse,
  IShoppingProductCards,
  IShoppingProductRequest,
  IShoppingReserveInfoRequest,
  IShoppingReserveInfoResponse,
  IShoppingSetTermInMonthsRequest,
  IShoppingTvLicenceCheckRequest,
  IShoppingTvLicenceCheckResponse,
  IShoppingTvLicencePhotoIdRequest,
  IShoppingUpdateCartGiftOptionRequest,
  IUrlInfoResponse,
  ShoppingCartType,
} from '../../models/shopping.model';
import { IPayByCardResponse } from '../../models/wallet.model';
import * as EVENT from './shopping-buy.events';
import { HttpErrorAction } from '../application/task.actions';

// ShoppingBuyContentRequest
export class ShoppingBuyContentRequestAction implements Action {
  readonly type = EVENT.BUY_CART_CONTENT_REQUEST;
  constructor(public taskId: string, public cartType: string, public onSucceed?: (data: IShoppingCart) => void) {}
}
export class ShoppingBuyContentResponseAction implements Action {
  readonly type = EVENT.BUY_CART_CONTENT_RECEIVED;
  constructor(public taskId: string, public data: IShoppingCart) {}
}

// ShoppingBuyDeleteCartItem
export class ShoppingBuyDeleteCartItemRequestAction implements Action {
  readonly type = EVENT.BUY_CART_DELETE_CART_ITEM_REQUEST;
  constructor(
    public taskId: string,
    public cartItem: IShoppingProductRequest,
    public cartType: string,
    public onSucceeded?: (response: any) => void,
    public onError?: (error: any) => void,
  ) {}
}
export class ShoppingBuyDeleteCartItemResponseAction implements Action {
  readonly type = EVENT.BUY_CART_DELETE_CART_ITEM_RECEIVED;
  constructor(public taskId: string, public data: IShoppingCart) {}
}
// ShoppingBuyUpdateCartItem
export class ShoppingBuyUpdateCartItemRequestAction implements Action {
  readonly type = EVENT.BUY_CART_UPDATE_CART_ITEM_REQUEST;
  constructor(
    public taskId: string,
    public cartItem: IShoppingProductRequest,
    public cartType: string,
    public onSucceeded?: (response: any) => void,
    public onError?: (error: any) => void,
  ) {}
}
export class ShoppingBuyUpdateCartItemResponseAction implements Action {
  readonly type = EVENT.BUY_CART_UPDATE_CART_ITEM_RECEIVED;
  constructor(public taskId: string, public data: IShoppingCart) {}
}
// ShoppingBuySaveToLaterCartItem
export class ShoppingBuySaveToLaterCartItemRequestAction implements Action {
  readonly type = EVENT.BUY_CART_SAVE_CART_ITEM_REQUEST;
  constructor(public taskId: string, public cartItem: IShoppingProductRequest) {}
}
export class ShoppingBuySaveToLaterCartItemResponseAction implements Action {
  readonly type = EVENT.BUY_CART_SAVE_CART_ITEM_RECEIVED;
  constructor(public taskId: string, public data: IShoppingProductCards[]) {}
}
// ShoppingBuyUpdateSavedCartItem
export class ShoppingBuyUpdateSavedCartItemRequestAction implements Action {
  readonly type = EVENT.BUY_CART_UPDATE_SAVED_CART_ITEM_REQUEST;
  constructor(public taskId: string, public cartItem: IShoppingProductRequest) {}
}
export class ShoppingBuyUpdateSavedCartItemResponseAction implements Action {
  readonly type = EVENT.BUY_CART_UPDATE_SAVED_CART_ITEM_RECEIVED;
  constructor(public taskId: string, public data: IShoppingCart) {}
}
export class ShoppingBuyUpdateSavedCartItemErrorAction implements Action {
  readonly type = EVENT.BUY_CART_UPDATE_SAVED_CART_ITEM_FAILED;
  constructor(public taskId: string, public error: IResponseError) {}
}
// ShoppingBuyDeleteSavedCartItem
export class ShoppingBuyDeleteSavedCartItemRequestAction implements Action {
  readonly type = EVENT.BUY_CART_DELETE_SAVED_CART_ITEM_REQUEST;
  constructor(public taskId: string, public cartItemId: string, public sellerId: string) {}
}
export class ShoppingBuyDeleteSavedCartItemResponseAction implements Action {
  readonly type = EVENT.BUY_CART_DELETE_SAVED_CART_ITEM_RECEIVED;
  constructor(public taskId: string, public data: IShoppingProductCards[]) {}
}
// ShoppingBuyGetPromo
export class ShoppingBuyGetPromoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_GET_PROMO_REQUEST;
  constructor(public taskId: string) {}
}
export class ShoppingBuyGetPromoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_GET_PROMO_RECEIVED;
  constructor(public taskId: string, public data: IShoppingBuyPromoResponse[]) {}
}
// ShoppingBuyApplyPromo
export class ShoppingBuyApplyPromoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_APPLY_PROMO_REQUEST;
  constructor(
    public taskId: string,
    public promoCode: string,
    public cartType: string,
    public onSuccess: () => void,
    public onError: (error) => void,
  ) {}
}
export class ShoppingBuyApplyPromoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_APPLY_PROMO_RECEIVED;
  constructor(public taskId: string, public data: IShoppingCart) {}
}
export class ShoppingBuyRemovePromoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_REMOVE_PROMO_REQUEST;
  constructor(
    public taskId: string,
    public promoCode: string,
    public cartType: string,
    public onSucceeded: () => void,
  ) {}
}
export class ShoppingBuyRemovePromoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_REMOVE_PROMO_RECEIVED;
  constructor(public taskId: string, public data: IShoppingCart) {}
}

// ShoppingBuyGetPickupLocations
export class ShoppingBuyGetPickupLocationsRequestAction implements Action {
  readonly type = EVENT.BUY_CART_GET_PICKUP_LOCATION_REQUEST;
  constructor(
    public taskId: string,
    public cartType: string,
    public fulfillmentId: string,
    public deliveryMethod: string,
  ) {}
}
export class ShoppingBuyGetPickupLocationsResponseAction implements Action {
  readonly type = EVENT.BUY_CART_GET_PICKUP_LOCATION_RECEIVED;
  constructor(public taskId: string, public data: IShoppingBuyPickupLocationResponse[]) {}
}
// ShoppingBuyDeliveryInfo
export class ShoppingBuyDeliveryInfoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_DELIVERY_INFO_REQUEST;
  constructor(
    public taskId: string,
    public deliverInfo: IShoppingBuyDeliveryInfoRequest,
    public cartType: string,
    public redirect: () => void,
  ) {}
}
export class ShoppingBuyDeliveryInfoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_DELIVERY_INFO_RECEIVED;
  constructor(public taskId: string, public data: IShoppingCart) {}
}

// ShoppingBuyReserveInfo
export class ShoppingBuyReserveInfoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_RESERVE_INFO_REQUEST;
  constructor(
    public taskId: string,
    public reserveInfo: IShoppingReserveInfoRequest,
    public cartType: string,
    public redirect: () => void,
    public onSucceeded?: () => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyReserveInfoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_RESERVE_INFO_RECEIVED;
  constructor(public taskId: string, public data: IShoppingReserveInfoResponse) {}
}

// createOrder
export class ShoppingBuyCreateOrderRequestAction implements Action {
  readonly type = EVENT.BUY_CART_CREATE_ORDER_REQUEST;
  constructor(
    public taskId: string,
    public cartType: string,
    public onSucceeded?: (data: IShoppingOrderDetailResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyCreateOrderResponseAction implements Action {
  readonly type = EVENT.BUY_CART_CREATE_ORDER_RECEIVED;

  constructor(public taskId: string, public data: IShoppingOrderDetailResponse) {}
}

export class ShoppingBuyCreateOrderErrorAction extends HttpErrorAction {
  override readonly type = EVENT.BUY_CART_CREATE_ORDER_FAILED;
}

// payByWallet
export class ShoppingBuyPayByWalletRequestAction implements Action {
  readonly type = EVENT.BUY_CART_PAY_BY_WALLET_REQUEST;
  constructor(
    public taskId: string,
    public payload: IShoppingPayWalletRequest,
    public orderNumber: string,
    public onSucceeded?: (data: IShoppingPayWalletResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyPayByWalletResponseAction implements Action {
  readonly type = EVENT.BUY_CART_PAY_BY_WALLET_RECEIVED;
  constructor(public taskId: string, public data: IShoppingPayWalletResponse) {}
}

// payByCard
export class ShoppingBuyPayByCardRequestAction implements Action {
  readonly type = EVENT.BUY_CART_PAY_BY_CARD_REQUEST;
  constructor(
    public taskId: string,
    public payload: IShoppingPayCardRequest,
    public orderNumber: string,
    public onSucceeded?: (data: IPayByCardResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyPayByCardResponseAction implements Action {
  readonly type = EVENT.BUY_CART_PAY_BY_CARD_RECEIVED;
  constructor(public taskId: string, public data: IPayByCardResponse) {}
}

// orderInfo
export class ShoppingBuyOrderInfoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_ORDER_INFO_REQUEST;
  constructor(
    public taskId: string,
    public orderNumber: string,
    public onSucceeded?: (data: IShoppingOrderPaymentResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyOrderInfoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_ORDER_INFO_RECEIVED;

  constructor(public taskId: string, public data: IShoppingOrderPaymentResponse) {}
}

// orderDetails
export class ShoppingBuyOrderDetailRequestAction implements Action {
  readonly type = EVENT.BUY_CART_ORDER_DETAIL_REQUEST;
  constructor(
    public taskId: string,
    public orderNumber: string,
    public onSucceeded?: (data: IShoppingOrderDetailResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyOrderDetailResponseAction implements Action {
  readonly type = EVENT.BUY_CART_ORDER_DETAIL_RECEIVED;

  constructor(public taskId: string, public data: IShoppingOrderDetailResponse) {}
}

// paymentInfo
export class ShoppingBuyPaymentInfoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_PAYMENT_INFO_REQUEST;

  constructor(
    public taskId: string,
    public paymentId: string,
    public onSucceeded?: (data: IShoppingPaymentInfoResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyPaymentInfoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_PAYMENT_INFO_RECEIVED;

  constructor(public taskId: string, public data: IShoppingPaymentInfoResponse) {}
}

// ShoppingBuyMoveCartItem
export class ShoppingBuyMoveCartItemRequestAction implements Action {
  readonly type = EVENT.BUY_CART_MOVE_CART_ITEM_REQUEST;

  constructor(public taskId: string, public cartItem: IShoppingProductRequest, public cartType: string) {}
}

export class ShoppingBuyMoveCartItemResponseAction implements Action {
  readonly type = EVENT.BUY_CART_MOVE_CART_ITEM_RECEIVED;

  constructor(public taskId: string, public data: IShoppingProductCards[]) {}
}
// ShoppingBuyGetDeliveryLocations
export class ShoppingBuyGetDeliveryLocationsRequestAction implements Action {
  readonly type = EVENT.BUY_CART_GET_DELIVER_LOCATION_REQUEST;
  constructor(public taskId: string) {}
}
export class ShoppingBuyGetDeliveryLocationsResponseAction implements Action {
  readonly type = EVENT.BUY_CART_GET_DELIVER_LOCATION_RECEIVED;
  constructor(public taskId: string, public data: IShoppingAddress[]) {}
}
export class ShoppingBuyGetDeliveryLocationsErrorAction implements Action {
  readonly type = EVENT.BUY_CART_GET_DELIVER_LOCATION_FAILED;
  constructor(public taskId: string, public error: IResponseError) {}
}
// ShoppingBuySetBillingAddress
export class ShoppingBuySetBillingAddressAction implements Action {
  readonly type = EVENT.BUY_CART_SET_BILLING_ADDRESS;
  constructor(public taskId: string, public data: IShoppingAddress) {}
}
// ShoppingBuyCartCleanAction
export class ShoppingBuyCartCleanActionAction implements Action {
  readonly type = EVENT.BUY_CART_CLEAN;
  constructor(public taskId: string) {}
}

// ShoppingBuyFetchReturnPolicyInfo
export class ShoppingBuyFetchReturnPolicyInfoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_RETURN_POLICY_INFO_REQUEST;
  constructor(public taskId: string, public onSucceeded: (response: IUrlInfoResponse) => void) {}
}
export class ShoppingBuyFetchReturnPolicyInfoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_RETURN_POLICY_INFO_RECEIVED;
  constructor(public taskId: string, public data: IUrlInfoResponse) {}
}

// ShoppingBuyFetchTermsAndConditionsInfo
export class ShoppingBuyFetchTermsAndConditionsInfoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_TERMS_AND_CONDITIONS_INFO_REQUEST;
  constructor(public taskId: string, public onSucceeded: (response: IUrlInfoResponse) => void) {}
}
export class ShoppingBuyFetchTermsAndConditionsInfoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_TERMS_AND_CONDITIONS_INFO_RECEIVED;
  constructor(public taskId: string, public data: IUrlInfoResponse) {}
}

// ShoppingBuyFetchPaymentReservationInfo
export class ShoppingBuyFetchPaymentReservationInfoRequestAction implements Action {
  readonly type = EVENT.BUY_CART_PAYMENT_RESERVATION_INFO_REQUEST;
  constructor(public taskId: string, public onSucceeded: (response: IUrlInfoResponse) => void) {}
}
export class ShoppingBuyFetchPaymentReservationInfoResponseAction implements Action {
  readonly type = EVENT.BUY_CART_PAYMENT_RESERVATION_INFO_RECEIVED;
  constructor(public taskId: string, public data: IUrlInfoResponse) {}
}

// ShoppingBuyFetchDeliveryOptions
export class ShoppingBuyFetchDeliveryOptionsRequestAction implements Action {
  readonly type = EVENT.BUY_CART_DELIVERY_OPTIONS_REQUEST;
  constructor(public taskId: string, public cartType: string, public location: IPosition) {}
}
export class ShoppingBuyFetchDeliveryOptionsResponseAction implements Action {
  readonly type = EVENT.BUY_CART_DELIVERY_OPTIONS_RECEIVED;
  constructor(public taskId: string, public data: IShoppingFulfillmentDeliveryOptions[]) {}
}

export class ShoppingUpdateDeliveryOptionRequestAction implements Action {
  readonly type = EVENT.BUY_CART_UPDATE_DELIVERY_OPTIONS_REQUEST;
  constructor(
    public taskId: string,
    public cartType: string,
    public fulfillmentId: string,
    public delivery: IShoppingFulfillmentPatch,
    public onSucceed?: (data: IShoppingCart) => void,
  ) {}
}

export class ShoppingUpdateDeliveryOptionResponseAction implements Action {
  readonly type = EVENT.BUY_CART_UPDATE_DELIVERY_OPTIONS_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

export class ShoppingMyCartSavedItemsRequestAction implements Action {
  readonly type = EVENT.BUY_CART_SAVED_ITEMS_REQUEST;
  constructor(public taskId: string) {}
}

export class ShoppingMyCartSavedItemsResponseAction implements Action {
  readonly type = EVENT.BUY_CART_SAVED_ITEMS_RECEIVED;
  constructor(public taskId: string, public data: IShoppingProductCards[]) {}
}

// ShoppingBuyUpdateCartGift
export class ShoppingBuyUpdateCartGiftRequestAction implements Action {
  readonly type = EVENT.BUY_CART_UPDATE_CART_GIFT_REQUEST;
  constructor(
    public taskId: string,
    public updateCartGiftOption: IShoppingUpdateCartGiftOptionRequest,
    public cartType: string,
    public onSucceeded?: () => void,
  ) {}
}
export class ShoppingBuyUpdateCartGiftResponseAction implements Action {
  readonly type = EVENT.BUY_CART_UPDATE_CART_GIFT_RECEIVED;
  constructor(public taskId: string) {}
}

// ShoppingTvLicenceCheck
export class ShoppingTvLicenceCheckRequest implements Action {
  readonly type = EVENT.TV_LICENCE_CHECK_REQUEST;
  constructor(
    public taskId: string,
    public request: IShoppingTvLicenceCheckRequest,
    public onSucceed: (response: IShoppingTvLicenceCheckResponse) => void,
  ) {}
}
export class ShoppingTvLicenceCheckSucceeded implements Action {
  readonly type = EVENT.TV_LICENCE_CHECK_SUCCEEDED;
  constructor(public taskId: string) {}
}

// ShoppingPostAsset
export class ShoppingUploadAssetRequest implements Action {
  readonly type = EVENT.UPLOAD_ASSET_REQUEST;
  constructor(public taskId: string, public file: FormData, public onSucceed: (response: IImageFileResponse) => void) {}
}
export class ShoppingUploadAssetSucceeded implements Action {
  readonly type = EVENT.UPLOAD_ASSET_SUCCEEDED;
  constructor(public taskId: string, public response: IImageFileResponse) {}
}

// ShoppingAddTvLicencePhotoId
export class ShoppingAddTvLicencePhotoIdRequest implements Action {
  readonly type = EVENT.ADD_TV_LICENCE_PHOTO_ID_REQUEST;
  constructor(public taskId: string, public request: IShoppingTvLicencePhotoIdRequest) {}
}
export class ShoppingAddTvLicencePhotoIdSucceeded implements Action {
  readonly type = EVENT.ADD_TV_LICENCE_PHOTO_ID_SUCCEEDED;
  constructor(public taskId: string, public data: IShoppingCart) {}
}

// ShoppingBuySetTermInMonths
export class ShoppingBuySetTermInMonthsRequest implements Action {
  readonly type = EVENT.BUY_CART_SET_TERM_IN_MONTHS_REQUEST;
  constructor(public taskId: string, public cartType: string, public request: IShoppingSetTermInMonthsRequest) {}
}
export class ShoppingBuySetTermInMonthsResponse implements Action {
  readonly type = EVENT.BUY_CART_SET_TERM_IN_MONTHS_RECEIVED;
  constructor(public taskId: string, public data: IShoppingCart) {}
}

// ShoppingBuyClearCart
export class ShoppingBuyClearCartRequest implements Action {
  readonly type = EVENT.BUY_CART_CLEAR_REQUEST;

  constructor(public cartType: ShoppingCartType, public taskId: string) {}
}

export class ShoppingBuyClearCartResponse implements Action {
  readonly type = EVENT.BUY_CART_CLEAR_RECEIVED;

  constructor(public taskId: string, public data: IShoppingCart) {}
}

//cart v3
// AddCartItem
export class ShoppingBuyerAddCartItemRequestAction implements Action {
  readonly type = EVENT.BUYER_CART_ADD_CART_ITEM_REQUEST;

  constructor(
    public taskId: string,
    public request: IShoppingBuyerAddItemRequest,
    public onSucceeded?: (response: IShoppingBuyerAddItemResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyerAddCartItemResponseAction implements Action {
  readonly type = EVENT.BUYER_CART_ADD_CART_ITEM_RECEIVED;

  constructor(public taskId: string, public data: IShoppingBuyerAddItemResponse) {}
}

// CartSummary
export class ShoppingBuyerCartSummaryRequestAction implements Action {
  readonly type = EVENT.BUYER_CART_SUMMARY_REQUEST;

  constructor(
    public taskId: string,
    public onSucceed?: (data: IShoppingBuyerCartSummary) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyerCartSummaryResponseAction implements Action {
  readonly type = EVENT.BUYER_CART_SUMMARY_RECEIVED;

  constructor(public taskId: string, public data: IShoppingBuyerCartSummary) {}
}

// Carts
export class ShoppingBuyerCartsRequestAction implements Action {
  readonly type = EVENT.BUYER_CARTS_REQUEST;

  constructor(
    public taskId: string,
    public onSucceed?: (data: IShoppingBuyerCart[]) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyerCartsResponseAction implements Action {
  readonly type = EVENT.BUYER_CARTS_RECEIVED;

  constructor(public taskId: string, public data: IShoppingBuyerCart[]) {}
}

// Cart detail
export class ShoppingBuyerCartDetailRequestAction implements Action {
  readonly type = EVENT.BUYER_CART_DETAIL_REQUEST;

  constructor(
    public taskId: string,
    public cartId: string,
    public onSucceed?: (data: IShoppingBuyerCartDetail) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyerCartDetailResponseAction implements Action {
  readonly type = EVENT.BUYER_CART_DETAIL_RECEIVED;

  constructor(public taskId: string, public data: IShoppingBuyerCartDetail) {}
}

// Cart item quantity
export class ShoppingBuyerCartItemQuantityRequestAction implements Action {
  readonly type = EVENT.BUYER_CART_ITEM_QUANTITY_REQUEST;

  constructor(
    public taskId: string,
    public cartId: string,
    public cartItemId: string,
    public request: IShoppingBuyerCartItemQuantityRequest,
    public onSucceed?: (data: IShoppingBuyerCartDetail) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyerCartItemQuantityResponseAction implements Action {
  readonly type = EVENT.BUYER_CART_ITEM_QUANTITY_RECEIVED;

  constructor(public taskId: string, public data: IShoppingBuyerCartDetail) {}
}

// Cart delivery address
export class ShoppingBuyerCartDeliveryAddressRequestAction implements Action {
  readonly type = EVENT.BUYER_CART_DELIVERY_ADDRESS_REQUEST;

  constructor(
    public taskId: string,
    public cartId: string,
    public request: IShoppingBuyerCartDeliveryAddressRequest,
    public onSucceed?: (data: IShoppingBuyerCartDetail) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyerCartDeliveryAddressResponseAction implements Action {
  readonly type = EVENT.BUYER_CART_DELIVERY_ADDRESS_RECEIVED;

  constructor(public taskId: string, public data: IShoppingBuyerCartDetail) {}
}

// Cart delivery option
export class ShoppingBuyerCartDeliveryOptionRequestAction implements Action {
  readonly type = EVENT.BUYER_CART_DELIVERY_OPTION_REQUEST;

  constructor(
    public taskId: string,
    public cartId: string,
    public fulfilmentId: string,
    public request: IShoppingBuyerCartDeliveryOptionRequest,
    public onSucceed?: (data: IShoppingBuyerCartDetail) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyerCartDeliveryOptionResponseAction implements Action {
  readonly type = EVENT.BUYER_CART_DELIVERY_OPTION_RECEIVED;

  constructor(public taskId: string, public data: IShoppingBuyerCartDetail) {}
}

// Pay cart
export class ShoppingBuyerPayCartRequestAction implements Action {
  readonly type = EVENT.BUYER_PAY_CART_REQUEST;

  constructor(
    public taskId: string,
    public cartId: string,
    public onSucceed?: (data: IShoppingBuyerCartPaymentDetails) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ShoppingBuyerPayCartResponseAction implements Action {
  readonly type = EVENT.BUYER_PAY_CART_RECEIVED;

  constructor(public taskId: string, public data: IShoppingBuyerCartPaymentDetails) {}
}

export type ShoppingBuyActions =
  | ShoppingAddTvLicencePhotoIdRequest
  | ShoppingAddTvLicencePhotoIdSucceeded
  | ShoppingUploadAssetRequest
  | ShoppingUploadAssetSucceeded
  | ShoppingTvLicenceCheckRequest
  | ShoppingTvLicenceCheckSucceeded
  | ShoppingBuyUpdateCartGiftRequestAction
  | ShoppingBuyUpdateCartGiftResponseAction
  | ShoppingBuyCartCleanActionAction
  | ShoppingBuySetBillingAddressAction
  | ShoppingBuyGetDeliveryLocationsErrorAction
  | ShoppingBuyGetDeliveryLocationsResponseAction
  | ShoppingBuyGetDeliveryLocationsRequestAction
  | ShoppingBuyMoveCartItemResponseAction
  | ShoppingBuyMoveCartItemRequestAction
  | ShoppingBuyCreateOrderResponseAction
  | ShoppingBuyCreateOrderRequestAction
  | ShoppingBuyCreateOrderErrorAction
  | ShoppingBuyPayByWalletResponseAction
  | ShoppingBuyPayByWalletRequestAction
  | ShoppingBuyPayByCardResponseAction
  | ShoppingBuyPayByCardRequestAction
  | ShoppingBuyOrderInfoRequestAction
  | ShoppingBuyOrderInfoResponseAction
  | ShoppingBuyOrderDetailRequestAction
  | ShoppingBuyOrderDetailResponseAction
  | ShoppingBuyPaymentInfoRequestAction
  | ShoppingBuyPaymentInfoResponseAction
  | ShoppingBuyReserveInfoResponseAction
  | ShoppingBuyReserveInfoRequestAction
  | ShoppingBuyDeliveryInfoResponseAction
  | ShoppingBuyDeliveryInfoRequestAction
  | ShoppingBuyFetchDeliveryOptionsRequestAction
  | ShoppingBuyFetchDeliveryOptionsResponseAction
  | ShoppingBuyGetPickupLocationsResponseAction
  | ShoppingBuyGetPickupLocationsRequestAction
  | ShoppingBuyRemovePromoResponseAction
  | ShoppingBuyRemovePromoRequestAction
  | ShoppingBuyApplyPromoResponseAction
  | ShoppingBuyApplyPromoRequestAction
  | ShoppingBuyGetPromoResponseAction
  | ShoppingBuyGetPromoRequestAction
  | ShoppingBuyDeleteSavedCartItemResponseAction
  | ShoppingBuyDeleteSavedCartItemRequestAction
  | ShoppingBuyUpdateSavedCartItemErrorAction
  | ShoppingBuyUpdateSavedCartItemResponseAction
  | ShoppingBuyUpdateSavedCartItemRequestAction
  | ShoppingBuySaveToLaterCartItemResponseAction
  | ShoppingBuySaveToLaterCartItemRequestAction
  | ShoppingBuyUpdateCartItemResponseAction
  | ShoppingBuyUpdateCartItemRequestAction
  | ShoppingBuyerAddCartItemRequestAction
  | ShoppingBuyerAddCartItemResponseAction
  | ShoppingBuyerCartSummaryRequestAction
  | ShoppingBuyerCartSummaryResponseAction
  | ShoppingBuyerCartsRequestAction
  | ShoppingBuyerCartsResponseAction
  | ShoppingBuyerCartDetailRequestAction
  | ShoppingBuyerCartDetailResponseAction
  | ShoppingBuyerCartItemQuantityRequestAction
  | ShoppingBuyerCartItemQuantityResponseAction
  | ShoppingBuyerCartDeliveryAddressRequestAction
  | ShoppingBuyerCartDeliveryAddressResponseAction
  | ShoppingBuyerCartDeliveryOptionRequestAction
  | ShoppingBuyerCartDeliveryOptionResponseAction
  | ShoppingBuyerPayCartRequestAction
  | ShoppingBuyerPayCartResponseAction
  | ShoppingBuyDeleteCartItemResponseAction
  | ShoppingBuyDeleteCartItemRequestAction
  | ShoppingBuyContentResponseAction
  | ShoppingBuyContentRequestAction
  | ShoppingBuyFetchReturnPolicyInfoRequestAction
  | ShoppingBuyFetchReturnPolicyInfoResponseAction
  | ShoppingBuyFetchTermsAndConditionsInfoRequestAction
  | ShoppingBuyFetchTermsAndConditionsInfoResponseAction
  | ShoppingBuyFetchPaymentReservationInfoRequestAction
  | ShoppingBuyFetchPaymentReservationInfoResponseAction
  | ShoppingUpdateDeliveryOptionRequestAction
  | ShoppingUpdateDeliveryOptionResponseAction
  | ShoppingMyCartSavedItemsRequestAction
  | ShoppingMyCartSavedItemsResponseAction
  | ShoppingBuySetTermInMonthsRequest
  | ShoppingBuySetTermInMonthsResponse
  | ShoppingBuyClearCartRequest
  | ShoppingBuyClearCartResponse;
