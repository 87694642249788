import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as actions from './assets.actions';
import { AssetsBucketsEnum, IAssetStatusResponse } from '../../models/assets.model';
import { started } from '../application/task.actions';

@Injectable()
export class AssetsFacade {
  constructor(private store: Store) {}

  uploadAsset = (
    data: FormData,
    bucket: AssetsBucketsEnum,
    onSucceed?: (response: IAssetStatusResponse) => void,
    onError?: (error: any) => void,
    message = 'Please wait while your file is being uploaded',
  ): void => {
    const taskId = `assets-upload-file-${JSON.stringify(data)}`;
    this.store.dispatch(
      started(new actions.AssetsUploadFileRequestAction(taskId, data, bucket, onSucceed, onError), message),
    );
  };

  uploadAssetWithProgress = (
    data: FormData,
    bucket: AssetsBucketsEnum,
    onSucceed?: (response: IAssetStatusResponse) => void,
    onError?: (error: any) => void,
    onProgress?: (percentage: number) => void,
    message = 'Please wait',
  ): void => {
    const taskId = `assets-upload-file-with-progress-${JSON.stringify(data)}`;
    this.store.dispatch(
      started(
        new actions.AssetsUploadFileProgressRequestAction(taskId, data, bucket, onSucceed, onError, onProgress),
        message,
      ),
    );
  };
}
