import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AIRTIME_DATA_FEATURE_KEY, IAirtimeDataState } from './airtimedata.reducer';

const getState = createFeatureSelector<IAirtimeDataState>(AIRTIME_DATA_FEATURE_KEY);

export const getRecentTransactions = createSelector(getState, (state: IAirtimeDataState) => state.recentTransactions);

export const getProviders = createSelector(getState, (state: IAirtimeDataState) => state.providers);

export const getCurrentOrder = createSelector(getState, (state: IAirtimeDataState) => state.currentOrder);

export const getPlacedOrder = createSelector(getState, (state: IAirtimeDataState) => state.placedOrder);
