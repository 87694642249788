import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({ name: 'chatDate', pure: true })
export class ChatMessageDatePipe implements PipeTransform {
  transform(value: number, format: string): string {
    if (!value) return '';
    let timestamp = value;
    if (timestamp.toString().length > 10) {
      timestamp = Number(timestamp.toString().slice(0, 10));
    }
    return dayjs.unix(timestamp).format(format || 'h:mmA');
  }
}
