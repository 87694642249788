export interface IB2bBenefitPanelItem {
  section: B2BLandingBenefitSections | B2BBusinessBuyingBenefitSections;
  title: string;
  image?: string;
  items: { icon: string; text: string; comingSoon?: boolean }[];
}

export enum B2BLandingBenefitSections {
  DIGITISE = 'DIGITISE',
  STREAMLINE = 'STREAMLINE',
  EASY_SETUP = 'EASY_SETUP',
  CONTROLS = 'CONTROLS',
  MANAGEMENT = 'MANAGEMENT',
  TOOLS = 'TOOLS',
  UNLOCK = 'UNLOCK',
  MARKET = 'MARKET',
}

export enum B2BBusinessBuyingBenefitSections {
  UNLOCK_VARIETY = 'UNLOCK_VARIETY',
  SIMPLIFY_BUYING = 'SIMPLIFY_BUYING',
  PROCUREMENT_PRACTICES = 'PROCUREMENT_PRACTICES',
  EFFECIENT_BUYING = 'EFFECIENT_BUYING',
  PAYMENT_OPTIONS = 'PAYMENT_OPTIONS',
  TOOLS = 'TOOLS',
}

export interface ISellingToBusiness {
  title: string;
  text?: string;
  items: ISellingToBusinessItem[];
}

export interface ISellingToBusinessItem {
  title: string;
  text: string;
  image: string;
}

export interface IB2cBenefits {
  title: string;
  text?: string;
  items: IB2cBenefitsItem[];
}

export interface IB2cBenefitsItem {
  icon: string;
  title: string;
  text: string;
}

export interface IMenuItem {
  title: string;
  link?: string;
  subitems?: IMenuItem[];
}

export interface ISetupStep {
  step: string;
  title: string;
  text: string;
}

export interface ISellingToBusinessStep {
  mainIcon: string;
  title: string;
  text: string;
}

export interface IBusinessOwner {
  image: string;
  primaryText: string;
  secondaryText: string;
  link: string;
}

export interface ISupportFaqQuestion {
  title: string;
  questions: IFaqQuestion[];
}

export interface IFaqQuestion {
  question: string;
  answer?: string;
  answers?: string[];
}

export interface IBasicTable {
  title: string;
  hint?: string[];
  info?: string[];
  rows: IRow[];
}

export interface IRow {
  data: string[];
  type?: RowType;
}

export enum RowType {
  header = 'header',
}

export interface ITreeSetupStep {
  stepNumber?: number;
  title: string;
  text: string;
  align: TreeSetupStepAlignType;
  hint?: string;
}

export enum TreeSetupStepAlignType {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export enum BusinessChoiceType {
  B2B = 'B2B',
  B2C = 'B2C',
  BUYER = 'BUYER',
}

export interface IContactSupportRequest {
  organisationName: string;
  contactName: string;
  email: string;
  phone: string;
  text?: string;
}
