import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'formError',
  pure: true,
})
export class HandleFormErrorPipe implements PipeTransform {
  transform(error: ValidationErrors): string {
    if (!error) return null;

    const {
      required,
      pattern,
      min,
      max,
      moreThan,
      lessThan,
      minlength,
      invalidPhoneNumber,
      invalidEmail,
      email,
      maxlength,
      invalidSaId,
      invalidCapitalCase,
      invalidSmallCase,
      invalidNoSpace,
      invalidSpecialCharacters,
      noPasswordMatch,
      invalidBusinessRegistrationNumber,
      invalidMeterNumber,
      invalidCouponCode,
      invalidNatureOfBusiness,
      invalidNatureOfBusinessMinLength,
      invalidNatureOfBusinessMaxLength,
      invalidPasswordMinLength,
      invalidPasswordMaxLength,
      whitespace,
      customError,
      invalidNumber,
      invalidPassword,
      invalidAvoEmail,
      notANumber,
      notANumberWithDot,
      invalidAlphabet,
      invalidBusinessDetailName,
      invalidHomeloandIdNumber,
      invalidPostalCode,
      alphanumericCharactersOnly,
      invalidHexColor,
      valueIsNotANUmber,
      invalidUsername,
      invalidProductId,
      ownPhone,
      displayNameEmpty,
      displayNameShort,
      displayNameLong,
      invalidVatNumber,
      lowerThanB2bMinPurchasedUnits,
    } = error;

    if (required) return 'general.field_required';

    if (pattern) return `Invalid character - ${pattern.actualValue}`;

    if (min) return `Minimum is ${min.min}`;

    if (max) return `Maximum is ${max.max}`;

    if (moreThan) return 'validation.more_than';

    if (lessThan) return `Value is too high. Maximum is ${lessThan.max}`;

    if (minlength) return `You filled ${minlength.actualLength} from ${minlength.requiredLength}`;

    if (maxlength) return `You filled ${maxlength.actualLength} from ${maxlength.requiredLength}`;

    if (invalidPasswordMinLength) return `validation.password.format`;

    if (invalidPasswordMaxLength) return `validation.password.too_long`;

    if (invalidPhoneNumber) return `validation.phone.format`;

    if (invalidEmail || email) return 'onboarding.start.email.error';

    if (invalidSaId) return 'validation.sa_id.error';

    if (invalidSpecialCharacters) return 'validation.password.special_character';

    if (invalidSmallCase) return 'validation.password.lower_case';

    if (invalidCapitalCase) return 'validation.password.upper_case';

    if (invalidNoSpace) return 'validation.username.pattern';

    if (noPasswordMatch) return 'onboarding.login.password_mismatch';

    if (invalidBusinessRegistrationNumber) return `onboarding.start.merchant.business_registration_number_hint`;

    if (invalidMeterNumber) return `prepaid.electricity.invalid_meter_error`;

    if (invalidCouponCode) return `eshop.promo.invalid_promo_code`;

    if (invalidNatureOfBusiness) return `validation.business_details.nature.error_character`;

    if (invalidNatureOfBusinessMinLength) return 'validation.business_details.nature.error_less';

    if (invalidNatureOfBusinessMaxLength) return 'validation.business_details.nature.error_more';

    if (invalidPassword) return 'validation.password.specific_character';

    if (invalidNumber) {
      return `${invalidNumber.customLabel ? invalidNumber.customLabel : 'Amount must be greater than zero'}`;
    }

    if (whitespace) return `general.field_required`;

    if (invalidAvoEmail) return invalidAvoEmail.text;

    if (notANumber) return `validation.not_a_number`;

    if (notANumberWithDot) return `validation.numeric_plus_dot`;

    if (invalidAlphabet) return 'onboarding.business_details.alphabetic';

    if (invalidBusinessDetailName) return 'onboarding.business_details.unsupported';

    if (invalidHomeloandIdNumber) return 'home_loan.userinfo.invalid_id';

    if (invalidPostalCode) return 'validation.invalid_zip_code';

    if (alphanumericCharactersOnly) return 'validation.alphanumerics_only';

    if (invalidHexColor) return 'validation.invalid_rgba_hex';

    if (valueIsNotANUmber) return 'validation.value_is_not_a_number';

    if (invalidUsername) return 'validation.username.forbidden_character';

    if (invalidProductId) return 'validation.product.id_special.characters';

    if (ownPhone) return 'wallet.pay.form.own_phone_warning';

    if (customError) return customError;

    if (displayNameEmpty) return 'validation.display_name.empty';

    if (displayNameShort) return 'validation.display_name.too_short';

    if (displayNameLong) return 'validation.display_name.too_long';

    if (invalidVatNumber) return 'validation.vat_number.invalid';

    if (lowerThanB2bMinPurchasedUnits) return 'validation.minimum_order_quantity.invalid';

    return 'Unhandled form rejection.';
  }
}
