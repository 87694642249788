import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';
import keys from 'lodash-es/keys';
import map from 'lodash-es/map';

@Pipe({ name: 'inputError', pure: true })
export class InputErrorPipe implements PipeTransform {
  transform = (value: ValidationErrors): any[] => {
    if (!value || isEmpty(value)) return [];

    return map(keys(value), key => ({ [key]: value[key] }));
  };
}
