import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdobeModule, AdobeService } from '@wakanda/adobe-analytics';
import { TranslateFacade, TranslateStoreModule } from '@wakanda/zentity-translate-store';
import { adobeConfig } from './configs/module.config';
import { appConfigFactory } from './factories/appConfig.factory';
import { AppInitService } from './services/init/app-init.service';
import { CreateAvoBusinessStoreModule } from './store/create-store.module';
import { HttpClientModule } from '@angular/common/http';
import { AppConfigFacade, AppConfigService, ENVIRONMENT, WakandaCoreModule } from '@wakanda/wakanda-core';
import { ModalService } from './services/modal/modal.service';
import { environment } from '@wakanda/environment-avo-business/environment';

@NgModule({
  imports: [
    WakandaCoreModule,
    CommonModule,
    HttpClientModule,
    CreateAvoBusinessStoreModule,
    TranslateStoreModule.forRoot(),
    AdobeModule.forRoot(adobeConfig),
  ],
})
export class AvoBusinessCoreModule {
  static forRoot(): ModuleWithProviders<AvoBusinessCoreModule> {
    return {
      ngModule: AvoBusinessCoreModule,
      providers: [
        AppInitService,
        AppConfigService,
        {
          provide: APP_INITIALIZER,
          useFactory: appConfigFactory,
          deps: [AppInitService, TranslateFacade, AdobeService, AppConfigFacade],
          multi: true,
        },
        ModalService,
        { provide: ENVIRONMENT, useValue: environment },
      ],
    };
  }
}
