import { Action } from '@ngrx/store';
import { WSS_EVENTS } from './websockets.events';

export class WebsocketsChatFetchTokenRequest implements Action {
  readonly type = WSS_EVENTS.WSS_CHAT_TOKEN_REQUEST;
  constructor(public endpoint: string) {}
}

export class WebsocketsChatFetchTokenReceived implements Action {
  readonly type = WSS_EVENTS.WSS_CHAT_TOKEN_RECEIVED;
  constructor(public endpointWithToken: string) {}
}

export class WebsocketsError implements Action {
  readonly type = WSS_EVENTS.WSS_REQUEST_ERROR;
  constructor(public error: any) {}
}
