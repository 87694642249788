import { Action } from '@ngrx/store';
import { IAuthenticators, IResponseError, IZenAuthAccessToken } from '@wakanda/wakanda-core';
import {
  IAuthOTPRequest,
  IAuthOTPResendRequest,
  IAuthPasswordlessLoginStartRequest,
  IAuthProfileRequest,
  IAuthRefreshTokenStartRequest,
  IAuthSocialLoginStartRequest,
  IZenAuthAuthenticateRequest,
  IZenAuthBankOtpRequest,
  IZenAuthBasicDataRequest,
  IZenAuthBusinessAddressRequest,
  IZenAuthBusinessBasicDataRequest,
  IZenAuthBusinessLegalEntityRequest,
  IZenAuthCheckBankRequest,
  IZenAuthIdPassportNumberRequest,
  IZenAuthLegalEntityRequest,
  IZenAuthLoginRequest,
  IZenAuthOnboardingAddressRequest,
  IZenAuthOnboardingApproveItRequest,
  IZenAuthOnboardingBankphoneDifferentRequest,
  IZenAuthOnboardingBusinessCategoryInfoRequest,
  IZenAuthOnboardingBusinessDetailsRequest,
  IZenAuthOnboardingBusinessPartnerRequest,
  IZenAuthOnboardingBusinessTradingNameRequest,
  IZenAuthOnboardingExternalAuthRequest,
  IZenAuthOnboardingFirstNameLastNameAuthRequest,
  IZenAuthOnboardingIdPhotoRequest,
  IZenAuthOnboardingLinkAccountRequest,
  IZenAuthOnboardingLoginRequest,
  IZenAuthOnboardingMobiMoneyRequest,
  IZenAuthOnboardingOtpRequest,
  IZenAuthOnboardingOutstandingTCsRequest,
  IZenAuthOnboardingPersonalDataRequest,
  IZenAuthOnboardingProgressRequest,
  IZenAuthOnboardingReferralDetail,
  IZenAuthOnboardingSecurityQuestionsRequest,
  IZenAuthOnboardingSelectNedbankRequest,
  IZenAuthOnboardingSelectOtpRequest,
  IZenAuthOnboardingSelectSocialRequest,
  IZenAuthOnboardingSentApproveItRequest,
  IZenAuthOnboardingSignupManualRequest,
  IZenAuthOnboardingSignupRequest,
  IZenAuthOnboardingStartInfoRequest,
  IZenAuthOnboardingStartLinkAccountRequest,
  IZenAuthOnboardingStartManualRequest,
  IZenAuthOnboardingStartMoneyAppRequest,
  IZenAuthOnboardingStartRequest,
  IZenAuthOnboardingStartSocialRequest,
  IZenAuthOnboardingStepupRequest,
  IZenAuthOnboardingSuccessRequest,
  IZenAuthOnboardingTCsRequest,
  IZenAuthSocialByRequest,
  IZenAuthTransactionSigningAuthorizationHeaders,
  IZenAuthTransactionSigningAuthorizeRequest,
} from '../models/requests.model';
import {
  IAuthNextStep,
  IZenAuthAuthenticateResponse,
  IZenAuthInfoStepUpRequest,
  IZenAuthNextStepTransactionSigningResponse,
  IZenAuthOnboardingAddressSuggestResponse,
  IZenAuthOnboardingAvoClientInfo,
  IZenAuthOnboardingBankClientInfo,
  IZenAuthOnboardingCountry,
  IZenAuthOnboardingNextStepResponse,
  IZenAuthPersonalInformationEditEmailRequest,
  IZenAuthPersonalInformationResponse,
  IZenAuthSignupInfoResponse,
  IZenGuestLoginResponse,
} from '../models/responses.model';
import { EVENT } from './zenAuth.events';
import { IErrorResponse } from '@avo/shared/core';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * An action dispatched when a start request is fired.
 */
export class StartRequestAction implements Action {
  readonly type = EVENT.LOGIN_START_REQUEST;

  constructor(public taskId: string, public onSucceeded: (response: IZenAuthAuthenticateResponse) => void) {}
}

/**
 * An action dispatched when the zenauth has data from API.
 */
export class StartResponseAction implements Action {
  readonly type = EVENT.LOGIN_START_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthAuthenticateResponse) {}
}

export class StartSocialRequestAction implements Action {
  readonly type = EVENT.LOGIN_START_SOCIAL_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingStartSocialRequest,
    public onSucceeded: (response: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

/**
 * An action dispatched when the zenauth has data from API.
 */
export class StartSocialResponseAction implements Action {
  readonly type = EVENT.LOGIN_START_SOCIAL_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

/**
 * Payload of ZENAUTH_START_ERROR.
 */
export interface RouterErrorPayload {
  error: any;
}

/**
 * An action dispatched when the start errors.
 */
export class ZenAuthErrorAction implements Action {
  readonly type = EVENT.LOGIN_START_FAILED;
  constructor(public taskId: string, public error: IResponseError, public hidden?: boolean) {}
}

export class ZentAuthAuthenticateWithToken implements Action {
  readonly type = EVENT.LOGIN_AUTHENTICATE_WITH_TOKEN;
  constructor(public taskId: string, public authRequest: IZenAuthAuthenticateRequest) {}
}

export class ZenAuthAuthenticateResponse implements Action {
  readonly type = EVENT.LOGIN_AUTHENTICATE_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthAuthenticateResponse) {}
}

export class ZenAuthAuthenticateError implements Action {
  readonly type = EVENT.LOGIN_AUTHENTICATE_FAILED;
  constructor(public taskId: string, public error: any) {}
}

export class ZenAuthLogoutRequestAction implements Action {
  readonly type = EVENT.LOGOUT_REQUEST;
  constructor(public taskId: string, public onSucceed?: () => void, public afterSucceed?: () => void) {}
}

export class ZenAuthLogoutResponseAction implements Action {
  readonly type = EVENT.LOGOUT_RECEIVED;
  constructor(public taskId: string) {}
}

export class ZenAuthLoginMobimoneyRequestAction implements Action {
  readonly type = EVENT.LOGIN_MOBIMONEY_REQUEST;
  constructor(public taskId: string, public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void) {}
}

export class ZenAuthLoginMobimoneyResponseAction implements Action {
  readonly type = EVENT.LOGIN_MOBIMONEY_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthLoginNedidRequestAction implements Action {
  readonly type = EVENT.LOGIN_NEDID_REQUEST;
  constructor(public taskId: string, public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void) {}
}

export class ZenAuthLoginNedidResponseAction implements Action {
  readonly type = EVENT.LOGIN_NEDID_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingStartRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_START_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingStartRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingStartResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_START_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingStartInfoRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_START_INFO_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingStartInfoRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingStartInfoResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_START_INFO_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingStartMoneyAppRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_START_MONEY_APP_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingStartMoneyAppRequest,
    public onSucceed: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: () => void,
  ) {}
}

export class ZenAuthOnboardingStartMoneyAppResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_START_MONEY_APP_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingStartMoneyAppAsyncRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_START_MONEY_APP_ASYNC_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingStartMoneyAppRequest,
    public onSucceed: (start: IZenAuthOnboardingAvoClientInfo) => void,
    public onError?: () => void,
  ) {}
}

export class ZenAuthOnboardingStartMoneyAppAsyncResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_START_MONEY_APP_ASYNC_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingAvoClientInfo) {}
}

export class ZenAuthOnboardingBankInfoRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BANK_INFO_REQUEST;
  constructor(
    public taskId: string,
    public onSucceed?: (start: IZenAuthOnboardingBankClientInfo) => void,
    public onError?: () => void,
  ) {}
}

export class ZenAuthOnboardingBankInfoResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BANK_INFO_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingBankClientInfo) {}
}

export class ZenAuthOnboardingAvoInfoRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_AVO_INFO_REQUEST;
  constructor(
    public taskId: string,
    public onSucceed?: (start: IZenAuthOnboardingAvoClientInfo) => void,
    public onError?: () => void,
  ) {}
}

export class ZenAuthOnboardingAvoInfoResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_AVO_INFO_RECEIVED;

  constructor(public taskId: string, public payload: IZenAuthOnboardingAvoClientInfo) {}
}

export class ZenAuthOnboardingEditPersonalDetailsEmailRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_EDIT_PERSONAL_DETAILS_EMAIL_REQUEST;

  constructor(
    public taskId: string,
    public request: IZenAuthPersonalInformationEditEmailRequest,
    public onSucceed?: (start: IZenAuthPersonalInformationResponse) => void,
    public onError?: () => void,
  ) {}
}

export class ZenAuthOnboardingEditPersonalDetailsEmailResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_EDIT_PERSONAL_DETAILS_EMAIL_RECEIVED;

  constructor(public taskId: string, public payload: IZenAuthPersonalInformationResponse) {}
}

export class ZenAuthOnboardingStartSocialRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_START_SOCIAL_REQUEST;

  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingStartSocialRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ) {}
}

export class ZenAuthOnboardingStartSocialResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_START_SOCIAL_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingStartManualRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_START_MANUAL_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingStartManualRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ) {}
}

export class ZenAuthOnboardingStartManualResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_START_MANUAL_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingOtpRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_OTP_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingOtpRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingOtpResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_OTP_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingLinkAccountRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_LINK_ACCOUNT_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingLinkAccountRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingLinkAccountResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_LINK_ACCOUNT_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingStartLinkAccountRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_START_LINK_ACCOUNT_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingStartLinkAccountRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingStartLinkAccountResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_START_LINK_ACCOUNT_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingInitiateLinkAccountRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_INITIATE_LINK_ACCOUNT_REQUEST;
  constructor(
    public taskId: string,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingInitiateLinkAccountResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_INITIATE_LINK_ACCOUNT_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingExternalAuthRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_EXTERNAL_AUTH_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingExternalAuthRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ) {}
}

export class ZenAuthOnboardingExternalAuthResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_EXTERNAL_AUTH_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingIdPassportRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_ID_PASSPORT_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthIdPassportNumberRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingIdPassportResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_ID_PASSPORT_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSelectOtpRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SELECT_OTP_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSelectOtpRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingSelectOtpResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SELECT_OTP_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSelectSocialRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SELECT_SOCIAL_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSelectSocialRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingSelectSocialResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SELECT_SOCIAL_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSelectNedbankRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SELECT_NEDBANK_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSelectNedbankRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingSelectNedbankResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SELECT_NEDBANK_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSignupRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SIGNUP_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSignupRequest,
    public onSucceeded?: (start: IZenAuthSignupInfoResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingSignupResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SIGNUP_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthSignupInfoResponse) {}
}

export class ZenAuthOnboardingSignupSocialRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SIGNUP_SOCIAL_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSignupRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingSignupSocialResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SIGNUP_SOCIAL_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSignupNedbankRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SIGNUP_NEDBANK_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSignupRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingSignupNedbankResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SIGNUP_NEDBANK_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSignupManualRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SIGNUP_MANUAL_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSignupManualRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingSignupManualResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SIGNUP_MANUAL_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingOutstandingTcsRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_OUTSTANDNG_TCS_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingOutstandingTCsRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingOutstandingTcsResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_OUTSTANDNG_TCS_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingTcsRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_TCS_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingTCsRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingTcsResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_TCS_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingLegalEntityRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_LEGAL_ENTITY_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthLegalEntityRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingLegalEntityResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_LEGAL_ENTITY_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingBusinessLegalEntityRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_LEGAL_ENTITY_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthBusinessLegalEntityRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingBusinessLegalEntityResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_LEGAL_ENTITY_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingBasicDataRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BASIC_DATA_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthBasicDataRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingBasicDataResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BASIC_DATA_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingBusinessBasicDataRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_BASIC_DATA_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthBusinessBasicDataRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingBusinessBasicDataResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_BASIC_DATA_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingCountriesRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_COUNTRIES_REQUEST;
  constructor(public taskId: string, public onSucceeded?: (countries: IZenAuthOnboardingCountry[]) => void) {}
}

export class ZenAuthOnboardingCountriesResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_COUNTRIES_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingCountry[]) {}
}

export class ZenAuthOnboardingAddressRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_ADDRESS_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingAddressRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingAddressResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_ADDRESS_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingMobiMoneyRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_MOBIMONEY_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingMobiMoneyRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ) {}
}

export class ZenAuthOnboardingMobiMoneyResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_MOBIMONEY_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSecurityQuestionsRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SECURITY_QUESTIONS_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSecurityQuestionsRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ) {}
}

export class ZenAuthOnboardingSecurityQuestionsResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SECURITY_QUESTIONS_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingFirstNameLastNameAuthRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_FIRST_NAME_LAST_NAME_AUTH_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingFirstNameLastNameAuthRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingFirstNameLastNameAuthResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_FIRST_NAME_LAST_NAME_AUTH_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingBusinessTradingNameRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_TRADING_NAME_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingBusinessTradingNameRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingBusinessTradingNameResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_TRADING_NAME_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingPersonalDataRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_PERSONAL_DATA_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingPersonalDataRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingPersonalDataResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_PERSONAL_DATA_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSetLoginRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SET_LOGIN_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingLoginRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingSetLoginResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SET_LOGIN_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingLoginSocialRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SET_LOGIN_SOCIAL_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingLoginRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingLoginSocialResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SET_LOGIN_SOCIAL_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingStepupRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_STEPUP_REQUEST;
  constructor(
    public taskId: string,
    public request: IZenAuthOnboardingStepupRequest,
    public onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: () => void,
  ) {}
}

export class ZenAuthOnboardingStepupResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_STEPUP_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingHealthStepupRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_HEALTH_STEPUP_REQUEST;
  constructor(
    public taskId: string,
    public request: IZenAuthOnboardingStepupRequest,
    public onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: () => void,
  ) {}
}

export class ZenAuthOnboardingHealthStepupResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_HEALTH_STEPUP_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingLoginRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_LOGIN_REQUEST;
  constructor(
    public taskId: string,
    public request: IZenAuthLoginRequest,
    public onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingLoginResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_LOGIN_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingCheckBankRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_CHECK_BANK_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthCheckBankRequest,
    public merchantId?: string,
    public onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: () => void,
  ) {}
}

export class ZenAuthOnboardingCheckBankResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_CHECK_BANK_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingCheckLoginRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_CHECK_LOGIN_REQUEST;
  constructor(
    public taskId: string,
    public onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    public onError: () => void,
  ) {}
}

export class ZenAuthOnboardingCheckLoginResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_CHECK_LOGIN_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingAddMerchantRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_ADD_MERCHANT_REQUEST;
  constructor(
    public taskId: string,
    public onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    public onError: () => void,
  ) {}
}

export class ZenAuthOnboardingAddMerchantResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_ADD_MERCHANT_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingPostCheckRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_POSTCHECK_REQUEST;
  constructor(
    public taskId: string,
    public onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    public onErrorPostCheck: () => void,
  ) {}
}

export class ZenAuthOnboardingPostCheckResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_POSTCHECK_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingBusinessDetailsRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_DETAILS_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingBusinessDetailsRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingBusinessDetailsResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_DETAILS_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingBusinessAddressRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_ADDRESS_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthBusinessAddressRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingBusinessAddressResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_ADDRESS_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingBusinessPartnersRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_PARTNER_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingBusinessPartnerRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ) {}
}

export class ZenAuthOnboardingBusinessPartnersResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_PARTNER_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingBusinessCategoryRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_CATEGORY_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingBusinessCategoryInfoRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingBusinessCategoryResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BUSINESS_CATEGORY_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSuggestUsernameRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SUGGESTED_USERNAMES_REQUEST;
  constructor(public taskId: string, public onboardingId: string) {}
}

export class ZenAuthOnboardingSuggestUsernameResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SUGGESTED_USERNAMES_RECEIVED;
  constructor(public taskId: string, public suggestedUsername: string[]) {}
}

export class ZenAuthOnboardingSuggestAddressRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SUGGESTED_ADDRESS_REQUEST;
  constructor(public taskId: string, public query: string) {}
}

export class ZenAuthOnboardingSuggestAddressResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SUGGESTED_ADDRESS_RECEIVED;
  constructor(public taskId: string, public suggestedAddress: IZenAuthOnboardingAddressSuggestResponse[]) {}
}

export class ZenAuthTransactionSigningStartRequestAction implements Action {
  readonly type = EVENT.TRANSACTION_SIGNING_START_REQUEST;
  constructor(
    public taskId: string,
    public transactionAuthId: string,
    public onSucceed?: (data?: IZenAuthTransactionSigningAuthorizationHeaders) => void,
  ) {}
}

export class ZenAuthTransactionSigningStartResponseAction implements Action {
  readonly type = EVENT.TRANSACTION_SIGNING_START_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthNextStepTransactionSigningResponse) {}
}

export class ZenAuthTransactionSigningAuthorizeRequestAction implements Action {
  readonly type = EVENT.TRANSACTION_SIGNING_AUTHORIZE_REQUEST;
  constructor(public taskId: string, public authRequest: IZenAuthTransactionSigningAuthorizeRequest) {}
}

export class ZenAuthTransactionSigningAuthorizeResponseAction implements Action {
  readonly type = EVENT.TRANSACTION_SIGNING_AUTHORIZE_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthNextStepTransactionSigningResponse) {}
}

export class ZenAuthTransactionSigningCancelRequestAction implements Action {
  readonly type = EVENT.TRANSACTION_SIGNING_CANCEL_REQUEST;
  constructor(public taskId: string, public transactionAuthId: string, public onSucceed: () => void) {}
}

export class ZenAuthTransactionSigningCancelResponseAction implements Action {
  readonly type = EVENT.TRANSACTION_SIGNING_CANCEL_RECEIVED;
  constructor(public taskId: string) {}
}

export class ZenAuthTransactionSigningFakeRequestAction implements Action {
  readonly type = EVENT.TRANSACTION_SIGNING_FAKE_REQUEST;
  constructor(
    public taskId: string,
    public onSucceed: () => void,
    public transactionAuthId?: string,
    public signingToken?: string,
  ) {}
}

export class ZenAuthTransactionSigningFakeResponseAction implements Action {
  readonly type = EVENT.TRANSACTION_SIGNING_FAKE_RECEIVED;

  constructor(public taskId: string) {}
}

export class ZenAuthTransactionSigningSaveAuthenticatorsAction implements Action {
  readonly type = EVENT.TRANSACTION_SIGNING_AUTHENTICATORS_SAVE;

  constructor(public taskId: string, public authenticators: IAuthenticators) {}
}

export class ZenAuthOnboardingApproveItRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_APPROVEIT_REQUEST;

  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingApproveItRequest,
    public onSucceeded?: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingApproveItResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_APPROVEIT_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingProgressRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_PROGRESS_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingProgressRequest,
    public onSucceeded?: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingProgressResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_PROGRESS_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSentApproveItRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SENT_APPROVEIT_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSentApproveItRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ) {}
}

export class ZenAuthOnboardingSentApproveItResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SENT_APPROVEIT_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingBankOtpRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BANK_OTP_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthBankOtpRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingBankOtpResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BANK_OTP_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingIdPhotoRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_ID_PHOTO_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingIdPhotoRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ) {}
}

export class ZenAuthOnboardingIdPhotoResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_ID_PHOTO_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingSuccessRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_SUCCESS_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingSuccessRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingSuccessResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_SUCCESS_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingFinishedAction implements Action {
  readonly type = EVENT.ONBOARDING_FINISHED;
  constructor(public taskId: string) {}
}

export class ZenAuthLoginResetAction implements Action {
  readonly type = EVENT.LOGIN_RESET;
  constructor(public taskId: string) {}
}

export class ZenAuthOnboardingGetReferralDetailsRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_GET_REFERRAL_DETAILS_REQUEST;
  constructor(public taskId: string, public code: string) {}
}

export class ZenAuthOnboardingGetReferralDetailsResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_GET_REFERRAL_DETAILS_RECEIVED;
  constructor(public taskId: string, public data: IZenAuthOnboardingReferralDetail) {}
}

export class ZenAuthOnboardingBankphoneDifferentRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_BANKPHONE_DIFFERENT_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthOnboardingBankphoneDifferentRequest,
    public onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ) {}
}

export class ZenAuthOnboardingBankphoneDifferentResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_BANKPHONE_DIFFERENT_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthGuestRequestAction implements Action {
  readonly type = EVENT.GUEST_REQUEST;
  constructor(
    public taskId: string,
    public payload: IZenAuthSocialByRequest,
    public onSucceeded: (customerToken: IZenGuestLoginResponse) => void,
    public onError?: (error: any) => void,
  ) {}
}

export class ZenAuthGuestResponseAction implements Action {
  readonly type = EVENT.GUEST_RECEIVED;
  constructor(public taskId: string, public data: IZenGuestLoginResponse) {}
}

export class ZenAuthGuestInfoSetAction implements Action {
  readonly type = EVENT.GUEST_INFO_SET;
  constructor(public taskId: string, public data: IZenAuthSocialByRequest) {}
}

export class ZenAuthOnboardingInfoStepUpRequestAction implements Action {
  readonly type = EVENT.ONBOARDING_INFO_STEPUP_REQUEST;
  constructor(
    public taskId: string,
    public request: IZenAuthInfoStepUpRequest,
    public onSucceeded: (response: IZenAuthOnboardingNextStepResponse) => void,
    public onError: (error: any) => void,
  ) {}
}

export class ZenAuthOnboardingInfoStepUpResponseAction implements Action {
  readonly type = EVENT.ONBOARDING_INFO_STEPUP_RECEIVED;
  constructor(public taskId: string, public payload: IZenAuthOnboardingNextStepResponse) {}
}

export class ZenAuthOnboardingResetAction implements Action {
  readonly type = EVENT.ONBOARDING_RESET;

  constructor(public taskId: string) {}
}

export class ZenAuthorizationTokenSetAction implements Action {
  readonly type = EVENT.AUTHORIZATION_TOKEN_SET;

  constructor(public taskId: string, public token: IZenAuthAccessToken) {}
}

export class ZenAuthorizationInitAction implements Action {
  readonly type = EVENT.AUTHORIZATION_INIT_ACTION;

  constructor(public taskId: string) {}
}

export class ZenAuthorizationTokenRemoveAction implements Action {
  readonly type = EVENT.AUTHORIZATION_TOKEN_REMOVE;

  constructor(public taskId: string) {}
}

export class ZenAuthOnboardingSetReferralCodeAction implements Action {
  readonly type = EVENT.ONBOARDING_SET_REFERRAL_CODE;

  constructor(public taskId: string, public referralCode: string) {}
}

//v3
// Start passwordless
export class StartPasswordlessRequestAction implements Action {
  readonly type = EVENT.LOGIN_START_PASSWORDLESS_REQUEST;

  constructor(
    public taskId: string,
    public payload: IAuthPasswordlessLoginStartRequest,
    public onSucceeded: (response: IAuthNextStep) => void,
  ) {}
}

export class StartPasswordlessResponseAction implements Action {
  readonly type = EVENT.LOGIN_START_PASSWORDLESS_RECEIVED;

  constructor(public taskId: string, public data: IAuthNextStep) {}
}

// OTP
export class LoginOtpRequestAction implements Action {
  readonly type = EVENT.LOGIN_OTP_REQUEST;

  constructor(
    public taskId: string,
    public payload: IAuthOTPRequest,
    public onSucceeded: (response: IAuthNextStep) => void,
    public onError: (error: IErrorResponse & HttpErrorResponse) => void,
  ) {}
}

export class LoginOtpResponseAction implements Action {
  readonly type = EVENT.LOGIN_OTP_RECEIVED;

  constructor(public taskId: string, public data: IAuthNextStep) {}
}

// OTP resend
export class LoginOtpResendRequestAction implements Action {
  readonly type = EVENT.LOGIN_OTP_RESEND_REQUEST;

  constructor(
    public taskId: string,
    public payload: IAuthOTPResendRequest,
    public onSucceeded?: (response: IAuthNextStep) => void,
  ) {}
}

export class LoginOtpResendResponseAction implements Action {
  readonly type = EVENT.LOGIN_OTP_RESEND_RECEIVED;

  constructor(public taskId: string, public data: IAuthNextStep) {}
}

// Login profile
export class LoginProfileRequestAction implements Action {
  readonly type = EVENT.LOGIN_PROFILE_REQUEST;

  constructor(
    public taskId: string,
    public payload: IAuthProfileRequest,
    public onSucceeded?: (response: IAuthNextStep) => void,
  ) {}
}

export class LoginProfileResponseAction implements Action {
  readonly type = EVENT.LOGIN_PROFILE_RECEIVED;

  constructor(public taskId: string, public data: IAuthNextStep) {}
}

// Login Google
export class LoginGoogleRequestAction implements Action {
  readonly type = EVENT.LOGIN_GOOGLE_REQUEST;

  constructor(
    public taskId: string,
    public payload: IAuthSocialLoginStartRequest,
    public onSucceeded?: (response: IAuthNextStep) => void,
  ) {}
}

export class LoginGoogleResponseAction implements Action {
  readonly type = EVENT.LOGIN_GOOGLE_RECEIVED;

  constructor(public taskId: string, public data: IAuthNextStep) {}
}

// Login Facebook
export class LoginFacebookRequestAction implements Action {
  readonly type = EVENT.LOGIN_FACEBOOK_REQUEST;

  constructor(
    public taskId: string,
    public payload: IAuthSocialLoginStartRequest,
    public onSucceeded?: (response: IAuthNextStep) => void,
  ) {}
}

export class LoginFacebookResponseAction implements Action {
  readonly type = EVENT.LOGIN_FACEBOOK_RECEIVED;

  constructor(public taskId: string, public data: IAuthNextStep) {}
}

// Login Apple
export class LoginAppleRequestAction implements Action {
  readonly type = EVENT.LOGIN_APPLE_REQUEST;

  constructor(
    public taskId: string,
    public payload: IAuthSocialLoginStartRequest,
    public onSucceeded?: (response: IAuthNextStep) => void,
  ) {}
}

export class LoginAppleResponseAction implements Action {
  readonly type = EVENT.LOGIN_APPLE_RECEIVED;

  constructor(public taskId: string, public data: IAuthNextStep) {}
}

// Refresh token
export class LoginRefreshTokenRequestAction implements Action {
  readonly type = EVENT.LOGIN_REFRESH_TOKEN_REQUEST;

  constructor(public taskId: string, public payload: IAuthRefreshTokenStartRequest) {}
}

export class LoginRefreshTokenResponseAction implements Action {
  readonly type = EVENT.LOGIN_REFRESH_TOKEN_RECEIVED;

  constructor(public taskId: string, public data: IAuthNextStep) {}
}

// Clear next step
export class LoginClearNextStep implements Action {
  readonly type = EVENT.LOGIN_CLEAR_NEXT_STEP;

  constructor(public taskId: string) {}
}

/**
 * A union type of zenauth actions.
 */
export type ZenAuthAction =
  | ZenAuthGuestRequestAction
  | ZenAuthGuestResponseAction
  | ZenAuthOnboardingGetReferralDetailsRequestAction
  | ZenAuthOnboardingGetReferralDetailsResponseAction
  | ZenAuthOnboardingResetAction
  | ZenAuthLoginResetAction
  | ZenAuthOnboardingBusinessDetailsRequestAction
  | ZenAuthOnboardingSuccessResponseAction
  | ZenAuthOnboardingSuccessRequestAction
  | ZenAuthOnboardingBusinessDetailsResponseAction
  | ZenAuthOnboardingBusinessPartnersRequestAction
  | ZenAuthOnboardingBusinessPartnersResponseAction
  | ZenAuthOnboardingBusinessCategoryRequestAction
  | ZenAuthOnboardingBusinessCategoryResponseAction
  | StartRequestAction
  | StartResponseAction
  | StartSocialRequestAction
  | StartSocialResponseAction
  | ZenAuthErrorAction
  | ZentAuthAuthenticateWithToken
  | ZenAuthAuthenticateResponse
  | ZenAuthLogoutRequestAction
  | ZenAuthLogoutResponseAction
  | ZenAuthLoginMobimoneyRequestAction
  | ZenAuthLoginMobimoneyResponseAction
  | ZenAuthLoginNedidRequestAction
  | ZenAuthLoginNedidResponseAction
  | ZenAuthOnboardingStartRequestAction
  | ZenAuthOnboardingStartResponseAction
  | ZenAuthOnboardingStartInfoRequestAction
  | ZenAuthOnboardingStartInfoResponseAction
  | ZenAuthOnboardingStartMoneyAppRequestAction
  | ZenAuthOnboardingStartMoneyAppResponseAction
  | ZenAuthOnboardingStartSocialRequestAction
  | ZenAuthOnboardingStartSocialResponseAction
  | ZenAuthOnboardingStartManualRequestAction
  | ZenAuthOnboardingStartManualResponseAction
  | ZenAuthOnboardingOtpRequestAction
  | ZenAuthOnboardingOtpResponseAction
  | ZenAuthOnboardingPersonalDataRequestAction
  | ZenAuthOnboardingPersonalDataResponseAction
  | ZenAuthOnboardingSetLoginRequestAction
  | ZenAuthOnboardingSetLoginResponseAction
  | ZenAuthOnboardingLoginSocialRequestAction
  | ZenAuthOnboardingLoginSocialResponseAction
  | ZenAuthOnboardingExternalAuthRequestAction
  | ZenAuthOnboardingExternalAuthResponseAction
  | ZenAuthOnboardingAddressRequestAction
  | ZenAuthOnboardingAddressResponseAction
  | ZenAuthOnboardingMobiMoneyRequestAction
  | ZenAuthOnboardingMobiMoneyResponseAction
  | ZenAuthOnboardingSecurityQuestionsRequestAction
  | ZenAuthOnboardingSecurityQuestionsResponseAction
  | ZenAuthOnboardingFirstNameLastNameAuthRequestAction
  | ZenAuthOnboardingFirstNameLastNameAuthResponseAction
  | ZenAuthOnboardingLinkAccountRequestAction
  | ZenAuthOnboardingLinkAccountResponseAction
  | ZenAuthOnboardingStartLinkAccountRequestAction
  | ZenAuthOnboardingStartLinkAccountResponseAction
  | ZenAuthOnboardingInitiateLinkAccountRequestAction
  | ZenAuthOnboardingInitiateLinkAccountResponseAction
  | ZenAuthOnboardingSelectOtpRequestAction
  | ZenAuthOnboardingSelectOtpResponseAction
  | ZenAuthOnboardingSelectSocialRequestAction
  | ZenAuthOnboardingSelectSocialResponseAction
  | ZenAuthOnboardingSelectNedbankRequestAction
  | ZenAuthOnboardingSelectNedbankResponseAction
  | ZenAuthOnboardingSignupRequestAction
  | ZenAuthOnboardingSignupResponseAction
  | ZenAuthOnboardingSignupSocialRequestAction
  | ZenAuthOnboardingSignupSocialResponseAction
  | ZenAuthOnboardingSignupManualRequestAction
  | ZenAuthOnboardingSignupManualResponseAction
  | ZenAuthOnboardingSignupNedbankRequestAction
  | ZenAuthOnboardingSignupNedbankResponseAction
  | ZenAuthOnboardingBusinessTradingNameRequestAction
  | ZenAuthOnboardingBusinessTradingNameResponseAction
  | ZenAuthOnboardingPostCheckRequestAction
  | ZenAuthOnboardingPostCheckResponseAction
  | ZenAuthOnboardingSuggestUsernameResponseAction
  | ZenAuthOnboardingSuggestUsernameRequestAction
  | ZenAuthOnboardingSuggestAddressRequestAction
  | ZenAuthOnboardingSuggestAddressResponseAction
  | ZenAuthTransactionSigningStartRequestAction
  | ZenAuthTransactionSigningStartResponseAction
  | ZenAuthTransactionSigningAuthorizeRequestAction
  | ZenAuthTransactionSigningAuthorizeResponseAction
  | ZenAuthTransactionSigningCancelRequestAction
  | ZenAuthTransactionSigningCancelResponseAction
  | ZenAuthTransactionSigningFakeRequestAction
  | ZenAuthTransactionSigningFakeResponseAction
  | ZenAuthTransactionSigningSaveAuthenticatorsAction
  | ZenAuthOnboardingApproveItRequestAction
  | ZenAuthOnboardingApproveItResponseAction
  | ZenAuthOnboardingProgressRequestAction
  | ZenAuthOnboardingProgressResponseAction
  | ZenAuthOnboardingSentApproveItRequestAction
  | ZenAuthOnboardingSentApproveItResponseAction
  | ZenAuthOnboardingBankOtpRequestAction
  | ZenAuthOnboardingBankOtpResponseAction
  | ZenAuthOnboardingIdPhotoRequestAction
  | ZenAuthOnboardingIdPhotoResponseAction
  | ZenAuthOnboardingOutstandingTcsRequestAction
  | ZenAuthOnboardingOutstandingTcsResponseAction
  | ZenAuthOnboardingTcsRequestAction
  | ZenAuthOnboardingTcsResponseAction
  | ZenAuthOnboardingBusinessAddressRequestAction
  | ZenAuthOnboardingBusinessAddressResponseAction
  | ZenAuthOnboardingCheckBankRequestAction
  | ZenAuthOnboardingCheckBankResponseAction
  | ZenAuthOnboardingStepupRequestAction
  | ZenAuthOnboardingStepupResponseAction
  | ZenAuthOnboardingLoginRequestAction
  | ZenAuthOnboardingLoginResponseAction
  | ZenAuthOnboardingCheckLoginRequestAction
  | ZenAuthOnboardingCheckLoginResponseAction
  | ZenAuthOnboardingAddMerchantRequestAction
  | ZenAuthOnboardingAddMerchantResponseAction
  | ZenAuthOnboardingIdPassportRequestAction
  | ZenAuthOnboardingIdPassportResponseAction
  | ZenAuthOnboardingLegalEntityRequestAction
  | ZenAuthOnboardingLegalEntityResponseAction
  | ZenAuthOnboardingBusinessLegalEntityRequestAction
  | ZenAuthOnboardingBusinessLegalEntityResponseAction
  | ZenAuthOnboardingBasicDataRequestAction
  | ZenAuthOnboardingBasicDataResponseAction
  | ZenAuthOnboardingBusinessBasicDataRequestAction
  | ZenAuthOnboardingBusinessBasicDataResponseAction
  | ZenAuthOnboardingCountriesRequestAction
  | ZenAuthOnboardingCountriesResponseAction
  | ZenAuthOnboardingBankphoneDifferentRequestAction
  | ZenAuthOnboardingBankphoneDifferentResponseAction
  | ZenAuthOnboardingFinishedAction
  | ZenAuthOnboardingStartMoneyAppAsyncRequestAction
  | ZenAuthOnboardingStartMoneyAppAsyncResponseAction
  | ZenAuthOnboardingBankInfoRequestAction
  | ZenAuthOnboardingBankInfoResponseAction
  | ZenAuthOnboardingAvoInfoRequestAction
  | ZenAuthOnboardingAvoInfoResponseAction
  | ZenAuthOnboardingHealthStepupRequestAction
  | ZenAuthOnboardingHealthStepupResponseAction
  | ZenAuthOnboardingInfoStepUpRequestAction
  | ZenAuthOnboardingInfoStepUpResponseAction
  | ZenAuthAuthenticateError
  | ZenAuthorizationTokenSetAction
  | ZenAuthorizationTokenRemoveAction
  | ZenAuthOnboardingSetReferralCodeAction
  | ZenAuthGuestInfoSetAction
  | StartPasswordlessRequestAction
  | StartPasswordlessResponseAction
  | LoginOtpRequestAction
  | LoginOtpResponseAction
  | LoginOtpResendRequestAction
  | LoginOtpResendResponseAction
  | LoginRefreshTokenRequestAction
  | LoginRefreshTokenResponseAction
  | LoginClearNextStep
  | LoginProfileRequestAction
  | LoginProfileResponseAction
  | LoginGoogleRequestAction
  | LoginGoogleResponseAction
  | LoginFacebookRequestAction
  | LoginFacebookResponseAction
  | LoginAppleRequestAction
  | LoginAppleResponseAction;
