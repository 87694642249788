import { Pipe, PipeTransform } from '@angular/core';
import orderBy from 'lodash-es/orderBy';
import dayjs from 'dayjs';

@Pipe({
  name: 'sortByDatetime',
})
export class SortByDatetimePipe implements PipeTransform {
  transform = <T>(data: T[], sortBy: string, asc: boolean): T[] =>
    orderBy(data, item => dayjs(item[sortBy], 'YYYY-MM-DDTHH:mm:ss[Z]'), [asc ? 'asc' : 'desc']);
}
