import { AdobeCartVertical, AdobeSearchVertical } from '@avo/shared/adobe-analytics';

export enum PROPERTY {
  DIGITAL_DATA = 'digitalData',
  SATELLITE = '_satellite',
  ADOBE = 'adobe',
  UC03 = 'initUC03',
  UC04 = 'initUC04',
}

export enum BANK_STATE {
  UNKNOWN = 'UNKNOWN',
  NONE = 'NONE',
  LINKED = 'LINKED',
  LINKED_UNKNOWN = 'LINKED_UNKNOWN',
  WALLET_INPROGRESS = 'WALLET_INPROGRESS',
  LIMITED_WALLET = 'LIMITED_WALLET',
  FULL_WALLET = 'FULL_WALLET',
}

export interface IAdobeProductData {
  productId: string;
  productSku: string;
  productName: string;
  storeName: string;
  brand: string;
  amount: number;
  vertical: AdobeSearchVertical | AdobeCartVertical;
}

export interface IAdobeProductDataLayer {
  productInfo: IAdobeProductData;
}
