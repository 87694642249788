// todo(lukas.jurygacek): split into subscription and account setup files

import { IWalletSettlementAccount } from './wallet.model';

export enum AccountSetupStep {
  BUSINESS_INFORMATION = 0,
  BUSINESS_INFORMATION2 = 1,
  SUBSCRIPTION = 2,
  BILLING_INFO = 3,
}

export enum AccountOwnership {
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  PARTNERSHIP = 'PARTNERSHIP',
  LIMITED_COMPANY = 'LIMITED_COMPANY',
  PUBLIC = 'PUBLIC',
  NPO = 'NPO',
  STATE_OWNED = 'STATE_OWNED',
  OTHER = 'OTHER',
}

export interface IAccountOwnershipOption {
  key: AccountOwnership;
  label: string;
}

export interface IAccountSetupSubscriptionPlans {
  platformHead: string;
  platformText: string;
  note: string;
  plans: IAccountSetupSubscriptionPlan[];
}

export interface IAccountSetupSubscriptionPlan {
  type: AccountSetupSubscriptionPlan;
  isBusiness: boolean;
  flag?: string;
  name: string;
  amountMonth: string;
  amountYear?: string;
  yearSave?: string;
  items: AccountSetupSubscriptionPlanItem[];
  buttonText?: string;
  buttonAction?: () => void;
}

export enum AccountSetupSubscriptionPlan {
  ESSENTIAL = 'ESSENTIAL',
  STANDARD = 'STANDARD',
}

export enum AccountSetupSubscriptionPlanItemType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
}

export interface AccountSetupSubscriptionPlanItem {
  type: AccountSetupSubscriptionPlanItemType;
  content: boolean | string;
}

export interface AccountSetupSubscriptionRowInfo {
  label: string;
  descriptions: AccountSetupSubscriptionDescription[];
}

export interface AccountSetupSubscriptionDescription {
  text: string;
  isBold?: boolean;
}

export enum AccountSetupBillingCycle {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum AccountSetupBillingPayment {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  CARD = 'CARD',
}

export interface IAccountSetupSubscriptionForm {
  plan: AccountSetupSubscriptionPlan;
  terms: boolean;
}

export interface IAccountSetupBillingInfoForm {
  cycle: AccountSetupBillingCycle;
  payment: AccountSetupBillingPayment;
  isSettlement: boolean;
  bankAccount?: {
    newBankAccount: IWalletSettlementAccount;
    activeBankAccountId: number;
  };
  saId?: string;
}

export enum SubscriptionUpgradeType {
  B2B = 'B2B',
  B2C = 'B2C',
  B2C_ON_DEMAND = 'B2C_ON_DEMAND',
  BUYER = 'BUYER',
}

export enum AccountSetupJourney {
  B2B = 'B2B',
  ONLINE_STORE = 'ONLINE_STORE',
  RESTAURANT = 'RESTAURANT',
  LIQUOR = 'LIQUOR',
  SERVICE = 'SERVICE',
  WINERIES = 'WINERIES',
  BUYER = 'BUYER',
}
