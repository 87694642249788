import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApplicationFacade, failed, succeed } from '@wakanda/wakanda-core';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import * as actions from './avo-business.actions';
import * as EVENTS from './avo-business.events';
import { AvoBusinessService } from '../../services/avo-business/avo-business.service';

@Injectable({ providedIn: 'root' })
export class AvoBusinessEffects {
  constructor(private service: AvoBusinessService, private actions$: Actions, private appFacade: ApplicationFacade) {}

  sendContactSupportEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AvoBusinessSendContactEmailRequestAction>(EVENTS.AVO_BUSINESS_SEND_CONTACT_EMAIL_REQUEST),
      switchMap(({ taskId, request, onSucceeded }) =>
        this.service.sendContactSupportEmail(request).pipe(
          map(response => {
            if (onSucceeded) onSucceeded(response);
            return succeed(new actions.AvoBusinessSendContactEmailResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.AvoBusinessErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
}
