import { Component, Input, OnInit } from '@angular/core';
import get from 'lodash-es/get';
import random from 'lodash-es/random';
import split from 'lodash-es/split';
import toString from 'lodash-es/toString';
import toUpper from 'lodash-es/toUpper';

@Component({
  selector: 'ui-letter-avatar',
  template: `
    <div class="ui-letter-avatar" [ngClass]="{ huge: !!huge }" [ngStyle]="{ background: color }">
      <p [ngStyle]="{ color: inverted ? 'black' : 'white' }">{{ data }}</p>
    </div>
  `,
})
export class LetterAvatarComponent implements OnInit {
  data: string;
  color: string;
  palette = [
    '#1abc9c',
    '#2ecc71',
    '#3498db',
    '#9b59b6',
    '#34495e',
    '#16a085',
    '#27ae60',
    '#2980b9',
    '#8e44ad',
    '#2c3e50',
    '#f1c40f',
    '#e67e22',
    '#e74c3c',
    '#9eecff',
    '#580ea1',
    '#f39c12',
    '#d35400',
    '#c0392b',
    '#95999c',
    '#0c737a',
  ];

  @Input() defaultColor: string;
  @Input() text: string;
  @Input() huge: boolean;
  @Input() inverted: boolean;

  getText = (text: string): void => {
    if (!text) return;
    const stringArr = split(toUpper(toString(text)), ' ');
    const firstChar = get(stringArr, 0).charAt(0);
    const secondChar = get(stringArr, 0).charAt(1);
    this.data = `${firstChar}${secondChar}`;
  };

  getColor = (palette: string[]): void => {
    if (this.defaultColor && this.defaultColor !== '') {
      this.color = this.defaultColor;
      return;
    }
    const randomNum = random(0, 20);
    this.color = get(palette, randomNum);
  };

  ngOnInit(): void {
    this.getText(this.text);
    this.getColor(this.palette);
  }
}
