export enum EVENT {
  LOCATION_FETCH_LOCATIONS_REQUEST = 'LOCATION/FETCH-LOCATIONS/REQUEST',
  LOCATION_FETCH_LOCATIONS_RECEIVED = 'LOCATION/FETCH-LOCATIONS/RECEIVED',

  LOCATION_FETCH_MERCHANT_LOCATIONS_REQUEST = 'LOCATION/FETCH-MERCHANT-LOCATIONS/REQUEST',
  LOCATION_FETCH_MERCHANT_LOCATIONS_RECEIVED = 'LOCATION/FETCH-MERCHANT-LOCATIONS/RECEIVED',

  LOCATION_ADD_MERCHANT_LOCATION_REQUEST = 'LOCATION/ADD-MERCHANT-LOCATION/REQUEST',
  LOCATION_ADD_MERCHANT_LOCATION_RECEIVED = 'LOCATION/ADD-MERCHANT-LOCATION/RECEIVED',

  LOCATION_EDIT_LOCATION_REQUEST = 'LOCATION/EDIT-LOCATION/REQUEST',
  LOCATION_EDIT_LOCATION_RECEIVED = 'LOCATION/EDIT-LOCATION/RECEIVED',

  LOCATION_DELETE_LOCATION_REQUEST = 'LOCATION/DELETE-LOCATION/REQUESTED',
  LOCATION_DELETE_LOCATION_RECEIVED = 'LOCATION/DELETE-LOCATION/RECEIVED',

  LOCATION_FETCH_CURRENT_LOCATION_REQUEST = 'LOCATION/FETCH-CURRENT-LOCATION/REQUEST',
  LOCATION_FETCH_CURRENT_LOCATION_RECEIVED = 'LOCATION/FETCH-CURRENT-LOCATION/RECEIVED',
  LOCATION_FETCH_CURRENT_LOCATION_FAILED = 'LOCATION/FETCH-CURRENT-LOCATION/ERROR',

  LOCATION_SET_CURRENT_LOCATION_REQUEST = 'LOCATION/SET-CURRENT-LOCATION/REQUEST',
  LOCATION_SET_CURRENT_LOCATION_RECEIVED = 'LOCATION/SET-CURRENT-LOCATION/RECEIVED',

  LOCATION_FETCH_NEAREST_LOCATION_REQUEST = 'LOCATION/FETCH-NEAREST-LOCATION/REQUEST',
  LOCATION_FETCH_NEAREST_LOCATION_RECEIVED = 'LOCATION/FETCH-NEAREST-LOCATION/RECEIVED',

  LOCATION_FETCH_SEARCH_BY_QUERY_REQUEST = 'LOCATION/FETCH-SEARCH-BY-QUERY/REQUESTED',
  LOCATION_FETCH_SEARCH_BY_QUERY_RECEIVED = 'LOCATION/FETCH-SEARCH-BY-QUERY/RECEIVED',

  LOCATION_FIND_PLACE_BY_ADDRESSSTRING_REQUEST = 'LOCATION/FIND-PLACE-BY-ADDRESSSTRING/REQUESTED',
  LOCATION_FIND_PLACE_BY_ADDRESSSTRING_RECEIVED = 'LOCATION/FIND-PLACE-BY-ADDRESSSTRING/RECEIVED',

  LOCATION_FIND_PLACE_BY_POSITION_REQUEST = 'LOCATION/FIND-BY-POSITION/REQUESTED',
  LOCATION_FIND_PLACE_BY_POSITION_RECEIVED = 'LOCATION/FIND-BY-POSITION/RECEIVED',

  LOCATION_CLEAR = 'LOCATION/CLEAR',
}
