import { Injector } from '@angular/core';
import {
  AddressType,
  ApplicationFacade,
  CommonComponent,
  GoogleMapsPredictionService,
  IAddressForm,
  IAddressLocation,
  IBuyerLocation,
  ILocationV3,
  IPosition,
  LocationFacade,
} from '@wakanda/wakanda-core';
import { TranslateFacade } from '@wakanda/zentity-translate-store';
import compact from 'lodash-es/compact';
import get from 'lodash-es/get';
import size from 'lodash-es/size';
import toNumber from 'lodash-es/toNumber';
import { take } from 'rxjs';

export class GoogleMapBaseContainer extends CommonComponent {
  translate: TranslateFacade;
  locationFacade: LocationFacade;
  applicationFacade: ApplicationFacade;
  googleMapsPredictionService: GoogleMapsPredictionService;
  //
  currentLocation: ILocationV3;
  location: IAddressForm;
  searched: IAddressLocation[];
  customerAddresses: IAddressLocation[];
  merchantAddresses: IBuyerLocation[];
  isLoading: boolean;
  defaultPosition: IPosition = {
    latitude: -26.195246,
    longitude: 28.034088,
  };
  isMapsApiReady = false;
  //
  constructor(injector: Injector) {
    super(injector);
    this.locationFacade = injector.get(LocationFacade);
    this.applicationFacade = injector.get(ApplicationFacade);
    this.translate = injector.get(TranslateFacade);
    this.googleMapsPredictionService = injector.get(GoogleMapsPredictionService);
    //
    this.subscriptions.add(this.locationFacade.getGooglePlaceSearch$.subscribe(data => (this.location = data)));
    this.subscriptions.add(this.locationFacade.getCurrentLocation$.subscribe(data => (this.currentLocation = data)));
    this.subscriptions.add(this.locationFacade.getLocations$.subscribe(data => (this.customerAddresses = data)));
    this.subscriptions.add(
      this.locationFacade.getMerchantLocations$.subscribe(data => (this.merchantAddresses = data)),
    );
    this.subscriptions.add(this.locationFacade.getSearchedData$.subscribe(data => (this.searched = data)));
    this.subscriptions.add(
      this.applicationFacade.getIsApplicationLoadingSilently$.subscribe(data => (this.isLoading = !!size(data))),
    );
    //
    this.googleMapsPredictionService.mapsApi$.pipe(take(1)).subscribe(data => (this.isMapsApiReady = data));
  }

  processAddresses(numberOfVisibleAddresses?: number, isMerchant = false, isAuthorized = true): IAddressLocation[] {
    const addresses = isMerchant ? (this.merchantAddresses as any) : this.customerAddresses;
    const optionShowMore: IAddressLocation = {
      name: this.getTranslatedKey('customer.settings.location.set.show'),
      icon: 'down',
      type: AddressType.SelectAll,
      address: null,
      id: null,
      instructions: null,
    };

    const optionCurrentLocation: IAddressLocation = this.currentLocation
      ? {
          id: 'currentLocation',
          name: this.getTranslatedKey('customer.settings.locations.set.your_current_location'),
          icon: 'location_marker',
          type: AddressType.StaticCurrentLocation,
          address: this.currentLocation.address,
          instructions: null,
        }
      : null;

    const optionUsingMaps: IAddressLocation = {
      id: 'setUsingMaps',
      name: this.getTranslatedKey('customer.settings.locations.set.set_location_using_map'),
      icon: 'location_marker',
      type: AddressType.StaticUsingMap,
      address: null,
      instructions: null,
    };

    const optionManageLocations: IAddressLocation = isAuthorized
      ? {
          id: 'manageYourLocation',
          name: this.getTranslatedKey('customer.settings.location.manage'),
          icon: 'location_settings',
          type: AddressType.StaticManage,
          address: null,
          instructions: null,
        }
      : null;

    if (!addresses || addresses?.length === 0) {
      return compact([optionUsingMaps, optionManageLocations]);
    } else if (!!numberOfVisibleAddresses && addresses?.length > numberOfVisibleAddresses) {
      const visibleLocations = addresses.slice(0, numberOfVisibleAddresses);
      return compact([
        optionCurrentLocation,
        ...visibleLocations,
        optionShowMore,
        optionUsingMaps,
        optionManageLocations,
      ]);
    } else {
      return compact([optionCurrentLocation, ...addresses, optionUsingMaps, optionManageLocations]);
    }
  }

  getBrowserPosition = (callback: (latitude: number, longitude: number) => void): void => {
    if (!navigator || !navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition(
      position =>
        this.locationFacade.findPlaceByPosition(
          {
            latitude: toNumber(get(position, 'coords.latitude')),
            longitude: toNumber(get(position, 'coords.longitude')),
          },
          response => callback(response.latitude, response.longitude),
        ),
      () =>
        this.locationFacade.findPlaceByPosition(this.defaultPosition, response =>
          callback(response.latitude, response.longitude),
        ),
    );
  };

  getTranslatedKey = (key: string): string => {
    let result: string;
    this.subscriptions.add(this.translate.getTranslateByKey$(key).subscribe(translatedKey => (result = translatedKey)));
    return result;
  };
}
