import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'secureImage',
})
export class SecureImagePipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  transform(url, bypassSecurity = true): Observable<SafeUrl | string> {
    if (!url) {
      return null;
    }
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map(val => {
        const url = URL.createObjectURL(val);
        return bypassSecurity ? this.sanitizer.bypassSecurityTrustUrl(url) : url;
      }),
    );
  }
}
