import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { tap, Observable, Subscription } from 'rxjs';
import { ITransactionSigningAuthenticators } from '../store/models/store.models';
import { TransactionsFacade } from '../store/transactions/transactions.facade';

@Injectable()
export class TransactionSigningInterceptor implements HttpInterceptor, OnDestroy {
  subscriptions = new Subscription();
  authenticators: ITransactionSigningAuthenticators;
  constructor(private transactionsFacade: TransactionsFacade) {
    this.subscriptions.add(
      this.transactionsFacade.getTransactionSigningAuthenticators$.subscribe(data => (this.authenticators = data)),
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.endsWith('configuration.json')) {
      return next.handle(request);
    }
    if (!!this.authenticators?.xAuthTXId && !!this.authenticators?.xAuthTXToken) {
      request = request.clone({
        headers: request.headers
          .set('X-AUTH-TX-ID', this.authenticators.xAuthTXId)
          .set('X-AUTH-TX-TOKEN', this.authenticators.xAuthTXToken),
      });
      return next.handle(request).pipe(tap(() => this.transactionsFacade.transactionSigningSaveAuthenticators(null)));
    }

    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
