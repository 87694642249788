export interface IWebsocketTokenResponse {
  token: string;
}

export interface IWebsocketMessageEvent {
  websocketChatEventType: WebsocketMessageEventType;
  chatId: string;
  roomId: string;
  senderId: string;
  sender: string;
  senderDisplayname: string;
  senderType: WebsocketMessageSenderType;
  timestamp: string;
  type: WebsocketMessageType;
  content?: string;
  chatStatus?: WebsocketMessageChatStatus;
}

export interface IWebsocketKeepAliveStatus {
  ping?: IWebsocketPing;
  createSocketAt?: string;
  durationInMs?: number;
  countReconnect?: number;
  refreshWsInMs?: number;
  pingPongInMs?: number;
  pongCheckInMs?: number;
}

export interface IWebsocketPing {
  pingId: string;
  pingSentTime: string;
  pongResult?: IWebsocketPong;
}

export interface IWebsocketPong {
  pongArriveAt: string;
}

export interface IWebsocketEventLog extends IWebsocketMessageEvent {
  timestamp: string;
}

export enum WebsocketMessageChatStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  AWAITING_AGENT = 'AWAITING_AGENT',
  CLOSE = 'CLOSE',
}

export enum WebsocketMessageEventType {
  NEW_MESSAGE = 'NEW_MESSAGE',
  NEW_QUEUE_MESSAGE = 'NEW_QUEUE_MESSAGE',
  NEW_MESSAGE_INTERNAL = 'NEW_MESSAGE_INTERNAL',
  NEW_MESSAGE_AGENT = 'NEW_MESSAGE_AGENT',
  CHAT_STATUS_CHANGE = 'CHAT_STATUS_CHANGE',
  CHAT_AGENT_JOINED = 'CHAT_AGENT_JOINED',
  USER_WRITING = 'USER_WRITING',
}

export enum WebsocketMessageSenderType {
  CUSTOMER = 'CUSTOMER',
  MERCHANT = 'MERCHANT',
  AGENT = 'AGENT',
}

export enum WebsocketMessageType {
  TEXT_EVENT = 'TextEvent',
  SUPPORT = 'SUPPORT',
}
