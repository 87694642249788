import { HttpErrorResponse } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs';
import { TranslateFacade } from '../store/translate.facade';
import replace from 'lodash-es/replace';

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
  constructor(private translateFacade: TranslateFacade) {}

  transform(key: string, param?: any, defaultTranslate?: string): string {
    let translate = '';
    this.translateFacade
      .getTranslateByKey$(key, defaultTranslate)
      .pipe(take(1))
      .subscribe({
        next: response => {
          translate = response;
        },
        error: (error: HttpErrorResponse) => {
          translate = key;
          console.error(`An error has occurred with key ${key}. Error message: ${error.message}`);
        },
      });

    if (param) {
      return this.replaceParams(translate, param);
    }

    return translate;
  }

  private replaceParams(translate: string, param: any): string {
    if (typeof param !== 'object') {
      if (translate.includes('$0')) {
        return replace(translate, '$0', param);
      } else if (translate.includes('$1')) {
        return replace(translate, '$1', param);
      }
      return replace(translate, '$', param);
    } else {
      for (const key in param) {
        translate = replace(translate, key, param[key]);
      }
      return translate;
    }
  }
}
