<div
  class="ui-rating"
  *ngIf="!linear"
  [ngClass]="{
    'read-only': readOnly,
    'mini': size === 'mini',
    'tiny': size === 'tiny',
    'small': size === 'small',
    'large': size === 'large',
    'big': size === 'big',
    'huge': size === 'huge',
    'massive': size === 'massive',
    'align-left': alignLeft,
    'align-right': alignRight,
    'align-center': alignCenter
  }"
>
  <ng-container *ngFor="let star of stars; let i = index">
    <ui-icon
      *ngIf="rating > star; else emptyStar"
      class="star"
      icon="star_yellow"
      (click)="onClick(star + 1)"
      [size]="sizes[!!size ? size : '1rem']"
    ></ui-icon>
    <ng-template #emptyStar>
      <ui-icon
        class="star"
        icon="star_blank"
        (click)="onClick(star + 1)"
        [size]="sizes[!!size ? size : '1rem']"
      ></ui-icon>
    </ng-template>
  </ng-container>
</div>

<div
  *ngIf="linear"
  class="ui-linear-rating"
  [ngClass]="{
    'align-left': alignLeft,
    'align-right': alignRight,
    'align-center': alignCenter
  }"
>
  <div class="row">
    <p class="small">Excellent</p>
    <span class="bar">
      <ng-container *ngIf="!isPercentage">
        <div
          class="value excellent"
          [ngStyle]="{ width: (!!allRatingCount && allRatingCount > 0 ? (excellent / allRatingCount) * 100 : 0) + '%' }"
        ></div>
      </ng-container>
      <ng-container *ngIf="!!isPercentage">
        <div class="value excellent" [ngStyle]="{ width: excellent + '%' }"></div>
      </ng-container>
    </span>
  </div>
  <div class="row">
    <p class="small">Very Good</p>
    <span class="bar">
      <ng-container *ngIf="!isPercentage">
        <div
          class="value very-good"
          [ngStyle]="{ width: (!!allRatingCount && allRatingCount > 0 ? (veryGood / allRatingCount) * 100 : 0) + '%' }"
        ></div>
      </ng-container>
      <ng-container *ngIf="!!isPercentage">
        <div class="value very-good" [ngStyle]="{ width: veryGood + '%' }"></div>
      </ng-container>
    </span>
  </div>
  <div class="row">
    <p class="small">Good</p>
    <span class="bar">
      <ng-container *ngIf="!isPercentage">
        <div
          class="value good"
          [ngStyle]="{ width: (!!allRatingCount && allRatingCount > 0 ? (good / allRatingCount) * 100 : 0) + '%' }"
        ></div>
      </ng-container>
      <ng-container *ngIf="!!isPercentage">
        <div class="value good" [ngStyle]="{ width: good + '%' }"></div>
      </ng-container>
    </span>
  </div>
  <div class="row">
    <p class="small">Average</p>
    <span class="bar">
      <ng-container *ngIf="!isPercentage">
        <div
          class="value average"
          [ngStyle]="{ width: (!!allRatingCount && allRatingCount > 0 ? (average / allRatingCount) * 100 : 0) + '%' }"
        ></div>
      </ng-container>
      <ng-container *ngIf="!!isPercentage">
        <div class="value average" [ngStyle]="{ width: average + '%' }"></div>
      </ng-container>
    </span>
  </div>
  <div class="row">
    <p class="small">Poor</p>
    <span class="bar">
      <ng-container *ngIf="!isPercentage">
        <div
          class="value poor"
          [ngStyle]="{ width: (!!allRatingCount && allRatingCount > 0 ? (poor / allRatingCount) * 100 : 0) + '%' }"
        ></div>
      </ng-container>
      <ng-container *ngIf="!!isPercentage">
        <div class="value poor" [ngStyle]="{ width: poor + '%' }"></div>
      </ng-container>
    </span>
  </div>
</div>
