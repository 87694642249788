import { Constants } from './constants';

export class PhoneUtil {
  public static phoneSplit(value: string, maxLength: number): string {
    let pattern = Constants.patterns.splitPhoneLessSix;
    if (value.length < 6) {
      return value.replace(pattern, '$1 $2');
    } else {
      value = value.replace(/\s/g, '').substring(0, maxLength);
      pattern = Constants.patterns.splitPhoneMoreSix;
      return value.replace(pattern, '$1 $2 $3');
    }
  }
}
