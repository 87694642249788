import { Component, Input } from '@angular/core';
import { IMatrixMessage, IMessageEventAudio } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-audio',
  styleUrls: ['./chat-widget-audio.component.scss'],
  template: `
    <div class="chat-widget">
      <div
        class="ui-chat-message audio-message cursor-pointer no-background"
        [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }"
      >
        <div class="column" *ngIf="message?.content?.url | secureImage | async as audioRecord; else loaderTemplate">
          <audio controls>
            <source [src]="audioRecord" type="audio/wav" />
            <source [src]="audioRecord" type="audio/ogg" />
            <source [src]="audioRecord" type="audio/mpeg" />
            <p class="small error">Your browser does not support the audio element.</p>
          </audio>
        </div>
        <ng-template #loaderTemplate>
          <ui-skeleton-screen
            [card]="true"
            [rect]="true"
            [compact]="true"
            width="100%"
            height="3.75rem"
          ></ui-skeleton-screen>
        </ng-template>
      </div>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
  `,
})
export class ChatWidgetAudioComponent {
  @Input() message: IMatrixMessage<IMessageEventAudio>;
}
