import { Action } from '@ngrx/store';
import {
  IAdvertisingBannerClickRequest,
  IAdvertisingBannerFormRequest,
  IAdvertisingBannerRequest,
  IAdvertisingBannerResponse,
} from '../../models/advertising.model';
import { EVENTS } from './advertising.events';

export class AdvertisingBannerRequestAction implements Action {
  readonly type = EVENTS.ADVERTISING_BANNER_REQUEST;
  constructor(
    public taskId: string,
    public bannerRequest: IAdvertisingBannerRequest,
    public onSucceed?: (data: IAdvertisingBannerResponse) => void,
  ) {}
}

export class AdvertisingBannerResponseAction implements Action {
  readonly type = EVENTS.ADVERTISING_BANNER_RECEIVED;
  constructor(public taskId: string, public data: IAdvertisingBannerResponse) {}
}

export class AdvertisingBannerClickRequestAction implements Action {
  readonly type = EVENTS.ADVERTISING_BANNER_CLICK_REQUEST;
  constructor(
    public taskId: string,
    public bannerId: string,
    public bannerClick: IAdvertisingBannerClickRequest,
    public onSucceed: () => void,
  ) {}
}

export class AdvertisingBannerClickResponseAction implements Action {
  readonly type = EVENTS.ADVERTISING_BANNER_CLICK_RECEIVED;
  constructor(public taskId: string) {}
}

export class AdvertisingBannerFormRequestAction implements Action {
  readonly type = EVENTS.ADVERTISING_BANNER_FORM_REQUEST;
  constructor(
    public taskId: string,
    public bannerId: string,
    public bannerFormRequest: IAdvertisingBannerFormRequest,
    public onSucceed: () => void,
  ) {}
}

export class AdvertisingBannerFormResponseAction implements Action {
  readonly type = EVENTS.ADVERTISING_BANNER_FORM_RECEIVED;
  constructor(public taskId: string) {}
}

export type AdvertisingActions =
  | AdvertisingBannerRequestAction
  | AdvertisingBannerResponseAction
  | AdvertisingBannerClickRequestAction
  | AdvertisingBannerClickResponseAction
  | AdvertisingBannerFormRequestAction
  | AdvertisingBannerFormResponseAction;
