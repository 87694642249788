import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import get from 'lodash-es/get';
import orderBy from 'lodash-es/orderBy';
import { BehaviorSubject } from 'rxjs';

@Pipe({
  name: 'tableHeaderSort',
  pure: true,
})
export class TableHeaderPipe implements PipeTransform, OnDestroy {
  result$ = new BehaviorSubject(null);

  transform = <T>(
    dataSource$: BehaviorSubject<T[]>,
    activeSortName: string,
    order: 'asc' | 'desc' = 'desc',
  ): BehaviorSubject<T[]> => {
    if (!activeSortName || !dataSource$) return dataSource$;

    dataSource$.subscribe(data => {
      const newData = orderBy(
        data,
        i => {
          if (activeSortName === 'price') {
            return get(i, [activeSortName, 'amount']);
          }
          return get(i, [activeSortName]);
        },
        [order],
      );
      this.result$.next(newData);
    });

    return this.result$;
  };

  ngOnDestroy(): void {
    this.result$.unsubscribe();
  }
}
