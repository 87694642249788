import { Action } from '@ngrx/store';
import map from 'lodash-es/map';
import { getAddressByPlace, getAddressForm } from '../../maps/utils';
import { AddressType, IAddressForm, IAddressLocation, ILocationV3 } from '../../models/location.model';
import { SetLocationAction } from './location.actions';
import { EVENT } from './location.events';
import { IBuyerLocation } from '../../models/buyer.model';

export const LOCATION_FEATURE_KEY = 'location2';

export interface ILocationState {
  searched?: IAddressLocation[];
  googlePlaceSearch?: IAddressForm;
  locations?: IAddressLocation[];
  merchantLocations?: IBuyerLocation[];
  currentLocation?: ILocationV3;
}

export function locationReducer(state: ILocationState = {}, actions: Action): ILocationState {
  const action = actions as SetLocationAction;
  switch (action.type) {
    case EVENT.LOCATION_FETCH_LOCATIONS_RECEIVED: {
      const locations = map(action.data, a => ({
        ...a,
        icon: 'location_home',
        type: AddressType.CustomerLocation,
      }));
      return Object.assign({}, state, { locations: [...locations] });
    }
    case EVENT.LOCATION_FETCH_MERCHANT_LOCATIONS_RECEIVED: {
      const locations = map(action.data, a => ({
        ...a,
        icon: 'location_home',
        type: AddressType.CustomerLocation,
      }));
      return Object.assign({}, state, { merchantLocations: [...locations] });
    }

    case EVENT.LOCATION_FIND_PLACE_BY_ADDRESSSTRING_RECEIVED:
    case EVENT.LOCATION_FIND_PLACE_BY_POSITION_RECEIVED: {
      const googlePlaceSearch = getAddressForm(action.data);
      return Object.assign({}, state, { googlePlaceSearch });
    }

    case EVENT.LOCATION_FETCH_CURRENT_LOCATION_RECEIVED:
    case EVENT.LOCATION_SET_CURRENT_LOCATION_RECEIVED:
      return Object.assign({}, state, { currentLocation: action.data });
    case EVENT.LOCATION_FETCH_CURRENT_LOCATION_FAILED:
    case EVENT.LOCATION_CLEAR:
      return Object.assign({}, state, { currentLocation: null });

    case EVENT.LOCATION_FETCH_SEARCH_BY_QUERY_RECEIVED:
      return Object.assign({}, state, { searched: getAddressByPlace(action.data) });

    default:
      return state;
  }
}
