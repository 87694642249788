import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { DefaultProjectorFn } from '@ngrx/store/src/selector';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import { IMyMoneyWalletState, IWalletSettlementAccount, MY_MONEY_WALLET_FEATURE_KEY } from '../../models/wallet.model';

const getState = createFeatureSelector<IMyMoneyWalletState>(MY_MONEY_WALLET_FEATURE_KEY);

export const getWallets = createSelector(getState, (state: IMyMoneyWalletState) => state.wallets);

export const getWallet = createSelector(getState, (state: IMyMoneyWalletState) => state.wallet);

export const getTransactions = createSelector(getState, (state: IMyMoneyWalletState) => state.transactions);

export const getRecentTransactions = createSelector(getState, (state: IMyMoneyWalletState) => state.recentTransactions);

export const getTransactionDetail = createSelector(getState, (state: IMyMoneyWalletState) => state.transactionDetail);

export const getMainWallet = createSelector(getState, (state: IMyMoneyWalletState) =>
  find(get(state, 'wallets'), w => w.type === 'MAIN'),
);

export const getQRData = createSelector(getState, (state: IMyMoneyWalletState) => state.accept.qr);

export const getQRCode = createSelector(getState, (state: IMyMoneyWalletState) => state.accept.qrCode);

export const getQRCodePayRequest = createSelector(getState, (state: IMyMoneyWalletState) => state.qrCodePayData);

export const getQRCodePayRequestOriginalString = createSelector(
  getState,
  (state: IMyMoneyWalletState) => state.qrCodePayDataString,
);

export const getPayRecentRecipient = createSelector(getState, (state: IMyMoneyWalletState) => state.payRecentRecipient);

export const getExternalAccounts = createSelector(getState, (state: IMyMoneyWalletState) => state.externalAccounts);

export const getSavedExternalAccount = createSelector(
  getState,
  (state: IMyMoneyWalletState) => state.savedExternalAccount,
);

export const getBanks = createSelector(getState, (state: IMyMoneyWalletState) => state.banks);

export const getNedbankBanks = createSelector(getState, (state: IMyMoneyWalletState) => state.nedbankBanks);

export const getDebitAccount = createSelector(getState, state =>
  state?.bankAccount?.find(bankAccount => bankAccount.isDebit),
);

export const getBankAccountsFiltered = (
  isDebit?: boolean,
): MemoizedSelector<object, IWalletSettlementAccount[], DefaultProjectorFn<IWalletSettlementAccount[]>> =>
  createSelector(getState, (state: IMyMoneyWalletState) => {
    if (isDebit) {
      return state?.bankAccount?.filter(bankAccount => bankAccount.isDebit);
    }
    return state?.bankAccount;
  });

export const getFilteredBanks = createSelector(getState, (state: IMyMoneyWalletState) => state.filteredBanks);

export const getFilter = createSelector(getState, (state: IMyMoneyWalletState) => state.filter);

export const getWithdrawResponse = createSelector(getState, (state: IMyMoneyWalletState) => state.withdrawResponse);

export const getLoyaltyPoints = createSelector(getState, (state: IMyMoneyWalletState) => state.loyaltyPoints);

export const getPaymentLinkResponse = createSelector(
  getState,
  (state: IMyMoneyWalletState) => state.paymentLinkResponse,
);

export const getAddCard = createSelector(getState, (state: IMyMoneyWalletState) => state.addCard);

export const getPayTopUp = createSelector(getState, (state: IMyMoneyWalletState) => state.payTopUp);

export const getSelectedCardTopUp = createSelector(getState, (state: IMyMoneyWalletState) => state.selectedTopUpCard);

export const getActiveBuyer = createSelector(getState, (state: IMyMoneyWalletState) => state.activeBuyer);

export const getDashboardWidget = createSelector(getState, (state: IMyMoneyWalletState) => state.dashboardWidget);

export const getSubscriptions = createSelector(getState, (state: IMyMoneyWalletState) => state.subscriptions);

export const getHasBuyerStandardSubscription = createSelector(getState, state => state.hasBuyerStandardSubscription);

export const getAvailableSubscriptions = createSelector(
  getState,
  (state: IMyMoneyWalletState) => state.availableSubscriptions,
);

export const getSellerPayoutTransactions = createSelector(
  getState,
  (state: IMyMoneyWalletState) => state.sellerPayoutTransactions,
);

export const getSellerPayoutTransactionItem = createSelector(getState, (state: IMyMoneyWalletState) =>
  state.sellerPayoutTransactions?.items?.length > 0 ? state.sellerPayoutTransactions?.items[0] : null,
);

export const getTaxInvoices = createSelector(getState, (state: IMyMoneyWalletState) => state.taxInvoices);

export const getPaymentMethod = createSelector(getState, (state: IMyMoneyWalletState) => state.paymentMethod);

export const getPartyAccounts = createSelector(getState, (state: IMyMoneyWalletState) => state.partyAccounts);

export const getCardTopUp = createSelector(getState, (state: IMyMoneyWalletState) => state.cardTopup);

export const getSellerStatements = createSelector(getState, state => state?.sellerStatements);
