import { Component, Input } from '@angular/core';
import { IRow } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
})
export class TableRowComponent {
  @Input() rows: IRow[];
}
