import { Component } from '@angular/core';

@Component({
  selector: 'ui-chat-end-message',
  template: `
    <div class="ui-chat-end-message">
      <ui-divider [solid]="true"></ui-divider>
      <span class="text">
        <p class="small">CHAT HAS ENDED</p>
      </span>
      <ui-divider [solid]="true"></ui-divider>
    </div>
  `,
})
export class ChatMessageEndMessageComponent {}
