import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorConsoleLogger {
  info = (sessionId: string, error: HttpErrorResponse): void => {
    let log = '';

    if (sessionId) {
      log = `[SessionId: ${sessionId}]`;
    }

    if (error && error.status) {
      log = `${log} | [http status: ${error.status}]`;
    }

    if (error && error.error) {
      log = `${log} | [error: ${JSON.stringify(error.error)}]`;
    }
    console.error(log);
  };
}
