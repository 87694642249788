import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APPLICATION_FEATURE_KEY, State } from './application.reducer';

export const getApplicationState = createFeatureSelector<State>(APPLICATION_FEATURE_KEY);

export const getMaintenance = createSelector(getApplicationState, state =>
  state.maintenance?.maintenancePageON ? state.maintenance : null,
);
export const getCurrentLocation = createSelector(getApplicationState, state => state.currentLocation);
export const getAvoInfo = createSelector(getApplicationState, state => state.avoInfo);
export const isAuthorized = createSelector(getApplicationState, state => state.isAuthorized);
export const isLimitedUser = createSelector(getApplicationState, state => !state.avoInfo?.linkedToBank);
export const getReturnUrl = createSelector(getApplicationState, state => state.returnUrl);
export const getfuneralReturnUrl = createSelector(getApplicationState, state => state.funeralReturnUrl);
export const hasLocationAvailable = createSelector(getApplicationState, state => state.hasLocationAvailable);
export const getSearchOptions = createSelector(getApplicationState, state => state.searchOptions);
export const getCountryCode = createSelector(getApplicationState, state => state.countryCode);
export const getDiscoverAvoCategories = createSelector(getApplicationState, state => state.discoverAvoCategories);
export const getPrimersRedirectFinished = createSelector(getApplicationState, state => state.primersRedirectFinished);

export const hasPageVisited = createSelector(getApplicationState, state => state.hasPageVisited);

export const getCmpidValue = createSelector(getApplicationState, state => state.cmpid);
