import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import first from 'lodash-es/first';
import get from 'lodash-es/get';
import includes from 'lodash-es/includes';
import size from 'lodash-es/size';
import split from 'lodash-es/split';
import toNumber from 'lodash-es/toNumber';
import toString from 'lodash-es/toString';
import values from 'lodash-es/values';
import without from 'lodash-es/without';
import memoizeOne from 'memoize-one';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ui-pin',
  templateUrl: './pin.component.html',
})
export class PinComponent implements OnInit, AfterViewInit {
  id: string;

  @ViewChild('first', { static: true }) firstInput: ElementRef;

  @Input() pin: any = {};
  @Input() digits = 5;
  @Input() digitArray: number[] = [];
  @Input() showAllDigits: boolean;
  @Input() incorrectPin: boolean;
  @Input() errorMessage: string;
  @Output() onChange: EventEmitter<string[]> = new EventEmitter();

  makeId = memoizeOne((index: string | number): string => `${index}_${this.id}`);
  makeGetPinValue = memoizeOne((index: string | number): string => {
    const id = this.makeId(index);
    if (this.showAllDigits && get(this.pin, id)) return get(this.pin, id);
    else return get(this.pin, id) && '*';
  });

  constructor() {
    this.id = uuidv4();
  }

  ngAfterViewInit(): void {
    for (let i = 0; i < this.digits; i++) {
      this.digitArray.push(i);
    }
    setTimeout(() => {
      if (this.firstInput) {
        this.firstInput.nativeElement.focus();
      }
    });
  }

  private getSplitedPinId = (pinId: string | number): number => {
    const id = toString(pinId);
    const result = first(split(id, '_'));
    return toNumber(result);
  };

  private getNextPinId = (id: string): string => {
    const elementIndex = this.getSplitedPinId(id);
    if (elementIndex >= this.digits - 1) return;
    const nextElementId = elementIndex + 1;
    return this.makeId(nextElementId);
  };

  private getPreviousPinId = (id: string): string => {
    const elementIndex = this.getSplitedPinId(id);
    if (elementIndex === 0) return;
    const nextElementId = elementIndex - 1;
    return this.makeId(nextElementId);
  };

  private emitValue = (pin: { value: string }): void => {
    this.onChange.emit(without(values(pin), undefined));
  };

  /**
   * Function takes values on input and handle refocus on next, or previous Input element.
   * Also setting value, which handle each Input element.
   *
   * @param event - DOM event.
   */
  onKeyUp = (
    { key, target: { value, id } },
    allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
  ): void => {
    const hasKey = includes(allowedKeys, value);
    /**
     * Remove value on Backspace/Delete key.
     * Then refocus back to the previous element.
     */
    if (key === 'Backspace' || key === 'Delete') {
      this.pin[id] = undefined;
      // emit value to the upper layer
      this.emitValue(this.pin);

      const prevPinId = this.getPreviousPinId(id);
      if (prevPinId) document.getElementById(prevPinId).focus();
      return;
    }
    /**
     * Invalid expected value.
     */
    if (hasKey && this.pin[id] && size(this.pin[id]) > 0) {
      this.pin[id] = undefined;
      // emit value to the upper layer
      this.emitValue(this.pin);
      return;
    }
    /**
     * We have the right key and everything else is fine.
     * Set the new value, then refocus on the next.
     */
    if (hasKey) {
      // set value
      this.pin[id] = value;
      // emit value to the upper layer
      this.emitValue(this.pin);
      // find next element
      const nextPinId = this.getNextPinId(id);
      // if exists, focus him
      if (nextPinId) {
        document.getElementById(nextPinId).focus();
      } else {
        document.getElementById(id).blur();
        document.getElementById('blur-hack').focus();
        document.getElementById('blur-hack').blur();
      }
      return;
    }
    /**
     * Invalid key, fast way away.
     */
    // Clears DOM from restricted characters.
    (event.target as HTMLInputElement).value = '';
    this.pin[id] = undefined;
    this.emitValue(this.pin);
  };
  /**
   * Function handles state when user has incompleted PIN code.
   * In that case, will set @undefined and re-focus on the first element.
   * Focused input is cleared
   */
  onFocus = ({ target: { id } }): void => {
    // When focused removes content of inputfield.
    if (id) {
      this.pin[id] = undefined;
      this.emitValue(this.pin);
    }
    // Get prev pin element Id by current.
    const prevPinId = this.getPreviousPinId(id);
    // PIN index of the first element does'nt exist, there is no previous element.
    if (!prevPinId) return;
    // First get previous index by his Id (prev), then get the value.
    const prevPinValue = this.makeGetPinValue(this.getSplitedPinId(prevPinId));
    // Everything is fine, fast way out.
    if (prevPinValue) return;
    // PIN is incorrect, reset and focus the first one.
    this.pin = {};
    this.emitValue(this.pin);
    this.focusFirstInput();
  };
  // helper
  focusFirstInput = (): void => {
    const element = document.getElementById(this.makeId(0));
    if (!element) return;
    element.focus();
  };

  ngOnInit(): void {
    this.focusFirstInput();
  }
}
