import { Component, Injector, Input } from '@angular/core';
import { ResponsiveType } from '@wakanda/wakanda-core';
import { LayoutBaseContainer } from '../layout.base.container';

@Component({
  selector: 'wakanda-avo-business-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends LayoutBaseContainer {
  @Input() borderRadius = true;
  ResponsiveType = ResponsiveType;
  today = new Date();
  year: number;

  constructor(injector: Injector) {
    super(injector);
    this.year = this.today.getFullYear();
    this.subscriptions.add(this.responsiveService.getActiveScreenType().subscribe(type => (this.screenType = type)));
  }
}
