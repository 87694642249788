import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationEffects } from './store/application/application.effects';
import { TRANSLATE_FEATURE_KEY, TranslateEffects, translateReducer } from '@avo/shared/avo-translate';
import { APPLICATION_FEATURE_KEY, applicationReducer } from './store/application/application.reducer';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { AssetsEffects } from './store/assets/assets.effects';
import { AppConfigEffects } from './store/app-config/app-config.effects';
import { APP_CONFIG_FEATURE_KEY, appConfigReducer } from './store/app-config/app-config.reducer';
import { metaReducers } from './logout.meta-reducer';
import { TASKS_FEATURE_KEY, tasksReducer } from './store/tasks/tasks.reducer';
import { TasksEffects } from './store/tasks/tasks.effects';
import { TRANSACTIONS_FEATURE_KEY, transactionsReducer } from './store/transactions/transactions.reducer';
import { TransactionsEffects } from './store/transactions/transactions.effects';
import { LAYOUT_FEATURE_KEY, layoutReducer } from './store/layout/layout.reducer';
import { LayoutEffects } from './store/layout/layout.effects';
import { AUTH_FEATURE_KEY, authReducer } from './store/auth/auth.reducer';
import { AuthEffects } from './store/auth/auth.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    // uncomment only for local development!
    // StoreDevtoolsModule.instrument({ maxAge: 100}),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(APPLICATION_FEATURE_KEY, applicationReducer),
    StoreModule.forFeature(TASKS_FEATURE_KEY, tasksReducer),
    StoreModule.forFeature(TRANSACTIONS_FEATURE_KEY, transactionsReducer),
    StoreModule.forFeature(LAYOUT_FEATURE_KEY, layoutReducer),
    StoreModule.forFeature(APP_CONFIG_FEATURE_KEY, appConfigReducer),
    StoreModule.forFeature(TRANSLATE_FEATURE_KEY, translateReducer),
    StoreModule.forFeature(AUTH_FEATURE_KEY, authReducer),
    EffectsModule.forFeature([
      TranslateEffects,
      ApplicationEffects,
      AssetsEffects,
      AppConfigEffects,
      TasksEffects,
      TransactionsEffects,
      LayoutEffects,
      AuthEffects,
    ]),
  ],
})
export class CreateStoreModule {}
