import { Action } from '@ngrx/store';
import filter from 'lodash-es/filter';
import map from 'lodash-es/map';
import { INotificationsResponse } from '../../models/notifications.model';
import * as actions from './notifications.actions';
import { EVENTS } from './notifications.events';

export const NOTIFICATIONS_FEATURE_KEY = 'notifications';

export interface INotificationBelongsToJob {
  id: string;
  type: string;
}

export interface INotificationsState {
  data?: INotificationsResponse[];
  hasLoadedAll?: boolean;
  lastLoadedPage?: number;
  belongsToJobs?: INotificationBelongsToJob[];
}

export interface INotificationsPartialState {
  readonly [NOTIFICATIONS_FEATURE_KEY]: INotificationsState;
}

const ACTION_HANDLERS = {
  [EVENTS.NOTIFICATIONS_RECEIVED]: (
    state: INotificationsState,
    action: actions.NotificationsResponseAction,
  ): INotificationsState => {
    const combinedData: INotificationsResponse[] =
      action.page > 0 ? [...(state.data || []), ...action.data] : action.data;

    // workaround!!
    const belongsToJobs = map(
      filter(combinedData, item => item.type === 'JOB_MERCHANT' && !item.hidden),
      n => ({
        id: n.notificationId,
        type: n.title === 'New job request' ? 'NEW' : n.title === 'Job reservation done' ? 'ACTIVE' : 'INTERESTED',
      }),
    );

    return Object.assign({}, state, {
      belongsToJobs,
      data: combinedData,
      lastLoadedPage: action.page,
      hasLoadedAll: action.data.length === 0,
    });
  },

  [EVENTS.NOTIFICATIONS_HIDE_RECEIVED]: (
    state: INotificationsState,
    action: actions.HideNotificationResponseAction,
  ): INotificationsState =>
    Object.assign({}, state, {
      data: state.data.map(notification => {
        if (notification.notificationId === action.notificationId) return { ...notification, hidden: true };
        return notification;
      }),
    }),

  [EVENTS.NOTIFICATIONS_HIDE_ALL_RECEIVED]: (state: INotificationsState): INotificationsState =>
    Object.assign({}, state, {
      data: state.data.map(notification => ({ ...notification, hidden: true })),
    }),
};

export function notificationsReducer(state: INotificationsState = {}, action: Action): INotificationsState {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
