import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import {
  FilterSettlements,
  IDashboardWalletWidget,
  IFilterPayoutTransactionRequest,
  IFilterSettlementRequest,
  IGenerateRemittanceRequest,
  ILoyaltyPoint,
  IPayByCardRequest,
  IPayByCardResponse,
  IPayByCreditRequest,
  IPayByCreditResponse,
  IPayByWalletRequest,
  IPaymentInfo,
  IPaymentRemittanceExport,
  IQrCodeDecoded,
  ISellerStatement,
  ISellerStatementExport,
  ISellerStatementExportRequest,
  ISellerStatementRequest,
  ISettlementExport,
  ITaskDetail,
  ITaxInvoice,
  ITaxInvoiceRequest,
  ITopUpAndPayRequest,
  ITopUpAndPayResponse,
  ITransactionExport,
  IWallet,
  IWalletAddCardResponse,
  IWalletAddCardStatusResponse,
  IWalletAtm,
  IWalletBank,
  IWalletBankInfo,
  IWalletCardResponse,
  IWalletChangeSettlementSettingsRequest,
  IWalletCheckPayRequest,
  IWalletCheckPayResponse,
  IWalletCreditLoanTypeResponse,
  IWalletEditCardRequest,
  IWalletExternalAccount,
  IWalletPartyAccount,
  IWalletPaymentLinkResponse,
  IWalletPaymentRequest,
  IWalletPaymentRequestResponse,
  IWalletPayRecentRecipient,
  IWalletPayResponse,
  IWalletPhoneNumberRequest,
  IWalletPhoneNumberResponse,
  IWalletQrCodePaymentRequest,
  IWalletQrRequest,
  IWalletRecentRecipient,
  IWalletRecentSearch,
  IWalletRefund,
  IWalletRefundResponse,
  IWalletReminder,
  IWalletRenameRequest,
  IWalletRequest,
  IWalletRequestResponse,
  IWalletSettlementAccount,
  IWalletSettlementAccountRequest,
  IWalletSettlementAccountTypeRequest,
  IWalletSettlementRequest,
  IWalletSettlementResponse,
  IWalletSettlementSettings,
  IWalletStatementFilter,
  IWalletSubscription,
  IWalletSubscriptionAvailable,
  IWalletSubscriptionRequest,
  IWalletTopUpAccountRequest,
  IWalletTopUpAccountResponse,
  IWalletTopUpAddCardRequest,
  IWalletTopUpCardResponse,
  IWalletTopUpPayRequest,
  IWalletTopUpStatusResponse,
  IWalletTransaction,
  IWalletTransactionDetail,
  IWalletWithdrawRequest,
  IWalletWithdrawResponse,
  SellerPayoutTransactions,
  WalletDashboardWidgetType,
} from '../../models/wallet.model';
import { ApiService } from '../api/api.service';
import * as endpoints from './wallet.endpoints';

@Injectable()
export class WalletService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiV3Url;

  public fetchWallet(useMockApi = false): Observable<IWallet[]> {
    return this.api.get<IWallet[]>(endpoints.wallets(this.getApiUrl(useMockApi)));
  }

  public fetchWalletById(walletId: string, useMockApi = false): Observable<IWallet> {
    return this.api.get<IWallet>(endpoints.walletById(walletId, this.getApiUrl(useMockApi)));
  }

  public fetchTransactions(walletId: string, useMockApi = false): Observable<IWalletTransaction[]> {
    return this.api.get<IWalletTransaction[]>(endpoints.transactions(walletId, this.getApiUrl(useMockApi)));
  }

  public fetchPartyAccounts(useMockApi = false): Observable<IWalletPartyAccount[]> {
    return this.api.get<IWalletPartyAccount[]>(endpoints.partyAccounts(this.getApiUrl(useMockApi)));
  }

  public fetchCreditLoanType(): Observable<IWalletCreditLoanTypeResponse> {
    return this.api.get<IWalletCreditLoanTypeResponse>(endpoints.creditLoanType(this.getApiUrl(false)));
  }

  public fetchTransactionDetail(
    walletId: string,
    transactionId: string,
    useMockApi = false,
  ): Observable<IWalletTransactionDetail> {
    return this.api.get<IWalletTransactionDetail>(
      endpoints.transactionDetail(walletId, transactionId, this.getApiUrl(useMockApi)),
    );
  }

  public fetchSearch(walletId?: string, query?: string, useMockApi = false): Observable<IWalletTransaction[]> {
    return this.api.get<IWalletTransaction[]>(endpoints.transactionSearch(walletId, this.getApiUrl(useMockApi), query));
  }

  public fetchRecent(walletId?: string, useMockApi = false): Observable<IWalletRecentSearch[]> {
    return this.api.get<IWalletRecentSearch[]>(endpoints.transactionRecent(walletId, this.getApiUrl(useMockApi)));
  }

  public fetchPayRecentRecipients(walletId: string, useMockApi = false): Observable<IWalletPayRecentRecipient[]> {
    return this.api.get<IWalletPayRecentRecipient[]>(
      endpoints.payRecentRecipients(walletId, this.getApiUrl(useMockApi)),
    );
  }

  public setStatementFilter(
    walletId: string,
    statementFilter: IWalletStatementFilter,
    useMockApi = false,
  ): Observable<IWalletTransaction[]> {
    return this.api.post<IWalletStatementFilter>(
      endpoints.transactionFilter(walletId, this.getApiUrl(useMockApi)),
      statementFilter,
    );
  }

  public downloadStatement(
    walletId: string,
    statementFilter: IWalletStatementFilter,
    useMockApi = false,
  ): Observable<any> {
    return this.api.post<IWalletStatementFilter>(
      endpoints.transactionStatement(walletId, this.getApiUrl(useMockApi)),
      statementFilter,
      'blob',
    );
  }

  public reminder(
    walletId: string,
    transactionId: string,
    reminder: IWalletReminder,
    useMockApi = false,
  ): Observable<any> {
    return this.api.post<IWalletReminder>(
      endpoints.reminder(walletId, transactionId, this.getApiUrl(useMockApi)),
      reminder,
    );
  }

  public refund(
    walletId: string,
    transactionId: string,
    refund: IWalletRefund,
    useMockApi = false,
  ): Observable<IWalletRefundResponse> {
    return this.api.post<IWalletRefund>(endpoints.refund(walletId, transactionId, this.getApiUrl(useMockApi)), refund);
  }

  public request(
    walletId: string,
    paymentRequest: IWalletPaymentRequest,
    useMockApi = false,
  ): Observable<IWalletPaymentRequestResponse> {
    return this.api.post<IWalletPaymentRequest>(
      endpoints.request(walletId, this.getApiUrl(useMockApi)),
      paymentRequest,
    );
  }

  public phoneBoook(
    walletId: string,
    phoneBook: IWalletPhoneNumberRequest[],
    useMockApi = false,
  ): Observable<IWalletPhoneNumberResponse[]> {
    return this.api.post<IWalletPhoneNumberRequest[]>(
      endpoints.phonebook(walletId, this.getApiUrl(useMockApi)),
      phoneBook,
    );
  }

  public pay(
    walletId: string,
    paymentRequest: IWalletPaymentRequest,
    useMockApi = false,
  ): Observable<IWalletPayResponse> {
    return this.api.post<IWalletPaymentRequest>(endpoints.pay(walletId, this.getApiUrl(useMockApi)), paymentRequest);
  }

  public payByQRCode(
    walletId: string,
    paymentRequest: IWalletQrCodePaymentRequest,
    useMockApi = false,
  ): Observable<any> {
    return this.api.post<any>(endpoints.payByQrCode(walletId, this.getApiUrl(useMockApi)), paymentRequest);
  }

  public withdraw(
    walletId: string,
    withdrawRequest: IWalletWithdrawRequest,
    useMockApi = false,
  ): Observable<IWalletWithdrawResponse> {
    return this.api.post<IWalletWithdrawRequest>(
      endpoints.withdraw(walletId, this.getApiUrl(useMockApi)),
      withdrawRequest,
    );
  }

  public fetchPaymentRecent(walletId: string, useMockApi = false): Observable<IWalletRecentRecipient[]> {
    return this.api.get<IWalletRecentRecipient[]>(endpoints.recent(walletId, this.getApiUrl(useMockApi)));
  }

  public fetchExternalAccounts(
    walletId: string,
    accountType: string,
    useMockApi = false,
  ): Observable<IWalletExternalAccount[]> {
    return this.api.get<IWalletExternalAccount[]>(
      endpoints.externalAccounts(walletId, accountType, this.getApiUrl(useMockApi)),
    );
  }

  public fetchCardWithDestination(
    walletId: string,
    accountType: string,
    useMockApi = false,
  ): Observable<IWalletTopUpCardResponse[]> {
    return this.api.get<IWalletTopUpCardResponse[]>(
      endpoints.cardWithDestination(walletId, accountType, this.getApiUrl(useMockApi)),
    );
  }

  public sendPaymentLink(walletId: string, form: any): Observable<any> {
    return this.api.post<any>(endpoints.sendPaymentLink(walletId, this.appConfig.backendConfig.apiUrl), form);
  }

  public fetchPaymentLink(paymentLink: string): Observable<any> {
    return this.api.get<IWalletPaymentLinkResponse>(
      endpoints.fetchPaymentLink(paymentLink, this.appConfig.backendConfig.apiUrl),
    );
  }

  public setWalletAsMain(walletId: string): Observable<IWallet> {
    return this.api.patch(endpoints.setWalletAsMain(walletId, this.appConfig.backendConfig.apiUrl), {});
  }

  public renameWallet(walletId: string, request: IWalletRenameRequest): Observable<IWallet> {
    return this.api.post(endpoints.renameWallet(walletId, this.appConfig.backendConfig.apiUrl), request);
  }

  public acceptPaymentLink(walletId: string, paymentId: string): Observable<any> {
    return this.api.post(endpoints.acceptPaymentLink(walletId, paymentId, this.appConfig.backendConfig.apiUrl));
  }

  public fetchCardsAndAccountByExternalAccountId(
    walletId: string,
    externalAccountId: string,
    useMockApi = false,
  ): Observable<IWalletExternalAccount> {
    return this.api.get<IWalletExternalAccount>(
      endpoints.cardsAndAccountById(walletId, externalAccountId, this.getApiUrl(useMockApi)),
    );
  }

  public deleteCardsAndAccountByExternalAccountId(
    walletId: string,
    externalAccountId: string,
    useMockApi = false,
  ): Observable<IWalletExternalAccount> {
    return this.api.remove(endpoints.cardsAndAccountById(walletId, externalAccountId, this.getApiUrl(useMockApi)));
  }

  public validateNewExternalAccount(
    walletId: string,
    newExternalAccount: IWalletExternalAccount,
    useMockApi = false,
  ): Observable<any> {
    return this.api.post<IWalletExternalAccount>(
      endpoints.validateNewExternalAccount(walletId, this.getApiUrl(useMockApi)),
      newExternalAccount,
    );
  }

  public addNewExternalAccount(
    walletId: string,
    newExternalAccount: IWalletExternalAccount,
    useMockApi = false,
  ): Observable<any> {
    return this.api.post<IWalletExternalAccount>(
      endpoints.addNewExternalAccount(walletId, this.getApiUrl(useMockApi)),
      newExternalAccount,
    );
  }

  public filterSettlements(
    filter: IFilterSettlementRequest,
    pageSize: number,
    next: number,
  ): Observable<FilterSettlements> {
    return this.api.post(endpoints.settlementFilter(this.getApiUrl(false), { pageSize, next }), filter);
  }

  public generatePaymentRemittance(
    storeId: string,
    request: IGenerateRemittanceRequest,
  ): Observable<IPaymentRemittanceExport> {
    return this.api.post(endpoints.generateRemittance(storeId, this.getApiUrl(false)), request);
  }

  public fetchTaxInvoices(
    filter: ITaxInvoiceRequest,
    storeId: string,
    pageSize: number,
    page: number,
  ): Observable<ITaxInvoice> {
    return this.api.get(endpoints.taxInvoices(this.getApiUrl(false), storeId, { pageSize, page }), filter);
  }

  public fetchTaxInvoice(taxInvoiceId: number, storeId: string): Observable<ITaxInvoice> {
    return this.api.get(endpoints.taxInvoiceById(taxInvoiceId, storeId, this.getApiUrl(false)));
  }

  public downloadTaxInvoice(taxInvoiceId: string, storeId: string): Observable<string> {
    return this.api.get(endpoints.taxInvoiceDownload(taxInvoiceId, storeId, this.getApiUrl(false)), {}, {}, 'blob');
  }

  public filterSettlementsExport(filter: IFilterSettlementRequest, storeId: string): Observable<ISettlementExport> {
    return this.api.get(endpoints.settlementFilterExport(this.getApiUrl(false), storeId), filter);
  }

  public filterTransactionsExport(
    filter: IFilterPayoutTransactionRequest,
    storeId: string,
  ): Observable<ITransactionExport> {
    return this.api.get(endpoints.transactionsFilterExport(this.getApiUrl(false), storeId, filter));
  }

  public sellerStatement = (
    request: ISellerStatementRequest,
    storeId: string,
    pageSize: number,
    page: number,
  ): Observable<ISellerStatement> =>
    this.api.get<ISellerStatement>(
      endpoints.sellerStatement(this.getApiUrl(false), storeId, {
        pageSize,
        page,
      }),
      request,
    );

  public generateSellerStatement(
    filter: ISellerStatementExportRequest,
    storeId: string,
  ): Observable<ISellerStatementExport> {
    return this.api.post(endpoints.generateSellerStatement(this.getApiUrl(false), storeId), filter);
  }

  public downloadSellerStatement(taxInvoiceId: string, storeId: string): Observable<string> {
    return this.api.get(endpoints.statementDownload(taxInvoiceId, storeId, this.getApiUrl(false)), {}, {}, 'blob');
  }

  public fetchTaskFile(taskId: string): Observable<string> {
    return this.api.get(endpoints.getTaskFile(taskId, this.getApiUrl(false)), {}, {}, 'blob');
  }

  public fetchTaskDetail(taskId: string): Observable<ITaskDetail> {
    return this.api.get(endpoints.getTaskDetail(taskId, this.getApiUrl(false)));
  }

  public filterPayoutTransactions(
    storeId: string,
    filter: IFilterPayoutTransactionRequest,
    pageSize: number,
    next: number,
  ): Observable<SellerPayoutTransactions> {
    return this.api.get(endpoints.filterPayoutTransactions(this.getApiUrl(false), storeId, { pageSize, next }), filter);
  }

  public addSettlementAccount(settlementAccount: IWalletSettlementAccountRequest, useMockApi = false): Observable<any> {
    return this.api.post<IWalletSettlementAccountRequest>(
      endpoints.addSettlementAccount(this.getApiUrl(useMockApi)),
      settlementAccount,
    );
  }

  public updateSettlementAccount(
    accountId: string,
    newExternalAccount: IWalletSettlementAccountRequest,
    useMockApi = false,
  ): Observable<any> {
    return this.api.put<IWalletSettlementAccountRequest>(
      endpoints.settlementAccount(accountId, this.getApiUrl(useMockApi)),
      newExternalAccount,
    );
  }

  public updateSettlementAccountType(
    accountId: string,
    request: IWalletSettlementAccountTypeRequest,
    useMockApi = false,
  ): Observable<any> {
    return this.api.patch<IWalletSettlementAccountTypeRequest>(
      endpoints.settlementAccount(accountId, this.getApiUrl(useMockApi)),
      request,
    );
  }

  public deleteSettlementAccount(accountId: string, useMockApi = false): Observable<any> {
    return this.api.remove(endpoints.settlementAccount(accountId, this.getApiUrl(useMockApi)));
  }

  public fetchSettlementAccount(useMockApi = false): Observable<any> {
    return this.api.get<IWalletSettlementAccount[]>(endpoints.fetchSettlementAccount(this.getApiUrl(useMockApi)));
  }

  public updateSettlementSettings(
    request: IWalletChangeSettlementSettingsRequest,
    useMockApi = false,
  ): Observable<any> {
    return this.api.put<IWalletChangeSettlementSettingsRequest>(
      endpoints.settlementSettings(this.getApiUrl(useMockApi)),
      request,
    );
  }

  public fetchSettlementSettings(useMockApi = false): Observable<any> {
    return this.api.get<IWalletSettlementSettings>(endpoints.settlementSettings(this.getApiUrl(useMockApi)));
  }

  public removeExternalAccount(walletId: string, externalAccountId: string, useMockApi = false): Observable<any> {
    return this.api.remove(endpoints.removeExternalAccount(walletId, externalAccountId, this.getApiUrl(useMockApi)));
  }

  public fetchAtms(useMockApi = false): Observable<IWalletAtm[]> {
    return this.api.get<IWalletAtm[]>(endpoints.atms(this.getApiUrl(useMockApi)));
  }

  public generateQRCode(walletId: string, qrDataRequest: IWalletQrRequest, useMockApi = false): Observable<string> {
    return this.api.post<IWalletQrRequest>(
      endpoints.qrCode(walletId, this.getApiUrl(useMockApi)),
      qrDataRequest,
      'text',
    );
  }

  public decodeQRCode(data: string, useMockApi = false): Observable<IQrCodeDecoded> {
    return this.api.post<string>(endpoints.decodeQrCode(this.getApiUrl(useMockApi)), data);
  }

  public sendRequest(
    walletId: string,
    request: IWalletRequest,
    useMockApi = false,
  ): Observable<IWalletRequestResponse> {
    return this.api.post<IWalletRequest>(endpoints.request(walletId, this.getApiUrl(useMockApi)), request);
  }

  public createSettlement(
    walletId: string,
    request: IWalletSettlementRequest,
    useMockApi = false,
  ): Observable<IWalletSettlementResponse> {
    return this.api.post<IWalletSettlementRequest>(endpoints.settle(walletId, this.getApiUrl(useMockApi)), request);
  }

  public banks(useMockApi = false): Observable<IWalletBank[]> {
    return this.api.get<IWalletBank[]>(endpoints.banks(this.getApiUrl(useMockApi)));
  }

  public nedbankBanks(useMockApi = false): Observable<IWalletBankInfo[]> {
    return this.api.get<IWalletBankInfo[]>(endpoints.nedbankBanks(this.getApiUrl(useMockApi)));
  }

  public getCard(walletId: string, useMockApi = false): Observable<IWalletCardResponse> {
    return this.api.get<IWalletCardResponse>(endpoints.card(walletId, this.getApiUrl(useMockApi)));
  }

  public addCard(
    walletId: string,
    addCardRequest: IWalletTopUpAddCardRequest,
    userMockApi = false,
  ): Observable<IWalletAddCardResponse> {
    return this.api.post<IWalletTopUpAddCardRequest>(
      endpoints.card(walletId, this.getApiUrl(userMockApi)),
      addCardRequest,
    );
  }

  public editCard(
    walletId: string,
    cardId: string,
    request: IWalletEditCardRequest,
    useMockApi = false,
  ): Observable<any> {
    return this.api.put<IWalletEditCardRequest>(
      endpoints.editDeleteCard(walletId, cardId, this.getApiUrl(useMockApi)),
      request,
    );
  }

  public deleteCard(walletId: string, cardId: string, useMockApi = false): Observable<any> {
    return this.api.remove(endpoints.editDeleteCard(walletId, cardId, this.getApiUrl(useMockApi)));
  }

  public topupCard(
    walletId: string,
    cardId: string,
    pay: IWalletTopUpPayRequest,
    useMockApi = false,
  ): Observable<IWalletAddCardResponse> {
    return this.api.post<IWalletTopUpPayRequest>(
      endpoints.topupCard(walletId, cardId, this.getApiUrl(useMockApi)),
      pay,
    );
  }

  public checkTopUpCard(
    walletId: string,
    cardId: string,
    topUpId: string,
    useMockApi = false,
  ): Observable<IWalletAddCardStatusResponse> {
    return this.api.get<IWalletAddCardStatusResponse>(
      endpoints.checkTopUpCard(walletId, cardId, topUpId, this.getApiUrl(useMockApi)),
    );
  }
  public fetchLoyaltyPoints(): Observable<ILoyaltyPoint> {
    return this.api.get<ILoyaltyPoint>(endpoints.loyaltyPoints(this.getApiUrl(false)));
  }

  public checkPayment(walletId: string, payload: IWalletCheckPayRequest): Observable<IWalletCheckPayResponse> {
    return this.api.post(endpoints.payCheck(walletId, this.appConfig.backendConfig.apiUrl), payload);
  }

  public topUpAccount(walletId: string, payload: IWalletTopUpAccountRequest): Observable<IWalletTopUpAccountResponse> {
    return this.api.post(endpoints.topupAccount(walletId, this.appConfig.backendConfig.apiUrl), payload);
  }

  public topupAccountStatus(walletId: string, topupId: string): Observable<IWalletTopUpStatusResponse> {
    return this.api.get(endpoints.topupAccountStatus(walletId, topupId, this.appConfig.backendConfig.apiUrl));
  }

  public topupCardStatus(walletId: string, cardId: string, topupId: string): Observable<IWalletTopUpStatusResponse> {
    return this.api.get(endpoints.topupCardStatus(walletId, cardId, topupId, this.appConfig.backendConfig.apiUrl));
  }

  public fetchSubscriptions(): Observable<IWalletSubscription[]> {
    return this.api.get<IWalletSubscription[]>(endpoints.subscription(this.appConfig.backendConfig.apiUrl));
  }

  public fetchMerchantSubscriptions(): Observable<IWalletSubscription[]> {
    return this.api.get<IWalletSubscription[]>(endpoints.subscriptionMerchant(this.appConfig.backendConfig.apiUrl));
  }

  public fetchSubscriptionAvailableSubscriptions(): Observable<IWalletSubscriptionAvailable[]> {
    return this.api.get<IWalletSubscriptionAvailable[]>(
      endpoints.subscriptionAvailable(this.appConfig.backendConfig.apiUrl),
    );
  }

  public fetchSubscriptionAvailableMerchantSubscriptions(): Observable<IWalletSubscriptionAvailable[]> {
    return this.api.get<IWalletSubscriptionAvailable[]>(
      endpoints.subscriptionAvailableMerchant(this.appConfig.backendConfig.apiUrl),
    );
  }

  public addSubscription(subscription: IWalletSubscriptionRequest): Observable<IWalletSubscription> {
    return this.api.post<IWalletSubscriptionRequest>(
      endpoints.subscription(this.appConfig.backendConfig.apiUrl),
      subscription,
    );
  }

  public addMerchantSubscription(subscription: IWalletSubscriptionRequest): Observable<IWalletSubscription> {
    return this.api.post<IWalletSubscriptionRequest>(
      endpoints.subscriptionMerchant(this.appConfig.backendConfig.apiUrl),
      subscription,
    );
  }

  public activateSubscription(subscriptionId: string): Observable<any> {
    return this.api.post(endpoints.subscriptionActivate(subscriptionId, this.appConfig.backendConfig.apiUrl));
  }

  public deactivateSubscription(subscriptionId: string): Observable<any> {
    return this.api.post(endpoints.subscriptionDeactivate(subscriptionId, this.appConfig.backendConfig.apiUrl));
  }

  public deactivateMerchantSubscription(subscriptionId: string): Observable<any> {
    return this.api.post(endpoints.subscriptionMerchantDeactivate(subscriptionId, this.appConfig.backendConfig.apiUrl));
  }

  public fetchDashboardWallet(type: WalletDashboardWidgetType) {
    return this.api.get<IDashboardWalletWidget>(
      endpoints.fetchDashboardWallet(this.appConfig.backendConfig.apiUrl, type),
    );
  }

  public fetchPayInfo(flowId: string): Observable<IPaymentInfo> {
    return this.api.get<any>(endpoints.fetchPayInfo(flowId, this.appConfig.backendConfig.apiUrl));
  }

  public payByCard(flowId: string, request: IPayByCardRequest): Observable<IPayByCardResponse> {
    return this.api.post<IPayByCardRequest>(
      endpoints.payByCard(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }

  public payByPointsOnly(flowId: string): Observable<void> {
    return this.api.post<void>(endpoints.payByPointsOnly(flowId, this.appConfig.backendConfig.apiV3Url));
  }

  public topUpAndPay(flowId: string, request: ITopUpAndPayRequest): Observable<ITopUpAndPayResponse> {
    return this.api.post<ITopUpAndPayRequest>(
      endpoints.topUpAndPay(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }

  public payByWallet(flowId: string, request: IPayByWalletRequest): Observable<IWalletPayResponse> {
    return this.api.post<IPayByWalletRequest>(
      endpoints.payByWallet(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }

  public payForFree(flowId: string): Observable<void> {
    return this.api.post<void>(endpoints.payForFree(flowId, this.appConfig.backendConfig.apiV3Url));
  }

  public payByEft(flowId: string): Observable<void> {
    return this.api.post<void>(endpoints.payByEft(flowId, this.appConfig.backendConfig.apiV3Url));
  }

  public payByAmex(flowId: string, request: IPayByCardRequest): Observable<IPayByCardResponse> {
    return this.api.post<IPayByCardRequest>(
      endpoints.payByAmex(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }

  public payByCredit(flowId: string, request: IPayByCreditRequest): Observable<IPayByCreditResponse> {
    return this.api.post<IPayByCreditRequest>(
      endpoints.payByCredit(flowId, this.appConfig.backendConfig.apiV3Url),
      request,
    );
  }

  public fetchWalletSubscription(): Observable<any> {
    return this.api.get(endpoints.fetchWalletSubscription(this.appConfig.backendConfig.apiUrl));
  }
}
