<div class="row between contact-support-card">
  <p zen-translate>merchant.b2c_pre_onboarding_landing.cant_find</p>
  <ui-divider [vertical]="true"></ui-divider>
  <ui-button
    (onClick)="openSupportModal()"
    [selectButton]="true"
    [callToActionButton]="true"
    [content]="'merchant.b2c_pre_onboarding_landing.contact_support' | translate"
  ></ui-button>
</div>
