import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import {
  AdvertisingScreenType,
  IAdvertisingBannerClickRequest,
  IAdvertisingBannerFormRequest,
  IAdvertisingBannerRequest,
} from '../../models/advertising.model';
import { started } from '../application/task.actions';
import { INotificationsPartialState } from '../notifications/notifications.reducer';
import * as actions from './advertising.actions';
import * as selectors from './advertising.selectors';

@Injectable()
export class AdvertisingFacade {
  getBanner$ = this.store.pipe(select(selectors.getBanner));
  constructor(private store: Store<INotificationsPartialState>) {}

  getHomePageBanner = (): void => {
    const bannerRequest: IAdvertisingBannerRequest = {
      screenId: AdvertisingScreenType.HOMEPAGE,
    };
    this.getBanner(bannerRequest);
  };

  getProductDetailsBanner = (sku: string): void => {
    const bannerRequest: IAdvertisingBannerRequest = {
      screenId: AdvertisingScreenType.PRODUCT_DETAILS,
      referenceObjectId: sku,
    };
    this.getBanner(bannerRequest);
  };

  getEshopCategoryBanner = (categoryId: string): void => {
    const bannerRequest: IAdvertisingBannerRequest = {
      screenId: AdvertisingScreenType.ESHOP_CATEGORY,
      referenceObjectId: categoryId,
    };
    this.getBanner(bannerRequest);
  };

  getVouchersBanner = (): void => {
    const bannerRequest: IAdvertisingBannerRequest = {
      screenId: AdvertisingScreenType.VOUCHERS,
    };
    this.getBanner(bannerRequest);
  };

  getVasAirtimeBanner = (): void => {
    const bannerRequest: IAdvertisingBannerRequest = {
      screenId: AdvertisingScreenType.VAS_AIRTIME,
    };
    this.getBanner(bannerRequest);
  };

  getVasElectricityBanner = (): void => {
    const bannerRequest: IAdvertisingBannerRequest = {
      screenId: AdvertisingScreenType.VAS_ELECTRICITY,
    };
    this.getBanner(bannerRequest);
  };

  getVasBanner = (): void => {
    const bannerRequest: IAdvertisingBannerRequest = {
      screenId: AdvertisingScreenType.VAS,
    };
    this.getBanner(bannerRequest);
  };

  getServicesBanner = (): void => {
    const bannerRequest: IAdvertisingBannerRequest = {
      screenId: AdvertisingScreenType.SERVICES,
    };
    this.getBanner(bannerRequest);
  };

  getHealthcareBanner = (): void => {
    const bannerRequest: IAdvertisingBannerRequest = {
      screenId: AdvertisingScreenType.HEALTH_LANDING,
    };
    this.getBanner(bannerRequest);
  };

  private getBanner = (bannerRequest: IAdvertisingBannerRequest): void => {
    const taskId = `fetching-banner-${bannerRequest.screenId}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.AdvertisingBannerRequestAction(taskId, bannerRequest), 'Fetching banner', true),
    );
  };

  public sendBannerClick = (
    bannerId: string,
    bannerClick: IAdvertisingBannerClickRequest,
    onSucceed: () => void,
    silently = true,
  ): void => {
    const taskId = `send-banner-click-${bannerId}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.AdvertisingBannerClickRequestAction(taskId, bannerId, bannerClick, onSucceed), '', silently),
    );
  };

  public sendBannerForm = (
    bannerId: string,
    bannerForm: IAdvertisingBannerFormRequest,
    onSucceed: () => void,
  ): void => {
    const taskId = `send-banner-form-${bannerId}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.AdvertisingBannerFormRequestAction(taskId, bannerId, bannerForm, onSucceed)),
    );
  };
}
