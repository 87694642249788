import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ApplicationFacade,
  AuthorizationService,
  failed,
  GuestAuthorizationService,
  HttpErrorAction,
  NotificationService,
  pushNotification,
  started,
  succeed,
} from '@wakanda/wakanda-core';
import get from 'lodash-es/get';
import { mergeMap, of, Subscription, timer } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import * as models from '../models/responses.model';
import {
  IAuthAccessToken,
  IZenAuthAccessToken,
  IZenAuthOnboardingAddressSuggestResponse,
} from '../models/responses.model';
import { OnboardingService, TransactionSigningService } from '../services';
import { AuthService } from '../services/auth.service';
import * as actions from './zenAuth.actions';
import { EVENT } from './zenAuth.events';
import { v4 as uuidv4 } from 'uuid';
import { TranslateFacade } from '@wakanda/zentity-translate-store';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { ZenAuthFacade } from './zenAuth.facade';
import { ApplicationFacade as AppFacade } from '@avo/shared/core';
import isEmpty from 'lodash-es/isEmpty';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class ZenAuthEffects {
  refreshTokenSubscription: Subscription;
  start$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.StartRequestAction>(EVENT.LOGIN_START_REQUEST),
      switchMap(payload =>
        this.service.start().pipe(
          map((data: models.IZenAuthAuthenticateResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.StartResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  passwordlessStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.StartPasswordlessRequestAction>(EVENT.LOGIN_START_PASSWORDLESS_REQUEST),
      switchMap(({ taskId, payload, onSucceeded }) =>
        this.service.passwordlessStart(payload).pipe(
          map((data: models.IAuthNextStep) => {
            if (onSucceeded) onSucceeded(data);
            return succeed(new actions.StartPasswordlessResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  loginOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoginOtpRequestAction>(EVENT.LOGIN_OTP_REQUEST),
      switchMap(({ taskId, payload, onSucceeded, onError }) =>
        this.service.loginOtp(payload).pipe(
          map(data => {
            if (onSucceeded) onSucceeded(data);
            return succeed(new actions.LoginOtpResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  loginOtpResend$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoginOtpResendRequestAction>(EVENT.LOGIN_OTP_RESEND_REQUEST),
      switchMap(({ taskId, payload, onSucceeded }) =>
        this.service.loginOtpResend(payload).pipe(
          map(data => {
            if (onSucceeded) onSucceeded(data);
            return succeed(new actions.LoginOtpResendResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  loginRefreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoginRefreshTokenRequestAction>(EVENT.LOGIN_REFRESH_TOKEN_REQUEST),
      mergeMap(({ taskId, payload }) =>
        this.service.loginRefreshToken(payload).pipe(
          switchMap(data => {
            if (this.authService.getAccessToken()) {
              const token: IZenAuthAccessToken = {
                access_token: (data.token as IAuthAccessToken).accessToken,
                expires_in: (data.token as IAuthAccessToken).expiresIn,
                token_type: (data.token as IAuthAccessToken).tokenType,
                refresh_token: (data.token as IAuthAccessToken).refreshToken,
              };
              return [
                new actions.ZenAuthorizationTokenSetAction(taskId, token),
                new actions.LoginRefreshTokenResponseAction(taskId, data),
              ];
            } else {
              return [new actions.ZenAuthorizationTokenSetAction(taskId, null)];
            }
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  loginProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoginProfileRequestAction>(EVENT.LOGIN_PROFILE_REQUEST),
      switchMap(({ taskId, payload, onSucceeded }) =>
        this.service.loginProfile(payload).pipe(
          map(data => {
            if (onSucceeded) onSucceeded(data);
            return succeed(new actions.LoginProfileResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  loginGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoginGoogleRequestAction>(EVENT.LOGIN_GOOGLE_REQUEST),
      switchMap(({ taskId, payload, onSucceeded }) =>
        this.service.loginGoogle(payload).pipe(
          map(data => {
            if (onSucceeded) onSucceeded(data);
            return succeed(new actions.LoginGoogleResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  loginFacebook$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoginFacebookRequestAction>(EVENT.LOGIN_FACEBOOK_REQUEST),
      switchMap(({ taskId, payload, onSucceeded }) =>
        this.service.loginFacebook(payload).pipe(
          map(data => {
            if (onSucceeded) onSucceeded(data);
            return succeed(new actions.LoginFacebookResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  loginApple$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoginAppleRequestAction>(EVENT.LOGIN_APPLE_REQUEST),
      switchMap(({ taskId, payload, onSucceeded }) =>
        this.service.loginApple(payload).pipe(
          map(data => {
            if (onSucceeded) onSucceeded(data);
            return succeed(new actions.LoginAppleResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  startSocial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.StartSocialRequestAction>(EVENT.LOGIN_START_SOCIAL_REQUEST),
      switchMap(payload =>
        this.service.startSocial(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            this.notificationService.registerNotifications();
            return succeed(new actions.StartSocialResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (typeof payload.onError === 'function') payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  mobimoneyLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthLoginMobimoneyRequestAction>(EVENT.LOGIN_MOBIMONEY_REQUEST),
      switchMap(payload =>
        this.service.loginMobimoney().pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthLoginMobimoneyResponseAction(payload.taskId, data));
          }),

          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  nedidLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthLoginNedidRequestAction>(EVENT.LOGIN_NEDID_REQUEST),
      switchMap(payload =>
        this.service.loginNedid().pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthLoginNedidResponseAction(payload.taskId, data));
          }),

          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthLogoutRequestAction>(EVENT.LOGOUT_REQUEST),
      switchMap(({ taskId, onSucceed, afterSucceed }) =>
        this.service.logout().pipe(
          switchMap(() => {
            this.removeRefreshTokenTimer();
            if (onSucceed) onSucceed();
            return timer(1000).pipe(
              switchMap(() => {
                if (afterSucceed) afterSucceed();
                return of(succeed(new actions.ZenAuthLogoutResponseAction(taskId)));
              }),
            );
          }),
          catchError(error => {
            if (onSucceed) onSucceed();
            return timer(1000).pipe(
              switchMap(() => {
                if (afterSucceed) afterSucceed();
                return of(failed(new HttpErrorAction(taskId, error)));
              }),
            );
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  logoutSocial$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.ZenAuthLogoutRequestAction>(EVENT.LOGOUT_REQUEST),
        tap(() =>
          this.socialAuthService.authState
            .pipe(
              take(1),
              tap(authState => {
                if (authState) {
                  this.socialAuthService.signOut();
                }
              }),
            )
            .subscribe(),
        ),
      ),
    { dispatch: false },
  );

  onboardingStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingStartRequestAction>(EVENT.ONBOARDING_START_REQUEST),
      switchMap(payload =>
        this.onboardingService.start(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingStartResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  basicData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBasicDataRequestAction>(EVENT.ONBOARDING_BASIC_DATA_REQUEST),
      switchMap(({ taskId, payload, onSucceeded, onError }) =>
        this.onboardingService.basicData(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (typeof onSucceeded === 'function') onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingBasicDataResponseAction(taskId, data));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  businessBasicData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBusinessBasicDataRequestAction>(EVENT.ONBOARDING_BUSINESS_BASIC_DATA_REQUEST),
      switchMap(({ taskId, payload, onSucceeded, onError }) =>
        this.onboardingService.businessBasicData(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (typeof onSucceeded === 'function') onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingBusinessBasicDataResponseAction(taskId, data));
          }),
          catchError(error => {
            if (typeof onError === 'function') onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  idPassport$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingIdPassportRequestAction>(EVENT.ONBOARDING_ID_PASSPORT_REQUEST),
      switchMap(payload =>
        this.onboardingService.idPassport(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingIdPassportResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (typeof payload.onError === 'function') payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  legalEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingLegalEntityRequestAction>(EVENT.ONBOARDING_LEGAL_ENTITY_REQUEST),
      switchMap(payload =>
        this.onboardingService.legalEntity(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingLegalEntityResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (typeof payload.onError === 'function') payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  businessLegalEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBusinessLegalEntityRequestAction>(EVENT.ONBOARDING_BUSINESS_LEGAL_ENTITY_REQUEST),
      switchMap(payload =>
        this.onboardingService.businessLegalEntity(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingBusinessLegalEntityResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (typeof payload.onError === 'function') payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  countries$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingCountriesRequestAction>(EVENT.ONBOARDING_COUNTRIES_REQUEST),
      switchMap(payload =>
        this.onboardingService.countries().pipe(
          map((data: models.IZenAuthOnboardingCountry[]) => {
            if (payload.onSucceeded) payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingCountriesResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingCheckBank$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingCheckBankRequestAction>(EVENT.ONBOARDING_CHECK_BANK_REQUEST),
      switchMap(({ taskId, payload, merchantId, onSucceeded, onError }) =>
        this.onboardingService.checkBank(payload, merchantId).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingCheckBankResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError();
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingOutstandingTCs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingOutstandingTcsRequestAction>(EVENT.ONBOARDING_OUTSTANDNG_TCS_REQUEST),
      switchMap(({ taskId, payload, onSucceeded, onError }) =>
        this.onboardingService.outstandingTcs(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingOutstandingTcsResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingTCs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingTcsRequestAction>(EVENT.ONBOARDING_TCS_REQUEST),
      switchMap(({ taskId, payload, onSucceeded, onError }) =>
        this.onboardingService.tcs(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingTcsResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  linkAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingLinkAccountRequestAction>(EVENT.ONBOARDING_LINK_ACCOUNT_REQUEST),
      switchMap(({ taskId, payload, onSucceeded, onError }) =>
        this.onboardingService.linkAccount(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingLinkAccountResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  startLinkAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingStartLinkAccountRequestAction>(EVENT.ONBOARDING_START_LINK_ACCOUNT_REQUEST),
      switchMap(({ taskId, payload, onSucceeded, onError }) =>
        this.onboardingService.startLinkAccount(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingStartLinkAccountResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  initiateLinkAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingInitiateLinkAccountRequestAction>(EVENT.ONBOARDING_INITIATE_LINK_ACCOUNT_REQUEST),
      switchMap(({ taskId, onSucceeded, onError }) =>
        this.onboardingService.initiateLinkAccount().pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingInitiateLinkAccountResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingStepup$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingStepupRequestAction>(EVENT.ONBOARDING_STEPUP_REQUEST),
      switchMap(({ request, taskId, onError, onSucceeded }) =>
        this.onboardingService.stepup(request).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingStepupResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError();
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingHealthStepup$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingHealthStepupRequestAction>(EVENT.ONBOARDING_HEALTH_STEPUP_REQUEST),
      switchMap(({ request, taskId, onError, onSucceeded }) =>
        this.onboardingService.healthStepup(request).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingHealthStepupResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError();
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingInfoStepup$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingInfoStepUpRequestAction>(EVENT.ONBOARDING_INFO_STEPUP_REQUEST),
      switchMap(({ taskId, request, onSucceeded, onError }) =>
        this.onboardingService.infoStepUp(request).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingStepupResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingSelectOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSelectOtpRequestAction>(EVENT.ONBOARDING_SELECT_OTP_REQUEST),
      switchMap(({ payload, taskId, onError, onSucceeded }) =>
        this.onboardingService.selectOtp(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingSelectOtpResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingSelectSocial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSelectSocialRequestAction>(EVENT.ONBOARDING_SELECT_SOCIAL_REQUEST),
      switchMap(({ payload, taskId, onError, onSucceeded }) =>
        this.onboardingService.selectSocial(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingSelectSocialResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingSignup$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSignupRequestAction>(EVENT.ONBOARDING_SIGNUP_REQUEST),
      switchMap(({ payload, taskId, onError, onSucceeded }) =>
        this.onboardingService.signup(payload).pipe(
          map((data: models.IZenAuthSignupInfoResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingSignupResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingSignupSocial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSignupSocialRequestAction>(EVENT.ONBOARDING_SIGNUP_SOCIAL_REQUEST),
      switchMap(({ payload, taskId, onError, onSucceeded }) =>
        this.onboardingService.signupSocial(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingSignupSocialResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingSignupManual$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSignupManualRequestAction>(EVENT.ONBOARDING_SIGNUP_MANUAL_REQUEST),
      switchMap(({ payload, taskId, onError, onSucceeded }) =>
        this.onboardingService.signupManual(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingSignupManualResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingSignupNedbank$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSignupNedbankRequestAction>(EVENT.ONBOARDING_SIGNUP_NEDBANK_REQUEST),
      switchMap(({ payload, taskId, onError, onSucceeded }) =>
        this.onboardingService.signupNedbank(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingSignupNedbankResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingSelectNedbank$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSelectNedbankRequestAction>(EVENT.ONBOARDING_SELECT_NEDBANK_REQUEST),
      switchMap(({ payload, taskId, onError, onSucceeded }) =>
        this.onboardingService.selectNedbank(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingSelectNedbankResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingLoginRequestAction>(EVENT.ONBOARDING_LOGIN_REQUEST),
      switchMap(({ taskId, request, onSucceeded, onError }) =>
        this.onboardingService.login(request).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceeded) {
              onSucceeded(data);
            }
            return succeed(new actions.ZenAuthOnboardingLoginResponseAction(taskId, data));
          }),
          catchError(error => {
            const errorCode = get(error, ['error', 'error', 'code']);
            if (onError && (errorCode === 'invalid_password' || errorCode === 'invalid_username')) {
              onError(error);
              return of(failed(new HttpErrorAction(taskId, error, true)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingCheckLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingCheckLoginRequestAction>(EVENT.ONBOARDING_CHECK_LOGIN_REQUEST),
      switchMap(payload =>
        this.onboardingService.checkLogin().pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingCheckLoginResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload && payload.onError) {
              payload.onError();
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingAddMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingAddMerchantRequestAction>(EVENT.ONBOARDING_ADD_MERCHANT_REQUEST),
      switchMap(payload =>
        this.onboardingService.addMerchant().pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingAddMerchantResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload?.onError) {
              payload.onError();
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingStartInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingStartInfoRequestAction>(EVENT.ONBOARDING_START_INFO_REQUEST),
      switchMap(payload =>
        this.onboardingService.startInfo(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingStartInfoResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (typeof payload.onError === 'function') payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingStartMoneyApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingStartMoneyAppRequestAction>(EVENT.ONBOARDING_START_MONEY_APP_REQUEST),
      switchMap(({ payload, taskId, onSucceed, onError }) =>
        this.onboardingService.startMoneyApp(payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (onSucceed) onSucceed(data);
            return succeed(new actions.ZenAuthOnboardingStartMoneyAppResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) onError();
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingStartMoneyAppAsync$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingStartMoneyAppAsyncRequestAction>(EVENT.ONBOARDING_START_MONEY_APP_ASYNC_REQUEST),
      switchMap(({ payload, taskId, onSucceed, onError }) =>
        this.onboardingService.startMoneyAppAsync(payload).pipe(
          switchMap((data: models.IZenAuthOnboardingAvoClientInfo) => {
            if (onSucceed) onSucceed(data);
            return [
              succeed(new actions.ZenAuthOnboardingStartMoneyAppAsyncResponseAction(taskId, data)),
              started(
                new actions.ZenAuthOnboardingBankInfoRequestAction(
                  `zenauth-onboarding-fetch-bankclientinfo-${uuidv4()}`,
                ),
                '',
                true,
              ),
            ];
          }),
          catchError(error => {
            if (onError) onError();
            return of(failed(new HttpErrorAction(taskId, error, true)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
  onboardingFetchBankClientInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBankInfoRequestAction>(EVENT.ONBOARDING_BANK_INFO_REQUEST),
      switchMap(({ taskId, onSucceed, onError }) =>
        this.onboardingService.bankInfo().pipe(
          map((data: models.IZenAuthOnboardingBankClientInfo) => {
            if (onSucceed) onSucceed(data);
            return succeed(new actions.ZenAuthOnboardingBankInfoResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) onError();
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingFetchAvoClientInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingAvoInfoRequestAction>(EVENT.ONBOARDING_AVO_INFO_REQUEST),
      switchMap(({ taskId, onSucceed, onError }) =>
        this.onboardingService.avoInfo().pipe(
          switchMap((data: models.IZenAuthOnboardingAvoClientInfo) => {
            if (onSucceed) onSucceed(data);
            this.appFacade2.setAvoInfo({
              displayName: data.displayName,
              id: data.id,
              linkedToBank: data.linkedToBank,
              loginCreated: data.loginCreated,
              walletCreated: data.walletCreated,
              email: data.email,
              name: data.name,
              phone: data.phone,
              photo: data.photo,
              surname: data.surname,
              nationality: data.nationality,
              saId: data.saId,
              addressLine1: data.addressLine1,
              country: data.country,
            });
            if (data.linkedToBank) {
              return [
                succeed(new actions.ZenAuthOnboardingAvoInfoResponseAction(taskId, data)),
                started(
                  new actions.ZenAuthOnboardingBankInfoRequestAction(
                    `zenauth-onboarding-fetch-bankclientinfo-${uuidv4()}`,
                  ),
                  '',
                  true,
                ),
              ];
            }
            return [succeed(new actions.ZenAuthOnboardingAvoInfoResponseAction(taskId, data))];
          }),
          catchError(error => {
            if (onError) onError();
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingEditPersonalDetailsEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingEditPersonalDetailsEmailRequestAction>(
        EVENT.ONBOARDING_EDIT_PERSONAL_DETAILS_EMAIL_REQUEST,
      ),
      switchMap(({ taskId, request, onSucceed, onError }) =>
        this.onboardingService.editPersonalDetailsEmail(request).pipe(
          map((data: models.IZenAuthPersonalInformationResponse) => {
            if (onSucceed) onSucceed(data);
            return succeed(new actions.ZenAuthOnboardingEditPersonalDetailsEmailResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) onError();
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  onboardingStartSocial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingStartSocialRequestAction>(EVENT.ONBOARDING_START_SOCIAL_REQUEST),
      switchMap(payload =>
        this.onboardingService.startSocial(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingStartSocialResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
        ),
      ),
    ),
  );

  onboardingStartManual$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingStartManualRequestAction>(EVENT.ONBOARDING_START_MANUAL_REQUEST),
      switchMap(payload =>
        this.onboardingService.startManual(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingStartManualResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
        ),
      ),
    ),
  );

  onboardingOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingOtpRequestAction>(EVENT.ONBOARDING_OTP_REQUEST),
      switchMap(payload =>
        this.onboardingService.otp(payload.payload).pipe(
          map(data => {
            payload.onSucceeded(data);
            if (payload.payload.resend) {
              return pushNotification(
                new actions.ZenAuthOnboardingOtpResponseAction(payload.taskId, data),
                this.getTranslateByKey('enroll.otp.resend_success_toast'),
              );
            }
            return succeed(new actions.ZenAuthOnboardingOtpResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            const errorCode = get(error, ['error', 'error', 'code']);
            const errorMessage = get(error, ['error', 'error', 'messageCode']);
            if (
              ['otp_wrong_code', 'otp_expired_new_sms_sent', 'otp_failed', 'simswap_error_blocked'].includes(
                errorCode,
              ) ||
              errorMessage === 'error.onboarding.simswap'
            ) {
              if (payload && payload.onError) {
                payload.onError(error);
              }
              return of(succeed(new actions.ZenAuthAuthenticateError(payload.taskId, error)));
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingExternalAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingExternalAuthRequestAction>(EVENT.ONBOARDING_EXTERNAL_AUTH_REQUEST),
      switchMap(payload =>
        this.onboardingService.externalAuth(payload.payload).pipe(
          map(data => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingOtpResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingAddressRequestAction>(EVENT.ONBOARDING_ADDRESS_REQUEST),
      switchMap(payload =>
        this.onboardingService.address(payload.payload).pipe(
          map(data => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingAddressResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (typeof payload.onError === 'function') payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingMobiMoney$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingMobiMoneyRequestAction>(EVENT.ONBOARDING_MOBIMONEY_REQUEST),
      switchMap(payload =>
        this.onboardingService.mobiMoney(payload.payload).pipe(
          map(data => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingMobiMoneyResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingSecurityQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSecurityQuestionsRequestAction>(EVENT.ONBOARDING_SECURITY_QUESTIONS_REQUEST),
      switchMap(payload =>
        this.onboardingService.securityQuestions(payload.payload).pipe(
          map(data => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingPersonalDataResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingFirstNameLastNameAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingFirstNameLastNameAuthRequestAction>(
        EVENT.ONBOARDING_FIRST_NAME_LAST_NAME_AUTH_REQUEST,
      ),
      switchMap(payload =>
        this.onboardingService.fistNameLastNameAuth(payload.payload).pipe(
          map(data => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingFirstNameLastNameAuthResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            const errorCode = get(error, ['error', 'error', 'code']);
            if (errorCode === 'name_verification_failed') {
              if (typeof payload.onError === 'function') {
                payload.onError(error);
              }
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingBusinessTradingName$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBusinessTradingNameRequestAction>(EVENT.ONBOARDING_BUSINESS_TRADING_NAME_REQUEST),
      switchMap(payload =>
        this.onboardingService.businessTradingName(payload.payload).pipe(
          map(data => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingBusinessTradingNameResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (typeof payload.onError === 'function') payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingPersonalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingPersonalDataRequestAction>(EVENT.ONBOARDING_PERSONAL_DATA_REQUEST),
      switchMap(payload =>
        this.onboardingService.personalData(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingPersonalDataResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (typeof payload.onError === 'function') payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingPostCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingPostCheckRequestAction>(EVENT.ONBOARDING_POSTCHECK_REQUEST),
      switchMap(payload =>
        this.onboardingService.postCheck().pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingPostCheckResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            payload.onErrorPostCheck();
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSuccessRequestAction>(EVENT.ONBOARDING_SUCCESS_REQUEST),
      switchMap(payload =>
        this.onboardingService.success(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingPostCheckResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (typeof payload.onError === 'function') payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingApproveIt$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingApproveItRequestAction>(EVENT.ONBOARDING_APPROVEIT_REQUEST),
      switchMap(payload =>
        this.onboardingService.approveIt(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (payload.onSucceeded) payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingApproveItResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload.onError) payload.onError(error);
            const errorCode = get(error, ['error', 'error', 'code']);
            const errorMessage = get(error, ['error', 'error', 'messageCode']);
            if (errorCode === 'simswap_error_blocked' || errorMessage === 'error.onboarding.simswap') {
              return of(succeed(new actions.ZenAuthOnboardingApproveItResponseAction(payload.taskId, null)));
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingProgressRequestAction>(EVENT.ONBOARDING_PROGRESS_REQUEST),
      switchMap(payload =>
        this.onboardingService.progress(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            if (payload.onSucceeded) payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingProgressResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload.onError) payload.onError(error);
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingSentApproveIt$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSentApproveItRequestAction>(EVENT.ONBOARDING_SENT_APPROVEIT_REQUEST),
      switchMap(payload =>
        this.onboardingService.sentApproveIt(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingSentApproveItResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingBankOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBankOtpRequestAction>(EVENT.ONBOARDING_BANK_OTP_REQUEST),
      switchMap(payload =>
        this.onboardingService.bankOtp(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingBankOtpResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload && payload.onError) {
              payload.onError(error);
            }
            const errorCode = get(error, ['error', 'error', 'code']);
            const errorMessage = get(error, ['error', 'error', 'messageCode']);
            if (errorCode === 'simswap_error_blocked' || errorMessage === 'error.onboarding.simswap') {
              return of(succeed(new actions.ZenAuthOnboardingBankOtpResponseAction(payload.taskId, null)));
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingBusinessCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBusinessCategoryRequestAction>(EVENT.ONBOARDING_BUSINESS_CATEGORY_REQUEST),
      switchMap(payload =>
        this.onboardingService.businessCategoryInfo(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingBusinessCategoryResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload && payload.onError) {
              payload.onError(error);
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingBusinessDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBusinessDetailsRequestAction>(EVENT.ONBOARDING_BUSINESS_DETAILS_REQUEST),
      switchMap(payload =>
        this.onboardingService.businessDetails(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingBusinessDetailsResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload && payload.onError) {
              payload.onError(error);
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingBusinessAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBusinessAddressRequestAction>(EVENT.ONBOARDING_BUSINESS_ADDRESS_REQUEST),
      switchMap(payload =>
        this.onboardingService.businessAddress(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingBusinessAddressResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            if (payload && payload.onError) {
              payload.onError(error);
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  onboardingIdPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingIdPhotoRequestAction>(EVENT.ONBOARDING_ID_PHOTO_REQUEST),
      switchMap(payload =>
        this.onboardingService.idPhoto(payload.payload).pipe(
          map(data => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingIdPhotoResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  createLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSetLoginRequestAction>(EVENT.ONBOARDING_SET_LOGIN_REQUEST),
      switchMap(payload =>
        this.onboardingService.createUsernamePassword(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            this.notificationService.registerNotifications();
            return succeed(new actions.ZenAuthOnboardingSetLoginResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  bankphoneDifferent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingBankphoneDifferentRequestAction>(EVENT.ONBOARDING_BANKPHONE_DIFFERENT_REQUEST),
      switchMap(payload =>
        this.onboardingService.bankphoneDifferent(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            return succeed(new actions.ZenAuthOnboardingBankphoneDifferentResponseAction(payload.taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  createLoginSocial$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingLoginSocialRequestAction>(EVENT.ONBOARDING_SET_LOGIN_SOCIAL_REQUEST),
      switchMap(payload =>
        this.onboardingService.createSocialUsernamePassword(payload.payload).pipe(
          map((data: models.IZenAuthOnboardingNextStepResponse) => {
            payload.onSucceeded(data);
            this.notificationService.registerNotifications();
            return succeed(new actions.ZenAuthOnboardingLoginSocialResponseAction(payload.taskId, data));
          }),
          catchError(error => {
            const errorCode = get(error, ['error', 'error', 'code']);
            if (errorCode === 'invalid_username' || errorCode === 'invalid_password') {
              if (payload.onError) {
                payload.onError(error);
              }
            }
            return of(failed(new HttpErrorAction(payload.taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  authenticateWithToken = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZentAuthAuthenticateWithToken>(EVENT.LOGIN_AUTHENTICATE_WITH_TOKEN),
      switchMap(({ taskId, authRequest }) =>
        this.service.authenticate(authRequest.authToken, authRequest.temporaryAccessToken).pipe(
          map((data: any) => {
            authRequest.onSucceeded(data);
            if (data.nextStep) return succeed(new actions.StartResponseAction(taskId, data));
            // after suceeded login to the app
            this.notificationService.registerNotifications();
            if (authRequest.onLogin) {
              authRequest.onLogin();
            }
            return succeed(new actions.ZenAuthAuthenticateResponse(taskId, data));
          }),
          catchError(error => {
            const errorCode = get(error, ['error', 'error', 'code']);
            const errorMessage = get(error, ['error', 'error', 'messageCode']);
            if (
              errorCode === 'invalid_username' ||
              errorCode === 'otp_wrong_code' ||
              errorCode === 'otp_expired_new_sms_sent' ||
              errorCode === 'otp_failed' ||
              errorCode === 'simswap_error_blocked' ||
              errorMessage === 'error.onboarding.simswap'
            ) {
              if (authRequest.onError) {
                authRequest.onError(error);
              }
              return of(succeed(new actions.ZenAuthAuthenticateError(taskId, error)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  suggestUsernames = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSuggestUsernameRequestAction>(EVENT.ONBOARDING_SUGGESTED_USERNAMES_REQUEST),
      switchMap(payload =>
        this.onboardingService.suggestUsername(payload.onboardingId).pipe(
          map((data: string[]) =>
            succeed(new actions.ZenAuthOnboardingSuggestUsernameResponseAction(payload.taskId, data)),
          ),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  suggestAddress = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingSuggestAddressRequestAction>(EVENT.ONBOARDING_SUGGESTED_ADDRESS_REQUEST),
      switchMap(payload =>
        this.onboardingService.addressSuggest(payload.query).pipe(
          map((data: IZenAuthOnboardingAddressSuggestResponse[]) =>
            succeed(new actions.ZenAuthOnboardingSuggestAddressResponseAction(payload.taskId, data)),
          ),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  transactionSigningStart = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthTransactionSigningStartRequestAction>(EVENT.TRANSACTION_SIGNING_START_REQUEST),
      switchMap(({ taskId, transactionAuthId, onSucceed }) =>
        this.transactionSigningService.start(transactionAuthId).pipe(
          map(data => {
            if (data.authorized) {
              onSucceed({ signingToken: data.signingToken, transactionAuthId });
            }
            return succeed(new actions.ZenAuthTransactionSigningStartResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  transactionSigningAuthorize = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthTransactionSigningAuthorizeRequestAction>(EVENT.TRANSACTION_SIGNING_AUTHORIZE_REQUEST),
      switchMap(({ taskId, authRequest: { transactionAuthId, authRequest, onSucceed, onData, onFailure } }) =>
        this.transactionSigningService.authorize(transactionAuthId, authRequest).pipe(
          map(data => {
            if (data.authorized) {
              onSucceed({ signingToken: data.signingToken, transactionAuthId });
            } else {
              onData(data);
            }
            return succeed(new actions.ZenAuthTransactionSigningAuthorizeResponseAction(taskId, data));
          }),
          catchError(error => {
            onFailure(error);
            const errorCode = get(error, ['error', 'error', 'code']);
            const errorMessage = get(error, ['error', 'error', 'messageCode']);
            if (errorCode === 'simswap_error_blocked' || errorMessage === 'error.onboarding.simswap') {
              return of(succeed(new actions.ZenAuthTransactionSigningAuthorizeResponseAction(taskId, null)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  transactionSigningCancel = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthTransactionSigningCancelRequestAction>(EVENT.TRANSACTION_SIGNING_CANCEL_REQUEST),
      switchMap(payload =>
        this.transactionSigningService.cancel(payload.transactionAuthId).pipe(
          map(() => {
            payload.onSucceed();
            return succeed(new actions.ZenAuthTransactionSigningCancelResponseAction(payload.taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(payload.taskId, error)))),
          finalize(() => this.appFacade.finalize(payload.taskId)),
        ),
      ),
    ),
  );

  fakeTx = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthTransactionSigningFakeRequestAction>(EVENT.TRANSACTION_SIGNING_FAKE_REQUEST),
      switchMap(({ transactionAuthId, signingToken, onSucceed, taskId }) =>
        this.transactionSigningService.fakeTx(transactionAuthId, signingToken).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.ZenAuthTransactionSigningFakeResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  getReferralDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthOnboardingGetReferralDetailsRequestAction>(EVENT.ONBOARDING_GET_REFERRAL_DETAILS_REQUEST),
      switchMap(({ code, taskId }) =>
        this.onboardingService.getReferralDetails(code).pipe(
          map(data => succeed(new actions.ZenAuthOnboardingGetReferralDetailsResponseAction(taskId, data))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  guestLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ZenAuthGuestRequestAction>(EVENT.GUEST_REQUEST),
      switchMap(({ taskId, payload, onSucceeded, onError }) =>
        this.onboardingService.guestLogin(payload).pipe(
          map(customerToken => {
            this.guestAuthService.setCustomerToken(customerToken);
            onSucceeded(customerToken);
            return succeed(new actions.ZenAuthGuestResponseAction(taskId, customerToken));
          }),
          catchError(error => {
            onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  setTokenToWebStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.ZenAuthorizationTokenSetAction>(EVENT.AUTHORIZATION_TOKEN_SET),
        tap(({ token }) => {
          const refreshToken = token?.refresh_token || token['refresh_token'];
          if (refreshToken) {
            const defaultRefreshTime = 600;
            const refreshBeforeTime = 60;
            const timerValue = token?.expires_in || token['expires_in'] || defaultRefreshTime;
            this.refreshTokenSubscription = timer(timerValue * 1000 - refreshBeforeTime * 1000).subscribe(() => {
              this.authFacade.refreshToken({ refreshToken });
            });
          }

          this.authService.setAccessToken(token);
        }),
      ),
    { dispatch: false },
  );

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.ZenAuthorizationInitAction>(EVENT.AUTHORIZATION_INIT_ACTION),
        tap(() => {
          const token = this.authService.getAccessToken();
          if (this.isTokenValid(token)) {
            this.authFacade.setToken(this.authService.getAccessToken());
            const refreshToken = token?.refresh_token || token['refreshToken'];
            if (refreshToken) this.authFacade.refreshToken({ refreshToken });
          } else {
            this.removeRefreshTokenTimer();
            this.authService.removeAccessToken();
          }
        }),
      ),
    { dispatch: false },
  );

  removeTokenFromWebStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.ZenAuthorizationTokenRemoveAction>(EVENT.AUTHORIZATION_TOKEN_REMOVE),
        tap(() => this.authService.removeAccessToken()),
      ),
    { dispatch: false },
  );

  isTokenValid = (token: any): boolean => {
    if (!token) return false;

    const tokenData = token['access_token'] || token['accessToken'];
    if (tokenData && !isEmpty(token)) {
      const decodedToken: any = jwt_decode(tokenData);
      const expiration = decodedToken?.exp * 1000;
      const currentTime = new Date().getTime();
      if (currentTime > expiration) {
        console.log('accessToken expired');
        return false;
      }
      return true;
    }
    return false;
  };

  removeRefreshTokenTimer(): void {
    if (this.refreshTokenSubscription) {
      this.refreshTokenSubscription.unsubscribe();
    }
  }

  private getTranslateByKey = (key: string): string => {
    let result: string;
    this.translateFacade.getTranslateByKey$(key).subscribe(translatedKey => (result = translatedKey));
    return result;
  };

  constructor(
    private service: AuthService,
    private onboardingService: OnboardingService,
    private actions$: Actions,
    private appFacade: ApplicationFacade,
    private appFacade2: AppFacade,
    private notificationService: NotificationService,
    private transactionSigningService: TransactionSigningService,
    private guestAuthService: GuestAuthorizationService,
    private authService: AuthorizationService,
    private translateFacade: TranslateFacade,
    private socialAuthService: SocialAuthService,
    private authFacade: ZenAuthFacade,
  ) {}
}
