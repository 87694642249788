import { IAmount } from './common.model';

export enum ChatMessageType {
  text = 'm.text',
  image = 'm.image',
  platformMessage = 'm.platform_message',
  notifyWriting = 'm.notify_writing',
  userWriting = 'm.user_writing',
  newRoom = 'm.heyned_new_room',
  paymentRequest = 'm.payment_request',
  paymentDone = 'm.payment_done',
  map = 'm.map',
  ratingRequest = 'm.rating_request',
  rating = 'm.rating',
  locationSet = 'm.location_set',
  document = 'm.document',
  ping = 'm.ping',
  pong = 'm.pong',
}

export enum ChatType {
  HEALTH = 'HEALTH',
}

export interface IChatSendMessage {
  id: ChatSendMessageId;
  message: IChatSendMessageInner;
}

export interface IChatSendMessageInner {
  content: IChatSendMessageContent;
  roomId?: string;
}

export interface IChatSendMessageContent {
  msgtype: ChatMessageType;
  body?: string;
  url?: string;
  uploadedFileId?: string;
}

export enum ChatSendMessageId {
  SENDMESSAGE = 'sendmessage',
}

export enum ChatHistoryRoomType {
  SUPPORT = 'SUPPORT',
  CONCIERGE = 'CONCIERGE',
  OAS = 'OAS',
  OAS_C2M = 'OAS_C2M',
  ORDER_C2M = 'ORDER_C2M',
  HEYNED = 'HEYNED',
}

export interface IChatHistoryRoom {
  roomId: string;
  type: ChatHistoryRoomType;
  lastMessage?: string;
  lastMessageUserName?: string;
  lastMessageAt?: string;
  closed: boolean;
  unreadMessageCount: number;
  matchedMessageText?: string;
}

export interface IChatPaymentRequestContent {
  paymentId: string;
  usedPoints?: number;
  amount?: IAmount;
}
