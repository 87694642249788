import find from 'lodash-es/find';
import first from 'lodash-es/first';
import isEmpty from 'lodash-es/isEmpty';
import { IWallet } from '../models/wallet.model';

export const selectMainWallet = (wallets: IWallet[]): IWallet => {
  let wallet: IWallet = null;

  if (!isEmpty(wallets)) {
    wallet = find(wallets, w => w.type === 'MAIN');
    if (!wallet) wallet = first(wallets);
  }

  return wallet;
};
