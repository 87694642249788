export interface IApplicationPushNotification {
  text: string;
}

export interface IError {
  code: string;
  message: string;
  messageCode: string;
}

export interface IResponseError {
  debug: any;
  error: IError;
}

export enum ConfigCampaignType {
  GB_BONUS = 'GB_BONUS',
}

export interface IZenAuthAccessToken {
  access_token: string;
  expires_in: number;
  refresh_token?: string;
  scope?: string;
  token_type: string;
}

export enum DatePipeEnum {
  TIME = 'h:mm a',
  TIME24 = 'HH:mm',
  DATE = 'd MMM y',
  LONG_DATE = 'd MMMM y',
  SHORT_DATE_TIME = 'd MMM, h:mm a',
  DATE_TIME = 'd MMM yy, h:mm a',
  LONG_DATE_TIME = 'd MMMM y, h:mm a',
  SHORT_DATE = 'yyyy/MM/dd',
  DAY_MONTH = 'd MMM',
}

export interface IInfoStepupPrep {
  stepupType: string;
  startUrl: string;
}

export interface IApplicationMaintenanceResponse {
  title: string;
  description?: string;
  expectedEndTs?: string;
  imageUrl?: string;
  retrySeconds: number;
  maintenancePageON?: boolean;
}
