<div class="container submerged">
  <div class="page-container small">
    <h1 class="title">{{ basicTable.title | translate }}</h1>
    <ui-divider [compact]="true"></ui-divider>
    <p class="light">{{ basicTable.hint || '' | translate }}</p>

    <ui-divider></ui-divider>

    <div>
      <wakanda-table-row [rows]="basicTable.rows"></wakanda-table-row>
    </div>
  </div>
</div>
