import { Component, Injector, Input } from '@angular/core';
import { CommonComponent } from '@wakanda/wakanda-core';
import { ITreeSetupStep, TreeSetupStepAlignType } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-avo-business-tree-setup-steps',
  templateUrl: './tree-setup-steps.component.html',
  styleUrls: ['./tree-setup-steps.component.scss'],
})
export class TreeSetupStepsComponent extends CommonComponent {
  @Input() steps: ITreeSetupStep[];
  TreeSetupStepAlignType = TreeSetupStepAlignType;

  constructor(injector: Injector) {
    super(injector);
  }
}
