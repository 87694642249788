import { IAmount } from './common.model';
import { IImage } from './image.model';

export interface ISearchInit {
  trending: string[];
  recent: string[];
}

export interface IHomeSearchSuggestion {
  categories: IHomeSearchSuggestionCategory[];
  productSuggestionQueryId: string;
}

export interface IHomeSearchSuggestionCategory {
  items: string[];
  groceryItems?: string[];
  name: SearchSuggestionCategory;
}

export enum SearchSuggestionCategory {
  Product = 'Product',
  ProductCategory = 'ProductCategory',
  Service = 'Service',
  Grocery = 'Grocery',
  Supplier = 'Supplier',
}

export interface IHomeSearchResultItem {
  id: string;
  name: string;
  icon?: string;
  price?: IAmount;
  inStock?: boolean;
}

export interface ISearchResults {
  categories: IHomeSearchResultCategory[];
  productQueryId?: string;
  serviceQueryId?: string;
  airtimeQueryId?: string;
  electricityQueryId?: string;
  groceryQueryId?: string;
}

export enum SearchResultCategoriesSearchTarget {
  PRODUCT = 'PRODUCT',
  SERVICE = 'SERVICE',
  AIRTIME = 'AIRTIME',
  ELECTRICITY = 'ELECTRICITY',
  GROCERY = 'GROCERY',
  VOUCHER = 'VOUCHER',
  SUPPLIER = 'SUPPLIER',
}

export enum IHomeSearchSuggestionsResultCategorySearchTarget {
  ProductCategory = 'ProductCategory',
  Product = 'Product',
  Service = 'Service',
  Grocery = 'Grocery',
}

export enum HomeSearchLocation {
  HOMEPAGE = 'HOMEPAGE',
  SERVICE = 'SERVICE',
  SHOPPING = 'SHOPPING',
}

export interface IHomeSearchResultCategory {
  searchTarget: SearchResultCategoriesSearchTarget;
  count: number;
  items: IHomeSearchResultItem[];
  groceryItems?: IEshopGrocerySearchItem[];
  filters: IHomeSearchFilterCategory[];
}

export interface IHomeSearchFilterCategory {
  id: string;
  name: string;
  options: IHomeSearchFilterCategoryOption[];
}

export interface IHomeSearchFilterCategoryOption {
  id: string;
  name: string;
}

export interface ISearchClickRequest {
  queryId: string;
  itemId: string;
  index: string;
  order: string;
  location: string;
}

export interface ISearchQuery {
  searchString?: string;
  query?: string;
  filter?: string;
  location?: string;
  fixedFilter?: string;
}

export interface ICategoryList {
  items: ICategory[];
}

export interface ICategory {
  categoryId: string;
  categoryName: string;
  iconUrl: string;
}

export interface IEshopGrocerySearchItem {
  sku: string;
  sellerId: string;
  name: string;
  image?: IImage;
  price: IAmount;
  inStock: boolean;
  storeName?: string;
}

// v3 search
export interface ISearchProductResponse {
  queryId?: string;
  vertical?: ISearchVerticalEnum;
  storesOrBrands?: ISearchStoreOrBrandResult[];
  availableSorts?: ISearchSortEnum[];
  groups?: ISearchResultGroup[];
  suggestions?: IProductSearchSuggestion[];
  currentCategoryPath?: ISearchCategoryFilter;
  availableCategories?: ISearchCategoryFilter[];
  appliedFilters?: ISearchProductFilterRequest[];
  availableFilters?: ISearchAvailableFilter[];
}

export interface ISearchProductRequest {
  vertical?: ISearchVerticalEnum;
  category?: string;
  latitude?: number;
  longitude?: number;
  query?: string;
  filters?: object;
  groupBy?: ISearchGroupByEnum;
  resultsPerGroup?: number;
  offset?: number;
  limit?: number;
  sort?: ISearchSortEnum;
  b2b?: boolean;
  tag?: string;
}

export interface ISearchB2BStoreResponse {
  queryId?: string;
  vertical?: ISearchVerticalEnum;
  stores?: ISearchB2BStoreResult[];
  availableSorts?: ISearchB2BStoreSortEnum[];
  appliedFilters?: ISearchB2BStoreFilter[];
  availableFilters?: ISearchAvailableFilter[];
  currentCategory?: ISearchCategoryFilter;
  availableCategories?: ISearchCategoryFilter[];
}

export interface ISearchB2BStoreRequest {
  latitude?: number;
  longitude?: number;
  vertical?: ISearchVerticalEnum;
  limitToStores?: string[];
  storeId?: string;
  chainId?: string;
  category?: string;
  query?: string;
  b2b?: boolean;
  filters?: ISearchB2BStoreFilter;
  offset?: number;
  limit?: number;
  sort?: ISearchB2BStoreSortEnum;
}

export interface ISearchB2BStoreFilter {
  key?: string;
  values?: string[];
}

export interface ISearchResultGroup {
  iconUrl: string;
  vertical: ISearchVerticalEnum;
  label: string;
  products: ISearchProductCard[];
}

export interface ISearchStoreOrBrandResult {
  imageUrl: string;
  name: string;
  filter: SearchProductFilterField;
  filterValue: string;
}

export interface ISearchB2BStoreResult {
  storeId: string;
  storeLocation: string;
  logoUrl: string;
  badgeUrl: string;
  backgroundImageUrl: string;
  tags: ISearchB2BStoreTag[];
  rating: number;
  description: string;
  openedState: StoreOpenedStateEnum;
  opensAt: string;
  distanceMeters: number;
  addressString: string;
  deliverySpan: string;
  collectionSpan: string;
  deliverySupported: boolean;
  deliveryNotAvailableAfter: string;
  collectionSupported: boolean;
  verticals: ISearchVerticalEnum;
  name: string;
}

export interface ISearchB2BStoreTag {
  label?: string;
  position?: StoreTagPositionEnum;
  color?: StoreTagColorEnum;
  icon?: string;
}

export enum StoreTagPositionEnum {
  LABEL = 'LABEL',
  ICON_TOP_LEFT = 'ICON_TOP_LEFT',
  ICON_TOP_RIGHT = 'ICON_TOP_RIGHT',
}

export enum StoreOpenedStateEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CLOSING_SOON = 'CLOSING_SOON',
}

export enum StoreTagColorEnum {
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  ORANGE = 'ORANGE',
  PURPLE = 'PURPLE',
  LIGHT_GREEN = 'LIGHT_GREEN',
}

export interface ISearchProductCard {
  sku: string;
  vertical?: ISearchVerticalEnum;
  apno?: string;
  sellerId?: string;
  sellerName?: string;
  title?: string;
  imageUrl?: string;
  rating?: number;
  points?: number;
  price?: ISearchMoney;
  priceBeforeDiscount?: ISearchMoney;
  buttonAction?: SearchProductCardButtonAction;
  tags?: ISearchProductTag[];
}

export interface ISearchProductTag {
  label?: string;
  type?: string;
  iconUrl?: string;
}

export interface ISearchProductFilterRequest {
  key?: SearchProductFilterField;
  values?: string[];
}

export interface ISearchAvailableFilter {
  key?: SearchProductFilterField;
  label?: string;
  order?: number;
  values?: ISearchAvailableFilterValues;
}

export interface ISearchAvailableFilterValues {
  [key: string]: number;
}

export enum ISearchSortEnum {
  DEFAULT = 'DEFAULT',
  PRICE_HIGH_LOW = 'PRICE_HIGH_LOW',
  PRICE_LOW_HIGH = 'PRICE_LOW_HIGH',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
  NEWEST_ARRIVALS = 'NEWEST_ARRIVALS',
  RATING = 'RATING',
}

export enum ISearchB2BStoreSortEnum {
  NAME = 'NAME',
  DISTANCE = 'DISTANCE',
}

export enum SearchProductCardButtonAction {
  DETAIL = 'DETAIL',
  ADD_TO_CART = 'ADD_TO_CART',
}

export enum ISearchGroupByEnum {
  VERTICAL = 'VERTICAL',
}

export enum SearchProductFilterField {
  BRAND = 'BRAND',
  STORE = 'STORE',
  PREFERENCE = 'PREFERENCE',
  TAGS = 'TAGS',
}

export interface IProductSearchSuggestion {
  query?: string;
  context?: string;
}

export interface ISearchCategoryFilter {
  id?: string;
  name?: string;
}

export interface ISearchMoney {
  formatted?: string;
  amount: number;
  currencyCode: string;
}

export enum ISearchVerticalEnum {
  ESHOP = 'ESHOP',
  TAKEAWAYS = 'TAKEAWAYS',
  GROCERIES = 'GROCERIES',
  VOUCHERS = 'VOUCHERS',
  VAS = 'VAS',
  LIQUOR = 'LIQUOR',
  PREPAIDS = 'PREPAIDS',
  BANKING = 'BANKING',
  SERVICES = 'SERVICES',
  TRAVEL = 'TRAVEL',
  HEALTH = 'HEALTH',
}
