import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { AppConfigEffects, ApplicationFacade } from '@wakanda/wakanda-core';
import { TranslateEffects } from '@wakanda/zentity-translate-store';
import { rootReducer } from './register-reducers';
import { metaReducers } from './logout.meta-reducer';
import { AvoBusinessFacade } from './avo-business/avo-business.facade';
import { AvoBusinessEffects } from './avo-business/avo-business.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(rootReducer, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    // !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 10 }) : [],
    EffectsModule.forRoot([TranslateEffects, AppConfigEffects, AvoBusinessEffects]),
    StoreRouterConnectingModule.forRoot(),
  ],
  providers: [ApplicationFacade, AvoBusinessFacade],
})
export class CreateAvoBusinessStoreModule {
  static forRoot(): ModuleWithProviders<CreateAvoBusinessStoreModule> {
    return {
      ngModule: CreateAvoBusinessStoreModule,
      providers: [ApplicationFacade],
    };
  }
}
