import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AppConfig } from '../../configs/app.config';
import { ApiService } from '../api/api.service';
import { AuthorizationService } from './../../services/authorization/authorization.service';
import { registration, unregisterDevice } from './notification.endpoint';

@Injectable()
export class NotificationService implements OnDestroy {
  private api: ApiService;
  private authService: AuthorizationService;
  private router: Router;
  private fireMessaging: AngularFireMessaging;
  private subscriptions = new Subscription();
  token: string;

  constructor(injector: Injector, private appConfig: AppConfig) {
    this.api = injector.get(ApiService);
    this.authService = injector.get(AuthorizationService);
    this.router = injector.get(Router);
    this.fireMessaging = injector.get(AngularFireMessaging);
  }

  requestToken(): void {
    if (navigator.serviceWorker) {
      this.subscriptions.add(
        this.fireMessaging.requestToken.subscribe(
          token => {
            if (!token) return;
            console.log('+++++NOTIFICATIONS PERMISSION GRANTED!!');
            console.warn('+++++NOTIFICATIONS TOKEN: ', token);
            this.token = token;
            this.registerToken();
          },
          error => console.error('++++NOTIFICATIONS PERMISSION DENIED: ', error),
        ),
      );
    }
  }

  showNotification(title, body, icon, data): void {
    if ('Notification' in window) {
      const notification = new Notification(title, { body, icon, data });
      notification.addEventListener('click', event => {
        this.router.navigate(['/notifications/push-message'], { queryParams: event.target['data'] });
        window.focus();
      });
    }
  }

  backgroundMessageReceived(message: any): void {
    console.log('+++++NEW MESSAGE ARRIVED FROM SERVICEWORKER: ', message);
  }

  registerNotifications = (): void => {
    if (this.token) {
      this.registerToken();
    } else {
      this.requestToken();
    }
  };

  registerToken = (): void => {
    if (!this.authService.isAuthorized()) return;
    console.log('+++++REGISTER APPLICATION FOR PUSH NOTIFICATIONS WITH TOKEN: ', this.token);
    const request = {
      applicationVersion: '0.0.1',
      deviceId: this.authService.getDeviceId() || uuidv4(),
      deviceName: 'PWA-Client',
      language: 'en',
      os: 'PWA',
      pushToken: this.token,
    };
    this.subscriptions.add(
      this.api
        .post(registration(this.appConfig.backendConfig ? this.appConfig.backendConfig.apiV3Url : ''), request)
        .subscribe(() => {
          console.log('+++++NOTIFICATIONS HAS BEEN REGISTERED: ', request);
        }),
    );
  };

  unregisterFromNotifications = (): void => {
    if (!this.token) return;
    console.log('+++++UNSUBSCRIBING TOKEN ', this.token);
    this.fireMessaging.deleteToken(this.token).subscribe(result => {
      this.token = null;
      console.log('+++++TOKEN WAS REMOVED: ', result);
      // CALL UNREGISTER API
    });
  };

  unregisterDevice = (deviceId: string, onSuccess: () => void): void => {
    this.api
      .post(unregisterDevice(this.appConfig.backendConfig?.apiV3Url || '', deviceId))
      .pipe(take(1))
      .subscribe(onSuccess);
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
