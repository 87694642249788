<header class="header-container no-margin">
  <div class="row center">
    <div class="flex-1 row align-stretch">
      <ui-divider [vertical]="true"></ui-divider>
      <img
        class="avo-logo-by-nedbank"
        alt="merchant-logo"
        [@fadeInOut]
        src="../../../assets/icons/AVO_Business_By_Nedbank.svg"
        (click)="navigateToHome()"
      />

      <ui-divider [vertical]="true"></ui-divider>
    </div>

    <ng-container
      *ngIf="screenType !== responsiveService?.type?.mobile && screenType !== responsiveService?.type?.tablet"
    >
      <div class="top-navigation row center">
        <wakanda-avo-business-header-selling-on-avo-menu></wakanda-avo-business-header-selling-on-avo-menu>

        <ui-divider [vertical]="true"></ui-divider>

        <a
          [class.active]="router.url === routes.businessBuying.landing"
          (click)="navigateToBusinessBuying()"
          zen-translate
        >
          merchant.pre_onboarding.buy_on_avo
        </a>

        <ui-divider [vertical]="true"></ui-divider>

        <a [class.active]="router.url === routes.capital" (click)="navigateToB2BCapital()" zen-translate
          >merchant.pre_onboarding.working_capital</a
        >

        <ui-divider [vertical]="true"></ui-divider>

        <a (click)="navigateToMarketPlace()" zen-translate> merchant.pre_onboarding.b2b_marketplace </a>
      </div>

      <div class="row right flex-1">
        <a (click)="navigateToSupport()" zen-translate>merchant.pre_onboarding.faq</a>

        <ui-divider [vertical]="true"></ui-divider>

        <a (click)="navigateToSupport()" class="shrink-0" zen-translate>merchant.pre_onboarding.contact_avo</a>

        <ui-divider [vertical]="true"></ui-divider>

        <ui-button
          [content]="'merchant.pre_onboarding.register' | translate"
          (onClick)="openRegisterModal()"
          [selectButton]="true"
        ></ui-button>

        <ui-divider [compact]="true" [vertical]="true"></ui-divider>

        <ui-button [content]="'merchant.pre_onboarding.log_in' | translate" (onClick)="navigateToLogin()"></ui-button>

        <ui-divider [vertical]="true"></ui-divider>
      </div>
    </ng-container>

    <ui-hamburger
      *ngIf="screenType === responsiveService?.type?.mobile || screenType === responsiveService?.type?.tablet"
      [isOpen]="isMenuOpen === menuVisibility.OPEN"
      [isDark]="true"
      (onClick)="onHamburgerMenu()"
      (clickOutside)="onClickOutsideMenu($event)"
    >
    </ui-hamburger>
  </div>
</header>

<div class="launch-offer-banner">
  <img class="background-stars left" alt="stars_1" src="../../../assets/icons/landing_banner_star_1.svg" />
  <img class="background-stars right" alt="stars_2" src="../../../assets/icons/landing_banner_star_2.svg" />
</div>

<div class="launch-offer-banner join-business-banner" *ngIf="router.url !== routes.capital">
  <img class="background-stars left" alt="stars_1" src="../../../assets/icons/landing_banner_star_1.svg" />
  <img class="background-stars right" alt="stars_2" src="../../../assets/icons/landing_banner_star_2.svg" />
  <div class="row center">
    <div class="row aligned-center center banner-content">
      <b zen-translate>merchant.pre_onboarding.info_banner</b>
    </div>
  </div>
</div>

<wakanda-avo-business-sidemenu [isOpen]="isMenuOpen" (closeMenu)="onHamburgerMenu()"></wakanda-avo-business-sidemenu>
