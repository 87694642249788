import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig, ITaskAction, LocalStorageKeys, remove, save } from '@wakanda/wakanda-core';
import get from 'lodash-es/get';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-failure-dialog',
  template: `
    <ng-container
      *ngTemplateOutlet="
        task?.error?.status === 428 && !agentChat
          ? authorizeModalTemplate
          : task?.error?.status === 401 && !agentChat
          ? signInDialogTemplate
          : agentChat
          ? agentChatFailureDialogTemplate
          : failureDialogTemplate
      "
    >
    </ng-container>

    <ng-template #signInDialogTemplate>
      <ui-sign-in-dialog (onLogin)="navigateToLogin(taskId)" (onClose)="onSignInDialogClose(taskId)" [isAvo3]="isAvo3">
      </ui-sign-in-dialog>
    </ng-template>

    <ng-template #failureDialogTemplate>
      <ui-modal
        [modal]="ModalType.SLIDE_IN_UP"
        [title]="title || ('general.error' | translate)"
        [closeButton]="false"
        [isVisible]="true"
        (onCloseClick)="onClose.emit({ taskId: taskId, errorCode: errorCode })"
      >
        <p class="small light">{{ task | getFailureDialogError }}</p>

        <ui-divider></ui-divider>
        <ui-divider></ui-divider>

        <ui-button
          [callToActionButton]="true"
          [selectButton]="true"
          aligned="bottom"
          [content]="'general.close' | translate"
          (onClick)="onHandleError.emit(errorCode)"
        ></ui-button>
        <ui-divider></ui-divider>
        <div class="text-align-right">
          <p class="small">{{ 'error.session_id' | translate }} {{ appConfig?.backendConfig?.sessionId }}</p>
        </div>
      </ui-modal>
    </ng-template>

    <ng-template #authorizeModalTemplate>
      <ui-authorization-dialog
        [merchant]="merchant"
        [taskId]="task?.taskId"
        [transactionAuthId]="task?.error?.error?.transactionAuthId"
        (onClose)="onClose.emit({ taskId: taskId, errorCode: errorCode })"
        (onSwimSwapDetected)="navigateToSimSwapDetected()"
      ></ui-authorization-dialog>
    </ng-template>

    <ng-template #agentChatFailureDialogTemplate>
      <ui-modal
        [modal]="ModalType.ERROR"
        [title]="title || ('general.error' | translate)"
        [closeButton]="true"
        [isVisible]="true"
        (onCloseClick)="onClose.emit(taskId)"
      >
        <p class="small light">{{ task | getFailureDialogError }}</p>

        <ui-divider [compact]="true"></ui-divider>

        <div [style.display]="'flex'" class="failure-dialog-button" [style.alignItems]="'center'">
          <ui-button
            [selectButton]="true"
            content="COPY ERROR INFO"
            (onClick)="copyToClipboard(getStringifyJson(task?.error))"
          ></ui-button>
          <ng-container *ngIf="copied !== undefined">
            <ui-divider [compact]="true" [vertical]="true"></ui-divider>
            <ui-icon [icon]="copied ? 'check_circle' : 'cross_circle'" size="1.2rem"></ui-icon>
          </ng-container>
        </div>

        <ui-divider [compact]="true"></ui-divider>

        <div [style.display]="'flex'" class="failure-dialog-show-details" (click)="handleStacktraceWrapper()">
          <i>Show details</i>
          <ui-divider [vertical]="true"></ui-divider>
          <ui-icon [icon]="showStacktrace ? 'cross' : 'down'" size=".8rem"></ui-icon>
        </div>

        <ng-container *ngIf="showStacktrace">
          <ui-divider></ui-divider>
          <div #stacktraceWrapper class="text-align-left" [style.overflowY]="'auto'">
            <b>STATUS</b>
            <p [style.height]="'auto'" class="small">{{ task?.error?.status }}</p>

            <ui-divider [compact]="true"></ui-divider>

            <b>Code</b>
            <p [style.height]="'auto'" class="small">{{ task?.error?.error?.error?.code }}</p>

            <ui-divider [compact]="true"></ui-divider>

            <b>Message</b>
            <p [style.height]="'auto'" class="small">{{ task?.error?.error?.error?.message }}</p>

            <ui-divider [compact]="true"></ui-divider>

            <b>Headers</b>
            <p [style.height]="'auto'" class="small">{{ getStringifyJson(task?.error?.error?.debug?.headers) }}</p>

            <ui-divider [compact]="true"></ui-divider>
            <b>Stacktrace</b>
            <p [style.height]="'auto'" class="small">{{ getStringifyJson(task?.error?.error?.debug?.stacktrace) }}</p>
          </div>
          <ui-divider [solid]="true"></ui-divider>
        </ng-container>

        <ui-divider [compact]="true"></ui-divider>

        <ui-button
          *ngIf="isHandleError"
          [callToActionButton]="true"
          [selectButton]="true"
          aligned="bottom"
          [content]="'general.close' | translate"
          (onClick)="onHandleError.emit(errorCode)"
        ></ui-button>
      </ui-modal>
    </ng-template>
  `,
})
export class FailureDialogComponent implements OnInit {
  isHandleError = false;
  errorCode: string;
  showStacktrace: boolean;
  copied: boolean;

  @Input() title: string;
  //
  @Input() agentChat: boolean;
  @Input() taskId: string;
  //
  @Input() merchant: boolean;
  @Input() isAvo3 = false;
  @Input() task: ITaskAction;
  //
  @Output() onClose = new EventEmitter<any>();
  @Output() onHandleError = new EventEmitter<string>();
  //
  ModalType = ModalType;

  constructor(private router: Router, public appConfig: AppConfig) {}

  getStringifyJson = (json: JSON): string => JSON.stringify(json);

  prepareError = (): void => {
    if (
      typeof this.task === 'object' &&
      (this.task.error instanceof HttpResponse || this.task.error instanceof HttpErrorResponse)
    ) {
      const code: string = get(this.task, ['error', 'error', 'error', 'messageCode']);
      if (code) {
        this.errorCode = code;
        if (code.includes('WTX')) {
          this.isHandleError = true;
        }
      }
    }
  };

  handleStacktraceWrapper = (): void => {
    this.showStacktrace = !this.showStacktrace;
  };

  copyToClipboard = (item: string): void => {
    try {
      document.addEventListener('copy', (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', item);
        e.preventDefault();
        document.removeEventListener('copy', null);
      });
      document.execCommand('copy');
      console.log('Copied stacktrace: ');
      console.log(item);
      this.copied = true;
    } catch (err) {
      console.error('Copied failed: ', err);
      this.copied = false;
    }
  };

  navigateToSimSwapDetected = (): void => {
    if (this.merchant) {
      this.router.navigateByUrl('/onboarding/sim-swap-detected');
    }
  };

  navigateToLogin = (taskId: string): void => {
    save(LocalStorageKeys.LastPath, this.router.url);
    this.onClose.emit({ taskId, errorCode: this.errorCode });
    if (this.merchant) {
      this.router.navigateByUrl(encodeURI('/login/signin'));
    } else {
      this.router.navigateByUrl(encodeURI('/auth/login'));
    }
  };

  onSignInDialogClose = (taskId: string): void => {
    remove(LocalStorageKeys.LastPath);
    this.onClose.emit({ taskId, errorCode: this.errorCode });
  };

  ngOnInit(): void {
    this.prepareError();
  }
}
