import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CHAT_FEATURE_KEY, IChatReducerState } from './chat.reducer';

const state = createFeatureSelector<IChatReducerState>(CHAT_FEATURE_KEY);

export const getActiveRoom = createSelector(state, ({ activeRoom }: IChatReducerState) => activeRoom);
export const getActiveRoomMessages = createSelector(
  state,
  ({ activeRoomMessages }: IChatReducerState) => activeRoomMessages,
);
export const getEventSent = createSelector(state, ({ lastSentEvent }: IChatReducerState) => lastSentEvent);
export const getHeyNedRoom = createSelector(state, ({ heyNedRoom }: IChatReducerState) => heyNedRoom);
export const getHeyNedOriginRoomId = createSelector(
  state,
  ({ heyNedOriginRoomId }: IChatReducerState) => heyNedOriginRoomId,
);
export const getHeyNedSubscriptionCheck = createSelector(
  state,
  ({ heyNedSubscriptionCheck }: IChatReducerState) => heyNedSubscriptionCheck,
);

export const getHeyNedEnabled = createSelector(state, ({ heyNedEnabled }: IChatReducerState) => heyNedEnabled);

export const getChatHistoryOngoing = createSelector(
  state,
  ({ chatHistoryOngoing }: IChatReducerState) => chatHistoryOngoing,
);
export const getChatHistoryCompleted = createSelector(
  state,
  ({ chatHistoryCompleted }: IChatReducerState) => chatHistoryCompleted,
);
export const getChatHistorySearchResults = createSelector(
  state,
  ({ chatHistorySearchResult }: IChatReducerState) => chatHistorySearchResult,
);
