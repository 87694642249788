import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ZenAuthFacade } from '../redux/zenAuth.facade';
@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private locationRouter: Location, private authFacade: ZenAuthFacade) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authFacade.isAuthorized$.pipe(
      tap(isAuth => {
        if (isAuth) this.locationRouter.back();
      }),
      map(isAuth => !isAuth),
    );
  }
}
