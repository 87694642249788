export enum EVENT {
  UPGRADE_WALLET_INIT_REQUESTED = 'UPGRADE-WALLET/INIT/REQUESTED',
  UPGRADE_WALLET_INIT_RECEIVED = 'UPGRADE-WALLET/INIT/RECEIVED',

  UPGRADE_WALLET_PERSONAL_DATA_REQUESTED = 'UPGRADE-WALLET/PERSONAL-DATA/REQUESTED',
  UPGRADE_WALLET_PERSONAL_DATA_RECEIVED = 'UPGRADE-WALLET/PERSONAL-DATA/RECEIVED',

  UPGRADE_WALLET_ADDRESS_REQUESTED = 'UPGRADE-WALLET/ADDRESS/REQUESTED',
  UPGRADE_WALLET_ADDRESS_RECEIVED = 'UPGRADE-WALLET/ADDRESS/RECEIVED',

  UPGRADE_WALLET_SECURITY_QUESTIONS_REQUESTED = 'UPGRADE-WALLET/SECURITY-QUESTIONS/REQUESTED',
  UPGRADE_WALLET_SECURITY_QUESTIONS_RECEIVED = 'UPGRADE-WALLET/SECURITY-QUESTIONS/RECEIVED',

  UPGRADE_WALLET_ID_PHOTO_REQUESTED = 'UPGRADE-WALLET/ID-PHOTO/REQUESTED',
  UPGRADE_WALLET_ID_PHOTO_RECEIVED = 'UPGRADE-WALLET/ID-PHOTO/RECEIVED',
}
