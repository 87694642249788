export enum EVENT {
  //
  LOGIN_RESET = '@@ZENTITY/ZENAUTH/LOGIN/RESET',
  LOGIN_START_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/START/REQUEST',
  LOGIN_START_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/START/RECEIVED',
  LOGIN_START_SOCIAL_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/START-SOCIAL/REQUEST',
  LOGIN_START_SOCIAL_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/START-SOCIAL/RECEIVED',
  LOGIN_START_FAILED = '@@ZENTITY/ZENAUTH/LOGIN/START/FAILED',
  LOGIN_AUTHENTICATE_WITH_TOKEN = '@@ZENTITY/ZENAUTH/LOGIN/AUTHENTICATE/WITH-TOKEN',
  LOGIN_AUTHENTICATE_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/AUTHENTICATE/RECEIVED',
  LOGIN_AUTHENTICATE_FAILED = '@@ZENTITY/ZENAUTH/LOGIN/AUTHENTICATE/FAILED',
  LOGIN_MOBIMONEY_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN-MOBIMONEY/REQUEST',
  LOGIN_MOBIMONEY_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN-MOBIMONEY/RECEIVED',
  LOGIN_NEDID_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN-NEDID/REQUEST',
  LOGIN_NEDID_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN-NEDID/RECEIVED',
  //
  ONBOARDING_START_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/START/REQUEST',
  ONBOARDING_START_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/START/RECEIVED',
  ONBOARDING_CHECK_BANK_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/CHECK-BANK/REQUEST',
  ONBOARDING_CHECK_BANK_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/CHECK-BANK/RECEIVED',
  ONBOARDING_STEPUP_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/STEPUP/REQUEST',
  ONBOARDING_STEPUP_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/STEPUP/RECEIVED',
  ONBOARDING_HEALTH_STEPUP_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/HEALTH-STEPUP/REQUEST',
  ONBOARDING_HEALTH_STEPUP_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/HEALTH-STEPUP/RECEIVED',
  ONBOARDING_OUTSTANDNG_TCS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/OUTSTANDING_TCS/REQUEST',
  ONBOARDING_OUTSTANDNG_TCS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/OUTSTANDING_TCS/RECEIVED',
  ONBOARDING_TCS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/TCS/REQUEST',
  ONBOARDING_TCS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/TCS/RECEIVED',
  ONBOARDING_LOGIN_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/LOGIN/REQUEST',
  ONBOARDING_LOGIN_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/LOGIN/RECEIVED',
  ONBOARDING_CHECK_LOGIN_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/CHECK-LOGIN/REQUEST',
  ONBOARDING_CHECK_LOGIN_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/CHECK-LOGIN/RECEIVED',
  ONBOARDING_ADD_MERCHANT_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/ADD-MERCHANT/REQUEST',
  ONBOARDING_ADD_MERCHANT_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/ADD-MERCHANT/RECEIVED',
  ONBOARDING_START_INFO_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/START-INFO/REQUEST',
  ONBOARDING_START_INFO_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/START-INFO/RECEIVED',
  ONBOARDING_START_MONEY_APP_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/START-MONEY-APP/REQUEST',
  ONBOARDING_START_MONEY_APP_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/START-MONEY-APP/RECEIVED',
  ONBOARDING_START_MONEY_APP_ASYNC_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/START-MONEY-APP-ASYNC/REQUEST',
  ONBOARDING_START_MONEY_APP_ASYNC_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/START-MONEY-APP-ASYNC/RECEIVED',
  ONBOARDING_BANK_INFO_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BANK-INFO/REQUEST',
  ONBOARDING_BANK_INFO_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BANK-INFO/RECEIVED',
  ONBOARDING_AVO_INFO_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/AVO-INFO/REQUEST',
  ONBOARDING_AVO_INFO_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/AVO-INFO/RECEIVED',
  ONBOARDING_EDIT_PERSONAL_DETAILS_EMAIL_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/EDIT_PERSONAL_DETAILS_EMAIL/REQUEST',
  ONBOARDING_EDIT_PERSONAL_DETAILS_EMAIL_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/EDIT_PERSONAL_DETAILS_EMAIL/RECEIVED',
  ONBOARDING_START_SOCIAL_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/START-SOCIAL/REQUEST',
  ONBOARDING_START_SOCIAL_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/START-SOCIAL/RECEIVED',
  ONBOARDING_START_MANUAL_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/START-MANUAL/REQUEST',
  ONBOARDING_START_MANUAL_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/START-MANUAL/RECEIVED',
  ONBOARDING_ID_PASSPORT_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/ID-PASSPORT/REQUEST',
  ONBOARDING_ID_PASSPORT_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/ID-PASSPORT/RECEIVED',
  ONBOARDING_LEGAL_ENTITY_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/LEGAL-ENTITY/REQUEST',
  ONBOARDING_LEGAL_ENTITY_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/LEGAL-ENTITY/RECEIVED',
  ONBOARDING_BUSINESS_LEGAL_ENTITY_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-LEGAL-ENTITY/REQUEST',
  ONBOARDING_BUSINESS_LEGAL_ENTITY_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-LEGAL-ENTITY/RECEIVED',
  ONBOARDING_COUNTRIES_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/COUNTRIES/REQUEST',
  ONBOARDING_COUNTRIES_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/COUNTRIES/RECEIVED',
  ONBOARDING_BASIC_DATA_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BASIC-DATA/REQUEST',
  ONBOARDING_BASIC_DATA_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BASIC-DATA/RECEIVED',
  ONBOARDING_BUSINESS_BASIC_DATA_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-BASIC-DATA/REQUEST',
  ONBOARDING_BUSINESS_BASIC_DATA_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-BASIC-DATA/RECEIVED',
  ONBOARDING_OTP_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/OTP/REQUEST',
  ONBOARDING_OTP_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/OTP/RECEIVED',
  ONBOARDING_LINK_ACCOUNT_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/LINK-ACCOUNT/REQUEST',
  ONBOARDING_LINK_ACCOUNT_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/LINK-ACCOUNT/RECEIVED',
  ONBOARDING_START_LINK_ACCOUNT_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/START-LINK-ACCOUNT/REQUEST',
  ONBOARDING_START_LINK_ACCOUNT_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/START-LINK-ACCOUNT/RECEIVED',
  ONBOARDING_INITIATE_LINK_ACCOUNT_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/INITIATE-LINK-ACCOUNT/REQUEST',
  ONBOARDING_INITIATE_LINK_ACCOUNT_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/INITIATE-LINK-ACCOUNT/RECEIVED',
  ONBOARDING_EXTERNAL_AUTH_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/EXTERNAL-AUTH/REQUEST',
  ONBOARDING_EXTERNAL_AUTH_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/EXTERNAL-AUTH/RECEIVED',
  ONBOARDING_PERSONAL_DATA_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/PERSONAL-DATA/REQUEST',
  ONBOARDING_PERSONAL_DATA_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/PERSONAL-DATA/RECEIVED',
  ONBOARDING_SET_LOGIN_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SET-LOGIN/REQUEST',
  ONBOARDING_SET_LOGIN_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SET-LOGIN/RECEIVED',
  ONBOARDING_SET_LOGIN_SOCIAL_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SET-LOGIN/SOCIAL/REQUEST',
  ONBOARDING_SET_LOGIN_SOCIAL_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SET-LOGIN/SOCIAL/RECEIVED',
  ONBOARDING_SELECT_OTP_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SELECT-OTP/REQUEST',
  ONBOARDING_SELECT_OTP_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SELECT-OTP/RECEIVED',
  ONBOARDING_SELECT_SOCIAL_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SELECT-SOCIAL/REQUEST',
  ONBOARDING_SELECT_SOCIAL_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SELECT-SOCIAL/RECEIVED',
  ONBOARDING_SELECT_NEDBANK_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SELECT-NEDBANK/REQUEST',
  ONBOARDING_SELECT_NEDBANK_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SELECT-NEDBANK/RECEIVED',
  ONBOARDING_SIGNUP_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SIGNUP/REQUEST',
  ONBOARDING_SIGNUP_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SIGNUP/RECEIVED',
  ONBOARDING_SIGNUP_MANUAL_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SIGNUP-MANUAL/REQUEST',
  ONBOARDING_SIGNUP_MANUAL_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SIGNUP-MANUAL/RECEIVED',
  ONBOARDING_SIGNUP_SOCIAL_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SIGNUP-SOCIAL/REQUEST',
  ONBOARDING_SIGNUP_SOCIAL_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SIGNUP-SOCIAL/RECEIVED',
  ONBOARDING_SIGNUP_NEDBANK_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SIGNUP-NEDBANK/REQUEST',
  ONBOARDING_SIGNUP_NEDBANK_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SIGNUP-NEDBANK/RECEIVED',
  ONBOARDING_SUGGESTED_USERNAMES_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SET-LOGIN/SUGGESTED-USERNAMES/REQUEST',
  ONBOARDING_SUGGESTED_USERNAMES_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SET-LOGIN/SUGGESTED-USERNAMES/RECEIVED',
  ONBOARDING_BUSINESS_DETAILS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-DETAILS/REQUEST',
  ONBOARDING_BUSINESS_DETAILS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-DETAILS/RECEIVED',
  ONBOARDING_BUSINESS_ADDRESS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-ADDRESS/REQUEST',
  ONBOARDING_BUSINESS_ADDRESS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-ADDRESS/RECEIVED',
  ONBOARDING_BUSINESS_PARTNER_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-PARTNER/REQUEST',
  ONBOARDING_BUSINESS_PARTNER_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-PARTNER/RECEIVED',
  ONBOARDING_BUSINESS_CATEGORY_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-CATEGORY/REQUEST',
  ONBOARDING_BUSINESS_CATEGORY_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-CATEGORY/RECEIVED',
  ONBOARDING_BUSINESS_TRADING_NAME_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-TRADING-NAME/REQUEST',
  ONBOARDING_BUSINESS_TRADING_NAME_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BUSINESS-TRADING-NAME/RECEIVED',
  ONBOARDING_FIRST_NAME_LAST_NAME_AUTH_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/FIRST-NAME-LAST-NAME-AUTH/REQUEST',
  ONBOARDING_FIRST_NAME_LAST_NAME_AUTH_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/FIRST-NAME-LAST-NAME-AUTH/RECEIVED',
  ONBOARDING_SECURITY_QUESTIONS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SECURITY-QUESTIONS/REQUEST',
  ONBOARDING_SECURITY_QUESTIONS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SECURITY-QUESTIONS/RECEIVED',
  ONBOARDING_ADDRESS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/ADDRESS/REQUEST',
  ONBOARDING_ADDRESS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/ADDRESS/RECEIVED',
  ONBOARDING_MOBIMONEY_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/MOBIMONEY/REQUEST',
  ONBOARDING_MOBIMONEY_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/MOBIMONEY/RECEIVED',
  ONBOARDING_SUGGESTED_ADDRESS_INIT = '@@ZENTITY/ZENAUTH/ONBOARDING/ADDRESS/SUGGESTED-ADDRESS/INIT',
  ONBOARDING_SUGGESTED_ADDRESS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/ADDRESS/SUGGESTED-ADDRESS/REQUEST',
  ONBOARDING_SUGGESTED_ADDRESS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/ADDRESS/SUGGESTED-ADDRESS/RECEIVED',
  ONBOARDING_POSTCHECK_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/POSTCHECK/REQUEST',
  ONBOARDING_POSTCHECK_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/POSTCHECK/RECEIVED',
  ONBOARDING_FINISHED = '@@ZENTITY/ZENAUTH/ONBOARDING/FINISHED',
  ONBOARDING_SUCCESS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SUCCESS/REQUEST',
  ONBOARDING_SUCCESS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SUCCESS/RECEIVED',
  ONBOARDING_APPROVEIT_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/APPROVEIT/REQUEST',
  ONBOARDING_APPROVEIT_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/APPROVEIT/RECEIVED',
  ONBOARDING_PROGRESS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/PROGRESS/REQUEST',
  ONBOARDING_PROGRESS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/PROGRESS/RECEIVED',
  ONBOARDING_SENT_APPROVEIT_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/SENT-APPROVEIT/REQUEST',
  ONBOARDING_SENT_APPROVEIT_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/SENT-APPROVEIT/RECEIVED',
  ONBOARDING_BANK_OTP_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BANK-OTP/REQUEST',
  ONBOARDING_BANK_OTP_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BANK-OTP/RECEIVED',
  ONBOARDING_ID_PHOTO_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/ID-PHOTO/REQUEST',
  ONBOARDING_ID_PHOTO_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/ID-PHOTO/RECEIVED',
  ONBOARDING_GET_REFERRAL_DETAILS_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/GET-REFERRAL-CODE/REQUEST',
  ONBOARDING_GET_REFERRAL_DETAILS_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/GET-REFERRAL-CODE/RECEIVED',
  ONBOARDING_BANKPHONE_DIFFERENT_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/BANKPHONE-DIFFERENT/REQUEST',
  ONBOARDING_BANKPHONE_DIFFERENT_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/BANKPHONE-DIFFERENT/RECEIVED',
  ONBOARDING_RESET = '@@ZENTITY/ZENAUTH/ONBOARDING/RESET',
  ONBOARDING_INFO_STEPUP_REQUEST = '@@ZENTITY/ZENAUTH/ONBOARDING/INFO-STEPUP/REQUEST',
  ONBOARDING_INFO_STEPUP_RECEIVED = '@@ZENTITY/ZENAUTH/ONBOARDING/INFO-STEPUP/RECEIVED',
  ONBOARDING_SET_REFERRAL_CODE = '@@ZENTITY/ZENAUTH/ONBOARDING/SET-REFERRAL-CODE',

  //
  TRANSACTION_SIGNING_START_REQUEST = '@@ZENTITY/ZENAUTH/TRANSACTION_SIGNING/START/REQUEST',
  TRANSACTION_SIGNING_START_RECEIVED = '@@ZENTITY/ZENAUTH/TRANSACTION_SIGNING/START/RECEIVED',
  TRANSACTION_SIGNING_AUTHORIZE_REQUEST = '@@ZENTITY/ZENAUTH/TRANSACTION_SIGNING/AUTHORIZE/REQUEST',
  TRANSACTION_SIGNING_AUTHORIZE_RECEIVED = '@@ZENTITY/ZENAUTH/TRANSACTION_SIGNING/AUTHORIZE/RECEIVED',
  TRANSACTION_SIGNING_CANCEL_REQUEST = '@@ZENTITY/ZENAUTH/TRANSACTION_SIGNING/CANCEL/REQUEST',
  TRANSACTION_SIGNING_CANCEL_RECEIVED = '@@ZENTITY/ZENAUTH/TRANSACTION_SIGNING/CANCEL/RECEIVED',
  TRANSACTION_SIGNING_FAKE_REQUEST = '@@ZENTITY/ZENAUTH/TRANSACTION_SIGNING/FAKE/REQUEST',
  TRANSACTION_SIGNING_FAKE_RECEIVED = '@@ZENTITY/ZENAUTH/TRANSACTION_SIGNING/FAKE/RECEIVED',
  TRANSACTION_SIGNING_AUTHENTICATORS_SAVE = '@@ZENTITY/ZENAUTH/TRANSACTION_SIGNING/AUTHENTICATORS/SAVE',
  GUEST_REQUEST = '@@ZENTITY/ZENAUTH/GUEST/REQUEST',
  GUEST_RECEIVED = '@@ZENTITY/ZENAUTH/GUEST/RECEIVED',
  GUEST_INFO_SET = '@@ZENTITY/ZENAUTH/GUEST/INFO/SET',
  LOGOUT_REQUEST = '@@ZENTITY/ZENAUTH/LOGOUT/REQUEST',
  LOGOUT_RECEIVED = '@@ZENTITY/ZENAUTH/LOGOUT/RECEIVED',
  //
  AUTHORIZATION_TOKEN_SET = '@@ZENTITY/ZENAUTH/AUTHORIZATION/TOKEN/SET',
  AUTHORIZATION_TOKEN_REMOVE = '@@ZENTITY/ZENAUTH/AUTHORIZATION/TOKEN/REMOVE',
  LOGIN_CLEAR_NEXT_STEP = '@@ZENTITY/ZENAUTH/LOGIN/CLEAR_NEXT_STEP',
  LOGIN_START_PASSWORDLESS_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/START-PASSWORDLESS/REQUEST',
  LOGIN_START_PASSWORDLESS_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/START-PASSWORDLESS/RECEIVED',
  LOGIN_OTP_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/OTP/REQUEST',
  LOGIN_OTP_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/OTP/RECEIVED',
  LOGIN_OTP_RESEND_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/OTP-RESEND/REQUEST',
  LOGIN_OTP_RESEND_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/OTP-RESEND/RECEIVED',
  LOGIN_REFRESH_TOKEN_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/REFRESH-TOKEN/REQUEST',
  LOGIN_REFRESH_TOKEN_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/REFRESH-TOKEN/RECEIVED',
  LOGIN_PROFILE_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/PROFILE/REQUEST',
  LOGIN_PROFILE_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/PROFILE/RECEIVED',
  LOGIN_GOOGLE_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/GOOGLE/REQUEST',
  LOGIN_GOOGLE_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/GOOGLE/RECEIVED',
  LOGIN_FACEBOOK_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/FACEBOOK/REQUEST',
  LOGIN_FACEBOOK_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/FACEBOOK/RECEIVED',
  LOGIN_APPLE_REQUEST = '@@ZENTITY/ZENAUTH/LOGIN/APPLE/REQUEST',
  LOGIN_APPLE_RECEIVED = '@@ZENTITY/ZENAUTH/LOGIN/APPLE/RECEIVED',
  AUTHORIZATION_INIT_ACTION = '@@ZENTITY/ZENAUTH/AUTHORIZATION/INIT/ACTION/SET',
}
