import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiService } from './services/api.service';
import { ChatService } from './services/chat.service';
import { ChatFacade } from './state/chat.facade';

@NgModule()
export class WakandaChatModule {
  static forRoot(): ModuleWithProviders<WakandaChatModule> {
    return {
      ngModule: WakandaChatModule,
      providers: [ApiService, ChatService, ChatFacade],
    };
  }
}
