import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-button-item',
  template: `
    <div preventDoubleClick class="button dropdown-item" (throttledClick)="onClick.emit()">
      <p>{{ content }}</p>
    </div>
  `,
})
export class ButtonItemComponent {
  @Input() content: string;
  @Output() onClick = new EventEmitter();
}
