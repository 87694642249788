import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HashStringPrefix, IAmount } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-card',
  templateUrl: './card.component.html',
})
export class CardComponent {
  hashType = HashStringPrefix;
  @Input() info: boolean;
  @Input() image: boolean;
  @Input() imageSrc: string;
  @Input() title: boolean;
  @Input() active: boolean;
  @Input() type: string;
  @Input() name: string;
  @Input() id: any;
  @Input() price: IAmount;
  @Input() priceBefore: IAmount;
  @Input() discount: any;
  @Input() favourite: boolean;
  @Input() description: string;
  @Input() footer: string;
  @Input() clickable: boolean;
  @Input() compact: boolean;
  @Output() onFavourite: EventEmitter<any> = new EventEmitter();
  @Output() onCard: EventEmitter<any> = new EventEmitter();

  getImageName = (imageSrc: string) => (imageSrc ? imageSrc.split('/').pop() : '');
}
