import { IAmount, ICommonDaysWithOpeningHours, ICommonImage, ITaxPrice } from './common.model';
import { IImage } from './image.model';
import { ILocationV3, ILocationV3Address, IPosition } from './location.model';
import { PaymentMethod, PaymentStatus } from './wallet.model';
import { ISearchVerticalEnum } from './search.model';

export interface IShoppingCatalogCategory {
  id: string;
  name: string;
  filters: IShoppingCatalogCategoryFilter[];
  sort: IShoppingCatalogCategorySort[];
  screenBoxes: IShoppingCatalogScreenBoxes[];
  singleProductSku?: string;
  productCards?: IShoppingCatalogProduct[];
  description?: string;
  images?: ICommonImage[];
  thumbnail?: ICommonImage;
  subCategories?: IShoppingCatalogCategory[];
}

export interface IShoppingCatalogProduct {
  id?: string;
  sku?: string;
  name: string;
  image: string;
  price: IAmount;
  priceBeforeDiscount?: IAmount;
  noOfVariants?: number;
  favourite?: boolean;
  discount?: number;
  inStock?: boolean;
  creditAvailable?: boolean;
  creditMonthlyRepaymentAmount?: IAmount;
  sellerName?: string;
  sellerId?: string;
  thumbnail?: ICommonImage;
  wishlistProductType?: WishlistProductType;
  b2bMinPurchasedUnits?: number;
  b2bMinPrice?: IAmount;
  b2bMaxPrice?: IAmount;
}

export enum WishlistProductType {
  FAVOURITE = 'FAVOURITE',
  SAVED = 'SAVED',
}

export interface IShoppingCatalogScreenBoxes {
  id: string;
  type: ScreenBoxType;
  name: string;
  targetId: string;
  dataRefs: string[];
  buttonName?: string;
}

export enum ScreenBoxType {
  BLOG_HORIZONTAL = 'BLOG_HORIZONTAL',
  BRAND_HORIZONTAL = 'BRAND_HORIZONTAL',
  FILTER_VERTICAL = 'FILTER_VERTICAL',
  ONBOARDING = 'ONBOARDING ',
  PRODUCT_HORIZONTAL_BIG = 'PRODUCT_HORIZONTAL_BIG',
  PRODUCT_HORIZONTAL_SMALL = 'PRODUCT_HORIZONTAL_SMALL',
}

export interface IShoppingCatalogCategoryFilter {
  key: string;
  label: string;
  type: ShoppingCatalogCategoryFilterType;
  order: number;
  availableValues: IShoppingCategoryFilterAvailableValue[];
}

export enum ShoppingCatalogCategoryFilterType {
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  SLIDER = 'SLIDER',
  COLOR = 'COLOR',
}

export interface IShoppingCategoryFilterAvailableValue {
  id: string;
  order: number;
  label: string;
  count?: number;
}

export interface IShoppingCatalogCategorySort {
  key: string;
  icon: ICommonImage;
  label: string;
}

export interface IShoppingCatalogCategoryFilterItem {
  key: string;
  value?: string[];
  minValue?: string;
  maxValue?: string;
}

export interface IShoppingCart {
  addresses: IShoppingCartAddresses;
  fees: IShoppingFee[];
  fulfillments: IShoppingFulfilment[];
  giftCart: boolean;
  giftCartData?: IShoppingGift;
  promos: IShoppingPromo[];
  showGiftCart: boolean;
  totalFees: IShoppingTaxPrice;
  totalItemsPrice: IShoppingTaxPrice;
  totalPoints: number;
  totalPrice: IShoppingTaxPrice;
  totalShipmentPrice: IShoppingTaxPrice;
  tvLicenseStatus?: IShopppingCartTvLicenseStatus;
  discountTotal?: IShoppingTaxPrice;
  totalItemsPriceWithoutDiscount?: IShoppingTaxPrice;
  creditOnlyCart?: boolean;
  loanTerms?: IShoppingCartLoanTerm;
  selectedLoanTermRepayment?: IShoppingCartLoanTermRepayment; // only FE
}

export interface IShoppingCartLoanTerm {
  availableMonthlyRepayments?: IShoppingCartLoanTermRepayment[];
  selectedTermInMonths?: number;
}

export interface IShoppingCartLoanTermRepayment {
  creditAmount?: IAmount;
  termInMonths?: number;
  monthlyRepayment?: IAmount;
  totalLoanAmount?: IAmount;
}

export enum IShopppingCartTvLicenseStatus {
  NOT_REQUIRED = 'NOT_REQUIRED',
  REQUIRED = 'REQUIRED',
  MISSING_ID_CARD = 'MISSING_ID_CARD',
  COMPLETE = 'COMPLETE',
}

export interface IShoppingGift {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  message?: string;
}

export interface IShoppingFulfilment {
  id: string;
  deliveryMethod: ShoppingDeliveryMethodEnum;
  sellerId: string;
  sellerName: string;
  lockerboxId?: string;
  mallId?: string;
  mallName?: string;
  pickupPoint?: IShoppingPickupPoint;
  itemsPrice: IShoppingTaxPrice;
  shipmentPrice: IShoppingTaxPrice;
  shipmentDiscount: IShoppingTaxPrice;
  price: IShoppingTaxPrice;
  promos?: IShoppingPromo[];
  items: IShoppingFulfilmentItem[];
  expectedDelivery?: string;
  supportsGiftCart: boolean;
}

export interface IShoppingPromo {
  id: string;
  title: string;
  message: string;
  code: string;
  totalItemsDiscount?: IAmount;
  totalShippingDiscount?: IAmount;
}

export interface IShoppingFulfilmentItem {
  sku: string;
  sellerId: string;
  sellerName: string;
  quantity: number;
  type: ShoppingProductTypeEnum;
  thumbnail?: ICommonImage;
  name: string;
  favourite: boolean;
  price: IAmount;
  points: number;
  priceBeforeDiscount?: IAmount;
  priceBeforePromo?: IAmount;
  pricePerUnit?: IShoppingTaxPrice;
  deal?: boolean;
  discount?: number;
  exceededTotalDealCount?: boolean;
  priceIcon?: ICommonImage;
  totalPrice: IShoppingTaxPrice;
  priceBeforeDiscountPerUnit?: IShoppingTaxPrice;
  maxOrderUnits?: number;
  customAmount?: IAmount;
}

// Todo replace by ITaxPrice from common model
export interface IShoppingTaxPrice {
  withoutTax: IAmount;
  withTax: IAmount;
  tax: IAmount;
}

export interface IShoppingPickupPoint {
  id: string;
  type: ShoppingPickupPointTypeEnum;
  name: string;
  address: ILocationV3Address;
  location: ILocationV3;
  openHours: string;
  openingHours: ICommonDaysWithOpeningHours[];
  favourite: boolean;
  note: string;
  distance?: number;
}

export interface IShoppingUpdateCartGiftOptionRequest {
  giftCart: boolean;
  giftCartData: IShoppingGift;
}

export interface IShoppingFulfillmentDeliveryOptions {
  fulfilmentId: string;
  deliveryOptions: IDeliveryOption[];
  pickupPointSelectionRequiredFor: ShoppingDeliveryMethodEnum[];
}

export interface IDeliveryOption {
  deliveryByAvo: boolean;
  deliveryMethod: ShoppingDeliveryMethodEnum;
  requiresPickupSelection: boolean;
}

export interface IShoppingCartAdress {
  locationId: string;
  address: ILocationV3Address;
  location: IPosition;
  name: string;
  surname: string;
}

export interface IShoppingCartAddresses {
  billingAddress?: IShoppingCartAdress;
  deliveryAddress?: IShoppingCartAdress;
}

export interface IShoppingOrderPaymentResponse {
  orderId: string;
  expectedDelivery?: string;
  successMessage?: string;
  uploadMessage?: string;
  showGiftCart: boolean;
  giftCart: boolean;
  giftCartData?: IShoppingGift;
  points: number;
  itemsTotal: IShoppingTaxPrice;
  shippingTotal: IShoppingTaxPrice;
  total: IShoppingTaxPrice;
  totalDiscount: IShoppingTaxPrice;
  feesTotal: IShoppingTaxPrice;
  availablePaymentMethods?: IShoppingPaymentMethod[];
  paymentStatus: PaymentStatus;
}

export interface IShoppingOrderDetailResponse {
  orderId: string;
  expectedDelivery?: string;
  successMessage?: string;
  paymentId: string;
  cta?: IShoppingOrderDetailCta[];
  giftCart: boolean;
  giftCartData?: IShoppingGift;
  points: number;
  totalPoints: number;
  feesTotal: IShoppingTaxPrice;
  itemsTotal: IShoppingTaxPrice;
  shippingTotal: IShoppingTaxPrice;
  total: IShoppingTaxPrice;
  availablePaymentMethods?: IShoppingPaymentMethod[];
  paymentStatus: PaymentStatus;
}

export interface IShoppingOrderDetailCta {
  text: string;
  link: string;
}

export interface IShoppingPaymentInfoResponse {
  fulfilments: IShoppingPaymentFullfilment[];
  fees: IShoppingPaymentFees[];
  discounts: IShoppingPaymentDiscount[];
  paymentAmount: IShoppingMoney;
  availableMethods: IShoppingAvailablePaymentMethod[];
  pointsData?: IShoppingPointsData;
}

export interface IShoppingAvailablePaymentMethod {
  method: ShoppingPaymentMehod;
  splitPaymentAllowed: boolean;
  defaultLinkedAccount?: IShoppingLinkedAccount;
  defaultCard?: IShoppingPaymentSavedCard;
  creditOnlyDeal?: IShoppingPaymentCreditOnlyDeal;
  defaultWallet?: IShoppingDefaultWallet;
}

export interface IShoppingDefaultWallet {
  id: string;
  name: string;
  availableBalance: IShoppingMoney;
  walletId: string;
  default: boolean;
}

export interface IShoppingLinkedAccount {
  id: string;
  name: string;
  accountNumber: string;
  bankBranchNote: string;
  bankName: string;
  iban: string;
  bic: string;
  default: boolean;
}

export interface IShoppingPaymentDiscount {
  amount: IShoppingMoney;
  promoCode: string;
  description: string;
}

export interface IShoppingPaymentFees {
  type: ShoppingFeeType;
  amount: IShoppingMoney;
}

export interface IShoppingPointsData {
  availableBalance: number;
}

export interface IShoppingPaymentFullfilment {
  id: string;
  name: string;
  price: IShoppingMoney;
}

export enum ShoppingPaymentMehod {
  WALLET = 'WALLET',
  DIGITAL_WALLET = 'DIGITAL_WALLET',
  TOPUP_AND_PAY = 'TOPUP_AND_PAY',
  PAYMENT_CARD = 'PAYMENT_CARD',
  PAYMENT_CARD_DPO = 'PAYMENT_CARD_DPO',
  EFT = 'EFT',
  FREE = 'FREE',
  POINTS_ONLY = 'POINTS_ONLY',
  AMEX = 'AMEX',
  CREDIT = 'CREDIT',
  TP = 'TP',
  LOYALTY = 'LOYALTY',
  MOBICRED_DPO = 'MOBICRED_DPO',
}

export interface IShoppingPaymentSavedCard {
  id: string;
  cardPaymentProvider: ShoppingPaymentCardProvider;
  expirationYear: string;
  expirationMonth: string;
  last4Digits: string;
  name: string;
  default: boolean;
  expired: boolean;
}

export interface IShoppingPaymentCreditOnlyDeal {
  loanTerm: number;
  monthlyAmount: IShoppingMoney;
}

export interface IShoppingMoney {
  formatted?: string;
  amount: number;
  currencyCode: string;
}

export enum ShoppingFeeType {
  ESCROW = 'ESCROW',
  SUCCESS_FEE = 'SUCCESS_FEE',
  MARKUP = 'MARKUP',
  TRANSACTION_FEE = 'TRANSACTION_FEE',
  PICKUP_FEE = 'PICKUP_FEE',
  SHIPPING_FEE = 'SHIPPING_FEE',
  DELIVERY_INSURANCE_FEE = 'DELIVERY_INSURANCE_FEE',
  SUBSCRIPTION_FEE = 'SUBSCRIPTION_FEE',
}

export enum ShoppingPaymentCardProvider {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMEX = 'AMEX',
  UNKNOWN = 'UNKNOWN',
}

export interface IShoppingPaymentMethod {
  method: PaymentMethod;
  splitPaymentAllowed: boolean;
}

export enum ShoppingDeliveryMethodEnum {
  DIGITAL = 'DIGITAL',
  TO_DOOR = 'TO_DOOR',
  PICKUP = 'PICKUP',
  LOCKERBOX = 'LOCKERBOX',
  MALL_LOCKERBOX = 'MALL_LOCKERBOX',
  MALL_KIOSK = 'MALL_KIOSK',
}

export enum ShoppingPickupPointTypeEnum {
  LOCKERBOX = 'LOCKERBOX',
  MALL_LOCKERBOX = 'MALL_LOCKERBOX',
  MALL_KIOSK = 'MALL_KIOSK',
  STORE = 'STORE',
}

export enum ShoppingProductTypeEnum {
  PRODUCT = 'PRODUCT',
  VOUCHER = 'VOUCHER',
  GIFT_VOUCHER = 'GIFT_VOUCHER',
  VAS_AIRTIME = 'VAS_AIRTIME',
  VAS_ELECTRICITY = 'VAS_ELECTRICITY',
  WINERY = 'WINERY',
  GROCERIES = 'GROCERIES',
}

export interface IShoppingFee {
  amount: IShoppingTaxPrice;
  type: IShoppingFeeTypeEnum;
}

export enum IShoppingFeeTypeEnum {
  ESCROW = 'ESCROW',
}

export interface IShoppingProductDetailBase {
  avoPointsPaymentEnabled?: boolean;
  breadcrumbIds: string[];
  breadcrumbNames: string[];
  categories: IShoppingProductCategory[];
  description: string;
  details: IShoppingProductDetailAttribute[];
  documents: any[];
  favourite: boolean;
  images: ICommonImage[];
  maxPrice: IAmount;
  minPrice: IAmount;
  name: string;
  noOfRatings: number;
  productType: string;
  rating: number;
  requiresTvLicense?: boolean;
  retired: boolean;
  screenBoxes: IShoppingCatalogScreenBoxes[];
  sellers: IShoppingProductSeller[];
  shareUrl: string;
  sku: string;
  thumbnail: ICommonImage;
  type: ShoppingProductTypeEnum;
  variantCount: number;
  variants: IShoppingProductVariant[];
  hasNotes?: boolean;
  hasWarranty?: boolean;
}

export interface IShoppingProductVariant {
  attributes: IShoppingProductDetailAttribute[];
  price: IAmount;
  minPrice: IAmount;
  maxPrice: IAmount;
  masterVariant: boolean;
  sku: string;
  name: string;
  tiers?: IShoppingProductPriceTier[];
}

export interface IShoppingProductPriceTier {
  amount: IAmount;
  minimumQuantity: number;
  maximumQuantity?: number;
}

export interface IShoppingProductDetailAttribute {
  dimensional: boolean;
  name: string;
  value: string;
  type: ProductAttributeType;
}

export interface IShoppingCatalogSellerCategory {
  description?: string;
  filters: IShoppingCatalogCategoryFilter[];
  id: string;
  logo: ICommonImage;
  name: string;
  productCards?: IShoppingCatalogProduct[];
  rating: number;
}

export interface IShoppingProductRequest {
  productType?: ShoppingProductTypeEnum;
  sku: string;
  sellerId?: string;
  quantity?: number;
  mallId?: string;
  location?: string;
  index?: string;
  queryId?: string;
  customAmount?: IAmount;
}

export enum ShoppingCartType {
  STANDARD = 'STANDARD',
  BUY_NOW = 'BUY_NOW',
  ONECART = 'ONECART',
}

export interface IShoppingProductDetailState {
  data?: IShoppingProduct;
  buyNow?: IShoppingBuyNowProductToCart;
}

export interface IShoppingFulfillmentPatch {
  deliveryMethod: string;
  pickupPointId?: string;
}

export interface IShoppingPayWalletRequest {
  usedPoints: number;
  walletId: string;
}

export interface IShoppingPayWalletResponse {
  points: number;
  paidPoints?: number;
}

export interface IShoppingPayCardRequest {
  redirectSuccessUrl?: string;
  redirectFailUrl?: string;
  redirectTryAgainUrl?: string;
  redirectErrorUrl?: string;
}

export interface IShoppingPayCardResponse {
  completionUrl: string;
  topupId: string;
}

export interface IShoppingSellerChatResponse {
  roomId: string;
}

export interface IShoppingProductFilter {
  sort?: string;
  filters?: IShoppingCatalogCategoryFilterItem[];
}

export interface IShoppingCustomerReview {
  id: string;
  reviewerName: string;
  date: string;
  rating: number;
  text?: string;
}

export interface IShoppingProductDetail extends IShoppingProductDetailBase {
  data: IShoppingProductDetailData;
}

interface IShoppingProductDetailData {
  products: IShoppingCatalogProduct[];
}

export enum ProductAttributeType {
  COLOR = 'COLOR',
  HTML = 'HTML',
  IMAGE_URL = 'IMAGE_URL',
  STRING = 'STRING',
}

export interface IProductVariantOld {
  matchOptions: IProductOptionItemMatch[];
  sku: string;
  name: string;
  isFavourite: boolean;
  price: IAmount;
  minPrice: IAmount;
  maxPrice: IAmount;
  points: number;
  details: IShoppingProductDetailAttribute[];
}

interface IProductOptionItemMatch {
  optionId: string;
  optionValue: string;
}

export interface IShoppingProductDetailOption {
  id: string;
  name: string;
  type: string;
  values: IShoppingProductDetailValue[];
}

export interface IShoppingProductDetailValue {
  id: string;
  name: string;
  hexCode?: string;
}

export interface IShoppingBuyNowProductToCart {
  sku: string;
  quantity: string;
  options: IShoppingBuyNowProductToCartOptions[];
}

export interface IShoppingBuyNowProductToCartOptions {
  sku: string;
  value: any;
}

export interface IShoppingCategory {
  id: string;
  name: string;
  description?: string;
  thumbnail?: ICommonImage;
  images?: ICommonImage[];
  screenBoxes: IShoppingCatalogScreenBoxes[];
  subCategories?: IShoppingCategory[];
  productCards?: IShoppingProductCards[];
  filters: IShoppingCatalogCategoryFilter[];
  sort: IShoppingCatalogCategorySort[];
}

export interface IShoppingProductCards {
  sku: string;
  sellerId: string;
  sellerName: string;
  quantity?: number;
  type: ShoppingProductTypeEnum;
  thumbnail: ICommonImage;
  name: string;
  favourite: boolean;
  variantCount: number;
  minPrice?: IAmount;
  maxPrice?: IAmount;
  price: IAmount;
  priceBeforeDiscount?: IAmount;
  deal?: boolean;
  discount?: number;
  outOfStock: boolean;
  groceryStore?: IEshopGroceryStore;
}

export interface IShoppingProduct {
  sku: string;
  type: ShoppingProductTypeEnum;
  thumbnail: ICommonImage;
  images?: ICommonImage[];
  name: string;
  description: string;
  shareUrl: string;
  favourite: boolean;
  details: IShoppingProductAttribute[];
  noOfRatings: number;
  rating: number;
  documents?: IShoppingDocument;
  sellers: IShoppingProductSeller[];
  screenBoxes: IShoppingCatalogScreenBoxes[];
  breadcrumbIds: string[];
  breadcrumbNames: string[];
  variantCount: number;
  variants?: IShoppingProductVariant[];
  minPrice?: IAmount;
  maxPrice?: IAmount;
  categories: IShoppingProductCategory[];
  hasNotes?: boolean;
  hasWarranty?: boolean;
  tvLicenceRequired?: boolean;
  retired: boolean;
}

export interface IShoppingDocument {
  url: string;
  name: string;
  description?: string;
  fileFormat: ShoppingDocumentFormatTypeEnum;
  type: ShoppingDocumentTypeEnum;
}

export interface IShoppingProductAttribute {
  name: string;
  value: string;
  dimensional: boolean;
  type: ProductAttributeType;
}

export interface IShoppingProductSeller {
  amexAvailable?: boolean;
  deal?: boolean;
  default: boolean;
  description?: string;
  discount?: number;
  expectedDelivery?: string;
  id: string;
  inStock?: boolean;
  logo: ICommonImage;
  lowStock?: boolean;
  sellerDocuments?: IShoppingDocument[];
  maxOrderUnits?: number;
  maxPurchasesPerDeal?: number;
  ministoreEnabled?: boolean;
  name: string;
  outOfStock: boolean;
  points: number;
  price: IAmount;
  priceBeforeDiscount?: IAmount;
  priceIcon?: ICommonImage;
  priceOffers: IShoppingPriceOffer[];
  taxedPrice?: ITaxPrice;
  rating: number;
  restockDate: string;
  restockInDays?: number;
  returnPolicy?: string;
  returnPolicyDays?: number;
  stock?: string;
  warranty?: string;
  warrantyMonths?: number;
  b2bMinPurchasedUnits?: number;
  vatIncluded?: boolean;
  unitType?: string;
  creditItemOnly?: boolean;
  termInMonths?: number;
  monthlyRepayment?: IAmount;
}

export interface IShoppingPriceOffer {
  icon: ICommonImage;
  message: string;
  url: string;
  urlKey: string;
}

export interface IShoppingProductSellerOld {
  id: string;
  name: string;
  default: boolean;
  logo: ICommonImage;
  price: IAmount;
  points: number;
  deal?: boolean;
  maxPurchasesPerDeal?: number;
  priceBeforeDiscount?: IAmount;
  discount?: number;
  warranty?: string;
  warrantyMonths?: number;
  returnPolicy?: string;
  returnPolicyDays?: number;
  outOfStock: boolean;
  restockInDays?: number;
  stock?: string;
  lowStock?: boolean;
  sellerDocuments?: IShoppingDocument[];
  expectedDelivery?: string;
  priceIcon?: ICommonImage;
}

export interface IShoppingSellerCategory {
  id: string;
  name: string;
  description?: string;
  rating: number;
  logo: ICommonImage;
  productCards: IShoppingProductCards[];
}

export interface IShoppingSellerReview {
  id: string;
  reviewerName: string;
  date: string;
  rating: number;
  text: string;
}

export interface IShoppingProductCategory {
  id: string;
  name: string;
}

export interface IVoucherDownloadVoucherRequest {
  purchasedVoucherId: string;
}

export interface IShoppingProductVariant {
  sku: string;
  masterVariant: boolean;
  attributes: IShoppingProductAttribute[];
}

export enum ShoppingDocumentFormatTypeEnum {
  PDF = 'PDF',
}

export enum ShoppingDocumentTypeEnum {
  TC = 'TC',
  WARRANTY = 'WARRANTY',
  RETURN_POLICY = 'RETURN_POLICY',
  OTHER = 'OTHER',
}

export interface IShoppingLandingCategoryRequest {
  categoryId: string;
  filterData?: IShoppingLandingCategoryFilterData;
  filters?: IShoppingCatalogCategoryFilterItem[];
  onSucceed?: (response: IShoppingCategory) => void;
  silent?: boolean;
}

export interface IShoppingLandingCategoryFilterData {
  allSubproducts?: boolean;
  next?: number;
  pageSize?: number;
  mallId?: string;
  sort?: string;
  brandId?: string;
  query?: string;
}

export interface IShoppingApplyPromoCode {
  promocode: string;
}

export interface IShoppingBuyItem {
  id?: string;
  sku?: string;
  name: string;
  image: string;
  price: IAmount;
  attributes: IShoppingBuyAttribute[];
  quantity: number;
  pickupStore: boolean;
  inStock?: boolean;
}

export interface IShoppingBuyAttribute {
  id: string;
  name: string;
  value: string;
}

export interface IShoppingBuyPromoResponse {
  id: string;
  title: string;
  message: string;
  code: string;
}

export interface IShoppingBuyPickupLocationResponse {
  id: string;
  type: any;
  name: string;
  address: ILocationV3Address;
  location: IPosition;
  openHours: string;
  favourite: boolean;
  note: string;
  distance?: number;
}

export interface IShoppingBuyDeliveryInfoRequest {
  deliveryAddressId?: string;
  billingAddressId?: string;
}

export interface IShoppingDeliveryInfoResponse {
  totalAmount: IAmount;
  isOnlyTravel?: boolean;
  items: IShoppingDeliveryInfoItem[];
}

export interface IShoppingReserveInfoRequest {
  usedPoints?: number;
}

export interface IShoppingReserveInfoResponse {
  points?: number;
  orderId?: string;
  expectedDelivery?: string;
  paidPoints?: number;
  successMessage?: string;
  uploadMessage?: string;
}

export interface IShoppingDeliveryInfoItem {
  id: string;
  name: string;
  deliveredBy: string;
  deliveryCharges: IAmount;
  expectedDate?: string;
  items: IShoppingDeliveryInfoItemItem[];
}

export interface IShoppingDeliveryInfoItemItem {
  sku?: string;
  id?: string;
  name: string;
  price: IAmount;
}

export interface IShoppingAddress {
  locationId: string;
  name: string;
  surname: string;
  location: IPosition;
  address: ILocationV3Address;
}

export interface IUrlInfoResponse {
  url: string;
}

export interface IShoppingOrderPaymentResponse {
  orderId: string;
  expectedDelivery?: string;
  successMessage?: string;
  uploadMessage?: string;
  showGiftCart: boolean;
  giftCart: boolean;
  giftCartData?: IShoppingGift;
  points: number;
  itemsTotal: IShoppingTaxPrice;
  shippingTotal: IShoppingTaxPrice;
  total: IShoppingTaxPrice;
  totalDiscount: IShoppingTaxPrice;
  feesTotal: IShoppingTaxPrice;
  availablePaymentMethods?: IShoppingPaymentMethod[];
  paymentStatus: PaymentStatus;
  paymentId: number;
}

export interface IShoppingGift {
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  message?: string;
}

export interface IShoppingTaxPrice {
  withoutTax: IAmount;
  withTax: IAmount;
  tax: IAmount;
}

export interface IShoppingPaymentMethod {
  method: PaymentMethod;
  splitPaymentAllowed: boolean;
}

export interface IShoppingPayWalletRequest {
  usedPoints: number;
  walletId: string;
}

export interface IShoppingPayWalletResponse {
  points: number;
  paidPoints?: number;
}

export interface IShoppingPayCardRequest {
  redirectSuccessUrl?: string;
  redirectFailUrl?: string;
  redirectTryAgainUrl?: string;
  redirectErrorUrl?: string;
}

export interface IShoppingTvLicenceCheckRequest {
  accountNumber?: number;
  passportNumber?: string;
  saIdNumber?: string;
  cartType?: string;
}

export interface IShoppingTvLicenceCheckResponse {
  accountNumber: string;
  name: string;
  valid: boolean;
  hasOutstandingBalance: boolean;
  paymentRedirectUrl?: string;
  outstandingBalance?: number;
}

export interface IShoppingTvLicencePhotoIdRequest {
  backScanId?: string;
  frontScanId?: string;
  cartType?: string;
}

export interface IShoppingSavedSellerRequestParams {
  offset?: number;
  limit?: number;
}

export interface IShoppingSavedSeller {
  storeId: string;
  name: string;
  description: string;
  completedOrdersCount: number;
}

export interface IEshopGroceryStore {
  storeId: string;
  categoryId: string;
  sellerId: string;
  name: string;
  logo?: IImage;
  hasSubCategories: boolean;
}

export interface IShoppingSetTermInMonthsRequest {
  termInMonths: number;
}

export interface IShoppingWishedProduct {
  id: number;
  sku: string;
  originalSku: string;
  apno: string;
  campaign: ICampaign;
  baseProductId: number;
  independentlySellable: boolean;
  kind: string;
  state: string;
  vendor: IVendor;
  supplier: ISupplier;
  vendorSku: string;
  productType: string;
  storeVertical: string;
  title: string;
  description: string;
  taxClass: string;
  unitType: string;
  unitTypeCount: number;
  barcode: string;
  whatsInTheBox: string;
  requiresTvLicense: boolean;
  requiresSaId: boolean;
  images: IIProductmage[];
  documents: IDocument[];
  trackStock: boolean;
  nonStockItemInStock: boolean;
  brand: IBrand;
  avoPointsPaymentEnabled: boolean;
  googleShoppingEnabled: boolean;
  b2bMinPurchasedUnits: number;
  disabledInB2b: boolean;
  disabledInB2c: boolean;
  attributes: IAttributes;
  keywords: string[];
  extraBubblePoints: number;
  importData: IImportData;
  pricing: IPricing;
  terms: ITerms;
  dimensions: IDimensions;
  approval: IApproval;
  shippingTerms: IShippingTerms;
  voucherSource: string;
  bundlesAvailable: number;
  currentStandardB2cPriceWithVat: number;
  categories: string[];
  bundledProducts: IBundledProduct[];
  forceSoldProducts: IForceSoldProduct[];
  tags: string[];
  size: ISize;
  color: IColor;
  modifiers: IModifier[];
}

export interface IShoppingProductFavourite {
  sku: string;
  price: IAmount;
  priceBeforeDiscount?: IAmount;
  minPrice?: IAmount;
  maxPrice?: IAmount;
  favourite: boolean;
  primaryText: string;
  secondaryText?: string;
  image?: string;
  b2bMinPurchasedUnits?: number;
}

export interface ICampaign {
  id: number;
  name: string;
  validFrom: string;
  validTo: string;
  priority: number;
  persistPublishState: boolean;
  singleSkuCampaign: boolean;
}

export interface IVendor {
  id: string;
  name: string;
  type: string;
  logo: ILogo;
}

export interface ILogo {
  id: string;
  type: string;
  url: string;
  hash: string;
  description: string;
  order: number;
  bytes: number;
}

export interface ISupplier {
  id: string;
  name: string;
  type: string;
  logo: ILogo;
}

export interface IIProductmage {
  id: string;
  type: string;
  url: string;
  hash: string;
  description: string;
  order: number;
  bytes: number;
}

export interface IDocument {
  name: string;
  type: string;
  otherType: string;
  url: string;
  sizeBytes: number;
  filesId: string;
}

export interface IBrand {
  id: string;
  name: string;
  logo: ILogo;
  verticals: string[];
}

export interface IAttributes {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export interface IImportData {
  importSource: string;
  importSourceIdentifier: string;
  importSourceVariantGroupingId: string;
  lastImported: string;
  retireReasons: string[];
  ignoreFeedCategories: boolean;
  ignoreFeedStock: boolean;
  ignoredFeedPriceTypes: string[];
  onescan: boolean;
  importHash: string;
  ignoreFeedTags: boolean;
  onecart: boolean;
  stateBeforeAutoRetire: string;
  autoRetiredAt: string;
  airtime: boolean;
  dataBundle: boolean;
  customAmount: boolean;
}

export interface IPricing {
  basePrice: number;
  purchasePrice: number;
  b2bPrice: number;
  dealPrice: number;
  minAmount: number;
  maxAmount: number;
  maxDealPurchases: number;
  avoDealPrice: number;
  avoDealPriceSubsidyWallet: string;
  avoDealPriceMaximumSubsidyAmount: number;
  tieredPricingEnabled: boolean;
  tiers: ITier[];
  marginPricingEnabled: boolean;
  priceMargin: number;
  segmentedPricingEnabled: boolean;
  segmentPrices: ISegmentPrice[];
  locationPricingEnabled: boolean;
  locationPrices: ILocationPrice[];
  creditOnlyItem: boolean;
  creditPriceSubsidyWallet: string;
  creditPrices: ICreditPrice[];
}

export interface ITier {
  minimumQuantity: number;
  amount: number;
}

export interface ISegmentPrice {
  enabled: boolean;
  customerTag: string;
  type: string;
  base: string;
  fixedAmount: number;
  absoluteDiscountAmount: number;
  relativeDiscountPercentage: number;
  subsidyWalletId: string;
  maximumSubsidyAmount: number;
}

export interface ILocationPrice {
  vendorPremiseId: string;
  amount: number;
  amountBeforeDiscount: number;
}

export interface ICreditPrice {
  creditAmount: number;
  termInMonths: number;
}

export interface ITerms {
  warrantyInfo: string;
  warrantyMonths: number;
  returnable: boolean;
  returnPolicyDays: number;
  returnPolicyDescription: string;
}

export interface IDimensions {
  packagingHeightCm: number;
  packagingWidthCm: number;
  packagingDepthCm: number;
  packagingWeightKg: number;
  productHeightCm: number;
  productWidthCm: number;
  productDepthCm: number;
  productWeightKg: number;
}

export interface IApproval {
  state: string;
  submittedBy: string;
  note: string;
  reply: string;
  rejectionDate: string;
  changeSet: string;
  changeState: string;
}

export interface IShippingTerms {
  lockerboxEnabled: boolean;
  toDoorDeliveryEnabled: boolean;
  storePickupEnabled: boolean;
  pickupPointEnabled: boolean;
  mallKioskEnabled: boolean;
  mallLockerboxEnabled: boolean;
  digitalDeliveryEnabled: boolean;
}

export interface IBundledProduct {
  sku: string;
  vendorId: string;
  customAmount: number;
}

export interface IForceSoldProduct {
  sku: string;
  vendorId: string;
  customAmount: number;
}

export interface ISize {
  id: number;
  type: string;
  label: string;
  description: string;
  position: number;
  image: IIProductmage;
  color: string;
}

export interface IColor {
  id: number;
  type: string;
  label: string;
  description: string;
  position: number;
  image: IIProductmage;
  color: string;
}

export interface IModifier {
  id: string;
  externalId: string;
  label: string;
  required: boolean;
  items: IModifierItem[];
  fromImport: boolean;
}

export interface IModifierItem {
  id: string;
  externalId: string;
  price: number;
  defaultItem: boolean;
  label: string;
  image: IIProductmage;
}

//cart v3

export interface IShoppingBuyerCart {
  cartId?: string;
  iconUrl: string;
  label?: string;
  total: IShoppingMoney;
  items?: IShoppingBuyerCartItemOneliner[];
}

export interface IShoppingBuyerCartItemOneliner {
  quantity: number;
  text: string;
}

export interface IShoppingBuyerCartItemQuantityRequest {
  quantity?: number;
}

export interface IShoppingBuyerCartDeliveryAddressRequest {
  locationId?: string;
}

export interface IShoppingBuyerCartDeliveryOptionRequest {
  method?: ShoppingBuyerCartDeliveryMethod;
  schedule?: boolean;
  timeslot?: string;
  tipAmount?: number;
}

export interface IShoppingBuyerAddItemRequest {
  buyNow?: boolean;
  sku: string;
  modifiers?: IShoppingBuyerModifierDraft[];
  quantity: number;
  substitution?: ShoppingBuyerCartItemSubstitution;
  customAmount?: number;
  note?: string;
  meterNumber?: string;
  targetPhoneNumber?: string;
  description?: string;
  recipientName?: string;
  trackingId?: string;
  queryId?: string;
}

export interface IShoppingBuyerCartSummary {
  totalItems: number;
}

export interface IShoppingBuyerAddItemResponse {
  totalItems: number;
  cartId: string;
  itemStates: IShoppingBuyerCartItemState[];
}

export interface IShoppingBuyerCartItemState {
  sku: string;
  cartItemId: string;
  currentQuantity: number;
}

export interface IShoppingBuyerModifierDraft {
  modifierId: string;
  items: IShoppingBuyerModifierItemDraft[];
}

export interface IShoppingBuyerModifierItemDraft {
  itemId: string;
  modifiers?: IShoppingBuyerModifierDraft[];
  quantity: number;
  note?: string;
  price?: number;
}

export enum ShoppingBuyerCartItemSubstitution {
  LET_SHOPPER_CHOOSE = 'LET_SHOPPER_CHOOSE',
  DO_NOT_SUBSTITUTE = 'DO_NOT_SUBSTITUTE',
}

export interface IShoppingBuyerCartDetail {
  cartId: string;
  vertical: ISearchVerticalEnum;
  deliveryAddress: IShoppingBuyerCartDeliveryAddress;
  fulfilments: IShoppingBuyerCartFulfilment[];
  roadblocks?: ShoppingBuyerCartRoadblock[];
  deliveryInstruction: ShoppingBuyerCartDeliveryInstruction;
  availableDeliveryInstructions: ShoppingBuyerCartDeliveryInstruction[];
  deliveryInstructionNote: string;
  deliveryContact: string;
  giftCartData?: IShoppingBuyerGiftCart;
  enableCheckout: boolean;
  enablePayment: boolean;
  creditOnly: boolean;
  promoCodes: IShoppingBuyerCartPromoCode[];
  receiptItems: IShoppingBuyerCartReceiptItem[];
  showDeliveryContactField: boolean;
  showDeliveryInstructionNoteField: boolean;
  total: IShoppingMoney;
  totalVat: IShoppingMoney;
  totalExclVat: IShoppingMoney;
  totalItemsDiscount: IShoppingMoney;
  singlePromoOnly?: boolean;
  tvLicenseData?: IShoppingBuyerCartTvLicense;
  loanData?: IShoppingBuyerCartLoad;
}

export interface IShoppingBuyerCartLoad {
  term: number;
  monthlyPayment: IAmount;
  loanAmount: IAmount;
  totalAmountRepayable: IAmount;
}

export interface IShoppingBuyerGiftCart {
  name?: string;
  phone?: string;
  message?: string;
}

export interface IShoppingBuyerCartTvLicense {
  complete: boolean;
  licenseHolder: string;
  licenseNumber: string;
}

export interface IShoppingBuyerCartDeliveryAddress {
  locationId: string;
  locationName: string;
  addressString: string;
}

export enum ShoppingBuyerCartRoadblock {
  MISSING_DELIVERY_ADDRESS = 'MISSING_DELIVERY_ADDRESS',
  INCOMPATIBLE_CREDIT_ONLY_ITEMS = 'INCOMPATIBLE_CREDIT_ONLY_ITEMS',
  AWAITING_STOCK_CONFIRMATION = 'AWAITING_STOCK_CONFIRMATION',
}

export interface IShoppingBuyerCartFulfilment {
  fulfilmentId: string;
  fulfilledBy: string;
  fulfilledByNote: string;
  stores: IShoppingBuyerCartStore[];
  fees: IShoppingBuyerCartFee[];
  selectedDeliveryMethod: IShoppingBuyerCartDeliveryMethod;
  availableDeliveryMethods: IShoppingBuyerCartDeliveryMethod[];
  selectedTip: IShoppingBuyerCartTipOption;
  availableTipOptions: IShoppingBuyerCartTipOption[];
  availableInLocation: boolean;
  driverStatus?: ShoppingBuyerCartDriverStatus;
  roadblocks?: ShoppingBuyerCartFulfilmentRoadblock[];
}

export enum ShoppingBuyerCartFulfilmentRoadblock {
  DRIVER_CHECK_PENDING = 'DRIVER_CHECK_PENDING',
  DRIVER_CHECK_FAILED = 'DRIVER_CHECK_FAILED',
}

export enum ShoppingBuyerCartDriverStatus {
  WAITING = 'WAITING',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  AVAILABLE = 'AVAILABLE',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export interface IShoppingBuyerCartStore {
  iconUrl: string;
  label: string;
  total: IShoppingMoney;
  items: IShoppingBuyerCartItem[];
  storeId: string;
  storeStatus: CartStoreStatus;
  opensAt: string;
}

export enum CartStoreStatus {
  OPENED = 'OPENED',
  CLOSING_SOON = 'CLOSING_SOON',
  CLOSED = 'CLOSED',
}

export interface IShoppingBuyerCartItem {
  itemId: string;
  sku: string;
  imageUrl: string;
  title: string;
  description: string;
  meterNumber: string;
  targetPhoneNumber: string;
  recipientName: string;
  price: IShoppingMoney;
  unitPrice: IShoppingMoney;
  unitPriceBeforeDiscount: IShoppingMoney;
  quantity: number;
  quantityLimitedTo: number;
  substitutionOption: ShoppingBuyerCartItemSubstitution;
  roadblocks?: ShoppingBuyerCartItemRoadblock[];
}

export enum ShoppingBuyerCartItemRoadblock {
  LIMITED_QUANTITY = 'LIMITED_QUANTITY',
  ITEM_AVAILABLE_VIA_APP_ONLY = 'ITEM_AVAILABLE_VIA_APP_ONLY',
  ITEM_OUT_OF_STOCK = 'ITEM_OUT_OF_STOCK',
  TV_LICENSE_REQUIRED = 'TV_LICENSE_REQUIRED',
  LIMITED_TO_CREDIT_ONLY = 'LIMITED_TO_CREDIT_ONLY',
  ITEM_NO_LONGER_AVAILABLE = 'ITEM_NO_LONGER_AVAILABLE',
  MISSING_SUBSTITUTION_OPTION = 'MISSING_SUBSTITUTION_OPTION',
}

export interface IShoppingBuyerCartFee {
  feeType: ShoppingBuyerCartFee;
  note: string;
  amount: IShoppingMoney;
}

export enum ShoppingBuyerCartFee {
  DELIVERY = 'DELIVERY',
  CONCIERGE = 'CONCIERGE',
  ESCROW = 'ESCROW',
}

export interface IShoppingBuyerCartDeliveryMethod {
  deliveryMethod: ShoppingBuyerCartDeliveryMethod;
  scheduled: boolean;
  deliveryTimespan: IShoppingBuyerCartDeliveryTimespan;
  price: IShoppingMoney;
  scheduledFor: IShoppingBuyerCartTimeSlot;
}

export enum ShoppingBuyerCartDeliveryMethod {
  TO_DOOR = 'TO_DOOR',
  STORE_PICKUP = 'STORE_PICKUP',
  DIGITAL_DELIVERY = 'DIGITAL_DELIVERY',
}

export interface IShoppingBuyerCartDeliveryTimespan {
  expectedFrom: string;
  expectedTo: string;
  from: number;
  to: number;
  units: ShoppingBuyerCartTimespanUnit;
}

export enum ShoppingBuyerCartTimespanUnit {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export interface IShoppingBuyerCartTimeSlot {
  timeslotId: string;
  from: string;
  to: string;
}

export interface IShoppingBuyerCartTipOption {
  label: string;
  amount: IShoppingMoney;
}

export enum ShoppingBuyerCartDeliveryInstruction {
  MEET_AT_DOOR = 'MEET_AT_DOOR',
  LEAVE_AT_GATE = 'LEAVE_AT_GATE',
  GIFT_CART = 'GIFT_CART',
}

export interface IShoppingBuyerCartPromoCode {
  promoCode: string;
  iconUrl?: string;
  title: string;
  description?: string;
  appliedDiscount?: IShoppingMoney;
  reasonForNotApplied?: string;
  applied: boolean;
  valid: boolean;
  selected?: boolean;
}

export interface IShoppingBuyerCartReceiptItem {
  label: string;
  note: IShoppingBuyerTranslateVariables;
  amount: IShoppingMoney;
}

export interface IShoppingBuyerTranslateVariables {
  messageCode: string;
  variables: Record<string, string | number>;
}

export interface IShoppingBuyerCartPaymentDetails {
  paymentId: string;
  receiptItems: IShoppingBuyerCartReceiptItem[];
  orderIds: string[];
  total: IShoppingMoney;
}
