import { AfterViewChecked, ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateFacade } from '../state/translate.facade';

@Directive({
  selector: '[zen-translate]',
})
export class TranslateDirective implements AfterViewChecked, OnDestroy {
  key: string;
  translateSubscription: Subscription;
  data: any;

  @Input() set Ztranslate(key: string) {
    if (key) {
      this.key = key;
      this.checkNodes();
    }
  }

  constructor(private translateFacade: TranslateFacade, private element: ElementRef, private _ref: ChangeDetectorRef) {
    if (!this.translateSubscription) {
      this.translateSubscription = this.translateFacade.getData$.subscribe(() => this.checkNodes());
    }
  }

  ngAfterViewChecked(): void {
    this.checkNodes();
  }

  checkNodes(forceUpdate = false): void {
    let nodes: NodeList = this.element.nativeElement.childNodes;
    // if the element is empty
    if (!nodes.length) {
      // we add the key as content
      this.setContent(this.element.nativeElement, this.key);
      nodes = this.element.nativeElement.childNodes;
    }
    for (const node of Array.from<any>(nodes)) {
      if (node.nodeType === 3) {
        // node type 3 is a text node
        let key: string;
        if (this.key) {
          key = this.key;
          if (forceUpdate) {
            node.lastKey = null;
          }
        } else {
          const content = this.getContent(node);
          const trimmedContent = content.trim();
          if (trimmedContent.length) {
            // we want to use the content as a key, not the translation value
            if (content !== node.currentValue) {
              key = trimmedContent;
              // the content was changed from the user, we'll use it as a reference if needed
              node.originalContent = this.getContent(node);
            } else if (node.originalContent && forceUpdate) {
              // the content seems ok, but the lang has changed
              node.lastKey = null;
              // the current content is the translation, not the key, use the last real content as key
              key = node.originalContent.trim();
            }
          }
        }
        this.updateValue(key, node);
      }
    }
  }

  isDefined(value: any): boolean {
    return typeof value !== 'undefined' && value !== null;
  }

  setContent(node: any, key: string): void {
    const content = typeof key === 'undefined' ? '' : key;
    if (this.isDefined(node.textContent)) {
      node.textContent = content;
    } else {
      node.data = content;
    }
  }

  getContent(node: any): string {
    return this.isDefined(node.textContent) ? node.textContent : node.data;
  }

  updateValue(key: string, node: any): void {
    if (key) {
      if (node.lastKey === key) {
        return;
      }

      const onTranslation = (res: string): void => {
        if (res !== key) {
          node.lastKey = key;
        }
        if (!node.originalContent) {
          node.originalContent = this.getContent(node);
        }

        node.currentValue = this.isDefined(res) ? res : node.originalContent || key;
        // we replace in the original content to preserve spaces that we might have trimmed
        this.setContent(node, this.key ? node.currentValue : node.originalContent.replace(key, node.currentValue));
        this._ref.markForCheck();
      };
      this.translateFacade.getTranslateByKey$(key).subscribe(onTranslation);
    }
  }
  ngOnDestroy(): void {
    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
    }
  }
}
