import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  IUpgradeWalletAddressStepRequest,
  IUpgradeWalletIdPhotoStepRequest,
  IUpgradeWalletNextStepResponse,
  IUpgradeWalletPersonalDataStepRequest,
  IUpgradeWalletSecurityQuestionsStepRequest,
} from '../../models/upgrade-wallet.model';
import { started } from '../application/task.actions';
import * as actions from './upgrade-wallet.actions';
import { UpgradeWalletState } from './upgrade-wallet.reducer';
import { upgradeWalletQuery } from './upgrade-wallet.selectors';

@Injectable({
  providedIn: 'root',
})
export class UpgradeWalletFacade {
  getNextStep$ = this.store.pipe(select(upgradeWalletQuery.getNextStep));

  constructor(private store: Store<UpgradeWalletState>) {}

  upgradeWalletInit(onSucceed: (response: IUpgradeWalletNextStepResponse) => void, onError: () => void): void {
    const taskId = `upgrade-wallet-init`;
    this.store.dispatch(
      started(new actions.UpgradeWalletInitRequestAction(taskId, onSucceed, onError), 'Please wait a moment'),
    );
  }

  upgradeWalletPersonalData(
    request: IUpgradeWalletPersonalDataStepRequest,
    flowId: string,
    onSucceed: (response: IUpgradeWalletNextStepResponse) => void,
  ): void {
    const taskId = `upgrade-wallet-personal-data`;
    this.store.dispatch(
      started(
        new actions.UpgradeWalletPersonalDataRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
      ),
    );
  }
  upgradeWalletAddress(
    request: IUpgradeWalletAddressStepRequest,
    flowId: string,
    onSucceed: (response: IUpgradeWalletNextStepResponse) => void,
  ): void {
    const taskId = `upgrade-wallet-address`;
    this.store.dispatch(
      started(
        new actions.UpgradeWalletAddressRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
      ),
    );
  }

  upgradeWalletSecurityQuestions(
    request: IUpgradeWalletSecurityQuestionsStepRequest,
    flowId: string,
    onSucceed: (response: IUpgradeWalletNextStepResponse) => void,
  ): void {
    const taskId = `upgrade-wallet-security-questions`;
    this.store.dispatch(
      started(
        new actions.UpgradeWalletSecurityQuestionsRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
      ),
    );
  }
  upgradeWalletIdPhoto(
    request: IUpgradeWalletIdPhotoStepRequest,
    flowId: string,
    onSucceed: (response: IUpgradeWalletNextStepResponse) => void,
  ): void {
    const taskId = `upgrade-wallet-id-photo`;
    this.store.dispatch(
      started(
        new actions.UpgradeWalletIdPhotoRequestAction(taskId, flowId, request, onSucceed),
        'Please wait a moment',
      ),
    );
  }
}
