import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@avo/environment/customer/environment';
import { Observable } from 'rxjs';
import { EnvironmentEnum } from '../model/core.model';

@Injectable()
export class CanaryInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    if (environment.environment === EnvironmentEnum.PREVIEW && !request.url.includes('/configuration.json'))
      request = request.clone({ headers: request.headers.set('X-Canary', 'always') });
    return next.handle(request);
  }
}
// 