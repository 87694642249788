import { Action } from '@ngrx/store';
import * as actions from './websockets.actions';
import { WSS_EVENTS } from './websockets.events';

export const WEBSOCKETS_FEATURE_KEY = 'websockets';

export interface IWebsocketsState {
  endpointWithToken?: string;
}

export interface IWebsocketsPartialState {
  readonly [WEBSOCKETS_FEATURE_KEY]: IWebsocketsState;
}

const ACTION_HANDLERS = {
  [WSS_EVENTS.WSS_CHAT_TOKEN_RECEIVED]: (
    state: IWebsocketsState,
    action: actions.WebsocketsChatFetchTokenReceived,
  ): IWebsocketsState => Object.assign({}, state, { endpointWithToken: action.endpointWithToken }),
};

export function websocketsReducer(state: IWebsocketsState = {}, action: Action): IWebsocketsState {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
