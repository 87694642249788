import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  WebsocketsChatFetchTokenRequest,
  WebsocketsChatFetchTokenReceived,
  WebsocketsError,
} from './websockets.actions';
import { WSS_EVENTS } from './websockets.events';
import { WebSocketService } from '../../services/websockets/websockets.service';
import { failed } from '../application/task.actions';

@Injectable({ providedIn: 'root' })
export class WebsocketsEffects {
  constructor(private service: WebSocketService, private actions$: Actions) {}

  fetchWssToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<WebsocketsChatFetchTokenRequest>(WSS_EVENTS.WSS_CHAT_TOKEN_REQUEST),
      switchMap(({ endpoint }) =>
        this.service.fetchToken().pipe(
          map(response => new WebsocketsChatFetchTokenReceived(`${endpoint}${response.token}`)),
          catchError(error => of(failed(new WebsocketsError(error)))),
        ),
      ),
    ),
  );
}
