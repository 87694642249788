import { AgentChatNoticationMessagesCountType } from './agent-chat.model';

export enum ListType {
  DASHBOARD = 'Dashboard',
  CUSTOMERS = 'Customers',
  MERCHANTS = 'Merchants',
  QUEUES = 'Queues',
  AGENTS = 'Agents',
  INTERNAL_CHAT = 'Internal chat',
  DIRECT_CHAT = 'Direct messages',
  MY_CHATS = 'My chats',
  ACTIVE_CHATS = 'All active chats',
}

export enum SubItemsListType {
  AGENTS_ONLINE_LIST = 'Online',
  AGENTS_OFFLINE_LIST = 'Offline',
  ACTIVE_CHAT_SUPPORT = 'Support',
  ACTIVE_CHAT_CONCIERGE = 'Concierge',
  ACTIVE_CHAT_HEYNED = 'HeyNed',
  ACTIVE_CHAT_OAS = 'OAS',
  ACTIVE_CHAT_JOB = 'Job C2M',
  ACTIVE_CHAT_ORDER = 'Order C2M',
}

export interface IListItem {
  icon: string;
  activeIcon: string;
  text: ListType;
  counterType?: AgentChatNoticationMessagesCountType;
  visible: boolean;
  expandable?: boolean;
  subcategories?: IListItemSubcategory[];
  subLinks?: IListItemSubcategory[];
}

export interface IListItemSubcategory {
  text: SubItemsListType;
  visible: boolean;
  expandable?: boolean;
  link?: string;
}

export interface INavigateToChat {
  roomId?: string;
  internalChatRoomId?: string;
  listType?: ListType;
  userId?: string;
  notificationType?: AgentChatNoticationMessagesCountType;
  url?: string;
}

export enum AgentListType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}
