import { Action } from '@ngrx/store';
import {
  ICategoryList,
  IHomeSearchSuggestion,
  ISearchB2BStoreRequest,
  ISearchB2BStoreResponse,
  ISearchClickRequest,
  ISearchInit,
  ISearchProductRequest,
  ISearchProductResponse,
  ISearchQuery,
  ISearchResults,
} from '../../models/search.model';
import * as EVENT from './search.events';

//  SearchInit
export class SearchInitRequest implements Action {
  readonly type = EVENT.INIT_REQUEST;
  constructor(public taskId: string) {}
}

export class SearchInitReceived implements Action {
  readonly type = EVENT.INIT_RECEIVED;
  constructor(public taskId: string, public data: ISearchInit) {}
}

// SearchResults
export class SearchResultsRequest implements Action {
  readonly type = EVENT.RESULTS_REQUEST;
  constructor(
    public taskId: string,
    public query: string,
    public location: string,
    public categoryId: string,
    public onSucceed?: (data: ISearchResults) => void,
  ) {}
}

export class SearchResultsReceived implements Action {
  readonly type = EVENT.RESULTS_RECEIVED;
  constructor(public taskId: string, public data: ISearchResults) {}
}

// SearchClick
export class SearchClickRequest implements Action {
  readonly type = EVENT.RESULT_CLICK_REQUEST;
  constructor(public taskId: string, public query: ISearchClickRequest) {}
}

export class SearchClickReceived implements Action {
  readonly type = EVENT.RESULT_CLICK_RECEIVED;
  constructor(public taskId: string) {}
}

// SearchSuggestions
export class SearchSuggestionsRequest implements Action {
  readonly type = EVENT.SUGGESTIONS_REQUEST;
  constructor(public taskId: string, public query: string, public location: string) {}
}

export class SearchSuggestionsReceived implements Action {
  readonly type = EVENT.SUGGESTIONS_RECEIVED;
  constructor(public taskId: string, public data: IHomeSearchSuggestion) {}
}

//  ServiceSearchPopular
export class ServiceSearchPopularRequest implements Action {
  readonly type = EVENT.SERVICE_POPULAR_REQUEST;
  constructor(public taskId: string) {}
}

export class ServiceSearchPopularReceived implements Action {
  readonly type = EVENT.SERVICE_POPULAR_RECEIVED;
  constructor(public taskId: string, public data: ICategoryList) {}
}

export class SearchSetQuery implements Action {
  readonly type = EVENT.SET_QUERY;
  constructor(public query: ISearchQuery) {}
}

export class CancelSuggestions implements Action {
  readonly type = EVENT.CANCEL_SUGGESTIONS;
}

export class SetSearchCategoryId implements Action {
  readonly type = EVENT.SET_SEARCH_CATEGORY_ID_DASHBOARD;
  constructor(public taskId: string, public categoryId: string) {}
}

// v3
// Search product
export class SearchProductRequest implements Action {
  readonly type = EVENT.SEARCH_PRODUCT_REQUEST;
  constructor(
    public taskId: string,
    public request: ISearchProductRequest,
    public onSucceed?: (data: ISearchProductResponse) => void,
  ) {}
}

export class SearchProductReceived implements Action {
  readonly type = EVENT.SEARCH_PRODUCT_RECEIVED;

  constructor(public taskId: string, public data: ISearchProductResponse) {}
}

// Search B2B store
export class SearchB2BStoreRequest implements Action {
  readonly type = EVENT.SEARCH_B2B_STORE_REQUEST;
  constructor(
    public taskId: string,
    public request: ISearchB2BStoreRequest,
    public onSucceed?: (data: ISearchB2BStoreResponse) => void,
  ) {}
}

export class SearchB2BStoreReceived implements Action {
  readonly type = EVENT.SEARCH_B2B_STORE_RECEIVED;

  constructor(public taskId: string, public data: ISearchB2BStoreResponse) {}
}

// Search product named
export class SearchProductNamedRequest implements Action {
  readonly type = EVENT.SEARCH_PRODUCT_NAMED_REQUEST;

  constructor(
    public taskId: string,
    public filterName: string,
    public onSucceed?: (data: ISearchProductResponse) => void,
  ) {}
}

export class SearchProductNamedReceived implements Action {
  readonly type = EVENT.SEARCH_PRODUCT_NAMED_RECEIVED;

  constructor(public taskId: string, public data: ISearchProductResponse) {}
}
