<div class="column steps">
  <ng-container *ngFor="let item of steps; let index = index; let last = last">
    <div class="step-item row align-center text-align-left" [class.left]="item.align === TreeSetupStepAlignType.RIGHT">
      <b class="step-number">{{ item?.stepNumber ? item.stepNumber : index + 1 }}</b>
      <ui-divider [vertical]="true" [compact]="true"></ui-divider>
      <div class="column align-start">
        <h3 zen-translate>{{ item?.title }}</h3>
        <p class="light" zen-translate>{{ item?.text }}</p>
      </div>
      <div *ngIf="!last" class="arrow-line">
        <div class="arrow"></div>
      </div>
      <p class="small step-item-hint" *ngIf="item?.hint" zen-translate>{{ item.hint }}</p>
    </div>
  </ng-container>
</div>
