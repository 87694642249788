import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  homeSearchClick,
  homeSearchInit,
  homeSearchResults,
  homeSearchSuggestions,
  homeServiceSearchPopular,
  homeServiceSearchRecent,
  product,
  productNamedSearch,
  storesB2B,
} from './search.endpoints';
import { ApiService } from '../api/api.service';
import { AppConfig } from '../../configs/app.config';
import {
  ICategoryList,
  IHomeSearchSuggestion,
  ISearchB2BStoreRequest,
  ISearchB2BStoreResponse,
  ISearchClickRequest,
  ISearchInit,
  ISearchProductRequest,
  ISearchProductResponse,
  ISearchResults,
} from '../../models/search.model';

@Injectable()
export class SearchService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiUrl;

  private getApiV3Url = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiV3Url;

  public fetchHomeSearchInit(useMockApi = false): Observable<ISearchInit> {
    return this.api.get<ISearchInit>(homeSearchInit(this.getApiUrl(useMockApi)));
  }

  public fetchHomeSearchSuggestions(
    query?: string,
    location?: string,
    useMockApi = false,
  ): Observable<IHomeSearchSuggestion> {
    return this.api.get<IHomeSearchSuggestion>(homeSearchSuggestions(this.getApiUrl(useMockApi), query, location));
  }

  public fetchHomeSearchResults(
    item: string,
    location: string,
    categoryId: string,
    useMockApi = false,
  ): Observable<ISearchResults> {
    return this.api.get<ISearchResults>(homeSearchResults(this.getApiUrl(useMockApi), item, location, categoryId));
  }

  public homeSearchClick(query: ISearchClickRequest, useMockApi = false): Observable<void> {
    return this.api.get<void>(homeSearchClick(this.getApiUrl(useMockApi)), query);
  }

  public fetchHomeServiceSearchRecent(useMockApi = false): Observable<ISearchInit> {
    return this.api.get<ISearchInit>(homeServiceSearchRecent(this.getApiUrl(useMockApi)));
  }

  public fetchHomeServiceSearchPopular(useMockApi = false): Observable<ICategoryList> {
    return this.api.get<ICategoryList>(homeServiceSearchPopular(this.getApiUrl(useMockApi)));
  }

  // v3
  public fetchProductSearch(request: ISearchProductRequest, useMockApi = false): Observable<ISearchProductResponse> {
    return this.api.get<ISearchProductResponse>(product(this.getApiV3Url(useMockApi), request));
  }

  public fetchB2BStoreSearch(request: ISearchB2BStoreRequest, useMockApi = false): Observable<ISearchB2BStoreResponse> {
    return this.api.post<ISearchB2BStoreResponse>(storesB2B(this.getApiV3Url(useMockApi)), request);
  }

  public fetchProductNamedSearch(filterName: string, useMockApi = false): Observable<ISearchProductResponse> {
    return this.api.get<ISearchProductResponse>(productNamedSearch(this.getApiV3Url(useMockApi), filterName));
  }
}
