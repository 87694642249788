import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import {
  IAdvertisingBannerClickRequest,
  IAdvertisingBannerFormRequest,
  IAdvertisingBannerRequest,
  IAdvertisingBannerResponse,
} from '../../models/advertising.model';
import { ApiService } from '../api/api.service';
import * as endpoints from './advertising.endpoints';

@Injectable({
  providedIn: 'root',
})
export class AdvertisingService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  public fetchBanner(bannerRequest: IAdvertisingBannerRequest): Observable<IAdvertisingBannerResponse> {
    return this.api.post<IAdvertisingBannerRequest>(
      endpoints.banner(this.appConfig.backendConfig.apiV3Url),
      bannerRequest,
    );
  }

  public bannerClick(bannerId: string, bannerClick: IAdvertisingBannerClickRequest): Observable<any> {
    return this.api.post<IAdvertisingBannerClickRequest>(
      endpoints.bannerClick(this.appConfig.backendConfig.apiV3Url, bannerId),
      bannerClick,
    );
  }

  public bannerForm(bannerId: string, bannerForm: IAdvertisingBannerFormRequest): Observable<any> {
    return this.api.post<IAdvertisingBannerFormRequest>(
      endpoints.bannerForm(this.appConfig.backendConfig.apiV3Url, bannerId),
      bannerForm,
    );
  }
}
