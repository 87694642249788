import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as TasksActions from './tasks.actions';
import * as TasksSelectors from './tasks.selectors';
import { ITask } from '../models/store.models';

@Injectable({ providedIn: 'root' })
export class TasksFacade {
  hasFullscreenTasks$ = this.store.pipe(select(TasksSelectors.hasFullscreenTasks));
  getTasks$ = this.store.pipe(select(TasksSelectors.getTasks));
  getErrors$ = this.store.pipe(select(TasksSelectors.getErrors));

  constructor(private readonly store: Store) {}

  finishTask(taskId: string) {
    this.store.dispatch(TasksActions.finishTaskAction({ taskId }));
  }

  repeatTask(data: ITask) {
    this.store.dispatch(TasksActions.repeatTaskAction({ data }));
  }
}
