import { Pipe, PipeTransform } from '@angular/core';
import { IShoppingFulfilment, IShoppingProductCards } from '../models/shopping.model';

@Pipe({
  name: 'productCartQuantity',
})
export class ProductCartQuantityPipe implements PipeTransform {
  transform(productCard: IShoppingProductCards, fulfilments: IShoppingFulfilment[]): number {
    if (!fulfilments || !productCard) return 0;

    let cartItems = [];
    fulfilments?.forEach(fulfilment => {
      cartItems = cartItems.concat(fulfilment?.items);
    });

    return cartItems?.filter(item => item?.sku === productCard.sku)[0]?.quantity;
  }
}
