import { Pipe, PipeTransform } from '@angular/core';
import get from 'lodash-es/get';

@Pipe({
  name: 'get',
})
export class GetPipe implements PipeTransform {
  transform<T>(item: T, path: string, defaultValue?: any): any {
    if (!!item && !!path) {
      return defaultValue ? get(item, [path], defaultValue) : get(item, [path]);
    }

    return item;
  }
}
