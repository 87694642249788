import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IFileUpload } from '@wakanda/wakanda-core';

@Component({
  selector: 'wakanda-image-upload',
  template: `
    <ng-container *ngIf="!multiple">
      <input
        style="display: none"
        type="file"
        (change)="onFileSelected($event)"
        (click)="onPanelClick($event)"
        [accept]="
          acceptSpecific
            ? acceptSpecific
            : acceptDocuments
            ? 'application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*'
            : 'image/*'
        "
        #fileInput
      />
    </ng-container>

    <ng-container *ngIf="!!multiple">
      <input
        multiple
        style="display: none"
        type="file"
        (change)="onFilesSelected($event)"
        (click)="onPanelClick($event)"
        [accept]="
          acceptSpecific
            ? acceptSpecific
            : acceptDocuments
            ? 'application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/*'
            : 'image/*'
        "
        #fileInput
      />
    </ng-container>
    <ng-container *ngIf="!withButton && !hidden">
      <div class="ui-upload-image flex row center" (click)="openFileInput()">
        <ui-icon
          *ngIf="!merchant && !withText"
          [icon]="icon || 'gallery'"
          [size]="large ? '2.5rem' : huge ? '6rem' : '1.5rem'"
        ></ui-icon>
        <ui-icon
          *ngIf="!!merchant && !withText"
          [icon]="icon || 'plus'"
          [size]="large ? '2.5rem' : huge ? '6rem' : '1.5rem'"
        ></ui-icon>
        <div *ngIf="withText" class="with-text text-align-center">
          <ui-icon [icon]="icon || 'photos'" size="6rem"></ui-icon>
          <ui-divider></ui-divider>
          <h3 zen-translate>{{ text ? text : ('general.upload_photo' | translate) }}</h3>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!!withButton && !hidden">
      <ui-button
        [callToActionButton]="true"
        [selectButton]="true"
        [content]="buttonContent"
        [disabled]="disabledButton"
        (onClick)="openFileInput()"
      ></ui-button>
    </ng-container>
  `,
})
export class ImageUploadComponent {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @Input() large: boolean;
  @Input() withText: boolean;
  @Input() icon: string;
  @Input() acceptDocuments: boolean;
  @Input() acceptSpecific: string;
  @Input() empty: boolean;
  @Input() merchant: boolean;
  @Input() withButton: boolean;
  @Input() buttonContent: string;
  @Input() disabledButton: boolean;
  @Input() huge: boolean;
  @Input() text: string;
  @Input() hidden: boolean;
  @Input() multiple: boolean;
  @Output() onChange = new EventEmitter<IFileUpload | IFileUpload[]>();

  onFileSelected = (event: any): void => {
    const file: File = event.target.files[0] as File;
    const image: IFileUpload = {
      name: file.name,
      data: file,
    };
    this.onChange.emit(image);
  };

  onFilesSelected = (event: any): void => {
    const images: IFileUpload[] = [];
    Array.from(event?.target?.files)?.forEach(f => {
      const file: File = f as File;
      const image: IFileUpload = {
        name: file?.name,
        data: file,
      };
      images.push(image);
    });
    this.onChange.emit(images);
  };

  onPanelClick = (event: any): void => {
    event.target.value = '';
  };

  openFileInput(): void {
    this.fileInput.nativeElement.click();
  }
}
