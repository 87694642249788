import { Action } from '@ngrx/store';
import {
  IClaimElectricityRequest,
  IOrderDetailForPayment,
  IRecentElectricityClaimResponse,
} from '../../../models/prepaids.model';
import { PrepaidElectricityActions } from './prepaid-electricity.actions';
import { EVENT } from './prepaid-electricity.events';

export const PREPAID_ELECTRICITY_FEATURE_KEY = 'prepaidElectricity';

export interface IPrepaidElectricityReducerState {
  recent?: IRecentElectricityClaimResponse[];
  meterValidated?: boolean;
  data?: IClaimElectricityRequest;
  placedOrder?: IOrderDetailForPayment;
  wakandaUser?: boolean;
}

export interface IPrepaidElectricityPartialState {
  readonly [PREPAID_ELECTRICITY_FEATURE_KEY]: IPrepaidElectricityReducerState;
}

export function prepaidElectricityReducer(
  state: IPrepaidElectricityReducerState = {},
  action: Action,
): IPrepaidElectricityReducerState {
  const prepaidElectricityAction = action as PrepaidElectricityActions;

  switch (prepaidElectricityAction.type) {
    case EVENT.RECENT_REQUEST:
    case EVENT.PLACE_ORDER_REQUEST: {
      return Object.assign({}, state, {});
    }

    case EVENT.VALIDATE_METER_REQUEST: {
      return Object.assign({}, state, { meterValidated: false });
    }

    case EVENT.RECENT_RECEIVED: {
      const response: IRecentElectricityClaimResponse[] = prepaidElectricityAction.payload;
      return Object.assign({}, state, { recent: response });
    }

    case EVENT.VALIDATE_METER_RECEIVED: {
      return Object.assign({}, state, { meterValidated: true });
    }

    case EVENT.PLACE_ORDER_RECEIVED: {
      const data: IOrderDetailForPayment = prepaidElectricityAction.payload;
      return Object.assign({}, state, { placedOrder: data });
    }

    case EVENT.SAVE_TO_STORE: {
      const data: IClaimElectricityRequest = prepaidElectricityAction.payload;
      return Object.assign({}, state, { data });
    }

    case EVENT.CHECK_USER_RESPONSE: {
      const data = prepaidElectricityAction.payload;
      return Object.assign({}, state, { wakandaUser: data.isWakandaUser });
    }

    case EVENT.CLEAN: {
      const newState: IPrepaidElectricityReducerState = {
        data: null,
        recent: null,
        meterValidated: false,
        placedOrder: null,
      };
      return Object.assign({}, state, newState);
    }

    case EVENT.FAILED:
      return Object.assign({}, state, {});
    default:
      return state as IPrepaidElectricityReducerState;
  }
}
