import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, of, switchMap } from 'rxjs';
import { HttpEventType, HttpUploadProgressEvent } from '@angular/common/http';
import * as assetsActions from './assets.actions';
import toInteger from 'lodash-es/toInteger';
import { delay } from 'rxjs/operators';
import { AssetsService } from '../../services/assets.service';
import { httpErrorAction } from '../application/application.actions';
import { TaskType } from '../models/store.models';
import { AssetUploadStatus } from './assets.models';
import { TasksFacade } from '../tasks/tasks.facade';

@Injectable()
export class AssetsEffects {
  constructor(private readonly actions$: Actions, private tasksFacade: TasksFacade, private service: AssetsService) {}

  uploadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assetsActions.uploadAssetRequest),
      switchMap(({ data, bucket, onSuccess, onError, taskMetadata }) =>
        this.service.postAsset(data, bucket).pipe(
          switchMap(response => [
            assetsActions.uploadAssetResponse({
              taskMetadata: {
                taskId: taskMetadata.taskId,
                type: TaskType.SUCCESS,
              },
            }),
            assetsActions.statusRequest({
              id: response.dmzFileId,
              onSuccess,
              onError,
              taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.STARTED },
            }),
          ]),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata.taskId || '')),
        ),
      ),
    ),
  );

  assetUploadFileWithProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assetsActions.uploadAssetProgressRequest),
      switchMap(({ data, bucket, onSuccess, onProgress, onError, taskMetadata }) =>
        this.service.postAssetWithProgress(data, bucket).pipe(
          map(response => {
            if (response?.type === HttpEventType.UploadProgress) {
              const uploadResponse = response as HttpUploadProgressEvent;
              const percentage = toInteger((uploadResponse?.loaded / uploadResponse?.total) * 100);
              if (onProgress) onProgress(percentage);
              return assetsActions.uploadAssetProgressResponse({
                taskMetadata: {
                  taskId: taskMetadata.taskId,
                  type: TaskType.SUCCESS,
                },
              });
            } else if (response?.type === HttpEventType.Response) {
              return assetsActions.statusRequest({
                id: response.body['dmzFileId'],
                onSuccess,
                onError,
                taskMetadata: { taskId: taskMetadata.taskId, type: TaskType.STARTED },
              });
            }

            return assetsActions.uploadAssetProgressResponse({
              taskMetadata: {
                taskId: taskMetadata.taskId,
                type: TaskType.SUCCESS,
              },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata.taskId || '')),
        ),
      ),
    ),
  );

  fetchStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assetsActions.statusRequest),
      mergeMap(({ id, onSuccess, onError, taskMetadata }) =>
        this.service.getStatus(id).pipe(
          delay(1500),
          map(response => {
            if (response.status === AssetUploadStatus.PROCESSING) {
              return assetsActions.statusRequest({
                id,
                onSuccess,
                onError,
                taskMetadata: {
                  taskId: taskMetadata.taskId,
                  type: TaskType.STARTED,
                },
              });
            } else if (response.status === AssetUploadStatus.ERROR) {
              if (onError) onError();
              return assetsActions.statusResponse({
                taskMetadata: {
                  taskId: taskMetadata.taskId,
                  type: TaskType.FAILED,
                },
              });
            }

            onSuccess(response);
            return assetsActions.statusResponse({
              taskMetadata: {
                taskId: taskMetadata.taskId,
                type: TaskType.SUCCESS,
              },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(
              httpErrorAction({
                error,
                data: { taskId: taskMetadata.taskId, type: TaskType.FAILED },
              }),
            );
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata.taskId || '')),
        ),
      ),
    ),
  );
}
