import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@wakanda/wakanda-core';
import { Observable } from 'rxjs';
import {
  transactionSigningAuthorize,
  transactionSigningCancel,
  transactionSigningStart,
  transactionTest,
} from '../endpoints/zenAuth.endpoint';
import { IZenAuthTransactionSigningAuthorizeRequestPayload } from '../models/requests.model';
import { IZenAuthNextStepTransactionSigningResponse } from '../models/responses.model';
import { ApiService } from './api.service';

@Injectable()
export class TransactionSigningService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  start(transactionAuthId: string): Observable<IZenAuthNextStepTransactionSigningResponse> {
    return this.api.post<any, IZenAuthNextStepTransactionSigningResponse>(
      transactionSigningStart(transactionAuthId, this.appConfig.backendConfig.transactionSigningUrl),
    );
  }

  authorize(
    transactionAuthId: string,
    authRequest: IZenAuthTransactionSigningAuthorizeRequestPayload,
  ): Observable<IZenAuthNextStepTransactionSigningResponse> {
    return this.api.post<IZenAuthTransactionSigningAuthorizeRequestPayload, IZenAuthNextStepTransactionSigningResponse>(
      transactionSigningAuthorize(transactionAuthId, this.appConfig.backendConfig.transactionSigningUrl),
      authRequest,
    );
  }

  cancel(transactionAuthId: string): Observable<any> {
    return this.api.remove(
      transactionSigningCancel(transactionAuthId, this.appConfig.backendConfig.transactionSigningUrl),
    );
  }

  fakeTx(id?: string, token?: string): Observable<any> {
    const obj = {
      txType: 'TEST',
      amount: 20,
      name: 'Peter',
    };

    if (!token || !id) return this.api.post(transactionTest(this.appConfig.backendConfig.apiUrl), obj);

    const headers = new HttpHeaders({
      'X-AUTH-TX-ID': id,
      'X-AUTH-TX-TOKEN': token,
    });

    return this.api.post(transactionTest(this.appConfig.backendConfig.apiUrl), obj, headers);
  }
}
