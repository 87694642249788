<div class="ui-pin" [ngClass]="{ errored: incorrectPin }">
  <ng-container *ngFor="let digit of digitArray; index as i">
    <span class="pin-item" [ngClass]="{ filled: !!makeGetPinValue(digit), digits: !!showAllDigits }">
      <ng-container *ngIf="digit === 0">
        <input
          type="text"
          [attr.pin-id]="i"
          [id]="makeId(digit)"
          #first
          autofocus="true"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
          [value]="makeGetPinValue(digit) || ''"
          (focus)="onFocus($event)"
          (keyup)="onKeyUp($event)"
        />
      </ng-container>

      <ng-container *ngIf="digit !== 0">
        <input
          type="text"
          [id]="makeId(digit)"
          [attr.pin-id]="i"
          maxlength="1"
          inputmode="numeric"
          autocomplete="off"
          [value]="makeGetPinValue(digit) || ''"
          (focus)="onFocus($event)"
          (keyup)="onKeyUp($event)"
        />
      </ng-container>
    </span>
  </ng-container>

  <input id="blur-hack" type="text" style="position: absolute; opacity: 0; display: none;" />
</div>

<ng-container *ngIf="!!errorMessage && incorrectPin">
  <p class="ui-pin small error">{{ errorMessage }}</p>
</ng-container>
