import { Injectable } from '@angular/core';
import { IAdobeSearchData } from '../model/algolia-search.model';
import { AdobeCore } from '../adobe-core';

@Injectable({
  providedIn: 'root',
})
/*
  Algolia is a search tool for Backend
  Methods below are used in these cases/app areas:
    - application search
    - clicking on a product
    - adding product to cart 
*/
export class AdobeAlgoliaSearchService extends AdobeCore {
  getSearchDataForSku = (sku: string): Omit<IAdobeSearchData, 'vertical' | 'sku'> => {
    if (this.searchData?.sku !== sku) return {};
    return {
      queryId: this.searchData?.queryId,
      trackingId: this.searchData?.trackingId,
      index: this.searchData?.index,
      location: this.searchData?.location,
    };
  };

  setSearchData = (searchData: IAdobeSearchData) => {
    this.searchData = { ...this.searchData, ...searchData };
  };
}
