<div class="container image" [style.background-image]="'url(\'assets/images/download_app.png\')'">
  <div class="banner-title-container">
    <h1>{{ 'merchant.b2c_pre_onboarding_landing.app_header' | translate }}</h1>
    <ui-divider></ui-divider>
    <div class="row">
      <img
        (click)="openAppStore()"
        class="store-image"
        [src]="'assets/images/appstore.png'"
        alt="App store link on our app"
      />

      <ui-divider [vertical]="true" [compact]="true"></ui-divider>

      <img
        (click)="openGooglePlay()"
        class="store-image"
        [src]="'assets/images/google_play.png'"
        alt="Google play link on our app"
      />
    </div>
  </div>
</div>
