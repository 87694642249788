import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { started } from '../application/task.actions';
import * as actions from './notifications.actions';
import { INotificationsPartialState } from './notifications.reducer';
import * as selectors from './notifications.selectors';

@Injectable()
export class NotificationsFacade {
  getNotifications$ = this.store.pipe(select(selectors.getNotifications));
  getNotificationsHasLoadedAll$ = this.store.pipe(select(selectors.getNotificationsHasLoadedAll));
  getNotificationsLastLoadedPage$ = this.store.pipe(select(selectors.getNotificationsLastLoadedPage));
  getRewardNotification$ = this.store.pipe(select(selectors.getRewardNotification));
  // workaorund
  getHasNewJobs$ = this.store.pipe(select(selectors.getHasNewJobs));
  getNewJobs$ = this.store.pipe(select(selectors.getNewJobs));
  getActiveJobs$ = this.store.pipe(select(selectors.getActiveJobs));
  getInterestedJobs$ = this.store.pipe(select(selectors.getInterestedJobs));

  constructor(private store: Store<INotificationsPartialState>) {}

  fetchNotifications = (page?: number, size?: number, silently?: boolean): void => {
    const taskId = `fetching-notifications-page-${page}`;
    this.store.dispatch(
      started(new actions.NotificationsRequestAction(taskId, page, size), 'Fetching Notifications', silently),
    );
  };

  hideNotification = (notificationId: string, silently?: boolean): void => {
    const taskId = `hiding-notification-${notificationId}`;
    this.store.dispatch(
      started(new actions.HideNotificationRequestAction(taskId, notificationId), 'Please wait a moment', silently),
    );
  };

  hideAllNotifications = (): void => {
    const taskId = `hiding-all-notifications-${uuidv4()}`;
    this.store.dispatch(started(new actions.HideAllNotificationsRequestAction(taskId), 'Please wait a moment'));
  };

  // workaorund
  removeCheckedJobNotifications = (ids: string[]): void => {
    if (!ids || !ids.length) return;

    const promise = ids.map(notificationId => this.hideNotification(notificationId, true));

    Promise.all(promise).then(() => this.fetchNotifications(0, 10, true));
  };
}
