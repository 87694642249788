import { Injectable } from '@angular/core';
import { StorageKeys } from '../store/models/store.models';

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {
  get = (key: StorageKeys, useSessionStorage = false, defaultResult = null): any => {
    try {
      const serialized = useSessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key);
      if (serialized === null) return defaultResult;
      return JSON.parse(serialized);
    } catch (err) {
      console.error(err);
      return defaultResult;
    }
  };

  set = (key: StorageKeys, value: any, useSessionStorage = false): void => {
    try {
      const serialized = JSON.stringify(value);
      useSessionStorage ? sessionStorage.setItem(key, serialized) : localStorage.setItem(key, serialized);
    } catch (err) {
      console.error(err);
    }
  };

  remove = (key: StorageKeys, useSessionStorage = false): void => {
    useSessionStorage ? sessionStorage.removeItem(key) : localStorage.removeItem(key);
  };

  clearAll = (useSessionStorage = false): void => {
    useSessionStorage ? sessionStorage.clear() : localStorage.clear();
  };
}
