import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import * as action from './activity-history.actions';
import { IActivityHistoryPartialState } from './activity-history.reducer';
import * as selector from './activity-history.selectors';
import {
  ActivityHistoryType,
  IActivityHistoryOrderDetail,
  IActivityHistoryCancelReasonsResponse,
  IActivityHistoryCancelRequest,
  IActivityHistoryRatingRequest,
  ICustomerOrderMerchantDetailRequest,
} from '../../models/activity-history.model';
import { started } from '../application/task.actions';
import { IRmaDetail, IRmaRequest, RmaType } from '../../models/rma.model';

@Injectable()
export class ActivityHistoryFacade {
  // todo rework
  getOngoingOrders$ = this.store.pipe(select(selector.getOngoingOrders));
  getOngoingOrdersLoadedPage$ = this.store.pipe(select(selector.getOngoingOrdersLastLoadedPage));
  getOngoingOrdersHasLoadedAll$ = this.store.pipe(select(selector.getOngoingOrdersHasLoadedAll));

  // todo rework
  getCompletedOrders$ = this.store.pipe(select(selector.getCompletedOrders));
  getCompletedOrdersLoadedPage$ = this.store.pipe(select(selector.getCompletedOrdersLastLoadedPage));
  getCompletedOrdersHasLoadedAll$ = this.store.pipe(select(selector.getCompletedOrdersHasLoadedAll));

  getOrderMerchantDetail$ = this.store.pipe(select(selector.getOrderMerchantDetail));
  getOrderDetail$ = this.store.pipe(select(selector.getAOrderDetail));
  getSearchResults$ = this.store.pipe(select(selector.getSearchResults));
  getVoucherOrders$ = this.store.pipe(select(selector.getVoucherOrders));
  getCancelReasons$ = this.store.pipe(select(selector.getCancelReasons));
  getRma$ = this.store.pipe(select(selector.getRma));
  getRmaList$ = this.store.pipe(select(selector.getRmaList));
  getRmaCancelReasons$ = this.store.pipe(select(selector.getRmaCancelReasons));
  getRmaDetail$ = this.store.pipe(select(selector.getRmaDetail));

  constructor(private store: Store<IActivityHistoryPartialState>) {}

  fetchActivityHistoryOngoingOrders = (
    onSucceed?: (orderHistory: IActivityHistoryOrderDetail[]) => void,
    silented?: boolean,
    page?: number,
    size?: number,
  ): void => {
    const taskId = `fetch-ongoing-orders-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileActivityHistoryOngoingRequestAction(taskId, onSucceed, page, size),
        'Fetching orders',
        silented,
      ),
    );
  };

  fetchActivityHistorySearch = (query: string, onSucceed: () => void): void => {
    const taskId = `fetch-activity-history-search-${query}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileActivityHistorySearchRequestAction(taskId, query, onSucceed),
        'Fetching activity history search',
        true,
      ),
    );
  };

  fetchActivityHistoryCompletedOrders = (
    onSucceed?: (orderHistory: IActivityHistoryOrderDetail[]) => void,
    silented?: boolean,
    page?: number,
    size?: number,
  ): void => {
    const taskId = `fetch-completed-orders-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileActivityHistoryCompletedRequestAction(taskId, onSucceed, page, size),
        'Fetching completed orders',
        silented,
      ),
    );
  };

  fetchActivityHistoryOrderDetail = (
    orderId: string,
    onSucceed?: (response: IActivityHistoryOrderDetail) => void,
    silently?: boolean,
  ): void => {
    const taskId = `fetch-order-detail-${orderId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileActivityHistoryDetailRequestAction(taskId, orderId, onSucceed),
        'Fetching data',
        silently,
      ),
    );
  };

  fetchActivityHistoryCancelReasons = (
    orderType: ActivityHistoryType,
    onSucceed?: (response: IActivityHistoryCancelReasonsResponse) => void,
  ): void => {
    const taskId = `fetch-cancel-reasons-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileActivityHistoryCancelReasonsRequestAction(taskId, orderType, onSucceed),
        'Fetching data',
      ),
    );
  };

  fetchRmaCancelReasons = (orderId: string, fulfilmentId: string): void => {
    const taskId = `fetch-rma-cancel-reasons-${orderId}-${fulfilmentId}-${uuidv4()}`;
    this.store.dispatch(
      started(new action.MyProfileRmaCancelReasonsRequest(taskId, orderId, fulfilmentId), 'Fetching reasons'),
    );
  };

  sendActivityHistoryRating = (
    orderId: string,
    fulfillmentId: string,
    request: IActivityHistoryRatingRequest,
    onSucceed: () => void,
  ): void => {
    const taskId = `fetch-send-rating-${orderId}- ${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileActivityHistoryRatingRequestAction(taskId, orderId, fulfillmentId, request, onSucceed),
        'Please wait a moment',
      ),
    );
  };

  sendActivityHistoryCancelOrder = (
    orderId: string,
    request: IActivityHistoryCancelRequest,
    onSucceed: () => void,
  ): void => {
    const taskId = `fetch-send-cancel-order-${orderId}- ${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileActivityHistoryCancelOrderRequestAction(taskId, orderId, request, onSucceed),
        'Please wait a moment',
        false,
      ),
    );
  };

  releasePayment = (orderId: string, fulfilmentId: string): void => {
    const taskId = `my-profile-activity-history-release-payment-${orderId}-${fulfilmentId}`;
    this.store.dispatch(
      started(new action.MyProfileActivityHistoryReleasePaymentRequest(taskId, orderId, fulfilmentId)),
    );
  };

  rma = (orderId: string, fulfilmentId: string, type: RmaType, request: IRmaRequest, onSucceeded: () => void): void => {
    const taskId = `rma-${orderId}-${fulfilmentId}-${type}-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileRmaRequest(taskId, orderId, fulfilmentId, type, request, onSucceeded),
        'Please wait a moment',
      ),
    );
  };

  setRma = (data: IRmaRequest): void => {
    const taskId = `set-rma-${JSON.stringify(data)}`;
    this.store.dispatch(new action.MyProfileRmaDataSet(taskId, data));
  };

  fetchRmaDetail = (orderId: string, fulfilmentId: string, rmaId: string): void => {
    const taskId = `fetch-rma-detail-${orderId}-${fulfilmentId}-${rmaId}`;
    this.store.dispatch(
      started(new action.MyProfileFetchRmaDetailRequest(taskId, orderId, fulfilmentId, rmaId), 'Fetching data'),
    );
  };

  fetchRmaList = (activeOnly: boolean, next: number, size: number, onSucceeded: (data: IRmaDetail[]) => void): void => {
    const taskId = `fetch-rma-list-${activeOnly}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new action.MyProfileFetchRmaListRequest(taskId, activeOnly, next, size, onSucceeded),
        'Please wait a moment',
      ),
    );
  };

  fetchOrderMerchantDetail = (request: ICustomerOrderMerchantDetailRequest): void => {
    const taskId = `fetch-order-merchant-detail-${JSON.stringify(request)}`;
    this.store.dispatch(
      started(new action.MyProfileGetOrderMerchantDetailRequestAction(taskId, request), 'Fetching merchant detail'),
    );
  };
}
