import { requestData, wallet } from '../wallet/wallet.endpoints';

export const outgoingRequest = (baseUrl: string, filter: string): string =>
  `${requestData(baseUrl)}/outgoing?status=${filter}`;
export const outgoingPaymentRequestDetail = (baseUrl: string, paymentRequestId: string): string =>
  `${requestData(baseUrl)}/${paymentRequestId}`;
export const outgoingStatement = (baseUrl: string): string => `${requestData(baseUrl)}/outgoing/statement`;
export const requestPay = (walletId: string, requestId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/request/${requestId}/pay`;
export const requestDecline = (walletId: string, requestId: string, baseUrl: string): string =>
  `${wallet(baseUrl)}/${walletId}/request/${requestId}/decline`;
