import { Action } from '@ngrx/store';
import {
  ActivityHistoryType,
  IActivityHistoryOrderDetail,
  IActivityHistoryCancelReasonsResponse,
  IActivityHistoryCancelRequest,
  IActivityHistoryRatingRequest,
  ICustomerOrderMerchantDetailRequest,
  IJobCardMerchantResponse,
} from '../../models/activity-history.model';
import { IRmaCancelReason, IRmaDetail, IRmaRequest, RmaType } from '../../models/rma.model';
import * as EVENT from './activity-history.events';

// MyProfileActivityHistoryOngoing
export class MyProfileActivityHistoryOngoingRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_ONGOING_REQUEST;

  constructor(
    public taskId,
    public onSucceed?: (orderHistory: IActivityHistoryOrderDetail[]) => void,
    public page?: number,
    public size?: number,
  ) {}
}

export class MyProfileActivityHistoryOngoingResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_ONGOING_RESPONSE;

  constructor(public taskId, public data: IActivityHistoryOrderDetail[], public page?: number) {}
}

// MyProfileActivityHistoryCompleted
export class MyProfileActivityHistoryCompletedRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_COMPLETED_REQUEST;

  constructor(
    public taskId,
    public onSucceed?: (orderHistory: IActivityHistoryOrderDetail[]) => void,
    public page?: number,
    public size?: number,
  ) {}
}

export class MyProfileActivityHistoryCompletedResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_COMPLETED_RESPONSE;

  constructor(public taskId, public data: IActivityHistoryOrderDetail[], public page?: number) {}
}

// MyProfileActivityHistoryDetail
export class MyProfileActivityHistoryDetailRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_DETAIL_REQUEST;

  constructor(
    public taskId,
    public orderId: string,
    public onSucceed?: (response: IActivityHistoryOrderDetail) => void,
  ) {}
}

export class MyProfileActivityHistoryDetailResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_DETAIL_RESPONSE;

  constructor(public taskId, public data: IActivityHistoryOrderDetail) {}
}

// MyProfileActivityHistorySearch
export class MyProfileActivityHistorySearchRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_SEARCH_REQUEST;

  constructor(public taskId, public query: string, public onSucceed: () => void) {}
}

export class MyProfileActivityHistorySearchResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_SEARCH_RECEIVED;

  constructor(public taskId, public data: IActivityHistoryOrderDetail[]) {}
}

// MyProfileActivityHistoryCancelReasons
export class MyProfileActivityHistoryCancelReasonsRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_CANCEL_REASONS_REQUEST;

  constructor(
    public taskId,
    public orderType: ActivityHistoryType,
    public onSucceed?: (data: IActivityHistoryCancelReasonsResponse) => void,
  ) {}
}

export class MyProfileActivityHistoryCancelReasonsResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_CANCEL_REASONS_RECEIVED;

  constructor(public taskId, public data: IActivityHistoryCancelReasonsResponse) {}
}

// MyProfileActivityHistoryCancelOrder
export class MyProfileActivityHistoryCancelOrderRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_CANCEL_ORDER_REQUEST;

  constructor(
    public taskId,
    public orderId: string,
    public payload: IActivityHistoryCancelRequest,
    public onSucceed: () => void,
  ) {}
}

export class MyProfileActivityHistoryCancelOrderResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_CANCEL_ORDER_RECEIVED;

  constructor(public taskId) {}
}

// MyProfileActivityHistoryRating
export class MyProfileActivityHistoryRatingRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_SEND_RATING_REQUEST;

  constructor(
    public taskId,
    public orderId: string,
    public fulfillmentId: string,
    public payload: IActivityHistoryRatingRequest,
    public onSucceed: () => void,
  ) {}
}

export class MyProfileActivityHistoryRatingResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_HISTORY_SEND_RATING_RECEIVED;

  constructor(public taskId) {}
}

// Release payment

export class MyProfileActivityHistoryReleasePaymentRequest implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_RELEASE_PAYMENT_REQUEST;

  constructor(public taskId, public orderId: string, public fulfilmentId: string) {}
}

export class MyProfileActivityHistoryReleasePaymentResponse implements Action {
  readonly type = EVENT.MY_PROFILE_ACTIVITY_RELEASE_PAYMENT_RECEIVED;

  constructor(public taskId) {}
}

// MyProfileRmaData
export class MyProfileRmaDataSet implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_DATA_SET;

  constructor(public taskId, public data: IRmaRequest) {}
}

// MyProfileRmaCancelReasons
export class MyProfileRmaCancelReasonsRequest implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_CANCEL_REASONS_REQUEST;

  constructor(public taskId, public orderId: string, public fulfilmentId: string) {}
}

export class MyProfileRmaCancelReasonsResponse implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_CANCEL_REASONS_RECEIVED;

  constructor(public taskId, public data: IRmaCancelReason[]) {}
}

// MyProfileRma
export class MyProfileRmaRequest implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_REQUEST;

  constructor(
    public taskId,
    public orderId: string,
    public fulfilmentId: string,
    public rmaType: RmaType,
    public request: IRmaRequest,
    public onSucceeded: () => void,
  ) {}
}

export class MyProfileRmaResponse implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_RECEIVED;

  constructor(public taskId) {}
}

// MyProfileFetchRmaDetail
export class MyProfileFetchRmaDetailRequest implements Action {
  readonly type = EVENT.MY_PROFILE_FETCH_RMA_DETAIL_REQUEST;

  constructor(public taskId, public orderId: string, public fulfilmentId: string, public rmaId: string) {}
}

export class MyProfileFetchRmaDetailResponse implements Action {
  readonly type = EVENT.MY_PROFILE_FETCH_RMA_DETAIL_RECEIVED;

  constructor(public taskId, public data: IRmaDetail) {}
}

// MyProfileFetchRmaList
export class MyProfileFetchRmaListRequest implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_LIST_REQUEST;

  constructor(
    public taskId,
    public activeOnly: boolean,
    public next?: number,
    public size?: number,
    public onSucceed?: (orderHistory: IRmaDetail[]) => void,
  ) {}
}

export class MyProfileFetchRmaListResponse implements Action {
  readonly type = EVENT.MY_PROFILE_RMA_LIST_RECEIVED;

  constructor(public taskId, public data: IRmaDetail[], public next: number) {}
}

// MyProfileGetOrderMerchantDetail
export class MyProfileGetOrderMerchantDetailRequestAction implements Action {
  readonly type = EVENT.MY_PROFILE_ORDER_MERCHANT_DETAIL_REQUEST;
  constructor(public taskId: string, public payload: ICustomerOrderMerchantDetailRequest) {}
}

export class MyProfileGetOrderMerchantDetailResponseAction implements Action {
  readonly type = EVENT.MY_PROFILE_ORDER_MERCHANT_DETAIL_RECEIVED;
  constructor(public taskId: string, public data: IJobCardMerchantResponse) {}
}

export type ActivityHistoryActions =
  | MyProfileActivityHistoryOngoingRequestAction
  | MyProfileActivityHistoryOngoingResponseAction
  | MyProfileActivityHistoryCompletedRequestAction
  | MyProfileActivityHistoryCompletedResponseAction
  | MyProfileActivityHistoryDetailRequestAction
  | MyProfileActivityHistoryDetailResponseAction
  | MyProfileActivityHistorySearchRequestAction
  | MyProfileActivityHistorySearchResponseAction
  | MyProfileActivityHistoryCancelReasonsRequestAction
  | MyProfileActivityHistoryCancelReasonsResponseAction
  | MyProfileActivityHistoryCancelOrderRequestAction
  | MyProfileActivityHistoryCancelOrderResponseAction
  | MyProfileActivityHistoryRatingRequestAction
  | MyProfileActivityHistoryRatingResponseAction
  | MyProfileActivityHistoryReleasePaymentRequest
  | MyProfileActivityHistoryReleasePaymentResponse
  | MyProfileRmaDataSet
  | MyProfileRmaCancelReasonsRequest
  | MyProfileRmaCancelReasonsResponse
  | MyProfileRmaRequest
  | MyProfileRmaResponse
  | MyProfileFetchRmaDetailRequest
  | MyProfileFetchRmaDetailResponse
  | MyProfileFetchRmaListRequest
  | MyProfileFetchRmaListResponse
  | MyProfileGetOrderMerchantDetailRequestAction
  | MyProfileGetOrderMerchantDetailResponseAction;
