import { createFeatureSelector, createSelector } from '@ngrx/store';
import get from 'lodash-es/get';
import { IZenAutReducerState } from '../models/redux.model';
import { IZenAuthOnboardingNextStepResponse, IZenAuthUserInfo } from '../models/responses.model';
import { ZENAUTH_FEATURE_KEY } from './zenAuth.reducer';

const state = createFeatureSelector<IZenAutReducerState>(ZENAUTH_FEATURE_KEY);

export const getOnboarding = createSelector(state, ({ onboarding }) => onboarding);

export const getOnboardingNextStep = createSelector(
  state,
  ({ onboarding }) => get(onboarding, 'nextStep', null) as IZenAuthOnboardingNextStepResponse,
);

export const getOnboardingUserName = createSelector(state, ({ onboarding }) => get(onboarding, 'userName', null));

export const getOnboardingSuggestedUsernames = createSelector(state, ({ onboarding }) =>
  get(onboarding, 'suggestedUsernames', null),
);

export const getOnboardingSuggestedAddress = createSelector(state, ({ onboarding }) =>
  get(onboarding, 'suggestedAddress', null),
);

export const getTransactionSigningAuthId = createSelector(state, ({ transactionSigning }) =>
  get(transactionSigning, 'transactionAuthId', null),
);

export const getTransactionSigningToken = createSelector(state, ({ transactionSigning }) =>
  get(transactionSigning, 'nextStep.signingToken', null),
);

export const getTransactionSigningNextStep = createSelector(state, ({ transactionSigning }) =>
  get(transactionSigning, 'nextStep', null),
);

export const getTransactionSigningAuthenticators = createSelector(
  state,
  ({ transactionAuthenticators }) => transactionAuthenticators,
);

export const getLoginUserName = createSelector(state, ({ username }) => username);

export const getSuccess = createSelector(state, ({ success }) => success);

export const getReferralDetails = createSelector(state, ({ referralDetails }) => referralDetails);

export const getCountries = createSelector(state, ({ countries }) => countries);

export const getAuthenticate = createSelector(state, ({ authenticate }) => authenticate);

export const getAvoClientInfo = createSelector(state, ({ avoClientInfo }) => avoClientInfo);

export const getBankClientInfo = createSelector(state, ({ bankClientInfo }) => bankClientInfo);

export const getUserInfo = createSelector(state, ({ bankClientInfo, avoClientInfo }) =>
  (avoClientInfo?.linkedToBank && bankClientInfo) || avoClientInfo?.linkedToBank === false
    ? ({
        avoClientInfo,
        bankClientInfo,
      } as IZenAuthUserInfo)
    : null,
);

export const getToken = createSelector(state, ({ token }) => token);
export const isAuthorized = createSelector(state, ({ token }) => !!token);
export const getReferralCode = createSelector(state, ({ referralCode }) => referralCode);
export const getGuestLogin = createSelector(state, ({ guestInfo }) => guestInfo);
export const getNextStep = createSelector(state, ({ nextStep }) => nextStep);
