export enum LocalStorageKeys {
  Enrollment = '__enrollment__',
  RestaurantCode = '__rest_code___',
  PosTheme = '__pos_theme___',
  Cookies = '__cookies__',
  RewardDialog = '__reward_dialog__',
  ReferralCode = '__referral_code__',
  LastPath = '__last_path__',
  DefaultActiveMerchant = '__default_active_merchant__',
  UserLogout = '__user_logout__',
  BuildNumber = '__build_number___',
  OrdersPrintQueue = '__orders_print_queue___',
  PrinterAvailable = '__printer_available___',
  OrdersProcessed = '__orders_processed___',
  DatadogAvailable = '__datadog_available___',
}

export enum TcType {
  termsAndConditions = 'tc',
  promoTermsAndConditions = 'promo-tc',
  privacyPolicy = 'pp',
  termsOfUse = 'tu',
  copyright = 'cr',
  legal = 'lg',
  paymentsAndReservations = 'pr',
  paymentsAndReservationsFAQ = 'pr-faq',
  returnPolicy = 'rp',
  cancellltionPolicy = 'cp',
  faisDisclaimer = 'fd',
  marketingConsent = 'mc',
  faq = 'faq',
  paia = 'paia',
}
