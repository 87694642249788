import { IAddressLocation, IAmount, IPosition } from '@wakanda/wakanda-core';
import { ListType } from './navigation.model';

export enum AgentChatSoundSettings {
  NEW_MESSAGE_ENABLED = 'isNewMessagesNotificationSoundEnabled',
  NEW_CHAT_ENABLED = 'isNewChatsNotificationSoundEnabled',
}

export interface IChatMenu {
  activeItem: ListType;
  isExpandable?: boolean;
}

export interface IInfoModalClick {
  longitude: number;
  latitude: number;
  address: string;
}

export interface IChatQuery {
  from?: string;
  to?: string;
  dir?: IChatQueryDirection;
  limit?: string;
}

export interface IChatQueryDirection {
  asc: 'asc';
  desc: 'desc';
}

export interface IAgent {
  agentId: string;
  status: string;
  name: string;
  busySince?: Date;
}

export interface IAgentStatus {
  status: AgentStatusType;
  busySince?: Date;
}

export interface IAgentDetails {
  agentId: string;
  chats: IAgentChat[];
}

export interface IAgentChat {
  chatId: string;
  roomId: string;
  type: AgentChatType;
  owner: IAgentChatUser;
  roomLabel: string;
  participants: IAgentChatUser[];
  lastMessageAt: Date;
  lastMessageAtEpoch: number;
  lastMessageUserType: string;
  lastMessageUserName: string;
}

export interface ISearchResponse {
  created: string;
  lastMessageAt: string;
  lastMessageAtEpoch: number;
  lastMessageUserType: RoomMemberType;
  message?: string;
  owner: ISearchRoomMember;
  chatId: string;
  roomId: string;
  status: StatusType;
  type: AgentChatType;
}

export interface ISearchRoom {
  id: string;
  created: number;
  alias: string;
  entityType: string;
  entityId: string;
  customerId: string;
  customerName: string;
  merchantId: string;
  merchantName: string;
  members: ISearchRoomMember[];
}

export interface ISearchRoomMember {
  type: RoomMemberType;
  userId: string;
  name: string;
  phoneNumber: string;
  email: string;
  photo: string;
  login: string;
  wakandaId: string;
}

export enum RoomMemberType {
  CUSTOMER = 'CUSTOMER',
  MERCHANT = 'MERCHANT',
  AGENT = 'AGENT',
  UNKNOWN = 'UNKNOWN',
}

export enum StatusType {
  PERMANENT = 'PERMANENT',
  AWAITING_AGENT = 'AWAITING_AGENT',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}

export interface IAgentChatUser {
  userId: string;
  type: UserType;
  wakandaId: string;
  name: string;
  photo?: string;
  login?: string;
  email?: string;
  importanceLevel?: CrmCustomerImportanceLevel;
  wsConnectionsDebugInfo?: IAgentChatUserWsConnectionInfo;
}

export interface IAgentChatUserWsConnectionInfo {
  connectedAt: string;
  connectionId: string;
  disconnectedAt: string;
  id: number;
  token: string;
}

export interface IAgentChatMerchant {
  id: number;
  name: string;
  phone: string;
}

export interface IAgentChatDetails {
  chatId: string;
  roomId: string;
  roomLabel: string;
  type: AgentChatType;
  status: StatusType;
  owner: IAgentChatUser;
  participants: IAgentChatUser[];
  lastMessageAt: Date;
  createdAt: Date;
  pastChats: Date[];
  jobId: string;
}

export interface IAgentChatRoom {
  roomId: string;
}

export interface Locale {
  latitude: number;
  longitude: number;
}

export interface IAddress {
  houseNumber: string;
  streetName?: string;
  suburb?: string;
  city?: string;
  postalCode?: string;
  country?: string;
  countryCode?: string;
}

export interface IStaticData {
  responses: string[];
  images: IImage[];
  bots: IBot[];
}

export interface IImage {
  imageId: string;
  title: string;
  thumbnail: string;
  link: string;
}

export interface IBot {
  botId: string;
  name: string;
}

export enum AgentStatusType {
  ONLINE = 'ONLINE',
  BUSY = 'BUSY',
  OFFLINE = 'OFFLINE',
}

export enum UserType {
  CUSTOMER = 'CUSTOMER',
  MERCHANT = 'MERCHANT',
  AGENT = 'AGENT',
  BOT = 'BOT',
  UNKNOWN = 'UNKNOWN',
}

export enum AgentChatType {
  INTERNAL = 'INTERNAL',
  AGENT = 'AGENT',
  SUPPORT = 'SUPPORT',
  JOB_C2M = 'JOB_C2M',
  JOB = 'JOB',
  JOB_CONCIERGE = 'JOB_CONCIERGE',
  OAS = 'OAS',
  CONCIERGE = 'CONCIERGE',
  ORDER_C2M = 'ORDER_C2M',
}

export enum CrmCustomerImportanceLevel {
  STANDARD = 'Standard',
  VIP = 'VIP',
  VVIP = 'VVIP',
}

export enum AgentChatEventType {
  ROOM_MESSAGE = 'm.room.message',
}

export interface ISendInformalPaymentRequest {
  jobId: string;
  merchantId: string;
  request: ISendInformalPaymentRequestBase;
}

export interface ISendInformalPaymentRequestBase {
  amountToReserve: IAmount;
  description?: string;
}

export interface IAddInformalMerchantRequest {
  merchantName: string;
  merchantPhone: string;
}

export interface IOrderDetailLocation {
  addressString: string;
  addressId: string;
  name: string;
  type: string;
  streetName: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  stateOrProvince: string;
  country: string;
  suburb: string;
  complex: string;
  geocodingData: string;
  deliveryInstructions: string;
  operatingRadius: number;
  latitude: number;
  longitude: number;
}

export interface IOrderDetailExternalMerchantAdditionalData {
  category: string;
  option: string;
  supplierId: string;
  supplierName: string;
  contactPerson: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  country: string;
  level1Category: string;
  level2Category: string;
  telephone1: string;
  telephone2: string;
  emailAddress: string;
  location: IPosition;
}

export enum OrderDetailMerchantType {
  EXTERNAL = 'EXTERNAL',
  ONBOARDED = 'ONBOARDED',
}

export interface IOrderDetailQuote {
  id: number;
  merchantType: OrderDetailMerchantType;
  merchantId: string;
  chatRoomId: string;
  escrowId: string;
  merchantName: string;
  merchantPhone: string;
  externalMerchantAdditionalData: IOrderDetailExternalMerchantAdditionalData;
  status: string;
  repliedOn: string;
  notInterestedReasonId: string;
  notInterestedNote: string;
  comment: string;
  invoiceDocuments: string[];
  crmUrl: string;
}

export interface IOrderDetail {
  id: number;
  customerId: string;
  name: string;
  description: string;
  crmLocationId: string;
  location: IOrderDetailLocation;
  serviceCategory: string;
  serviceCategoryName: string;
  serviceSubCategory: string;
  serviceSubCategoryName: string;
  serviceL3CategoryName?: string;
  quotes: IOrderDetailQuote[];
  urgent: boolean;
  status: string;
  statusNote: string;
  placedOn: string;
  acceptedOn: string;
  completedOn: string;
  photos: string[];
  reviewGivenByMerchant: boolean;
  reviewGivenByCustomer: boolean;
}

export interface IUserRoom {
  chats: IAgentChat[];
  name: string;
  userId: string;
}

export interface IAgentChatDashboard {
  days: IAgentChatDashboardItem[];
  hours: IAgentChatDashboardItem[];
}

export interface IAgentChatAgentsDashboard {
  [key: string]: IAgentChatDashboard;
}

export interface IAgentChatDashboardItem {
  periodType?: AgentChatDashboardPeriodType | string;
  chatType?: AgentChatDashboardChatType | string;
  periodStart?: string;
  chatsCreated?: number;
  chatsClosed: number;
  avgResponseTime?: number;
  maxResponseTime?: number;
  minResponseTime?: number;
  noResponsesCount?: number;
  responsesCount: number;
  stdDevResponseTime?: number;
}

export interface IMyStoreCategory {
  id: string;
  name: string;
  children: IMyStoreCategory[];
}

export enum AgentChatDashboardChatType {
  CONCIERGE = 'CONCIERGE',
  JOB_CONCIERGE = 'JOB_CONCIERGE',
  SUPPORT_CUSTOMER = 'SUPPORT_CUSTOMER',
  SUPPORT_MERCHANT = 'SUPPORT_MERCHANT',
  JOB = 'JOB',
  ORDER = 'ORDER',
}

export enum AgentChatDashboardPeriodType {
  DAY = 'DAY',
  HOUR = 'HOUR',
}

export enum SearchTabs {
  MESSAGES = 'Messages',
  PEOPLE = 'People',
}

export enum ChatIds {
  INTERNAL_CHAT_ID = 'ALL_AGENTS',
}

export enum AgentChatNoticationMessagesCountType {
  NOTIFICATIONS_NEW_QUEUE_MESSAGE = 'notificationNewQueueMessage',
  NOTIFICATIONS_NEW_MESSAGE = 'notificationNewMessage',
  NOTIFICATIONS_NEW_AGENT_MESSAGE = 'notificationNewAgentMessage',
}

export interface IOasChatbotRequest {
  roomId: string;
  userId: string;
}

export interface IOasJob {
  serviceId: string;
  chatRoomId: string;
  projectName: string;
  projectDescription: string;
  urgent: boolean;
  crmLocationId: string;
  picturesIds?: string[];
}

export interface ICustomerDetail {
  addresses: IAddressLocation[];
  firstName: string;
  lastName: string;
  customerId: string;
  email: string;
}

export interface IMerchantLookupItem {
  name: string;
  id: string;
}

export interface IMerchantsLookupRequest {
  merchantIds: string[];
  roomId: string;
}

export interface IProductLookupItem {
  name: string;
  sku: string;
  thumbnail: string;
  money?: IAmount;
}

export interface IProductsLookupRequest {
  skus: string[];
  roomId: string;
}

export interface IAverageTimes {
  avgResponseTime: number;
  avgWaitTime: number;
  heyNedAvgResponseTime: number;
  heyNedAvgWaitTime: number;
}

export interface IHeynedSubscriptionStatus {
  active: boolean;
  subscriptionId: string;
  type: IHeynedSubscriptionType;
  trial: boolean;
  autoRenew: boolean;
  willBeChargedOnCreatingRoom: boolean;
}

export enum IHeynedSubscriptionType {
  MONTHLY = 'MONTHLY',
  PER_TASK = 'PER_TASK',
}

export interface IAllActiveChats {
  customers: IAgentChat[];
  merchantss: IAgentChat[];
}

export interface ISetRoomLabelRequest {
  roomLabel: string;
}
