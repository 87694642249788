/* eslint-disable no-bitwise */
import { Pipe, PipeTransform } from '@angular/core';

export enum HashStringPrefix {
  BUTTON = 'btn-',
  TEXT = 'txtf-',
  MESSAGE = 'msg-',
  RADIO = 'radio-',
  CHECKBOX = 'checkbox-',
  DEFAULT = 'elem-',
}

@Pipe({ name: 'hashString' })
export class HashStringPipe implements PipeTransform {
  transform(content?: string, prefix?: HashStringPrefix): string {
    if (!content) return '';
    let hash = 0;
    let hashString = '';
    if (typeof prefix === 'undefined') {
      prefix = HashStringPrefix.DEFAULT;
    }
    for (let i = 0; i < content.length; i++) {
      const char = content.charCodeAt(i);
      hash = (hash << 1) - hash + char;
      hash = hash & hash;
      hashString = prefix + hash.toString();
    }

    return hashString;
  }
}
