import { Action } from '@ngrx/store';
import { IAddressForm, ILocationV3, INewLocationV3, IPosition } from '../../models/location.model';
import { EVENT } from './location.events';
import { HttpErrorAction } from '../application/task.actions';
import { IBuyerLocation, IBuyerLocationRequest } from '../../models/buyer.model';

// LocationFetchLocations
export class LocationFetchLocationsRequest implements Action {
  readonly type = EVENT.LOCATION_FETCH_LOCATIONS_REQUEST;
  constructor(public taskId: string) {}
}

export class LocationFetchLocationsReceived implements Action {
  readonly type = EVENT.LOCATION_FETCH_LOCATIONS_RECEIVED;
  constructor(public taskId: string, public data: ILocationV3[]) {}
}

// LocationFetchMerchantLocations
export class LocationFetchMerchantLocationsRequest implements Action {
  readonly type = EVENT.LOCATION_FETCH_MERCHANT_LOCATIONS_REQUEST;
  constructor(public merchantId: string, public taskId: string) {}
}

export class LocationFetchMerchantLocationsReceived implements Action {
  readonly type = EVENT.LOCATION_FETCH_MERCHANT_LOCATIONS_RECEIVED;

  constructor(public taskId: string, public data: IBuyerLocation[]) {}
}

// LocationAddMerchantLocation
export class LocationAddMerchantLocationRequest implements Action {
  readonly type = EVENT.LOCATION_ADD_MERCHANT_LOCATION_REQUEST;
  constructor(
    public taskId: string,
    public request: IBuyerLocationRequest,
    public merchantId: string,
    public onSucceed: (response: IBuyerLocation) => void,
    public onError?: () => void,
  ) {}
}

export class LocationAddMerchantLocationReceived implements Action {
  readonly type = EVENT.LOCATION_ADD_MERCHANT_LOCATION_RECEIVED;

  constructor(public taskId: string, public data: IBuyerLocation) {}
}

// LocationFetchCurrentLocation
export class LocationFetchCurrentLocationRequest implements Action {
  readonly type = EVENT.LOCATION_FETCH_CURRENT_LOCATION_REQUEST;
  constructor(
    public taskId: string,
    public onSucceed?: (response: IAddressForm, json?: string) => void,
    public onError?: () => void,
  ) {}
}

export class LocationFetchCurrentLocationReceived implements Action {
  readonly type = EVENT.LOCATION_FETCH_CURRENT_LOCATION_RECEIVED;
  constructor(public taskId: string, public data: ILocationV3) {}
}

// LocationFetchCurrentLocationFailed
export class LocationFetchCurrentLocationFailed extends HttpErrorAction {
  override readonly type = EVENT.LOCATION_FETCH_CURRENT_LOCATION_FAILED;
}

// LocationFetchNearestLocation
export class LocationFetchNearestLocationRequest implements Action {
  readonly type = EVENT.LOCATION_FETCH_NEAREST_LOCATION_REQUEST;
  constructor(
    public taskId: string,
    public position: IPosition,
    public onSucceed?: (response: IAddressForm, json?: string) => void,
  ) {}
}

export class LocationFetchNearestLocationReceived implements Action {
  readonly type = EVENT.LOCATION_FETCH_NEAREST_LOCATION_RECEIVED;
  constructor(public taskId: string, public data: ILocationV3) {}
}

// LocationEditLocation
export class LocationEditLocationRequest implements Action {
  readonly type = EVENT.LOCATION_EDIT_LOCATION_REQUEST;
  constructor(
    public taskId: string,
    public locationId: string,
    public request: INewLocationV3,
    public onSucceed: () => void,
    public onError: () => void,
  ) {}
}

export class LocationEditLocationReceived implements Action {
  readonly type = EVENT.LOCATION_EDIT_LOCATION_RECEIVED;
  constructor(public taskId: string) {}
}

// LocationDeleteLocationRequest
export class LocationDeleteLocationRequest implements Action {
  readonly type = EVENT.LOCATION_DELETE_LOCATION_REQUEST;
  constructor(public taskId: string, public locationId: string, public onSucceed: () => void) {}
}

export class LocationDeleteLocationReceived implements Action {
  readonly type = EVENT.LOCATION_DELETE_LOCATION_RECEIVED;
  constructor(public taskId: string) {}
}

// LocationSetCurrentLocation
export class LocationSetCurrentLocationRequest implements Action {
  readonly type = EVENT.LOCATION_SET_CURRENT_LOCATION_REQUEST;
  constructor(
    public taskId: string,
    public locationId: string,
    public onSucceed?: (response: IAddressForm, json?: string) => void,
  ) {}
}

export class LocationSetCurrentLocationReceived implements Action {
  readonly type = EVENT.LOCATION_SET_CURRENT_LOCATION_RECEIVED;
  constructor(public taskId: string, public data: ILocationV3) {}
}

// LocationFetchSearchByQuery
export class LocationFetchSearchByQueryRequest implements Action {
  readonly type = EVENT.LOCATION_FETCH_SEARCH_BY_QUERY_REQUEST;
  constructor(
    public taskId: string,
    public query: string,
    public onSucceed: (query: string, response: google.maps.places.AutocompletePrediction[]) => void,
    public countryCodes?: string[],
  ) {}
}

export class LocationFetchSearchByQueryReceived implements Action {
  readonly type = EVENT.LOCATION_FETCH_SEARCH_BY_QUERY_RECEIVED;
  constructor(public taskId: string, public data: google.maps.places.AutocompletePrediction[]) {}
}

// LocationFindPlaceByAddressString
export class LocationFindPlaceByAddressStringRequest implements Action {
  readonly type = EVENT.LOCATION_FIND_PLACE_BY_ADDRESSSTRING_REQUEST;
  constructor(
    public taskId: string,
    public addressString: string,
    public onSucceed?: (response: IAddressForm, json?: any) => void,
    public onError?: (error?: any) => void,
  ) {}
}

export class LocationFindPlaceByAddressStringReceived implements Action {
  readonly type = EVENT.LOCATION_FIND_PLACE_BY_ADDRESSSTRING_RECEIVED;
  constructor(public taskId: string, public data: google.maps.GeocoderResult[]) {}
}

// LocationFindPlaceByPosition
export class LocationFindPlaceByPositionRequest implements Action {
  readonly type = EVENT.LOCATION_FIND_PLACE_BY_POSITION_REQUEST;
  constructor(
    public taskId: string,
    public position: IPosition,
    public onSucceed?: (response: IAddressForm, json?: string) => void,
    public onError?: (error?: any) => void,
  ) {}
}

export class LocationFindPlaceByPositionReceived implements Action {
  readonly type = EVENT.LOCATION_FIND_PLACE_BY_POSITION_RECEIVED;
  constructor(public taskId: string, public data: google.maps.GeocoderResult[]) {}
}

export class LocationClear implements Action {
  readonly type = EVENT.LOCATION_CLEAR;
  constructor(public taskId: string) {}
}

export type SetLocationAction =
  | LocationFetchNearestLocationRequest
  | LocationFetchNearestLocationReceived
  | LocationFetchCurrentLocationRequest
  | LocationFetchCurrentLocationReceived
  | LocationFetchCurrentLocationFailed
  | LocationFindPlaceByAddressStringReceived
  | LocationFindPlaceByAddressStringRequest
  | LocationFindPlaceByPositionReceived
  | LocationFindPlaceByPositionRequest
  | LocationFetchSearchByQueryRequest
  | LocationFetchSearchByQueryReceived
  | LocationEditLocationRequest
  | LocationEditLocationReceived
  | LocationSetCurrentLocationRequest
  | LocationSetCurrentLocationReceived
  | LocationFetchLocationsRequest
  | LocationFetchLocationsReceived
  | LocationFetchMerchantLocationsRequest
  | LocationFetchMerchantLocationsReceived
  | LocationAddMerchantLocationRequest
  | LocationAddMerchantLocationReceived
  | LocationClear;
