import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { INotificationsResponse } from '../../models/notifications.model';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { ApplicationFacade } from '../application/application.facade';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';
import * as actions from './notifications.actions';
import { EVENTS } from './notifications.events';

@Injectable()
export class NotificationsEffects {
  fetchNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.NotificationsRequestAction>(EVENTS.NOTIFICATIONS_REQUEST),
      switchMap(({ taskId, page, size }) =>
        this.service.fetchNotifications(page, size).pipe(
          map((data: INotificationsResponse[]) => succeed(new actions.NotificationsResponseAction(taskId, data, page))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  postHideNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.HideNotificationRequestAction>(EVENTS.NOTIFICATIONS_HIDE_REQUEST),
      switchMap(({ taskId, notificationId }) =>
        this.service.hideNotification(notificationId).pipe(
          map(() => succeed(new actions.HideNotificationResponseAction(taskId, notificationId))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  postHideAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.HideAllNotificationsRequestAction>(EVENTS.NOTIFICATIONS_HIDE_ALL_REQUEST),
      switchMap(({ taskId }) =>
        this.service.hideAllNotifications().pipe(
          map(() => succeed(new actions.HideAllNotificationsResponseAction(taskId))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: NotificationsService) {}
}
