import { Component, Injector, Input } from '@angular/core';
import { CommonComponent } from '@wakanda/wakanda-core';
import { ModalService } from '@wakanda/avo-business-core';

@Component({
  selector: 'wakanda-avo-business-contact-support-card',
  templateUrl: './contact-support-card.component.html',
  styleUrls: ['./contact-support-card.component.scss'],
})
export class ContactSupportCardComponent extends CommonComponent {
  @Input() imageUrl: string;
  @Input() primaryText: string;
  @Input() secondaryText: string;
  modalService: ModalService;

  constructor(injector: Injector) {
    super(injector);
    this.modalService = injector.get(ModalService);
  }

  openSupportModal = (): void => {
    this.modalService.showSupportModal(true);
  };
}
