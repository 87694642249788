import { HttpErrorResponse } from '@angular/common/http';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import replace from 'lodash-es/replace';
import { Subscription } from 'rxjs';
import { TranslateFacade } from '../state/translate.facade';

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform, OnDestroy {
  private subscription = new Subscription();

  constructor(private translateFacade: TranslateFacade) {}

  transform(key: string, param?: any): string {
    let translate = '';
    this.subscription.add(
      this.translateFacade.getTranslateByKey$(key).subscribe(
        response => {
          translate = response;
        },
        (error: HttpErrorResponse) => {
          translate = key;
          console.error(`An error has occurred with key ${key}. Error message: ${error.message}`);
        },
      ),
    );

    if (param) {
      return this.replaceParams(translate, param);
    }

    return translate;
  }

  // TODO: replace by regex
  private replaceParams(translate: string, param: any): string {
    if (typeof param !== 'object') {
      if (translate.includes('$0')) {
        return replace(translate, '$0', param);
      } else if (translate.includes('$1')) {
        return replace(translate, '$1', param);
      }
      return replace(translate, '$', param);
    } else {
      for (const key in param) {
        if (Object.prototype.hasOwnProperty.call(param, key)) {
          translate = replace(translate, key, param[key]);
        }
      }
      return translate;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
