import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IAdvertisingBannerResponse } from '../../models/advertising.model';
import { AdvertisingService } from '../../services/advertising/advertising.service';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';
import * as actions from './advertising.actions';
import { EVENTS } from './advertising.events';

@Injectable()
export class AdvertisingEffects {
  fetchBanner$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AdvertisingBannerRequestAction>(EVENTS.ADVERTISING_BANNER_REQUEST),
      switchMap(({ taskId, bannerRequest, onSucceed }) =>
        this.service.fetchBanner(bannerRequest).pipe(
          map((data: IAdvertisingBannerResponse) => {
            if (onSucceed) onSucceed(data);
            return succeed(new actions.AdvertisingBannerResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  bannerClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AdvertisingBannerClickRequestAction>(EVENTS.ADVERTISING_BANNER_CLICK_REQUEST),
      switchMap(({ taskId, bannerId, bannerClick, onSucceed }) =>
        this.service.bannerClick(bannerId, bannerClick).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.AdvertisingBannerClickResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  bannerForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AdvertisingBannerFormRequestAction>(EVENTS.ADVERTISING_BANNER_FORM_REQUEST),
      switchMap(({ taskId, bannerId, bannerFormRequest, onSucceed }) =>
        this.service.bannerForm(bannerId, bannerFormRequest).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.AdvertisingBannerFormResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private service: AdvertisingService) {}
}
