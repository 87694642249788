import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { AppConfigService } from '../../services/app-config.service';
import { httpErrorAction } from '../application/application.actions';
import { TaskType } from '../models/store.models';
import { TasksFacade } from '../tasks/tasks.facade';
import * as actions from './app-config.actions';

@Injectable({
  providedIn: 'root',
})
export class AppConfigEffects {
  constructor(private actions$: Actions, private tasksFacade: TasksFacade, private service: AppConfigService) {}

  fetchAppConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchAppConfigRequestAction),
      mergeMap(({ taskMetadata, onSuccess, onError }) =>
        this.service.fetchAppConfig().pipe(
          map(data => {
            if (onSuccess) onSuccess();
            return actions.fetchAppConfigResponseAction({
              data,
              taskMetadata: { type: TaskType.SUCCESS },
            });
          }),
          catchError(error => {
            if (onError) onError();
            return of(httpErrorAction({ error, data: { taskId: taskMetadata?.taskId, type: TaskType.FAILED_SILENT } }));
          }),
          finalize(() => this.tasksFacade.finishTask(taskMetadata?.taskId || '')),
        ),
      ),
    ),
  );
}
