import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-expand',
  template: `
    <div class="ui-expand" [ngClass]="{ 'grey-background': useBackground }">
      <div class="title" (click)="handleTitleClick()" [style.padding]="noPadding ? '0' : '0 0.75rem'">
        <b [ngClass]="{ small: small }">
          {{ title }}
        </b>
        <ui-divider [compact]="true" [vertical]="true"></ui-divider>
        <ui-icon *ngIf="!expanded" icon="down" size="1rem"></ui-icon>
        <ui-icon *ngIf="expanded" icon="up" size="1rem"></ui-icon>
      </div>
      <div
        class="content"
        [style.max-height]="height || '5rem'"
        [class.expanded]="expanded"
        [class.collapsed]="!expanded"
      >
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class ExpandComponent {
  @Input() expanded: boolean;
  @Input() noPadding: boolean;
  @Input() small: boolean;
  @Input() title: string;
  @Input() height: string;
  @Input() useBackground = true;

  @Output() onToogle = new EventEmitter<any>();

  handleTitleClick = (): void => {
    this.onToogle.emit();
  };
}
