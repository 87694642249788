<div (clickOutside)="isDropdownOpened = false">
  <div class="row cursor-pointer" (click)="isDropdownOpened = !isDropdownOpened">
    <a
      [class.title-active]="
        (router.url.includes(routes.b2c.landing) || router.url.includes(routes.b2b.landing)) &&
        !router.url.includes(routes.businessBuying.landing)
      "
      zen-translate
    >
      merchant.pre_onboarding.sell_on_avo
    </a>

    <ui-icon icon="{{ isDropdownOpened ? 'up' : 'down' }}" size="0.8rem" class="text-align-right"></ui-icon>
  </div>

  <div class="selling-avo-dropdown-menu" [class.is-active]="isDropdownOpened">
    <ng-container *ngFor="let item of sellingOnAvoMenu">
      <h3 (click)="navigateToLink(item.link)" zen-translate>{{ item.title }}</h3>
      <ui-divider *ngIf="item?.subitems?.length" [compact]="true"></ui-divider>
      <ng-container *ngFor="let subitem of item?.subitems">
        <a [class.active]="router.url === subitem.link" (click)="navigateToLink(subitem.link)" zen-translate>
          {{ subitem.title }}
        </a>
        <ui-divider [compact]="true"></ui-divider>
      </ng-container>
    </ng-container>
  </div>
</div>
