import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';

export interface ITimer {
  timer: number;
  timeoutCount?: number;
  callback(): void;
  timeoutCallback?(): void;
}

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  timerData: ITimer;
  subscription: Subscription;
  counter = 0;
  public start = (timer: ITimer): void => {
    if (timer) {
      this.timerData = timer;
      this.subscription = interval(timer.timer).subscribe(() => {
        this.counter++;
        if (this.timerData.timeoutCount && this.timerData.timeoutCallback) {
          if (this.counter === timer.timeoutCount) {
            this.timerData.timeoutCallback();
            return;
          }
        }
        this.timerData.callback();
      });
    }
  };

  public isRunning = (): boolean => !!this.timerData;

  public stop = (): void => {
    this.timerData = null;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  };
}
