import { Component, Input } from '@angular/core';
import { IMatrixMessage, IMessageEventLocationSet, MessageEventType } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-location-set',
  styleUrls: ['./chat-widget-location.component.scss'],
  template: `
    <div class="ui-chat-message" [ngClass]="{ outgoing: true }">
      <p>{{ message.content.addressString }}</p>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ outgoing: true }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
  `,
})
export class ChatWidgetLocationSetComponent {
  @Input() message: IMatrixMessage<IMessageEventLocationSet>;
  MessageEventType = MessageEventType;
}
