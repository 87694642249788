import { Component, Injector } from '@angular/core';
import { LayoutBaseContainer } from '../layout.base.container';
import { ModalType } from '@wakanda/wakanda-ui';
import { ResponsiveType } from '@wakanda/wakanda-core';
import { Observable } from 'rxjs';

@Component({
  selector: 'wakanda-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent extends LayoutBaseContainer {
  modalType: ModalType;
  isRegisterModalVisible$: Observable<boolean>;
  isSupportModalVisible$: Observable<boolean>;

  constructor(injector: Injector) {
    super(injector);
    this.isRegisterModalVisible$ = this.modalService.getRegisterModalVisibility();
    this.isSupportModalVisible$ = this.modalService.getSupportModalVisibility();
    this.subscriptions.add(
      this.responsiveService.getActiveScreenType().subscribe(type => {
        if (!type) return;
        this.screenType = type;
        this.modalType =
          this.screenType === ResponsiveType.mobile || this.screenType === ResponsiveType.tablet
            ? ModalType.SLIDE_IN_UP
            : ModalType.FADE_IN;
      }),
    );
  }
}
