import { HttpErrorResponse } from '@angular/common/http';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import replace from 'lodash-es/replace';
import { Subscription } from 'rxjs';
import { TranslateFacade } from '../state/translate.facade';
import { catchError } from 'rxjs/operators';

@Pipe({ name: 'deliveryTimeTranslate', pure: true })
export class DeliveryTimeTranslatePipe implements PipeTransform, OnDestroy {
  private subscription = new Subscription();
  private mhdTranslate: string[];

  constructor(private translateFacade: TranslateFacade) {}

  transform(key: string, param?: any): string {
    let translate = '';
    let translateMinHourDay: string[] = [];

    if (param && Object.prototype.hasOwnProperty.call(param, 'trans')) {
      translateMinHourDay = param['trans'];
    }

    this.subscription.add(
      this.translateFacade
        .getTranslatesByKeys$(translateMinHourDay)
        .subscribe(response => (this.mhdTranslate = response)),
    );
    this.subscription.add(
      this.translateFacade
        .getTranslateByKey$(key)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error(`An error has occurred with key ${key}. Error message: ${error.message}`);
            return key;
          }),
        )
        .subscribe(response => (translate = response)),
    );

    if (param) {
      return this.replaceParams(translate, param);
    }

    return translate;
  }

  private secondsToHms(duration: number) {
    const h = Math.floor(duration / 60);
    const m = Math.floor(duration);
    const day = Math.floor(duration / 60 / 24);

    if (duration >= 0 && duration < 120) {
      return m > 0 ? replace(this.mhdTranslate[0], '$1', m.toString()) : replace(this.mhdTranslate[0], '$1', '0');
    } else if (duration >= 120 && duration < 1440) {
      return h > 0 ? replace(this.mhdTranslate[1], '$1', h.toString()) : replace(this.mhdTranslate[1], '$1', '0');
    } else {
      if (day > 0) {
        return day == 1
          ? replace(this.mhdTranslate[2], '$1', day.toString())
          : replace(this.mhdTranslate[3], '$1', day.toString());
      }
      return replace(this.mhdTranslate[3], '$1', '0');
    }
  }

  private replaceParams(translate: string, param: any): string {
    if (typeof param !== 'object') {
      if (translate.includes('$0')) {
        return replace(translate, '$0', param);
      } else if (translate.includes('$1')) {
        return replace(translate, '$1', param);
      }
      return replace(translate, '$', param);
    } else {
      for (const key in param) {
        if (Object.prototype.hasOwnProperty.call(param, key)) {
          const converted = this.secondsToHms(param[key]);
          translate = replace(translate, key, converted);
        }
      }
      return translate;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
