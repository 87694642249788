import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  IAuthOTPRequest,
  IAuthOTPResendRequest,
  IAuthPasswordlessLoginStartRequest,
  IAuthProfileRequest,
  IAuthRefreshTokenStartRequest,
  IAuthSocialLoginStartRequest,
  IZenAuthAuthenticateRequest,
  IZenAuthBankOtpRequest,
  IZenAuthBasicDataRequest,
  IZenAuthBusinessAddressRequest,
  IZenAuthBusinessBasicDataRequest,
  IZenAuthBusinessLegalEntityRequest,
  IZenAuthCheckBankRequest,
  IZenAuthIdPassportNumberRequest,
  IZenAuthLegalEntityRequest,
  IZenAuthLoginRequest,
  IZenAuthOnboardingAddressRequest,
  IZenAuthOnboardingApproveItRequest,
  IZenAuthOnboardingBankphoneDifferentRequest,
  IZenAuthOnboardingBusinessCategoryInfoRequest,
  IZenAuthOnboardingBusinessDetailsRequest,
  IZenAuthOnboardingBusinessPartnerRequest,
  IZenAuthOnboardingBusinessTradingNameRequest,
  IZenAuthOnboardingExternalAuthRequest,
  IZenAuthOnboardingFirstNameLastNameAuthRequest,
  IZenAuthOnboardingIdPhotoRequest,
  IZenAuthOnboardingLinkAccountRequest,
  IZenAuthOnboardingLoginRequest,
  IZenAuthOnboardingMobiMoneyRequest,
  IZenAuthOnboardingOtpRequest,
  IZenAuthOnboardingOutstandingTCsRequest,
  IZenAuthOnboardingPersonalDataRequest,
  IZenAuthOnboardingProgressRequest,
  IZenAuthOnboardingSecurityQuestionsRequest,
  IZenAuthOnboardingSelectNedbankRequest,
  IZenAuthOnboardingSelectOtpRequest,
  IZenAuthOnboardingSelectSocialRequest,
  IZenAuthOnboardingSentApproveItRequest,
  IZenAuthOnboardingSignupManualRequest,
  IZenAuthOnboardingSignupRequest,
  IZenAuthOnboardingStartInfoRequest,
  IZenAuthOnboardingStartLinkAccountRequest,
  IZenAuthOnboardingStartManualRequest,
  IZenAuthOnboardingStartMoneyAppRequest,
  IZenAuthOnboardingStartRequest,
  IZenAuthOnboardingStartSocialRequest,
  IZenAuthOnboardingStepupRequest,
  IZenAuthOnboardingSuccessRequest,
  IZenAuthOnboardingTCsRequest,
  IZenAuthSocialByRequest,
  IZenAuthTransactionSigningAuthorizationHeaders,
  IZenAuthTransactionSigningAuthorizeRequest,
} from '../models/requests.model';
import { v4 as uuidv4 } from 'uuid';
import {
  IAuthNextStep,
  IZenAuthAuthenticateResponse,
  IZenAuthInfoStepUpRequest,
  IZenAuthOnboardingAvoClientInfo,
  IZenAuthOnboardingBankClientInfo,
  IZenAuthOnboardingCountry,
  IZenAuthOnboardingNextStepResponse,
  IZenAuthPersonalInformationEditEmailRequest,
  IZenAuthPersonalInformationResponse,
  IZenAuthSignupInfoResponse,
  IZenGuestLoginResponse,
} from '../models/responses.model';
import * as actions from './zenAuth.actions';
import { IZenAuthPartialState } from './zenAuth.reducer';
import * as selector from './zenAuth.selector';
import { IAuthenticators, IZenAuthAccessToken, started } from '@wakanda/wakanda-core';

@Injectable({ providedIn: 'root' })
export class ZenAuthFacade {
  // Onboarding
  getOnboarding$ = this.store.pipe(select(selector.getOnboarding));
  // Onboarding NextStep
  getOnboardingNextStep$ = this.store.pipe(select(selector.getOnboardingNextStep));
  // Onboarding UserName
  getOnboardingUserName$ = this.store.pipe(select(selector.getOnboardingUserName));
  // Login username
  getLoginUsername$ = this.store.pipe(select(selector.getLoginUserName));
  // Suggested usernames
  getSuggestedUsernames$ = this.store.pipe(select(selector.getOnboardingSuggestedUsernames));
  // Suggested address
  getSuggestedAddress$ = this.store.pipe(select(selector.getOnboardingSuggestedAddress));
  // Countries
  getCountries$ = this.store.pipe(select(selector.getCountries));

  getAuthenticate$ = this.store.pipe(select(selector.getAuthenticate));

  getTransactionNextStep$ = this.store.pipe(select(selector.getTransactionSigningNextStep));
  getTransactionAuthenticators$ = this.store.pipe(select(selector.getTransactionSigningAuthenticators));

  getSuccess$ = this.store.pipe(select(selector.getSuccess));
  getReferralDetails$ = this.store.pipe(select(selector.getReferralDetails));
  getBankClientInfo$ = this.store.pipe(select(selector.getBankClientInfo));
  getAvoClientInfo$ = this.store.pipe(select(selector.getAvoClientInfo));
  getUserInfo$ = this.store.pipe(select(selector.getUserInfo));

  getToken$ = this.store.pipe(select(selector.getToken));
  isAuthorized$ = this.store.pipe(select(selector.isAuthorized));
  getReferralCode$ = this.store.pipe(select(selector.getReferralCode));
  getGuestLogin$ = this.store.pipe(select(selector.getGuestLogin));

  //login
  getNextStep$ = this.store.pipe(select(selector.getNextStep));

  signingAuthorizeTaskId = (id: string): string => `zenauth-onboarding-signing-authorize-with-id-${id}`;

  constructor(private store: Store<IZenAuthPartialState>) {}

  fetchStart = (onSucceeded: (startResponse: IZenAuthAuthenticateResponse) => void): void => {
    const taskId = `zenauth-start-request-${uuidv4()}`;
    this.store.dispatch(started(new actions.StartRequestAction(taskId, onSucceeded), 'Please wait for a moment'));
  };

  fetchStartSocial = (
    request: IZenAuthOnboardingStartSocialRequest,
    onSucceeded: (startResponse: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-start-social-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.StartSocialRequestAction(taskId, request, onSucceeded, onError), 'Please wait for a moment'),
    );
  };

  authenticate = (authRequest: IZenAuthAuthenticateRequest): void => {
    const { temporaryAccessToken } = authRequest;
    const taskId = `zenauth-start-with-token-${temporaryAccessToken}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZentAuthAuthenticateWithToken(taskId, authRequest), 'Please wait for a moment'),
    );
  };

  logout = (onSucceed?: () => void, afterSucceed?: () => void): void => {
    const taskId = `zenauth-logout-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthLogoutRequestAction(taskId, onSucceed, afterSucceed), 'Please wait for a moment'),
    );
  };

  loginMobimoney = (onSucceed: (start: IZenAuthOnboardingNextStepResponse) => void): void => {
    const taskId = `zenauth-login-mobimoney-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthLoginMobimoneyRequestAction(taskId, onSucceed), 'Please wait for a moment'),
    );
  };

  loginNedId = (onSucceed: (start: IZenAuthOnboardingNextStepResponse) => void): void => {
    const taskId = `zenauth-login-nedid-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthLoginNedidRequestAction(taskId, onSucceed), 'Please wait for a moment'),
    );
  };

  systemLogout = (): void => {
    const taskId = `zenauth-system-logout-${uuidv4()}`;
    this.store.dispatch(started(new actions.ZenAuthLogoutResponseAction(taskId), 'Please wait for a moment', true));
  };

  fetchOnboardingStart = (
    start: IZenAuthOnboardingStartRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenatu-onboarding-start-with-request-${JSON.stringify(start)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingStartRequestAction(taskId, start, onSucceeded, onError),
        'We are setting up your data.. Please wait for a moment',
      ),
    );
  };

  fetchOnboardingStartInfo = (
    start: IZenAuthOnboardingStartInfoRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `zenatu-onboarding-start-with-request-${JSON.stringify(start)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingStartInfoRequestAction(taskId, start, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  fetchOnboardingStartMoneyApp = (
    start: IZenAuthOnboardingStartMoneyAppRequest,
    onSucceed?: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError?: () => void,
    label?: string,
  ): void => {
    const taskId = `zenatu-onboarding-start-with-request-${JSON.stringify(start)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingStartMoneyAppRequestAction(taskId, start, onSucceed, onError),
        label || 'We are veryfying your Money App. Please wait for a moment',
      ),
    );
  };

  fetchOnboardingStartMoneyAppAsync = (
    start: IZenAuthOnboardingStartMoneyAppRequest,
    onSucceed: (start: IZenAuthOnboardingAvoClientInfo) => void,
    onError?: () => void,
  ): void => {
    const taskId = `zenauth-onboarding-start-moneyapp-async-${JSON.stringify(start)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingStartMoneyAppAsyncRequestAction(taskId, start, onSucceed, onError),
        '',
        true,
      ),
    );
  };

  fetchBankClientInfo = (
    onSucceed?: (data: IZenAuthOnboardingBankClientInfo) => void,
    onError?: () => void,
    silently = true,
  ): void => {
    const taskId = `zenauth-onboarding-fetch-bankclientinfo-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingBankInfoRequestAction(taskId, onSucceed, onError), '', silently),
    );
  };

  fetchAvoClientInfo = (
    onSucceed?: (data: IZenAuthOnboardingAvoClientInfo) => void,
    onError?: () => void,
    silently = true,
  ): void => {
    const taskId = `zenauth-onboarding-fetch-avoclientinfo-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingAvoInfoRequestAction(taskId, onSucceed, onError), '', silently),
    );
  };

  editPersonalDetailsEmail = (
    request: IZenAuthPersonalInformationEditEmailRequest,
    onSucceed?: (data: IZenAuthPersonalInformationResponse) => void,
    onError?: () => void,
    silently = true,
  ): void => {
    const taskId = `zenauth-onboarding-edit-personal-details-email-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingEditPersonalDetailsEmailRequestAction(taskId, request, onSucceed, onError),
        '',
        silently,
      ),
    );
  };

  fetchOnboardingStartSocial = (
    start: IZenAuthOnboardingStartSocialRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    silently?: boolean,
  ): void => {
    const taskId = `zenatu-onboarding-start-social-${JSON.stringify(start)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingStartSocialRequestAction(taskId, start, onSucceeded),
        'Please wait for a moment',
        silently,
      ),
    );
  };

  fetchOnboardingStartManual = (
    start: IZenAuthOnboardingStartManualRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    silently?: boolean,
  ): void => {
    const taskId = `zenatu-onboarding-start-manual-${JSON.stringify(start)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingStartManualRequestAction(taskId, start, onSucceeded),
        'Please wait for a moment',
        silently,
      ),
    );
  };

  sendOnboardingOtp = (
    otp: IZenAuthOnboardingOtpRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-otp-with-request-${JSON.stringify(otp)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingOtpRequestAction(taskId, otp, onSucceeded, onError),
        'We are verifying your OTP, it will take a while',
      ),
    );
  };

  sendLinkedAccount = (
    request: IZenAuthOnboardingLinkAccountRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-link-account-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingLinkAccountRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  startLinkAccount = (
    request: IZenAuthOnboardingStartLinkAccountRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-start-link-account-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingStartLinkAccountRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  initiateLinkAccount = (
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-initiate-link-account-request-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingInitiateLinkAccountRequestAction(taskId, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendOutstandingTcs = (
    payload: IZenAuthOnboardingOutstandingTCsRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-outstanding-tcs-${JSON.stringify(payload)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingOutstandingTcsRequestAction(taskId, payload, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendTcs = (
    payload: IZenAuthOnboardingTCsRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-tcs-${JSON.stringify(payload)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingTcsRequestAction(taskId, payload, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendOnboardingExternalAuth = (
    auth: IZenAuthOnboardingExternalAuthRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
  ): void => {
    const taskId = `zenauth-onboarding-auth-with-request-${JSON.stringify(auth)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingExternalAuthRequestAction(taskId, auth, onSucceeded),
        'Please wait for a moment',
      ),
    );
  };

  sendOnboardingAddress = (
    address: IZenAuthOnboardingAddressRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-address-with-request-${JSON.stringify(address)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingAddressRequestAction(taskId, address, onSucceeded, onError),
        'We are processing your address. Please wait for a moment',
      ),
    );
  };

  sendSelectOtp = (
    request: IZenAuthOnboardingSelectOtpRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-select-otp-with-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSelectOtpRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendSelectSocial = (
    request: IZenAuthOnboardingSelectSocialRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-select-social-with-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSelectSocialRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendSelectNedbank = (
    request: IZenAuthOnboardingSelectNedbankRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-select-nedbank-with-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSelectNedbankRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  signup = (
    request: IZenAuthOnboardingSignupRequest,
    onSucceeded?: (nextStep: IZenAuthSignupInfoResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-signup-with-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSignupRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  signupManual = (
    request: IZenAuthOnboardingSignupManualRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-signup-manual-with-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSignupManualRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  signupSocial = (
    request: IZenAuthOnboardingSignupRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-signup-social-with-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSignupSocialRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  signupNedbank = (
    request: IZenAuthOnboardingSignupRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-signup-nedbank-with-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSignupNedbankRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendOnboardingMobiMoney = (
    onboardingId: IZenAuthOnboardingMobiMoneyRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
  ): void => {
    const taskId = `zenauth-onboarding-mobimoney-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingMobiMoneyRequestAction(taskId, onboardingId, onSucceeded),
        'Please wait for a moment',
      ),
    );
  };

  sendOnboardingSecurityQuestions = (
    questions: IZenAuthOnboardingSecurityQuestionsRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
  ): void => {
    const taskId = `zenauth-onboarding-security-questuion-with-request-${JSON.stringify(questions)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSecurityQuestionsRequestAction(taskId, questions, onSucceeded),
        'Please wait for a moment',
      ),
    );
  };

  sendOnboardingFirstNameLastNameAuth = (
    firstNameLastNameAuth: IZenAuthOnboardingFirstNameLastNameAuthRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-firstname-lastname-auth-with-request-${JSON.stringify(
      firstNameLastNameAuth,
    )}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingFirstNameLastNameAuthRequestAction(
          taskId,
          firstNameLastNameAuth,
          onSucceeded,
          onError,
        ),
        'We are setting up your data.. Please wait for a moment',
      ),
    );
  };

  sendOnboardingBusinessTradingName = (
    businessTradingName: IZenAuthOnboardingBusinessTradingNameRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-trading-name-with-request-${JSON.stringify(businessTradingName)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingBusinessTradingNameRequestAction(
          taskId,
          businessTradingName,
          onSucceeded,
          onError,
        ),
        'We are setting up your data.. Please wait for a moment',
      ),
    );
  };

  sendOnboardingPersonalData = (
    personalData: IZenAuthOnboardingPersonalDataRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-personal-data-with-request-${JSON.stringify(personalData)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingPersonalDataRequestAction(taskId, personalData, onSucceeded, onError),
        'We are setting up your data.. Please wait for a moment',
      ),
    );
  };

  createLogin = (
    login: IZenAuthOnboardingLoginRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-create-login-with-request-${JSON.stringify(login)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSetLoginRequestAction(taskId, login, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  login = (
    request: IZenAuthLoginRequest,
    onSucceeded?: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-login-with-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingLoginRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  createLoginSocial = (
    login: IZenAuthOnboardingLoginRequest,
    onSucceeded: (start: IZenAuthOnboardingNextStepResponse) => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-create-login-social-with-request-${JSON.stringify(login)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingLoginSocialRequestAction(taskId, login, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendOnboardingPostCheck = (
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError: () => void,
    text: string,
  ): void => {
    const taskId = `zenauth-onboarding-post-check-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingPostCheckRequestAction(taskId, onSucceeded, onError), text),
    );
  };

  sendOnboardingBusinessAddress = (
    businessAddress: IZenAuthBusinessAddressRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-business-address-with-request-${JSON.stringify(businessAddress)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingBusinessAddressRequestAction(taskId, businessAddress, onSucceeded, onError),
        'We are setting up your data.. Please wait for a moment',
      ),
    );
  };

  sendOnboardingBusinessDetails = (
    businessDetails: IZenAuthOnboardingBusinessDetailsRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-business-details-with-request-${JSON.stringify(businessDetails)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingBusinessDetailsRequestAction(taskId, businessDetails, onSucceeded, onError),
        'We are setting up your data.. Please wait for a moment',
      ),
    );
  };

  sendOnboardingBusinessPartner = (
    businessParner: IZenAuthOnboardingBusinessPartnerRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
  ): void => {
    const taskId = `zenauth-onboarding-business-partner-with-request-${JSON.stringify(businessParner)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingBusinessPartnersRequestAction(taskId, businessParner, onSucceeded),
        'Please wait for a moment',
      ),
    );
  };

  sendOnboardingBusinessCategoryInfo = (
    businessCategoryInfo: IZenAuthOnboardingBusinessCategoryInfoRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-business-category-info-with-request-${JSON.stringify(
      businessCategoryInfo,
    )}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingBusinessCategoryRequestAction(taskId, businessCategoryInfo, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendOnboardingSuccess = (
    successData: IZenAuthOnboardingSuccessRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-success-with-request-${JSON.stringify(successData)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSuccessRequestAction(taskId, successData, onSucceeded, onError),
        'Everything went well. We are setting up your application',
      ),
    );
  };

  fetchSuggestedUsername = (onboardingId: string): void => {
    const taskId = `zenauth-onboarding-success-with-id-${onboardingId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSuggestUsernameRequestAction(taskId, onboardingId),
        'Fetching suggested user name',
      ),
    );
  };

  fetchSuggestedAddress = (query: string): void => {
    const taskId = `zenauth-onboarding-suggested-address-with-query-${query}-${uuidv4()}`;
    this.store.dispatch(started(new actions.ZenAuthOnboardingSuggestAddressRequestAction(taskId, query), '', true));
  };

  sendOnboardingApproveIt = (
    onboardingId: IZenAuthOnboardingApproveItRequest,
    onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-approveit-request-${JSON.stringify(onboardingId)}-${uuidv4()}`;
    this.store.dispatch(
      new actions.ZenAuthOnboardingApproveItRequestAction(taskId, onboardingId, onSucceeded, onError),
    );
  };

  sendOnboardingSentApproveIt = (
    onboardingId: IZenAuthOnboardingSentApproveItRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
  ): void => {
    const taskId = `zenauth-onboarding-sent-approveit-request-${JSON.stringify(onboardingId)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingSentApproveItRequestAction(taskId, onboardingId, onSucceeded),
        'Please wait for a moment',
      ),
    );
  };

  sendOnboardingProgress = (
    onboardingId: IZenAuthOnboardingProgressRequest,
    onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-progress-request-${JSON.stringify(onboardingId)}-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthOnboardingProgressRequestAction(taskId, onboardingId, onSucceeded, onError));
  };

  sendOnboardingBankOtp = (
    request: IZenAuthBankOtpRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-bank-otp-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingBankOtpRequestAction(taskId, request, onSucceeded, onError),
        'We are verifying your OTP, it will take a while',
      ),
    );
  };

  sendOnboardingIdPhoto = (
    request: IZenAuthOnboardingIdPhotoRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
  ): void => {
    const taskId = `zenauth-onboarding-id-photo-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingIdPhotoRequestAction(taskId, request, onSucceeded),
        'Please wait for a moment',
      ),
    );
  };

  sendBasicData = (
    request: IZenAuthBasicDataRequest,
    onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-basic-data-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingBasicDataRequestAction(taskId, request, onSucceeded, onError)),
    );
  };

  sendBusinessBasicData = (
    request: IZenAuthBusinessBasicDataRequest,
    onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-business-basic-data-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingBusinessBasicDataRequestAction(taskId, request, onSucceeded, onError)),
    );
  };

  sendIdPassport = (
    request: IZenAuthIdPassportNumberRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-id-passport-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingIdPassportRequestAction(taskId, request, onSucceeded, onError)),
    );
  };

  sendLegalEntity = (
    request: IZenAuthLegalEntityRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-legal-entity-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingLegalEntityRequestAction(taskId, request, onSucceeded, onError)),
    );
  };

  sendBusinessLegalEntity = (
    request: IZenAuthBusinessLegalEntityRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-onboarding-business-legal-entity-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingBusinessLegalEntityRequestAction(taskId, request, onSucceeded, onError)),
    );
  };

  fetchCountries = (onSucceeded?: (countries: IZenAuthOnboardingCountry[]) => void): void => {
    const taskId = `zenauth-onboarding-fetch-countries-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingCountriesRequestAction(taskId, onSucceeded), 'Please wait for a moment'),
    );
  };

  sendCheckBank = (
    payload: IZenAuthCheckBankRequest,
    merchantId?: string,
    onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: () => void,
  ): void => {
    const taskId = `zenauth-onboarding-check-bank-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingCheckBankRequestAction(taskId, payload, merchantId, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendStepup = (
    request: IZenAuthOnboardingStepupRequest,
    onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: () => void,
  ): void => {
    const taskId = `zenauth-onboarding-stepup-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingStepupRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendHealthStepup = (
    request: IZenAuthOnboardingStepupRequest,
    onSucceeded?: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError?: () => void,
  ): void => {
    const taskId = `zenauth-onboarding-health-stepup-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingHealthStepupRequestAction(taskId, request, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendCheckLogin = (onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void, onError: () => void): void => {
    const taskId = `zenauth-onboarding-check-login-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingCheckLoginRequestAction(taskId, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  sendAddMerchant = (
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
    onError: () => void,
  ): void => {
    const taskId = `zenauth-onboarding-add-merchant-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingAddMerchantRequestAction(taskId, onSucceeded, onError),
        'Please wait for a moment',
      ),
    );
  };

  finishOnboarding = (): void => {
    const taskId = `zenauth-onboarding-finished-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthOnboardingFinishedAction(taskId));
  };

  transactionSigningStart = (
    transactionAuthId: string,
    onSucceed?: (data?: IZenAuthTransactionSigningAuthorizationHeaders) => void,
  ): void => {
    const taskId = `zenauth-onboarding-signing-start-with-id-${transactionAuthId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthTransactionSigningStartRequestAction(taskId, transactionAuthId, onSucceed),
        'Please wait for a moment',
      ),
    );
  };

  transactionSigningAuthorize = (authRequest: IZenAuthTransactionSigningAuthorizeRequest, silently = true): void => {
    const { transactionAuthId } = authRequest;
    this.store.dispatch(
      started(
        new actions.ZenAuthTransactionSigningAuthorizeRequestAction(
          this.signingAuthorizeTaskId(transactionAuthId),
          authRequest,
        ),
        'Please wait for a moment',
        silently,
      ),
    );
  };

  transactionSigningCancel = (transactionAuthId: string, onSucceed: () => void): void => {
    const taskId = `zenauth-onboarding-signing-cancel-with-id-${transactionAuthId}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.ZenAuthTransactionSigningCancelRequestAction(taskId, transactionAuthId, onSucceed)),
    );
  };

  transactionSigningFakeTx = (onSucceed: () => void): void => {
    const taskId = `zenauth-onboarding-signing-fake-payment-${uuidv4()}`;
    this.store.dispatch(started(new actions.ZenAuthTransactionSigningFakeRequestAction(taskId, onSucceed)));
  };

  saveTransactionSigningAuthenticators = (authenticators: IAuthenticators): void => {
    const taskId = `zenauth-transaction-signing-save-authenticators-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthTransactionSigningSaveAuthenticatorsAction(taskId, authenticators));
  };

  loginReset = (): void => {
    const taskId = `zenauth-login-reset-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthLoginResetAction(taskId));
  };

  onboardingReset = (): void => {
    const taskId = `zenauth-onboarding-reset-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthOnboardingResetAction(taskId));
  };

  getReferralDetails = (code: string): void => {
    const taskId = `zenauth-onboarding-get-referral-details-${code}`;
    this.store.dispatch(
      started(new actions.ZenAuthOnboardingGetReferralDetailsRequestAction(taskId, code), 'Fetching referral code'),
    );
  };

  bankphoneDifferent = (
    request: IZenAuthOnboardingBankphoneDifferentRequest,
    onSucceeded: (nextStep: IZenAuthOnboardingNextStepResponse) => void,
  ): void => {
    const taskId = `zenauth-onboarding-bankphone-different-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.ZenAuthOnboardingBankphoneDifferentRequestAction(taskId, request, onSucceeded),
        'Please wait for a moment',
      ),
    );
  };

  guestLogin = (
    request: IZenAuthSocialByRequest,
    onSucceeded: (customerToken: IZenGuestLoginResponse) => void,
    onError?: (error: any) => void,
  ): void => {
    const taskId = `zenauth-guest-login-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(started(new actions.ZenAuthGuestRequestAction(taskId, request, onSucceeded, onError)));
  };

  sendInfoStepupRequest = (
    request: IZenAuthInfoStepUpRequest,
    onSucceeded: (customerToken: IZenAuthOnboardingNextStepResponse) => void,
    onError: () => void,
  ): void => {
    const taskId = `zenauth-info-stepup-request-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthOnboardingInfoStepUpRequestAction(taskId, request, onSucceeded, onError));
  };

  setToken = (token: IZenAuthAccessToken): void => {
    const taskId = `zenauth-start-with-token-${token}-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthorizationTokenSetAction(taskId, token));
  };

  removeToken = (): void => {
    const taskId = `zenauth-remove-token-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthorizationTokenRemoveAction(taskId));
  };

  setReferralCode = (referralCode: string): void => {
    const taskId = `zenauth-set-referral-code-${referralCode}-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthOnboardingSetReferralCodeAction(taskId, referralCode));
  };

  setGuestInfo = (guestInfo: IZenAuthSocialByRequest): void => {
    const taskId = `zenauth-set-guest-info-${JSON.stringify(guestInfo)}-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthGuestInfoSetAction(taskId, guestInfo));
  };

  //v3
  fetchPasswordlessStart = (
    payload: IAuthPasswordlessLoginStartRequest,
    onSucceeded: (startResponse: IAuthNextStep) => void,
  ): void => {
    const taskId = `zenauth-start-passwordless-request-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.StartPasswordlessRequestAction(taskId, payload, onSucceeded), 'Please wait for a moment'),
    );
  };

  loginOtp = (
    payload: IAuthOTPRequest,
    onSucceeded: (startResponse: IAuthNextStep) => void,
    onError: (error: any) => void,
  ): void => {
    const taskId = `zenauth-login-otp-request-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.LoginOtpRequestAction(taskId, payload, onSucceeded, onError), 'Please wait for a moment'),
    );
  };

  loginOtpResend = (payload: IAuthOTPResendRequest, onSucceeded?: (startResponse: IAuthNextStep) => void): void => {
    const taskId = `zenauth-login-otp-resend-request-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.LoginOtpResendRequestAction(taskId, payload, onSucceeded), 'Please wait for a moment'),
    );
  };

  loginProfile = (payload: IAuthProfileRequest, onSucceeded: (startResponse: IAuthNextStep) => void): void => {
    const taskId = `zenauth-login-profile-request-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.LoginProfileRequestAction(taskId, payload, onSucceeded), 'Please wait for a moment'),
    );
  };

  loginGoogle = (payload: IAuthSocialLoginStartRequest, onSucceeded: (startResponse: IAuthNextStep) => void): void => {
    const taskId = `zenauth-login-google-request-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.LoginGoogleRequestAction(taskId, payload, onSucceeded), 'Please wait for a moment'),
    );
  };

  loginFacebook = (
    payload: IAuthSocialLoginStartRequest,
    onSucceeded: (startResponse: IAuthNextStep) => void,
  ): void => {
    const taskId = `zenauth-login-facebook-request-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.LoginFacebookRequestAction(taskId, payload, onSucceeded), 'Please wait for a moment'),
    );
  };

  loginApple = (payload: IAuthSocialLoginStartRequest, onSucceeded: (startResponse: IAuthNextStep) => void): void => {
    const taskId = `zenauth-login-apple-request-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.LoginAppleRequestAction(taskId, payload, onSucceeded), 'Please wait for a moment'),
    );
  };

  refreshToken = (payload: IAuthRefreshTokenStartRequest): void => {
    const taskId = `zenauth-login-refresh-token-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.LoginRefreshTokenRequestAction(taskId, payload), 'Please wait for a moment'),
    );
  };

  init = (): void => {
    const taskId = `zenauth-init-${uuidv4()}`;
    this.store.dispatch(new actions.ZenAuthorizationInitAction(taskId));
  };

  clearNextStep = (): void => {
    const taskId = `zenauth-login-clear-next-step-${uuidv4()}`;
    this.store.dispatch(new actions.LoginClearNextStep(taskId));
  };
}
