import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ICommonImage } from '@wakanda/wakanda-core';
import { Assets } from '@avo/shared/ui';

@Component({
  selector: 'ui-common-variant-image',
  styles: [
    `
      :host {
        display: block;
        overflow: hidden;
      }

      :host.disabled {
        opacity: 0.25;
        pointer-events: none;
      }

      :host.round {
        border-radius: 0.615rem;
      }

      :host > img {
        display: block;
      }

      :host div.background {
        position: relative;
      }

      :host div.background ui-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `,
  ],
  template: `
    <img
      *ngIf="!imageLoadFailed && (!!itemData?.variants?.length || forceDisplay); else noImageTemplate"
      [alt]="
        itemData?.altText || itemData?.recommended?.url || (itemData?.variants && itemData?.variants[0])?.url || ''
      "
      [style.width]="
        width || (itemData?.recommended?.width || (itemData?.variants && itemData?.variants[0])?.width) + 'px'
      "
      [style.height]="
        height || (itemData?.recommended?.height || (itemData?.variants && itemData?.variants[0])?.height) + 'px'
      "
      [style.object-fit]="fit || 'auto'"
      [style.borderTopLeftRadius]="cornersRadius || topCornersRadius || 'unset'"
      [style.borderTopRightRadius]="cornersRadius || topCornersRadius || 'unset'"
      [style.borderBottomLeftRadius]="cornersRadius || 'unset'"
      [style.borderBottomRightRadius]="cornersRadius || 'unset'"
      [src]="disableResizing ? src : (src | resizeImageUrl)"
      [viewportDetector]="lazyLoad"
      [viewportDetectorOptions]="viewportDetectorOptions"
      [viewportDetectorIntersectionRatio]="0"
      [viewportDetectorOnce]="true"
      (inViewportChange)="onInViewport($event)"
      [width]="width || (itemData?.recommended?.width || (itemData?.variants && itemData?.variants[0])?.width) + 'px'"
      [height]="
        height || (itemData?.recommended?.height || (itemData?.variants && itemData?.variants[0])?.height) + 'px'
      "
      (click)="onClick.emit()"
      (error)="imageLoadFailed = true"
    />
    <ng-template #noImageTemplate>
      <div
        *ngIf="defaultBackground"
        class="background"
        [style.width]="
          width || (itemData?.recommended?.width || (itemData?.variants && itemData?.variants[0])?.width) + 'px'
        "
        [style.height]="
          height || (itemData?.recommended?.height || (itemData?.variants && itemData?.variants[0])?.height) + 'px'
        "
        [style.borderTopLeftRadius]="cornersRadius || topCornersRadius || 'unset'"
        [style.borderTopRightRadius]="cornersRadius || topCornersRadius || 'unset'"
        [style.borderBottomLeftRadius]="cornersRadius || 'unset'"
        [style.borderBottomRightRadius]="cornersRadius || 'unset'"
        (click)="onClick.emit()"
      >
        <ui-icon [src]="Assets.icon.system.noImage.dark"></ui-icon>
      </div>
    </ng-template>
  `,
})
export class CommonVariantImageComponent {
  @Input() fit: string;
  @Input() width: string;
  @Input() height: string;
  @Input() lazyLoad: boolean;
  @Input() forceDisplay: boolean;
  @Input() viewportDetectorOptions: IntersectionObserverInit;
  @Input() topCornersRadius: string;
  @Input() cornersRadius: string;
  @Input() disableResizing: boolean;
  @Input() defaultBackground: boolean;
  @Input() @HostBinding('class.round') roundCorners: boolean;
  @Input() @HostBinding('class.disabled') disabled: boolean;
  @Output() onClick = new EventEmitter();
  itemData: ICommonImage;
  src: string;
  imageLoadFailed = false;

  readonly Assets = Assets;

  @Input() set item(value: ICommonImage) {
    this.itemData = value;
    if (!this.lazyLoad) this.setSrc();
  }

  onInViewport = (value: boolean): void => {
    if (value) this.setSrc();
  };

  private setSrc = (): void => {
    this.src = this.itemData?.recommended?.url || (this.itemData?.variants && this.itemData?.variants[0])?.url;
  };
}
