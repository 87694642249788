import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'landingInputLabel' })
export class LandingInputLabelPipe implements PipeTransform {
  transform = (isLoginByTelNum: boolean): string => {
    if (isLoginByTelNum == undefined || isLoginByTelNum == null) {
      return 'customer.login.entry_field_value';
    }
    return isLoginByTelNum ? 'customer.login.cell_phone' : 'customer.login.email';
  };
}
