import { Injector } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import forEach from 'lodash-es/forEach';
import get from 'lodash-es/get';
import keys from 'lodash-es/keys';
import size from 'lodash-es/size';

export type IInvalidControls = { [key: string]: AbstractControl };

export interface IForm {
  name: string;
  initialValue?: any;
  updateOn?: 'change' | 'blur' | 'submit';
  validators?: ValidatorFn | ValidatorFn[];
}

export class CommonForm {
  form: UntypedFormGroup;
  formBuilder: UntypedFormBuilder;
  constructor(injector: Injector) {
    this.formBuilder = injector.get(UntypedFormBuilder);
  }

  createForm = (form: IForm[]): void => {
    if (!form) return;

    this.form = new UntypedFormGroup({});

    forEach(form, i => {
      let validatorOrOpts = {};
      forEach(keys(i), key => {
        if (key === 'name' || key === 'initialValue') return;
        validatorOrOpts = { ...validatorOrOpts, [key]: i[key] };
      });
      this.form.addControl(i.name, new UntypedFormControl(get(i, 'initialValue', null), validatorOrOpts));
    });
  };

  createSimpleForm = (form: string[]): void => {
    if (!size(form)) return;

    this.form = new UntypedFormGroup({});

    forEach(form, i => this.form.addControl(i, new UntypedFormControl(null, {})));
  };

  getForm = <T>(): T => this.form.value;

  /**
   * e.g. setForm({ user: 'Ivan', password: 'password123' });
   */
  setForm = <T>(form: T): void => {
    this.form.setValue(form);
  };

  setFormValue = (name: string, value: any, group?: AbstractControl): void => {
    const control = (group ?? this.form)?.get(name);
    if (!control) return;
    if (!control.touched && value !== null) control.markAsTouched();
    control.patchValue(value);
  };

  getFormValue = (name: string): string | boolean | undefined | any => this.form?.get(name)?.value;

  isTouched = (name: string): boolean => this.form?.get(name)?.touched;

  getFormValueStatus = (name: string): string => this.form?.get(name)?.status;

  isFormValueValid = (name: string): boolean | undefined => this.form?.get(name)?.valid;

  isFormValid = (): boolean => this.form?.valid;

  getErrors = (name: string, group?: AbstractControl): ValidationErrors => {
    group = group ?? this.form;
    return group?.get(name)?.touched && group.get(name).errors;
  };

  clearForm = (): void => {
    this.form.reset();
  };

  atLeastOne =
    (validator: ValidatorFn) =>
    (group: UntypedFormGroup): ValidationErrors | null => {
      const hasAtLeastOne =
        group && group.controls && Object.keys(group?.controls)?.some(k => !validator(group.controls[k]));

      return hasAtLeastOne ? null : { atLeastOne: true };
    };

  getFormControl = (name: string): UntypedFormControl => this.form?.get(name) as UntypedFormControl;
}
