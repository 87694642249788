import { Component, Injector } from '@angular/core';
import { HeaderMenuVisibility, LayoutBaseContainer } from '../layout.base.container';
import { FadeInOutLongAnimation } from '@wakanda/wakanda-ui';

@Component({
  selector: 'wakanda-avo-business-header',
  animations: [FadeInOutLongAnimation],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends LayoutBaseContainer {
  isMenuOpen = HeaderMenuVisibility.HIDDEN;

  constructor(injector: Injector) {
    super(injector);
    this.subscriptions.add(this.responsiveService.getActiveScreenType().subscribe(type => (this.screenType = type)));
  }

  onHamburgerMenu = (): void => {
    if (this.isMenuOpen === HeaderMenuVisibility.OPEN) this.isMenuOpen = HeaderMenuVisibility.HIDDEN;
    else this.isMenuOpen = HeaderMenuVisibility.OPEN;
  };

  onClickOutsideMenu = (targetElement: HTMLElement): void => {
    if (
      this.isMenuOpen === HeaderMenuVisibility.HIDDEN ||
      document.getElementById('header-sidemenu-slide-content').contains(targetElement)
    ) {
      return;
    } else {
      this.onHamburgerMenu();
    }
  };

  openPdfUrl = (): void => {
    const pdfUrl = this.getTranslatedKey('merchant.pre_onboarding.top.tcs_link');
    window.open(pdfUrl, '_blank');
  };
}
