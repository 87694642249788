import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { UpgradeWalletService } from '../../services/upgrade-wallet/upgrade-wallet.service';
import { ApplicationFacade } from '../application/application.facade';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';
import * as actions from './upgrade-wallet.actions';
import { EVENT } from './upgrade-wallet.events';

@Injectable({
  providedIn: 'root',
})
export class UpgradeWalletEffects {
  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: UpgradeWalletService) {}

  upgradeWalletInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.UpgradeWalletInitRequestAction>(EVENT.UPGRADE_WALLET_INIT_REQUESTED),
      switchMap(({ taskId, onSucceed, onError }) =>
        this.service.upgradeWalletInit().pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.UpgradeWalletInitReceivedAction(taskId, response));
          }),
          catchError(error => {
            onError();
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  upgradeWalletPersonalData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.UpgradeWalletPersonalDataRequestAction>(EVENT.UPGRADE_WALLET_PERSONAL_DATA_REQUESTED),
      switchMap(({ taskId, request, flowId, onSucceed }) =>
        this.service.upgradeWalletPersonalData(request, flowId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.UpgradeWalletPersonalDataReceivedAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  upgradeWalletAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.UpgradeWalletAddressRequestAction>(EVENT.UPGRADE_WALLET_ADDRESS_REQUESTED),
      switchMap(({ taskId, request, flowId, onSucceed }) =>
        this.service.upgradeWalletAddress(request, flowId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.UpgradeWalletAddressReceivedAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  upgradeWalletSecurityQuestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.UpgradeWalletSecurityQuestionsRequestAction>(EVENT.UPGRADE_WALLET_SECURITY_QUESTIONS_REQUESTED),
      switchMap(({ taskId, request, flowId, onSucceed }) =>
        this.service.upgradeWalletSecurityQuestions(request, flowId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.UpgradeWalletSecurityQuestionsReceivedAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  upgradeWalletIdPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.UpgradeWalletIdPhotoRequestAction>(EVENT.UPGRADE_WALLET_ID_PHOTO_REQUESTED),
      switchMap(({ taskId, request, flowId, onSucceed }) =>
        this.service.upgradeWalletIdPhoto(request, flowId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.UpgradeWalletIdPhotoReceivedAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
}
