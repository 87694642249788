import { AssetsBucketsEnum } from '../../models/assets.model';

const assetsV3 = (baseUrl: string): string => `${baseUrl}/assets`;

export const postAsset = (baseUrl: string, bucket: AssetsBucketsEnum): string => {
  const extra = bucket === AssetsBucketsEnum.CHAT ? '/agent' : '';
  return `${assetsV3(baseUrl)}${extra}/bucket/${bucket}`;
};
export const getAssetStatus = (baseUrl: string, dmzFilesId: string, bucket: AssetsBucketsEnum): string => {
  const extra = bucket === AssetsBucketsEnum.CHAT ? '/agent' : '';
  return `${assetsV3(baseUrl)}${extra}/status/${dmzFilesId}`;
};
