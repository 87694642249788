import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[handleBlur]',
})
export class HandleBlurDirective {
  constructor(private element: ElementRef) {}

  @Output() handleBlur = new EventEmitter<HTMLElement>();

  @HostListener('document:click', ['$event.target']) onMouseEnter = (targetElement: HTMLElement): void => {
    const { nativeElement } = this.element;
    const clickedInside = nativeElement.contains(targetElement);
    if (!clickedInside) this.handleBlur.emit(null);
  };
}
