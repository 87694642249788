import { Action } from '@ngrx/store';
import { EVENT } from './app-config.events';
import { AppConfigActions } from './app-config.actions';

export const APP_CONFIG_FEATURE_KEY = 'appConfig';

export interface IAppConfigState {
  config?: any;
}

export function appConfigReducer(state: IAppConfigState = {}, actions: Action): IAppConfigState {
  const action = actions as AppConfigActions;
  switch (action.type) {
    case EVENT.APP_CONFIG_RECEIVED: {
      const config = action.data;
      return Object.assign({}, state, { config });
    }
    default:
      return state;
  }
}
