import { Injectable } from '@angular/core';
import * as actions from './email-verification.actions';
import { Store } from '@ngrx/store';
import { started } from '../application/task.actions';

@Injectable()
export class EmailVerificationFacade {
  constructor(private store: Store) {}

  verifyEmail = (token: string, onError: () => void): void => {
    const taskId = `verify-email-by-token-${token}`;
    this.store.dispatch(
      started(
        new actions.EmailVerificationEmailVerifyReequestAction(taskId, token, onError),
        'We are trying verify you email. Please wait a moment.',
      ),
    );
  };
}
