import { Action } from '@ngrx/store';
import {
  IChatHeyNedEnabledCheckResponse,
  IChatHeyNedRoom,
  IChatHeyNedSubscriptionCheckResponse,
  IChatRoom,
} from '../model/chat.model';
import { IMatrixMessage, MessageEventType } from '@wakanda/agent-chat-model';
import * as actions from './chat.actions';
import * as EVENT from './chat.events';
import { IChatHistoryRoom } from '@wakanda/wakanda-core';

export const CHAT_FEATURE_KEY = 'chat_avo2';

export interface IChatReducerState {
  activeRoom?: IChatRoom;
  activeRoomMessages?: IMatrixMessage[];
  lastSentEvent?: string;
  heyNedRoom?: IChatHeyNedRoom;
  heyNedSubscriptionCheck?: IChatHeyNedSubscriptionCheckResponse;
  heyNedEnabled?: IChatHeyNedEnabledCheckResponse;
  heyNedOriginRoomId?: string;
  chatHistoryOngoing?: IChatHistoryRoom[];
  chatHistoryCompleted?: IChatHistoryRoom[];
  chatHistorySearchResult?: IChatHistoryRoom[];
}

export interface IChatPartialState {
  readonly [CHAT_FEATURE_KEY]: IChatReducerState;
}

const initialState: IChatReducerState = {
  activeRoom: null,
  activeRoomMessages: null,
  lastSentEvent: null,
  heyNedRoom: null,
  heyNedSubscriptionCheck: null,
  chatHistoryOngoing: null,
  chatHistoryCompleted: null,
  chatHistorySearchResult: null,
};

function pairMessagesWithWidgets(messages: IMatrixMessage[]): any[] {
  return messages.map(message => {
    if (message.content.msgtype === MessageEventType.BUTTONS) {
      const pair = messages.find(
        item =>
          item.content.msgtype === MessageEventType.BUTTON_PRESSED &&
          item.content.actionId === (message.content as any).actionId,
      );
      return { ...message, content: { ...message.content, pairedMessage: pair?.content } };
    }

    if (message.content.msgtype === MessageEventType.LOCATION) {
      const pair = messages.find(
        item =>
          item.content.msgtype === MessageEventType.LOCATION_SET &&
          item.content.actionId === (message.content as any).actionId,
      );
      return { ...message, content: { ...message.content, pairedMessage: pair?.content } };
    }

    if (message.content.msgtype === MessageEventType.UPLOAD) {
      const pair = messages.find(
        item =>
          item.content.msgtype === MessageEventType.UPLOAD_DOCUMENTS &&
          item.content.actionId === (message.content as any).actionId,
      );
      return { ...message, content: { ...message.content, pairedMessage: pair?.content } };
    }

    return message;
  });
}

const ACTION_HANDLERS = {
  [EVENT.CHAT_GET_ROOM_RECEIVED]: (
    state: IChatReducerState,
    action: actions.GetChatRoomResponseAction,
  ): IChatReducerState => Object.assign({}, state, { activeRoom: action.data }),

  [EVENT.CHAT_GET_MESSAGES_RECEIVED]: (
    state: IChatReducerState,
    action: actions.FetchMessagesByRoomIdReceived,
  ): IChatReducerState => {
    const messages = action.data.chunk as any[] as IMatrixMessage[];
    return Object.assign({}, state, { activeRoomMessages: pairMessagesWithWidgets(messages) });
  },

  [EVENT.CHAT_SEND_EVENT_RECEIVED]: (
    state: IChatReducerState,
    action: actions.ChatSendEventResponseAction,
  ): IChatReducerState => Object.assign({}, state, { lastSentEvent: action.data }),

  [EVENT.CHAT_CREATE_HEYNED_ROOM_RECEIVED]: (
    state: IChatReducerState,
    action: actions.ChatCreateHeyNedRoomResponseAction,
  ): IChatReducerState => Object.assign({}, state, { heyNedRoom: action.data }),

  [EVENT.CHAT_CREATE_HEYNED_HEALTH_ROOM_RECEIVED]: (
    state: IChatReducerState,
    action: actions.ChatCreateHeyNedHealthRoomResponseAction,
  ): IChatReducerState => Object.assign({}, state, { heyNedRoom: action.data }),

  [EVENT.CHAT_HEYNED_OPENED_TASK_RECEIVED]: (
    state: IChatReducerState,
    action: actions.ChatCreateHeyNedRoomResponseAction,
  ): IChatReducerState => Object.assign({}, state, { heyNedRoom: action.data }),

  [EVENT.CHECK_HEYNED_SUBSCRIPTION_RECEIVED]: (
    state: IChatReducerState,
    action: actions.ChatCheckHeyNedSubscriptionResponseAction,
  ): IChatReducerState => Object.assign({}, state, { heyNedSubscriptionCheck: action.data }),

  [EVENT.CHECK_HEYNED_ENABLED_RECEIVED]: (
    state: IChatReducerState,
    action: actions.ChatCheckHeyNedEnabledResponseAction,
  ): IChatReducerState => Object.assign({}, state, { heyNedEnabled: action.data }),

  [EVENT.CHAT_HISTORY_ONGOING_RECEIVED]: (
    state: IChatReducerState,
    action: actions.ChatHistoryOngoingResponseAction,
  ): IChatReducerState => Object.assign({}, state, { chatHistoryOngoing: action.data }),

  [EVENT.CHAT_HISTORY_COMPLETED_RECEIVED]: (
    state: IChatReducerState,
    action: actions.ChatHistoryCompletedResponseAction,
  ): IChatReducerState => Object.assign({}, state, { chatHistoryCompleted: action.data }),

  [EVENT.CHAT_HISTORY_SEARCH_RECEIVED]: (
    state: IChatReducerState,
    action: actions.ChatHistorySearchResponseAction,
  ): IChatReducerState => Object.assign({}, state, { chatHistorySearchResult: action.data }),

  [EVENT.CHAT_FAILED]: (state: IChatReducerState): IChatReducerState => Object.assign({}, state, {}),

  [EVENT.CHAT_CLEAR_DATA]: (state: IChatReducerState): IChatReducerState => Object.assign({}, state, initialState),

  [EVENT.CHAT_SAVE_HEYNED_ORIGIN_ROOM_ID]: (
    state: IChatReducerState,
    action: actions.ChatSaveHeyNedOriginRoomIdAction,
  ): IChatReducerState => Object.assign({}, state, { heyNedOriginRoomId: action.data }),

  [EVENT.CHAT_CLEAR_HEYNED_ORIGIN_ROOM_ID]: (state: IChatReducerState): IChatReducerState =>
    Object.assign({}, state, { heyNedOriginRoomId: null }),
};

export function chatReducer(state: IChatReducerState = initialState, action: Action): IChatReducerState {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
