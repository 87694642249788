<div (click)="handlePanel()" class="row between faq-panel" [class.small]="isSmall">
  <p class="text-align-left" [class.active]="isOpen">{{ title }}</p>
  <ui-icon
    icon="{{ isOpen ? (!isSmall ? 'up' : 'plus3') : !isSmall ? 'down' : 'plus3' }}"
    size="{{ isSmall ? '1rem' : '1.2rem' }}"
  ></ui-icon>
</div>
<div *ngIf="isOpen" class="panel-content">
  <ng-content></ng-content>
</div>
<ui-divider *ngIf="!isOpen && !last" [dotted]="true" [compact]="true"></ui-divider>
