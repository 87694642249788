import { inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private readonly document = inject(DOCUMENT);

  setCanonical(url: string): void {
    const existingElement = this.document.querySelector('link[rel=canonical]');
    if (existingElement) {
      existingElement.setAttribute('href', url);
      return;
    }

    const element = this.document.createElement('link');
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
    this.document.head.append(element);
  }

  removeCanonical(): void {
    const existingElement = this.document.querySelector('link[rel=canonical]');
    if (existingElement) existingElement.remove();
  }
}
