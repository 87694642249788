import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import { ILocationV3, INewLocationV3, IPosition } from '../../models/location.model';
import { ApiService } from '../api/api.service';
import * as endpoint from './location.endpoints';
import { IBuyerLocation, IBuyerLocationRequest } from '../../models/buyer.model';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private apiService: ApiService, private appConfig: AppConfig) {}

  private apiV3Url = (): string => this.appConfig.backendConfig.apiV3Url;

  public fetchLocations(): Observable<ILocationV3[]> {
    return this.apiService.get<ILocationV3[]>(endpoint.fetchLocations(this.apiV3Url()));
  }

  public fetchMerchantLocations(buyerId: string): Observable<IBuyerLocation[]> {
    return this.apiService.get<IBuyerLocation[]>(endpoint.buyerLocations(this.apiV3Url(), buyerId));
  }

  public deleteLocation(locationId: string): Observable<void> {
    return this.apiService.remove<void>(endpoint.deleteLocation(locationId, this.apiV3Url()));
  }

  public addMerchantLocation(request: IBuyerLocationRequest, buyerId: string): Observable<IBuyerLocation> {
    return this.apiService.post<IBuyerLocationRequest>(endpoint.buyerLocations(this.apiV3Url(), buyerId), request);
  }

  public editLocation(locationId: string, request: INewLocationV3): Observable<ILocationV3> {
    return this.apiService.put<INewLocationV3>(endpoint.editLocation(locationId, this.apiV3Url()), request);
  }

  public setCurrentLocation(locationId: string): Observable<ILocationV3> {
    return this.apiService.post<{ id: string }>(endpoint.setCurrentLocation(this.apiV3Url()), { id: locationId });
  }

  public fetchCurrentLocation(): Observable<ILocationV3> {
    return this.apiService.get<ILocationV3>(endpoint.fetchCurrentLocation(this.apiV3Url()));
  }

  public fetchNearestLocation(position: IPosition): Observable<any> {
    return this.apiService.get<any>(endpoint.fetchNearestLocation(this.apiV3Url(), position));
  }
}
