import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICommonOnChangeOutput } from '@wakanda/wakanda-core';
import memoizeOne from 'memoize-one';

@Component({
  selector: 'ui-slider-simple',
  template: `
    <ui-divider [compact]="true"></ui-divider>
    <div>
      <input
        class="slider-simple"
        type="range"
        autocomplete="off"
        [min]="min"
        [max]="max"
        [step]="step"
        [value]="value || 0"
        [name]="name"
        (input)="handleChange($event.target)"
      />
    </div>
    <ui-divider [compact]="true"></ui-divider>
    <div *ngIf="!!minMaxLabels && minMaxLabels?.length > 0" class="row between">
      <p class="small light">{{ minMaxLabels[0] }}</p>
      <p class="small light">{{ minMaxLabels[1] }}</p>
    </div>
  `,
})
export class SliderSimpleComponent {
  @Input() disabled = false;
  @Input() min = 0;
  @Input() max = 100;
  @Input() value: number;
  @Input() step = 1;
  @Input() name: string;
  @Input() minMaxLabels: string[];
  @Output() onChange = new EventEmitter<ICommonOnChangeOutput>();

  private makeChange = memoizeOne((name: string, value: number): void => this.onChange.emit({ name, value }));

  handleChange = (data: any): void => {
    this.makeChange(data.name, data.value);
  };
}
