import { createFeatureSelector, createSelector } from '@ngrx/store';
import filter from 'lodash-es/filter';
import dayjs from 'dayjs';
import { ACTIVITY_HISTORY_FEATURE_KEY, IActivityHistoryState } from './activity-history.reducer';
import { ActivityHistoryType } from '../../models/activity-history.model';

const getState = createFeatureSelector<IActivityHistoryState>(ACTIVITY_HISTORY_FEATURE_KEY);

export const getOngoingOrders = createSelector(getState, state => state.ongoingActivityHistory);
export const getOngoingOrdersHasLoadedAll = createSelector(getState, state => state.onGoingHasLoadedAll);
export const getOngoingOrdersLastLoadedPage = createSelector(getState, state => state.onGoingLastLoadedPage);
export const getCompletedOrders = createSelector(getState, state => state.completedActivityHistory);
export const getCompletedOrdersHasLoadedAll = createSelector(getState, state => state.onCompletedHasLoadedAll);
export const getCompletedOrdersLastLoadedPage = createSelector(getState, state => state.onCompletedLastLoadedPage);

export const getVoucherOrders = createSelector(getState, state =>
  filter(state.completedActivityHistory, item => item.type === ActivityHistoryType.VOUCHER).sort((a, b) =>
    dayjs(a.orderDate).isBefore(dayjs(b.orderDate)) ? 1 : -1,
  ),
);

export const getAOrderDetail = createSelector(getState, state => state.orderDetail);
export const getSearchResults = createSelector(getState, state => state.search);
export const getCancelReasons = createSelector(getState, state => state.cancelReasons);
export const getRma = createSelector(getState, state => state.rma);
export const getRmaList = createSelector(getState, state => state.rmaList);
export const getRmaCancelReasons = createSelector(getState, state => state.rmaCancelReasons);
export const getRmaDetail = createSelector(getState, state => state.rmaDetail);
export const getOrderMerchantDetail = createSelector(getState, state => state.orderMerchantDetail); // todo
