import { createFeatureSelector, createSelector } from '@ngrx/store';
import get from 'lodash-es/get';
import { ISearchReducerState, SEARCH_FEATURE_KEY } from './search.reducer';

const getState = createFeatureSelector<ISearchReducerState>(SEARCH_FEATURE_KEY);

export const getInitData = createSelector(getState, (state: ISearchReducerState) => get(state, 'init'));

export const getResultsData = createSelector(getState, (state: ISearchReducerState) => get(state, 'results'));

export const getSuggestionsData = createSelector(getState, (state: ISearchReducerState) => get(state, 'suggestions'));

export const getServicePopular = createSelector(getState, (state: ISearchReducerState) => get(state, 'servicePopular'));

export const getQuery = createSelector(getState, (state: ISearchReducerState) => get(state, 'query'));

export const getSearchCategoryId = createSelector(getState, (state: ISearchReducerState) =>
  get(state, 'searchCategoryId'),
);

export const getSearchProductData = createSelector(getState, (state: ISearchReducerState) =>
  get(state, 'searchProducts'),
);

export const getB2BStoreSearchData = createSelector(getState, (state: ISearchReducerState) =>
  get(state, 'b2bStoresSearch'),
);

export const getSearchProductNamedData = createSelector(getState, (state: ISearchReducerState) =>
  get(state, 'searchNamedProducts'),
);
