export const retreive = (key: string, defaultResult = {}, useSessionStorage = false): any => {
  try {
    const serialized = useSessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key);
    if (serialized === null) return defaultResult;
    return JSON.parse(serialized);
  } catch (err) {
    console.error(err);
    return defaultResult;
  }
};

export const save = (key: string, data = {}, useSessionStorage = false): void => {
  try {
    const serialized = JSON.stringify(data);
    useSessionStorage ? sessionStorage.setItem(key, serialized) : localStorage.setItem(key, serialized);
  } catch (err) {
    console.error(err);
  }
};

export const remove = (key: string, useSessionStorage = false): void => {
  useSessionStorage ? sessionStorage.removeItem(key) : localStorage.removeItem(key);
};

export const clear = (useSessionStorage = false): void => {
  useSessionStorage ? sessionStorage.clear() : localStorage.clear();
};
