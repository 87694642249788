import { Injectable } from '@angular/core';
import { retreive, remove, save, clear } from '../../utils/browserStorage';

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {
  get = (key: string, useSessionStorage = false, defaultResult = null): any =>
    retreive(key, defaultResult, useSessionStorage);
  set = (key: string, value: any, useSessionStorage = false): void => save(key, value, useSessionStorage);
  remove = (key: string, useSessionStorage = false): void => remove(key, useSessionStorage);
  clearAll = (useSessionStorage = false): void => clear(useSessionStorage);
}
