import { createAction, props } from '@ngrx/store';
import { ITask } from '../models/store.models';
import { AssetsBucketsEnum, IAssetStatusResponse } from './assets.models';

// upload asset

export const uploadAssetRequest = createAction(
  '[ASSETS] upload asset request',
  props<{
    data: FormData;
    bucket: AssetsBucketsEnum;
    onSuccess: (response: IAssetStatusResponse) => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const uploadAssetResponse = createAction('[ASSETS] upload asset response', props<{ taskMetadata: ITask }>());

// upload asset with progress

export const uploadAssetProgressRequest = createAction(
  '[ASSETS] upload asset progress request',
  props<{
    data: FormData;
    bucket: AssetsBucketsEnum;
    onSuccess: (response: IAssetStatusResponse) => void;
    onProgress: (percentages: number) => void;
    onError?: () => void;
    taskMetadata: ITask;
  }>(),
);

export const uploadAssetProgressResponse = createAction(
  '[ASSETS] upload asset progress response',
  props<{ taskMetadata: ITask }>(),
);

// status

export const statusRequest = createAction(
  '[ASSETS] status request',
  props<{
    id: string;
    onSuccess: (response: IAssetStatusResponse) => void;
    onError: () => void;
    taskMetadata: ITask;
  }>(),
);

export const statusResponse = createAction('[ASSETS] status response', props<{ taskMetadata: ITask }>());
