import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AddressType, IAddressLocation, ISetLocationMapChange } from '@wakanda/wakanda-core';
import get from 'lodash-es/get';
import size from 'lodash-es/size';
import { GoogleMapBaseContainer } from '../google-map-base-container/google-map.base.container';

@Component({
  selector: 'ui-select-location',
  template: `
    <div class="ui-select-location">
      <ui-select
        bindLabel="name"
        bindValue="id"
        [placeholder]="'customer.settings.locations.set.search_hint' | translate"
        [showAsMapSearch]="showAsMapSearch"
        [isOpen]="true"
        [autofocus]="true"
        [value]="query"
        (onSelect)="onSelect($event)"
        (onChange)="onSearch($event.value)"
        (onClear)="onClear()"
      ></ui-select>

      <div class="padded-content text-align-left" *ngIf="!query; else searchTemplate">
        <ng-container *ngFor="let address of customerAddresses | slice : 0 : limit; let last = last">
          <div class="row address-item" (click)="onSelect(address)">
            <ui-icon [icon]="address.icon || 'location_marker'" size="1rem"></ui-icon>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <div class="column">
              <b>{{ address.name || ('settings.location.no_address_name' | translate) }}</b>
              <p class="small light">{{ address.address?.addressString }}</p>
              <p *ngIf="!address.valid" class="error small" zen-translate>customer.settings.location.invalid</p>
            </div>
          </div>
          <ui-divider *ngIf="limit || !last" [solid]="true" [compact]="true"></ui-divider>
        </ng-container>
        <div class="row address-item" *ngIf="customerAddresses?.length > limit && limit" (click)="limit = undefined">
          <ui-icon [icon]="'down'" size="1rem"></ui-icon>
          <ui-divider [vertical]="true" [compact]="true"></ui-divider>
          <div class="column">
            <b zen-translate>customer.settings.location.set.show</b>
          </div>
        </div>
      </div>
      <ng-template #searchTemplate>
        <div class="padded-content text-align-left">
          <ng-container *ngFor="let address of searched; let last = last">
            <div class="row address-item" (click)="onSelect(address)">
              <ui-icon [icon]="address.icon || 'location_marker'" size="1rem"></ui-icon>
              <ui-divider [vertical]="true" [compact]="true"></ui-divider>
              <div class="column">
                <b>{{ address.name }}</b>
                <p class="small light">{{ address.address?.addressString }}</p>
              </div>
            </div>
            <ui-divider *ngIf="!last" [solid]="true" [compact]="true"></ui-divider>
          </ng-container>
        </div>
      </ng-template>
      <ng-container *ngIf="showManageLocations">
        <div [style.height]="'6rem'">
          <ui-divider [compact]="true"></ui-divider>
        </div>
        <div class="padded-content text-align-left bottom-button">
          <ui-divider [compact]="true"></ui-divider>
          <div class="row" (click)="onManageLocations.emit()">
            <ui-icon [icon]="'location_settings'" size="1rem"></ui-icon>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <p class="light" zen-translate>customer.settings.location.manage</p>
          </div>
        </div>
      </ng-container>
    </div>
  `,
})
export class SelectLocationComponent extends GoogleMapBaseContainer implements OnInit {
  selecetedItem: IAddressLocation;
  query: string;
  isSearching: boolean;
  limit = 5;
  //
  @Input() isAuthorized: boolean;
  @Input() isMerchant: boolean;
  @Input() showManageLocations = true;
  @Input() showAsMapSearch = true;
  @Input() clearAfterSelect = false;
  @Output() onSubmitButton = new EventEmitter<IAddressLocation>();
  @Output() onManageLocations = new EventEmitter<void>();
  @Output() onQueryChange = new EventEmitter<void>();
  @Output() onChange = new EventEmitter<ISetLocationMapChange>();
  @Output() onUpdateAddress = new EventEmitter<string>();

  constructor(injector: Injector) {
    super(injector);
  }

  handleSubmit = (): void => {
    this.onSubmitButton.emit(this.selecetedItem);
  };

  onSearch = (value): void => {
    this.query = value;

    if (!value || !size(value) || value === '') return;

    this.onQueryChange.emit();

    if (!this.isSearching) {
      this.isSearching = true;
      this.locationFacade.fetchSearchLocationByQuery(value, () => {
        this.isSearching = false;
      });
    }
  };

  onSelect = (locationData: IAddressLocation): void => {
    this.selecetedItem = locationData;
    if (!this.selecetedItem) return;
    if (this.selecetedItem.type === AddressType.CustomerLocation) {
      if (!this.selecetedItem.valid) {
        this.onUpdateAddress.emit(this.selecetedItem.id);
        return;
      }
      this.locationFacade.findPlaceByPosition(this.selecetedItem.location, locationForm => {
        this.onChange.emit({
          locationForm: {
            ...locationForm,
            name: locationForm.name || this.selecetedItem.name || '',
            contactPerson: locationForm.contactPerson || '',
          },
          id: this.selecetedItem.id,
          type: this.selecetedItem.type,
        });
        this.handleSubmit();
        if (this.clearAfterSelect) this.query = null;
      });
      return;
    }

    if (this.selecetedItem.type === AddressType.Search && !!get(this.selecetedItem, ['address', 'addressString'])) {
      this.locationFacade.findPlaceByAddressString(
        get(this.selecetedItem, 'address.addressString'),
        (locationForm, json) => {
          this.onChange.emit({
            locationForm: {
              ...locationForm,
              name: locationForm.name || this.selecetedItem.name || '',
              contactPerson: locationForm.contactPerson || '',
            },
            id: this.selecetedItem.id,
            type: this.selecetedItem.type,
            geocodingData: json?.address_components,
          });
          this.handleSubmit();
          if (this.clearAfterSelect) this.query = null;
        },
      );
    }
  };

  onClear = (): void => {
    this.query = null;
    this.onChange.emit();
  };

  ngOnInit(): void {
    if (this.isAuthorized && !this.isMerchant) {
      this.locationFacade.fetchLocations(true);
      if (!this.currentLocation) {
        this.locationFacade.fetchCurrentLocation();
      }
    }
  }
}
