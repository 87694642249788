export const WALLET_FAILED = 'MY-MONEY/FAILED';

export const WALLETS_REQUEST = 'MY-MONEY/GET-WALLETS/REQUESTED';
export const WALLETS_RECEIVED = 'MY-MONEY/GET-WALLETS/RECEIVED';

export const WALLET_SELECTED_MAIN = 'MY-MONEY/WALLET/SELECTED/MAIN';

export const TRANSACTIONS_REQUEST = 'MY-MONEY/TRANSACTION/REQUESTED';
export const TRANSACTIONS_RECEIVED = 'MY-MONEY/TRANSACTION/RECEIVED';

export const TRANSACTION_DETAIL_REQUEST = 'MY-MONEY/TRANSACTION-DETAIL/REQUESTED';
export const TRANSACTION_DETAIL_RECEIVED = 'MY-MONEY/TRANSACTION-DETAIL/RECEIVED';

export const TRANSACTION_SEARCH_REQUEST = 'MY-MONEY/TRANSACTION-SEARCH/REQUESTED';
export const TRANSACTION_SEARCH_RECEIVED = 'MY-MONEY/TRANSACTION-SEARCH/RECEIVED';

export const TRANSACTION_RECENT_REQUEST = 'MY-MONEY/TRANSACTION-RECENT/REQUESTED';
export const TRANSACTION_RECENT_RECEIVED = 'MY-MONEY/TRANSACTION-RECENT/RECEIVED';

export const TRANSACTION_FILTER_REQUEST = 'MY-MONEY/TRANSACTION-FILTER/REQUESTED';
export const TRANSACTION_FILTER_RECEIVED = 'MY-MONEY/TRANSACTION-FILTER/RECEIVED';

export const TRANSACTION_DOWNLOAD_STATEMENT_REQUEST = 'MY-MONEY/DOWNLOAD-STATEMENT/REQUESTED';
export const TRANSACTION_DOWNLOAD_STATEMENT_RECEIVED = 'MY-MONEY/DOWNLOAD-STATEMENT/RECEIVED';

export const PAY_REQUEST = 'MY-MONEY/PAY/REQUESTED';
export const PAY_SUCCEEDED = 'MY-MONEY/PAY/SUCCEEDED';

export const PAY_RECENT_RECIPIENTS_REQUEST = 'MY-MONEY/PAY-RECENT-RECIPIENTS/REQUESTED';
export const PAY_RECENT_RECIPIENTS_RECEIVED = 'MY-MONEY/PAY-RECENT-RECIPIENTS/RECEIVED';

export const PAY_BY_QR_CODE_REQUEST = 'MY-MONEY/PAY-QR-CODE/REQUESTED';
export const PAY_BY_QR_CODE_SUCCEEDED = 'MY-MONEY/PAY-QR-CODE/SUCCEEDED';

export const REFUND_REQUEST = 'MY-MONEY/REFUND/REQUESTED';
export const REFUND_SUCCEEDED = 'MY-MONEY/REFUND/SUCCEEDED';

export const PARTY_ACCOUNTS_REQUEST = 'MY-MONEY/PARTY-ACCOUNTS/REQUESTED';
export const PARTY_ACCOUNTS_SUCCEEDED = 'MY-MONEY/PARTY-ACCOUNTS/SUCCEEDED';

export const WITHDRAW_REQUEST = 'MY-MONEY/WITHDRAW/REQUESTED';
export const WITHDRAW_SUCCEEDED = 'MY-MONEY/WITHDRAW/SUCCEEDED';

export const ACCEPT_SWITCH_PAGE = 'MY-MONEY/ACCEPT/SWITCH_PAGE';

export const DECODE_QR_CODE_REQUEST = 'MY-MONEY/DECODE_QR_CODE/REQUESTED';
export const DECODE_QR_CODE_RECEIVED = 'MY-MONEY/DECODE_QR_CODE/RECEIVED';

export const CREATE_PAYMENT_LINK_REQUEST = 'MY-MONEY/CREATE-PAYMENT-LINK/REQUESTED';
export const CREATE_PAYMENT_LINK_RECEIVED = 'MY-MONEY/CREATE-PAYMENT-LINK/RECEIVED';

export const CREATE_PAYMENT_REQUEST = 'MY-MONEY/CREATE-PAYMENT-REQUEST/REQUESTED';
export const CREATE_PAYMENT_RECEIVED = 'MY-MONEY/CREATE-PAYMENT-REQUEST/RECEIVED';

export const ADD_CARD_REQUEST = 'MY-MONEY/CARD/ADD/REQUESTED';
export const ADD_CARD_RECEIVED = 'MY-MONEY/CARD/ADD/RECEIVED';

export const EDIT_CARD_REQUEST = 'MY-MONEY/CARD/EDIT/REQUESTED';
export const EDIT_CARD_RECEIVED = 'MY-MONEY/CARD/EDIT/RECEIVED';

export const DELETE_CARD_REQUEST = 'MY-MONEY/CARD/DELETE/REQUESTED';
export const DELETE_CARD_RECEIVED = 'MY-MONEY/CARD/DELETE/RECEIVED';

export const WALLET_TOPUP_PAY_REQUEST = 'MY-MONEY/TOPUP/PAY/REQUESTED';
export const WALLET_TOPUP_PAY_RECEIVED = 'MY-MONEY/TOPUP/PAY/RECEIVED';

export const CLEAR_QR_CODE_PAYMENT_DATA = 'MY-MONEY/CLEAR_QR_CODE/PAYMENT_DATA';
export const COPY_LINK = 'MY-MONEY/COPY-LINK/SUCCEED';

export const WALLET_EXTERNAL_ACCOUNTS_REQUESTED = 'MY-MONEY/EXTERNAL-ACCOUNTS/REQUESTED';
export const WALLET_EXTERNAL_ACCOUNTS_RECEIVED = 'MY-MONEY/EXTERNAL-ACCOUNTS/RECEIVED';

export const WALLET_CARD_WITH_DESTINATION_REQUESTED = 'MY-MONEY/CARD-TOPUP/REQUESTED';
export const WALLET_CARD_WITH_DESTINATION_RECEIVED = 'MY-MONEY/CARD-TOPUP/RECEIVED';

export const WALLET_BANKS_REQUESTED = 'MY-MONEY/BANKS/REQUESTED';
export const WALLET_BANKS_RECEIVED = 'MY-MONEY/BANKS/RECEIVED';

export const WALLET_NEDBANK_BANKS_REQUESTED = 'MY-MONEY/NEDBANK-BANKS/REQUESTED';
export const WALLET_NEDBANK_BANKS_RECEIVED = 'MY-MONEY/NEDBANK-BANKS/RECEIVED';

export const WALLET_REMOVE_EXTERNAL_ACCOUNTS_REQUESTED = 'MY-MONEY/EXTERNAL-ACCOUNTS/REMOVE/REQUESTED';
export const WALLET_REMOVE_EXTERNAL_ACCOUNTS_RECEIVED = 'MY-MONEY/EXTERNAL-ACCOUNTS/REMOVE/RECEIVED';
export const WALLET_SAVE_EXTERNAL_ACCOUNT = 'MY-MONEY/EXTERNAL-ACCOUNTS/SAVE';

export const WALLET_PAYMENT_LINK_REQUESTED = 'MY-MONEY/PAYMENT-LINK/REQUESTED';
export const WALLET_PAYMENT_LINK_RECEIVED = 'MY-MONEY/PAYMENT-LINK/RECEIVED';

export const WALLET_PAYMENT_ACCEPT_LINK_REQUESTED = 'MY-MONEY/PAYMENT-LINK/ACCEPT/REQUESTED';
export const WALLET_PAYMENT_ACCEPT_LINK_RECEIVED = 'MY-MONEY/PAYMENT-LINK/ACCEPT/RECEIVED';

export const REMINDER_REQUESTED = 'MY-MONEY/REMINDER/REQUESTED';
export const REMINDER_RECEIVED = 'MY-MONEY/REMINDER/RECEIVED';

export const LOYALTY_POINTS_REQUESTED = 'MY-MONEY/LOYALTY-POINTS/REQUESTED';
export const LOYALTY_POINTS_RECEIVED = 'MY-MONEY/LOYALTY-POINTS/RECEIVED';

export const TOPUP_PAYCHECK_REQUESTED = 'MY-MONEY/TOPUP/PAYCHECK/REQUESTED';
export const TOPUP_PAYCHECK_RECEIVED = 'MY-MONEY/TOPUP/PAYCHECK/RECEIVED';

export const TOPUP_ACCOUNT_REQUESTED = 'MY-MONEY/TOPUP/ACCOUNT/REQUESTED';
export const TOPUP_ACCOUNT_RECEIVED = 'MY-MONEY/TOPUP/ACCOUNT/RECEIVED';

export const TOPUP_ACCOUNT_STATUS_REQUESTED = 'MY-MONEY/TOPUP-ACCOUNT/STATUS/REQUESTED';
export const TOPUP_ACCOUNT_STATUS_RECEIVED = 'MY-MONEY/TOPUP-ACCOUNT/STATUS/RECEIVED';

export const TOPUP_CARD_STATUS_REQUESTED = 'MY-MONEY/TOPUP-CARD/STATUS/REQUESTED';
export const TOPUP_CARD_STATUS_RECEIVED = 'MY-MONEY/TOPUP-CARD/STATUS/RECEIVED';

export const MERCHANT_ACTIVE_BUYER_SET = 'MY-MONEY/ACTIVE-BUYER/SET';

export const SUBSCRIPTIONS_MERCHANT_LIST_REQUESTED = 'MY-MONEY/SUBSCRIPTIONS-MERCHANT-LIST/REQUESTED';
export const SUBSCRIPTIONS_MERCHANT_LIST_RECEIVED = 'MY-MONEY/SUBSCRIPTIONS-MERCHANT-LIST/RECEIVED';

export const SUBSCRIPTIONS_AVAILABLE_MERCHANT_LIST_REQUESTED =
  'MY-MONEY/SUBSCRIPTIONS-AVAILABLE-MERCHANT-LIST/REQUESTED';
export const SUBSCRIPTIONS_AVAILABLE_MERCHANT_LIST_RECEIVED = 'MY-MONEY/SUBSCRIPTIONS-AVAILABLE-MERCHANT-LIST/RECEIVED';

export const SUBSCRIPTIONS_MERCHANT_ADD_REQUESTED = 'MY-MONEY/SUBSCRIPTIONS/MERCHANT/ADD/REQUESTED';
export const SUBSCRIPTIONS_MERCHANT_ADD_RECEIVED = 'MY-MONEY/SUBSCRIPTIONS/MERCHANT/ADD/RECEIVED';

export const DASHBOARD_WIDGET_REQUESTED = 'MY-MONEY/DASHBOARD/WIDGET/REQUESTED';
export const DASHBOARD_WIDGET_RECEIVED = 'MY-MONEY/DASHBOARD/WIDGET/RECEIVED';

export const FILTER_SETTLEMENT_TRANSACTION_REQUESTED = 'MY-MONEY/FILTER_SETTLEMENT_TRANSACTION/REQUESTED';
export const FILTER_SETTLEMENT_TRANSACTION_RECEIVED = 'MY-MONEY/FILTER_SETTLEMENT_TRANSACTION/RECEIVED';

export const FILTER_SETTLEMENTS_EXPORT_REQUESTED = 'MY-MONEY/FILTER_SETTLEMENTS_EXPORT/REQUESTED';
export const FILTER_SETTLEMENTS_EXPORT_RECEIVED = 'MY-MONEY/FILTER_SETTLEMENTS_EXPORT/RECEIVED';

export const FILTER_TRANSACTIONS_EXPORT_REQUESTED = 'MY-MONEY/FILTER_TRANSACTIONS_EXPORT/REQUESTED';
export const FILTER_TRANSACTIONS_EXPORT_RECEIVED = 'MY-MONEY/FILTER_TRANSACTIONS_EXPORT/RECEIVED';

export const FETCH_TASK_FILE_REQUESTED = 'MY-MONEY/FETCH_TASK_FILE/REQUESTED';
export const FETCH_TASK_FILE_RECEIVED = 'MY-MONEY/FETCH_TASK_FILE/RECEIVED';

export const FETCH_TASK_DETAIL_REQUESTED = 'MY-MONEY/FETCH_TASK_DETAIL/REQUESTED';
export const FETCH_TASK_DETAIL_RECEIVED = 'MY-MONEY/FETCH_TASK_DETAIL/RECEIVED';

export const FETCH_SETTLEMENT_ACCOUNT_REQUESTED = 'MY-MONEY/FETCH_SETTLEMENT_ACCOUNT/REQUESTED';
export const FETCH_SETTLEMENT_ACCOUNT_RECEIVED = 'MY-MONEY/FETCH_SETTLEMENT_ACCOUNT/RECEIVED';

export const ADD_SETTLEMENT_ACCOUNT_REQUESTED = 'MY-MONEY/ADD_SETTLEMENT_ACCOUNT/REQUESTED';
export const ADD_SETTLEMENT_ACCOUNT_RECEIVED = 'MY-MONEY/ADD_SETTLEMENT_ACCOUNT/RECEIVED';

export const UPDATE_SETTLEMENT_ACCOUNT_TYPE_REQUESTED = 'MY-MONEY/UPDATE_SETTLEMENT_ACCOUNT_TYPE/REQUESTED';
export const UPDATE_SETTLEMENT_ACCOUNT_TYPE_RECEIVED = 'MY-MONEY/UPDATE_SETTLEMENT_ACCOUNT_TYPE/RECEIVED';

export const PAY_INFO_CLEAR = 'MY-MONEY/PAY-INFO/CLEAR';

export const PAY_BY_CARD_REQUESTED = 'MY-MONEY/PAY-BY-CARD/REQUESTED';
export const PAY_BY_CARD_RECEIVED = 'MY-MONEY/PAY-BY-CARD/RECEIVED';

export const PAY_BY_POINTS_ONLY_REQUESTED = 'MY-MONEY/PAY-BY-POINTS-ONLY/REQUESTED';
export const PAY_BY_POINTS_ONLY_RECEIVED = 'MY-MONEY/PAY-BY-POINTS-ONLY/RECEIVED';

export const PAY_BY_WALLET_REQUESTED = 'MY-MONEY/PAY-BY-WALLET/REQUESTED';
export const PAY_BY_WALLET_RECEIVED = 'MY-MONEY/PAY-BY-WALLET/RECEIVED';

export const PAY_FOR_FREE_REQUESTED = 'MY-MONEY/PAY-FOR-FREE/REQUESTED';
export const PAY_FOR_FREE_RECEIVED = 'MY-MONEY/PAY-FOR-FREE/RECEIVED';

export const TOPUP_AND_PAY_REQUESTED = 'MY-MONEY/TOPUP-AND-PAY/REQUESTED';
export const TOPUP_AND_PAY_RECEIVED = 'MY-MONEY/TOPUP-AND-PAY/RECEIVED';

export const PAY_BY_EFT_REQUESTED = 'MY-MONEY/PAY-BY-EFT/REQUESTED';
export const PAY_BY_EFT_RECEIVED = 'MY-MONEY/PAY-BY-EFT/RECEIVED';

export const PAY_BY_AMEX_REQUESTED = 'MY-MONEY/PAY-BY-AMEX/REQUESTED';
export const PAY_BY_AMEX_RECEIVED = 'MY-MONEY/PAY-BY-AMEX/RECEIVED';

export const PAY_BY_CREDIT_REQUESTED = 'MY-MONEY/PAY-BY-CREDIT/REQUESTED';
export const PAY_BY_CREDIT_RECEIVED = 'MY-MONEY/PAY-BY-CREDIT/RECEIVED';

export const SET_PAYMENT_METHOD = 'MY-MONEY/SET-PAYMENT-METHOD';

export const FETCH_TAX_INVOICES_REQUESTED = 'MY-MONEY/FETCH_TAX_INVOICES/REQUESTED';
export const FETCH_TAX_INVOICES_RECEIVED = 'MY-MONEY/FETCH_TAX_INVOICES/RECEIVED';

export const GENERATE_PAYMENT_REMITTANCE_REQUESTED = 'MY-MONEY/GENERATE_PAYMENT_REMITTANCE/REQUESTED';
export const GENERATE_PAYMENT_REMITTANCE_RECEIVED = 'MY-MONEY/GENERATE_PAYMENT_REMITTANCE/RECEIVED';

export const DOWNLOAD_TAX_INVOICE_REQUESTED = 'MY-MONEY/DOWNLOAD_TAX_INVOICE/REQUESTED';
export const DOWNLOAD_TAX_INVOICE_RECEIVED = 'MY-MONEY/DOWNLOAD_TAX_INVOICE/RECEIVED';

export const SETTLEMENT_FETCH_SETTLEMENT_SETTINGS_REQUESTED = 'SETTLEMENT/FETCH_SETTLEMENT_SETTINGS/REQUESTED';
export const SETTLEMENT_FETCH_SETTLEMENT_SETTINGS_RECEIVED = 'SETTLEMENT/FETCH_SETTLEMENT_SETTINGS/RECEIVED';

export const SETTLEMENT_CHANGE_SETTLEMENT_SETTINGS_REQUESTED = 'SETTLEMENT/CHANGE_SETTLEMENT_SETTINGS/REQUESTED';
export const SETTLEMENT_CHANGE_SETTLEMENT_SETTINGS_RECEIVED = 'SETTLEMENT/CHANGE_SETTLEMENT_SETTINGS/RECEIVED';

export const STATEMENTS_FETCH_SELLER_STATEMENT_REQUEST = 'STATEMENTS/FETCH-SELLER-STATEMENT/REQUEST';
export const STATEMENTS_FETCH_SELLER_STATEMENT_RECEIVED = 'STATEMENTS/FETCH-SELLER-STATEMENT/RECEIVED';

export const STATEMENTS_DOWNLOAD_SELLER_STATEMENT_REQUEST = 'STATEMENTS/DOWNLOAD-SELLER-STATEMENT/REQUEST';
export const STATEMENTS_DOWNLOAD_SELLER_STATEMENT_RECEIVED = 'STATEMENTS/DOWNLOAD-SELLER-STATEMENT/RECEIVED';

export const STATEMENTS_GENERATE_SELLER_STATEMENT_REQUEST = 'STATEMENTS/GENERATE-SELLER-STATEMENT/REQUEST';
export const STATEMENTS_GENERATE_SELLER_STATEMENT_RECEIVED = 'STATEMENTS/GENERATE-SELLER-STATEMENT/RECEIVED';
