import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import range from 'lodash-es/range';

interface IRatingOutput {
  id?: string;
  rating: number;
  name?: string;
}

@Component({
  selector: 'ui-rating',
  templateUrl: './rating.component.html',
})
export class RatingComponent implements OnInit {
  input: string;
  stars = [];

  @Input() excellent: number;
  @Input() veryGood: number;
  @Input() good: number;
  @Input() average: number;
  @Input() poor: number;
  @Input() starCount: number;
  @Input() allRatingCount: number;
  @Input() isPercentage: boolean;

  @Input() linear = false;

  @Input() size: string;

  @Input() readOnly: boolean;

  @Input() rating: number;

  @Input() id: string;

  @Input() name: string;

  @Input() alignLeft: boolean;

  @Input() alignRight: boolean;

  @Input() alignCenter: boolean;

  @Output() onRatingClick: EventEmitter<IRatingOutput> = new EventEmitter<IRatingOutput>();

  sizes = {
    mini: '.65rem',
    tiny: '.8rem',
    small: '.9rem',
    default: '1rem',
    large: '1.2rem',
    big: '1.5rem',
    huge: '2rem',
    massive: '3rem',
  };

  onClick = (rating: number): void => {
    if (this.readOnly) return;

    if (rating === this.rating) this.rating = rating - 1;
    else this.rating = rating;

    this.onRatingClick.emit({ id: this.id, name: this.name || 'rating', rating: this.rating });
  };

  ngOnInit(): void {
    if (!this.starCount) {
      this.starCount = 5;
    }
    this.stars = range(this.starCount);
    this.input = `${this.id}-rating`;
  }
}
