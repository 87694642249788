import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { GoogleMapsPredictionService, IAddressForm, IPosition } from '@wakanda/wakanda-core';
import get from 'lodash-es/get';
import { take } from 'rxjs';

@Component({
  selector: 'ui-inline-location',
  styles: [
    `
      p,
      p.small,
      p.light,
      p.small.light,
      b,
      b.small,
      b.light,
      b.small.light,
      a,
      a.small {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
  template: `
    <ng-container *ngIf="!bold && !link">
      <p
        [style.width]="width"
        class="ellipsis-font"
        [ngClass]="{
          light: !!light,
          small: !!small
        }"
        (click)="onClick.emit()"
      >
        <ng-container *ngIf="!!place">
          {{ place?.city || place?.locality || place?.addressString }}
        </ng-container>

        <ng-container *ngIf="!!addressString && showAddressString">
          {{ addressString }}
        </ng-container>
      </p>
    </ng-container>
    <ng-container *ngIf="!!bold && !link">
      <b
        [style.width]="width"
        class="ellipsis-font"
        [ngClass]="{
          light: !!light,
          small: !!small
        }"
        (click)="onClick.emit()"
      >
        <ng-container *ngIf="!!place">
          {{ place?.city || place?.locality || place?.addressString }}
        </ng-container>

        <ng-container *ngIf="!!addressString && showAddressString">
          {{ addressString }}
        </ng-container>
      </b>
    </ng-container>
    <ng-container *ngIf="!!link">
      <a
        [style.width]="width"
        class="ellipsis-font"
        [ngClass]="{
          small: !!small
        }"
        (click)="onClick.emit()"
      >
        <ng-container *ngIf="!!place">
          {{ place?.city || place?.locality || place?.suburb || place?.addressString }}
        </ng-container>

        <ng-container *ngIf="!!addressString && showAddressString">
          {{ addressString }}
        </ng-container>
      </a>
    </ng-container>
  `,
})
export class InlineLocationComponent implements OnInit {
  @Input() small: boolean;
  @Input() light: boolean;
  @Input() bold: boolean;
  @Input() link: boolean;
  @Input() place: IAddressForm;
  @Input() location: IPosition;
  @Input() width: string;
  @Input() showAddressString: boolean;
  @Output() onClick = new EventEmitter<any>();

  addressString: string;

  private readonly googleMapsPredictionService: GoogleMapsPredictionService;

  constructor(injector: Injector) {
    this.googleMapsPredictionService = injector.get(GoogleMapsPredictionService);
  }

  ngOnInit(): void {
    if (!this.location) return;

    this.googleMapsPredictionService.mapsApi$.pipe(take(1)).subscribe(() => {
      const location = {
        lat: this.location.latitude,
        lng: this.location.longitude,
      };
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          this.addressString = get(results, [0, 'formatted_address']);
        }
      });
    });
  }
}
