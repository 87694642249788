import { Component, Injector, Input } from '@angular/core';
import { CommonComponent } from '@wakanda/wakanda-core';
import { ISetupStep } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-avo-business-setup-steps',
  templateUrl: './setup-steps.component.html',
  styleUrls: ['./setup-steps.component.scss'],
})
export class SetupStepsComponent extends CommonComponent {
  @Input() steps: ISetupStep[];

  constructor(injector: Injector) {
    super(injector);
  }
}
