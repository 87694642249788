import { Component, EventEmitter, Output } from '@angular/core';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-desktop-device-dialog',
  template: `
    <ui-modal
      [modal]="ModalType.SLIDE_IN_UP"
      [title]="'avo_seller.landing_dialog.title' | translate"
      [isVisible]="true"
    >
      <p zen-translate>avo_seller.landing_dialog.message1</p>
      <p zen-translate>avo_seller.landing_dialog.message2</p>
      <p zen-translate>avo_seller.landing_dialog.message3</p>

      <ui-divider></ui-divider>
      <ui-button
        [callToActionButton]="true"
        [selectButton]="true"
        aligned="bottom"
        [content]="'avo_seller.landing_dialog.button' | translate"
        (onClick)="onDone.emit()"
      ></ui-button>
    </ui-modal>
  `,
})
export class DesktopDeviceDialogComponent {
  @Output() onDone = new EventEmitter();
  ModalType = ModalType;
}
