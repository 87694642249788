import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { applicationReducer, IApplicationState } from '@wakanda/wakanda-core';
import { ITranslateReducerState, translateReducer } from '@wakanda/zentity-translate-store';

export interface AppState {
  translate2: ITranslateReducerState;
  application2: IApplicationState;
}

export const rootReducer = new InjectionToken<ActionReducerMap<AppState>>(' Root reducer token', {
  factory: () => ({
    translate2: translateReducer,
    application2: applicationReducer,
  }),
});
