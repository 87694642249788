<div class="container submerged" [class.green]="isGreenContainer">
  <div class="page-container">
    <h1 class="title">{{ sellingToBusiness.title | translate }}</h1>
    <ng-container *ngIf="sellingToBusiness?.text">
      <ui-divider [compact]="true"></ui-divider>
      <p class="light">{{ sellingToBusiness.text | translate }}</p>
    </ng-container>
    <div class="row align-stretch">
      <div class="column">
        <ng-container *ngFor="let item of sellingToBusiness.items; let index = index">
          <ui-divider [compact]="true"></ui-divider>
          <div (click)="handlePanel(index)" class="row between title text-align-left">
            <p [class.active]="activeItem === index">{{ item.title | translate }}</p>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <ui-icon *ngIf="activeItem !== index" icon="down" size="1.2rem"></ui-icon>
            <wakanda-avo-business-selling-to-business-loader
              *ngIf="activeItem === index"
            ></wakanda-avo-business-selling-to-business-loader>
          </div>
          <ng-container *ngIf="activeItem === index">
            <ui-divider [compact]="true"></ui-divider>
            <p class="light text-align-left">{{ item.text | translate }}</p>
          </ng-container>
          <ui-divider [compact]="true"></ui-divider>
          <div *ngIf="activeItem === index" class="mobile-img-container">
            <img src="{{ 'assets/images/' + item.image }}" alt="selling_to_business{{ index }}" />
          </div>
          <ui-divider [solid]="activeItem !== index" [compact]="true"></ui-divider>
        </ng-container>
      </div>
      <div class="img-container">
        <ng-container *ngFor="let item of sellingToBusiness.items; let index = index">
          <img
            *ngIf="activeItem === index"
            src="{{ 'assets/images/' + item.image }}"
            alt="selling_to_business{{ index }}"
          />
        </ng-container>
      </div>
    </div>
  </div>
</div>
