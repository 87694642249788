import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { ZenAuthFacade } from '../redux/zenAuth.facade';
import { Router } from '@angular/router';
import { AuthFacade as AuthFacade2, UrlRoutes } from '@avo/shared/core';

@Injectable()
export class ErrorAuthorizationInterceptor implements HttpInterceptor {
  constructor(private authFacade: ZenAuthFacade, private router: Router, private authFacade2: AuthFacade2) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authFacade.removeToken();
          this.authFacade2.logout();
          return throwError(() => error);
        }

        if (error.error?.postAction === 'LOGOUT') {
          this.authFacade.removeToken();
          this.authFacade2.logout();
          this.router.navigateByUrl(UrlRoutes.auth.login.landing);
        }
        return throwError(() => error);
      }),
    );
  }
}
