const recoveryBaseV1 = (baseUrl: string): string => `${baseUrl}/users/v1`;
const recoveryBaseV2 = (baseUrl: string): string => `${baseUrl}/users/v2`;

export const recoveryStart = (baseUrl: string): string => `${recoveryBaseV2(baseUrl)}/recovery/start`;
export const recoveryUsername = (baseUrl: string): string => `${recoveryBaseV2(baseUrl)}/recovery/username`;
export const recoveryPassword = (baseUrl: string): string => `${recoveryBaseV1(baseUrl)}/recovery/password`;
export const recoveryVerifyOTP = (baseUrl: string): string => `${recoveryBaseV1(baseUrl)}/recovery/verifyOTP`;
export const recoveryNewPassword = (baseUrl: string): string => `${recoveryBaseV1(baseUrl)}/recovery/newPassword`;
export const recoveryPin = (baseUrl: string): string => `${recoveryBaseV1(baseUrl)}/recovery/pin`;
export const recoveryResetPin = (baseUrl: string): string => `${recoveryBaseV1(baseUrl)}/recovery/resetPin`;
export const recoveryFacemap = (baseUrl: string): string => `${recoveryBaseV1(baseUrl)}/recovery/facemap`;
