import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import {
  INewPasswordRequest,
  IRecoveryNextStepResponse,
  IRecoveryPasswordRequest,
  IRecoveryStartRequest,
  IRecoveryUsernameRequest,
  IVerifyOTPRequest,
} from '../../models/recovery.model';
import { ApiService } from '../api/api.service';
import * as endpoints from './recovery.endpoints';

@Injectable()
export class RecoveryService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  startRecovery = (start: IRecoveryStartRequest): Observable<IRecoveryNextStepResponse> =>
    this.api.post<IRecoveryStartRequest>(endpoints.recoveryStart(this.appConfig.backendConfig.apiUrl), start);

  usernameRecovery = (userInfo: IRecoveryUsernameRequest): Observable<IRecoveryNextStepResponse> =>
    this.api.post<IRecoveryUsernameRequest>(endpoints.recoveryUsername(this.appConfig.backendConfig.apiUrl), userInfo);

  passwordRecovery = (userInfo: IRecoveryPasswordRequest): Observable<IRecoveryNextStepResponse> =>
    this.api.post<IRecoveryPasswordRequest>(endpoints.recoveryPassword(this.appConfig.backendConfig.apiUrl), userInfo);

  passwordVerifyOtpRecovery = (userInfo: IVerifyOTPRequest): Observable<IRecoveryNextStepResponse> =>
    this.api.post<IVerifyOTPRequest>(endpoints.recoveryVerifyOTP(this.appConfig.backendConfig.apiUrl), userInfo);

  passwordNewPasswordRecovery = (userInfo: INewPasswordRequest): Observable<IRecoveryNextStepResponse> =>
    this.api.post<INewPasswordRequest>(endpoints.recoveryNewPassword(this.appConfig.backendConfig.apiUrl), userInfo);
}
