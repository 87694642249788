import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import { BrowserStorageService } from '../browser-storage/browser-storage.service';

interface IZenAuthAccessToken {
  access_token: string;
  expires_in: number;
  refresh_token?: string;
  scope?: string;
  token_type: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private tokenKey = 'accessToken';
  private deviceIdKey = 'deviceId';
  private customerKey = 'customerToken';

  constructor(private webStorage: BrowserStorageService) {}

  isAuthorized = (): boolean => !!this.getAccessToken() && !!this.getToken();

  getAccessToken = (): IZenAuthAccessToken => this.webStorage.get(this.tokenKey);
  getToken = (): string => {
    const accessToken = this.getAccessToken() as any;
    if (accessToken && accessToken.accessToken) {
      return accessToken.accessToken;
    }
    return get(this.getAccessToken(), 'access_token');
  };

  removeAccessToken = (): void => {
    this.webStorage.remove(this.tokenKey);
  };
  setAccessToken = (accessToken: IZenAuthAccessToken): void => {
    this.webStorage.remove(this.customerKey);
    this.webStorage.set(this.tokenKey, accessToken);
  };

  getDeviceId = (): string => this.webStorage.get(this.deviceIdKey, false, null);
  setDeviceId = (deviceId: string): void => this.webStorage.set(this.deviceIdKey, deviceId);

  checkJWTToken = (): void => {
    const token = this.getAccessToken();
    if (!!token?.access_token && !isEmpty(token)) {
      const decodedToken: any = jwt_decode(token?.access_token);
      const expiration = decodedToken?.exp * 1000;
      const currentTime = new Date().getTime();
      if (currentTime > expiration) {
        this.removeAccessToken();
      }
    }
  };
}
