import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { started } from '@wakanda/wakanda-core';
import { v4 as uuidv4 } from 'uuid';
import { TranslateNewRequestAction, TranslateRequestAction } from './translate.actions';
import { ITranslatePartialState } from './translate.reducer';
import { getTranslateByKey, getTranslates, getTranslatesByKeys } from './translate.selector';

@Injectable({ providedIn: 'root' })
export class TranslateFacade {
  getData$ = this.store.select(getTranslates);
  getTranslateByKey$ = (translateKey: string) => this.store.pipe(select(getTranslateByKey(translateKey)));
  getTranslatesByKeys$ = (translateKeys: string[]) => this.store.pipe(select(getTranslatesByKeys(translateKeys)));

  constructor(private store: Store<ITranslatePartialState>) {}

  fetchTranslations = (error: (error: any) => void): void => {
    const taskId = `fetching-translations-${uuidv4()}`;
    this.store.dispatch(started(new TranslateRequestAction(taskId, error)));
  };

  fetchNewTranslations = (url: string, error: (error: any) => void): void => {
    const taskId = `fetching-new-translations-${uuidv4()}`;
    this.store.dispatch(started(new TranslateNewRequestAction(taskId, url, error)));
  };
}
