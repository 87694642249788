<div class="column" *ngIf="!control.touched">
  <div class="row left">
    <ui-icon icon="tick_grey" size="1.25rem"></ui-icon>
    <ui-divider [compact]="true" [vertical]="true"></ui-divider>
    <p class="small light" zen-translate>validation.password.format</p>
  </div>
  <div class="row left">
    <ui-icon icon="tick_grey" size="1.25rem"></ui-icon>
    <ui-divider [compact]="true" [vertical]="true"></ui-divider>
    <p class="small light" zen-translate>validation.password.upper_case</p>
  </div>
  <div class="row left">
    <ui-icon icon="tick_grey" size="1.25rem"></ui-icon>
    <ui-divider [compact]="true" [vertical]="true"></ui-divider>
    <p class="small light" zen-translate>validation.password.lower_case</p>
  </div>
  <div class="row left">
    <ui-icon icon="tick_grey" size="1.25rem"></ui-icon>
    <ui-divider [compact]="true" [vertical]="true"></ui-divider>
    <p class="small light" zen-translate>validation.password.special_character</p>
  </div>
</div>

<div class="column" *ngIf="control.touched">
  <div class="row left">
    <ui-icon
      [icon]="!control?.errors?.invalidPasswordMinLength && !control?.errors?.required ? 'tick_green' : 'tick_red'"
      size="1.25rem"
    ></ui-icon>
    <ui-divider [compact]="true" [vertical]="true"></ui-divider>
    <p
      class="small error"
      [ngClass]="{ success: !control?.errors?.invalidPasswordMinLength && !control?.errors?.required }"
      zen-translate
    >
      validation.password.format
    </p>
  </div>
  <div class="row left">
    <ui-icon
      [icon]="!control?.errors?.invalidCapitalCase && !control?.errors?.required ? 'tick_green' : 'tick_red'"
      size="1.25rem"
    ></ui-icon>
    <ui-divider [compact]="true" [vertical]="true"></ui-divider>
    <p
      class="small error"
      [ngClass]="{ success: !control?.errors?.invalidCapitalCase && !control?.errors?.required }"
      zen-translate
    >
      validation.password.upper_case
    </p>
  </div>
  <div class="row left">
    <ui-icon
      [icon]="!control?.errors?.invalidSmallCase && !control?.errors?.required ? 'tick_green' : 'tick_red'"
      size="1.25rem"
    ></ui-icon>
    <ui-divider [compact]="true" [vertical]="true"></ui-divider>
    <p
      class="small error"
      [ngClass]="{ success: !control?.errors?.invalidSmallCase && !control?.errors?.required }"
      zen-translate
    >
      validation.password.lower_case
    </p>
  </div>
  <div class="row left">
    <ui-icon
      [icon]="!control?.errors?.invalidSpecialCharacters && !control?.errors?.required ? 'tick_green' : 'tick_red'"
      size="1.25rem"
    ></ui-icon>
    <ui-divider [compact]="true" [vertical]="true"></ui-divider>
    <p
      class="small error"
      [ngClass]="{ success: !control?.errors?.invalidSpecialCharacters && !control?.errors?.required }"
      zen-translate
    >
      validation.password.special_character
    </p>
  </div>
</div>
