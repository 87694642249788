import { Injectable } from '@angular/core';
import { TaskType } from '../models/store.models';
import { Store } from '@ngrx/store';
import { v4 } from 'uuid';
import * as AuthActions from './auth.actions';
import {
  IAuthAccessToken,
  IAuthAvoClientInfo,
  IAuthDevice,
  IAuthNextStep,
  IAuthRefreshTokenStartRequest,
} from './auth.models';
import * as AuthSelectors from './auth.selectors';

/**
 * The **AuthFacade** handles a basic authentication functionality on the site.
 *
 * It is a general facade which does not handle source of login data, but works with them (init, logout, save to storage, ...).
 */
@Injectable({ providedIn: 'root' })
export class AuthFacade {
  isLoggedIn$ = this.store.select(AuthSelectors.isLoggedIn);
  isTrustedDevice$ = this.store.select(AuthSelectors.isTrustedDevice);

  constructor(private readonly store: Store) {}

  /**
   * Check validity of the logged user on the application start, in case of failure the user is handled as a guest.
   *
   * @param onSuccess Success callback
   * @param onError Error callback
   */
  init(onSuccess: () => void, onError: () => void) {
    this.store.dispatch(AuthActions.initAction({ onSuccess, onError }));
  }

  /**
   * Save token to storage and setup following actions.
   *
   * @param data Access token data
   * @param saveToStorage Flag if token is going to be saved into storage
   * @param onSuccess Success callback
   */
  saveToken(data: IAuthAccessToken, saveToStorage: boolean, onSuccess?: (data?: IAuthNextStep) => void): void {
    this.store.dispatch(AuthActions.saveTokenAction({ data, saveToStorage, onSuccess }));
  }

  /**
   * Refresh token in order to extend the current user's session.
   *
   * @param payload Refresh token data
   * @param onSuccess Success callback
   * @param onError Error callback
   */
  refreshToken(
    payload: IAuthRefreshTokenStartRequest,
    onSuccess?: (data?: IAuthNextStep) => void,
    onError?: () => void,
  ): void {
    this.store.dispatch(
      AuthActions.refreshTokenRequest({
        payload,
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
        onSuccess,
        onError,
      }),
    );
  }

  /**
   * Logout the current user.
   *
   * @param keepAlive Flag if refresh token should be preserved for further auto login on the application start
   * @param onSuccess Success callback
   */
  logout(keepAlive?: boolean, onSuccess?: () => void): void {
    this.store.dispatch(AuthActions.logoutAction({ keepAlive, onSuccess }));
  }

  /**
   * Fetch the basic user data.
   *
   * @param onSuccess Success callback
   */
  fetchAvoInfo(onSuccess?: (data: IAuthAvoClientInfo) => void): void {
    this.store.dispatch(
      AuthActions.avoInfoRequest({
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
        onSuccess,
      }),
    );
  }

  /**
   * Fetch the current device data.
   *
   * @param onSuccess Success callback
   * @param onError Error callback
   */
  fetchCurrentDevice(onSuccess?: (data: IAuthDevice) => void, onError?: () => void): void {
    this.store.dispatch(
      AuthActions.currentDeviceRequest({
        taskMetadata: { type: TaskType.STARTED, taskId: v4() },
        onSuccess,
        onError,
      }),
    );
  }
}
