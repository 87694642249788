import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { IRecentElectricityClaimResponse } from '../../../models/prepaids.model';
import { PrepaidElectricityService } from '../../../services/prepaid/electricity/prepaid-electricity.service';
import { ProfileService } from '../../../services/profile/profile.service';
import { ApplicationFacade } from '../../application/application.facade';
import { failed, succeed } from '../../application/task.actions';
import * as actions from './prepaid-electricity.actions';
import { EVENT } from './prepaid-electricity.events';

@Injectable()
export class PrepaidElectricityEffects {
  constructor(
    private actions$: Actions,
    private appFacade: ApplicationFacade,
    private service: PrepaidElectricityService,
    private profileService: ProfileService,
  ) {}

  start$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RecentRequestAction>(EVENT.RECENT_REQUEST),
      switchMap(({ taskId }) =>
        this.service.recent().pipe(
          map((data: IRecentElectricityClaimResponse[]) => succeed(new actions.RecentResponseAction(taskId, data))),
          catchError(error => of(failed(new actions.ErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  validateMeter$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ValidateMeterRequestAction>(EVENT.VALIDATE_METER_REQUEST),
      switchMap(({ taskId, payload, onSucceeded, onError }) =>
        this.service.validateMeter(payload).pipe(
          map(() => {
            onSucceeded();
            return succeed(new actions.ValidateMeterResponseAction(taskId));
          }),
          catchError(error => {
            const errorCode = error?.error?.code || error?.error?.error?.code;
            if (error?.status === 401 || errorCode === 'wallet_exception' || errorCode === 'invalid_wallet') {
              return of(failed(new actions.ErrorAction(taskId, error)));
            }
            if (onError) {
              onError();
            }
            return of(succeed(new actions.ValidateMeterResponseAction(taskId)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  placeOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.PlaceOrderRequestAction>(EVENT.PLACE_ORDER_REQUEST),
      switchMap(({ taskId, payload, onSucceeded }) =>
        this.service.placeOrder(payload).pipe(
          map(data => {
            onSucceeded();
            return succeed(new actions.PlaceOrderResponseAction(taskId, data));
          }),
          catchError(error => of(failed(new actions.ErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchPhonebook$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ElectricityPhoneBookRequest>(EVENT.PHONEBOOK_REQUEST),
      switchMap(({ payload, taskId }) =>
        this.profileService.fetchPhonebook(payload, false).pipe(
          map(phonebook => succeed(new actions.ElectricityPhoneBookResponse(taskId, phonebook))),
          catchError(error => of(failed(new actions.ErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ElectricityCheckUserRequest>(EVENT.CHECK_USER_REQUEST),
      switchMap(({ taskId, payload }) =>
        this.profileService.checkPhone(payload).pipe(
          map((user: any) =>
            succeed(
              new actions.ElectricityCheckUserResponse(taskId, {
                isWakandaUser: user.userId !== undefined,
                phone: payload.phoneNumber.phoneNumber,
              }),
            ),
          ),
          catchError(error => of(failed(new actions.ErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
}
