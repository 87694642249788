import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'formatDate',
  pure: false,
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string | number, format: string): string {
    let valueTimestampD: dayjs.Dayjs;
    if (typeof value === 'number') {
      const valueLength = value.toString().length;
      if (valueLength === 13) {
        value = value / 1000;
      }
      valueTimestampD = dayjs.unix(value);
    } else {
      valueTimestampD = dayjs(value);
    }

    if (format) {
      return valueTimestampD.format(format);
    }
    return valueTimestampD.format();
  }
}
