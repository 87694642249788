import { Component, HostBinding, Injector, Input } from '@angular/core';
import { ENVIRONMENT, IEnvironment } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-icon',
  styles: [
    `
      :host > img {
        display: block;
      }

      :host.disabled {
        opacity: 0.25;
        pointer-events: none;
      }
    `,
  ],
  template: `
    <img
      [alt]="alt ? alt : icon ? icon : src ? src : ''"
      [style.object-fit]="autoFit ? 'cover' : 'auto'"
      [style.width]="width || size || 'auto'"
      [style.height]="height || size || 'auto'"
      [style.margin]="'auto'"
      [style.borderRadius]="roundCorners ? '50%' : '0'"
      [style.borderTopLeftRadius]="topCornersRadius ? topCornersRadius : 'unset'"
      [style.borderTopRightRadius]="topCornersRadius ? topCornersRadius : 'unset'"
      [style.borderBottomLeftRadius]="bottomCornersRadius ? bottomCornersRadius : 'unset'"
      [style.borderBottomRightRadius]="bottomCornersRadius ? bottomCornersRadius : 'unset'"
      [src]="!!src ? (src | iconSrc : baseUrl | resizeImageUrl) : baseUrl + 'assets/icons/' + icon + '.svg'"
      [ngClass]="{ 'animation-pulsate': animationPulsate, 'animation-rotate': animationRotate }"
      [width]="width || size || 'auto'"
      [height]="height || size || 'auto'"
    />
  `,
})
export class IconComponent {
  @Input() size: string;
  @Input() width: string;
  @Input() height: string;
  @Input() autoFit: boolean;
  @Input() minHeight: string;
  @Input() roundCorners: boolean;
  @Input() topCornersRadius: string;
  @Input() bottomCornersRadius: string;
  @Input() icon: string;
  @Input() alt: string;
  @Input() src: string;
  @Input() animationPulsate: boolean;
  @Input() animationRotate: boolean;
  @Input() @HostBinding('class.disabled') disabled: boolean;
  readonly environmentConfig: IEnvironment;
  readonly baseUrl: string;

  constructor(injector: Injector) {
    this.environmentConfig = injector.get(ENVIRONMENT);
    this.baseUrl = `${this.environmentConfig.baseUrl ?? ''}/`;
  }
}
