import { Component, Input } from '@angular/core';
import { IBasicTable } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-table-basic',
  templateUrl: './table-basic.component.html',
  styleUrls: ['./table-basic.component.scss'],
})
export class TableBasicComponent {
  @Input() basicTable: IBasicTable;
}
