import { createAction, props } from '@ngrx/store';
import { ITask } from '../models/store.models';

export const addTaskAction = createAction('[Application/Task] Add task', props<{ data: ITask }>());

export const removeTaskAction = createAction('[Application/Task] Remove task', props<{ data: ITask }>());

export const repeatTaskAction = createAction('[Application/Task] Repeat task', props<{ data: ITask }>());

export const finishTaskAction = createAction('[Application/Task] Finish task', props<{ taskId: string }>());
