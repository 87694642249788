import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { DefaultProjectorFn } from '@ngrx/store/src/selector';
import { ITranslateReducerState } from '../model/translate.model';
import { TRANSLATE_FEATURE_KEY } from './translate.reducer';
import get from 'lodash-es/get';

const translateState = createFeatureSelector<ITranslateReducerState>(TRANSLATE_FEATURE_KEY);

export const getTranslates = createSelector(translateState, state => state?.data);

export const getTranslateByKey = (translateKey: string): MemoizedSelector<object, any, DefaultProjectorFn<any>> =>
  createSelector(translateState, state => {
    if (!get(state, ['data', translateKey])) return translateKey;
    return get(state, ['data', translateKey]);
  });

export const getTranslatesByKeys = (translateKeys: string[]): MemoizedSelector<object, any, DefaultProjectorFn<any>> =>
  createSelector(translateState, state => {
    const result: string[] = [];

    translateKeys.forEach(key => {
      if (!get(state, ['data', key])) {
        result.push(key);
      } else {
        result.push(get(state, ['data', key]));
      }
    });
    return result;
  });
