import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMatrixMessage, IMessageEventShowJobCard } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-show-job-card',
  styleUrls: ['./chat-widget-show-job-card.component.scss'],
  template: `
    <ui-item>
      <div class="row between">
        <b class="text-align-left" zen-translate>chat.endchat.message</b>
        <ui-divider [vertical]="true"></ui-divider>
        <a (click)="onJobDetail.emit(message?.content?.jobId)" zen-translate>chat.endchat.view</a>
      </div>
    </ui-item>
    <ui-divider></ui-divider>
  `,
})
export class ChatWidgetShowJobCardComponent {
  @Input() message: IMatrixMessage<IMessageEventShowJobCard>;
  @Output() onJobDetail = new EventEmitter<string>();
}
