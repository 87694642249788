import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs';
import * as LayoutActions from './layout.actions';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';
import { TranslatePipe } from '@avo/shared/avo-translate';
import { ROUTER_NAVIGATED, RouterNavigatedAction, RouterNavigatedPayload } from '@ngrx/router-store';
import { IRouteData, SidenavType } from '../../model/core.model';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CommonUtility } from '../../utils/common-utility';
import { ITaskAction } from '../models/store.models';
import cloneDeep from 'lodash-es/cloneDeep';
import { initialHeaderState } from './layout.reducer';

@Injectable()
export class LayoutEffects {
  private readonly BREAKPOINTS_CONFIG = {
    mobile: '(max-width: 599px)',
    tablet: '(min-width: 600px) and (max-width: 839px)',
    desktop: '(min-width: 840px)',
  };

  /** Handles current device by media queries. It emits new value when resolution is changed. */
  observeBreakpoints$ = createEffect(() =>
    this.breakpointObserver.observe(Object.values(this.BREAKPOINTS_CONFIG)).pipe(
      map(result => {
        let device;
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            device = Object.keys(this.BREAKPOINTS_CONFIG).find(key => this.BREAKPOINTS_CONFIG[key] === query);
            break;
          }
        }
        return LayoutActions.currentDevice({ device });
      }),
    ),
  );

  setTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.setTitle),
      map(({ value, translate }) => {
        if (value && translate) {
          value = this.translatePipe.transform(value);
        }
        this.titleService.setTitle(this.composeTitle(value));
        return LayoutActions.saveTitle({ value });
      }),
    ),
  );

  hideInitLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LayoutActions.hideInitLoader),
        tap(() => document.querySelector('#page-init-loader')?.remove()),
      ),
    { dispatch: false },
  );

  processRouteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      switchMap((eventData: RouterNavigatedAction) => {
        const data = this.getMergedRouteData(eventData.payload);
        const header = CommonUtility.addPropertiesToObject(cloneDeep(initialHeaderState), data.header);

        return [
          LayoutActions.setTitle({ value: data.title ?? undefined, translate: true }),
          LayoutActions.paddedContentX({ value: data.paddedContentX ?? true }),
          LayoutActions.paddedContentY({ value: data.paddedContentY ?? true }),
          LayoutActions.bottomNavigationSection({ value: data.bottomNavigationSection ?? undefined }),
          LayoutActions.header({ value: header }),
          LayoutActions.sidenav({ value: data.sidenav ?? SidenavType.PRIMARY }),
          LayoutActions.sidenavLink({ value: data.sidenavLink }),
        ];
      }),
    ),
  );

  private readonly DEFAULT_TITLE = 'Avo';

  constructor(
    private readonly actions$: Actions<ITaskAction>,
    private titleService: Title,
    private translatePipe: TranslatePipe,
    private breakpointObserver: BreakpointObserver,
  ) {}

  private getMergedRouteData = (inputData: RouterNavigatedPayload): IRouteData => {
    let route: ActivatedRouteSnapshot = inputData.routerState.root;
    let storage: IRouteData = {};

    do {
      storage = CommonUtility.addPropertiesToObject(storage, route.data);
      route = route.firstChild;
    } while (route);

    return storage;
  };

  private composeTitle = (value: string): string => (value ? `${value} - ${this.DEFAULT_TITLE}` : this.DEFAULT_TITLE);
}
