import { LocationStrategy } from '@angular/common';
import { Directive, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[customRouterLink]',
})
export class CustomRouterLinkDirective implements OnInit {
  @Input() customRouterLink: string;
  @Output() exitModal?: EventEmitter<{ screenId: string; extUrl: string }> = new EventEmitter();

  @HostBinding('attr.href') href: string | null = null;
  @HostBinding('attr.target') @Input() target: string;
  finalUrl: string;
  isExternalUrl: boolean;
  exitUrlParams: URLSearchParams;
  internalUrls = [
    'https://test.avo.africa',
    'https://stage.avo.africa',
    'https://preview.avo.africa',
    'https://avo.africa',
    'https://www.test.avo.africa',
    'https://www.stage.avo.africa',
    'https://www.preview.avo.africa',
    'https://www.avo.africa',
    'https://stage-v3.avo.africa',
    'https://preview-v3.avo.africa',
    'https://www.stage-v3.avo.africa',
    'https://www.preview-v3.avo.africa',
  ];

  constructor(private router: Router, private locationStrategy: LocationStrategy) {}

  ngOnInit(): void {
    this.checkInternalUrl();
    this.checkExternalUrl();
    this.updateTargetUrlAndHref();
  }

  checkInternalUrl(): void {
    this.finalUrl = this.customRouterLink;
    this.internalUrls.forEach(internalUrl => {
      if (this.customRouterLink?.includes(internalUrl)) {
        this.finalUrl = this.customRouterLink.replace(internalUrl, '');
      }
    });
  }

  checkExternalUrl(): void {
    if (this.finalUrl?.includes('http') || this.finalUrl?.includes('www')) {
      this.isExternalUrl = true;
    } else {
      this.isExternalUrl = false;
    }
  }

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.shiftKey', '$event.altKey', '$event.metaKey'])
  onClick(button: number, ctrlKey: boolean, shiftKey: boolean, altKey: boolean, metaKey: boolean): boolean {
    if (button !== 0 || ctrlKey || shiftKey || altKey || metaKey) {
      return true;
    }

    if (typeof this.target === 'string' && this.target != '_self') {
      return true;
    }

    if (this.isExternalUrl) {
      if (this.exitUrlParams) {
        const screenId = this.exitUrlParams.get('type');
        const extUrl = this.exitUrlParams.get('url');
        this.exitModal.emit({ screenId, extUrl });
        return false;
      }
      this.target = '_blank';
      return true;
    } else {
      this.router.navigateByUrl(this.finalUrl);
      return false;
    }
  }

  private updateTargetUrlAndHref(): void {
    if (!this.finalUrl) return;
    if (this.isExternalUrl) {
      if (this.finalUrl.includes('exit')) {
        this.exitUrlParams = new URLSearchParams(this.finalUrl.split('?')[1]);
        return;
      }
      this.href = this.finalUrl;
    } else {
      this.href = this.locationStrategy.prepareExternalUrl(this.finalUrl);
    }
  }
}
