import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class IdempotencyInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    if (!request.url.includes('/configuration.json') && !request.url.includes('/autologon/start/moneyapp'))
      request = request.clone({ headers: request.headers.set('x-idempotency-key', uuidv4()) });
    return next.handle(request);
  }
}
