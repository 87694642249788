import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  ChatMessageType,
  ChatSendMessageId,
  IChatSendMessage,
  IWebsocketMessageEvent,
  WebsocketMessageEventType,
  WebSocketService,
} from '@wakanda/wakanda-core';
import { ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { ChatEventType, ChatFacade } from '@wakanda/wakanda-chat';
import {
  IChatPaymentRequestContent,
  IChatRatingRequested,
  IMatrixMessage,
  MessageEventType,
} from '@wakanda/agent-chat-model';
import { Subscription } from 'rxjs';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-chat',
  styles: [
    `
      :host {
        display: flex;
        flex: 1;
      }
    `,
  ],
  template: `
    <ui-chat-content
      [messages]="messages"
      [wsMessage]="lastWebsocketMessage"
      [chatType]="chatType"
      [compact]="compact"
      [borders]="borders"
      [heyNedTheme]="heyNedTheme"
      [title]="title"
      [showCloseButton]="showCloseButton"
      [noBottomPadding]="noBottomPadding"
      [noTopPadding]="noTopPadding"
      [hasWallet]="hasWallet"
      [isAttachmentPickerVisible]="isAttachmentPickerVisible"
      (onSend)="chatFacade.sendMessage({ roomId: _roomId, content: $event })"
      (onAttachmentUpload)="chatFacade.sendAttachment(_roomId, $event)"
      (onWidget)="sendEvent($event)"
      (setNewLocation)="setNewLocation.emit($event)"
      (onPayment)="onPayment.emit($event)"
      (onRating)="onRating.emit($event)"
      (onCreateWallet)="onCreateWallet.emit($event)"
      (onProductClick)="onProductClick.emit($event)"
      (onJobDetail)="onJobDetail.emit($event)"
      (onCloseChat)="onShowCloseDialog()"
    ></ui-chat-content>

    <ui-modal
      [isVisible]="showCloseDialog"
      [title]="'hn.concierge_chat.close_dialog.title' | translate"
      [modal]="ModalType.SLIDE_IN_UP"
    >
      <ui-divider [compact]="true"></ui-divider>

      <p zen-translate>hn.concierge_chat.close_dialog.message</p>
      <ui-divider></ui-divider>

      <div class="row between">
        <ui-button
          [selectButton]="false"
          [fluid]="true"
          [content]="'hn.concierge_chat.close_dialog.cancel_button' | translate"
          (onClick)="onShowCloseDialog()"
          class="flex-1"
        >
        </ui-button>

        <ui-divider [vertical]="true"></ui-divider>

        <ui-button
          [selectButton]="true"
          [fluid]="true"
          [content]="'hn.concierge_chat.close_dialog.confirm_button' | translate"
          (onClick)="onCloseChat()"
          class="flex-1"
        >
        </ui-button>
      </div>
    </ui-modal>
  `,
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() chatType: string;
  @Input() searchMessageType: ChatMessageType = ChatMessageType.text;
  @Input() clearChatDataOnInit = true;
  @Input() clearChatDataOnDestroy = true;
  @Input() hasWallet: boolean;
  @Input() isAttachmentPickerVisible = true;

  @Input() compact: boolean;
  @Input() borders: string;
  @Input() heyNedTheme: boolean;
  @Input() inlineTheme: boolean;
  @Input() showCloseButton: boolean;
  @Input() title: string;
  @Input() noTopPadding: string;
  @Input() noBottomPadding: string;

  @Output() onSearch = new EventEmitter<void>();
  @Output() onPayment = new EventEmitter<IChatPaymentRequestContent>();
  @Output() onRating = new EventEmitter<IChatRatingRequested>();
  @Output() setNewLocation = new EventEmitter<string>();
  @Output() onChatClosed = new EventEmitter<void>();
  @Output() onHeyNedRoom = new EventEmitter<void>();
  @Output() onCreateWallet = new EventEmitter<void>();
  @Output() onProductClick = new EventEmitter<string>();
  @Output() onJobDetail = new EventEmitter<string>();

  _roomId: string;
  messages: IMatrixMessage[];
  lastWebsocketMessage: IWebsocketMessageEvent;
  //
  chatFacade: ChatFacade;
  route: ActivatedRoute;
  webSocketService: WebSocketService;
  showCloseDialog: boolean;
  //
  ModalType = ModalType;
  //
  private subscriptions = new Subscription();

  constructor(injector: Injector) {
    this.chatFacade = injector.get(ChatFacade);
    this.route = injector.get(ActivatedRoute);
    this.webSocketService = injector.get(WebSocketService);
  }

  @Input() set roomId(roomId: string) {
    if (!roomId) return;
    this._roomId = roomId;

    this.chatFacade.fetchRoomById(roomId);
    this.chatFacade.fetchMessagesByRoomId({ roomId });

    const searchParam = this.route.snapshot.queryParamMap.get('search');
    if (!searchParam) return;

    const message: IChatSendMessage = {
      id: ChatSendMessageId.SENDMESSAGE,
      message: {
        roomId,
        content: {
          msgtype: this.searchMessageType,
          body: `You searched for "${searchParam}"`,
        },
      },
    };
    this.webSocketService.sendMessage(message);
    this.onSearch.emit();
  }

  ngOnInit(): void {
    if (this.clearChatDataOnInit) {
      this.chatFacade.clearChatData();
    }

    this.initWebsocketMessagesListener();
    this.initRoomMessagesListener();
    this.initLastEventSentListener();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.clearChatDataOnDestroy) {
      this.chatFacade.clearChatData();
    }
  }

  /**
   * @see initLastEventSentListener
   */
  sendEvent(event: any): void {
    this.chatFacade.sendEvent({
      roomId: this._roomId,
      eventType: ChatEventType.roomMessage,
      payload: event,
      txnId: uuidv4(),
    });
  }

  onCloseChat(): void {
    this.chatFacade.closeChat(this._roomId, () => {
      this.onChatClosed.emit();
    });
  }

  onShowCloseDialog(): void {
    this.showCloseDialog = !this.showCloseDialog;
  }

  private initRoomMessagesListener = (): void => {
    this.subscriptions.add(this.chatFacade.getActiveRoomMessages$.subscribe(messages => (this.messages = messages)));
  };

  private initWebsocketMessagesListener = (): void => {
    this.subscriptions.add(
      this.webSocketService.getMessages$.subscribe(message => {
        if (!message || !this._roomId) return;

        this.lastWebsocketMessage = message;

        const content = JSON.parse(message?.content ?? null);
        if (content?.msgtype === MessageEventType.NEW_ROOM) {
          this.onHeyNedRoom.emit();
        }

        if (message.websocketChatEventType === WebsocketMessageEventType.USER_WRITING) return;

        this.chatFacade.fetchMessagesByRoomId({ roomId: this._roomId });
      }),
    );
  };

  private initLastEventSentListener = (): void => {
    this.subscriptions.add(
      this.chatFacade.getEventSent$.subscribe(() => {
        if (!this._roomId) return;
        this.chatFacade.fetchMessagesByRoomId({ roomId: this._roomId });
      }),
    );
  };
}
