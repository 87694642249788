import { ModuleWithProviders, NgModule } from '@angular/core';
import { WakandaCoreModule } from '@wakanda/wakanda-core';
import { ZenAuthConfig } from './configs/zenauth.config';
import { ApiService, AuthService, OnboardingService, TransactionSigningService } from './services/';
import { ZEN_AUTH_CONFIG } from './tokens';

@NgModule({
  imports: [WakandaCoreModule],
})
export class ZenAuthModule {
  static forRoot(config: ZenAuthConfig): ModuleWithProviders<ZenAuthModule> {
    return {
      ngModule: ZenAuthModule,
      providers: [
        ApiService,
        AuthService,
        OnboardingService,
        TransactionSigningService,
        {
          provide: ZEN_AUTH_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
