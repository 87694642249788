import { AfterViewInit, Directive, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[afterIf]',
})
export class AfterIfDirective implements AfterViewInit, OnDestroy {
  @Output() afterIf = new EventEmitter<boolean>();

  ngAfterViewInit(): void {
    // send emit in next application tick when template is rendered
    window.setTimeout(() => this.afterIf.emit(true));
  }

  ngOnDestroy(): void {
    this.afterIf.emit(false);
  }
}
