import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { RequestService } from '../../services/request/request.service';
import { ApplicationFacade } from '../application/application.facade';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';
import * as actions from './request.actions';
import * as EVENT from './request.events';

@Injectable()
export class RequestEffects {
  fetchRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RequestsListRequestAction>(EVENT.REQUESTS_REQUESTED),
      switchMap(({ taskId, filter }) =>
        this.service.fetchOutgoingRequests(filter).pipe(
          map(response => succeed(new actions.RequestListResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchRequestDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RequestDetailRequestAction>(EVENT.REQUEST_DETAIL_REQUESTED),
      switchMap(({ taskId, id }) =>
        this.service.fetchOutgoingPaymentRequestDetail(id).pipe(
          map(response => succeed(new actions.RequestDetailResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RequestPayRequestAction>(EVENT.REQUEST_PAY_REQUESTED),
      switchMap(({ taskId, paymentRequestId, walletId, onSucceed, onError }) =>
        this.service.payPaymentRequest(walletId, paymentRequestId).pipe(
          map(response => {
            if (onSucceed) {
              onSucceed(response);
            }
            return succeed(new actions.RequestPayResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) {
              onError(error);
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  declineRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RequestDeclineRequestAction>(EVENT.REQUEST_DECLINE_REQUESTED),
      switchMap(({ taskId, paymentRequestId, walletId, onSucceed }) =>
        this.service.declinePaymentRequest(walletId, paymentRequestId).pipe(
          map(response => {
            if (onSucceed) {
              onSucceed(response);
            }
            return succeed(new actions.RequestDeclineResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  transactionsStatementDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RequestDownloadStatementRequestAction>(EVENT.REQUEST_DOWNLOAD_REQUESTED),
      switchMap(({ filter, onSucceed, taskId }) =>
        this.service.downloadStatement(filter).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.RequestDownloadStatementResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: RequestService) {}
}
