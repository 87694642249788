import { createFeatureSelector, createSelector } from '@ngrx/store';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import { APPLICATION_FEATURE_KEY, IApplicationState } from './application.reducer';
import { prefix, SILENT_START, STARTED } from './task.events';

const getState = createFeatureSelector<IApplicationState>(APPLICATION_FEATURE_KEY);

export const isLoading = createSelector(getState, (state: IApplicationState) =>
  filter(state?.tasks, task => task[prefix].type === STARTED),
);

export const isLoadingSilently = createSelector(getState, (state: IApplicationState) =>
  filter(state?.tasks, task => task[prefix].type === SILENT_START),
);

export const getTasks = createSelector(getState, (state: IApplicationState) => state?.tasks);

export const getErrorById = (taskId: string): any =>
  createSelector(getState, (state: IApplicationState) => find(state?.stackTrace, error => error.taskId === taskId));

export const getTaskById = (taskId: string): any =>
  createSelector(getState, (state: IApplicationState) => find(state?.tasks, t => t.taskId === taskId));

export const getInfoStepupPrep = (): any =>
  createSelector(getState, (state: IApplicationState) => state?.infoStepupPrep);

export const getScrollPosition = (): any =>
  createSelector(getState, (state: IApplicationState) => state?.scrollPosition);

export const getMaintenance = (): any =>
  createSelector(getState, (state: IApplicationState) =>
    state?.maintenance?.maintenancePageON ? state.maintenance : null,
  );
