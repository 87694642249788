import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as endpoints from './prepaid-airtimedata.endpoints';
import { AppConfig } from '../../../configs/app.config';
import {
  IAirtimeDataProvider,
  IOrderDetailForPayment,
  IPurchaseAirtimeDataRequest,
  IRecentAirtimedataPurchase,
} from '../../../models/prepaids.model';
import { ApiService } from '../../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class AirtimeDataService {
  constructor(private apiService: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiUrl;

  // Recent transactions
  public fetchRecent = (useMockApi = false): Observable<IRecentAirtimedataPurchase[]> =>
    this.apiService.get<IRecentAirtimedataPurchase[]>(endpoints.recent(this.getApiUrl(useMockApi)));

  // Providers
  public fetchProviders = (useMockApi = false): Observable<IAirtimeDataProvider[]> =>
    this.apiService.get<IAirtimeDataProvider[]>(endpoints.providers(this.getApiUrl(useMockApi)));

  // Send created
  public placeOrder = (
    createdOrder: IPurchaseAirtimeDataRequest,
    useMockApi = false,
  ): Observable<IOrderDetailForPayment> =>
    this.apiService.post<IPurchaseAirtimeDataRequest>(endpoints.placeOrder(this.getApiUrl(useMockApi)), createdOrder);
}
