import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import * as actions from './maintenance.actions';
import { CheckMaintenanceError } from './maintenance.actions';
import * as EVENTS from './maintenance.events';
import { ApplicationService } from '../../services/application/application.service';
import { ApplicationFacade } from '../application/application.facade';
import { failed, succeed } from './task.actions';

@Injectable()
export class MaintenanceEffects {
  checkMaintenanceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.CheckMaintenanceRequest>(EVENTS.MAINTENANCE_CHECK_MAINTENANCE_REQUEST),
      mergeMap(({ taskId }) =>
        this.service.checkMaintenance().pipe(
          map(data => succeed(new actions.CheckMaintenanceReceived(taskId, data))),
          catchError(() => of(failed(new CheckMaintenanceError(taskId)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: ApplicationService) {}
}
