import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe as LegacyCurrencyPipe } from '@angular/common';

@Pipe({
  name: 'avoCurrency',
})
export class AvoCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: LegacyCurrencyPipe) {}

  transform(value: number | string, inputCountryCode: string): string {
    const currencyCode = inputCountryCode === 'NAD' ? 'N$' : 'R';
    return this.currencyPipe.transform(value, currencyCode);
  }
}
