import { Injectable } from '@angular/core';
import { parseApiResponse } from '../utilities/adobe.utility';
import { IAdobeAvoInfoDto } from '../model/dto.model';
import { AdobeCore } from '../adobe-core';
import {
  ENTRY_POINT_ACTION,
  EVENT,
  IAdobeCartFulfillment,
  IAdobeCartItem,
  IAdobeCartStore,
  IAdobeCartTransformedItem,
  IAdobeCustomerPersonalDetailsData,
  IAdobeDigitalData,
  IAdobeEntryPoint,
  IAdobeEventClickData,
  IAdobeEventErrorData,
  IAdobeEventErrorDetails,
  IAdobeEventFormData,
  IAdobeEventLoadData,
  IAdobeOrderDetails,
  IAdobePushNotification,
  IAdobeShoppingCartData,
  IEventOptions,
  SECTION,
  SUBSECTION,
  TARGET_FUNCTION,
  ERROR_CODE,
} from '../model/analytics.model';
import { AdobeCartVertical, AdobeSearchVertical } from '../model/target.model';
import { IGroceriesCartResponse } from '../model/groceries.model';

@Injectable({
  providedIn: 'root',
})
/*
  Adobe analytics works with Adobe script to send analytics data how users interact with the web.
  Every env has its own script url (see adobeAnalyticsUrl attribute in environment.ts).

  There are various events which send different analytics data (always defined in specifications).
  All events eventually fire _satellite.track() which is called in AdobeCore.
*/
export class AdobeAnalyticsService extends AdobeCore {
  readonly EVENT = EVENT;
  readonly SECTION = SECTION;
  readonly SUBSECTION = SUBSECTION;
  readonly ERROR_CODE = ERROR_CODE;
  readonly ENTRY_POINT_ACTION = ENTRY_POINT_ACTION;

  /** start: PUBLIC OPTIONS API */

  setIsLoggedIn(value: boolean): void {
    this.isLoggedIn = value;
  }

  setAuthUserInfo(value: IAdobeAvoInfoDto): void {
    this.authUserInfo = value;
  }

  setCountryCode(value: string): void {
    this.countryCode = value;
  }

  setCmpId(value: string): void {
    this.cmpId = value;
  }

  /** end: PUBLIC OPTIONS API */

  setEntryPoint = (entryPoint?: IAdobeEntryPoint) => {
    this.entryPoint = entryPoint;
  };

  getEntryPoint = (): IAdobeEntryPoint => {
    return this.entryPoint;
  };

  // -------------------------- Fundamental events --------------------------
  /**
   * The most used Adobe analytics event (together with eventClick).
   * Tracks what page has been loaded.
   *
   * @param options - specifies details about loaded page - always defined in specifications.
   */
  eventLoad = (options: IEventOptions, attempt = 0): void => {
    if (!this.countryCode && attempt < 10) {
      setTimeout(() => this.eventLoad(options, attempt + 1), 750);
      return;
    }
    const previousPage: string = this.activePage?.pageInfo?.pageName || '';
    const previousSiteSection: string = this.activePage?.pageInfo?.siteSection || '';

    let resolvedEntryPoint: IAdobeEntryPoint =
      options?.siteSection === previousSiteSection || options?.siteSection === SECTION.SYSTEM_RESPONSES
        ? {
            action: this.activePage?.pageInfo?.entryPointAction,
            value: this.activePage?.pageInfo?.entryPointValue,
          }
        : undefined;
    // Utilize previously set entry point, and clear it from memory
    if (this.entryPoint?.action) {
      resolvedEntryPoint = { ...this.entryPoint }; // spread so that object is cloned
      this.setEntryPoint();
    }

    if (options.viewName === previousPage) return;

    this.adobeTarget(TARGET_FUNCTION.TRIGGER_VIEW)(options.viewName);

    const satteliteTrackEventLoadData: IAdobeEventLoadData = {
      page: {
        pageInfo: {
          pageName: options.viewName,
          previousPage,
          siteSection: options?.siteSection,
          subSiteSection: options?.subSiteSection,
          entryPointAction: resolvedEntryPoint?.action || ENTRY_POINT_ACTION.DIRECT,
          entryPointValue: resolvedEntryPoint?.value,
        },
        user: {
          id: this.authUserInfo?.id,
          loginState: this.isLoggedIn ? 'authenticated' : 'non-authenticated',
          sessionId: this.window['sessionId'] || undefined,
          walletCreated: this.authUserInfo?.walletCreated,
          loginCreated: this.authUserInfo?.loginCreated,
          linkedToBank: this.authUserInfo?.linkedToBank,
        },
        techDetail: {
          countryCode: this.countryCode,
          userPlatform: 'PWA',
          // always raise version by 1 with every code change in all adobe services
          serviceVersion: '3.36',
        },
      },
      cmpid: this.cmpId,
      ...options?.additionalObject,
    };
    this.activePage = satteliteTrackEventLoadData.page;
    this.satelliteTrack(EVENT.COMMON_PAGE_LOAD, satteliteTrackEventLoadData);
  };

  /**
   * The most used Adobe analytics event (together with eventLoad).
   * Tracks what button has been clicked.
   *
   * @param linkName - the button label
   */
  eventClick = (linkName: string, assetAction = 'click', options?: any): void => {
    if (!linkName) return;
    const satteliteTrackEventClickData: IAdobeEventClickData = {
      page: this.activePage,
      link: {
        linkName,
        assetAction,
        ...options,
      },
    };
    this.satelliteTrack(EVENT.LINK_CLICK, satteliteTrackEventClickData);
  };

  /*
    Tracks every error that occurs in the application.
    Called by error.interceptor.ts
  */
  eventError = (errorType: string, errorDetails?: IAdobeEventErrorDetails): void => {
    if (!errorType) return;
    if (!this.activePage.user.sessionId)
      this.activePage = { ...this.activePage, user: { ...this.activePage.user, sessionId: this.window['sessionId'] } };
    const satteliteTrackEventErrorData: IAdobeEventErrorData = {
      page: this.activePage,
      link: {
        errorType,
        details: errorDetails,
      },
    };
    this.satelliteTrack(EVENT.ERROR, satteliteTrackEventErrorData);
  };

  // -------------------------- Ecommerce cart --------------------------
  getCartItemsDataLayer = (
    vertical: AdobeSearchVertical | AdobeCartVertical,
    cartFulfillments: IAdobeCartFulfillment[],
  ): IAdobeCartTransformedItem[] => {
    const transformStoreToItems = (store: IAdobeCartStore): IAdobeCartTransformedItem[] => {
      const itemPriceIsMultiplied =
        store?.items?.reduce((total, item) => total + item?.price, 0) === store?.totalAmount;
      return store?.items?.map(product => {
        let amount = product.price;
        if (itemPriceIsMultiplied) amount = amount / product.quantity;
        return {
          productInfo: {
            productId: product.sku,
            productSku: product.adobeSku || product.sku,
            productName: product.title,
            storeName: vertical === AdobeCartVertical.VOUCHERS ? product.title : store.label,
            brand: vertical === AdobeCartVertical.VOUCHERS ? product.title : store.label,
            amount,
            totalAmount: amount * (product.quantity || 1),
            quantity: product.quantity,
            vertical: vertical,
          },
        };
      });
    };
    return cartFulfillments.flatMap(fulfillment => fulfillment.stores.flatMap(transformStoreToItems));
  };

  shoppingCartAdd = (
    productData: IAdobeCartItem,
    vertical: AdobeSearchVertical | AdobeCartVertical,
    apiResponse: string,
  ): void => {
    const satteliteTrackShoppingCartAddData = {
      page: this.activePage,
      cart: {
        item: this.getProductDetailsDataLayer(productData, vertical),
      },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.SHOPPING_CART_ADD, satteliteTrackShoppingCartAddData);
  };

  shoppingCartProductIncrease = (productData: IAdobeCartItem, vertical: AdobeCartVertical, apiResponse: any): void => {
    const satteliteTrackShoppingCartProductIncreaseData: IAdobeShoppingCartData = {
      page: this.activePage,
      cart: {
        item: this.getProductDetailsDataLayer(productData, vertical),
      },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.SHOPPING_CART_PRODUCT_INCREASE, satteliteTrackShoppingCartProductIncreaseData);
  };

  shoppingCartProductDecrease = (
    apiResponse: any,
    isProductRemove = false,
    productData?: IAdobeCartItem,
    vertical?: AdobeCartVertical,
  ): void => {
    const satteliteTrackShoppingCartProductDecreaseData: IAdobeShoppingCartData = {
      page: this.activePage,
      cart: {
        item: productData || vertical ? this.getProductDetailsDataLayer(productData, vertical) : undefined,
      },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(
      isProductRemove ? EVENT.SHOPPING_CART_REMOVE : EVENT.SHOPPING_CART_PRODUCT_DECREASE,
      satteliteTrackShoppingCartProductDecreaseData,
    );
  };

  cartCheckoutClick = (
    vertical: AdobeCartVertical,
    cartFulfilments: IAdobeCartFulfillment[],
    promoCodes: string[],
    apiResponse: string,
  ): void => {
    const satteliteTrackShoppingCartCheckoutClickData: IAdobeShoppingCartData = {
      page: this.activePage,
      promocode: promoCodes.join(','),
      cart: { item: this.getCartItemsDataLayer(vertical, cartFulfilments) },
      apiResponse: { apiResponse: apiResponse },
    };
    this.satelliteTrack(EVENT.CART_CHECKOUT_CLICK, satteliteTrackShoppingCartCheckoutClickData);
  };

  addPromoCode = (promoCode: string, apiResponse: any): void => {
    const satteliteTrackAddPromoCodeData: IAdobeShoppingCartData = {
      promocode: promoCode,
      cart: undefined,
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.CART_APPLY_PROMO_CODE, satteliteTrackAddPromoCodeData);
  };

  orderConfirmation = (orderData: IAdobeOrderDetails[]): void => {
    const digitalData: IAdobeDigitalData = window['digitalData'];
    const satteliteTrackOrderConfirmationData: IAdobeDigitalData = {
      ...digitalData,
      order: orderData,
    };
    this.satelliteTrack(EVENT.ORDER_CONFIRMATION, satteliteTrackOrderConfirmationData);
  };

  paymentSuccess = (): void => {
    const satteliteTrackPaymentSuccessData: IAdobeDigitalData = window['digitalData'];
    this.satelliteTrack(EVENT.PAYMENT_SUCCESS_3, satteliteTrackPaymentSuccessData);
  };

  buyNow = (): void => {
    const satelliteTrackBuyNowData: IAdobeShoppingCartData = {
      page: this.activePage,
    };
    this.satelliteTrack(EVENT.BUY_NOW, satelliteTrackBuyNowData);
  };

  // -------------------------- Profile --------------------------
  customerProfile = (): void => this.satelliteTrack(EVENT.CUSTOMER_PROFILE, {});

  profileUpdateSuccess = (): void => this.satelliteTrack(EVENT.PROFILE_UPDATE_SUCCESS, {});

  addressUpdateSuccess = (): void => this.satelliteTrack(EVENT.ADDRESS_UPDATE_SUCCESS, {});

  pushNotificationClicked = (pn: IAdobePushNotification): void =>
    this.satelliteTrack(EVENT.PUSH_NOTIFICATION_CLICKED, pn);

  customerPersonalDetails = (eventName: string): void => {
    const satteliteTrackCustomerPersonalDetailsData: IAdobeCustomerPersonalDetailsData = { event: { eventName } };
    this.satelliteTrack(EVENT.CUSTOMER_PERSONAL_DETAILS, satteliteTrackCustomerPersonalDetailsData);
  };

  // -------------------------- Other --------------------------
  eventForm = (eventType: EVENT, formName?: string): void => {
    const satelliteTrackEventFormData: IAdobeEventFormData = { formName, componentEvent: {} };
    this.satelliteTrack(eventType, satelliteTrackEventFormData);
  };

  productView = (): void => {
    this.satelliteTrack(EVENT.PRODUCT_VIEW);
  };

  /* migrated from avo2 */

  getCartItemsDataLayerAvo2Groceries = cart => {
    const transformStoreToItems = store =>
      store?.items?.map(product => {
        const amount =
          product.pricePerUnit?.withTax?.amount ||
          product.pricing?.price?.amount ||
          product.price?.amount ||
          product.price ||
          product.amount?.amount ||
          product.amount;
        return {
          productInfo: {
            productId: product.sku,
            productSku: product.adobeSku || product.sku,
            productName: product.name || product.title,
            storeName: product.sellerName || product.seller?.sellerRealName || store.label || store.storeName,
            brand: product.sellerName || product.seller?.name || store.storeName,
            amount,
            totalAmount: amount * (product.quantity || 1),
            quantity: product.quantity,
            vertical: product.vertical || cart.vertical,
          },
        };
      });
    const fulfillments = cart?.fulfillments || cart?.fulfilments; // TODO: should use one variant across hundreds of files...
    return fulfillments?.flatMap(fulfillment => {
      if (fulfillment?.malls) return fulfillment?.malls?.flatMap(mall => mall?.stores?.flatMap(transformStoreToItems));
      if (fulfillment?.stores) return fulfillment?.stores?.flatMap(transformStoreToItems);
      return transformStoreToItems(fulfillment);
    });
  };

  cartDeliveryAddressAvo2Groceries = (cart: IGroceriesCartResponse, apiResponse: any): void => {
    const object = {
      promocode: cart.promos ? cart.promos.map(promo => promo.code).join(',') : undefined,
      cart: { item: this.getCartItemsDataLayerAvo2Groceries({ ...cart, vertical: 'GROCERIES' }) },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.CART_DELIVERY_ADDRESS, object);
  };

  cartCheckoutClickAvo2Groceries = (cart: IGroceriesCartResponse, apiResponse: any): void => {
    const object = {
      promocode: cart.promos ? cart.promos.map(promo => promo.code).join(',') : undefined,
      cart: { item: this.getCartItemsDataLayerAvo2Groceries({ ...cart, vertical: 'GROCERIES' }) },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.CART_CHECKOUT_CLICK, object);
  };
}
