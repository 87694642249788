import { IAmount, ITaxPrice } from './common.model';

export interface IRecentAirtimedataPurchase {
  id: string;
  imageUrl: string;
  recipientId?: string;
  recipientName?: string;
  recipientPhone: string;
  type: AirtimedataType;
  date: string;
  amount: IAmount;
  bundleId?: string;
  bundleName?: string;
  providerId: string;
  providerImageUrl: string;
  description?: string;
  sku?: string;
}

export enum AirtimedataType {
  AIRTIME = 'AIRTIME',
  DATA = 'DATA',
}

export enum AirtimedataBundleType {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
}

export interface IAirtimeDataProvider {
  id: string;
  imageUrl: string;
  name: string;
  airtimeEnableCustomAmount?: boolean;
  dataEnableCustomAmount?: boolean;
  airtimeBundles?: IAirtimeDataBundle[];
  airtimeCustomBundle?: IVasCustomBundle;
  dataBundles?: IAirtimeDataBundle[];
  dataCustomBundle?: IVasCustomBundle;
}

export interface IAirtimeDataBundle {
  name: string;
  id?: string;
  amount?: IAmount;
}

export interface IPurchaseAirtimeDataCurrentOrder {
  recipientId?: string;
  recipientName?: string;
  recipientPhone?: string;
  provider?: IAirtimeDataProvider;
  type?: AirtimedataType;
  bundle?: IAirtimeDataBundle;
  amount?: IAmount;
  description?: string;
  usedPoints?: number;
  sku?: string;
  bundleType?: AirtimedataBundleType;
  customAmountName?: string;
  customBundleName?: string;
}

export interface IPurchaseAirtimeDataRequest {
  sku: string;
  phoneNumber: string;
  amount: IAmount;
  recipientName?: string;
  description?: string;
}

export interface IPurchaseAirtimeDataResponse {
  providerImage?: string;
  providerName?: string;
  amount: IAmount;
  bundleName: string;
  recipientName?: AirtimedataType;
  recipientNumber?: string;
  points?: number;
  orderId?: string;
}

export interface INumberOrNameData {
  recipientEnetered: string;
}

export interface IRecentElectricityClaimResponse {
  meterNumber?: string;
  recipientName: string;
  recipientPhone?: string;
  dateTime: string;
  purchaseFree?: boolean;
  amount: IAmount;
  description?: string;
}

export interface IClaimElectricityRequest {
  meterNumber: string;
  amount: IAmount;
  phoneNumber: string;
  sku?: string;
  description?: string;
  recipientName?: string;
  usedPoints?: number;
}

export interface IOrderDetailForPayment {
  orderId: string;
  expectedDelivery: string;
  successMessage: string;
  paymentId: string;
  cta: IOrderDetailCta;
  giftCart: boolean;
  giftCartData: IGiftCartData;
  points: number;
  totalPoints: number;
  feesTotal: ITaxPrice;
  itemsTotal: ITaxPrice;
  shippingTotal: ITaxPrice;
  total: ITaxPrice;
}

export interface IMeterNumberValidateRequest {
  meterNumber: string;
}

export interface IGiftCartData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  message: string;
}

export interface IOrderDetailCta {
  text: string;
  link: string;
}

export interface IVasCustomBundle {
  minAmount: IAmount;
  maxAmount: IAmount;
  bundleSku: string;
}
