export enum EVENT {
  RECENT_REQUEST = 'PREPAID/ELECTRICITY/RECENT/REQUEST',
  RECENT_RECEIVED = 'PREPAID/ELECTRICITY/RECENT/RECEIVED',
  VALIDATE_METER_REQUEST = 'PREPAID/ELECTRICITY/VALIDATE-METER/REQUEST',
  VALIDATE_METER_RECEIVED = 'PREPAID/ELECTRICITY/VALIDATE-METER/RECEIVED',
  PLACE_ORDER_REQUEST = 'PREPAID/ELECTRICITY/PLACE-ORDER/REQUEST',
  PLACE_ORDER_RECEIVED = 'PREPAID/ELECTRICITY/PLACE-ORDER/RECEIVED',
  SAVE_TO_STORE = 'PREPAID/ELECTRICITY/SAVE',
  RETRIEVE_FROM_STORE = 'PREPAID/ELECTRICITY/RETRIEVE',
  CHECK_USER_REQUEST = 'PREPAID/ELECTRICITY/CHECK_USER/REQUEST',
  CHECK_USER_RESPONSE = 'PREPAID/ELECTRICITY/CHECK_USER/RESPONSE',
  PHONEBOOK_REQUEST = 'PREPAID/ELECTRICITY/PHONEBOOK/REQUEST',
  PHONEBOOK_RESPONSE = 'PREPAID/ELECTRICITY/PHONEBOOK/RESPONSE',
  CLEAN = 'CLEAN',
  FAILED = 'FAILED',
}
