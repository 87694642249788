export const BUYER_CART_ADD_CART_ITEM_REQUEST = 'SHOPPING/BUYER-CART-ADD-CART-ITEM/REQUEST';
export const BUYER_CART_ADD_CART_ITEM_RECEIVED = 'SHOPPING/BUYER-CART-ADD-CART-ITEM/RECEIVED';

export const BUYER_CART_SUMMARY_REQUEST = 'SHOPPING/BUYER-CART-SUMMARY/REQUEST';
export const BUYER_CART_SUMMARY_RECEIVED = 'SHOPPING/BUYER-CART-SUMMARY/RECEIVED';

export const BUYER_CARTS_REQUEST = 'SHOPPING/BUYER-CARTS/REQUEST';
export const BUYER_CARTS_RECEIVED = 'SHOPPING/BUYER-CARTS/RECEIVED';

export const BUYER_CART_DETAIL_REQUEST = 'SHOPPING/BUYER-CART-DETAIL/REQUEST';
export const BUYER_CART_DETAIL_RECEIVED = 'SHOPPING/BUYER-CART-DETAIL/RECEIVED';

export const BUYER_CART_ITEM_QUANTITY_REQUEST = 'SHOPPING/BUYER-CART-ITEM-QUANTITY/REQUEST';
export const BUYER_CART_ITEM_QUANTITY_RECEIVED = 'SHOPPING/BUYER-CART-ITEM-QUANTITY/RECEIVED';

export const BUYER_CART_DELIVERY_ADDRESS_REQUEST = 'SHOPPING/BUYER-CART-DELIVERY-ADDRESS/REQUEST';
export const BUYER_CART_DELIVERY_ADDRESS_RECEIVED = 'SHOPPING/BUYER-CART-DELIVERY-ADDRESS/RECEIVED';

export const BUYER_CART_DELIVERY_OPTION_REQUEST = 'SHOPPING/BUYER-CART-DELIVERY-OPTION/REQUEST';
export const BUYER_CART_DELIVERY_OPTION_RECEIVED = 'SHOPPING/BUYER-CART-DELIVERY-OPTION/RECEIVED';

export const BUYER_PAY_CART_REQUEST = 'SHOPPING/BUYER-PAY-CART/REQUEST';
export const BUYER_PAY_CART_RECEIVED = 'SHOPPING/BUYER-PAY-CART/RECEIVED';

export const BUY_CART_CONTENT_REQUEST = 'SHOPPING/BUY-CART-CONTENT/REQUEST';
export const BUY_CART_CONTENT_RECEIVED = 'SHOPPING/BUY-CART-CONTENT/RECEIVED';

export const BUY_CART_DELETE_CART_ITEM_REQUEST = 'SHOPPING/BUY-CART-DELETE-CART-ITEM/REQUEST';
export const BUY_CART_DELETE_CART_ITEM_RECEIVED = 'SHOPPING/BUY-CART-DELETE-CART-ITEM/RECEIVED';

export const BUY_CART_UPDATE_CART_ITEM_REQUEST = 'SHOPPING/BUY-CART-UPDATE-CART-ITEM/REQUEST';
export const BUY_CART_UPDATE_CART_ITEM_RECEIVED = 'SHOPPING/BUY-CART-UPDATE-CART-ITEM/RECEIVED';

export const BUY_CART_SAVE_CART_ITEM_REQUEST = 'SHOPPING/BUY-CART-SAVE-CART-ITEM/REQUEST';
export const BUY_CART_SAVE_CART_ITEM_RECEIVED = 'SHOPPING/BUY-CART-SAVE-CART-ITEM/RECEIVED';

export const BUY_CART_UPDATE_SAVED_CART_ITEM_REQUEST = 'SHOPPING/BUY-CART-UPDATE-SAVED-CART-ITEM/REQUEST';
export const BUY_CART_UPDATE_SAVED_CART_ITEM_RECEIVED = 'SHOPPING/BUY-CART-UPDATE-SAVED-CART-ITEM/RECEIVED';
export const BUY_CART_UPDATE_SAVED_CART_ITEM_FAILED = 'SHOPPING/BUY-CART-UPDATE-SAVED-CART-ITEM/FAILED';

export const BUY_CART_DELETE_SAVED_CART_ITEM_REQUEST = 'SHOPPING/BUY-CART-DELETE-SAVED-CART-ITEM/REQUEST';
export const BUY_CART_DELETE_SAVED_CART_ITEM_RECEIVED = 'SHOPPING/BUY-CART-DELETE-SAVED-CART-ITEM/RECEIVED';

export const BUY_CART_GET_PROMO_REQUEST = 'SHOPPING/BUY-CART-GET-PROMO/REQUEST';
export const BUY_CART_GET_PROMO_RECEIVED = 'SHOPPING/BUY-CART-GET-PROMO/RECEIVED';

export const BUY_CART_APPLY_PROMO_REQUEST = 'SHOPPING/BUY-CART-APPLY-PROMO/REQUEST';
export const BUY_CART_APPLY_PROMO_RECEIVED = 'SHOPPING/BUY-CART-APPLY-PROMO/RECEIVED';

export const BUY_CART_REMOVE_PROMO_REQUEST = 'SHOPPING/BUY-CART-REMOVE-PROMO/REQUEST';
export const BUY_CART_REMOVE_PROMO_RECEIVED = 'SHOPPING/BUY-CART-REMOVE-PROMO/RECEIVED';

export const BUY_CART_GET_PICKUP_LOCATION_REQUEST = 'SHOPPING/BUY-CART-GET-PICKUP-LOCATIONS/REQUEST';
export const BUY_CART_GET_PICKUP_LOCATION_RECEIVED = 'SHOPPING/BUY-CART-GET-PICKUP-LOCATIONS/RECEIVED';

export const BUY_CART_GET_DELIVER_LOCATION_REQUEST = 'SHOPPING/BUY-CART-GET-DELIVERY-LOCATIONS/REQUEST';
export const BUY_CART_GET_DELIVER_LOCATION_RECEIVED = 'SHOPPING/BUY-CART-GET-DELIVERY-LOCATIONS/RECEIVED';
export const BUY_CART_GET_DELIVER_LOCATION_FAILED = 'SHOPPING/BUY-CART-GET-DELIVERY-LOCATIONS/FAILED';

export const BUY_CART_DELIVERY_INFO_REQUEST = 'SHOPPING/BUY-CART-DELIVERY-INFO/REQUEST';
export const BUY_CART_DELIVERY_INFO_RECEIVED = 'SHOPPING/BUY-CART-DELIVERY-INFO/RECEIVED';

export const BUY_CART_RESERVE_INFO_REQUEST = 'SHOPPING/BUY_CART_RESERVE_INFO/REQUEST';
export const BUY_CART_RESERVE_INFO_RECEIVED = 'SHOPPING/BUY_CART_RESERVE_INFO/RECEIVED';

export const BUY_CART_MOVE_CART_ITEM_REQUEST = 'SHOPPING/BUY-CART-MOVE-CART-ITEM/REQUEST';
export const BUY_CART_MOVE_CART_ITEM_RECEIVED = 'SHOPPING/BUY-CART-MOVE-CART-ITEM/RECEIVED';

export const BUY_CART_RETURN_POLICY_INFO_REQUEST = 'SHOPPING/RETURN_POLICY_INFO/REQUEST';
export const BUY_CART_RETURN_POLICY_INFO_RECEIVED = 'SHOPPING/RETURN_POLICY_INFO/RECEIVED';

export const BUY_CART_TERMS_AND_CONDITIONS_INFO_REQUEST = 'SHOPPING/TERMS_AND_CONDITIONS_INFO/REQUEST';
export const BUY_CART_TERMS_AND_CONDITIONS_INFO_RECEIVED = 'SHOPPING/TERMS_AND_CONDITIONS_INFO/RECEIVED';

export const BUY_CART_PAYMENT_RESERVATION_INFO_REQUEST = 'SHOPPING/PAYMENT_RESERVATION_INFO/REQUEST';
export const BUY_CART_PAYMENT_RESERVATION_INFO_RECEIVED = 'SHOPPING/PAYMENT_RESERVATION_INFO/RECEIVED';

export const BUY_CART_CREATE_ORDER_REQUEST = 'SHOPPING/CREATE_ORDER/REQUEST';
export const BUY_CART_CREATE_ORDER_RECEIVED = 'SHOPPING/CREATE_ORDER/RECEIVED';
export const BUY_CART_CREATE_ORDER_FAILED = 'SHOPPING/CREATE_ORDER/FAILED';

export const BUY_CART_ORDER_INFO_REQUEST = 'SHOPPING/ORDER_INFO/REQUEST';
export const BUY_CART_ORDER_INFO_RECEIVED = 'SHOPPING/ORDER_INFO/RECEIVED';

export const BUY_CART_ORDER_DETAIL_REQUEST = 'SHOPPING/ORDER_DETAIL/REQUEST';
export const BUY_CART_ORDER_DETAIL_RECEIVED = 'SHOPPING/ORDER_DETAIL/RECEIVED';

export const BUY_CART_PAYMENT_INFO_REQUEST = 'SHOPPING/PAYMENT_INFO/REQUEST';
export const BUY_CART_PAYMENT_INFO_RECEIVED = 'SHOPPING/PAYMENT_INFO/RECEIVED';

export const BUY_CART_PAY_BY_WALLET_REQUEST = 'SHOPPING/PAY_BY_WALLET/REQUEST';
export const BUY_CART_PAY_BY_WALLET_RECEIVED = 'SHOPPING/PAY_BY_WALLET/RECEIVED';

export const BUY_CART_PAY_BY_CARD_REQUEST = 'SHOPPING/PAY_BY_CARD/REQUEST';
export const BUY_CART_PAY_BY_CARD_RECEIVED = 'SHOPPING/PAY_BY_CARD/RECEIVED';

export const BUY_CART_CLEAN = 'SHOPPING/BUY-CART-CLEAN';
export const BUY_CART_SET_BILLING_ADDRESS = 'SHOPPING/BUY-CART-SET-BILLING-ADDRESS';

export const BUY_CART_DELIVERY_OPTIONS_REQUEST = 'SHOPPING/BUY-CART-DELIVERY-OPTIONS/REQUEST';
export const BUY_CART_DELIVERY_OPTIONS_RECEIVED = 'SHOPPING/BUY-CART-DELIVERY-OPTIONS/RECEIVED';

export const BUY_CART_UPDATE_DELIVERY_OPTIONS_REQUEST = 'SHOPPING/BUY-CART/DELIVERY-OPTION/REQUEST';
export const BUY_CART_UPDATE_DELIVERY_OPTIONS_RECEIVED = 'SHOPPING/BUY-CART/DELIVERY-OPTION/RECEIVED';

export const BUY_CART_SAVED_ITEMS_REQUEST = 'SHOPPING/BUY-CART/SAVED_ITEMS/REQUEST';
export const BUY_CART_SAVED_ITEMS_RECEIVED = 'SHOPPING/BUY-CART/SAVED_ITEMS/RECEIVED';

export const BUY_CART_UPDATE_CART_GIFT_REQUEST = 'SHOPPING/BUY-CART/UPDATE_CART_GIFT/REQUEST';
export const BUY_CART_UPDATE_CART_GIFT_RECEIVED = 'SHOPPING/BUY-CART/UPDATE_CART_GIFT/RECEIVED';

export const TV_LICENCE_CHECK_REQUEST = 'SHOPPING/TV-LICENCE-CHECK/REQUEST';
export const TV_LICENCE_CHECK_SUCCEEDED = 'SHOPPING/TV-LICENCE-CHECK/SUCCEEDED';

export const UPLOAD_ASSET_REQUEST = 'SHOPPING/UPLOAD-ASSET/REQUEST';
export const UPLOAD_ASSET_SUCCEEDED = 'SHOPPING/UPLOAD-ASSET/SUCCEEDED';

export const ADD_TV_LICENCE_PHOTO_ID_REQUEST = 'SHOPPING/AD-TV-LICENCE-PHOTO-ID/REQUEST';
export const ADD_TV_LICENCE_PHOTO_ID_SUCCEEDED = 'SHOPPING/AD-TV-LICENCE-PHOTO-ID/SUCCEEDED';

export const BUY_CART_SET_TERM_IN_MONTHS_REQUEST = 'SHOPPING/BUY-CART-SET_TERM_IN_MONTHS/REQUEST';
export const BUY_CART_SET_TERM_IN_MONTHS_RECEIVED = 'SHOPPING/BUY-CART-SET_TERM_IN_MONTHS/RECEIVED';

export const BUY_CART_CLEAR_REQUEST = 'SHOPPING/BUY-CART/CLEAR/REQUEST';
export const BUY_CART_CLEAR_RECEIVED = 'SHOPPING/BUY-CART/CLEAR/RECEIVED';
