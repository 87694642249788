import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-progress-bar',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent {
  @Input() percentage: number;
  @Input() width: string;
  @Input() withLabels: boolean;
  @Input() animated: true;
  @Input() animationDuration: number;

  readonly MAX_ANIMATION_DURATION = '120s';
}
