import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HashStringPrefix, ICommonOnChangeOutput } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-radio',
  template: `
    <div class="ui radio" [class.standalone]="standalone" (click)="handleChange()">
      <span class="wrapper" [ngClass]="{ checked: !!checked }">
        <ui-icon
          [attr.id]="id | hashString: hashType.RADIO"
          [icon]="!!checked ? 'circle_picked' : 'circle'"
          width="1.125rem"
          height="1.125rem"
        ></ui-icon>
      </span>
      <label *ngIf="label" class="ui radio-label">
        {{ label }}
      </label>
      <ng-content *ngIf="custom"></ng-content>
    </div>
  `,
})
export class RadioComponent {
  hashType = HashStringPrefix;
  @Input() id: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() name: string;
  @Input() standalone = false;

  /** Use provided content projection for label */
  @Input() custom: boolean;

  @Output() onChange = new EventEmitter<ICommonOnChangeOutput>();

  handleChange = (): void => {
    if (this.disabled) return;
    this.onChange.emit({ name: this.name, value: !this.checked });
  };
}
