import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAmount } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-available-balance',
  template: `
    <ng-container *ngIf="data && !renderAs">
      <div
        (click)="onClick.emit()"
        class="ui available-balance"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <b
          >{{ data | availableBalance: 'currencyCode' }} {{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}</b
        >
      </div>
    </ng-container>

    <ng-container *ngIf="data && renderAs === 'small'">
      <div
        (click)="onClick.emit()"
        class="ui available-balance small"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <b class="small"
          >{{ data | availableBalance: 'currencyCode' }} {{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}</b
        >
      </div>
    </ng-container>

    <ng-container *ngIf="data && renderAs === 'smallLight'">
      <div
        (click)="onClick.emit()"
        class="ui available-balance small-light"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <b class="small light"
          >{{ data | availableBalance: 'currencyCode' }} {{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}</b
        >
      </div>
    </ng-container>

    <ng-container *ngIf="data && renderAs === 'p'">
      <div
        (click)="onClick.emit()"
        class="ui available-balance"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <p>
          {{ data | availableBalance: 'currencyCode' }} {{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="data && renderAs === 'pSmall'">
      <div
        (click)="onClick.emit()"
        class="ui available-balance small"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <p class="small">
          {{ data | availableBalance: 'currencyCode' }} {{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="data && renderAs === 'pSmallLight'">
      <div
        (click)="onClick.emit()"
        class="ui available-balance small-light"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <p class="small light">
          {{ data | availableBalance: 'currencyCode' }} {{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="data && renderAs === 'h1'">
      <div
        (click)="onClick.emit()"
        class="ui available-balance h1"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <ng-container *ngIf="!!smallDecimal">
          <h1 *ngIf="data?.currencySymbol">{{ data | availableBalance: 'currencySymbol' }}</h1>
          <h1 *ngIf="data?.currencyCode">{{ data | availableBalance: 'currencyCode' }}</h1>
          <h1>{{ data | availableBalance: 'integer' }}</h1>
          <h1 class="smallDecimal">{{ data | availableBalance: 'decimal' }}</h1>
        </ng-container>
        <h1 *ngIf="!smallDecimal">
          {{ data | availableBalance: 'currencyCode' }}{{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}
        </h1>
      </div>
    </ng-container>

    <ng-container *ngIf="data && renderAs === 'h2'">
      <div
        (click)="onClick.emit()"
        class="ui available-balance h2"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <h2>
          {{ data | availableBalance: 'currencyCode' }} {{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}
        </h2>
      </div>
    </ng-container>

    <ng-container *ngIf="data && renderAs === 'h3'">
      <div
        (click)="onClick.emit()"
        class="ui available-balance h3"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <h3>
          {{ data | availableBalance: 'currencyCode' }} {{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}
        </h3>
      </div>
    </ng-container>

    <ng-container *ngIf="data && renderAs === 'h4'">
      <div
        (click)="onClick.emit()"
        class="ui available-balance h4"
        [ngClass]="{
          positive: !!isPositive,
          negative: !!isNegative,
          inverted: !!inverted,
          neutral: !!isNeutral,
          crossed: !!isCrossed,
          leftAlign: !!alignLeft,
          rightAlign: !!alignRight,
          centerAlign: !!alignCenter
        }"
      >
        <h4>
          {{ data | availableBalance: 'currencyCode' }} {{ data | availableBalance: 'integer'
          }}{{ data | availableBalance: 'decimal' }}
        </h4>
      </div>
    </ng-container>
    <ng-container *ngIf="!!label">
      <i style="display: block;">{{ label }}</i>
    </ng-container>
  `,
})
export class AvailableBalanceComponent {
  @Input() data: IAmount | number;
  @Input() renderAs: string;
  @Input() isPositive: boolean;
  @Input() isNegative: boolean;
  @Input() isNeutral: boolean;
  @Input() isCrossed: boolean;
  @Input() inverted: boolean;
  @Input() alignLeft: boolean;
  @Input() alignRight: boolean;
  @Input() alignCenter: boolean;
  @Input() smallDecimal: boolean;
  @Input() label: string;
  @Output() onClick = new EventEmitter();
}
