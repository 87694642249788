import { Component, Input } from '@angular/core';

export interface IStepProgressBarItems {
  id: string;
  description?: string;
  active?: boolean;
  pending?: boolean;
  cancelled?: boolean;
  checked?: boolean;
  sub?: string;
  selectButton?: boolean;
  buttonDisabled?: boolean;
  buttonContent?: string;
  buttonIcon?: string;
  buttonDropdown?: boolean;

  onButton?: () => void;
}

@Component({
  selector: 'ui-step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
})
export class StepProgressBarComponent {
  @Input() items: IStepProgressBarItems[];
  @Input() vertical: boolean;
}
