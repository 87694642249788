import { Action } from '@ngrx/store';
import { INotificationsResponse } from '../../models/notifications.model';
import { EVENTS } from './notifications.events';

// Fetching notifications
export class NotificationsRequestAction implements Action {
  readonly type = EVENTS.NOTIFICATIONS_REQUEST;
  constructor(public taskId: string, public page?: number, public size?: number) {}
}

export class NotificationsResponseAction implements Action {
  readonly type = EVENTS.NOTIFICATIONS_RECEIVED;
  constructor(public taskId: string, public data: INotificationsResponse[], public page?: number) {}
}

// Hide (dismiss) specified notification
export class HideNotificationRequestAction implements Action {
  readonly type = EVENTS.NOTIFICATIONS_HIDE_REQUEST;
  constructor(public taskId: string, public notificationId: string) {}
}

export class HideNotificationResponseAction implements Action {
  readonly type = EVENTS.NOTIFICATIONS_HIDE_RECEIVED;
  constructor(public taskId: string, public notificationId: string) {}
}

// Hide (dismiss) all notifications
export class HideAllNotificationsRequestAction implements Action {
  readonly type = EVENTS.NOTIFICATIONS_HIDE_ALL_REQUEST;
  constructor(public taskId: string) {}
}

export class HideAllNotificationsResponseAction implements Action {
  readonly type = EVENTS.NOTIFICATIONS_HIDE_ALL_RECEIVED;
  constructor(public taskId: string) {}
}
