import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../init/app.config';
import { ICountryCode } from '../model/core.model';
import { IDiscoverAvoCategory } from '../store/models/store.models';
import { IUiExitScreenDto } from '@avo/shared/ui/modal';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  constructor(private httpClient: HttpClient, private appConfig: AppConfig) {}

  fetchAppConfig = (): Observable<any> => this.httpClient.get<any>(`${this.appConfig.apiUrl}/profile/v1/app-config`);

  fetchCountryCode = (): Observable<ICountryCode> =>
    this.httpClient.get<any>(`${this.appConfig.apiUrl}/profile/v3/customer/current-country`);

  fetchDiscoverAvoCategories = () =>
    this.httpClient.get<IDiscoverAvoCategory[]>(`${this.appConfig.apiUrl}/content/discover-avo-categories`);

  fetchExitScreen = (screenId: string) =>
    this.httpClient.get<IUiExitScreenDto>(`${this.appConfig.apiUrl}/content/exit-screens/${screenId}`);
}
