import { CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvoCurrencyPipe } from './pipes/avo-currency.pipe';
import { CreditCurrencyPipe } from './pipes/credit-currency.pipe';

@NgModule({
  declarations: [AvoCurrencyPipe, CreditCurrencyPipe],
  providers: [AvoCurrencyPipe, CurrencyPipe],
  exports: [AvoCurrencyPipe, CreditCurrencyPipe],
  imports: [],
})
export class AvoCurrencyModule {}
