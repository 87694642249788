import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IChatRatingRequested, IMatrixMessage, IMessageEventRatingRequest } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-rating-request',
  template: `
    <div class="column">
      <b zen-translate>chat.rate.chat_message</b>
      <ui-divider [compact]="true"></ui-divider>
      <ui-button [content]="'chat.rate.chat_button' | translate" (onClick)="openRatingScreen()"> </ui-button>
    </div>
    <ui-divider></ui-divider>
  `,
})
export class ChatWidgetRatingRequestComponent {
  @Input() message: IMatrixMessage<IMessageEventRatingRequest>;
  @Output() onRating = new EventEmitter<IChatRatingRequested>();

  openRatingScreen(): void {
    this.onRating.emit({
      sendReasons: this.message?.content?.sendReasons,
      actionId: this.message?.content?.actionId,
      roomId: this.message?.room_id,
    });
  }
}
