import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WebcamImage } from 'ngx-webcam';
import { FilesUtil } from '@wakanda/wakanda-core';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-chat-file-upload',
  styleUrls: ['./chat-file-upload.component.scss'],
  template: `
    <ui-icon [icon]="'plus2'" size="'2rem'" (click)="toggleModal()"></ui-icon>

    <ui-modal
      [modal]="ModalType.SLIDE_IN_UP"
      [title]="'UPLOAD IMAGE'"
      [template]="uploadDialog"
      [closeButton]="true"
      [isVisible]="showUploadDialog"
      (onCloseClick)="handleUploadPhotoDialog()"
    >
      <ng-template #uploadDialog>
        <div class="text-align-left">
          <div class="upload-chat-wrapper">
            <div class="item" (click)="handleCamera()">
              <ui-icon icon="camera" size="2rem"></ui-icon>
              <b>CAMERA</b>
            </div>
            <input
              style="display: none"
              type="file"
              (change)="uploadFile($event)"
              [accept]="'image/png,image/jpeg'"
              #fileImageInput
            />
            <div *ngIf="showImage" class="item" (click)="fileImageInput.click()">
              <ui-icon icon="gallery" size="'2rem'"></ui-icon>
              <b>PHOTO</b>
            </div>
            <input
              style="display: none"
              type="file"
              (change)="uploadFile($event)"
              [accept]="'application/pdf'"
              #fileDocumentInput
            />
            <div *ngIf="showDocument" class="item" (click)="fileDocumentInput.click()">
              <ui-icon icon="document" size="'2rem'"></ui-icon>
              <b>DOCUMENT</b>
            </div>
          </div>
        </div>
      </ng-template>
    </ui-modal>

    <ui-modal
      [modal]="ModalType.SLIDE_IN_UP"
      [title]="'TAKE A PHOTO'"
      [template]="takeAPhoto"
      [closeButton]="true"
      [isVisible]="showCamera"
      (onCloseClick)="showCamera = false"
    >
      <ng-template #takeAPhoto>
        <div style="height: 400px; display: flex">
          <ui-camera (onChange)="uploadWebcamImage($event)"></ui-camera>
        </div>
      </ng-template>
    </ui-modal>
  `,
})
export class ChatFileUploadComponent {
  showUploadDialog = false;
  showCamera = false;

  @Input() modalTitle: string;
  @Input() showImage: boolean;
  @Input() showDocument: boolean;

  @Output() fileSelected = new EventEmitter();
  ModalType = ModalType;
  toggleModal(): void {
    this.showUploadDialog = !this.showUploadDialog;
  }

  handleUploadPhotoDialog = (): void => {
    this.showUploadDialog = !this.showUploadDialog;
  };

  handleCamera = (): void => {
    this.showCamera = !this.showCamera;
    this.showUploadDialog = false;
  };

  uploadWebcamImage(image: WebcamImage): void {
    const blob = FilesUtil.dataURItoBlob(image.imageAsDataUrl);

    this.showUploadDialog = false;
    this.showCamera = false;

    const payload = new FormData();
    payload.append('file', blob);

    this.fileSelected.emit({ data: payload, file: new File([], 'webcamImage.jpeg') });
  }

  uploadFile(event: any): void {
    const file = event.target.files[0];

    this.showUploadDialog = false;
    this.showCamera = false;

    const payload = new FormData();
    payload.append('file', file, file.name);

    this.fileSelected.emit({ data: payload, file });
  }
}
