import { AfterViewInit, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

type InViewType = 'onDemand' | 'always';

@Directive({
  selector: '[inView]',
})

/**
 * IS IN VIEW DIRECTIVE
 * --------------------
 * Mounts a component whenever it is visible to the user
 * Usage: <div *inView="'onDemand'">I'm on screen only if I am in viewport!</div>
 * Usage: <div *inView="'always'">I'm always on screen!</div>
 */
export class InViewDirective implements AfterViewInit {
  _inViewForce: InViewType;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('inView') set inView(data: InViewType) {
    this._inViewForce = data || 'onDemand';
  }
  constructor(private vcRef: ViewContainerRef, private tplRef: TemplateRef<any>) {}

  ngAfterViewInit() {
    const observedElement = this.vcRef.element.nativeElement.parentElement;

    const observer = new IntersectionObserver(([entry]) => {
      this.renderContents(entry.isIntersecting);
    });
    observer.observe(observedElement);
  }

  renderContents(isIntersecting: boolean) {
    this.vcRef.clear();

    if (isIntersecting || this._inViewForce === 'always') {
      this.vcRef.createEmbeddedView(this.tplRef);
    }
  }
}
