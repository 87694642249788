import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConfigFacade } from '../redux/app-config/app-config.facade';
import { AppConfig } from '../configs/app.config';

@Pipe({
  name: 'resizeImageUrl',
  pure: true,
})
export class ResizeImageUrlPipe implements PipeTransform, OnDestroy {
  subscriptions = new Subscription();
  resizableDomains: string[];

  constructor(private appConfigFacade: AppConfigFacade, private appConfig: AppConfig) {
    this.subscriptions.add(
      this.appConfigFacade.getResizableDomains$.subscribe(domains => (this.resizableDomains = domains)),
    );
  }

  transform(url: string): string {
    if (!url) {
      return null;
    }

    let imageSize = window.screen.width;
    const href = window.location.href;

    if (this.appConfig.appType === 'C') {
      // max width size image

      imageSize = 450;
      // display a 250 width image on these list pages urls
      if (
        (href.includes('shopping') && href.includes('list')) ||
        (href.includes('groceries') && href.includes('list')) ||
        (href.includes('takeaway') && !href.includes('restaurant'))
      ) {
        // list & catalog pages
        imageSize = 380;
      }
    }
    if (this.appConfig.appType === 'M') {
      imageSize = 380;
    }

    return this.transformImageUrl(url, imageSize);
  }

  transformImageUrl(url: string, imageSize: number): string {
    if (this.resizableDomains) {
      for (const domain of this.resizableDomains) {
        if (url.includes(domain) && !url.includes('cdn-cgi/image') && (url.includes('.jpg') || url.includes('.png'))) {
          const resizeModification = `${domain}/cdn-cgi/image/w=${imageSize}`;
          return url.replace(domain, resizeModification);
        }
      }
    }
    return url;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
