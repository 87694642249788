import { Pipe, PipeTransform } from '@angular/core';
import last from 'lodash-es/last';
import { IMatrixMessage } from '@wakanda/agent-chat-model';

@Pipe({ name: 'chatInputDisabled', pure: true })
export class ChatInputDisabledPipe implements PipeTransform {
  lockInput: boolean;
  transform(messages: IMatrixMessage[]): boolean {
    if (!messages?.length) return false;

    const lastMessage = last(messages);

    if (lastMessage.content?.displayWritingAfterReply) this.lockInput = true;

    if (this.lockInput && lastMessage.sender_self) {
      this.lockInput = false;
      return true;
    }

    return false;
  }
}
