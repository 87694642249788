import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { CommonForm, IForm } from '@wakanda/wakanda-core';
import {
  IZenAuthTcsItem,
  IZenAuthNextStepTransactionSigningResponse,
  IZenAuthTransactionSigningAuthorizationHeaders,
  IZenAuthTransactionSigningAuthorizeRequest,
  IZenAuthTcsDocumentExtended,
} from '@wakanda/zenauth';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-authorization-dialog-tcs',
  template: `
    <ui-modal
      [modal]="modalTypes.SLIDE_IN_UP"
      [title]="'outstanding_tcs.dialog_title' | translate"
      [isVisible]="true"
      [template]="authorizationDialogTemplate"
    >
      <ng-template #authorizationDialogTemplate>
        <ui-icon icon="terms" size="5.5rem"></ui-icon>
        <ui-divider></ui-divider>
        <h2 zen-translate>outstanding_tcs.title</h2>
        <ui-divider [compact]="true"></ui-divider>
        <p class="small light" zen-translate>outstanding_tcs.message</p>

        <ui-divider></ui-divider>
        <ng-container *ngFor="let doc of documents">
          <ui-divider [compact]="true"></ui-divider>
          <ui-checkbox
            [checked]="getFormValue(doc.name)"
            [rounded]="true"
            [name]="doc.name"
            [label]="doc.checkboxLabel | translate"
            [link]="doc.label | translate"
            (onChange)="setFormValue($event.name, $event.value)"
            (onLinkClick)="onLinkClick(doc.url)"
          ></ui-checkbox>
        </ng-container>

        <ui-divider></ui-divider>

        <ui-button
          aligned="bottom"
          [callToActionButton]="true"
          [disabled]="!isFormValid()"
          [selectButton]="true"
          [content]="'outstanding_tcs.cta_button' | translate"
          (onClick)="onConfirmTcs()"
        ></ui-button>
        <ui-divider [compact]="true"></ui-divider>
        <a class="text-align-center" (click)="onCancel.emit()">{{ 'general.cancel' | translate }}</a>
      </ng-template>
    </ui-modal>
  `,
})
export class AuthorizationDialogTcsComponent extends CommonForm implements OnInit {
  @Input() nextStep: IZenAuthNextStepTransactionSigningResponse;
  @Input() transactionAuthId: string;
  @Output() onCancel = new EventEmitter();
  @Output() onConfirm = new EventEmitter<IZenAuthTransactionSigningAuthorizeRequest>();
  @Output() onSucceed = new EventEmitter<IZenAuthTransactionSigningAuthorizationHeaders>();
  modalTypes = ModalType;
  documents: IZenAuthTcsDocumentExtended[];

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.documents = this.nextStep?.tcsMethod?.documents?.map(doc => ({
      ...doc,
      name: this.getFormatedFormFieldName(doc.id),
      checkboxLabel: this.getFormatedLabel(doc.id),
    }));

    const formFields: IForm[] = [];
    this.documents?.forEach(doc => {
      const formField: IForm = {
        name: doc.name,
        initialValue: false,
        validators: doc?.required ? [Validators.requiredTrue] : [],
      };
      formFields.push(formField);
    });
    this.createForm(formFields);
  }

  getFormatedFormFieldName(fieldName: string): string {
    return fieldName?.replace(/./g, '_');
  }

  getFormatedLabel(fieldName: string): string {
    if (fieldName.includes('tcs')) {
      return 'outstanding_tcs.tcs_description';
    } else if (fieldName.includes('marketing')) {
      return 'outstanding_tcs.consent_description';
    } else {
      return 'outstanding_tcs.check_message';
    }
  }

  onConfirmTcs = (): void => {
    const itemsChecked: IZenAuthTcsItem[] = this.documents?.map(doc => {
      return {
        id: doc.id,
        confirmed: this.getFormValue(doc.name),
      };
    });
    const request: IZenAuthTransactionSigningAuthorizeRequest = {
      transactionAuthId: this.transactionAuthId,
      authRequest: {
        tcsAuth: {
          itemsChecked,
        },
      },

      onSucceed: (authHeaders: IZenAuthTransactionSigningAuthorizationHeaders): void =>
        this.onSucceed.emit(authHeaders),
      onData: (): void => {},
      onFailure: (): void => {},
    };
    this.onConfirm.emit(request);
  };

  onLinkClick = (link: string): void => {
    window.open(link, '_blank');
  };
}
