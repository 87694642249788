import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IInfoStepupPrep } from '../models/application.model';
import { ApplicationFacade } from '../redux/application/application.facade';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private applicationFacade: ApplicationFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.error?.postAction === 'CUSTOMER_DATA_REQUIRED') {
          if (error.error?.error?.code === 'ADD_PHONE' || error.error?.error?.code === 'ADD_EMAIL') {
            const editType = error.error?.error?.code === 'ADD_PHONE' ? 'editPhone' : 'editEmail';
            this.navigateToWithParams('/profile', {
              editType,
              returnUrl: this.router.url,
            });
          } else {
            const data: IInfoStepupPrep = { stepupType: error.error.error.code, startUrl: this.router.url };
            this.applicationFacade.setInfoStepupPrep(data);
          }
        }
        return throwError(() => error);
      }),
    );
  }

  private navigateToWithParams = (url: string, params: Params, replaceUrl?: boolean): void => {
    this.router.navigate(url ? [url] : [], { replaceUrl, queryParams: params });
  };
}
