import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import { INotificationsResponse } from '../../models/notifications.model';
import { ApiService } from '../../services/api/api.service';
import * as endpoints from './notifications.endpoints';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private apiService: ApiService, private appConfig: AppConfig) {}

  public fetchNotifications = (page?: number, size?: number): Observable<INotificationsResponse[]> =>
    this.apiService.get<INotificationsResponse[]>(
      endpoints.notifications(this.appConfig.backendConfig.apiV3Url, page, size),
    );

  public hideNotification = (notificationId: string): Observable<void> =>
    this.apiService.post<void>(endpoints.hideNotification(this.appConfig.backendConfig.apiV3Url, notificationId));

  public hideAllNotifications = (): Observable<void> =>
    this.apiService.post<void>(endpoints.hideAllNotifications(this.appConfig.backendConfig.apiV3Url));
}
