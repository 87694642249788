import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import get from 'lodash-es/get';
import { Observable } from 'rxjs';
import { IInfoStepupPrep } from '../../models/application.model';
import { IApplicationPartialState } from './application.reducer';
import * as selector from './application.selectors';
import { clean, finalize, ITaskAction, SetInfoStepupPrepAction, SetScrollPositionAction } from './task.actions';
import { CLEAN, FINALIZE, ORIGINAL_ACTION } from './task.events';
import { v4 as uuidv4 } from 'uuid';
import { CheckMaintenanceRequest } from './maintenance.actions';

@Injectable()
export class ApplicationFacade {
  getIsApplicationLoading$ = this.store.pipe(select(selector.isLoading));
  getIsApplicationLoadingSilently$ = this.store.pipe(select(selector.isLoadingSilently));
  getApplicationTasks$ = this.store.pipe(select(selector.getTasks));
  getInfoStepupPrep$ = this.store.pipe(select(selector.getInfoStepupPrep()));
  getScrollPosition$ = this.store.pipe(select(selector.getScrollPosition()));
  getMaintenance$ = this.store.pipe(select(selector.getMaintenance()));
  getErrorById$ = (taskId: string): Observable<ITaskAction> => this.store.pipe(select(selector.getErrorById(taskId)));
  getTaskById$ = (taskId: string): Observable<ITaskAction> => this.store.pipe(select(selector.getTaskById(taskId)));

  constructor(private store: Store<IApplicationPartialState>) {}

  onEventClean = (taskId: string): void => {
    this.store.dispatch(
      clean({
        type: CLEAN,
        taskId,
      }),
    );
  };

  repeatTask = (task: ITaskAction): void => {
    this.store.dispatch(get(task, ORIGINAL_ACTION) as never);
  };

  finalize = (taskId: string): void => {
    this.store.dispatch(
      finalize({
        type: FINALIZE,
        taskId,
      }),
    );
  };

  setInfoStepupPrep = (data: IInfoStepupPrep): void => {
    const taskId = `set-info-stepup-prep-${JSON.stringify(data)}`;
    this.store.dispatch(new SetInfoStepupPrepAction(taskId, data));
  };

  setScrollPosition = (position: number): void => {
    const taskId = `set-scroll-position-${position}`;
    this.store.dispatch(new SetScrollPositionAction(taskId, position));
  };

  checkMaintenance = (): void => {
    const taskId = `application-check-maintenance-${uuidv4()}`;
    this.store.dispatch(new CheckMaintenanceRequest(taskId));
  };
}
