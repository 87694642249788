export interface DialogData {
  title: string;
  text?: string;
  text2?: string;
  icon?: string;
  type?: ModalType;
  showConfirmButton?: boolean;
  confirmButtonText?: string;
  showCloseButton?: boolean;
  closeButtonText?: string;
}

enum ModalType {
  ERROR,
  SLIDE_IN_UP,
  FADE_IN,
  SIDEPANEL,
}
