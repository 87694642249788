import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IMatrixMessage,
  IMessageEventUpload,
  IMessageEventUploadDocuments,
  IMessageEventUploadedDocument,
  MessageEventType,
  MessageUploadedDocumentType,
} from '@wakanda/agent-chat-model';
import { ChatFacade, IChatImageFileResponse } from '@wakanda/wakanda-chat';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-chat-widget-upload',
  styleUrls: ['./chat-widget-upload.component.scss'],
  template: `
    <div class="ui-chat-message" [ngClass]="{ 'incoming': true, 'hey-ned': heyNedTheme }">
      <p>{{ message.content.body }}</p>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: true }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>

    <div class="ui-chat-message no-background" [ngClass]="{ outgoing: true }">
      <div class="document-preview">
        <div class="document-preview__container" *ngFor="let file of filesPreview">
          <img
            *ngIf="file?.type === MessageUploadedDocumentType.IMAGE"
            [src]="file?.url | secureImage | async"
            [alt]="file?.url"
            (click)="toggleImageDetail(file?.url)"
          />
          <ng-container *ngIf="file?.type === MessageUploadedDocumentType.PDF">
            <a [href]="file?.url | secureImage | async" target="_blank">
              <ui-icon icon="document"></ui-icon>
            </a>
          </ng-container>
          <div class="document-preview__delete-icon" (click)="removeFileFromStage(file)" *ngIf="!isReadOnly">
            <ui-icon icon="cross_circle" [size]="'2rem'"></ui-icon>
          </div>
        </div>
        <div
          class="document-preview__container uploader"
          *ngIf="!isReadOnly && fileStage?.length < message?.content?.maxDocuments"
        >
          <ui-chat-file-upload
            [modalTitle]="'Upload file'"
            [showImage]="message?.content?.allowImages"
            [showDocument]="message?.content?.allowPdf"
            (fileSelected)="onFileSelected($event)"
          ></ui-chat-file-upload>
        </div>
      </div>
      <div class="row right">
        <ui-button
          [disabled]="documentsInvalid && !isReadOnly"
          [selectButton]="isReadOnly && !pairedMessage?.skipButtonPressed"
          [content]="'general.submit' | translate"
          (onClick)="commitChanges()"
        ></ui-button>
        <ui-divider [compact]="true" [vertical]="true"></ui-divider>
        <ui-button
          *ngIf="message.content.displaySkipButton"
          [selectButton]="isReadOnly && pairedMessage?.skipButtonPressed"
          [content]="message?.content?.skipButtonLabel"
          (onClick)="skipUpload()"
        ></ui-button>
      </div>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ outgoing: true }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
    <ui-modal
      [modal]="ModalType.SLIDE_IN_UP"
      [template]="imageDetail"
      [closeButton]="true"
      [isVisible]="showImageDetail"
      (onCloseClick)="toggleImageDetail('')"
    >
      <ng-template #imageDetail>
        <img [src]="imageDetailUrl | secureImage | async" [alt]="imageDetailUrl" />
      </ng-template>
    </ui-modal>
  `,
})
export class ChatWidgetUploadComponent {
  @Input() message: IMatrixMessage<IMessageEventUpload>;
  @Input() heyNedTheme: boolean;
  @Output() uploadResult = new EventEmitter<IMessageEventUploadDocuments>();
  ModalType = ModalType;

  MessageEventType = MessageEventType;
  MessageUploadedDocumentType = MessageUploadedDocumentType;

  fileStage: IMessageEventUploadedDocument[] = [];

  showImageDetail = false;
  imageDetailUrl = '';

  get filesPreview(): IMessageEventUploadedDocument[] {
    return this.isReadOnly ? this.pairedMessage.documents : this.fileStage;
  }

  get documentsInvalid(): boolean {
    return this.fileStage.length < this.message.content.minDocuments;
  }

  get pairedMessage(): IMessageEventUploadDocuments {
    return this.message.content.pairedMessage;
  }

  get isReadOnly(): boolean {
    return !!this.pairedMessage;
  }

  constructor(private chatFacade: ChatFacade) {}

  toggleImageDetail(url: string): void {
    this.imageDetailUrl = url;
    this.showImageDetail = !this.showImageDetail;
  }

  removeFileFromStage(file: IMessageEventUploadedDocument): void {
    this.fileStage = this.fileStage.filter(item => item.id !== file.id);
  }

  onFileSelected({ data, file }): void {
    const fileType =
      file.name.split('.').pop() === 'pdf' ? MessageUploadedDocumentType.PDF : MessageUploadedDocumentType.IMAGE;

    this.chatFacade.uploadImage(data, (response: IChatImageFileResponse) => {
      this.fileStage.push({
        id: response.id,
        url: response.url,
        type: fileType,
      });
    });
  }

  commitChanges(): void {
    if (this.documentsInvalid || this.isReadOnly) {
      return;
    }

    this.uploadResult.emit({
      msgtype: MessageEventType.UPLOAD_DOCUMENTS,
      actionId: this.message.content.actionId,
      documents: this.fileStage,
      skipButtonPressed: false,
    });
  }

  skipUpload(): void {
    if (this.isReadOnly) {
      return;
    }

    this.uploadResult.emit({
      msgtype: MessageEventType.UPLOAD_DOCUMENTS,
      actionId: this.message.content.actionId,
      documents: [],
      skipButtonPressed: true,
    });
  }
}
