export const coreErrors = {
  translate: {
    errorCode: 'ERR-T1',
    errorMessage: 'An error occurred while the application was started',
    errorDescription: 'fetching translate',
  },
  appConfig: {
    errorCode: 'ERR-A1',
    errorMessage: 'An error occurred while the application was started',
    errorDescription: 'fetching appConfig',
  },
  initApp: {
    errorCode: 'ERR-I1',
    errorMessage: 'An error occurred while the application was started',
    errorDescription: 'fetching configuration.json',
  },
  subscriptions: {
    errorCode: 'ERR-SU',
    errorMessage: 'An error occurred while the application was started. Please try to refresh this page',
    errorDescription: 'fetching subscriptions',
  },
  companies: {
    errorCode: 'ERR-CO',
    errorMessage: 'An error occurred while the application was started. Please try to refresh this page',
    errorDescription: 'fetching companies',
  },
};
