import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { ZenAuthFacade } from '../redux/zenAuth.facade';

@Injectable()
export class LogoutInterceptor implements HttpInterceptor {
  hasTokenInStore = false;
  constructor(private zenAuthFacade: ZenAuthFacade) {
    this.zenAuthFacade.getAuthenticate$.subscribe(token => {
      this.hasTokenInStore = !!token;
    });
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && this.hasTokenInStore) {
          this.zenAuthFacade.systemLogout();
          return throwError(() => error);
        }
        return throwError(() => error);
      }),
    );
  }
}
