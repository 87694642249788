import { Action } from '@ngrx/store';
import { IZenAuthOnboarding, IZenAutReducerState } from '../models/redux.model';
import { IZenAuthUsernameToken } from '../models/requests.model';
import {
  IAddressSuggestModel,
  IZenAuthOnboardingBankClientInfo,
  ZenAuthOnboardingSuccessInfoType,
} from '../models/responses.model';
import { ZenAuthAction } from './zenAuth.actions';
import { EVENT } from './zenAuth.events';

export const ZENAUTH_FEATURE_KEY = 'ZENAuth';

export interface IZenAuthPartialState {
  readonly [ZENAUTH_FEATURE_KEY]: IZenAutReducerState;
}

export function zenAuthReducer(state: IZenAutReducerState = {}, action: Action): IZenAutReducerState {
  const zenAuthAction = action as ZenAuthAction;

  switch (zenAuthAction.type) {
    case EVENT.ONBOARDING_SUCCESS_RECEIVED: {
      const nextStep = zenAuthAction.payload;
      const onboarding = state.onboarding;
      const bankClientInfo = state.bankClientInfo || ({} as IZenAuthOnboardingBankClientInfo);
      if (
        [
          ZenAuthOnboardingSuccessInfoType.ACCOUNT_LINKED,
          ZenAuthOnboardingSuccessInfoType.EXISTING,
          ZenAuthOnboardingSuccessInfoType.NEW,
          ZenAuthOnboardingSuccessInfoType.WALLET_LINKED,
        ].includes(nextStep?.successInfo?.type)
      ) {
        bankClientInfo.walletAvailable = true;
        bankClientInfo.upgradeWallet = !nextStep?.successInfo?.unlimited;
      }
      const newOnboarding = Object.assign({}, onboarding, { nextStep });
      return Object.assign({}, state, { onboarding: newOnboarding, success: true, bankClientInfo });
    }
    case EVENT.ONBOARDING_POSTCHECK_RECEIVED:
    case EVENT.ONBOARDING_BUSINESS_CATEGORY_RECEIVED:
    case EVENT.ONBOARDING_BUSINESS_PARTNER_RECEIVED:
    case EVENT.ONBOARDING_BUSINESS_DETAILS_RECEIVED:
    case EVENT.ONBOARDING_BUSINESS_ADDRESS_RECEIVED:
    case EVENT.ONBOARDING_BUSINESS_TRADING_NAME_RECEIVED:
    case EVENT.ONBOARDING_FIRST_NAME_LAST_NAME_AUTH_RECEIVED:
    case EVENT.ONBOARDING_SECURITY_QUESTIONS_RECEIVED:
    case EVENT.ONBOARDING_ADDRESS_RECEIVED:
    case EVENT.ONBOARDING_OTP_RECEIVED:
    case EVENT.ONBOARDING_EXTERNAL_AUTH_RECEIVED:
    case EVENT.ONBOARDING_PERSONAL_DATA_RECEIVED:
    case EVENT.ONBOARDING_APPROVEIT_RECEIVED:
    case EVENT.ONBOARDING_SENT_APPROVEIT_RECEIVED:
    case EVENT.ONBOARDING_MOBIMONEY_RECEIVED:
    case EVENT.ONBOARDING_BANK_OTP_RECEIVED:
    case EVENT.ONBOARDING_START_MONEY_APP_RECEIVED:
    case EVENT.ONBOARDING_START_SOCIAL_RECEIVED:
    case EVENT.ONBOARDING_START_MANUAL_RECEIVED:
    case EVENT.ONBOARDING_START_INFO_RECEIVED:
    case EVENT.ONBOARDING_CHECK_BANK_RECEIVED:
    case EVENT.ONBOARDING_CHECK_LOGIN_RECEIVED:
    case EVENT.ONBOARDING_ADD_MERCHANT_RECEIVED:
    case EVENT.ONBOARDING_BASIC_DATA_RECEIVED:
    case EVENT.ONBOARDING_BUSINESS_BASIC_DATA_RECEIVED:
    case EVENT.ONBOARDING_ID_PASSPORT_RECEIVED:
    case EVENT.ONBOARDING_PROGRESS_RECEIVED:
    case EVENT.ONBOARDING_ID_PHOTO_RECEIVED:
    case EVENT.ONBOARDING_SET_LOGIN_RECEIVED:
    case EVENT.ONBOARDING_SET_LOGIN_SOCIAL_RECEIVED:
    case EVENT.ONBOARDING_BUSINESS_LEGAL_ENTITY_RECEIVED:
    case EVENT.ONBOARDING_LEGAL_ENTITY_RECEIVED:
    case EVENT.ONBOARDING_BANKPHONE_DIFFERENT_RECEIVED:
    case EVENT.ONBOARDING_STEPUP_RECEIVED:
    case EVENT.ONBOARDING_HEALTH_STEPUP_RECEIVED:
    case EVENT.ONBOARDING_INFO_STEPUP_RECEIVED:
    case EVENT.ONBOARDING_LOGIN_RECEIVED:
    case EVENT.ONBOARDING_LINK_ACCOUNT_RECEIVED:
    case EVENT.ONBOARDING_START_LINK_ACCOUNT_RECEIVED:
    case EVENT.ONBOARDING_INITIATE_LINK_ACCOUNT_RECEIVED:
    case EVENT.ONBOARDING_OUTSTANDNG_TCS_RECEIVED:
    case EVENT.ONBOARDING_TCS_RECEIVED:
    case EVENT.ONBOARDING_SELECT_OTP_RECEIVED:
    case EVENT.ONBOARDING_SELECT_SOCIAL_RECEIVED:
    case EVENT.ONBOARDING_SELECT_NEDBANK_RECEIVED:
    case EVENT.ONBOARDING_SIGNUP_SOCIAL_RECEIVED:
    case EVENT.ONBOARDING_SIGNUP_NEDBANK_RECEIVED:
    case EVENT.ONBOARDING_SIGNUP_MANUAL_RECEIVED:
    case EVENT.LOGIN_NEDID_RECEIVED:
    case EVENT.LOGIN_MOBIMONEY_RECEIVED:
    case EVENT.LOGIN_START_SOCIAL_RECEIVED: {
      const onboarding = state.onboarding;
      const newOnboarding = Object.assign({}, onboarding, { nextStep: zenAuthAction.payload });
      return Object.assign({}, state, { onboarding: newOnboarding });
    }
    case EVENT.ONBOARDING_FINISHED:
    case EVENT.ONBOARDING_RESET: {
      return Object.assign({}, state, { onboarding: null });
    }

    case EVENT.LOGIN_RESET: {
      return Object.assign({}, state, { authenticate: null, username: null });
    }
    case EVENT.ONBOARDING_START_RECEIVED: {
      const onboarding: IZenAuthOnboarding = {
        nextStep: zenAuthAction.payload,
      };
      return Object.assign({}, state, { onboarding });
    }
    case EVENT.ONBOARDING_SET_LOGIN_REQUEST: {
      const onboarding = Object.assign({}, state.onboarding, {
        userName: zenAuthAction.payload.username,
        nextStep: state?.onboarding?.nextStep,
      });
      return Object.assign({}, state, { onboarding });
    }

    case EVENT.ONBOARDING_SUGGESTED_USERNAMES_RECEIVED: {
      const onboarding = Object.assign({}, state.onboarding, { suggestedUsernames: zenAuthAction.suggestedUsername });
      return Object.assign({}, state, { onboarding });
    }

    case EVENT.ONBOARDING_SUGGESTED_ADDRESS_RECEIVED: {
      const suggestedAddress: IAddressSuggestModel[] = zenAuthAction.suggestedAddress.map(address => ({
        id: `${address.suburb}-${address.city}-${address.postalCode}`,
        suburb: address.suburb,
        city: address.city,
        postalCode: address.postalCode,
        label: `${address.suburb}, ${address.city}, ${address.postalCode}`,
      }));
      const onboarding = Object.assign({}, state.onboarding, { suggestedAddress });
      return Object.assign({}, state, { onboarding });
    }

    case EVENT.TRANSACTION_SIGNING_START_REQUEST: {
      const transactionSigning = Object.assign({}, state.transactionSigning, {
        transactionAuthId: zenAuthAction.transactionAuthId,
      });
      return Object.assign({}, state, { transactionSigning });
    }

    case EVENT.TRANSACTION_SIGNING_AUTHENTICATORS_SAVE: {
      return Object.assign({}, state, { transactionAuthenticators: zenAuthAction.authenticators });
    }

    case EVENT.ONBOARDING_AVO_INFO_RECEIVED:
    case EVENT.ONBOARDING_START_MONEY_APP_ASYNC_RECEIVED: {
      const avoClientInfo = zenAuthAction.payload;
      return Object.assign({}, state, { avoClientInfo });
    }

    case EVENT.ONBOARDING_BANK_INFO_RECEIVED: {
      const bankClientInfo = zenAuthAction.payload;
      const avoClientInfo = Object.assign({}, state.avoClientInfo);
      if (bankClientInfo?.walletAvailable) {
        avoClientInfo.walletCreated = true;
      }

      return Object.assign({}, state, { bankClientInfo, avoClientInfo });
    }

    case EVENT.TRANSACTION_SIGNING_START_RECEIVED:
    case EVENT.TRANSACTION_SIGNING_AUTHORIZE_RECEIVED: {
      const transactionSigning = Object.assign({}, state.transactionSigning, { nextStep: zenAuthAction.payload });
      return Object.assign({}, state, { transactionSigning });
    }

    case EVENT.TRANSACTION_SIGNING_CANCEL_RECEIVED: {
      const transactionSigning = null;
      return Object.assign({}, state, { transactionSigning });
    }

    case EVENT.LOGIN_AUTHENTICATE_WITH_TOKEN: {
      const authToken = zenAuthAction.authRequest.authToken as IZenAuthUsernameToken;
      if (authToken && authToken.username) {
        return Object.assign({}, state, { username: authToken.username.username });
      }
      return state as IZenAutReducerState;
    }

    case EVENT.LOGIN_START_RECEIVED:
    case EVENT.LOGIN_AUTHENTICATE_RECEIVED: {
      return Object.assign({}, state, { authenticate: zenAuthAction.payload });
    }

    case EVENT.ONBOARDING_GET_REFERRAL_DETAILS_RECEIVED: {
      return Object.assign({}, state, { referralDetails: zenAuthAction.data });
    }

    case EVENT.ONBOARDING_COUNTRIES_RECEIVED: {
      return Object.assign({}, state, { countries: zenAuthAction.payload });
    }

    case EVENT.AUTHORIZATION_TOKEN_SET: {
      const token = zenAuthAction?.token?.access_token;
      return Object.assign({}, state, { token });
    }

    case EVENT.AUTHORIZATION_TOKEN_REMOVE: {
      return Object.assign({}, state, { token: null });
    }

    case EVENT.ONBOARDING_SET_REFERRAL_CODE: {
      return Object.assign({}, state, { referralCode: zenAuthAction.referralCode });
    }

    case EVENT.GUEST_INFO_SET: {
      return Object.assign({}, state, { guestInfo: zenAuthAction.data });
    }

    //v3
    case EVENT.LOGIN_PROFILE_RECEIVED:
    case EVENT.LOGIN_OTP_RECEIVED:
    case EVENT.LOGIN_OTP_RESEND_RECEIVED:
    case EVENT.LOGIN_GOOGLE_RECEIVED:
    case EVENT.LOGIN_FACEBOOK_RECEIVED:
    case EVENT.LOGIN_APPLE_RECEIVED:
    case EVENT.LOGIN_START_PASSWORDLESS_RECEIVED: {
      return Object.assign({}, state, { nextStep: zenAuthAction.data });
    }

    case EVENT.LOGIN_CLEAR_NEXT_STEP: {
      return Object.assign({}, state, { nextStep: undefined });
    }

    default:
      return state as IZenAutReducerState;
  }
}
