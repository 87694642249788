import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { AmountTransformPipe } from '../pipe/amount-transform.pipe';
import { AmountUtility } from '../utils/amount';
import { Constants } from '../utils/constants';

@Directive({
  selector: '[appAmountFormatMask]',
})
export class AmountFormatDirective implements AfterContentInit {
  @Input() ngModel;
  @Output() ngModelChange = new EventEmitter();
  @Input() hidePrefix = false;
  @Input() noDecimal = false;
  @Input() prefixValue?: string;
  code: number;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private amountTransform: AmountTransformPipe,
  ) {}

  ngAfterContentInit(): void {
    if (this.elementRef.nativeElement.value) {
      const parseValue = AmountUtility.amountOnly(this.elementRef.nativeElement.value.toString());
      const formatted_value = this.amountTransform.transform(parseValue, {
        hidePrefix: this.hidePrefix,
        noDecimal: this.noDecimal,
        prefixValue: this.prefixValue,
        isInput: true,
      });
      this.renderer.setValue(this.elementRef, formatted_value);
      this.ngModelChange.emit(parseValue);
    }
  }

  @HostListener('keydown', ['$event'])
  onkeydown(event): void {
    this.code = event.keyCode || event.charCode;
  }
  @HostListener('input', ['$event.target.value'])
  onInput(value): void {
    const formatted_value = this.amountTransform.transform(value.toString().replace(',', '.'), {
      hidePrefix: this.hidePrefix,
      noDecimal: this.noDecimal,
      prefixValue: this.prefixValue,
      isInput: true,
    });
    const parsed_value = this.amountTransform.parse(formatted_value, this.prefixValue);
    let startselection = this.elementRef.nativeElement.selectionStart;
    if (value.length < formatted_value.length && this.code !== Constants.keyCode.delete) {
      startselection += formatted_value.length - value.length;
    }
    if (value.length > formatted_value.length && startselection > 1) {
      startselection -= value.length - formatted_value.length;
    }
    if (formatted_value[startselection - 1] === ' ' && this.code !== Constants.keyCode.delete) {
      startselection--;
    }
    if (formatted_value[startselection] === ' ' && this.code === Constants.keyCode.delete) {
      startselection++;
    }
    if (startselection === 0) {
      startselection = 1;
    }
    this.elementRef.nativeElement.value = formatted_value;
    if (this.elementRef.nativeElement.setSelectionRange) {
      this.elementRef.nativeElement.setSelectionRange(startselection, startselection);
    }
    this.ngModelChange.emit(parsed_value);
  }
  @HostListener('blur', ['$event.target.value'])
  onBlur(value): void {
    let parseValue = AmountUtility.amountOnly(value.toString());
    parseValue = parseFloat(parseValue).toFixed(2);
    parseValue = this.amountTransform.transform(parseValue.toString(), {
      hidePrefix: this.hidePrefix,
      noDecimal: this.noDecimal,
      prefixValue: this.prefixValue,
      isInput: true,
    });

    this.elementRef.nativeElement.value = parseValue;
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value): void {
    this.elementRef.nativeElement.value = this.amountTransform.parseBlur(value, this.hidePrefix, this.prefixValue);
  }
}
