export interface INotificationsResponse {
  notificationId: string;
  hidden: boolean;
  title: string;
  description: string;
  created: string;
  type: NotificationItem;
  objectId?: string;
}

export enum NotificationItem {
  NONE = 'NONE',
  LOGIN = 'LOGIN',
  RECOVERY = 'RECOVERY',
  HOMEPAGE = 'HOMEPAGE',
  SHOPPING = 'SHOPPING',
  DEALS = 'DEALS',
  EXPERIENCES = 'EXPERIENCES',
  ENTERTAINMENT = 'ENTERTAINMENT',
  TRAVEL = 'TRAVEL',
  VOUCHERS = 'VOUCHERS',
  TAKEAWAYS = 'TAKEAWAYS',
  GIFTING = 'GIFTING',
  SERVICES = 'SERVICES',
  PREPAID = 'PREPAID',
  PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
  PRODUCT = 'PRODUCT',
  GROCERIES = 'GROCERIES',
  WALLET = 'WALLET',
  TRANSACTION = 'TRANSACTION',
  PAY = 'PAY',
  DISPLAY_QR = 'DISPLAY_QR',
  ACCEPT_MONEY = 'ACCEPT_MONEY',
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
  SCAN = 'SCAN',
  PROFILE = 'PROFILE',
  ORDER_HISTORY = 'ORDER_HISTORY',
  ORDER_ID = 'ORDER_ID',
  PERSONAL_LOANS = 'PERSONAL_LOANS',
  COUPONS = 'COUPONS',
  BANKING_SERVICES = 'BANKING_SERVICES',
  BANK_APPROVED_BENEFICIARIES = 'BANK_APPROVED_BENEFICIARIES',
  EDUCATION = 'EDUCATION',
  JOB_CUSTOMER = 'JOB_CUSTOMER',
  CHAT_JOB = 'CHAT_JOB',
  CHAT_JOB_C2M = 'CHAT_JOB_C2M',
  CHAT_RECEIVED_SUPPORT = 'CHAT_RECEIVED_SUPPORT',
  JOB_MERCHANT = 'JOB_MERCHANT',
  ORDER_SHIPMENT = 'ORDER_SHIPMENT',
  WALLET_PAYMENT_REQUEST = 'WALLET_PAYMENT_REQUEST',
  LOYALTY = 'LOYALTY',
  JOB_CUSTOMER_NUDGE = 'JOB_CUSTOMER_NUDGE',
  JOB_CUSTOMER_HEYNEDSERVICE = 'JOB_CUSTOMER_HEYNEDSERVICE',
  CHAT_ORDER = 'CHAT_ORDER',
  CHAT_SUPPORT = 'CHAT_SUPPORT',
  AVO_MESSAGE = 'AVO_MESSAGE',
  RMA = 'RMA',
}

export enum NotificationRewardType {
  FIRST_REVIEW = 'FIRST_REVIEW',
  POINTS = 'POINTS',
  FIRST_BANKING = 'FIRST_BANKING',
  BIRTHDAY = 'BIRTHDAY',
}
