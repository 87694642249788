import pickBy from 'lodash-es/pickBy';
import identity from 'lodash-es/identity';
import isEmpty from 'lodash-es/isEmpty';
import { ShoppingCartType } from '../../models/shopping.model';

const shopping = (baseUrl: string): string => `${baseUrl}/ecommerce/v2`;
const catalog = (baseUrl: string): string => `${baseUrl}/catalog/v2`;
const profile = (baseUrl: string): string => `${baseUrl}/profile/v1/customer`;
const buyCart = (baseUrl: string): string => `${shopping(baseUrl)}/cart`;
const order = (baseUrl: string): string => `${shopping(baseUrl)}/order`;
const buyCartSaved = (baseUrl: string): string => `${catalog(baseUrl)}/saved`;
const buyCartPromo = (baseUrl: string): string => `${shopping(baseUrl)}/promo`;
const payments = (baseUrl: string): string => `${baseUrl}/payments/v3`;

const cartV3 = (baseUrl: string): string => `${baseUrl}/cart`;

export const shoppingCustomerReviewsData = (productId: string, baseUrl: string): string =>
  `${catalog(baseUrl)}/product/${productId}/review`;
export const writeAReview = (productId: string, baseUrl: string): string =>
  `${catalog(baseUrl)}/product/${productId}/review`;

export const shoppingCategoryLandingData = (categoryId: string, baseUrl: string, queryParams: any): string => {
  const params = pickBy(queryParams, identity);
  return `${catalog(baseUrl)}/category/${categoryId}${
    !isEmpty(params) ? '?' + new URLSearchParams(params).toString() : ''
  }`;
};

export const shoppingProductDetailData = (productId: string, mallId: string, baseUrl: string): string =>
  `${catalog(baseUrl)}/product/${productId}${mallId ? `?mallId=${mallId}` : ''}`;
export const addToFavorites = (productId: string, baseUrl: string): string =>
  `${catalog(baseUrl)}/product/${productId}/favourite`;
export const cart = (cartType: string, baseUrl: string): string => `${buyCart(baseUrl)}/${cartType}`;
export const updateCartItem = (cartType: string, baseUrl: string): string => `${buyCart(baseUrl)}/${cartType}`;
export const saveCartItem = (baseUrl: string): string => `${buyCartSaved(baseUrl)}`;
export const updateSavedCartItem = (cartItemId: string, sellerId: string, baseUrl: string): string =>
  `${buyCartSaved(baseUrl)}/${cartItemId}/${sellerId}`;
export const promoCodes = (baseUrl: string): string => `${buyCartPromo(baseUrl)}`;
export const applyPromoCode = (promoCode: string, cartType: string, baseUrl: string): string =>
  `${buyCart(baseUrl)}/${cartType}/promo/${promoCode}`;
export const deletePromoCode = (promoCode: string, cartType: string, baseUrl: string): string =>
  `${buyCart(baseUrl)}/${cartType}/promo/${promoCode}`;
export const pickupLocations = (cartType: string, fulfillmentId: string, baseUrl: string): string =>
  `${buyCart(baseUrl)}/${cartType}/fulfilment/${fulfillmentId}/available-pickup-points`;
export const deliveryInfoAddress = (cartType: string, baseUrl: string): string =>
  `${buyCart(baseUrl)}/${cartType}/addresses`;
export const reserveInfo = (cartType: string, baseUrl: string): string => `${buyCart(baseUrl)}/${cartType}/pay`;
export const createOrder = (cartType: string, baseUrl: string): string => `${buyCart(baseUrl)}/${cartType}/order`;
export const payByWallet = (orderNumber: string, baseUrl: string): string =>
  `${order(baseUrl)}/${orderNumber}/pay-wallet`;
export const payByCard = (orderNumber: string, baseUrl: string): string => `${order(baseUrl)}/${orderNumber}/pay-card`;
export const orderInfo = (orderNumber: string, baseUrl: string): string => `${order(baseUrl)}/${orderNumber}/payment`;
export const orderDetail = (orderNumber: string, baseUrl: string): string => `${order(baseUrl)}/${orderNumber}/detail`;
export const paymentInfo = (paymentId: string, baseUrl: string): string => `${payments(baseUrl)}/${paymentId}`;
export const moveCartItem = (baseUrl: string): string => `${buyCart(baseUrl)}`;
export const deliveryAddress = (baseUrl: string): string => `${profile(baseUrl)}/location`;
export const returnPolicy = (baseUrl: string): string => `${shopping(baseUrl)}/buy/returnpolicy`;
export const termsAndConditions = (baseUrl: string): string => `${shopping(baseUrl)}/buy/tac`;
export const paymentReservation = (baseUrl: string): string => `${shopping(baseUrl)}/buy/paymentreservation`;
export const deliveryOptions = (cartType: string, baseUrl: string): string =>
  `${buyCart(baseUrl)}/${cartType}/deliveryOptions`;

export const updateDeliveryMethod = (cartType: string, fulfillmentId: string, baseUrl: string): string =>
  `${buyCart(baseUrl)}/${cartType}/fulfilment/${fulfillmentId}`;

export const sellerProductList = (sellerId: string, mallId: string, baseUrl: string): string =>
  `${catalog(baseUrl)}/seller/${sellerId}/product?allSubproducts=true${mallId ? `&mallId=${mallId}` : ''}`;
export const sellerReviewList = (sellerId: string, baseUrl: string): string =>
  `${catalog(baseUrl)}/seller/${sellerId}/review`;
export const gift = (cartType: string, baseUrl: string): string => `${buyCart(baseUrl)}/${cartType}/gift`;
export const checkTvLicence = (cartType: string, baseUrl: string): string =>
  `${buyCart(baseUrl)}/${cartType}/tv-license-check`;
export const postAsset = (baseUrl: string): string => `${baseUrl}/assets/ecommerce`;
export const tvLicPhotoId = (cartType: string, baseUrl: string): string =>
  `${buyCart(baseUrl)}/${cartType}/tv-license-photo-id`;
export const termInMonths = (cartType: string, baseUrl: string): string =>
  `${buyCart(baseUrl)}/${cartType}/termInMonths`;
export const clearCart = (cartType: ShoppingCartType, baseUrl: string): string => `${buyCart(baseUrl)}/${cartType}`;

// v3 cart
export const addToCart = (baseUrl: string): string => `${cartV3(baseUrl)}/items`;
export const cartSummary = (baseUrl: string): string => `${cartV3(baseUrl)}/summary`;
export const carts = (baseUrl: string): string => `${cartV3(baseUrl)}`;
export const cartDetail = (cartId: string, baseUrl: string): string => `${cartV3(baseUrl)}/${cartId}`;
export const cartItemQuantity = (cartId: string, cartItemId: string, baseUrl: string): string =>
  `${cartV3(baseUrl)}/${cartId}/items/${cartItemId}/quantity`;
export const cartDeliveryAddress = (cartId: string, baseUrl: string): string =>
  `${cartV3(baseUrl)}/${cartId}/delivery-address`;
export const cartDeliveryOption = (cartId: string, fulfilmentId: string, baseUrl: string): string =>
  `${cartV3(baseUrl)}/${cartId}/${fulfilmentId}/delivery`;
export const payCart = (cartId: string, baseUrl: string): string => `${cartV3(baseUrl)}/${cartId}/pay`;
