<ng-container>
  <div class="ui-progress-bar" [style.width]="width || '100%'">
    <div class="wrapper">
      <ng-container *ngIf="animated; else percentageProgressBarTemplate">
        <div
          class="progress progress-animated"
          [ngStyle]="{ 'animation-duration': animationDuration + 's'?? MAX_ANIMATION_DURATION }"
        ></div>
      </ng-container>
    </div>
    <div *ngIf="withLabels" class="row between">
      <b>0%</b>
      <b>100%</b>
    </div>

    <ng-template #percentageProgressBarTemplate>
      <div class="progress" [style.width]="percentage + '%'"></div>
    </ng-template>
  </div>
</ng-container>
