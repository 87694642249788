import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '@wakanda/wakanda-core';
import { Observable } from 'rxjs';
import { ZenAuthConfig } from '../configs/zenauth.config';
import {
  createUsernamePassword,
  getReferralDetails,
  onboardingAddress,
  onboardingAddressSuggestion,
  onboardingApproveIt,
  onboardingBankOtp,
  onboardingBasicData,
  onboardingBusinessAddress,
  onboardingBusinessCategoryInfo,
  onboardingBusinessDetails,
  onboardingBusinessPartners,
  onboardingBusinessTradingName,
  onboardingCheckBank,
  onboardingStepup,
  onboardingLogin,
  onboardingCountries,
  onboardingExternalAuth,
  onboardingFirstNameLastNameAuth,
  onboardingIdPassport,
  onboardingLegalEntity,
  onboardingIdPhoto,
  onboardingMobiMoney,
  onboardingOtp,
  onboardingPersonalData,
  onboardingProgress,
  onboardingSecurityQuestions,
  onboardingSentApproveIt,
  onboardingStart,
  onboardingStartInfo,
  onboardingStartManual,
  onboardingStartSocial,
  onboardingSuccess,
  postCheck,
  suggestedUsernames,
  onboardingCheckLogin,
  onboardingStartMoneyApp2,
  createSocialUsernamePassword,
  onboardingBusinessBasicData,
  onboardingAddMerchant,
  onboardingBankphoneDifferent,
  onboardingBusinessLegalEntity,
  onboardingOutstandingTcs,
  onboardingLinkAccount,
  onboardingStartLinkAccount,
  onboardingInitiateLinkAccount,
  onboardingTcs,
  onboardingSelectOtp,
  onboardingSelectSocial,
  onboardingSelectNedbank,
  onboardingSignupManual,
  onboardingSignupSocial,
  onboardingSignupNedbank,
  guestLogin,
  onboardingStartMoneyAppAsync,
  onboardingBankInfo,
  onboardingAvoInfo,
  onboardingSignup,
  onboardingHealtStepup,
  onboardingInfoStepup,
  editPersonalDetailsEmail,
} from '../endpoints/zenAuth.endpoint';
import {
  IZenAuthBankOtpRequest,
  IZenAuthBasicDataRequest,
  IZenAuthBusinessAddressRequest,
  IZenAuthIdPassportNumberRequest,
  IZenAuthLegalEntityRequest,
  IZenAuthOnboardingAddressRequest,
  IZenAuthOnboardingApproveItRequest,
  IZenAuthOnboardingBusinessCategoryInfoRequest,
  IZenAuthOnboardingBusinessDetailsRequest,
  IZenAuthOnboardingBusinessPartnerRequest,
  IZenAuthOnboardingBusinessTradingNameRequest,
  IZenAuthOnboardingExternalAuthRequest,
  IZenAuthOnboardingFirstNameLastNameAuthRequest,
  IZenAuthOnboardingIdPhotoRequest,
  IZenAuthOnboardingLoginRequest,
  IZenAuthOnboardingMobiMoneyRequest,
  IZenAuthOnboardingOtpRequest,
  IZenAuthOnboardingPersonalDataRequest,
  IZenAuthOnboardingProgressRequest,
  IZenAuthOnboardingReferralDetail,
  IZenAuthOnboardingSecurityQuestionsRequest,
  IZenAuthOnboardingSentApproveItRequest,
  IZenAuthOnboardingStartInfoRequest,
  IZenAuthOnboardingStartManualRequest,
  IZenAuthOnboardingStartMoneyAppRequest,
  IZenAuthOnboardingStartRequest,
  IZenAuthOnboardingStartSocialRequest,
  IZenAuthOnboardingSuccessRequest,
  IZenAuthBusinessBasicDataRequest,
  IZenAuthOnboardingBankphoneDifferentRequest,
  IZenAuthBusinessLegalEntityRequest,
  IZenAuthCheckBankRequest,
  IZenAuthOnboardingStepupRequest,
  IZenAuthLoginRequest,
  IZenAuthOnboardingOutstandingTCsRequest,
  IZenAuthOnboardingLinkAccountRequest,
  IZenAuthOnboardingStartLinkAccountRequest,
  IZenAuthOnboardingTCsRequest,
  IZenAuthOnboardingSelectOtpRequest,
  IZenAuthOnboardingSelectNedbankRequest,
  IZenAuthOnboardingSelectSocialRequest,
  IZenAuthOnboardingSignupManualRequest,
  IZenAuthOnboardingSignupRequest,
  IZenAuthSocialByRequest,
} from '../models/requests.model';
import {
  IZenAuthOnboardingAddressSuggestResponse,
  IZenAuthOnboardingCountry,
  IZenAuthOnboardingNextStepResponse,
  IZenGuestLoginResponse,
  IZenAuthOnboardingAvoClientInfo,
  IZenAuthOnboardingBankClientInfo,
  IZenAuthSignupInfoResponse,
  IZenAuthInfoStepUpRequest,
  IZenAuthPersonalInformationResponse,
  IZenAuthPersonalInformationEditEmailRequest,
} from '../models/responses.model';
import { ZEN_AUTH_CONFIG } from '../tokens';
import { ApiService } from './api.service';

@Injectable()
export class OnboardingService {
  constructor(
    private api: ApiService,
    @Inject(ZEN_AUTH_CONFIG) private config: ZenAuthConfig,
    private appConfig: AppConfig,
  ) {}

  start = (start: IZenAuthOnboardingStartRequest): Observable<IZenAuthOnboardingNextStepResponse> => {
    const payload = { ...start, clientId: this.config.clientId };
    return this.api.post<IZenAuthOnboardingStartRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingStart(this.appConfig.backendConfig.onboardingUrl),
      payload,
    );
  };

  basicData = (request: IZenAuthBasicDataRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthBasicDataRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBasicData(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  businessBasicData = (request: IZenAuthBusinessBasicDataRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthBusinessBasicDataRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBusinessBasicData(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  outstandingTcs = (request: IZenAuthOnboardingOutstandingTCsRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingOutstandingTCsRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingOutstandingTcs(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  tcs = (request: IZenAuthOnboardingTCsRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingTCsRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingTcs(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  linkAccount = (request: IZenAuthOnboardingLinkAccountRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingLinkAccountRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingLinkAccount(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  startLinkAccount = (
    request: IZenAuthOnboardingStartLinkAccountRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingStartLinkAccountRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingStartLinkAccount(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  initiateLinkAccount = (): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingInitiateLinkAccount(this.appConfig.backendConfig.onboardingUrl),
      {},
    );

  idPassport = (request: IZenAuthIdPassportNumberRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthIdPassportNumberRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingIdPassport(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  legalEntity = (request: IZenAuthLegalEntityRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthLegalEntityRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingLegalEntity(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  businessLegalEntity = (request: IZenAuthBusinessLegalEntityRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthBusinessLegalEntityRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBusinessLegalEntity(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  countries = (): Observable<IZenAuthOnboardingCountry[]> =>
    this.api.get<IZenAuthOnboardingCountry[]>(onboardingCountries(this.appConfig.backendConfig.onboardingUrl));

  startInfo = (start: IZenAuthOnboardingStartInfoRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingStartInfoRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingStartInfo(this.appConfig.backendConfig.onboardingUrl),
      start,
    );

  startMoneyApp = (start: IZenAuthOnboardingStartMoneyAppRequest): Observable<IZenAuthOnboardingNextStepResponse> => {
    const payload = { ...start, clientId: this.config.clientId };
    return this.api.post<IZenAuthOnboardingStartMoneyAppRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingStartMoneyApp2(this.appConfig.backendConfig.onboardingUrl),
      payload,
    );
  };

  startMoneyAppAsync = (start: IZenAuthOnboardingStartMoneyAppRequest): Observable<IZenAuthOnboardingAvoClientInfo> => {
    const payload = { ...start, clientId: this.config.clientId };
    return this.api.post<IZenAuthOnboardingStartMoneyAppRequest, IZenAuthOnboardingAvoClientInfo>(
      onboardingStartMoneyAppAsync(this.appConfig.backendConfig.onboardingV2Url),
      payload,
    );
  };

  bankInfo = (): Observable<IZenAuthOnboardingBankClientInfo> => {
    return this.api.post<any, IZenAuthOnboardingBankClientInfo>(
      onboardingBankInfo(this.appConfig.backendConfig.apiUrl),
      {},
    );
  };

  avoInfo = (): Observable<IZenAuthOnboardingAvoClientInfo> => {
    return this.api.post<any, IZenAuthOnboardingAvoClientInfo>(
      onboardingAvoInfo(this.appConfig.backendConfig.apiUrl),
      {},
    );
  };

  editPersonalDetailsEmail = (
    request: IZenAuthPersonalInformationEditEmailRequest,
  ): Observable<IZenAuthPersonalInformationResponse> => {
    return this.api.post<IZenAuthPersonalInformationEditEmailRequest, IZenAuthPersonalInformationResponse>(
      editPersonalDetailsEmail(this.appConfig.backendConfig.apiUrl),
      request,
    );
  };

  startSocial = (start: IZenAuthOnboardingStartSocialRequest): Observable<IZenAuthOnboardingNextStepResponse> => {
    const payload = { ...start, clientId: this.config.clientId };
    return this.api.post<IZenAuthOnboardingStartSocialRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingStartSocial(this.appConfig.backendConfig.onboardingUrl),
      payload,
    );
  };

  startManual = (start: IZenAuthOnboardingStartManualRequest): Observable<IZenAuthOnboardingNextStepResponse> => {
    const payload = { ...start, clientId: this.config.clientId };
    return this.api.post<IZenAuthOnboardingStartManualRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingStartManual(this.appConfig.backendConfig.onboardingUrl),
      payload,
    );
  };

  otp = (otp: IZenAuthOnboardingOtpRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingOtpRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingOtp(this.appConfig.backendConfig.onboardingUrl),
      otp,
    );

  externalAuth = (
    externalAuth: IZenAuthOnboardingExternalAuthRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingExternalAuthRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingExternalAuth(this.appConfig.backendConfig.onboardingUrl),
      externalAuth,
    );
  address = (address: IZenAuthOnboardingAddressRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingAddressRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingAddress(this.appConfig.backendConfig.onboardingUrl),
      address,
    );

  mobiMoney = (onboardingId: IZenAuthOnboardingMobiMoneyRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingMobiMoney(this.appConfig.backendConfig.onboardingUrl),
      onboardingId,
    );

  securityQuestions = (
    securityQuestion: IZenAuthOnboardingSecurityQuestionsRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingSecurityQuestionsRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingSecurityQuestions(this.appConfig.backendConfig.onboardingUrl),
      securityQuestion,
    );

  postCheck = (): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(postCheck(this.appConfig.backendConfig.onboardingUrl));

  fistNameLastNameAuth = (
    firstNameLastName: IZenAuthOnboardingFirstNameLastNameAuthRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingFirstNameLastNameAuthRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingFirstNameLastNameAuth(this.appConfig.backendConfig.onboardingUrl),
      firstNameLastName,
    );

  businessTradingName = (
    businessTradingName: IZenAuthOnboardingBusinessTradingNameRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingBusinessTradingNameRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBusinessTradingName(this.appConfig.backendConfig.onboardingUrl),
      businessTradingName,
    );

  personalData = (
    personalData: IZenAuthOnboardingPersonalDataRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingPersonalDataRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingPersonalData(this.appConfig.backendConfig.onboardingUrl),
      personalData,
    );

  createUsernamePassword = (login: IZenAuthOnboardingLoginRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingLoginRequest, IZenAuthOnboardingNextStepResponse>(
      createUsernamePassword(this.appConfig.backendConfig.onboardingV2Url),
      login,
    );

  createSocialUsernamePassword = (
    login: IZenAuthOnboardingLoginRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingLoginRequest, IZenAuthOnboardingNextStepResponse>(
      createSocialUsernamePassword(this.appConfig.backendConfig.onboardingUrl),
      login,
    );

  businessDetails = (
    businessDetails: IZenAuthOnboardingBusinessDetailsRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingBusinessDetailsRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBusinessDetails(this.appConfig.backendConfig.onboardingUrl),
      businessDetails,
    );

  businessAddress = (businessAddress: IZenAuthBusinessAddressRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthBusinessAddressRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBusinessAddress(this.appConfig.backendConfig.onboardingUrl),
      businessAddress,
    );

  businessPartner = (
    businessPartner: IZenAuthOnboardingBusinessPartnerRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingBusinessPartnerRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBusinessPartners(this.appConfig.backendConfig.onboardingUrl),
      businessPartner,
    );

  businessCategoryInfo = (
    businessCategoryInfo: IZenAuthOnboardingBusinessCategoryInfoRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingBusinessCategoryInfoRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBusinessCategoryInfo(this.appConfig.backendConfig.onboardingUrl),
      businessCategoryInfo,
    );
  suggestUsername = (onboardingId: string): Observable<string[]> =>
    this.api.get<string[]>(suggestedUsernames(this.appConfig.backendConfig.onboardingUrl, onboardingId));

  success = (successData: IZenAuthOnboardingSuccessRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingSuccessRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingSuccess(this.appConfig.backendConfig.onboardingUrl),
      successData,
    );

  addressSuggest = (query: string): Observable<IZenAuthOnboardingAddressSuggestResponse[]> =>
    this.api.get<IZenAuthOnboardingAddressSuggestResponse[]>(
      onboardingAddressSuggestion(query, this.appConfig.backendConfig.onboardingUrl),
    );

  approveIt = (onboardingId: IZenAuthOnboardingApproveItRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingApproveItRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingApproveIt(this.appConfig.backendConfig.onboardingUrl),
      onboardingId,
    );

  progress = (onboardingId: IZenAuthOnboardingProgressRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingProgressRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingProgress(this.appConfig.backendConfig.onboardingUrl),
      onboardingId,
    );

  sentApproveIt = (
    onboardingId: IZenAuthOnboardingSentApproveItRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingSentApproveItRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingSentApproveIt(this.appConfig.backendConfig.onboardingUrl),
      onboardingId,
    );

  bankOtp = (request: IZenAuthBankOtpRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthBankOtpRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBankOtp(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  idPhoto = (idPhotos: IZenAuthOnboardingIdPhotoRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingIdPhotoRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingIdPhoto(this.appConfig.backendConfig.onboardingUrl),
      idPhotos,
    );

  checkBank = (
    request: IZenAuthCheckBankRequest,
    merchantId?: string,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingCheckBank(this.appConfig.backendConfig.onboardingUrl, merchantId),
      request,
    );

  stepup = (request: IZenAuthOnboardingStepupRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingStepup(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  healthStepup = (request: IZenAuthOnboardingStepupRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingHealtStepup(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  selectOtp = (request: IZenAuthOnboardingSelectOtpRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingSelectOtp(this.appConfig.backendConfig.apiUrl),
      request,
    );

  selectSocial = (request: IZenAuthOnboardingSelectSocialRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingSelectSocial(this.appConfig.backendConfig.apiUrl),
      request,
    );

  selectNedbank = (request: IZenAuthOnboardingSelectNedbankRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingSelectNedbank(this.appConfig.backendConfig.apiUrl),
      request,
    );

  signup = (request: IZenAuthOnboardingSignupRequest): Observable<IZenAuthSignupInfoResponse> => {
    const payload = { ...request, clientId: this.config.clientId };
    return this.api.post<IZenAuthOnboardingSignupRequest, IZenAuthSignupInfoResponse>(
      onboardingSignup(this.appConfig.backendConfig.apiUrl),
      payload,
    );
  };

  signupManual = (request: IZenAuthOnboardingSignupManualRequest): Observable<IZenAuthOnboardingNextStepResponse> => {
    const payload = { ...request, clientId: this.config.clientId };
    return this.api.post<IZenAuthOnboardingSignupManualRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingSignupManual(this.appConfig.backendConfig.apiUrl),
      payload,
    );
  };

  signupSocial = (request: IZenAuthOnboardingSignupRequest): Observable<IZenAuthOnboardingNextStepResponse> => {
    const payload = { ...request, clientId: this.config.clientId };
    return this.api.post<IZenAuthOnboardingSignupRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingSignupSocial(this.appConfig.backendConfig.apiUrl),
      payload,
    );
  };

  signupNedbank = (request: IZenAuthOnboardingSignupRequest): Observable<IZenAuthOnboardingNextStepResponse> => {
    const payload = { ...request, clientId: this.config.clientId };
    return this.api.post<IZenAuthOnboardingSignupRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingSignupNedbank(this.appConfig.backendConfig.apiUrl),
      payload,
    );
  };

  login = (request: IZenAuthLoginRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthLoginRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingLogin(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  checkLogin = (): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingCheckLogin(this.appConfig.backendConfig.onboardingUrl),
      {},
    );

  getReferralDetails = (code: string): Observable<IZenAuthOnboardingReferralDetail> =>
    this.api.get<IZenAuthOnboardingReferralDetail>(
      getReferralDetails(this.appConfig.backendConfig.onboardingUrl, code),
    );

  addMerchant = (): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<any, IZenAuthOnboardingNextStepResponse>(
      onboardingAddMerchant(this.appConfig.backendConfig.onboardingUrl),
      {},
    );

  bankphoneDifferent = (
    request: IZenAuthOnboardingBankphoneDifferentRequest,
  ): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthOnboardingBankphoneDifferentRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingBankphoneDifferent(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  guestLogin = (request: IZenAuthSocialByRequest): Observable<IZenGuestLoginResponse> =>
    this.api.post<IZenAuthSocialByRequest, IZenGuestLoginResponse>(
      guestLogin(this.appConfig.backendConfig.onboardingUrl),
      request,
    );

  infoStepUp = (request: IZenAuthInfoStepUpRequest): Observable<IZenAuthOnboardingNextStepResponse> =>
    this.api.post<IZenAuthInfoStepUpRequest, IZenAuthOnboardingNextStepResponse>(
      onboardingInfoStepup(this.appConfig.backendConfig.onboardingUrl),
      request,
    );
}
