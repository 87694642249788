import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { AirtimeDataService } from '../../../services/prepaid/airtimedata/prepaid-airtimedata.service';
import * as actions from './airtimedata.actions';
import * as EVENT from './airtimedata.events';
import { ApplicationFacade } from '../../application/application.facade';
import { failed, HttpErrorAction, succeed } from '../../application/task.actions';

@Injectable()
export class AirtimeDataEffects {
  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: AirtimeDataService) {}

  fetchRecentTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AirtimeDataRecentTransactionsRequest>(EVENT.RECENT_TRANSACTIONS_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchRecent(false).pipe(
          map(recent => succeed(new actions.AirtimeDataRecentTransactionsResponse(taskId, recent))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AirtimeDataProvidersRequest>(EVENT.ORDER_DETAIL_PROVIDERS_REQUEST),
      switchMap(({ taskId, onSucceed }) =>
        this.service.fetchProviders(false).pipe(
          map(providers => {
            if (onSucceed) onSucceed(providers);
            return succeed(new actions.AirtimeDataProvidersResponse(taskId, providers));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  placeOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AirtimeDataPlaceOrderRequest>(EVENT.PLACE_ORDER_REQUEST),
      switchMap(({ taskId, payload, onSucceed }) =>
        this.service.placeOrder(payload, false).pipe(
          map(response => {
            onSucceed();
            return succeed(new actions.AirtimeDataPlaceOrderResponse(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
}
