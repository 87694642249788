import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApplicationMaintenanceResponse } from '../store/models/store.models';
import { environment } from '@avo/environment/customer/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(private httpClient: HttpClient) {}
  checkMaintenance(): Observable<IApplicationMaintenanceResponse> {
    return this.httpClient.get<IApplicationMaintenanceResponse>(environment.maintenanceUrl);
  }
}
