import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appFocus]',
})
export class FocusDirective implements OnInit {
  @Input() applyFocus?: boolean;
  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    if (this.element.nativeElement['focus'] && this.applyFocus) {
      const input: HTMLInputElement = this.element.nativeElement as HTMLInputElement;
      if (input) {
        input.focus();
      }
    }
  }
}
