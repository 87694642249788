export interface IUpgradeWalletNextStepResponse {
  flowId: string;
  nextStep?: IUpgradeWalletNextStep;
  personalDataInfo?: IUpgradeWalletPersonalDataInfo;
  securityQuestionsInfo?: IUpgradeWalletSecurityQuestionsInfo;
  successInfo?: IUpgradeWalletSuccessInfo;
  errorMessageLocalisationKey?: string;
}

export interface IUpgradeWalletSuccessInfo {
  name: string;
}

export enum IUpgradeWalletNextStep {
  PERSONAL_DATA = 'PERSONAL_DATA',
  ADDRESS = 'ADDRESS',
  SECURITY_QUESTIONS = 'SECURITY_QUESTIONS',
  ID_PHOTO = 'ID_PHOTO',
  SUCCESS = 'SUCCESS',
  END = 'END',
  STEPUPEND = 'STEPUPEND',
  STEPUP_SUCCESS = 'STEPUP_SUCCESS',
}

export interface IUpgradeWalletPersonalDataInfo {
  occupationRequired: boolean;
  sourceOfIncomeRequired: boolean;
  industryRequired: boolean;
  sourceOfIncomes: IUpgradeWalletSourceOfIncome[];
  occupations: IUpgradeWalletOccupation[];
  industries: IUpgradeWalletIndustry[];
  defaultSourceOfIncomeId: string;
  defaultIndustryId: string;
  defaultOccupationId: string;
}

export interface IUpgradeWalletSourceOfIncome {
  id: string;
  name: string;
}

export interface IUpgradeWalletOccupation {
  id: string;
  name: string;
}

export interface IUpgradeWalletIndustry {
  id: string;
  name: string;
}

export interface IUpgradeWalletSecurityQuestionsInfo {
  questions: IUpgradeWalletSecurityQuestion[];
}

export interface IUpgradeWalletSecurityQuestion {
  id: string;
  text: string;
  options: IUpgradeWalletSecurityQuestionOption[];
}

export interface IUpgradeWalletSecurityQuestionOption {
  id: string;
  text: string;
}

export interface IUpgradeWalletSecurityQuestionsStepRequest {
  answers: IUpgradeWalletSecurityAnswer[];
}

export interface IUpgradeWalletSecurityAnswer {
  questionId: string;
  answerId: string;
}

export interface IUpgradeWalletAddressStepRequest {
  addressLine1: string;
  addressLine2: string;
  houseNumber: string;
  streetName: string;
  city: string;
  postalCode: string;
  suburb: string;
}

export interface IUpgradeWalletPersonalDataStepRequest {
  occupation: string;
  industry: string;
  sourceOfIncome: string;
}

export interface IUpgradeWalletIdPhotoStepRequest {
  type: IUpgradeWalletIdPhotoType;
  photosBase64: string[];
}

export enum IUpgradeWalletIdPhotoType {
  GREENBARCODEID = 'GREENBARCODEID',
  RSA = 'RSA',
}
