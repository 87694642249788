// just add here the lodash functions you want to support
import map from 'lodash-es/map';
import filter from 'lodash-es/filter';
import toPairs from 'lodash-es/toPairs';
import orderBy from 'lodash-es/orderBy';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';
import mapValues from 'lodash-es/mapValues';
import reverse from 'lodash-es/reverse';

const chainableFunctions = {
  map,
  filter,
  toPairs,
  orderBy,
  groupBy,
  sortBy,
  reverse,
};

/**
 * Custom chain function inspired by Lodash usable with lodash-es
 * @param input
 */
export const chain = <T>(input: T) => {
  let value: any = input;
  const wrapper = {
    ...mapValues(chainableFunctions, (f: any) => (...args: any[]) => {
      // lodash always puts input as the first argument
      value = f(value, ...args);
      return wrapper;
    }),
    value: () => value,
  };
  return wrapper;
};
