import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  AppConfig,
  AuthorizationService,
  BuildNumberService,
  CommonUtility,
  IAuthenticators,
} from '@wakanda/wakanda-core';

import isString from 'lodash-es/isString';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { ZenAuthFacade } from '../redux/zenAuth.facade';

@Injectable()
export class MerchantAuthorizationInterceptor implements HttpInterceptor, OnDestroy {
  private subscriptions = new Subscription();
  authenticators: IAuthenticators;

  constructor(
    private authorizeService: AuthorizationService,
    private appConfig: AppConfig,
    private buildNumberService: BuildNumberService,
    private authFacade: ZenAuthFacade,
  ) {
    this.subscriptions.add(
      this.authFacade.getTransactionAuthenticators$.subscribe(data => {
        this.authenticators = data;
      }),
    );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);
    const authenticators = this.authenticators;
    const token = this.authorizeService.getToken();
    const forbiddenIdempodentyUrls = ['/wallet/v1/referencedata/banks'];

    if (CommonUtility.getEnvironment() === 'preview') {
      request = request.clone({
        headers: request.headers.set('X-Canary', 'always'),
      });
    }

    // needed for v3 shopping buyer cart
    request = request.clone({
      headers: request.headers.set('X-AVO-CC', 'ZA'),
    });

    if (request.url.includes('loc-saas')) {
      request = request.clone({ headers: request.headers.set('ngsw-bypass', 'true') });
    }

    let deviceId = this.authorizeService.getDeviceId();
    if (!deviceId) {
      deviceId = uuidv4();
      this.authorizeService.setDeviceId(deviceId);
    }

    if (this.appConfig.backendConfig && !request.url.includes('loc-saas')) {
      request = request.clone({
        headers: request.headers
          .set('X-Avo-SessionId', this.appConfig.backendConfig.sessionId)
          .set('X-Avo-ApiVersion', this.appConfig.apiVersion)
          .set('X-Avo-DeviceId', deviceId),
      });
      if (this.buildNumberService.buildNumber) {
        request = request.clone({ headers: request.headers.set('X-AppBuildID', this.buildNumberService.buildNumber) });
      }
    }

    if (forbiddenIdempodentyUrls.some(url => !request.url.includes(url))) {
      if (request.url.includes('/onboarding/externalAuth') && request.body && request.body.code) {
        request = request.clone({ headers: request.headers.set('x-idempotency-key', request.body.code) });
      } else {
        request = request.clone({ headers: request.headers.set('x-idempotency-key', uuidv4()) });
      }
    }

    if (!!token && isString(token)) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }

    if (!!authenticators && !!authenticators.xAuthTXId && !!authenticators.xAuthTXToken) {
      request = request.clone({
        headers: request.headers
          .set('X-AUTH-TX-ID', authenticators.xAuthTXId)
          .set('X-AUTH-TX-TOKEN', authenticators.xAuthTXToken),
      });
      return next.handle(request).pipe(finalize(() => this.authFacade.saveTransactionSigningAuthenticators(null)));
    }

    return next.handle(request);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
