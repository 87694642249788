import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HashStringPrefix } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-push-notification',
  template: `
    <div class="ui-push-notification" [attr.id]="text | hashString: hashType.MESSAGE">
      {{ text || 'Done' }}
    </div>
  `,
})
export class PushNotificationComponent implements OnInit {
  hashType = HashStringPrefix;
  @Input() taskId: string;
  @Input() text: string;
  @Output() onEventClean = new EventEmitter<string>();

  ngOnInit(): void {
    setTimeout(() => this.onEventClean.emit(this.taskId), 3200);
  }
}
