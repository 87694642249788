import { NgModule } from '@angular/core';
import { WakandaUiModule } from '@wakanda/wakanda-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FaqPanelComponent } from './faq-panel/faq-panel.component';
import { BusinessCardComponent } from './business-card/business-card.component';
import { ContactSupportCardComponent } from './contact-support-card/contact-support-card.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { SetupStepsComponent } from './setup-steps/setup-steps.component';
import { SellingToBusinessComponent } from './selling-to-business/selling-to-business.component';
import { DeliveryByAvoComponent } from './delivery-by-avo/delivery-by-avo.component';
import { SellingToBusinessLoaderComponent } from './selling-to-business-loader/selling-to-business-loader.component';
import { SellingToBussinessStepsComponent } from './selling-to-bussiness-steps/selling-to-bussiness-steps.component';
import { TableBasicComponent } from './table-basic/table-basic.component';
import { TableRowComponent } from './table-basic/table-row/table-row.component';
import { TreeSetupStepsComponent } from './tree-setup-steps/tree-setup-steps.component';
import { FaqQuestionsComponent } from './faq-questions/faq-questions.component';
import { BenefitPanelComponent } from './benefit-panel/benefit-panel.component';

@NgModule({
  declarations: [
    FaqPanelComponent,
    BusinessCardComponent,
    ContactSupportCardComponent,
    DownloadAppComponent,
    SetupStepsComponent,
    SellingToBusinessComponent,
    DeliveryByAvoComponent,
    SellingToBusinessLoaderComponent,
    SellingToBussinessStepsComponent,
    TableBasicComponent,
    TableRowComponent,
    TreeSetupStepsComponent,
    FaqQuestionsComponent,
    BenefitPanelComponent,
  ],
  imports: [WakandaUiModule, FormsModule, ReactiveFormsModule, CommonModule],
  providers: [],
  exports: [
    WakandaUiModule,
    FaqPanelComponent,
    BusinessCardComponent,
    ContactSupportCardComponent,
    DownloadAppComponent,
    SetupStepsComponent,
    SellingToBusinessComponent,
    DeliveryByAvoComponent,
    SellingToBussinessStepsComponent,
    TableBasicComponent,
    TreeSetupStepsComponent,
    FaqQuestionsComponent,
    BenefitPanelComponent,
  ],
})
export class SharedModule {}
