import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-chat-panel',
  template: `
    <ui-skeleton-screen [canShow]="canShow" [list]="true" times="2">
      <div class="title">
        <h3>{{ title }}</h3>
        <b (click)="onCollapse()">X</b>
      </div>
    </ui-skeleton-screen>
    <section
      class="content"
      [ngClass]="{
        collapsed: !!isCollapsed || !!isClosed
      }"
    >
      <ng-content></ng-content>
    </section>
  `,
})
export class ChatPanelComponent {
  @Input() title: string;
  @Input() isCollapsed: boolean;
  @Input() canShow: boolean;
  @Input() withIcon: boolean;
  @Output() onClose = new EventEmitter<boolean>();
  isClosed: boolean;

  onCollapse = (): void => {
    if (typeof this.isCollapsed !== 'undefined') {
      this.isClosed = !this.isCollapsed;
    } else this.isClosed = !this.isClosed;
    this.onClose.emit(this.isClosed);
  };
}
