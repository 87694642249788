import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'src',
})
export class SrcPipe implements PipeTransform {
  transform(value?: SafeUrl | string, isOrch = false): SafeUrl | string | undefined {
    if (!isOrch) return value;

    return `./assets/Icon/${value}.svg`;
  }
}
