const dashboard = (baseUrl: string): string => `${baseUrl}/dashboard/v1`;
const dashboardV2 = (baseUrl: string): string => `${baseUrl}/dashboard/v2`;
const orderService = (baseUrl: string): string => `${baseUrl}/order-services/v1`;
//v3
const search = (baseUrl: string): string => `${baseUrl}/search`;

export const homeSearchInit = (baseUrl: string): string => `${dashboard(baseUrl)}/search/recent`;

export const homeSearchSuggestions = (baseUrl: string, query?: string, location?: string): string =>
  `${dashboard(baseUrl)}/search/suggestion?query=${query}&location=${location}`;

export const homeSearchResults = (baseUrl: string, query: string, location: string, categoryId?: string): string =>
  `${dashboardV2(baseUrl)}/search?query=${query}&location=${location}${categoryId ? '&categoryId=' + categoryId : ''}`;

export const homeSearchClick = (baseUrl: string): string => `${dashboard(baseUrl)}/searchClick`;

export const homeServiceSearchRecent = (baseUrl: string): string => `${dashboard(baseUrl)}/search/recent`;

export const homeServiceSearchPopular = (baseUrl: string): string =>
  `${orderService(baseUrl)}/customer/category/popular`;

//v3
export const product = (baseUrl: string, request: any): string =>
  `${search(baseUrl)}/product?${new URLSearchParams(request).toString()}`;

export const productNamedSearch = (baseUrl: string, filterName: any): string =>
  `${search(baseUrl)}/product/named/${filterName}`;

export const storesB2B = (baseUrl: string): string => `${search(baseUrl)}/stores/b2b`;
