export enum AdvertisingScreenType {
  HOMEPAGE = 'HOMEPAGE',
  PRODUCT_DETAILS = 'PRODUCT_DETAILS',
  ESHOP_CATEGORY = 'ESHOP_CATEGORY',
  VOUCHERS = 'VOUCHERS',
  VAS_AIRTIME = 'VAS_AIRTIME',
  VAS_ELECTRICITY = 'VAS_ELECTRICITY',
  VAS = 'VAS',
  SERVICES = 'SERVICES',
  HEALTH_LANDING = 'HEALTH_LANDING',
}

export interface IAdvertisingBannerRequest {
  screenId: AdvertisingScreenType;
  referenceObjectId?: string;
}

export interface IAdvertisingBannerResponse {
  positions: IAdvertisingBannerPosition;
}

export type IAdvertisingBannerPosition = {
  [positionId in AdvertisingPositionId]?: IAdvertisingBanner[];
};

export enum AdvertisingPositionId {
  DEFAULT = 'DEFAULT',
  HERO = 'HERO',
  B_GRADE = 'B_GRADE',
}

export interface IAdvertisingBanner {
  id: string;
  image: IAdvertisingBannerImage;
  showTimeSeconds?: number;
  clickUrl?: string;
  showPopup?: boolean;
  popupTitle?: string;
  popupDescription?: string;
}

export interface IAdvertisingPositionIdBanner {
  positionId: AdvertisingPositionId;
  banner: IAdvertisingBanner;
}

export interface IAdvertisingBannerClickRequest {
  screenId: AdvertisingScreenType;
  positionId: AdvertisingPositionId;
}

export interface IAdvertisingBannerFormRequest {
  screenId: AdvertisingScreenType;
  positionId: AdvertisingPositionId;
  name: string;
  surname: string;
  email: string;
  phone: string;
  locationDescription: string;
}

export interface IAdvertisingBannerImage {
  variants: IAdvertisingBannerImageVariant[];
  altText?: string;
  recommended?: IAdvertisingBannerImageVariant;
}

export interface IAdvertisingBannerImageVariant {
  url: string;
  height: number;
  width: number;
}
