import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

export interface ICarouselItem {
  title?: string;
  description?: string;
  image?: string;
  imagePosition?: number;
  imageSrc?: string;
  svg?: string;
  url?: string;
  adobeViewName?: string;
  disabled?: boolean;
  extraButton?: string;
  data?: any;
  bannerUrl?: string;
  rotateInSeconds?: number;
}

@Component({
  selector: 'ui-carousel',
  templateUrl: './carousel.component.html',
})
export class CarouselComponent implements OnInit, OnDestroy, OnChanges {
  // get a reference to the component DOM element to set up the swipe event on
  constructor(private elRef: ElementRef) {}

  // define some scoped variables
  marginLeft = 0;
  width: number;
  clientXPosition = null;
  componentDOM = null;
  contentDOM = null;
  outerWrapperDOM = null;
  slideCount = 0;
  offscreenWidth: number;
  rotateTimeout: any;
  private _active = 0;
  private readonly DEFAULT_ROTATION_IN_SECONDS = 5;

  @ViewChild('outerWrapper') outerWrapper: ElementRef;

  @Input() items: ICarouselItem[];

  @Input() minHeight: string;

  @Input() maxHeight: string;

  @Input() rightMargin = 0;

  @Input() initialLeftMargin: number;

  @Input() share: boolean;

  @Input() favouriteActive: boolean;

  @Input() favourite: boolean;

  @Input() navigation: boolean;

  @Input() scrollElementSelector: string;

  @Input() restrictHeight: boolean;

  @Input() fullWidth: boolean;

  @Input() iconWidth: string;

  @Input() autoRotate: boolean;

  @Input() alignCenter: boolean;

  @Input() cover: boolean;

  @Output() onShare = new EventEmitter<ICarouselItem>();

  @Output() onFavourite = new EventEmitter<ICarouselItem>();

  @Output() onClick = new EventEmitter<ICarouselItem>();

  @Output() onChange = new EventEmitter<ICarouselItem>();

  @Output() onExtraButtonClick = new EventEmitter<ICarouselItem>();

  @Input()
  public set active(value: number) {
    this._active = value;
    if (this.outerWrapperDOM) {
      this.changeActive(this.outerWrapper.nativeElement);
    }
  }

  public get active() {
    return this._active ?? 0;
  }

  changeActive = (outerWrapper): void => {
    this.width = this.scrollElementSelector
      ? this.componentDOM.getElementsByTagName(this.scrollElementSelector)[0].offsetWidth
      : outerWrapper.clientWidth;

    let marginLeft = this.active * (this.width + this.rightMargin);

    if (this.scrollElementSelector) {
      this.offscreenWidth = this.contentDOM.offsetWidth - this.outerWrapperDOM.offsetWidth - marginLeft;

      if (this.offscreenWidth < 0) {
        marginLeft += this.offscreenWidth;

        if (this.initialLeftMargin) {
          marginLeft += this.initialLeftMargin * 2;
        }
      }
    }

    if (this.initialLeftMargin) {
      marginLeft -= this.initialLeftMargin;
    }

    if (this.active === 0 && this.initialLeftMargin) {
      this.marginLeft = this.initialLeftMargin;
    } else {
      this.marginLeft = -Math.abs(marginLeft);
    }

    this.onChange.emit(this.items[this.active]);
  };

  handleChange = (event: MouseEvent): void => {
    const { target } = event;

    this._active = parseInt(target['name'], 0);

    // pass the element as a list to match the swipe event
    this.changeActive(this.outerWrapper.nativeElement);
  };

  // function to capture interaction start
  lock = (e: MouseEvent): void => {
    this.clientXPosition = this.unify(e).clientX;
  };

  // function to capture interaction end
  move = (e: MouseEvent): void => {
    if (this.clientXPosition || this.clientXPosition === 0) {
      const dx = this.unify(e).clientX - this.clientXPosition;
      const s = Math.sign(dx);

      // s is -1 > 1 depending on left/right scroll
      if (s > 0) {
        // swipe right
        if (this.active > 0) {
          // decrement active
          this._active = this.active - 1;
          // trigger the slide change event
          this.changeActive(this.outerWrapperDOM);
        }
      } else if (s < 0) {
        // swipe left
        if (this.active < this.slideCount - 1) {
          if (!this.scrollElementSelector || !this.offscreenWidth || this.offscreenWidth > 0) {
            // increment active
            this._active = this.active + 1;
            // trigger the slide change event
            this.changeActive(this.outerWrapperDOM);
          }
        }
      }

      // reset the direction measure
      this.clientXPosition = null;
      if (this.autoRotate) {
        this.startRotation();
      }
    }
  };

  // TODO - be more specific about event types here, TouchEvent or MouseEvent
  unify = (e: any): any => (e.changedTouches ? e.changedTouches[0] : e);

  // attach event handlers
  initSwipe = (): void => {
    this.componentDOM.addEventListener('mousedown', this.lock, false);
    this.componentDOM.addEventListener('touchstart', this.lock, { passive: true });

    this.componentDOM.addEventListener('mouseup', this.move, false);
    this.componentDOM.addEventListener('touchend', this.move, { passive: true });
  };

  // only init once DOM is ready
  ngOnInit(): void {
    this.componentDOM = this.elRef.nativeElement;
    this.contentDOM = this.componentDOM.getElementsByClassName('content')[0];
    this.outerWrapperDOM = this.componentDOM.getElementsByClassName('outer-wrapper')[0];
    this.slideCount = this.items.filter(item => !item.disabled).length;
    this.marginLeft = this.initialLeftMargin ? this.initialLeftMargin : 0;
    if (this.autoRotate) {
      this.startRotation();
    }
    this.initSwipe();
  }

  ngOnDestroy(): void {
    if (this.rotateTimeout) {
      clearTimeout(this.rotateTimeout);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items?.currentValue) {
      this.slideCount = this.items.filter(item => !item.disabled).length;
    }
  }

  startRotation(): void {
    if (this.rotateTimeout) {
      clearTimeout(this.rotateTimeout);
    }
    const intervalinSec = this.items[this.active].rotateInSeconds || this.DEFAULT_ROTATION_IN_SECONDS;
    this.rotateTimeout = setTimeout(() => {
      this.processRotation();
    }, intervalinSec * 1000);
  }

  processRotation(): void {
    if (this.active < this.slideCount - 1) {
      this._active = this.active + 1;
    } else {
      this._active = 0;
    }
    this.changeActive(this.outerWrapperDOM);
    const intervalinSec = this.items[this.active].rotateInSeconds || this.DEFAULT_ROTATION_IN_SECONDS;
    if (intervalinSec) {
      this.rotateTimeout = setTimeout(() => {
        this.processRotation();
      }, intervalinSec * 1000);
    }
  }
}
