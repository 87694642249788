import { Pipe, PipeTransform } from '@angular/core';
import { AvoCurrencyPipe } from './avo-currency.pipe';

@Pipe({
  name: 'creditCurrency',
})
export class CreditCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: AvoCurrencyPipe) {}

  transform(value: number, countryCode: string | undefined): string {
    const formatted = this.currencyPipe.transform(value, countryCode);
    return formatted.replace('.00', '');
  }
}
