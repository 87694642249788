/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Pipe, PipeTransform } from '@angular/core';
import orderBy from 'lodash-es/orderBy';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform<T>(value: T[], order: 'asc' | 'desc' = 'asc', column: string): T[] {
    if (!value) return value;
    if (value?.length <= 1) return value;
    if (!column || column === '') {
      if (order === 'asc') {
        return value?.sort();
      } else {
        return value?.sort()?.reverse();
      }
    } // sort 1d array
    // @ts-ignore
    return orderBy(value, [column], [order]);
  }
}
