import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, of } from 'rxjs';
import { catchError, finalize, map, switchMap, takeUntil } from 'rxjs/operators';
import * as actions from './search.actions';
import * as EVENT from './search.events';
import { SearchService } from '../../services/search/search.service';
import { ApplicationFacade } from '../application/application.facade';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';

@Injectable()
export class SearchEffects {
  constructor(private actions$: Actions, private service: SearchService, private appFacade: ApplicationFacade) {}

  fetchSearchInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchInitRequest>(EVENT.INIT_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchHomeSearchInit().pipe(
          map(response => succeed(new actions.SearchInitReceived(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchHomeSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchResultsRequest>(EVENT.RESULTS_REQUEST),
      switchMap(({ taskId, query, location, categoryId, onSucceed }) =>
        this.service.fetchHomeSearchResults(query, location, categoryId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.SearchResultsReceived(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  homeSearchClick$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchClickRequest>(EVENT.RESULT_CLICK_REQUEST),
      switchMap(({ taskId, query }) =>
        this.service.homeSearchClick(query).pipe(
          map(() => succeed(new actions.SearchClickReceived(taskId))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error, true)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchHomeSearchSuggestions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchSuggestionsRequest>(EVENT.SUGGESTIONS_REQUEST),
      switchMap(({ taskId, query, location }) =>
        this.service.fetchHomeSearchSuggestions(query, location).pipe(
          map(response => succeed(new actions.SearchSuggestionsReceived(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), query))),
          finalize(() => this.appFacade.finalize(taskId)),
          takeUntil(this.actions$.pipe(ofType(EVENT.CANCEL_SUGGESTIONS))),
        ),
      ),
    ),
  );

  fetchServiceSearchPopular$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchInitRequest>(EVENT.SERVICE_POPULAR_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchHomeServiceSearchPopular().pipe(
          map(response => succeed(new actions.ServiceSearchPopularReceived(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  // v3

  fetchProductSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchProductRequest>(EVENT.SEARCH_PRODUCT_REQUEST),
      switchMap(({ taskId, request, onSucceed }) =>
        this.service.fetchProductSearch(request).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.SearchProductReceived(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchB2BStoreSearchResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchB2BStoreRequest>(EVENT.SEARCH_B2B_STORE_REQUEST),
      switchMap(({ taskId, request, onSucceed }) =>
        this.service.fetchB2BStoreSearch(request).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.SearchB2BStoreReceived(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchProductSearchNamedResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SearchProductNamedRequest>(EVENT.SEARCH_PRODUCT_NAMED_REQUEST),
      mergeMap(({ taskId, filterName, onSucceed }) =>
        this.service.fetchProductNamedSearch(filterName).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.SearchProductNamedReceived(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
}
