import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  IZenAuthNextStepTransactionSigningResponse,
  IZenAuthTransactionSigningAuthorizationHeaders,
  IZenAuthTransactionSigningAuthorizeRequest,
} from '@wakanda/zenauth';
import { ModalType } from '../modal/modal.component';

@Component({
  selector: 'ui-authorization-dialog-approveit',
  template: `
    <ui-modal
      [modal]="modalTypes.SLIDE_IN_UP"
      [title]="'transaction_sign.approveit_sent.approveit' | translate"
      [isVisible]="true"
      [template]="authorizationDialogTemplate"
      [closeButton]="true"
      (onCloseClick)="onCancel.emit()"
    >
      <ng-template #authorizationDialogTemplate>
        <div class="padded-content">
          <ui-divider></ui-divider>

          <ng-container *ngIf="!nextStep?.approveItMethod?.tryAgain; else tryAgainTemplate">
            <h3 class="text-align-center">
              {{
                'transaction_sign.approveit_sent.title$' | translate : { $1: nextStep?.approveItMethod?.sentToNumber }
              }}
            </h3>
            <ui-divider [compact]="true"></ui-divider>
            <p class="text-align-center small light" zen-translate>transaction_sign.approveit_sent.message</p>

            <ui-divider></ui-divider>
            <h1 class="text-align-center">{{ remainingSeconds }}</h1>
            <ui-divider></ui-divider>
          </ng-container>
        </div>
      </ng-template>

      <ng-template #tryAgainTemplate>
        <p class="text-align-center light" zen-translate>transaction_sign.approveit_tryagain.message</p>
        <ui-divider></ui-divider>
        <ui-button
          aligned="bottom"
          [callToActionButton]="true"
          (onClick)="onConfirmResend()"
          [selectButton]="true"
          [content]="'transaction_sign.approveit_tryagain.button' | translate"
        >
        </ui-button>
      </ng-template>
    </ui-modal>
  `,
})
export class AuthorizationDialogApproveitComponent implements OnInit, OnDestroy {
  @Input() nextStep: IZenAuthNextStepTransactionSigningResponse;
  @Input() transactionAuthId: string;
  @Output() onCancel = new EventEmitter();
  @Output() onConfirm = new EventEmitter<IZenAuthTransactionSigningAuthorizeRequest>();
  @Output() onSucceed = new EventEmitter<IZenAuthTransactionSigningAuthorizationHeaders>();
  //
  modalTypes = ModalType;
  timeToWait = 5;
  remainingSeconds = 90;
  countDownInterval;
  resendTimeout;

  ngOnInit(): void {
    this.startTimerCounter();
    this.startTimeout(this.nextStep?.approveItMethod?.timeToWait || this.timeToWait);
  }

  onConfirmResend = (): void => {
    const request: IZenAuthTransactionSigningAuthorizeRequest = {
      transactionAuthId: this.transactionAuthId,
      authRequest: {
        approveItAuth: {
          requestStatus: false,
        },
      },
      // extra call back after succeeded authorization, which call original action enriched with headers
      onSucceed: (authHeaders: IZenAuthTransactionSigningAuthorizationHeaders): void =>
        this.onSucceed.emit(authHeaders),
      onData: (response: IZenAuthNextStepTransactionSigningResponse): void => {
        if (response?.approveItMethod?.tryAgain) {
          clearInterval(this.countDownInterval);
          return;
        }
        if (!!response?.approveItMethod && this.remainingSeconds > 0) {
          this.startTimeout(response?.approveItMethod?.timeToWait || this.timeToWait);
        }
      },
      onFailure: (): void => {},
    };
    this.onConfirm.emit(request);
  };

  startTimerCounter = (): void => {
    this.countDownInterval = setInterval(() => {
      if (this.remainingSeconds === 0) {
        clearInterval(this.countDownInterval);
      } else {
        this.remainingSeconds--;
      }
    }, 1000);
  };

  startTimeout(timeToWait: number): void {
    this.resendTimeout = setTimeout(() => {
      this.onConfirmResend();
    }, timeToWait * 1000);
  }

  clearAllIntervals = (): void => {
    clearTimeout(this.resendTimeout);
    clearInterval(this.countDownInterval);
  };

  ngOnDestroy(): void {
    this.clearAllIntervals();
  }
}
