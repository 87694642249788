import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'ui-container',
  template: `
    <div
      class="ui-container"
      [ngClass]="{
        'borderless': borders === 'none',
        'compact': !!compact,
        'with-navigation': !!withNavigation,
        'left': !!leftAlign,
        'right': !!rightAlign,
        'no-bottom-padding': !!noBottomPadding,
        'no-top-padding': !!noTopPadding,
        'transparent': !!transparent,
        'gold-background': background === 'gold',
        'stretch-container': stretch
      }"
      #scrollingContent
      id="scrollingContent"
      (scroll)="handleScroll($event)"
    >
      <div class="container-content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class ContainerComponent implements OnInit, AfterViewChecked {
  @Input() compact: boolean;
  @Input() borders: string;
  @Input() leftAlign: boolean;
  @Input() rightAlign: boolean;
  @Input() noBottomPadding: boolean;
  @Input() noTopPadding: boolean;
  @Input() transparent: boolean;
  @Input() withNavigation: boolean;
  @Input() stretch = false;
  @Input() background: 'gold';

  // scrolling
  disableAutoScrollDown: boolean;
  @ViewChild('scrollingContent', { static: true }) scrollingContent: ElementRef;
  @Input() autoScrollBottom: boolean;
  @Output() onScroll = new EventEmitter<any>();

  private scrollToBottom = (): void => {
    if (this.disableAutoScrollDown) return;
    const { nativeElement } = this.scrollingContent;
    nativeElement.scrollTo({ top: nativeElement.scrollHeight, behavior: 'smooth' });
  };

  handleScroll = (event: any): void => {
    if (event) this.onScroll.emit(event);

    const { nativeElement } = this.scrollingContent;
    const atBottom = nativeElement.scrollHeight - nativeElement.scrollTop === nativeElement.clientHeight;

    if (this.disableAutoScrollDown && atBottom) {
      this.disableAutoScrollDown = false;
      return;
    }

    this.disableAutoScrollDown = true;
  };

  ngOnInit(): void {
    if (this.autoScrollBottom) this.scrollToBottom();
  }

  ngAfterViewChecked(): void {
    if (this.autoScrollBottom) this.scrollToBottom();
  }
}
