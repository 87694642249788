import * as actions from './airtimedata.actions';
import * as EVENT from './airtimedata.events';
import {
  IAirtimeDataProvider,
  IOrderDetailForPayment,
  IPurchaseAirtimeDataCurrentOrder,
  IRecentAirtimedataPurchase,
} from '../../../models/prepaids.model';
import { Action } from '@ngrx/store';

export const AIRTIME_DATA_FEATURE_KEY = 'airtimeData';

export interface IAirtimeDataState {
  recentTransactions?: IRecentAirtimedataPurchase[];
  providers?: IAirtimeDataProvider[];
  currentOrder?: IPurchaseAirtimeDataCurrentOrder;
  placedOrder?: IOrderDetailForPayment;
}

export interface IAirtimeDataPartialState {
  readonly [AIRTIME_DATA_FEATURE_KEY]: IAirtimeDataState;
}

export function airtimeDataReducer(state: IAirtimeDataState = {}, action: Action): IAirtimeDataState {
  const airtimeDataActions = action as actions.AirtimeDataActions;

  switch (airtimeDataActions.type) {
    case EVENT.RECENT_TRANSACTIONS_RECEIVED: {
      return Object.assign({}, state, {
        recentTransactions: airtimeDataActions.payload,
      });
    }
    case EVENT.CREATE_ORDER_EDIT: {
      return Object.assign({}, state, {
        currentOrder: airtimeDataActions.payload,
      });
    }
    case EVENT.ORDER_DETAIL_PROVIDERS_RECEIVED: {
      return Object.assign({}, state, {
        providers: airtimeDataActions.payload,
      });
    }

    case EVENT.PLACE_ORDER_RECEIVED: {
      return Object.assign({}, state, {
        placedOrder: airtimeDataActions.payload,
      });
    }

    default:
      return state;
  }
}
