import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { AppConfig } from '../../configs/app.config';
import { Observable } from 'rxjs';
import * as endpoints from './email-verification.endpoints';

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationService {
  constructor(private apiService: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (): string => this.appConfig.backendConfig.apiUrl;

  public verifyEmail(token: string): Observable<any> {
    return this.apiService.post<{ token: string }>(endpoints.verifyEmail(this.getApiUrl()), { token });
  }
}
