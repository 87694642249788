import { Injectable } from '@angular/core';
import { AppConfig, IChatHistoryRoom, IWalletCheckPayResponse } from '@wakanda/wakanda-core';
import { Observable } from 'rxjs';
import * as endpoints from '../endpoints/chat.endpoint';
import {
  IChatGetMessagesRequest,
  IChatGetRoomMessagesResponse,
  IChatHeyNedEnabledCheckResponse,
  IChatHeyNedRoom,
  IChatHeyNedRoomRequest,
  IChatHeyNedSubscriptionCheckResponse,
  IChatImageFileResponse,
  IChatMatrixMessageTypeContent,
  IChatRoom,
  IChatRoomId,
  IChatSendEventRequest,
} from '../model/chat.model';
import { ChatFacade } from '../state/chat.facade';
import { ApiService } from './api.service';

@Injectable()
export class ChatService {
  nextBatch: string;
  constructor(private api: ApiService, chatFacade: ChatFacade, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiUrl;

  public getRoomIdForSupport(useMockApi = false): Observable<IChatRoomId> {
    return this.api.get<IChatRoomId>(endpoints.getRoomForSupport(this.getApiUrl(useMockApi)));
  }

  public getRoomIdForConcierge(useMockApi = false): Observable<IChatRoomId> {
    return this.api.get<IChatRoomId>(endpoints.getRoomForConcierge(this.getApiUrl(useMockApi)));
  }

  public createRoomIdForConcierge(useMockApi = false): Observable<IChatRoomId> {
    return this.api.post<IChatRoomId, any>(endpoints.getRoomForConcierge(this.getApiUrl(useMockApi)));
  }

  public getRoomIdForJob(jobId: string, useMockApi = false): Observable<IChatRoomId> {
    return this.api.get<IChatRoomId>(endpoints.getRoomForSpecificJob(this.getApiUrl(useMockApi), jobId));
  }

  public getRoomIdForJobAndMerchant(jobId: string, merchantId: string, useMockApi = false): Observable<IChatRoomId> {
    return this.api.get<IChatRoomId>(
      endpoints.getRoomForSpecificJobAndMerchant(this.getApiUrl(useMockApi), jobId, merchantId),
    );
  }

  public getRoom(roomId: string, useMockApi = false): Observable<IChatRoom> {
    return this.api.get<IChatRoom>(endpoints.getRoom(this.getApiUrl(useMockApi), roomId));
  }

  public sendEvent(request: IChatSendEventRequest, useMockApi = false): Observable<any> {
    return this.api.put<IChatMatrixMessageTypeContent, any>(
      endpoints.sendEvent(this.getApiUrl(useMockApi), request.roomId, request.eventType, request.txnId),
      request.payload,
    );
  }

  public getMessages(request: IChatGetMessagesRequest, useMockApi = false): Observable<IChatGetRoomMessagesResponse> {
    return this.api.get<IChatGetRoomMessagesResponse>(
      endpoints.getRoomMessages(this.getApiUrl(useMockApi), request.roomId, request.from, request.to, request.limit),
    );
  }

  public uploadImage(file: FormData, useMockApi = false): Observable<IChatImageFileResponse> {
    return this.api.post(endpoints.uploadImage(this.getApiUrl(useMockApi)), file);
  }

  public getImage(useMockApi = false): Observable<any> {
    return this.api.get(endpoints.getImage(this.getApiUrl(useMockApi)));
  }

  public fetchInfPayment(jobId: string, quoteId: string, useMockApi = false): Observable<any> {
    return this.api.get(endpoints.fetchInfPayment(this.getApiUrl(useMockApi), jobId, quoteId));
  }

  public createHeyNedRoom(request: IChatHeyNedRoomRequest, useMockApi = false): Observable<IChatHeyNedRoom> {
    return this.api.post(endpoints.createHeyNedRoom(this.getApiUrl(useMockApi)), request);
  }

  public createHeyNedHealthRoom(request: IChatHeyNedRoomRequest, useMockApi = false): Observable<IChatHeyNedRoom> {
    return this.api.post(endpoints.createHeyNedHealthRoom(this.getApiUrl(useMockApi)), request);
  }

  public sendPayment(roomId: string, paymentId: string, usedPoints: number, useMockApi = false): Observable<any> {
    return this.api.post(endpoints.sendPayment(this.getApiUrl(useMockApi), roomId, paymentId), { usedPoints });
  }

  public fetchPayCheck(roomId: string, paymentId: string, useMockApi = false): Observable<IWalletCheckPayResponse> {
    return this.api.get(endpoints.fetchPayCheck(this.getApiUrl(useMockApi), roomId, paymentId));
  }

  public heyNedSubscriptionCheck(useMockApi = false): Observable<IChatHeyNedSubscriptionCheckResponse> {
    return this.api.get(endpoints.heyNedSubscriptionCheck(this.getApiUrl(useMockApi)));
  }

  public heyNedEnabledCheck(useMockApi = false): Observable<IChatHeyNedEnabledCheckResponse> {
    return this.api.get(endpoints.heyNedEnabledCheck(this.getApiUrl(useMockApi)));
  }

  public fetchChatHistoryOngoing(page: number, size: number, useMockApi = false): Observable<IChatHistoryRoom[]> {
    return this.api.get(endpoints.fetchChatHistoryOngoing(this.getApiUrl(useMockApi), page, size));
  }

  public fetchChatHistoryCompleted(page: number, size: number, useMockApi = false): Observable<IChatHistoryRoom[]> {
    return this.api.get(endpoints.fetchChatHistoryCompleted(this.getApiUrl(useMockApi), page, size));
  }

  public closeChatRoom(chatId: string, useMockApi = false): Observable<IChatHeyNedRoom> {
    return this.api.post(endpoints.closeChat(this.getApiUrl(useMockApi), chatId), {});
  }

  public searchChatHistory(query: string, useMockApi = false): Observable<IChatHistoryRoom[]> {
    return this.api.get(endpoints.searchChatHistory(this.getApiUrl(useMockApi), query));
  }

  public heyNedOpenedTask(useMockApi = false): Observable<IChatHeyNedRoom> {
    return this.api.get(endpoints.heyNedOpenedTask(this.getApiUrl(useMockApi)));
  }
}
