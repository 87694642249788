<!-- Horizontal step progress bar -->
<ng-container *ngIf="!vertical">
  <div class="ui-step-progress-bar">
    <ul>
      <li
        [id]="item.id"
        *ngFor="let item of items; let i = index"
        [ngClass]="{
          active: item.active,
          checked: item.checked,
          pending: item.pending,
          cancelled: item.cancelled,
          first: i === 0
        }"
      >
        <ui-icon style="margin: 0 auto" [icon]="item | stepIcon"></ui-icon>
      </li>
    </ul>
    <div class="description">
      <b [id]="item.id" *ngFor="let item of items" class="small">{{ item.description | translate }}</b>
    </div>
  </div>
</ng-container>

<!-- Vertical Step Progress Bar -->
<ng-container *ngIf="!!vertical">
  <div class="ui-step-progress-bar vertical">
    <ul>
      <li
        [id]="item.id"
        *ngFor="let item of items; let i = index"
        [ngClass]="{
          active: item.active,
          checked: item.checked,
          pending: item.pending,
          cancelled: item.cancelled,
          first: i === 0
        }"
      >
        <ui-icon style="margin: 0 auto" [icon]="item | stepIcon"></ui-icon>
        <div class="description">
          <b [id]="item.id" class="small">{{ item.description | translate }}</b>
          <p *ngIf="item?.sub" class="small sub-description" [innerHTML]="item.sub | translate"></p>
          <ui-divider [compact]="true"></ui-divider>
          <ui-button
            [selectButton]="item.selectButton"
            [disabled]="item.buttonDisabled"
            [content]="item.buttonContent"
            [icon]="item.buttonIcon"
            [iconSize]="'1rem'"
            [dropdown]="item.buttonDropdown"
            (onClick)="item.onButton()"
            *ngIf="item.buttonContent && item.onButton"
          ></ui-button>
        </div>
      </li>
    </ul>
  </div>
</ng-container>
