import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MyRewardsService } from '../../services/my-rewards/my-rewards.service';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';
import * as actions from './my-rewards.actions';
import { EVENTS } from './my-rewards.events';

@Injectable()
export class MyRewardsEffects {
  fetchTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetTransactionsRequestAction>(EVENTS.MY_REWARDS_TRANSACTIONS_REQUEST),
      switchMap(({ taskId, walletId }) =>
        this.service.fetchTransactions(walletId).pipe(
          map(data => succeed(new actions.MyRewardsGetTransactionsResponseAction(taskId, data))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  fetchPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetPointsRequestAction>(EVENTS.MY_REWARDS_POINTS_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchPoints().pipe(
          map(response => succeed(new actions.MyRewardsGetPointsResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  fetchBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetBalanceRequestAction>(EVENTS.MY_REWARDS_BALANCE_REQUEST),
      switchMap(({ taskId, walletId }) =>
        this.service.fetchBalance(walletId).pipe(
          map(response => succeed(new actions.MyRewardsGetBalanceResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  fetchCoupons$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetCouponsRequestAction>(EVENTS.MY_REWARDS_COUPONS_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchCoupons().pipe(
          map(response => succeed(new actions.MyRewardsGetCouponsResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  fetchCouponDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetCouponDetailRequestAction>(EVENTS.MY_REWARDS_COUPON_DETAIL_REQUEST),
      switchMap(({ taskId, couponId }) =>
        this.service.fetchCouponDetail(couponId).pipe(
          map(response => succeed(new actions.MyRewardsGetCouponDetailResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  fetchPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetLoyaltyFXRequestAction>(EVENTS.MY_REWARDS_LOYALTY_FX_REQUEST),
      switchMap(({ taskId, OnSucceeded }) =>
        this.service.fetchPartners().pipe(
          map(response => {
            OnSucceeded(response);
            return succeed(new actions.MyRewardsGetLoyaltyFXResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  linkPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetLoyaltyFXLinkRequestAction>(EVENTS.MY_REWARDS_LOYALTY_FX_LINK_REQUEST),
      switchMap(({ taskId, programId, OnSucceeded }) =>
        this.service.linkPartner(programId).pipe(
          map(response => {
            OnSucceeded(response);
            return succeed(new actions.MyRewardsGetLoyaltyFXLinkResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  unlinkPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetLoyaltyFXUnlinkRequestAction>(EVENTS.MY_REWARDS_LOYALTY_FX_UNLINK_REQUEST),
      switchMap(({ taskId, programId, OnSucceeded }) =>
        this.service.unlinkPartner(programId).pipe(
          map(() => {
            OnSucceeded();
            return succeed(new actions.MyRewardsGetLoyaltyFXUnlinkResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  convertPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetLoyaltyFXConvertRequestAction>(EVENTS.MY_REWARDS_LOYALTY_FX_CONVERT_REQUEST),
      switchMap(({ taskId, programId, payload, OnSucceeded }) =>
        this.service.convertPoints(programId, payload.request).pipe(
          map(response => {
            OnSucceeded(response);
            return succeed(new actions.MyRewardsGetLoyaltyFXConvertResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  quotePoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyRewardsGetLoyaltyFXQuoteRequestAction>(EVENTS.MY_REWARDS_LOYALTY_FX_QUOTE_REQUEST),
      switchMap(({ taskId, programId, payload, OnSucceeded }) =>
        this.service.quotePoints(programId, payload).pipe(
          map(response => {
            OnSucceeded(response);
            return succeed(new actions.MyRewardsGetLoyaltyFXQuoteResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private service: MyRewardsService) {}
}
