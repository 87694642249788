import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { v4 as uuidv4 } from 'uuid';
import * as actions from './airtimedata.actions';
import { IAirtimeDataPartialState } from './airtimedata.reducer';
import * as selector from './airtimedata.selectors';
import {
  IAirtimeDataProvider,
  IPurchaseAirtimeDataCurrentOrder,
  IPurchaseAirtimeDataRequest,
} from '../../../models/prepaids.model';
import { started } from '../../application/task.actions';

@Injectable()
export class AirtimeDataFacade {
  constructor(private store: Store<IAirtimeDataPartialState>) {}

  getRecentTransactions$ = this.store.pipe(select(selector.getRecentTransactions));
  getProviders$ = this.store.pipe(select(selector.getProviders));
  getCurrentOrder$ = this.store.pipe(select(selector.getCurrentOrder));
  getPlacedOrder$ = this.store.pipe(select(selector.getPlacedOrder));

  fetchRecentTransactions = (silently?: boolean): void => {
    const taskId = `fetch-recent-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.AirtimeDataRecentTransactionsRequest(taskId),
        'Fetching recent Airtime Transactions',
        silently,
      ),
    );
  };

  fetchProviders = (onSucceed?: (data: IAirtimeDataProvider[]) => void, silently?: boolean): void => {
    const taskId = `fetch-providers-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.AirtimeDataProvidersRequest(taskId, onSucceed), 'Fetching providers', silently),
    );
  };

  placeOrder = (createdOrder: IPurchaseAirtimeDataRequest, onSucceed: () => void): void => {
    const taskId = `pay-airtimedata-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.AirtimeDataPlaceOrderRequest(taskId, createdOrder, onSucceed), 'Processing payment'),
    );
  };

  editCurrentOrder = (order: IPurchaseAirtimeDataCurrentOrder): void => {
    const taskId = `edit-airtimedata-order-${uuidv4()}`;
    this.store.dispatch(new actions.AirtimeDataCurrentOrderEdit(taskId, order));
  };
}
