import { Component, Input } from '@angular/core';
import { IMatrixMessage, IMessageEventRating } from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-rating',
  template: `
    <div
      class="ui-chat-message"
      [ngClass]="{ 'incoming': !message?.sender_self, 'outgoing': message?.sender_self, 'hey-ned': heyNedTheme }"
    >
      <ui-rating [rating]="message?.content?.rating" [readOnly]="true"></ui-rating>
      <ng-container *ngIf="!!message?.content?.message">
        <ui-divider [compact]="true"></ui-divider>
        <p class="small light">{{ message.content.message }}</p>
      </ng-container>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: !message?.sender_self, outgoing: message?.sender_self }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
  `,
})
export class ChatWidgetRatingComponent {
  @Input() message: IMatrixMessage<IMessageEventRating>;
  @Input() heyNedTheme: boolean;
}
