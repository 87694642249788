import { ChatMessageType } from '@wakanda/wakanda-core';

export interface IChatRoomId {
  roomId: string;
  alreadyExisting?: boolean;
}

export interface IChatRoom {
  id: string;
  created: number;
  alias: string;
  entityType: string;
  entityId: string;
  customerId: string;
  customerName: string;
  merchantId: string;
  merchantName: string;
  members: IChatRoomMember[];
}

export interface IChatRoomMember {
  userId: string;
  displayName: string;
}

export interface IChatMatrixRoomTimelineEvent {
  event_id: string;
  origin_server_ts: number;
  room_id: string;
  user_id?: string;
  sender: string;
  sender_displayname: string;
  sender_self: boolean;
  type: string;
  content: IChatMatrixMessageTypeContent;
}

export interface IChatMatrixMessageTypeContent {
  msgtype: ChatMessageType;
  body?: string;
  url?: string;
}

export interface IChatGetRoomMessagesResponse {
  start: string;
  end: string;
  chunk: IChatMatrixRoomTimelineEvent[];
}

export enum ChatEventType {
  roomMessage = 'm.room.message',
}

export interface IChatSendEventRequest {
  roomId: string;
  eventType: string;
  payload: IChatMatrixMessageTypeContent;
  txnId?: string;
}

export interface IChatGetMessagesRequest {
  roomId: string;
  from?: string;
  to?: string;
  limit?: number;
}

export interface IChatImageFileResponse {
  id: string;
  url: string;
}

export interface IChatHeyNedRoomRequest {
  addressId?: string;
  title?: string;
  feeAccepted?: boolean;
  comingFromChatId?: string;
}

export interface IChatAttachmentRequest {
  name: string;
  data: FormData;
  messageType: ChatMessageType;
}

export interface IChatHeyNedRoom {
  open: boolean;
  roomId: string;
  jobId: string;
  addressId: string;
  title: string;
}

export interface IChatHeyNedSubscriptionCheckResponse {
  active: boolean;
  subscriptionId?: string;
  trial?: boolean;
}

export interface IChatHeyNedEnabledCheckResponse {
  heynedEnabled: boolean;
}
