export interface UiTableQuery {
  all?: string | boolean | any;
  page?: number | string;
  pageSize?: number | string;
  sortBy?: any;
  sortAsc?: boolean;
}

export enum UiTableItemType {
  'h1' = 'h1',
  'h2' = 'h3',
  'h3' = 'h3',
  'a' = 'a',
  'aSmall' = 'aSmall',
  'i' = 'i',
  'p' = 'p',
  'pSmall' = 'pSmall',
  'b' = 'b',
  'bSmall' = 'bSmall',
  'icon' = 'icon',
  'setOfIcons' = 'setOfIcons',
  'image' = 'image',
  'thumbnail' = 'thumbnail',
  'thumbnailSecure' = 'thumbnailSecure',
  'translateStatus' = 'translateStatus',
  'checkbox' = 'checkbox',
  'radio' = 'radio',
  'price' = 'price',
  'priceWithIcon' = 'priceWithIcon',
  'toggle' = 'toggle',
  'date' = 'date',
  'paymentDueDate' = 'paymentDueDate',
  'dateTime' = 'dateTime',
  'action' = 'action',
  'setOfActions' = 'setOfActions',
  'inputStockQuantity' = 'inputStockQuantity',
  'inputPriceQuantity' = 'inputPriceQuantity',
  'dropdown' = 'dropdown',
  'orderDetailsStore' = 'orderDetailsStore',
  'orderDetailsRestaurant' = 'orderDetailsRestaurant',
  'html' = 'html',
  'color' = 'color',
  'quoteDetailsSeller' = 'quoteDetailsSeller',
  'quoteDetailsBuyer' = 'quoteDetailsBuyer',
}

export interface IUiTableHeaderItem {
  content?: string;
  type?: UiTableItemType;
  capitalized?: boolean;
  columnWidth?: string;
  textAlign?: string;
  ellipsis?: boolean;
  sortable?: boolean;
}

export interface IUiTableItem {
  [key: string]: any | any[];
}

export interface IUiTableItemAction<T> {
  content: string;
  greenButton?: boolean;
  onClick?: (T) => void;
}

export interface IUiTableItemCheckbox<T> {
  checked: boolean;
  onClick: (arg0: T) => void;
  id: string;
}

export interface IUiTableItemDropdown<T> {
  optionKey: string;
  onChange: (tableItem: T, optionKey: string) => void;
  id: string;
  disabled?: boolean;
}
