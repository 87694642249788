export const INIT_REQUEST = 'SEARCH/INIT/REQUESTED';
export const INIT_RECEIVED = 'SEARCH/INIT/RECEIVED';

export const RESULTS_REQUEST = 'SEARCH/RESULTS/REQUESTED';
export const RESULTS_RECEIVED = 'SEARCH/RESULTS/RECEIVED';

export const RESULT_CLICK_REQUEST = 'SEARCH/RESULT-CLICK/REQUESTED';
export const RESULT_CLICK_RECEIVED = 'SEARCH/RESULT-CLICK/RECEIVED';

export const SUGGESTIONS_REQUEST = 'SEARCH/SUGGESTIONS/REQUESTED';
export const SUGGESTIONS_RECEIVED = 'SEARCH/SUGGESTIONS/RECEIVED';

export const SERVICE_POPULAR_REQUEST = 'SEARCH/SERVICE-SEARCH-POPULAR/REQUESTED';
export const SERVICE_POPULAR_RECEIVED = 'SEARCH/SERVICE-SEARCH-POPULAR/RECEIVED';

export const SET_QUERY = 'SEARCH/SET-QUERY';

export const CANCEL_SUGGESTIONS = 'SEARCH/CANCEL-SUGGESTIONS';

export const SET_SEARCH_CATEGORY_ID_DASHBOARD = 'SEARCH/SET-SEARCH-CATEGORY-ID';

export const SEARCH_PRODUCT_REQUEST = 'SEARCH/PRODUCT/REQUESTED';
export const SEARCH_PRODUCT_RECEIVED = 'SEARCH/PRODUCT/RECEIVED';

export const SEARCH_B2B_STORE_REQUEST = 'SEARCH/B2B_STORE/REQUESTED';
export const SEARCH_B2B_STORE_RECEIVED = 'SEARCH/B2B_STORE/RECEIVED';

export const SEARCH_PRODUCT_NAMED_REQUEST = 'SEARCH/PRODUCT-NAMED/REQUESTED';
export const SEARCH_PRODUCT_NAMED_RECEIVED = 'SEARCH/PRODUCT-NAMED/RECEIVED';
