export enum EVENTS {
  MY_REWARDS_COUPONS_REQUEST = 'MY_REWARDS/COUPONS/REQUEST',
  MY_REWARDS_COUPONS_RECEIVED = 'MY_REWARDS/COUPONS/RECEIVED',

  MY_REWARDS_COUPON_DETAIL_REQUEST = 'MY_REWARDS/COUPON_DETAIL/REQUEST',
  MY_REWARDS_COUPON_DETAIL_RECEIVED = 'MY_REWARDS/COUPON_DETAIL/RECEIVED',

  MY_REWARDS_POINTS_REQUEST = 'MY_REWARDS/POINTS/REQUEST',
  MY_REWARDS_POINTS_RECEIVED = 'MY_REWARDS/POINTS/RECEIVED',

  MY_REWARDS_TRANSACTIONS_REQUEST = 'MY_REWARDS/TRANSACTIONS/REQUEST',
  MY_REWARDS_TRANSACTIONS_RECEIVED = 'MY_REWARDS/TRANSACTIONS/RECEIVED',

  MY_REWARDS_BALANCE_REQUEST = 'MY_REWARDS/BALANCE/REQUEST',
  MY_REWARDS_BALANCE_RECEIVED = 'MY_REWARDS/BALANCE/RECEIVED',

  MY_REWARDS_REWARD_CALCULATE_REQUEST = 'MY_REWARDS/REWARD-CALCULATE/REQUEST',
  MY_REWARDS_REWARD_CALCULATE_RECEIVED = 'MY_REWARDS/REWARD-CALCULATE/RECEIVED',

  MY_REWARDS_LOYALTY_FX_REQUEST = 'MY_REWARDS/LOYALTY-FX/REQUEST',
  MY_REWARDS_LOYALTY_FX_RECEIVED = 'MY_REWARDS/LOYALTY-FX/RECEIVED',

  MY_REWARDS_LOYALTY_FX_LINK_REQUEST = 'MY_REWARDS/LOYALTY-FX-LINK/REQUEST',
  MY_REWARDS_LOYALTY_FX_LINK_RECEIVED = 'MY_REWARDS/LOYALTY-FX-LINK/RECEIVED',

  MY_REWARDS_LOYALTY_FX_UNLINK_REQUEST = 'MY_REWARDS/LOYALTY-FX-UNLINK/REQUEST',
  MY_REWARDS_LOYALTY_FX_UNLINK_RECEIVED = 'MY_REWARDS/LOYALTY-FX-UNLINK/RECEIVED',

  MY_REWARDS_LOYALTY_FX_CONVERT_REQUEST = 'MY_REWARDS/LOYALTY-FX-CONVERT/REQUEST',
  MY_REWARDS_LOYALTY_FX_CONVERT_RECEIVED = 'MY_REWARDS/LOYALTY-FX-CONVERT/RECEIVED',

  MY_REWARDS_LOYALTY_FX_QUOTE_REQUEST = 'MY_REWARDS/LOYALTY-FX-QUOTE/REQUEST',
  MY_REWARDS_LOYALTY_FX_QUOTE_RECEIVED = 'MY_REWARDS/LOYALTY-FX-QUOTE/RECEIVED',
}
