import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HashStringPrefix, ICommonOnChangeOutput } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-checkbox',
  template: `
    <div class="ui-checkbox" [class.disabled]="disabled">
      <span class="checkbox-icon-wrapper" (click)="onCheckbox($event)">
        <ng-container *ngIf="!rounded && !checked">
          <ui-icon icon="check_grey" [attr.id]="id || '' | hashString: hashType.CHECKBOX" [size]="iconSize"></ui-icon>
        </ng-container>

        <ng-container *ngIf="!rounded && !!checked">
          <ui-icon icon="check_blue" [attr.id]="id || '' | hashString: hashType.CHECKBOX" [size]="iconSize"></ui-icon>
        </ng-container>

        <ng-container *ngIf="!!rounded && !checked">
          <ui-icon
            icon="checkbox_empty"
            [attr.id]="id || '' | hashString: hashType.CHECKBOX"
            [size]="iconSize"
          ></ui-icon>
        </ng-container>

        <ng-container *ngIf="!!rounded && !!checked">
          <ui-icon icon="checkbox" [attr.id]="id || '' | hashString: hashType.CHECKBOX" [size]="iconSize"></ui-icon>
        </ng-container>
      </span>
      <span class="ui-checkbox-label">
        <p *ngIf="!!label || !!link" (click)="onCheckbox($event, onlyCheckboxIsClicable)">
          {{ label }} <a *ngIf="!!link" (click)="onLink($event); $event.stopPropagation()">{{ link }}</a>
        </p>

        <div class="custom-content" *ngIf="!!custom" (click)="onCheckbox($event, onlyCheckboxIsClicable)">
          <ng-content></ng-content>
        </div>
      </span>
    </div>
  `,
})
export class CheckboxComponent {
  hashType = HashStringPrefix;
  @Input() iconSize = '1.15rem';
  @Input() id: string;
  @Input() checked = false;
  @Input() disabled = false;
  @Input() label: string;
  @Input() link: string;
  @Input() name: string;
  @Input() rounded: boolean;
  @Input() merchant: boolean;
  @Input() custom: boolean;
  @Input() onlyCheckboxIsClicable: boolean;
  @Output() onChange = new EventEmitter<ICommonOnChangeOutput>();
  @Output() onLinkClick = new EventEmitter<string>();

  onCheckbox = (event: MouseEvent, disable?: boolean): void => {
    if (this.disabled || disable) return;
    event.preventDefault();
    event.stopPropagation();
    this.onChange.emit({ name: this.name, value: !this.checked });
  };

  onLink = (event: MouseEvent): void => {
    if (this.disabled || this.onlyCheckboxIsClicable) return;
    event.preventDefault();
    event.stopPropagation();
    this.onLinkClick.emit(this.name);
  };
}
