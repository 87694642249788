import { Action } from '@ngrx/store';
import { EVENT } from './translate.events';

/**
 * An action dispatched when a translate request is fired.
 */
export class TranslateRequestAction implements Action {
  readonly type = EVENT.REQUEST;
  constructor(public taskId: string, public errorCallback: (error: any) => void) {}
}

/**
 * An action dispatched when the translate has translate data from API.
 */
export class TranslateResponseAction implements Action {
  readonly type = EVENT.RECEIVED;

  constructor(public taskId: string, public payload: any) {}
}

/**
 * Fetch translates from new localization server
 */
export class TranslateNewRequestAction implements Action {
  readonly type = EVENT.REQUEST_NEW;

  constructor(public taskId: string, public url: string, public errorCallback: (error: any) => void) {}
}

export class TranslateNewResponseAction implements Action {
  readonly type = EVENT.RECEIVED_NEW;

  constructor(public taskId: string, public payload: any) {}
}

/**
 * Payload of TRANSLATE_ERROR.
 */
export interface RouterErrorPayload {
  error: any;
}

/**
 * An action dispatched when the translate errors.
 */
export class TranslateErrorAction implements Action {
  readonly type = EVENT.FAILED;
  constructor(public taskId: string, public payload: RouterErrorPayload) {}
}

/**
 * A union type of translate actions.
 */
export type TranslateAction =
  | TranslateRequestAction
  | TranslateNewRequestAction
  | TranslateNewResponseAction
  | TranslateResponseAction
  | TranslateErrorAction;
