export enum EVENTS {
  NOTIFICATIONS_REQUEST = 'NOTIFICATIONS/REQUEST',
  NOTIFICATIONS_RECEIVED = 'NOTIFICATIONS/RECEIVED',

  NOTIFICATIONS_HIDE_REQUEST = 'NOTIFICATIONS/HIDE/REQUEST',
  NOTIFICATIONS_HIDE_RECEIVED = 'NOTIFICATIONS/HIDE/RECEIVED',

  NOTIFICATIONS_HIDE_ALL_REQUEST = 'NOTIFICATIONS/HIDE-ALL/REQUEST',
  NOTIFICATIONS_HIDE_ALL_RECEIVED = 'NOTIFICATIONS/HIDE-ALL/RECEIVED',
}
