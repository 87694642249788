import { Pipe, PipeTransform } from '@angular/core';
import toNumber from 'lodash-es/toNumber';
import { IAmount } from '../models/common.model';
import { AmountUtility } from '../utils/amount';

@Pipe({
  name: 'availableBalance',
})
export class AvailableBalancePipe implements PipeTransform {
  transform(money: IAmount, key?: string | number): string {
    if (typeof money?.amount === 'undefined') return '';

    if (typeof money === 'number' && key === 'decimal') {
      const val = AmountUtility.splitAmount(Math.abs(money));
      return `R${val[0]}.${val[1] ? val[1] : '00'}`;
    }

    const { amount, currencyCode, currencySymbol, currency } = money;

    if (key === 0 || key === 'currencyCode' || key === 'currencySymbol') {
      const symbol = toNumber(amount) < 0 ? '-' : '';
      if (currencyCode === 'ZAR' || currencySymbol === 'ZAR' || currency === 'ZAR') return `${symbol}R`;
      return `${symbol}${currencyCode}`;
    }

    const value = AmountUtility.splitAmount(Math.abs(amount));

    if (key === 1 || key === 'integer') return value[0];

    if (key === 2 || key === 'decimal') return value[1] ? `.${value[1]}` : '.00';

    if (!key && currencyCode === 'ZAR') return `R${value[0]}.${value[1] ? value[1] : '00'}`;

    return `${currencyCode || currencySymbol}${' '}${value[0]}.${value[1] ? value[1] : '00'}`;
  }
}
