import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public get<T>(url: string, query: any = {}): Observable<T> {
    const queryParams = this.createQueryParams(query);
    return this.http
      .get<T>(url, {
        params: queryParams,
      })
      .pipe(map(response => response));
  }

  public post<P, R>(url: string, payload?: P, headers?: HttpHeaders): Observable<R> {
    return this.http.post<R>(url, payload, { headers }).pipe(map(response => response));
  }

  public put<P, R>(url: string, payload: P): Observable<R> {
    return this.http.put<R>(url, payload).pipe(map(response => response));
  }

  public remove(url: string): Observable<any> {
    return this.http.delete(url);
  }

  private createQueryParams(query: any): HttpParams {
    let params = new HttpParams();

    Object.keys(query).forEach(key => (params = params.append(key, query[key])));

    return params;
  }
}
