import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AmountTransformPipe } from '@wakanda/wakanda-core';
import toNumber from 'lodash-es/toNumber';
import round from 'lodash-es/round';

@Component({
  selector: 'ui-quantity-picker',
  template: `
    <div class="ui quantity picker" [ngClass]="{ small: !!small }">
      <span class="minus" (click)="handleButton(value, actionType.decrement)">
        <a>-</a>
      </span>
      <input
        *ngIf="type !== 'amount'"
        autocomplete="off"
        step="any"
        [type]="type"
        [readonly]="!!readonly"
        [name]="name"
        [value]="value || 0"
        (keyup)="handleChange($event)"
        (blur)="blur($event)"
      />
      <input
        *ngIf="type === 'amount'"
        type="text"
        step="any"
        [max]="maxValue"
        [readonly]="readonly"
        appAmountFormatMask
        autocomplete="off"
        [prefixValue]="currencyCode === 'ZAR' ? 'R' : currencyCode !== undefined ? currencyCode : 'R'"
        [name]="name"
        [value]="(value | amountTransform: { prefixValue: currencyCode, isInput: true }) || ''"
        (keyup)="handleChange($event)"
        (blur)="blur($event)"
      />
      <span class="plus" (click)="handleButton(value, actionType.increment)">
        <a>+</a>
      </span>
    </div>
  `,
})
export class QuantityPickerComponent {
  actionType = { increment: 'inc', decrement: 'dec' };

  @Input() currencyCode: string;

  @Input() name: string;

  @Input() type: string;

  @Input() exponent: any;

  @Input() number = 1;

  @Input() value = 0;

  @Input() minValue = 0;

  @Input() maxValue = Number.MAX_VALUE;

  @Input() readonly: boolean;

  @Input() small: boolean;

  @Input() inRangeOnKeyUp: boolean;

  @Output() onChange = new EventEmitter<any>();

  constructor(private amountTransform: AmountTransformPipe) {}

  blur = (event: any): void => {
    const value = event.target.value;
    const transformValue = this.amountTransform.parseBlur(value, true, this.currencyCode);
    const outputValue = Math.min(Math.max(toNumber(transformValue), this.minValue), this.maxValue);
    event.target.value = this.currencyCode
      ? this.amountTransform.transform(outputValue, { prefixValue: this.currencyCode, isInput: true })
      : outputValue;
    this.onChange.emit({
      name: this.name,
      value: outputValue,
    });
  };

  handleButton = (value: any, type: any): void => {
    if (this.readonly) return;
    if (type) {
      let outputValue = value;
      if (type === this.actionType.increment) {
        outputValue = this.exponent
          ? value + toNumber(this.number) * toNumber(this.exponent)
          : value + toNumber(this.number);
      }

      if (type === this.actionType.decrement) {
        outputValue = this.exponent
          ? value - toNumber(this.number) * toNumber(this.exponent)
          : value - toNumber(this.number);
      }

      outputValue = round(Math.min(Math.max(toNumber(outputValue), this.minValue), this.maxValue), 2);
      this.onChange.emit({
        name: this.name,
        value: outputValue,
      });
    }
  };

  handleChange = (event: any): void => {
    if (this.readonly) return;
    const value = event.target.value;
    if (this.type === 'number') {
      const outputValue = this.inRangeOnKeyUp
        ? Math.min(Math.max(toNumber(value), this.minValue), this.maxValue)
        : toNumber(value);
      event.target.value = outputValue;
      this.onChange.emit({
        name: this.name,
        value: outputValue,
      });
    } else {
      const transformValue = this.amountTransform.parseBlur(value, true, this.currencyCode);
      const outputValue = this.inRangeOnKeyUp
        ? Math.min(Math.max(toNumber(transformValue), this.minValue), this.maxValue)
        : toNumber(transformValue);
      event.target.value = this.amountTransform.transform(outputValue, {
        prefixValue: this.currencyCode,
        isInput: true,
      });
      this.onChange.emit({
        name: this.name,
        value: outputValue,
      });
    }
  };
}
