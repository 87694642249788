import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'walletDateFormat',
})
export class WalletDateFormatPipe implements PipeTransform {
  transform = (date: string): string =>
    date ? `${dayjs(date).format('DD MMM YY')} | ${dayjs(date).format('LT')}` : '';
}
