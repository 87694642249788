export const transactions = (walletId: string, baseUrl: string): string =>
  `${baseUrl}/wallet/v1/loyaltyandrewards/wallet/${walletId}/transaction`;
export const points = (baseUrl: string): string => `${baseUrl}/wallet/v1/loyalty/points`;
export const coupons = (baseUrl: string): string => `${baseUrl}/wallet/v1/loyalty/coupons`;
export const balance = (walletId: string, baseUrl: string): string =>
  `${baseUrl}/wallet/v1/loyaltyandrewards/wallet/${walletId}/balance`;
export const couponsDetail = (couponId: string, baseUrl: string): string =>
  `${baseUrl}/wallet/v1/loyalty/coupons/${couponId}`;

export const partners = (baseUrl: string): string => `${baseUrl}/wallet/v1/loyaltyfx`;
export const linkPartner = (programId: string, baseUrl: string): string =>
  `${baseUrl}/wallet/v1/loyaltyfx/${programId}/link`;
export const unlinkPartner = (programId: string, baseUrl: string): string =>
  `${baseUrl}/wallet/v1/loyaltyfx/${programId}/unlink`;
export const convertPoints = (programId: string, baseUrl: string): string =>
  `${baseUrl}/wallet/v1/loyaltyfx/${programId}/fx`;
export const quotePoints = (programId: string, baseUrl: string): string =>
  `${baseUrl}/wallet/v1/loyaltyfx/${programId}/quote`;
