import { ActionReducer, INIT, MetaReducer } from '@ngrx/store';
import { AppState } from './register-reducers';

export function logout(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state, action) => {
    if (action !== null && action.type === 'logout') {
      const resetStore: AppState = undefined;
      const newStore = Object.assign({}, resetStore, {
        translate2: state.translate2,
      });
      return reducer(newStore, { type: INIT });
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer[] = [logout];
