import { DataSource } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IStatusTranslate } from '@wakanda/zentity-translate-store';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUiTableHeaderItem, UiTableItemType, UiTableQuery } from '@wakanda/wakanda-core';
import get from 'lodash-es/get';
import isNil from 'lodash-es/isNil';
import { ExpandAnimation, ExpandAnimationState } from '../../utils/animations';
import { IDropdownOption } from '../input/labeledInput.component';

@Component({
  selector: 'ui-table',
  animations: [ExpandAnimation],
  template: `
    <table class="ui-table" cdk-table [dataSource]="dataSource$" multiTemplateDataRows>
      <tr cdk-header-row *cdkHeaderRowDef="columns"></tr>
      <tr
        cdk-row
        *cdkRowDef="let row; columns: columns"
        [class.table-expanded-row]="expandedItem === row"
        [class.table-expandable-row]="expandableRow"
        (click)="onExpandRow(row)"
      ></tr>

      <ng-container *ngIf="!!expandableRow">
        <tr cdk-row *cdkRowDef="let element; columns: ['expandedDetail']" class="table-detail-row"></tr>
      </ng-container>

      <ng-container cdkColumnDef="expandedDetail">
        <td cdk-cell *cdkCellDef="let element" [attr.colspan]="columns?.length">
          <div
            class="table-element-detail"
            [@expandAnimation]="element === expandedItem ? expandState.EXPANDED : expandState.COLLAPSED"
          >
            <ui-table-value [type]="element?.expandedDetailType" [item]="element"></ui-table-value>
          </div>
        </td>
      </ng-container>

      <ng-container
        *ngFor="
          let header of headers | paginate: { itemsPerPage: pageSize, currentPage: page, totalItems: total };
          let cell = index
        "
      >
        <ng-container [cdkColumnDef]="columns[cell]">
          <th
            [ngClass]="{ checkbox: header?.type === tableType.checkbox, columnClass: header?.columnClass }"
            [ngStyle]="{ width: header?.columnWidth }"
            cdk-header-cell
            *cdkHeaderCellDef
          >
            <ui-table-header-cell
              [content]="header?.content"
              [checkbox]="header?.type === tableType.checkbox"
              [isChecked]="all === 'true'"
              [isActive]="columns[cell] === sortBy"
              [sortAsc]="sortAsc !== 'true'"
              [sortable]="headerIsSortable(header.sortable)"
              (onClick)="onHeaderCell(columns[cell], $event)"
            ></ui-table-header-cell>
          </th>
          <td cdk-cell *cdkCellDef="let element" [ngStyle]="{ 'text-align': header?.textAlign }">
            <ui-table-value
              [type]="header?.type"
              [capitalized]="header?.capitalized"
              [cell]="columns[cell]"
              [item]="element"
              [translateData]="translateData"
              [ellipsis]="header?.ellipsis"
              [columnWidth]="header?.columnWidth"
              [dropdownTitle]="dropdownTitle"
              [dropdownOptions]="dropdownOptions"
            ></ui-table-value>
          </td>
        </ng-container>
      </ng-container>
    </table>

    <ng-container *ngIf="!hidePagination">
      <div class="pagination row center">
        <span class="number-of-pages row between" (click)="handleNumberOfPagesMenu($event)">
          <span class="text"> {{ pageSize }} / Pages </span>
          <ui-icon icon="down" size="0.65rem"></ui-icon>
        </span>
        <pagination-controls
          class="table-pagination"
          [autoHide]="true"
          [responsive]="true"
          (pageChange)="onPage($event)"
        ></pagination-controls>

        <div *ngIf="isNumberOfPagesMenuOpen" class="menu" (clickOutside)="handleNumberOfPagesMenu($event, true)">
          <ng-container *ngFor="let p of pageSizes">
            <div class="item" [ngClass]="{ active: p === pageSize }" (click)="onPageSize(p)">{{ p }}</div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  `,
})
export class TableComponent<T, DK> extends DataSource<T> implements OnInit, OnChanges {
  @Input() data: T[];
  tableType = UiTableItemType;
  dataSource$: BehaviorSubject<T[]>;
  //
  @Input() columns: string[];
  @Input() headers: IUiTableHeaderItem[];
  @Input() pageSize: string;
  @Input() page: string;
  @Input() total: string;
  @Input() sortBy: string;
  @Input() sortAsc: string;
  @Input() all: string;
  @Input() hidePagination: boolean;
  @Input() expandableRow: boolean;
  @Input() translateData: IStatusTranslate;
  @Input() dropdownTitle: string;
  @Input() dropdownOptions: IDropdownOption<DK>[];

  @Output() onChange = new EventEmitter<UiTableQuery>();

  isNumberOfPagesMenuOpen: boolean;

  expandedItem: T;
  expandState = ExpandAnimationState;

  pageSizes = ['10', '20', '30', '100'];

  onExpandRow = (row: T): void => {
    if (!this.expandableRow) return;
    this.expandedItem = row;
  };

  handleNumberOfPagesMenu = (e: Event, propagation?: boolean): void => {
    if (e && !propagation) e.stopPropagation();
    this.isNumberOfPagesMenuOpen = !this.isNumberOfPagesMenuOpen;
  };

  onPageSize = (pageSize: number | string): void => {
    this.isNumberOfPagesMenuOpen = false;
    if (pageSize === this.pageSize) return;
    this.onChange.emit({ pageSize });
  };

  onHeaderCell = (header: string, checkboxTriggered?: boolean): void => {
    if (checkboxTriggered) {
      this.onChange.emit({ all: this.all !== 'true' });
      return;
    }

    if (this.sortBy === header) {
      this.onChange.emit({ sortBy: header, sortAsc: this.sortAsc !== 'true' });
      return;
    }

    this.onChange.emit({ sortBy: header });
  };

  onPage = (page: number | string): void => {
    if (this.page === page) return;
    this.onChange.emit({ page });
  };

  connect = (): Observable<T[]> => {
    if (!this.dataSource$) return;
    return this.dataSource$;
  };

  disconnect = (): Observable<T[]> => {
    if (this.dataSource$) return;
    return this.dataSource$;
  };

  headerIsSortable(value: any): boolean {
    return isNil(value) ? true : value;
  }

  ngOnInit(): void {
    if (!this.data) return;
    this.dataSource$ = new BehaviorSubject<T[]>(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const firstChange = get(changes, 'data.firstChange');
    const prevProps = get(changes, 'data.previousValue');
    const nextProps = get(changes, 'data.currentValue');

    if (!firstChange && prevProps !== nextProps) this.dataSource$ = new BehaviorSubject<T[]>(this.data);
  }
}
