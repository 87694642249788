export enum UploadStatus {
  PROCESSING = 'PROCESSING',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export interface IAntivirusScanResponse {
  status: UploadStatus;
  documentId: string;
  error: string;
  uploadedFile: IUploadedFile;
}

export interface IUploadedFile {
  id: string;
  url: string;
}

export interface IDMZUploadInfo {
  dmzFileId: string;
}
