export const parseCategories = (breadcrumbs: string[]): string => {
  if (!breadcrumbs) return '';
  return breadcrumbs
    .slice(0, 5)
    .map(c => c?.replace(/[\s,]/g, '-'))
    .map((c, i, all) => all.slice(0, i + 1).join('_'))
    .join(',');
};

export const urlAbsoluteToRelative = (url: string): string => {
  if (url?.startsWith('http') || url?.startsWith('www')) {
    return url?.replace(/^(?:\/\/|[^/]+)*/, '');
  }
  return url;
};

export const getErrorMessageFromApiResponse = (apiResponse: any): string => {
  let errorMessage: any;
  if (apiResponse && apiResponse.error) {
    if (apiResponse.error.error) {
      if (apiResponse.error.error.messageCode) errorMessage = apiResponse.error.error.messageCode;
      else if (apiResponse.error.error.message) errorMessage = apiResponse.error.error.message;
    } else {
      if (apiResponse.error.messageCode) errorMessage = apiResponse.error.messageCode;
      else if (apiResponse.error.message) errorMessage = apiResponse.error.message;
      else if (typeof apiResponse.error === 'string') errorMessage = apiResponse.error;
    }
  }
  return errorMessage;
};

export const parseApiResponse = (apiResponse: any): string => {
  if (apiResponse === 'Success') return 'Success';
  return getErrorMessageFromApiResponse(apiResponse) || 'Error';
};
