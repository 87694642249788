import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../configs/app.config';
import { CommonUtility } from '../../utils/commonUtility';
import { catchError, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { BrowserStorageService } from '../browser-storage/browser-storage.service';

type FetchBuildNumberSuccess = (buildNumber: string) => void;
type FetchBuildNumberError = () => void;

@Injectable({
  providedIn: 'root',
})
export class BuildNumberService {
  buildNumber: string;

  constructor(private http: HttpClient, private storageService: BrowserStorageService, private appConfig: AppConfig) {}

  fetchBuildNumber(onSuccess?: FetchBuildNumberSuccess, onError?: FetchBuildNumberError): void {
    if (!this.appConfig.isDevelopmentUrl) {
      this.http
        .get(`${CommonUtility.getDomainUrl()}build.html`, { responseType: 'text' })
        .pipe(
          take(1),
          catchError(error => {
            if (onError) {
              onError();
              return EMPTY;
            }
            throw error;
          }),
        )
        .subscribe(response => this.parseBuildNumber(response, onSuccess, onError));
    } else if (onError) {
      onError();
    }
  }

  fetchSavedBuildNumber(): string {
    return this.storageService.get('appBuildNumber');
  }

  saveBuildNumber(buildNumber: string): void {
    this.storageService.set('appBuildNumber', buildNumber);
  }

  private parseBuildNumber(text: string, onSuccess?: FetchBuildNumberSuccess, onError?: FetchBuildNumberError): void {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      doc.body.childNodes.forEach(child => {
        if (child.textContent.includes('Build')) {
          const buildNumber = child.textContent.split(':').pop().trim();

          this.buildNumber = buildNumber;
          if (onSuccess) onSuccess(buildNumber);
        }
      });
    } catch (e) {
      if (onError) {
        onError();
        return;
      }
      throw e;
    }
  }
}
