import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'ui-camera',
  templateUrl: './camera.component.html',
})
export class CameraComponent implements OnInit {
  @Input() isLoading: boolean;
  @Output() onChange = new EventEmitter<WebcamImage>();

  // toggle webcam on/off
  showWebcam = true;
  allowCameraSwitch = true;
  multipleWebcamsAvailable = false;
  deviceId: string;
  videoOptions: MediaTrackConstraints = {
    width: 1024,
    height: 576,
  };
  errors: WebcamInitError[] = [];

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  triggerSnapshot = (): void => this.trigger.next();

  toggleWebcam = (): void => {
    this.showWebcam = !this.showWebcam;
  };

  handleInitError = (error: WebcamInitError): void => {
    this.errors.push(error);
  };

  showNextWebcam = (directionOrDeviceId: boolean | string): void => {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  };

  handleImage = (webcamImage: WebcamImage): void => {
    this.onChange.emit(webcamImage);
  };

  cameraWasSwitched = (deviceId: string): void => {
    this.deviceId = deviceId;
  };

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => (this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1),
    );
  }
}
