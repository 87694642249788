import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import {
  IUpgradeWalletAddressStepRequest,
  IUpgradeWalletIdPhotoStepRequest,
  IUpgradeWalletNextStepResponse,
  IUpgradeWalletPersonalDataStepRequest,
  IUpgradeWalletSecurityQuestionsStepRequest,
} from '../../models/upgrade-wallet.model';
import { ApiService } from '../api/api.service';
import * as endpoints from './upgrade-wallet.endpoints';

@Injectable()
export class UpgradeWalletService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiUrl;

  public upgradeWalletInit(useMockApi = false): Observable<IUpgradeWalletNextStepResponse> {
    return this.api.post(endpoints.upgradeWalletInit(this.getApiUrl(useMockApi)), {});
  }

  public upgradeWalletPersonalData(
    request: IUpgradeWalletPersonalDataStepRequest,
    flowId: string,
    useMockApi = false,
  ): Observable<IUpgradeWalletNextStepResponse> {
    return this.api.post<IUpgradeWalletPersonalDataStepRequest>(
      endpoints.upgradeWalletPersonalData(flowId, this.getApiUrl(useMockApi)),
      request,
    );
  }

  public upgradeWalletAddress(
    request: IUpgradeWalletAddressStepRequest,
    flowId: string,
    useMockApi = false,
  ): Observable<IUpgradeWalletNextStepResponse> {
    return this.api.post<IUpgradeWalletAddressStepRequest>(
      endpoints.upgradeWalletAddress(flowId, this.getApiUrl(useMockApi)),
      request,
    );
  }

  public upgradeWalletSecurityQuestions(
    request: IUpgradeWalletSecurityQuestionsStepRequest,
    flowId: string,
    useMockApi = false,
  ): Observable<IUpgradeWalletNextStepResponse> {
    return this.api.post<IUpgradeWalletSecurityQuestionsStepRequest>(
      endpoints.upgradeWalletSecurityQuestions(flowId, this.getApiUrl(useMockApi)),
      request,
    );
  }

  public upgradeWalletIdPhoto(
    request: IUpgradeWalletIdPhotoStepRequest,
    flowId: string,
    useMockApi = false,
  ): Observable<IUpgradeWalletNextStepResponse> {
    return this.api.post<IUpgradeWalletIdPhotoStepRequest>(
      endpoints.upgradeWalletIdPhoto(flowId, this.getApiUrl(useMockApi)),
      request,
    );
  }
}
