export const CHAT_GET_ROOM_ID_SUPPORT_REQUEST = 'CHAT/GET_ROOM_ID_SUPPORT/REQUEST';
export const CHAT_GET_ROOM_ID_SUPPORT_RECEIVED = 'CHAT/GET_ROOM_ID_SUPPORT/RECEIVED';

export const CHAT_GET_ROOM_ID_CONCIERGE_REQUEST = 'CHAT/GET_ROOM_ID_CONCIERGE/REQUEST';
export const CHAT_GET_ROOM_ID_CONCIERGE_RECEIVED = 'CHAT/GET_ROOM_ID_CONCIERGE/RECEIVED';

export const CHAT_CREATE_ROOM_ID_CONCIERGE_REQUEST = 'CHAT/CREATE_ROOM_ID_CONCIERGE/REQUEST';
export const CHAT_CREATE_ROOM_ID_CONCIERGE_RECEIVED = 'CHAT/CREATE_ROOM_ID_CONCIERGE/RECEIVED';

export const CHAT_GET_ROOM_ID_JOB_REQUEST = 'CHAT/GET_ROOM_ID_JOB/REQUEST';
export const CHAT_GET_ROOM_ID_JOB_RECEIVED = 'CHAT/GET_ROOM_ID_JOB/RECEIVED';

export const CHAT_GET_ROOM_ID_JOB_MERCHANT_REQUEST = 'CHAT/GET_ROOM_ID_JOB_MERCHANT/REQUEST';
export const CHAT_GET_ROOM_ID_JOB_MERCHANT_RECEIVED = 'CHAT/GET_ROOM_ID_JOB_MERCHANT/RECEIVED';

export const CHAT_GET_ROOM_REQUEST = 'CHAT/GET_ROOM/REQUEST';
export const CHAT_GET_ROOM_RECEIVED = 'CHAT/GET_ROOM/RECEIVED';

export const CHAT_GET_MESSAGES_REQUEST = 'CHAT/GET_MESSAGES/REQUEST';
export const CHAT_GET_MESSAGES_RECEIVED = 'CHAT/GET_MESSAGES/RECEIVED';

export const CHAT_SEND_EVENT_REQUEST = 'CHAT/SEND_EVENT/REQUEST';
export const CHAT_SEND_EVENT_RECEIVED = 'CHAT/SEND_EVENT/RECEIVED';

export const CHAT_UPLOAD_IMAGE_REQUEST = 'CHAT/UPLOAD_IMAGE/REQUEST';
export const CHAT_UPLOAD_IMAGE_RECEIVED = 'CHAT/UPLOAD_IMAGE/RECEIVED';

export const CHAT_INF_PAYMENT_STATUS_REQUEST = 'CHAT/INF-PAYMENT-STATUS/REQUEST';
export const CHAT_INF_PAYMENT_STATUS_RECEIVED = 'CHAT/INF-PAYMENT-STATUS/RECEIVED';

export const CHAT_SEND_WEBSOCKET_MESSAGE_REQUEST = 'CHAT/SEND-WEBSOCKET-MESSAGE/REQUEST';
export const CHAT_SEND_WEBSOCKET_MESSAGE_SUCCEEDED = 'CHAT/SEND-WEBSOCKET-MESSAGE/SUCCEEDED';

export const CHAT_SEND_WEBSOCKET_ATTACHMENT_REQUEST = 'CHAT/SEND-WEBSOCKET-ATTACHMENT/REQUEST';
export const CHAT_SEND_WEBSOCKET_ATTACHMENT_SUCCEEDED = 'CHAT/SEND-WEBSOCKET-ATTACHMENT/SUCCEEDED';

export const CHAT_CREATE_HEYNED_ROOM_REQUEST = 'CHAT/CREATE_HEYNED_ROOM/REQUEST';
export const CHAT_CREATE_HEYNED_ROOM_RECEIVED = 'CHAT/CREATE_HEYNED_ROOM/RECEIVED';

export const CHAT_CREATE_HEYNED_HEALTH_ROOM_REQUEST = 'CHAT/CREATE_HEYNED_HEALTH_ROOM/REQUEST';
export const CHAT_CREATE_HEYNED_HEALTH_ROOM_RECEIVED = 'CHAT/CREATE_HEYNED_HEALTH_ROOM/RECEIVED';

export const CHECK_HEYNED_SUBSCRIPTION_REQUEST = 'CHAT/CHECK_HEYNED_SUBSCRIPTION/REQUEST';
export const CHECK_HEYNED_SUBSCRIPTION_RECEIVED = 'CHAT/CHECK_HEYNED_SUBSCRIPTION/RECEIVED';

export const CHECK_HEYNED_ENABLED_REQUEST = 'CHAT/CHECK_HEYNED_ENABLED/REQUEST';
export const CHECK_HEYNED_ENABLED_RECEIVED = 'CHAT/CHECK_HEYNED_ENABLED/RECEIVED';

export const CHAT_CLEAR_DATA = 'CHAT/CLEAR_DATA';
export const CHAT_FAILED = 'CHAT/FAILED';

export const CHAT_SEND_PAYMENT_REQUEST = 'CHAT/SEND-PAYMENT/REQUEST';
export const CHAT_SEND_PAYMENT_RECEIVED = 'CHAT/SEND-PAYMENT/RECEIVED';

export const CHAT_FETCH_PAYCHECK_REQUEST = 'CHAT/FETCH-PAYCHECK/REQUEST';
export const CHAT_FETCH_PAYCHECK_RECEIVED = 'CHAT/FETCH-PAYCHECK/RECEIVED';

export const CHAT_HISTORY_ONGOING_REQUEST = 'CHAT/HISTORY/ONGOING/REQUEST';
export const CHAT_HISTORY_ONGOING_RECEIVED = 'CHAT/HISTORY/ONGOING/RECEIVED';

export const CHAT_HISTORY_COMPLETED_REQUEST = 'CHAT/HISTORY/COMPLETED/REQUEST';
export const CHAT_HISTORY_COMPLETED_RECEIVED = 'CHAT/HISTORY/COMPLETED/RECEIVED';

export const CHAT_HISTORY_SEARCH_REQUEST = 'CHAT/HISTORY/SEARCH/REQUEST';
export const CHAT_HISTORY_SEARCH_RECEIVED = 'CHAT/HISTORY/SEARCH/RECEIVED';

export const CHAT_CLOSE_CHAT_REQUEST = 'CHAT/CLOSE-CHAT/REQUEST';
export const CHAT_CLOSE_CHAT_RECEIVED = 'CHAT/CLOSE-CHAT/RECEIVED';

export const CHAT_SAVE_HEYNED_ORIGIN_ROOM_ID = 'CHAT/SAVE_HEYNED_ORIGIN_ROOM_ID';
export const CHAT_CLEAR_HEYNED_ORIGIN_ROOM_ID = 'CHAT/CLEAR_HEYNED_ORIGIN_ROOM_ID';

export const CHAT_HEYNED_OPENED_TASK_REQUEST = 'CHAT/HEYNED-OPENED-TASK/REQUEST';
export const CHAT_HEYNED_OPENED_TASK_RECEIVED = 'CHAT/HEYNED-OPENED-TASK/RECEIVED';
