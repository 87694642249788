import { Pipe, PipeTransform } from '@angular/core';
import { UiSpinnerType } from './ui-spinner.model';
import { AnimationOptions } from 'ngx-lottie';

@Pipe({
  name: 'lottieOptions',
})
export class LottieOptionsPipe implements PipeTransform {
  private readonly FILES: Record<UiSpinnerType, string> = {
    [UiSpinnerType.DARK]: '/assets/spinners/dark.json',
    [UiSpinnerType.LIGHT]: '/assets/spinners/light.json',
  };

  transform(type: UiSpinnerType): AnimationOptions {
    return {
      path: this.FILES[type],
    };
  }
}
