import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { IClaimElectricityRequest, IMeterNumberValidateRequest } from '../../../models/prepaids.model';
import { IPhoneNumberRequest } from '../../../models/profile.model';
import { started } from '../../application/task.actions';
import * as actions from './prepaid-electricity.actions';
import { IPrepaidElectricityPartialState } from './prepaid-electricity.reducer';
import * as selector from './prepaid-electricity.selectors';

@Injectable()
export class PrepaidElectricityFacade {
  getData$ = this.store.pipe(select(selector.getData));
  getRecent$ = this.store.pipe(select(selector.getRecent));
  getIsMeterValidated$ = this.store.pipe(select(selector.getIsMeterValidated));
  getPlacedOrder$ = this.store.pipe(select(selector.getPlacedOrderElectricity));
  getIsWakandaUser$ = this.store.pipe(select(selector.getIsWakandaUser));

  constructor(private store: Store<IPrepaidElectricityPartialState>) {}

  getRecent = (): void => {
    const taskId = `fetch-recent-${uuidv4()}`;
    this.store.dispatch(started(new actions.RecentRequestAction(taskId), 'Fetching recent Electricity Transactions'));
  };

  validateMeter = (meter: IMeterNumberValidateRequest, onSucceeded: () => void, onError: () => void): void => {
    const taskId = `validate-meter-${JSON.stringify(meter)}`;
    this.store.dispatch(
      started(new actions.ValidateMeterRequestAction(taskId, meter, onSucceeded, onError), 'Validating meter number'),
    );
  };

  placeOrder = (request: IClaimElectricityRequest, onSucceeded: () => void): void => {
    const taskId = `place-order-electricity-${JSON.stringify(request)}`;
    this.store.dispatch(started(new actions.PlaceOrderRequestAction(taskId, request, onSucceeded), 'Creating order'));
  };

  clean = (): void => this.store.dispatch(new actions.CleanAction());

  saveToStore = (data: IClaimElectricityRequest): void => this.store.dispatch(new actions.SaveToStoreAction(data));

  checkUser = (phone: IPhoneNumberRequest): void => {
    const taskId = `check-user-${uuidv4()}`;
    this.store.dispatch(started(new actions.ElectricityCheckUserRequest(taskId, phone)));
  };
}
