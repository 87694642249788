import { NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { AppType } from '../models/dashboard.model';
import { ITopUpAndPayRequest, IWalletTopUpAddCardRequest } from '../models/wallet.model';

export class CommonUtility {
  public static format(input: string, ...args: any[]): string {
    return input.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
  }

  public static convertStringToBoolean(value: string): boolean {
    switch (value) {
      case 'true':
      case '1':
      case 'on':
      case 'yes':
        return true;
      default:
        return false;
    }
  }

  public static parseUrlQueryParams(url: string): any {
    let objParams = null;
    if (!url || url.indexOf('?') < 0) return objParams;
    const params = url.substring(url.indexOf('?') + 1);
    if (!params || params.length === 0) return objParams;
    objParams = JSON.parse('{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) =>
      key === '' ? value : decodeURIComponent(value),
    );
    return objParams;
  }

  public static getEnvironment(): string {
    const environmentPreview = 'preview';
    const environmentTest = 'test';
    const environmentStage = 'stage';
    const environmentProd = 'prod';

    const domain = window.location.hostname;
    if (domain.includes('stage')) {
      return environmentStage;
    } else if (domain.includes('preview')) {
      return environmentPreview;
    } else if (domain.includes('test')) {
      return environmentTest;
    } else if (domain.includes('avo')) {
      return environmentProd;
    } else {
      return environmentTest;
    }
  }

  static getCdnUrl = (baseUrl?: string): string => {
    baseUrl = baseUrl ?? window.location.hostname;

    const testUrl = 'https://test-cdn.avo.africa';
    const stageUrl = 'https://stage-cdn.avo.africa';
    const prodUrl = 'https://cdn.avo.africa';

    if (baseUrl.includes('stage')) {
      return stageUrl;
    } else if (baseUrl.includes('test')) {
      return testUrl;
    } else if (baseUrl.includes('avo')) {
      return prodUrl;
    } else {
      return testUrl;
    }
  };

  static getTranslateUrl = (baseUrl: string): string => `${CommonUtility.getCdnUrl(baseUrl)}/localization`;

  static getDatadogRemoteConfigUrl = (): string => {
    const isMerchant = window.location.hostname.includes('merchant');
    const app = isMerchant ? 'merchant' : 'customer';

    return `${CommonUtility.getCdnUrl()}/config/datadog/${app}-pwa.json`;
  };

  public static getDomainUrl(domainOnly = false): string {
    const domain = window.location.hostname;
    const isMerchant =
      domain.includes('merchant.avo.africa') ||
      domain.includes('test-merchant.avo.africa') ||
      domain.includes('stage-merchant.avo.africa') ||
      domain.includes('preview-merchant.avo.africa');

    const previewUrl = domainOnly ? 'preview.avo.africa' : 'https://preview.avo.africa/';
    const testUrl = domainOnly ? 'test.avo.africa' : 'https://test.avo.africa/';
    const stageUrl = domainOnly ? 'stage.avo.africa' : 'https://stage.avo.africa/';
    const prodUrl = domainOnly ? 'avo.africa' : 'https://www.avo.africa/';

    const previewMerchantUrl = domainOnly ? 'preview-merchant.avo.africa' : 'https://preview-merchant.avo.africa/';
    const testMerchantUrl = domainOnly ? 'test-merchant.avo.africa' : 'https://test-merchant.avo.africa/';
    const stageMerchantUrl = domainOnly ? 'stage-merchant.avo.africa' : 'https://stage-merchant.avo.africa/';
    const prodMerchantUrl = domainOnly ? 'merchant.avo.africa' : 'https://merchant.avo.africa/';

    if (domain.includes('stage')) {
      return isMerchant ? stageMerchantUrl : stageUrl;
    } else if (domain.includes('preview')) {
      return isMerchant ? previewMerchantUrl : previewUrl;
    } else if (domain.includes('test')) {
      return isMerchant ? testMerchantUrl : testUrl;
    } else if (domain.includes('avo')) {
      return isMerchant ? prodMerchantUrl : prodUrl;
    } else {
      return testUrl;
    }
  }

  public static getBouleDomainUrl(): string {
    const testUrl = 'https://test-boule.avo.africa/';
    const stageUrl = 'https://stage-boule.avo.africa/';
    const prodUrl = 'https://boule.avo.africa/';

    const domain = window.location.hostname;
    if (domain.includes('stage')) {
      return stageUrl;
    } else if (domain.includes('test')) {
      return testUrl;
    } else if (domain.includes('avo')) {
      return prodUrl;
    } else {
      return testUrl;
    }
  }

  public static getTopUpCardRequest(
    cardTopupSuccessCallbackUrl: string,
    cardTopupErrorCallbackUrl: string,
    cardTopupTryAgainCallbackUrl: string,
    cardTopupFailCallbackUrl: string,
  ): IWalletTopUpAddCardRequest {
    const domain = this.getDomainUrl();
    return {
      redirectSuccessUrl: `${domain}${cardTopupSuccessCallbackUrl}`,
      redirectErrorUrl: `${domain}${cardTopupErrorCallbackUrl}`,
      redirectTryAgainUrl: `${domain}${cardTopupTryAgainCallbackUrl}`,
      redirectFailUrl: `${domain}${cardTopupFailCallbackUrl}`,
    };
  }

  public static getTopUpAndPayRequest(
    cardTopupSuccessCallbackUrl: string,
    cardTopupErrorCallbackUrl: string,
    cardTopupTryAgainCallbackUrl: string,
    cardTopupFailCallbackUrl: string,
    amount: number,
    usedPoints: number,
    walletId: string,
    storeCardId: string,
  ): ITopUpAndPayRequest {
    const domain = this.getDomainUrl();
    return {
      redirectSuccessUrl: `${domain}${cardTopupSuccessCallbackUrl}`,
      redirectErrorUrl: `${domain}${cardTopupErrorCallbackUrl}`,
      redirectTryAgainUrl: `${domain}${cardTopupTryAgainCallbackUrl}`,
      redirectFailUrl: `${domain}${cardTopupFailCallbackUrl}`,
      amount: amount,
      usedPoints: usedPoints,
      walletId: walletId,
      storedCardId: storeCardId,
    };
  }

  public static findString(str: string, regex: RegExp): RegExpMatchArray {
    return str.match(regex);
  }

  public static urlAbsoluteToRelative(url: string): string {
    if (url?.startsWith('http') || url?.startsWith('www')) {
      return url?.replace(/^(?:\/\/|[^/]+)*/, '');
    }
    return url;
  }

  public static enterZone(zone: NgZone) {
    return <T>(source: Observable<T>) =>
      new Observable<T>(observer =>
        source.subscribe({
          next: x => zone.run(() => observer.next(x)),
          error: err => observer.error(err),
          complete: () => observer.complete(),
        }),
      );
  }

  public static checkIfInternalUrl(url: string, appType: AppType): boolean {
    const navigateAppType: AppType = CommonUtility.isCustomerOrMarchant(url);
    return appType === navigateAppType || url.startsWith('/');
  }

  public static openUrl(url: string) {
    window.open(url.startsWith('http') ? url : `http://${url}`);
  }

  private static isCustomerOrMarchant(url: string): AppType {
    const urlsCustomer: string[] = [
      'https://www.avo.africa',
      'https://avo.africa',
      'test.avo.africa',
      'stage.avo.africa',
      'preview.avo.africa',
    ];

    const urlsMerchant: string[] = [
      'merchant.avo.africa',
      'test-merchant.avo.africa',
      'stage-merchant.avo.africa',
      'preview-merchant.avo.africa',
    ];

    if (urlsCustomer.some(cutomerlUrl => url.includes(cutomerlUrl))) return AppType.customer;
    if (urlsMerchant.some(merchantlUrl => url.includes(merchantlUrl))) return AppType.merchant;

    return null;
  }
}
