import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAmount } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-customer-mobi-money-wallet-inline-widget',
  template: `
    <div class="ui-customer-mobi-money-wallet-inline-widget" (click)="onClick.emit()">
      <ng-container
        *ngTemplateOutlet="!!price || price === 0 ? withPriceTemplate : onlyTotalBalanceTemplate"
      ></ng-container>
    </div>
    <ng-template #withPriceTemplate>
      <span class="text">
        <ui-icon icon="my_money" size="1.625rem"></ui-icon>
        <ui-divider [vertical]="true" [compact]="true"></ui-divider>
        <div class="text-align-left">
          <b class="small">{{ 'wallet.payment.common.avo_wallet' | translate }}</b>
          <span class="price">
            <ui-available-balance [data]="data" renderAs="h4"></ui-available-balance>
            <b class="small">{{ 'wallet.avo_points.points_available' | translate }}</b>
          </span>
        </div>
      </span>
      <span>
        <h2>{{ price | amountTransform: { isLabel: true } }}</h2>
      </span>
    </ng-template>
    <ng-template #onlyTotalBalanceTemplate>
      <span class="text">
        <ui-icon icon="my_money" size="1.938rem"></ui-icon>
        <b zen-translate>wallet.available_balance</b>
      </span>
      <span class="balance">
        <i zen-translate>wallet.pay.form.wallet_balance</i>
        <ui-available-balance [data]="data" renderAs="h2"></ui-available-balance>
      </span>
    </ng-template>
  `,
})
export class CustomerMobiMoneyWalletInlineWidgetComponent {
  @Input() data: IAmount;
  @Input() price: number;
  @Output() onClick = new EventEmitter();
}
