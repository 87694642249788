<div class="ui-chat-preview">
  <div class="header-wrapper">
    <h3>Job Chat</h3>
    <p class="small" *ngIf="data!.messages.length > 0">
      <span>{{ data.messages.length }}</span> new messages
    </p>
    <p class="no-results" *ngIf="!data.messages || data.messages.length === 0">No messages received yet.</p>
  </div>

  <div class="message-wrapper">
    <p class="message">
      {{ data.messages[0].message }}
    </p>
    <p class="small">{{ data.messages[0].time }}</p>
  </div>
</div>
