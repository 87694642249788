import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import { IPhonebookRequest, IPhonebookResponse, IPhoneNumberRequest } from '../../models/profile.model';
import { ApiService } from '../api/api.service';
import * as endpoints from './profile.endpoints';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apiService: ApiService, private appConfig: AppConfig) {}
  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiUrl;

  public fetchPhonebook = (phoneNumbers: IPhonebookRequest, useMockApi = false): Observable<IPhonebookResponse[]> =>
    this.apiService.post<string[]>(endpoints.phonebook(this.getApiUrl(useMockApi)), phoneNumbers.phoneNumbers);

  public checkPhone(phoneNumber: IPhoneNumberRequest, usermockApi = false): Observable<IPhonebookResponse> {
    return this.apiService.post<IPhoneNumberRequest>(endpoints.checkPhone(this.getApiUrl(usermockApi)), phoneNumber);
  }
}
