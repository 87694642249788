<ui-modal
  [modal]="ModalType.SLIDE_IN_UP"
  [title]="'onboarding.account_exists.dialog_title' | translate"
  [isVisible]="true"
  class="text-align-center"
>
  <ui-icon icon="permission" size="4.563rem"></ui-icon>
  <h2 zen-translate>onboarding.account_exists.title</h2>
  <ui-divider></ui-divider>
  <p zen-translate>onboarding.account_exists.message</p>
  <ui-divider></ui-divider>
  <ng-container *ngFor="let type of accountInfo?.accountLoginTypes">
    <b>
      {{ type | statusTranslate: typeTranslate | async }}
    </b>
    <ui-divider [compact]="true"></ui-divider>
  </ng-container>

  <ui-divider></ui-divider>
  <p class="small light">
    {{ 'onboarding.account_exists.contact_us' | translate
    }}<a class="small light" (click)="onContact()" zen-translate>onboarding.account_exists.contact_us_link</a>
  </p>
  <ui-divider></ui-divider>
  <ui-button
    [selectButton]="true"
    [callToActionButton]="true"
    aligned="bottom"
    [content]="'onboarding.account_exists.login_button' | translate"
    (onClick)="onLogin()"
  ></ui-button>
  <ui-divider></ui-divider>
  <ng-container *ngIf="showGoToHomepage">
    <p class="light small" zen-translate>moya.general.already_on_avo</p>
    <ui-divider [compact]="true"></ui-divider>
    <ui-button
      [callToActionButton]="true"
      [content]="'moya.general.lets_avo' | translate"
      (onClick)="onGoToHomepage.emit()"
    >
    </ui-button>
    <ui-divider [compact]="true"></ui-divider>
  </ng-container>
</ui-modal>
