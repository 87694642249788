import { Action } from '@ngrx/store';
import {
  IClaimElectricityRequest,
  IOrderDetailForPayment,
  IMeterNumberValidateRequest,
  IRecentElectricityClaimResponse,
} from '../../../models/prepaids.model';
import { IPhonebookRequest, IPhonebookResponse, IPhoneNumberRequest } from '../../../models/profile.model';
import { HttpErrorAction } from '../../application/task.actions';
import { EVENT } from './prepaid-electricity.events';

export class RecentRequestAction implements Action {
  readonly type = EVENT.RECENT_REQUEST;
  constructor(public taskId: string) {}
}
export class RecentResponseAction implements Action {
  readonly type = EVENT.RECENT_RECEIVED;
  constructor(public taskId: string, public payload: IRecentElectricityClaimResponse[]) {}
}

export class ValidateMeterRequestAction implements Action {
  readonly type = EVENT.VALIDATE_METER_REQUEST;
  constructor(
    public taskId: string,
    public payload: IMeterNumberValidateRequest,
    public onSucceeded: () => void,
    public onError: () => void,
  ) {}
}
export class ValidateMeterResponseAction implements Action {
  readonly type = EVENT.VALIDATE_METER_RECEIVED;
  constructor(public taskId: string) {}
}

export class PlaceOrderRequestAction implements Action {
  readonly type = EVENT.PLACE_ORDER_REQUEST;
  constructor(public taskId: string, public payload: IClaimElectricityRequest, public onSucceeded: () => void) {}
}
export class PlaceOrderResponseAction implements Action {
  readonly type = EVENT.PLACE_ORDER_RECEIVED;
  constructor(public taskId: string, public payload: IOrderDetailForPayment) {}
}

export class SaveToStoreAction implements Action {
  readonly type = EVENT.SAVE_TO_STORE;
  constructor(public payload: IClaimElectricityRequest) {}
}

export class RetrieveFromStoreAction implements Action {
  readonly type = EVENT.RETRIEVE_FROM_STORE;
}

export class ElectricityCheckUserRequest implements Action {
  readonly type = EVENT.CHECK_USER_REQUEST;
  constructor(public taskId: string, public payload: IPhoneNumberRequest) {}
}

export class ElectricityCheckUserResponse implements Action {
  readonly type = EVENT.CHECK_USER_RESPONSE;
  constructor(public taskId: string, public payload: { isWakandaUser: boolean; phone: string }) {}
}

export class ElectricityPhoneBookRequest implements Action {
  readonly type = EVENT.PHONEBOOK_REQUEST;
  constructor(public taskId: string, public payload: IPhonebookRequest) {}
}

export class ElectricityPhoneBookResponse implements Action {
  readonly type = EVENT.PHONEBOOK_RESPONSE;
  constructor(public taskId: string, public payload: IPhonebookResponse[]) {}
}

export interface RouterErrorPayload {
  error: any;
}

export class CleanAction implements Action {
  readonly type = EVENT.CLEAN;
}

export class ErrorAction extends HttpErrorAction {
  override readonly type = EVENT.FAILED;
}

export type PrepaidElectricityActions =
  | RecentRequestAction
  | RecentResponseAction
  | ValidateMeterRequestAction
  | ValidateMeterResponseAction
  | PlaceOrderRequestAction
  | PlaceOrderResponseAction
  | SaveToStoreAction
  | RetrieveFromStoreAction
  | ElectricityCheckUserRequest
  | ElectricityCheckUserResponse
  | ElectricityPhoneBookRequest
  | ElectricityPhoneBookResponse
  | CleanAction
  | ErrorAction;
