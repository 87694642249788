import { Component, Input } from '@angular/core';
import { ISellingToBusinessStep } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-selling-to-bussiness-steps',
  templateUrl: './selling-to-bussiness-steps.component.html',
  styleUrls: ['./selling-to-bussiness-steps.component.scss'],
})
export class SellingToBussinessStepsComponent {
  @Input() steps: ISellingToBusinessStep[];
}
