import { Injectable } from '@angular/core';
import find from 'lodash-es/find';
import { IScript, IScriptStatus } from '../../models/script.model';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  private scriptsLoaded: IScript[] = [];

  private load(scripts: IScript[]): Promise<IScriptStatus[]> {
    const promises: Promise<IScriptStatus>[] = [];
    scripts.forEach(script => {
      if (!(typeof script.canLoad !== 'undefined' && !script.canLoad)) {
        promises.push(this.loadScript(script));
      }
    });
    return Promise.all(promises);
  }

  callService = (scriptStore: IScript[]): Promise<void> =>
    this.load(scriptStore).then((data: IScriptStatus[]) =>
      data.forEach(d =>
        d.status !== 'Failed'
          ? console.log(`script: ${d.script}, status: ${d.status}, isLoaded: ${d.loaded}`)
          : console.error(`script: ${d.script}, status: ${d.status}, isLoaded: ${d.loaded}`),
      ),
    );

  private loadScript = (script: IScript): Promise<IScriptStatus> =>
    new Promise((resolve): void => {
      if (find(this.scriptsLoaded, s => s.name === script.name)) {
        resolve({ script: script.name, loaded: true, status: 'Already Loaded' });
      } else {
        const headScript = document.createElement('script');
        headScript.type = script.type || 'text/javascript';
        headScript.id = `script_${script.id}`;
        if (script.src) {
          headScript.src = script.src;
        }
        if (script.data) {
          headScript.append(script.data);
        }
        headScript.async = script.async;
        headScript.onload = (): void => {
          const status: IScriptStatus = { script: script.name, loaded: true, status: 'Loaded' };
          this.scriptsLoaded.push(script);
          resolve(status);
        };
        headScript.onerror = (): void => resolve({ script: script.name, loaded: false, status: 'Failed' });
        document.getElementsByTagName('head')[0].appendChild(headScript);
      }
    });

  unloadScript = (id: string): void => {
    const elem = document.getElementById(`script_${id}`);
    if (elem) {
      elem.remove();
    }
  };
}
