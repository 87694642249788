import { NgZone } from '@angular/core';
import { Assets } from '@avo/shared/ui';
import { Observable } from 'rxjs';

export class CommonUtility {
  public static format(input: string, ...args: any[]): string {
    return input.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
  }

  static generateRandomString(length = 6, source = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ'): string {
    let generatedString = '';
    for (let i = length; i > 0; --i) {
      generatedString += source[Math.floor(Math.random() * source.length)];
    }
    return generatedString;
  }

  static enterZone(zone: NgZone) {
    return <T>(source: Observable<T>) =>
      new Observable<T>(observer =>
        source.subscribe({
          next: x => zone.run(() => observer.next(x)),
          error: err => observer.error(err),
          complete: () => observer.complete(),
        }),
      );
  }

  /**
   * Add properties to object via merge strategy.
   * Data in storage are preserved if they are not changed by properties.
   *
   * @param storage Target storage object
   * @param properties Object with properties to be added
   */
  static addPropertiesToObject(storage: object, properties?: object): object {
    Object.keys(properties ?? {}).forEach(key => {
      if (typeof properties[key] === 'object') {
        storage[key] = CommonUtility.addPropertiesToObject(storage[key] ?? {}, properties[key]);
        return;
      }

      storage[key] = properties[key];
    });

    return storage;
  }
}

export const countryPhoneCodes = [
  {
    id: '+27',
    label: 'South Africa (+27)',
    icon: Assets.image.logo.southAfrica,
  },
  {
    id: '+264',
    label: 'Namibia (+264)',
    icon: Assets.image.logo.namibia,
  },
];
