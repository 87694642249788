import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import * as actions from './search.actions';
import { ISearchPartialState } from './search.reducer';
import * as selector from './search.selectors';
import {
  HomeSearchLocation,
  ISearchB2BStoreRequest,
  ISearchB2BStoreResponse,
  ISearchClickRequest,
  ISearchProductRequest,
  ISearchProductResponse,
  ISearchQuery,
  ISearchResults,
} from '../../models/search.model';
import { started } from '../application/task.actions';

@Injectable()
export class SearchFacade {
  getInitData$ = this.store.pipe(select(selector.getInitData));
  getResultsData$ = this.store.pipe(select(selector.getResultsData));
  getSuggestionsData$ = this.store.pipe(select(selector.getSuggestionsData));
  getServicePopular$ = this.store.pipe(select(selector.getServicePopular));
  getQuery$ = this.store.pipe(select(selector.getQuery));
  getSearchCategoryId$ = this.store.pipe(select(selector.getSearchCategoryId));

  getSearchProductData$ = this.store.pipe(select(selector.getSearchProductData));
  getB2BStoreSearchData$ = this.store.pipe(select(selector.getB2BStoreSearchData));
  getSearchProductNamedData$ = this.store.pipe(select(selector.getSearchProductNamedData));

  constructor(private store: Store<ISearchPartialState>) {}

  fetchSearchInit = (): void => {
    const taskId = `fetching-search-init-${uuidv4()}`;
    this.store.dispatch(started(new actions.SearchInitRequest(taskId), 'Fetching init data', true));
  };

  fetchSearchResults = (
    query: string,
    location: string = HomeSearchLocation.HOMEPAGE,
    categoryId?: string,
    onSucceed?: (data: ISearchResults) => void,
    silently = true,
  ): void => {
    const taskId = `fetching-search-results-by-query-${query}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.SearchResultsRequest(taskId, query, location, categoryId, onSucceed),
        'Fetching search results',
        silently,
      ),
    );
  };

  homeSearchClick = (query: ISearchClickRequest): void => {
    const taskId = `home-search-click-${uuidv4()}`;
    this.store.dispatch(started(new actions.SearchClickRequest(taskId, query), 'Sending search click', true));
  };

  fetchSearchSuggestions = (query: string, location: string = HomeSearchLocation.HOMEPAGE): void => {
    const taskId = `fetching-search-suggestion-by-query-${query}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.SearchSuggestionsRequest(taskId, query, location), 'Fetching search suggestions', true),
    );
  };

  fetchServiceSearchPopular = (silently?: boolean): void => {
    const taskId = `fetching-service-search-popular-${uuidv4()}`;
    this.store.dispatch(started(new actions.ServiceSearchPopularRequest(taskId), 'Fetching popular results', silently));
  };

  setQuery = (query: ISearchQuery): void => {
    this.store.dispatch(new actions.SearchSetQuery(query));
  };

  cancelSuggestions = (): void => {
    this.store.dispatch(new actions.CancelSuggestions());
  };

  setSearchCategoryId = (categoryId: string): void => {
    const taskId = `set-search-category-id-${categoryId}-${uuidv4()}`;
    this.store.dispatch(new actions.SetSearchCategoryId(taskId, categoryId));
  };

  //v3
  fetchSearchProductResults = (
    request: ISearchProductRequest,
    onSucceed?: (data: ISearchProductResponse) => void,
    silently = true,
  ): void => {
    const taskId = `fetching-search-product-by-query-${request.query}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.SearchProductRequest(taskId, request, onSucceed), 'Fetching search results', silently),
    );
  };

  fetchB2BStoreResults = (
    request: ISearchB2BStoreRequest,
    onSucceed?: (data: ISearchB2BStoreResponse) => void,
    silently = true,
  ): void => {
    const taskId = `fetching-search-b2b-store-by-query-${request.query}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.SearchB2BStoreRequest(taskId, request, onSucceed), 'Fetching store search results', silently),
    );
  };

  fetchSearchProductNamedResults = (
    filterName: string,
    onSucceed?: (data: ISearchProductResponse) => void,
    silently = true,
  ): void => {
    const taskId = `fetching-search-product-named-${filterName}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.SearchProductNamedRequest(taskId, filterName, onSucceed),
        'Fetching search results',
        silently,
      ),
    );
  };
}
