import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kilometersFormat',
})
export class KilometersFormatPipe implements PipeTransform {
  transform(value: number): number {
    return Math.ceil(value / 1000);
  }
}
