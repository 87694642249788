import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import get from 'lodash-es/get';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap } from 'rxjs/operators';
import { convertLocationToAddressForm, getAddressForm } from '../../maps/utils';
import { GoogleMapsPredictionService } from '../../services/location/google-maps-prediction.service';
import { LocationService } from '../../services/location/location.service';
import { CommonUtility } from '../../utils/commonUtility';
import { ApplicationFacade } from '../application/application.facade';
import { failed, HttpErrorAction, pushNotification, started, succeed } from '../application/task.actions';
import * as actions from './location.actions';
import { EVENT } from './location.events';

@Injectable({
  providedIn: 'root',
})
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private appFacade: ApplicationFacade,
    private googleMapsService: GoogleMapsPredictionService,
    private locationService: LocationService,
    private ngZone: NgZone,
  ) {}

  fetchLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationFetchLocationsRequest>(EVENT.LOCATION_FETCH_LOCATIONS_REQUEST),
      switchMap(({ taskId }) =>
        this.locationService.fetchLocations().pipe(
          map(response => succeed(new actions.LocationFetchLocationsReceived(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchMerchantLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationFetchMerchantLocationsRequest>(EVENT.LOCATION_FETCH_MERCHANT_LOCATIONS_REQUEST),
      switchMap(({ taskId, merchantId }) =>
        this.locationService.fetchMerchantLocations(merchantId).pipe(
          map(response => succeed(new actions.LocationFetchMerchantLocationsReceived(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  findPlaceByAddressString$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationFindPlaceByAddressStringRequest>(EVENT.LOCATION_FIND_PLACE_BY_ADDRESSSTRING_REQUEST),
      switchMap(({ taskId, addressString, onSucceed, onError }) =>
        this.googleMapsService.getPlaceByAddress(addressString).pipe(
          CommonUtility.enterZone(this.ngZone),
          map(response => {
            if (onSucceed) onSucceed(getAddressForm(response), get(response, [0, 0]));
            return succeed(new actions.LocationFindPlaceByAddressStringReceived(taskId, response));
          }),
          catchError(error => {
            onError(error);
            return of(failed(new HttpErrorAction(taskId, error), { addressString }));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  findPlaceByPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationFindPlaceByPositionRequest>(EVENT.LOCATION_FIND_PLACE_BY_POSITION_REQUEST),
      switchMap(({ taskId, position, onSucceed, onError }) =>
        this.googleMapsService.getPlaceByLocations(position).pipe(
          CommonUtility.enterZone(this.ngZone),
          map(response => {
            if (onSucceed) onSucceed(getAddressForm(response), JSON.stringify(get(response, [0, 0])));
            return succeed(new actions.LocationFindPlaceByPositionReceived(taskId, response));
          }),
          catchError(error => {
            onError(error);
            return of(failed(new HttpErrorAction(taskId, error), { position }));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  getSearchLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationFetchSearchByQueryRequest>(EVENT.LOCATION_FETCH_SEARCH_BY_QUERY_REQUEST),
      switchMap(({ taskId, query, onSucceed, countryCodes }) =>
        this.googleMapsService.getPlaceByQuery(query, countryCodes).pipe(
          CommonUtility.enterZone(this.ngZone),
          map(result => {
            const data = get(result, 0);
            onSucceed(query, data);
            return succeed(new actions.LocationFetchSearchByQueryReceived(taskId, data));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), { query }))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  deleteLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationDeleteLocationRequest>(EVENT.LOCATION_DELETE_LOCATION_REQUEST),
      switchMap(({ taskId, locationId, onSucceed }) =>
        this.locationService.deleteLocation(locationId).pipe(
          map(() => {
            onSucceed();
            return pushNotification(new actions.LocationDeleteLocationReceived(taskId), 'Location has been removed!');
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), { locationId }))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  editLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationEditLocationRequest>(EVENT.LOCATION_EDIT_LOCATION_REQUEST),
      switchMap(({ taskId, locationId, request, onSucceed, onError }) =>
        this.locationService.editLocation(locationId, request).pipe(
          map(() => {
            onSucceed();
            return pushNotification(new actions.LocationEditLocationReceived(taskId), 'Location has been edited!');
          }),
          catchError(error => {
            if (error && error.error && error.error.error && error.error.error.code === 'duplicate_address_name') {
              onError();
              return of(failed(new HttpErrorAction(taskId, error, true), { locationId, request }));
            }
            return of(failed(new HttpErrorAction(taskId, error), { locationId, request }));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  addMerchantLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationAddMerchantLocationRequest>(EVENT.LOCATION_ADD_MERCHANT_LOCATION_REQUEST),
      switchMap(({ taskId, request, merchantId, onSucceed, onError }) =>
        this.locationService.addMerchantLocation(request, merchantId).pipe(
          map(response => {
            onSucceed(response);
            return pushNotification(
              new actions.LocationAddMerchantLocationReceived(taskId, response),
              'New location has been saved!',
            );
          }),
          catchError(error => {
            if (error?.error?.error?.code === 'duplicate_address_name') {
              if (onError) onError();
              return of(failed(new HttpErrorAction(taskId, error, true)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  setCurrentLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationSetCurrentLocationRequest>(EVENT.LOCATION_SET_CURRENT_LOCATION_REQUEST),
      switchMap(({ taskId, locationId, onSucceed }) =>
        this.locationService.setCurrentLocation(locationId).pipe(
          map(response => {
            if (onSucceed) onSucceed(convertLocationToAddressForm(response), JSON.stringify(get(response, 0)));
            return succeed(new actions.LocationSetCurrentLocationReceived(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error), { locationId }))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchCurrentLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationFetchCurrentLocationRequest>(EVENT.LOCATION_FETCH_CURRENT_LOCATION_REQUEST),
      mergeMap(({ taskId, onSucceed, onError }) =>
        this.locationService.fetchCurrentLocation().pipe(
          map(response => {
            if (onSucceed) onSucceed(convertLocationToAddressForm(response), JSON.stringify(get(response, 0)));
            return succeed(new actions.LocationFetchCurrentLocationReceived(taskId, response));
          }),
          catchError(error => {
            if (onError) onError();
            return of(failed(new actions.LocationFetchCurrentLocationFailed(taskId, error, true)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchNearestLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LocationFetchNearestLocationRequest>(EVENT.LOCATION_FETCH_NEAREST_LOCATION_REQUEST),
      switchMap(({ taskId, position, onSucceed }) =>
        this.locationService.fetchNearestLocation(position).pipe(
          switchMap(response => {
            const setCurrentLocationTaskId = `set-current-location-${JSON.stringify(response.id)}`;
            return [
              succeed(new actions.LocationFetchNearestLocationReceived(taskId, response)),
              started(
                new actions.LocationSetCurrentLocationRequest(setCurrentLocationTaskId, response.id, onSucceed),
                'Processing your location',
              ),
            ];
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error, true)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
}
