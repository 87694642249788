import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../init/app.config';
import { Observable } from 'rxjs';
import {
  IAuthAvoClientInfo,
  IAuthDevice,
  IAuthNextStep,
  IAuthRefreshTokenStartRequest,
} from '../store/auth/auth.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient, private appConfig: AppConfig) {}

  refreshToken(payload: IAuthRefreshTokenStartRequest): Observable<IAuthNextStep> {
    return this.httpClient.post<IAuthNextStep>(`${this.appConfig.apiUrl}/login/refreshtoken/start`, payload);
  }

  avoInfo(): Observable<IAuthAvoClientInfo> {
    return this.httpClient.post<IAuthAvoClientInfo>(
      `${this.appConfig.apiUrl}/users/v2/onboarding/customer/avoInfo`,
      {},
    );
  }

  currentDevice(): Observable<IAuthDevice> {
    return this.httpClient.get<IAuthDevice>(`${this.appConfig.apiUrl}/login/devices/current`);
  }
}
