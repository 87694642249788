import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

import map from 'lodash-es/map';
import orderBy from 'lodash-es/orderBy';
import sortBy from 'lodash-es/sortBy';
import size from 'lodash-es/size';
import cloneDeep from 'lodash-es/cloneDeep';
import { chain } from '../utils/chain.util';

interface IGroupedDatePipe {
  dateTitle: string;
  data: any[];
}

@Pipe({
  name: 'groupDataByDate',
  pure: true,
})
export class GroupDataByDatePipe implements PipeTransform {
  transform = (
    input: any[],
    groupBy: string,
    format?: string,
    filterBy?: (data: any[]) => any,
    order?: 'asc' | 'desc',
  ): IGroupedDatePipe[] => {
    if (!input || !size(input)) return [];
    let chats = cloneDeep(input);
    chats = chats?.map(chat => {
      if (chat.origin_server_ts) {
        const strTimestamp = chat.origin_server_ts.toString();
        if (strTimestamp?.length > 10) {
          chat.origin_server_ts = Number(strTimestamp.slice(0, 10));
        }
      }
      return chat;
    });
    const getGroupedDataByDate = chain(chats)
      .groupBy(i => {
        const dayjsObject = typeof i[groupBy] === 'number' ? dayjs.unix(i[groupBy]) : dayjs(i[groupBy]);
        return dayjsObject.format(format);
      })
      .map((data: any[], dateTitle: string) => ({ data, dateTitle }))
      .sortBy(({ dateTitle }) => dateTitle)
      .value();
    const result = map(
      orderBy(getGroupedDataByDate, ({ dateTitle }) => dayjs(dateTitle, format), order || 'asc'),
      ({ dateTitle, data }) => ({
        dateTitle,
        data: sortBy(data, ({ date }) => date),
      }),
    );

    if (typeof filterBy === 'function') return filterBy(result);

    return result;
  };
}
