export const REQUESTS_REQUESTED = 'REQUEST/LIST/REQUESTED';
export const REQUESTS_RECEIVED = 'REQUEST/LIST/RECEIVED';

export const REQUEST_DETAIL_REQUESTED = 'REQUEST/DETAIL/REQUESTED';
export const REQUEST_DETAIL_RECEIVED = 'REQUEST/DETAIL/RECEIVED';

export const REQUEST_PAY_REQUESTED = 'REQUEST/PAY/REQUESTED';
export const REQUEST_PAY_RECEIVED = 'REQUEST/PAY/RECEIVED';

export const REQUEST_DECLINE_REQUESTED = 'REQUEST/DECLINE/REQUESTED';
export const REQUEST_DECLINE_RECEIVED = 'REQUEST/DECLINE/RECEIVED';

export const REQUEST_DOWNLOAD_REQUESTED = 'REQUEST/DOWNLOAD/REQUESTED';
export const REQUEST_DOWNLOAD_RECEIVED = 'REQUEST/DOWNLOAD/RECEIVED';
