import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuildNumberService } from '../services/build-number.service';

@Injectable()
export class AppBuildIdInterceptor implements HttpInterceptor {
  constructor(private buildNumberService: BuildNumberService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    if (this.buildNumberService.shellBuildNumber && !request.url.includes('/configuration.json')) {
      const buildNumber = this.buildNumberService.shellBuildNumber.replace(/\D/g, '');
      request = request.clone({ headers: request.headers.set('X-AppBuildID', buildNumber) });
    }
    return next.handle(request);
  }
}
