import { Component } from '@angular/core';

@Component({
  selector: 'wakanda-avo-business-selling-to-business-loader',
  templateUrl: './selling-to-business-loader.component.html',
  styleUrls: ['./selling-to-business-loader.component.scss'],
})
export class SellingToBusinessLoaderComponent {
  activeItem = 0;
  interval: number;
  timeLeft = 5;

  stroke: number;

  constructor() {
    this.startTimer();
  }

  startTimer() {
    const time = 5;
    const initialOffset = 74;
    let i = 0;

    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        i++;
        this.stroke = initialOffset - (i + 1) * (initialOffset / time);
      } else {
        this.timeLeft = 5;
      }
    }, 1000);
  }
}
