import { Action } from '@ngrx/store';
import * as EVENTS from './avo-business.events';
import { IContactSupportRequest } from '@wakanda/avo-business-model';

// AvoBusinessSendContactEmail
export class AvoBusinessSendContactEmailRequestAction implements Action {
  readonly type = EVENTS.AVO_BUSINESS_SEND_CONTACT_EMAIL_REQUEST;
  constructor(
    public taskId: string,
    public request: IContactSupportRequest,
    public onSucceeded: (response: any) => void,
  ) {}
}

export class AvoBusinessSendContactEmailResponseAction implements Action {
  readonly type = EVENTS.AVO_BUSINESS_SEND_CONTACT_EMAIL_RECEIVED;
  constructor(public taskId: string, public data: any) {}
}

// error
export class AvoBusinessErrorAction implements Action {
  readonly type = EVENTS.AVO_BUSINESS_ERROR;
  constructor(public taskId: string, public error: any, public hidden?: boolean) {}
}

export type AvoBusinessActions =
  | AvoBusinessErrorAction
  | AvoBusinessSendContactEmailRequestAction
  | AvoBusinessSendContactEmailResponseAction;
