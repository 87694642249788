import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { HashStringPrefix, PhoneTransformPipe } from '@wakanda/wakanda-core';
import filter from 'lodash-es/filter';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import size from 'lodash-es/size';
import memoizeOne from 'memoize-one';
import { v4 as uuidv4 } from 'uuid';
import { FadeInOutAnimation } from '../../utils/animations';

@Component({
  selector: 'ui-labeled-input',
  templateUrl: './labeledInput.component.html',
  animations: [FadeInOutAnimation],
})
export class LabeledInputComponent {
  @Input() maxLength: number;

  @Input() applyFocus: boolean;

  @Input() value: any;

  @Input() title: string;

  @Input() placeholder: string;

  @Input() label: string;

  @Input() boldLabel: boolean;

  @Input() extraLabel: boolean;

  @Input() withAddValue: boolean;

  @Input() extraLabelTitle: string;

  @Input() type = 'text';

  @Input() required: boolean;

  @Input() disabled: boolean;

  @Input() textarea: boolean;

  @Input() name: string;

  @Input() readonly: boolean;

  @Input() status: string;

  @Input() isValid: boolean;

  @Input() currencyCode: string;

  @Input() isOpen: boolean;

  @Input() arrowRight: boolean;

  @Input() arrowDown: boolean;

  @Input() forceClose: boolean;

  @Input() prefix: string;

  @Input() tooltip: string;

  @Input() dropdown: boolean;

  @Input() multiple: boolean;

  @Input() colorPicker: boolean;

  @Input() numberPicker: boolean;

  @Input() clearable: boolean;

  @Input() errors: ValidationErrors;

  @Input() options: any;

  @Input() searchable: boolean;

  @Input() bindLabel = 'text';

  @Input() bindValue = 'id';

  @Input() bindId;

  @Input() showEye = true;

  @Input() showLoading: boolean;

  @Input() dropdownMenuMaxHeight: number;

  @Input() alignedLeft: boolean;

  @Input() alignedCenter: boolean;

  @Input() alignedRight: boolean;

  @Input() fullWidth: boolean;

  @Input() externalDropdownValueResolver: any[];

  @Input() noDecimal: boolean;

  @Input() minWidth: string;

  @Input() unit: string;

  @Input() ignoreTabKey: boolean;

  @Output() onClick = new EventEmitter();

  @Output() onExtraLabelClick = new EventEmitter();

  @Output() onChange = new EventEmitter<any>();

  @Output() onBlur = new EventEmitter();

  @Output() onEnterChange = new EventEmitter();

  @ViewChild('dropdownWrapper') dropdownWrapper: ElementRef;

  @ViewChild('labelledInput') labelledInput: ElementRef;

  hashType = HashStringPrefix;

  focused: boolean;

  dropdownHeight: number;

  dropdownMaxHeight: number;

  direction = 'down';

  upPosition = '0px';

  passwordIsVisible: boolean;

  showTooltip = false;

  addValue: string;

  showAddValue: boolean;

  constructor(private phoneTransformPipe: PhoneTransformPipe) {}

  getDropdownValue = memoizeOne(value => {
    if (!value || value === '') {
      return '';
    }

    if (this.externalDropdownValueResolver) {
      const foundValue = this.externalDropdownValueResolver.find(item => item[this.bindId] === value);
      return foundValue ? get(foundValue, this.bindLabel) : '';
    }

    const getItem = find(this.options, item => item[this.bindValue] === value || item[this.bindLabel] === value);
    return getItem ? get(getItem, this.bindLabel) : '';
  });

  private getItem = (bindValue: string): any =>
    find(this.options, item => item[this.bindId || this.bindValue] === bindValue);

  handleClick = (event: Event): void => {
    if (this.disabled) return;
    this.onClick.emit(event);
  };

  handleExtraLabelClick = (event: Event): void => {
    if (this.disabled) return;
    this.onExtraLabelClick.emit(event);
  };

  handleChange = (data: any): void => {
    if (this.readonly) return;
    if (!data || !data.target || !data.target.value || !data.target.name || this.disabled) {
      this.value = '';
    }
    this.onChange.emit({ name: data.target.name, value: data.target.value });
    if (data.keyCode && data.keyCode === 13) {
      this.onEnterChange.emit();
    }
  };

  handleSearchChange = (event: any): void => {
    this.isOpen = !(!this.searchable || !event.target.value || event.target.value === '');
    this.onChange.emit({ name: event.target.name, value: event.target.value });
  };

  handleBlur = ({ value, name }): void => {
    if (!value || !name || this.disabled) this.value = '';
    this.onBlur.emit({ name, value });
  };

  onAmountChange = (value: any): void => {
    this.onChange.emit({
      name: this.name,
      value: value === 'R' || (value === '0' && this.required) ? null : value,
    });
  };

  onSaidChange = (value: number): void => this.onChange.emit({ name: this.name, value });

  onCellphoneChange = (value: string): void => {
    this.onChange.emit({
      name: this.name,
      value:
        value === '+27 '
          ? null
          : this.phoneTransformPipe.transform(value, {
              prefix: '',
              maxLength: this.maxLength ?? 9,
              formInputTransform: true,
            }),
    });
  };

  onFocus = (): void => {
    this.focused = true;
  };

  onDropdown = (): void => {
    if (this.readonly) return;
    this.isOpen = !this.isOpen;
    this.getDropdownHeight();
  };

  onDropdownClear = (): void => {
    this.onChange.emit({ name: this.name, value: null });
  };

  handleAddValue = (): void => {
    this.showAddValue = !this.showAddValue;
  };

  onAddChange = (data: any): void => {
    if (!data || !data.target || !data.target.value || !data.target.name || this.disabled) {
      this.addValue = '';
    }
    this.addValue = data.target.value;
  };

  onAddSubmit = (): void => {
    const newValue = { [this.bindValue || 'id']: uuidv4(), [this.bindLabel || 'text']: this.addValue };
    this.onChange.emit({ name: this.name, value: [...this.value, newValue] });
    this.addValue = '';
    this.handleAddValue();
  };

  getDropdownHeight = (): void => {
    const dropdownWrapper = this.dropdownWrapper.nativeElement;
    const boundingBox = dropdownWrapper.getBoundingClientRect();
    const wrapper = dropdownWrapper.closest('.container-content-wrapper') || dropdownWrapper.closest('div');
    const relativeTop = wrapper.getBoundingClientRect().top;
    const pageHeight = wrapper.clientHeight;
    const topOfDropdown = boundingBox.top - relativeTop;
    const availableSpace = pageHeight - topOfDropdown;
    const inputHeight = this.labelledInput.nativeElement.clientHeight + 2; // must add the borders
    const paddingFromEdge = 20;
    if (this.dropdownMenuMaxHeight) {
      this.direction = 'down';
      this.dropdownMaxHeight = this.dropdownMenuMaxHeight;
      this.upPosition = '';
      return;
    }
    if (availableSpace < 200) {
      this.direction = 'up';
      this.dropdownHeight = topOfDropdown - inputHeight /*height of the wrapper*/ - paddingFromEdge /*height from top*/;
      this.upPosition = -Math.abs(this.dropdownHeight + inputHeight) /*correct the top position*/ + 'px';
    } else {
      this.direction = 'down';
      this.dropdownHeight = availableSpace - paddingFromEdge /*height from bottom*/;
      this.upPosition = '';
    }
  };

  onMultipleDropdownItem = (item: any): void => {
    // multiple-dropdown - on remove click.
    if (item.notRemovable) {
      return;
    }
    const value = filter(this.value, i => i.id !== item.id);
    this.onChange.emit({ name: this.name, value });
  };

  onTogglePasswordVisible = (): void => {
    this.passwordIsVisible = !this.passwordIsVisible;
  };

  onDropdownItem = (bindValue: string): void => {
    const searchedItem = this.getItem(bindValue);
    if (this.multiple) {
      const value = this.value && size(this.value) ? this.value : [];
      value.push({
        id: searchedItem[this.bindValue],
        value: searchedItem[this.bindLabel],
      });
      this.onChange.emit({ name: this.name, value });
    } else {
      this.onChange.emit({
        name: this.name,
        value: searchedItem[this.bindLabel],
        id: searchedItem[this.bindValue],
        item: searchedItem,
      });
    }
  };

  onToggleTooltip = (): void => {
    this.showTooltip = !this.showTooltip;
  };

  onCloseTooltip = (): void => {
    this.showTooltip = false;
  };

  trackByErrorFn(index, item): any {
    return item;
  }

  handlePaste = (event: ClipboardEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    const value = event.clipboardData.getData('text');
    if (!value || !this.name) return;
    this.onChange.emit({ name: this.name, value: value });
  };
}

export interface IDropdownOption<T> {
  key: T | string;
  label?: string;
}
