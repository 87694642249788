import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../init/app.config';
import { AssetsBucketsEnum, IAssetDMZUploadInfo, IAssetStatusResponse } from '../store/assets/assets.models';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  constructor(private appConfig: AppConfig, private httpClient: HttpClient) {}

  postAsset = (file: FormData, bucket: AssetsBucketsEnum) =>
    this.httpClient.post<IAssetDMZUploadInfo>(`${this.appConfig.apiUrl}/assets/bucket/${bucket}`, file);

  postAssetWithProgress = (file: FormData, bucket: AssetsBucketsEnum) =>
    this.httpClient.post<HttpEvent<IAssetDMZUploadInfo>>(`${this.appConfig.apiUrl}/assets/bucket/${bucket}`, file, {
      responseType: 'json',
      reportProgress: true,
      observe: 'events',
    });

  getStatus = (dmzFilesId: string) =>
    this.httpClient.get<IAssetStatusResponse>(`${this.appConfig.apiUrl}/assets/status/${dmzFilesId}`);
}
