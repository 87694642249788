export interface IPhonebookRequest {
  phoneNumbers: string[];
}

export interface IPhoneNumberRequest {
  phoneNumber: IPhoneNumberData;
}

export interface IPhoneNumberData {
  phoneNumber: string;
}

export interface IPhonebookResponse {
  phoneNumber: string;
  wakanda: boolean;
  userId?: string;
}

export interface ICustomerPassword {
  currentPassword: string;
  newPassword: string;
}

export enum StatementPeriod {
  THREE_MONTHS = 'THREE_MONTHS',
  LAST_MONTH = 'LAST_MONTH',
  TWO_MONTHS = 'TWO_MONTHS',
}

export interface IStatementRequest {
  period: StatementPeriod;
}

export interface IReferralResponse {
  status: ReferralStatus;
  success: boolean;
}

export enum ReferralStatus {
  SUCCESS = 'SUCCESS',
  ALREADY_APPLIED = 'ALREADY_APPLIED',
  TOO_LATE = 'TOO_LATE',
}

export interface IMyProfileCustomerInfo {
  id: string;
  photo: string;
  name: string;
  surname: string;
  username: string;
  phone: string;
  displayName: string;
  email?: string;
  emailVerified?: boolean;
  upgradeWallet: boolean;
  residentialAddress: IMyProfileResidentialAddress;
  bankOnboarded?: boolean;
  walletAvailable?: boolean;
  pendingPoints?: number;
  loginCreated?: boolean;
  saId?: string;
  birthdate?: string;
  gender?: MyProfileCustomerInfoGender;
}

export interface IMyProfileResidentialAddress {
  houseNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  postalCode: string;
  country?: string;
  addressString?: string;
}

export enum MyProfileCustomerInfoGender {
  Male = 'Male',
  Female = 'Female',
}
