const serviceRoom = (baseUrl: string): string => `${baseUrl}/order-services/v1/job`;

export const getRoomForSupport = (baseUrl: string): string => `${baseUrl}/chat/v1/chat/support`;
export const getRoomForConcierge = (baseUrl: string): string => `${baseUrl}/chat/v2/chat/concierge`;
export const getRoomForSpecificJobAndMerchant = (baseUrl: string, jobId: string, merchantId: string): string =>
  `${serviceRoom(baseUrl)}/${jobId}/chat/merchant/${merchantId}`;
export const getRoomForSpecificJob = (baseUrl: string, jobId: string): string =>
  `${serviceRoom(baseUrl)}/${jobId}/chat/support`;
export const getRoom = (baseUrl: string, roomId: string): string => `${baseUrl}/chat/v1/rooms/${roomId}`;
export const getRoomMessages = (baseUrl: string, roomId: string, from: string, to?: string, limit?: number): string => {
  let base = `${baseUrl}/chat/v1/rooms/${roomId}/messages`;

  if (from) {
    base += `&from=${from}`;
  }

  if (to) {
    base += `&to=${to}`;
  }

  if (limit) {
    base += `&limit=${limit}`;
  }

  return base;
};
export const sendEvent = (baseUrl: string, roomId: string, eventType: string, txnId: string): string =>
  `${baseUrl}/chat/v1/rooms/${roomId}/send/${eventType}/${txnId}`;
export const uploadImage = (baseUrl: string): string => `${baseUrl}/assets/chat`;
export const getImage = (baseUrl: string): string => `${baseUrl}/chat/v1/file/image`;

export const fetchInfPayment = (baseUrl: string, jobId: string, quoteId: string): string =>
  `${baseUrl}/order-services/v1/customer/job/${jobId}/informal-quote/${quoteId}`;

export const createHeyNedRoom = (baseUrl: string): string => `${baseUrl}/chat/v1/heyned/room`;

export const createHeyNedHealthRoom = (baseUrl: string): string => `${baseUrl}/chat/v1/heyned/healthroom`;

export const sendPayment = (baseUrl: string, roomId: string, paymentId: string): string =>
  `${baseUrl}/chat/v2/rooms/${roomId}/payment/${paymentId}`;

export const fetchPayCheck = (baseUrl: string, roomId: string, paymentId: string): string =>
  `${baseUrl}/chat/v2/rooms/${roomId}/paycheck/${paymentId}`;

export const heyNedSubscriptionCheck = (baseUrl: string): string => `${baseUrl}/chat/v1/heyned/subscriptionCheck`;
export const heyNedEnabledCheck = (baseUrl: string): string => `${baseUrl}/chat/v1/heynedEnabled`;

export const fetchChatHistoryOngoing = (baseUrl: string, page: number, size: number): string =>
  `${baseUrl}/chat/v1/roomshistory/active${
    page !== undefined && size !== undefined ? `?page=${page}&pageSize=${size}` : ''
  }`;
export const fetchChatHistoryCompleted = (baseUrl: string, page: number, size: number): string =>
  `${baseUrl}/chat/v1/roomshistory/completed${
    page !== undefined && size !== undefined ? `?page=${page}&pageSize=${size}` : ''
  }`;
export const searchChatHistory = (baseUrl: string, query: string): string =>
  `${baseUrl}/chat/v1/roomshistory/search?query=${query}`;
export const closeChat = (baseUrl: string, chatId: string): string => `${baseUrl}/chat/v1/rooms/${chatId}/close`;
export const heyNedOpenedTask = (baseUrl: string): string => `${baseUrl}/chat/v1/heyned/openedTask`;
