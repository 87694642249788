import { IAmount } from '@wakanda/wakanda-core';
import { AgentChatEventType } from './agent-chat.model';

export interface AccountData {
  events?: MatrixBasicEvent[];
}

export interface JoinedRoom {
  account_data?: AccountData;
  timeline?: Timeline;
  unread_notifications: UnreadNotifications;
}

export interface UnreadNotifications {
  highlight_count: number;
  notification_count: number;
}

export interface MatrixBasicEvent {
  content?: any;
  room_id?: string;
  type?: string;
}

export enum MessageEventType {
  TEXT = 'm.text',
  IMAGE = 'm.image',
  ENDCHAT = 'm.endchat',
  JOB_INF_PAYMENT = 'm.job_inf_payment',
  PLATFORM_MESSAGE = 'm.platform_message',
  BUTTONS = 'm.buttons',
  BUTTON_PRESSED = 'm.button_pressed',
  UPLOAD = 'm.upload',
  UPLOAD_DOCUMENTS = 'm.upload_documents',
  LOCATION = 'm.location',
  LOCATION_SET = 'm.location_set',
  JOB_CARD = 'm.job_card',
  MERCHANTS = 'm.merchants',
  AUDIO = 'm.audio',
  DOCUMENT = 'm.document',
  PRODUCTS = 'm.products',
  PAYMENT_RESERVATION_REQUEST = 'm.payment_reservation_request',
  PAYMENT_RESERVATION_DONE = 'm.payment_reservation_done',
  SHOW_JOB_CARD = 'm.show_job_card',
  FLOW = 'm.flow',
  NOTIFYWRITING = 'm.notify_writing',
  USERWRITING = 'm.user_writing',
  NEW_ROOM = 'm.heyned_new_room',
  PAYMENT_REQUEST = 'm.payment_request',
  PAYMENT_DONE = 'm.payment_done',
  MAP = 'm.map',
  RATING_REQUEST = 'm.rating_request',
  RATING = 'm.rating',
  PING = 'm.ping',
  PONG = 'm.pong',
}

export enum MessageSenderType {
  BOT = 'BOT',
}

export type IMessageEvent =
  | IMessageEventText
  | IMessageEventImage
  | IMessageEventEndChat
  | IMessageEventJobInfPayment
  | IMessageEventPlatformMessage
  | IMessageEventButton
  | IMessageEventButtonPressed
  | IMessageEventUpload
  | IMessageEventUploadDocuments
  | IMessageEventLocation
  | IMessageEventLocationSet
  | IMessageEventJobCard
  | IMessageEventMerchant
  | IMessageEventAudio
  | IMessageEventDocument
  | IMessageEventPaymentReservation
  | IMessageEventPaymentReservationDone
  | IMessageEventFlow
  | IMessageEventUserWriting
  | IMessageEventPaymentRequest
  | IMessageEventPaymentDone
  | IMessageEventRating
  | IMessageEventRatingRequest
  | IMessageEventMap;

export interface IMessageEventUserWriting {
  msgtype: MessageEventType.USERWRITING;
  body: string;
  displayWritingAfterReply?: boolean;
  showForSeconds?: number;
}

export interface IMessageEventText {
  msgtype: MessageEventType.TEXT;
  body: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventImage {
  msgtype: MessageEventType.IMAGE;
  body: string;
  url: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventEndChat {
  msgtype: MessageEventType.ENDCHAT;
  body: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventJobInfPayment {
  msgtype: MessageEventType.JOB_INF_PAYMENT;
  body: string;
  amount: string;
  currency: string;
  merchantName: string;
  jobId: string;
  quoteId: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventPlatformMessage {
  msgtype: MessageEventType.PLATFORM_MESSAGE;
  body: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventPaymentRequest {
  msgtype: MessageEventType.PAYMENT_REQUEST;
  actionId: string;
  amount: IAmount;
  description: string;
  recipientDescription?: string;
  recipientWalletId?: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventPaymentDone {
  msgtype: MessageEventType.PAYMENT_DONE;
  actionId: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventMap {
  msgtype: MessageEventType.MAP;
  latitude: string;
  longitude: string;
  imageUrl: string;
  address: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventRatingRequest {
  msgtype: MessageEventType.RATING_REQUEST;
  actionId: string;
  description: string;
  sendReasons: boolean;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventRating {
  msgtype: MessageEventType.RATING;
  actionId: string;
  rating: number;
  message?: string;
  reasons?: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventButton {
  msgtype: MessageEventType.BUTTONS;
  body: string;
  actionId: string;
  label: string;
  buttons: IMessageEventButtonBody[];
  pairedMessage?: IMessageEventButtonPressed;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventButtonBody {
  id: string;
  label: string;
  url?: string;
  nextState: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventButtonPressed {
  msgtype: MessageEventType.BUTTON_PRESSED;
  body: string;
  actionId: string;
  buttonId: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventUpload {
  msgtype: MessageEventType.UPLOAD;
  body: string;
  actionId: string;
  displaySkipButton: boolean;
  skipButtonLabel: string;
  allowImages: boolean;
  allowVideos?: boolean; // TODO will be removed in future
  allowAudio?: boolean; // TODO will be removed in future
  allowPdf: boolean;
  minDocuments?: number;
  maxDocuments?: number;
  maxSizeMb?: number;
  pairedMessage?: IMessageEventUploadDocuments;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventUploadDocuments {
  msgtype: MessageEventType.UPLOAD_DOCUMENTS;
  actionId: string;
  skipButtonPressed?: boolean;
  documents: IMessageEventUploadedDocument[];
  removeDocumentIds?: any[];
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventUploadedDocument {
  id: string;
  type: MessageUploadedDocumentType;
  url: string;
  urlThumbnail?: string;
  displayWritingAfterReply?: boolean;
}

export enum MessageUploadedDocumentType {
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export interface IMessageEventLocation {
  msgtype: MessageEventType.LOCATION;
  body: string;
  actionId: string;
  locationId?: string;
  addressString?: string;
  buttonConfirmLabel?: string;
  buttonSetLabel: string;
  pairedMessage?: IMessageEventLocationSet;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventLocationSet {
  msgtype: MessageEventType.LOCATION_SET;
  body: string;
  actionId: string;
  locationId: string;
  locationName?: string;
  addressString: string;
  buttonType: MessageEventLocationButtonType;
  displayWritingAfterReply?: boolean;
}

export enum MessageEventLocationButtonType {
  COFIRM = 'CONFIRM',
  SET = 'SET',
}

export interface IMessageEventJobCard {
  msgtype: MessageEventType.JOB_CARD;
  body: string;
  jobId: string;
  jobName: string;
  description: string;
  locationName: string;
  urgent: boolean;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventProduct {
  msgtype: MessageEventType.PRODUCTS;
  products: IMessageEventProductData[];
}

export interface IMessageEventProductData {
  sku: string;
  name: string;
  thumbnail?: string;
  price: IAmount;
  priceBeforeDiscount?: IAmount;
  discount?: number;
  variantsCount?: number;
  favourite?: boolean;
  lowStockCount?: number;
}

export interface IMessageEventMerchant {
  msgtype: MessageEventType.MERCHANTS;
  body: string;
  jobId: string;
  merchants: IMessageEventMerchantDescription[];
  jobName: string;
  description: string;
  locationName: string;
  urgent: boolean;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventMerchantDescription {
  id: string;
  picture: string;
  name: string;
  rating: number;
  numberOfReviews: number;
  locationDescription: string;
  locationDistance: number;
  calloutFee: IAmount;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventAudio {
  msgtype: MessageEventType.AUDIO;
  body: string;
  url: string;
  duration: number;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventDocument {
  msgtype: MessageEventType.DOCUMENT;
  body: string;
  url: string;
  filename: string;
  format: MessageEventDocument;
  size: number;
  displayWritingAfterReply?: boolean;
}

export enum MessageEventDocument {
  PDF = 'PDF',
}

export interface IMessageEventPaymentReservation {
  msgtype: MessageEventType.PAYMENT_RESERVATION_REQUEST;
  body: string;
  actionId: string;
  jobId: string;
  paymentId: string;
  amount: IAmount;
  description?: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventShowJobCard {
  msgtype: MessageEventType.SHOW_JOB_CARD;
  body: string;
  jobId: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventPaymentReservationDone {
  msgtype: MessageEventType.PAYMENT_RESERVATION_DONE;
  body: string;
  displayWritingAfterReply?: boolean;
}

export interface IMessageEventFlow {
  msgtype: MessageEventType.FLOW;
  body: string;
  displayWritingAfterReply?: boolean;
}

export interface IPaymentRequestClick {
  jobId: string;
  paymentId: string;
  quoteId: string;
}

export interface IChatPaymentRequestContent {
  paymentId: string;
  usedPoints?: number;
  amount?: IAmount;
}

export interface IChatRatingRequested {
  sendReasons: boolean;
  actionId: string;
  roomId: string;
}

export interface IAgentChatSendEventRequest {
  roomId: string;
  eventType: AgentChatEventType;
  payload: any;
  txnId?: string;
}

export interface IMatrixMessage<T = IMessageEvent> {
  content?: T;
  event_id?: string;
  origin_server_ts?: number;
  room_id?: string;
  sender?: string;
  sender_type?: string;
  sender_self?: boolean;
  sender_displayname?: string;
  type?: string;
}

export interface MatrixRoomTimelineEvent<T = IMessageEvent> {
  event_id: string;
  origin_server_ts: number;
  room_id: string;
  user_id: string;
  sender: string;
  sender_displayname: string;
  sender_type: string;
  sender_self: boolean;
  type: string;
  content: T;
  readers: IMessageReader[];
  recipients?: IMessageRecipient[];
}

export interface IMessageReader {
  id: string;
  display_name: string;
  type: string;
  read_at?: string;
}

export interface IMessageRecipient {
  id: string;
  display_name: string;
  type: string;
  read_at?: string;
}

export interface SyncResponse {
  next_batch?: string;
  rooms?: SyncResponseRooms;
}

export interface SyncResponseRooms {
  join?: { [key: string]: JoinedRoom };
}

export interface Timeline {
  events?: IMatrixMessage[];
  limited?: boolean;
  prev_batch?: string;
}

export interface IGetRoomMessagesResponse {
  chunk: IMatrixMessage[];
  start: string;
  end: string;
}

export interface RoomMember {
  userId: string;
  displayName: string;
  type: string;
  email?: string;
  phoneNumber?: string;
}

export interface Room {
  id: string;
  created: number;
  entityType?: string;
  entityId?: string;
  customerId?: string;
  merchantId?: string;
  customerName?: string;
  merchantName?: string;
  members: RoomMember[];
}

export interface IGetMessagesRequest {
  from?: string;
  to?: string;
  dir: string;
  limit?: number;
}

export interface IChatImageFileResponse {
  id: string;
  url: string;
}

export interface IAgentChatCreateCRMCase {
  name: string;
  description: string;
  typeCode: CreateCRMCaseType;
  priority: CreateCRMCasePriority;
}

export enum CreateCRMCaseType {
  QUESTION = 'QUESTION',
  PROBLEM = 'PROBLEM',
  COMPLAINT = 'COMPLAINT',
  DISPUTE = 'DISPUTE',
  FRAUD = 'FRAUD',
}

export enum CreateCRMCasePriority {
  HIGH = 'HIGH',
  NORMAL = 'NORMAL',
  LOW = 'LOW',
}
