import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AddressType, HashStringPrefix, ICommonOnChangeOutput } from '@wakanda/wakanda-core';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import keys from 'lodash-es/keys';
import lowerCase from 'lodash-es/lowerCase';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'ui-select',
  templateUrl: './select.component.html',
})
export class SelectComponent implements OnChanges, OnDestroy {
  menuItems: any[] | any;
  isSelectOpen = new BehaviorSubject(false);
  isFocused = new BehaviorSubject(false);
  hashType = HashStringPrefix;
  openCategory: string;
  addressTypes = AddressType;

  @Input() showAsMapSelect: boolean;
  @Input() showAsMapSearch: boolean;

  @Input() autofocus: boolean;
  @Input() readOnly: boolean;
  @Input() disabled: boolean;
  @Input() isOpen: boolean;
  @Input() forceClose: boolean;
  @Input() openOnFocus = true;
  @Input() clearable = true;

  @Input() bindLabel = 'text';
  @Input() bindValue = 'id';
  @Input() placeholder = '';

  @Input() name = '';
  @Input() value: string;
  @Input() options: any;

  @Input() loading: boolean;
  @Input() showOnRemoveButton: boolean;
  @Input() style: any;
  @Input() minWidth: string;

  @Output() onFocus = new EventEmitter<boolean>();
  @Output() onBlur = new EventEmitter<ICommonOnChangeOutput>();
  @Output() onEnter = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<ICommonOnChangeOutput>();
  @Output() onClear = new EventEmitter<ICommonOnChangeOutput>();
  @Output() onRemove = new EventEmitter();
  @Output() onSelect = new EventEmitter<any>();
  @Output() onClick = new EventEmitter();

  subscriptions = new Subscription();

  handleChange = (value): void => {
    if (value === this.value) return;

    if (!value || value === '') {
      this.isSelectOpen.next(false);
    }

    this.isSelectOpen.next(true);
    this.onChange.emit({ value, name: this.name });
  };

  handleFocus = (): void => {
    if (this.openOnFocus) this.isSelectOpen.next(true);
    this.isFocused.next(true);
    this.subscriptions.add(this.isFocused.subscribe(isFocused => this.onFocus.emit(isFocused)));
  };

  handleInputBlur = (): void => {
    this.onBlur.emit({ name: this.name, value: this.value });
  };

  handleBlur = (): void => {
    this.isSelectOpen.next(false);
    this.isFocused.next(false);
    this.subscriptions.add(this.isFocused.subscribe(isFocused => this.onFocus.emit(isFocused)));
  };

  handleClear = (): void => {
    if (this.readOnly) return;
    this.value = '';
    this.isSelectOpen.next(false);
    this.isFocused.next(false);

    this.onClear.emit({ value: this.value, name: this.name });

    this.options = null;
    this.menuItems = null;
  };

  handleSelect = (bindValue: string): void => {
    this.isSelectOpen.next(false);
    this.isFocused.next(false);
    // we'd like to compare all values of each object of options.
    const searchedItem = find(
      this.options,
      item => !!find(keys(item), key => lowerCase(get(item, key)) === lowerCase(bindValue)),
    );
    this.value = get(searchedItem, this.bindLabel);

    const result = {
      name: this.name,
      value: get(searchedItem, this.bindLabel),
      id: get(searchedItem, this.bindValue),
      item: searchedItem,
    };

    this.onSelect.emit(result);
    this.options = null;
    this.menuItems = null;
  };

  handleCategorySelect = (value: string, item: any, index: number): void => {
    this.isSelectOpen.next(false);
    this.isFocused.next(false);

    this.value = value;

    const result = {
      name: this.name,
      value: this.value,
      id: index,
      item,
    };

    this.onSelect.emit(result);
    this.options = null;
    this.menuItems = null;
  };

  handleMapSelect = (bindValue: string): void => {
    const searchedItem = find(
      this.options,
      item => !!find(keys(item), key => lowerCase(get(item, key)) === lowerCase(bindValue)),
    );
    this.value = get(searchedItem, this.bindLabel);

    const result = {
      name: this.name,
      value: get(searchedItem, this.bindLabel),
      id: get(searchedItem, this.bindValue),
      item: searchedItem,
    };

    this.onSelect.emit(result);
  };

  handleEnter = (event: KeyboardEvent): void => {
    if (event.key !== 'Enter') return;

    event.stopPropagation();

    this.isSelectOpen.next(false);
    this.isFocused.next(false);

    this.onEnter.emit({ name: this.name, value: this.value });

    this.options = null;
    this.menuItems = null;
  };

  ngOnChanges(changes: SimpleChanges): void {
    const optionsChanges = changes['options'];
    const isOpenChanges = changes['options'];
    if (get(optionsChanges, 'previousValue') !== get(optionsChanges, 'currentValue')) {
      this.menuItems = this.options;
    }
    if (get(isOpenChanges, 'previousValue') !== get(isOpenChanges, 'currentValue')) {
      this.isSelectOpen.next(this.isOpen);
    }
  }

  onViewAll = (name: string): void => {
    if (name === this.openCategory) {
      this.openCategory = null;
      return;
    }
    this.openCategory = name;
  };

  trackByMapOptionId(index, item): any {
    return item.id;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
