import { createAction, props } from '@ngrx/store';
import { ITask } from '../models/store.models';

//
export const fetchAppConfigRequestAction = createAction(
  '[APP-CONFIG/API] fetch app config request action',
  props<{
    onSuccess?: () => void;
    onError?: () => void;
    taskMetadata?: ITask;
  }>(),
);
// TODO: appConfig interface
export const fetchAppConfigResponseAction = createAction(
  '[APP-CONFIG/API] fetch app config response action',
  props<{ data: any; taskMetadata?: ITask }>(),
);
