import { Action } from '@ngrx/store';
import * as events from './email-verification.events';

export class EmailVerificationEmailVerifyReequestAction implements Action {
  readonly type = events.EMAIL_VERIFICATINO_VERIFY_EMAIL_REQUEST;
  constructor(public taskId: string, public token: string, public onError: () => void) {}
}

export class EmailVerificationEmailVerifySucceedAction implements Action {
  readonly type = events.EMAIL_VERIFICATINO_VERIFY_EMAIL_RECEIVED;
  constructor(public taskId: string) {}
}

export class EmailVerificationErrorAction implements Action {
  readonly type = events.EMAIL_VERIFICATINO_FAILED;
  constructor(public taskId: string, public error: any, public hidden: boolean) {}
}
