import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authorization/authorization.service';
import { GuestAuthorizationService } from '../services/authorization/guest-authorization.service';

@Injectable()
export class CustomerTokenInterceptor implements HttpInterceptor {
  constructor(
    private guestAuthorizationService: GuestAuthorizationService,
    private authService: AuthorizationService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.guestAuthorizationService.getToken();
    const isAuthorized = this.authService.isAuthorized();
    const endpoints: string[] = ['/ecommerce/v2/cart/', '/groceries/v1/cart', '/takeaways/v1/cart/place-order'];

    const hasEndpoint = endpoints.some(url => request.url.includes(url));

    if (isAuthorized || !token || !hasEndpoint) {
      return next.handle(request);
    }

    const CUSTOMER_TOKEN = 'X-Avo-CustomerToken';

    request = request.clone({
      headers: request.headers.set(CUSTOMER_TOKEN, token),
    });

    return next.handle(request);
  }
}
