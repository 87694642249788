<div
  class="ui-carousel"
  [ngClass]="{
    'restrict-height': !!restrictHeight,
    'full-width': !!fullWidth
  }"
>
  <div #outerWrapper class="outer-wrapper" [style.minHeight]="minHeight ? minHeight : '18rem'">
    <div class="wrapper">
      <span class="content ngContent" #ref [style.marginLeft.px]="marginLeft">
        <ng-content></ng-content>
      </span>
      <span class="content" *ngIf="!ref.innerHTML.trim()">
        <ng-container *ngFor="let item of items; let i = index">
          <span
            class="item"
            [ngClass]="{ 'row center': alignCenter }"
            *ngIf="!item.disabled"
            [style.marginLeft.px]="i === 0 ? marginLeft : ''"
            (click)="onClick.emit(item)"
          >
            <div class="icons" *ngIf="(!!favourite || !!share) && (!!item?.url || !!item?.image) && active === i">
              <ui-icon
                [icon]="!!favouriteActive ? 'heart1_fill' : 'heart1'"
                *ngIf="!!favourite && (!!item?.url || !!item?.image) && active === i"
                (click)="onFavourite.emit(item)"
              ></ui-icon>
              <ui-icon
                icon="share_2"
                *ngIf="!!share && (!!item?.url || !!item?.image) && active === i"
                (click)="onShare.emit(item)"
              ></ui-icon>
            </div>
            <i *ngIf="!!item?.image" class="slide icon" [ngClass]="item?.image"></i>
            <ui-icon
              *ngIf="!!item?.svg"
              class="slide icon {{ item?.svg }}"
              [icon]="item?.svg"
              [width]="iconWidth || '100%'"
              [height]="maxHeight || 'auto'"
            ></ui-icon>
            <ng-container *ngIf="!item.imagePosition || item.imagePosition === 1">
              <ui-icon
                *ngIf="!!item?.url || !!item?.bannerUrl"
                class="slide icon {{ item?.url || item?.bannerUrl }}"
                [class.cover]="cover"
                [src]="item?.url || item?.bannerUrl"
                [width]="iconWidth || '100%'"
                [height]="maxHeight || 'auto'"
              ></ui-icon>
            </ng-container>
            <ui-divider [compact]="true" *ngIf="!!item?.title"></ui-divider>
            <h2 *ngIf="!!item?.title">{{ item.title }}</h2>
            <ng-container *ngIf="item.imagePosition === 2">
              <ui-divider [compact]="true" *ngIf="!!item?.title"></ui-divider>
              <ui-icon
                *ngIf="!!item?.url || !!item?.bannerUrl"
                class="slide icon {{ item?.url || item?.bannerUrl }}"
                [class.cover]="cover"
                [src]="item?.url || item?.bannerUrl"
                [width]="iconWidth || '100%'"
                [height]="maxHeight || 'auto'"
              ></ui-icon>
            </ng-container>
            <ui-divider [compact]="true" *ngIf="!!item?.title && !!item?.description"></ui-divider>
            <p *ngIf="!!item?.description" [style.white-space]="'break-spaces'" [style.width]="width">
              {{ item.description }}
            </p>
            <ui-divider [compact]="true" *ngIf="!!item?.extraButton"></ui-divider>
            <a *ngIf="!!item?.extraButton" (click)="onExtraButtonClick.emit(item)">{{ item.extraButton }}</a>
          </span>
        </ng-container>
      </span>
    </div>
  </div>
  <ng-container *ngIf="slideCount > 1 && !!navigation">
    <ui-divider [compact]="true" *ngIf="!restrictHeight"></ui-divider>
    <div class="action-buttons">
      <ng-container *ngFor="let item of items; let i = index">
        <button
          *ngIf="!item.disabled"
          class="carousel button"
          [name]="i"
          [attr.aria-label]="'slide to ' + (item.title || 'next')"
          [ngClass]="{ active: active === i, small: i < active - 1 || i > active + 1 }"
          (click)="handleChange($event)"
        ></button>
      </ng-container>
    </div>
  </ng-container>
</div>
