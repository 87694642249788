import { Component, Injector } from '@angular/core';
import { FadeInOutLongAnimation } from '@wakanda/wakanda-ui';
import { LayoutBaseContainer } from '../layout.base.container';

@Component({
  selector: 'wakanda-avo-business-header-selling-on-avo-menu',
  animations: [FadeInOutLongAnimation],
  templateUrl: './header-selling-on-avo-menu.component.html',
  styleUrls: ['./header-selling-on-avo-menu.component.scss'],
})
export class HeaderSellingOnAvoMenuComponent extends LayoutBaseContainer {
  isDropdownOpened = false;

  constructor(injector: Injector) {
    super(injector);
  }

  navigateToLink = (link: string): void => {
    this.isDropdownOpened = false;
    this.navigateTo(link);
  };
}
