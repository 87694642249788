import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSpinnerComponent } from './ui-spinner.component';
import { LottieModule } from 'ngx-lottie';
import { LottieOptionsPipe } from './lottie-options.pipe';
import { LottieDimensionsPipe } from './lottie-dimensions.pipe';

@NgModule({
  declarations: [UiSpinnerComponent, LottieOptionsPipe, LottieDimensionsPipe],
  exports: [UiSpinnerComponent],
  imports: [CommonModule, LottieModule],
})
export class UiSpinnerModule {}
