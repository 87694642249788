import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-segment',
  template: `
    <div
      class="ui-segment"
      [ngClass]="{
        'borderless': borders === 'none',
        'compact': !!compact,
        'full': !!fullHeight,
        'left': !!leftAlign,
        'right': !!rightAlign,
        'slide-in-up-modal': !!slideInUpModal,
        'no-bottom-padding': !!noBottomPadding,
        'no-top-padding': !!noTopPadding
      }"
    >
      <div class="segment-wrapper">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class SegmentComponent {
  @Input() compact: boolean;
  @Input() borders: string;
  @Input() fullHeight: boolean;
  @Input() leftAlign: boolean;
  @Input() rightAlign: boolean;
  @Input() slideInUpModal: boolean;
  @Input() noBottomPadding: boolean;
  @Input() noTopPadding: boolean;
}
