import { Component, Input } from '@angular/core';

@Component({
  selector: 'wakanda-avo-business-faq-panel',
  templateUrl: './faq-panel.component.html',
  styleUrls: ['./faq-panel.component.scss'],
})
export class FaqPanelComponent {
  @Input() title: string;
  @Input() last = false;
  @Input() isSmall = false;

  isOpen = false;

  handlePanel(): void {
    this.isOpen = !this.isOpen;
  }
}
