import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AssetUploadStatus } from '../../models/assets.model';
import { failed, HttpErrorAction, started, succeed } from '../../redux/application/task.actions';
import { of } from 'rxjs';
import { catchError, delay, finalize, map, mergeMap, switchMap } from 'rxjs/operators';
import * as actions from './assets.actions';
import * as EVENTS from './assets.events';
import { AssetsService } from '../../services/assets/assets.service';
import { ApplicationFacade } from '../../redux/application/application.facade';
import { HttpEventType, HttpUploadProgressEvent } from '@angular/common/http';
import toInteger from 'lodash-es/toInteger';

@Injectable()
export class AssetsEffects {
  assetUploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AssetsUploadFileRequestAction>(EVENTS.ASSETS_UPLOAD_FILE_REQUEST),
      mergeMap(({ taskId, data, bucket, onSucceed, onError }) =>
        this.service.postAsset(false, data, bucket).pipe(
          switchMap(response => {
            return [
              succeed(new actions.AssetsUploadFileResponseAction(taskId, response)),
              started(
                new actions.AssetStatusFileRequestAction(
                  `assets-check-file-status-${JSON.stringify(response.dmzFileId)}`,
                  response.dmzFileId,
                  bucket,
                  onSucceed,
                  onError,
                ),
                'Uploading file',
              ),
            ];
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(failed(new actions.AssetsFailed(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  assetUploadFileWithProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AssetsUploadFileProgressRequestAction>(EVENTS.ASSETS_UPLOAD_FILE_PROGRESS_REQUEST),
      mergeMap(({ taskId, data, bucket, onSucceed, onError, onProgress }) =>
        this.service.postAssetWithProgress(false, data, bucket).pipe(
          map(response => {
            if (response?.type === HttpEventType.UploadProgress) {
              const uploadResponse = response as HttpUploadProgressEvent;
              const percentage = toInteger((uploadResponse?.loaded / uploadResponse?.total) * 100);
              if (onProgress) onProgress(percentage);
              return succeed(new actions.AssetsUploadFileResponseAction(taskId, null));
            } else if (response?.type === HttpEventType.Response) {
              return started(
                new actions.AssetStatusFileRequestAction(
                  `assets-check-file-status-with-progress${JSON.stringify(response.body.dmzFileId)}`,
                  response.body.dmzFileId,
                  bucket,
                  onSucceed,
                  onError,
                ),
                null,
                true,
              );
            } else {
              return succeed(new actions.AssetsUploadFileResponseAction(taskId, null));
            }
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(failed(new actions.AssetsFailed(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchAssetStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AssetStatusFileRequestAction>(EVENTS.ASSETS_STATUS_FILE_REQUEST),
      mergeMap(({ taskId, id, bucket, onSucceed, onError }) =>
        this.service.getAssetStatus(false, id, bucket).pipe(
          delay(1500),
          map(response => {
            if (response.status === AssetUploadStatus.PROCESSING) {
              return new actions.AssetStatusFileRequestAction(taskId, id, bucket, onSucceed, onError);
            } else if (response.status === AssetUploadStatus.DONE) {
              onSucceed(response);
              return succeed(new actions.AssetStatusFileResponseAction(taskId, response));
            } else if (response.status === AssetUploadStatus.ERROR) {
              if (onError) onError(response.error);
              return failed(new actions.AssetsFailed(taskId, response.error));
            }
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: AssetsService) {}
}
