import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';
import { IAddressForm, ILocationFormV3, ILocationV3, INewLocationV3, IPosition } from '../../models/location.model';
import { started } from '../application/task.actions';
import * as actions from './location.actions';
import { ILocationState } from './location.reducer';
import * as selectors from './location.selectors';
import { IBuyerLocation, IBuyerLocationRequest } from '../../models/buyer.model';

@Injectable({
  providedIn: 'root',
})
export class LocationFacade {
  getLocations$ = this.store.pipe(select(selectors.getLocations));
  getMerchantLocations$ = this.store.pipe(select(selectors.getMerchantLocations));
  getValidMerchantLocations$ = this.store.pipe(select(selectors.getValidMerchantLocations));
  getCurrentLocation$ = this.store.pipe(select(selectors.getCurrentLocation));
  getSearchedData$ = this.store.pipe(select(selectors.getSearchedData));
  getGooglePlaceSearch$ = this.store.pipe(select(selectors.getGooglePlaceSearch));

  constructor(private store: Store<ILocationState>) {}

  fetchLocations(silently?: boolean): void {
    const taskId = `location-fetch-locations-${uuidv4()}`;
    this.store.dispatch(started(new actions.LocationFetchLocationsRequest(taskId), 'Fetching locations', silently));
  }

  fetchMerchantLocations(merchantId: string, silently?: boolean): void {
    const taskId = `location-fetch-merchant-locations-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.LocationFetchMerchantLocationsRequest(merchantId, taskId), 'Fetching locations', silently),
    );
  }

  addMerchantLocation(
    request: IBuyerLocationRequest,
    merchantId: string,
    onSucceed: (response: IBuyerLocation) => void,
    onError?: () => void,
  ): void {
    const taskId = `add-merchant-location-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.LocationAddMerchantLocationRequest(taskId, request, merchantId, onSucceed, onError),
        'Please wait a moment',
      ),
    );
  }

  editLocation(locationId: string, request: INewLocationV3, onSucceed: () => void, onError: () => void): void {
    const taskId = `location-edit-location-${JSON.stringify(request)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.LocationEditLocationRequest(taskId, locationId, request, onSucceed, onError),
        'Please wait a moment',
      ),
    );
  }

  deleteLocation(locationId: string, onSucceed: () => void): void {
    const taskId = `location-delete-location-${locationId}-${uuidv4()}`;
    this.store.dispatch(
      started(new actions.LocationDeleteLocationRequest(taskId, locationId, onSucceed), 'Please wait a moment'),
    );
  }

  findAndReplaceLocation = (
    form: ILocationFormV3,
    replaceLocationId: string,
    onSuccess: () => void,
    onError: () => void,
  ): void => {
    const { name, instructions, ...address } = form;

    if (replaceLocationId) {
      const request: INewLocationV3 = {
        name,
        address,
        instructions,
        geocodingData: JSON.stringify(form.geocodingData),
        location: form.location,
      };

      this.editLocation(replaceLocationId, request, onSuccess, onError);
    }
  };

  setCurrentLocation(locationId: string, onSucceed?: (response: IAddressForm, json?: string) => void): void {
    const taskId = `location-set-current-location-${locationId}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.LocationSetCurrentLocationRequest(taskId, locationId, onSucceed),
        'Processing your location',
        true,
      ),
    );
  }

  setCurrentLocationToStore(location: ILocationV3): void {
    const taskId = `location-set-current-location-to-store-${location.id}-${uuidv4()}`;
    this.store.dispatch(new actions.LocationSetCurrentLocationReceived(taskId, location));
  }

  fetchCurrentLocation(onSucceed?: (response: IAddressForm, json?: string) => void, onError?: () => void): void {
    const taskId = `location-fetch-current-location-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.LocationFetchCurrentLocationRequest(taskId, onSucceed, onError),
        'Fetching current location',
        true,
      ),
    );
  }

  fetchNearestLocation(position: IPosition, onSucceed?: (response: IAddressForm, json?: string) => void): void {
    const taskId = `location-fetch-get-nearest-position${JSON.stringify(position)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.LocationFetchNearestLocationRequest(taskId, position, onSucceed),
        'Finding nearest location',
        true,
      ),
    );
  }

  fetchSearchLocationByQuery(
    query: string,
    onSucceed: (query: string, response: google.maps.places.AutocompletePrediction[]) => void,
    countryCodes?: string[],
  ): void {
    const taskId = `location-search-location-by-query-${query}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.LocationFetchSearchByQueryRequest(taskId, query, onSucceed, countryCodes),
        'Processing searched location',
        true,
      ),
    );
  }

  findPlaceByAddressString(
    addressString: string,
    onSucceed?: (response: IAddressForm, json?: any) => void,
    onError?: any,
  ): void {
    const taskId = `location-find-place-by-addressString-${addressString}-${uuidv4()}`;
    const taskMessage = 'Processing searched location';
    this.store.dispatch(
      started(
        new actions.LocationFindPlaceByAddressStringRequest(taskId, addressString, onSucceed, onError),
        taskMessage,
        true,
      ),
    );
  }

  findPlaceByPosition(
    position: IPosition,
    onSucceed?: (response: IAddressForm, json?: string) => void,
    onError?: () => void,
  ): void {
    const taskId = `location-find-place-by-location-${JSON.stringify(position)}-${uuidv4()}`;
    this.store.dispatch(
      started(
        new actions.LocationFindPlaceByPositionRequest(taskId, position, onSucceed, onError),
        'Processing searched location',
        true,
      ),
    );
  }

  clearLocation(): void {
    const taskId = `location-clear-${uuidv4()}`;
    this.store.dispatch(new actions.LocationClear(taskId));
  }
}
