import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../init/app.config';

@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {
  constructor(private appConfig: AppConfig) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'JSONP') return next.handle(request);

    if (
      !request.url.includes('loc-saas') &&
      !request.url.includes('configuration.json') &&
      this.appConfig?.apiVersion
    ) {
      request = request.clone({
        headers: request.headers.set('X-Avo-ApiVersion', this.appConfig.apiVersion),
      });
    }

    return next.handle(request);
  }
}
