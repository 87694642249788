import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import get from 'lodash-es/get';
import { of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap } from 'rxjs/operators';
import { WalletService } from '../../services/wallet/wallet.service';
import { ApplicationFacade } from '../application/application.facade';
import { failed, HttpErrorAction, pushNotification, started, succeed } from '../application/task.actions';
import * as actions from './wallet.actions';
import * as EVENT from './wallet.events';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class MyMoneyWalletEffects {
  fetchMyMoneyWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyWalletsRequestAction>(EVENT.WALLETS_REQUEST),
      switchMap(({ taskId, onSucceed, onError }) =>
        this.service.fetchWallet().pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.MyMoneyWalletsResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(failed(new HttpErrorAction(taskId, error, true)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchMyMoneyTransactions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTransactionsRequestAction>(EVENT.TRANSACTIONS_REQUEST),
      switchMap(({ walletId, taskId, onSucceed }) =>
        this.service.fetchTransactions(walletId).pipe(
          map(response => {
            if (typeof onSucceed === 'function') onSucceed();
            return succeed(new actions.MyMoneyTransactionsResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchMyMoneyTransactionDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTransactionDetailRequestAction>(EVENT.TRANSACTION_DETAIL_REQUEST),
      switchMap(({ walletId, transactionId, taskId }) =>
        this.service.fetchTransactionDetail(walletId, transactionId).pipe(
          map(response => succeed(new actions.MyMoneyTransactionDetailResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchPartyAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPartyAccountsRequestAction>(EVENT.PARTY_ACCOUNTS_REQUEST),
      switchMap(({ taskId, onSucceed }) =>
        this.service.fetchPartyAccounts().pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyPartyAccountsResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchMyMoneyTransactionSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTransactionSeaarchRequestAction>(EVENT.TRANSACTION_SEARCH_REQUEST),
      switchMap(({ walletId, query, taskId, onSucceed }) =>
        this.service.fetchSearch(walletId, query).pipe(
          map(response => {
            if (onSucceed) onSucceed();
            return succeed(new actions.MyMoneyTransactionSearchResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchMyMoneyTransactionRecent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTransactionRecentRequestAction>(EVENT.TRANSACTION_RECENT_REQUEST),
      switchMap(({ walletId, taskId }) =>
        this.service.fetchRecent(walletId).pipe(
          map(response => succeed(new actions.MyMoneyTransactionRecentResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  transactionsByFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTransactionFilterRequestAction>(EVENT.TRANSACTION_FILTER_REQUEST),
      switchMap(({ walletId, filter, onSucceed, taskId }) =>
        this.service.setStatementFilter(walletId, filter).pipe(
          map(response => {
            if (onSucceed) onSucceed();
            return succeed(new actions.MyMoneyTransactionFilterResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  transactionsStatementDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTransactionDownloadStatementRequestAction>(EVENT.TRANSACTION_DOWNLOAD_STATEMENT_REQUEST),
      switchMap(({ walletId, filter, onSucceed, taskId }) =>
        this.service.downloadStatement(walletId, filter).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyTransactionDownloadStatementResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  topupPayCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTopupPayCheckRequestAction>(EVENT.TOPUP_PAYCHECK_REQUESTED),
      switchMap(({ walletId, onSucceed, taskId, payload }) =>
        this.service.checkPayment(walletId, payload).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyTopupPayCheckResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  topupAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTopupAccountRequestAction>(EVENT.TOPUP_ACCOUNT_REQUESTED),
      switchMap(({ taskId, walletId, payload, onSucceed }) =>
        this.service.topUpAccount(walletId, payload).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyTopupAccountResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  topupAccountStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTopupAccountStatusRequestAction>(EVENT.TOPUP_ACCOUNT_STATUS_REQUESTED),
      switchMap(({ walletId, topupId, onSucceed, taskId }) =>
        this.service.topupAccountStatus(walletId, topupId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyTopupAccountStatusResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  topupCardStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTopupCardStatusRequestAction>(EVENT.TOPUP_CARD_STATUS_REQUESTED),
      switchMap(({ taskId, walletId, cardId, topupId, onSucceed }) =>
        this.service.topupCardStatus(walletId, cardId, topupId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyTopupCardStatusResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  sendMyMoneyPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayRequestAction>(EVENT.PAY_REQUEST),
      switchMap(({ walletId, payRequest, onSucceed, taskId, onWalletError }) =>
        this.service.pay(walletId, payRequest).pipe(
          map(response => {
            onSucceed(response);
            return pushNotification(
              new actions.MyMoneyPayResponseAction(taskId, response),
              `Success You've just sent R${payRequest.amount.amount} to ${payRequest.phoneNumber}`,
            );
          }),
          catchError(error => {
            const messageCode = get(error, 'error.error.messageCode');
            if (!!messageCode && messageCode.includes('WTX006')) {
              onWalletError(error);
              return of(succeed(new actions.MyMoneyPayResponseAction(taskId, null)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchMyMoneyPayRecentRecipients$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayRecentRecipientsRequestAction>(EVENT.PAY_RECENT_RECIPIENTS_REQUEST),
      switchMap(({ walletId, taskId }) =>
        this.service.fetchPayRecentRecipients(walletId).pipe(
          map(response => succeed(new actions.MyMoneyPayRecentRecipientsResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  sendMyMoneyPayByQrCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByQrCodeRequestAction>(EVENT.PAY_BY_QR_CODE_REQUEST),
      switchMap(({ walletId, payRequest, onSucceed, taskId, onWalletError }) =>
        this.service.payByQRCode(walletId, payRequest).pipe(
          map(response => {
            onSucceed(response);
            return pushNotification(
              new actions.MyMoneyPayByQrCodeResponseAction(taskId, response),
              `Success You've just sent R${payRequest.amount.amount}`,
            );
          }),
          catchError(error => {
            const messageCode = get(error, 'error.error.messageCode');
            if (!!messageCode && messageCode.includes('WTX006')) {
              onWalletError(error);
              return of(succeed(new actions.MyMoneyPayResponseAction(taskId, null)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchMyMoneyRefund$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyRefundRequestAction>(EVENT.REFUND_REQUEST),
      switchMap(({ walletId, transactionId, refundAmount, taskId }) =>
        this.service.refund(walletId, transactionId, refundAmount).pipe(
          map(response => succeed(new actions.MyMoneyRefundResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  withdrawMoney$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyWithdrawRequestAction>(EVENT.WITHDRAW_REQUEST),
      switchMap(({ walletId, withdrawRequest, onSucceed, onError, taskId }) =>
        this.service.withdraw(walletId, withdrawRequest).pipe(
          map(data => {
            onSucceed();
            return succeed(new actions.MyMoneyWithdrawResponseAction(taskId, data));
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  decodeQrCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyDecodeQrCodeRequestAction>(EVENT.DECODE_QR_CODE_REQUEST),
      switchMap(({ data, onSucceed, taskId }) =>
        this.service.decodeQRCode(data).pipe(
          map(response => {
            onSucceed();
            return succeed(new actions.MyMoneyDecodeQrCodeResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  sendPaymentLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyCreatePaymentLinkRequestAction>(EVENT.CREATE_PAYMENT_LINK_REQUEST),
      switchMap(({ walletId, form, onSucceed, taskId }) =>
        this.service.sendPaymentLink(walletId, form).pipe(
          map(response => {
            onSucceed();
            return succeed(new actions.MyMoneyCreatePaymentLinkResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  sendPayRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyCreatePaymentRequestAction>(EVENT.CREATE_PAYMENT_REQUEST),
      switchMap(({ walletId, request, onSucceed, onError, taskId }) =>
        this.service.sendRequest(walletId, request).pipe(
          map(response => {
            onSucceed();
            return pushNotification(
              new actions.MyMoneyCreatePaymentResponseAction(taskId, response),
              'Payment request sent!',
            );
          }),
          catchError(error => {
            onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  addCardRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyAddCardRequestAction>(EVENT.ADD_CARD_REQUEST),
      switchMap(({ walletId, onSucceed, taskId, addCardRequest }) =>
        this.service.addCard(walletId, addCardRequest).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyAddCardResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  editCardRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyEditCardRequestAction>(EVENT.EDIT_CARD_REQUEST),
      switchMap(({ walletId, cardId, request, onSucceed, taskId }) =>
        this.service.editCard(walletId, cardId, request).pipe(
          map(response => {
            onSucceed(response);
            return pushNotification(
              new actions.MyMoneyEditCardResponseAction(taskId, response),
              `Card "${cardId}" has been successfully edited`,
            );
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  deleteCardRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyDeleteCardRequestAction>(EVENT.DELETE_CARD_REQUEST),
      switchMap(({ walletId, cardId, onSucceed, taskId }) =>
        this.service.deleteCard(walletId, cardId).pipe(
          map(() => {
            onSucceed();
            return pushNotification(
              new actions.MyMoneyDeleteCardResponseAction(taskId),
              `Card "${cardId}" has been successfully removed`,
            );
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  topupCardRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayTopUpRequestAction>(EVENT.WALLET_TOPUP_PAY_REQUEST),
      switchMap(({ walletId, cardId, pay, onSucceed, taskId }) =>
        this.service.topupCard(walletId, cardId, pay).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyPayTopResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  externalAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyExternalAccountsRequestAction>(EVENT.WALLET_EXTERNAL_ACCOUNTS_REQUESTED),
      switchMap(({ walletId, accountType, onSucceed, onError, taskId }) =>
        this.service.fetchExternalAccounts(walletId, accountType).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.MyMoneyExternalAccountsResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  cardWithDestination = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyCardWithDestinationRequestAction>(EVENT.WALLET_CARD_WITH_DESTINATION_REQUESTED),
      switchMap(({ walletId, destination, onSucceed, taskId }) =>
        this.service.fetchCardWithDestination(walletId, destination).pipe(
          map(response => {
            if (onSucceed) {
              onSucceed(response);
            }
            return succeed(new actions.MyMoneyCardWithDestinationResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  banks$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyBanksRequestAction>(EVENT.WALLET_BANKS_REQUESTED),
      switchMap(({ taskId }) =>
        this.service.banks().pipe(
          map(response => succeed(new actions.MyMoneyBanksResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  nedbankBanks$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyNedbankBanksRequestAction>(EVENT.WALLET_NEDBANK_BANKS_REQUESTED),
      switchMap(({ taskId }) =>
        this.service.nedbankBanks().pipe(
          map(response => succeed(new actions.MyMoneyNedbankBanksResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  filterSettlementsExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FilterSettlementsExportRequestAction>(EVENT.FILTER_SETTLEMENTS_EXPORT_REQUESTED),
      switchMap(({ taskId, filter, storeId, onSucceed }) =>
        this.service.filterSettlementsExport(filter, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FilterSettlementsExportResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  filterTransactionsExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FilterTransactionsExportRequestAction>(EVENT.FILTER_TRANSACTIONS_EXPORT_REQUESTED),
      switchMap(({ taskId, filter, storeId, onSucceed }) =>
        this.service.filterTransactionsExport(filter, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FilterTransactionsExportResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchTaskFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchTaskFileRequestAction>(EVENT.FETCH_TASK_FILE_REQUESTED),
      switchMap(({ taskId, fetchTaskId, onSucceed }) =>
        this.service.fetchTaskFile(fetchTaskId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FetchTaskFileResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchTaskDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchTaskDetailRequestAction>(EVENT.FETCH_TASK_DETAIL_REQUESTED),
      switchMap(({ taskId, fetchTaskId, onSucceed }) =>
        this.service.fetchTaskDetail(fetchTaskId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FetchTaskDetailResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  settlementTransactionFilter = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FilterPayoutTransactionsRequestAction>(EVENT.FILTER_SETTLEMENT_TRANSACTION_REQUESTED),
      switchMap(({ taskId, storeId, filter, pageSize, next, onSucceed, onError }) =>
        this.service.filterPayoutTransactions(storeId, filter, pageSize, next).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FilterPayoutTransactionsResponseAction(taskId, response));
          }),
          catchError(error => {
            if (error?.error?.error?.code === 'unknown_error') {
              onError(error);
              return of(failed(new HttpErrorAction(taskId, error, true)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  generatePaymentRemittance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GeneratePaymentRemittanceRequestAction>(EVENT.GENERATE_PAYMENT_REMITTANCE_REQUESTED),
      switchMap(({ taskId, filter, storeId, onSucceed, onError }) =>
        this.service.generatePaymentRemittance(storeId, filter).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.GeneratePaymentRemittanceResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchTaxInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchTaxInvoicesRequestAction>(EVENT.FETCH_TAX_INVOICES_REQUESTED),
      switchMap(({ taskId, filter, storeId, pageSize, page, onSucceed, onError }) =>
        this.service.fetchTaxInvoices(filter, storeId, pageSize, page).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.FetchTaxInvoicesResponseAction(taskId, response));
          }),
          catchError(error => {
            if (error?.error?.error?.code === 'unknown_error') {
              onError(error);
              return of(failed(new HttpErrorAction(taskId, error, true)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  downloadTaxInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DownloadTaxInvoiceRequestAction>(EVENT.DOWNLOAD_TAX_INVOICE_REQUESTED),
      switchMap(({ taskId, taxInvoiceId, storeId, onSucceed }) =>
        this.service.downloadTaxInvoice(taxInvoiceId, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.DownloadTaxInvoiceResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchSellerStatement$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchSellerStatementRequest>(EVENT.STATEMENTS_FETCH_SELLER_STATEMENT_REQUEST),
      mergeMap(({ taskId, request, storeId, page, pageSize, onError }) =>
        this.service.sellerStatement(request, storeId, pageSize, page).pipe(
          map(data => succeed(new actions.FetchSellerStatementReceived(taskId, data))),
          catchError(error => {
            if (error?.error?.error?.code === 'unknown_error') {
              onError(error);
              return of(failed(new HttpErrorAction(taskId, error, true)));
            }
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  downloadSellerStatement$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.DownloadSellerStatementRequestAction>(EVENT.STATEMENTS_DOWNLOAD_SELLER_STATEMENT_REQUEST),
      switchMap(({ taskId, statementNumber, storeId, onSucceed }) =>
        this.service.downloadSellerStatement(statementNumber, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.DownloadSellerStatementResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  generateSellerStatement$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GenerateSellerStatementRequest>(EVENT.STATEMENTS_GENERATE_SELLER_STATEMENT_REQUEST),
      switchMap(({ taskId, request, storeId, onSucceed }) =>
        this.service.generateSellerStatement(request, storeId).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.GenerateSellerStatementReceived(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  addSettlementAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyAddSettlementAccountRequestAction>(EVENT.ADD_SETTLEMENT_ACCOUNT_REQUESTED),
      switchMap(({ request, onSucceed, onError, taskId }) =>
        this.service.addSettlementAccount(request).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return pushNotification(
              new actions.MyMoneyAddSettlementAccountResponseAction(taskId, response),
              `External account "${request.accountNumber}" successfully added`,
            );
          }),
          catchError(error => {
            if (onError) onError(error);
            const hideError = error?.error?.error?.code === 'SID_missing';
            return of(failed(new HttpErrorAction(taskId, error, hideError)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  updateSettlementAccountType$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyUpdateSettlementAccountTypeRequestAction>(EVENT.UPDATE_SETTLEMENT_ACCOUNT_TYPE_REQUESTED),
      switchMap(({ request, onSucceed, taskId, accountId }) =>
        this.service.updateSettlementAccountType(accountId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyUpdateSettlementAccountTypeResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchSettlementAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyFetchSettlementAccountRequestAction>(EVENT.FETCH_SETTLEMENT_ACCOUNT_REQUESTED),
      switchMap(({ onSucceed, taskId }) =>
        this.service.fetchSettlementAccount().pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.MyMoneyFetchSettlementAccountResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchSettlementSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyFetchSettlementSettingsRequestAction>(EVENT.SETTLEMENT_FETCH_SETTLEMENT_SETTINGS_REQUESTED),
      switchMap(({ onSucceed, taskId }) =>
        this.service.fetchSettlementSettings().pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.MyMoneyFetchSettlementSettingsResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  updateSettlementSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyChangeSettlementSettingsRequestAction>(
        EVENT.SETTLEMENT_CHANGE_SETTLEMENT_SETTINGS_REQUESTED,
      ),
      switchMap(({ request, onSucceed, taskId }) =>
        this.service.updateSettlementSettings(request).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.MyMoneyChangeSettlementSettingsResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  removeExternalAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyRemoveExternalAccountRequestAction>(EVENT.WALLET_REMOVE_EXTERNAL_ACCOUNTS_REQUESTED),
      switchMap(({ walletId, accountId, taskId, onSucceed }) =>
        this.service.removeExternalAccount(walletId, accountId).pipe(
          map(() => {
            onSucceed();
            return pushNotification(
              new actions.MyMoneyRemoveExternalAccountResponseAction(taskId),
              `External account "${accountId}" has been successfully removed`,
            );
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchPaymentLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyFetchPaymentLinkRequestAction>(EVENT.WALLET_PAYMENT_LINK_REQUESTED),
      switchMap(({ onSucceed, taskId, link, onError }) =>
        this.service.fetchPaymentLink(link).pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.MyMoneyFetchPaymentLinkResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) onError();
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  acceptPaymentLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyAcceptPaymentLinkRequestAction>(EVENT.WALLET_PAYMENT_ACCEPT_LINK_REQUESTED),
      switchMap(({ onSucceed, onError, taskId, walletId, paymentLinkId }) =>
        this.service.acceptPaymentLink(walletId, paymentLinkId).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.MyMoneyAcceptPaymentLinkResponseAction(taskId));
          }),
          catchError(error => {
            onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  reminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyReminderRequestAction>(EVENT.REMINDER_REQUESTED),
      switchMap(({ walletId, paymentRequestId, message, onSucceed, taskId }) =>
        this.service.reminder(walletId, paymentRequestId, { message }).pipe(
          map(response => {
            onSucceed();
            return succeed(new actions.MyMoneyReminderResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  loyaltyPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyLoyaltyPointsRequestAction>(EVENT.LOYALTY_POINTS_REQUESTED),
      switchMap(({ taskId }) =>
        this.service.fetchLoyaltyPoints().pipe(
          map(response => succeed(new actions.MyMoneyLoyaltyPointsResponseAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchMerchantSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyFetchMerchantSubscriptionsRequestAction>(EVENT.SUBSCRIPTIONS_MERCHANT_LIST_REQUESTED),
      switchMap(({ onSucceed, taskId, onError }) =>
        this.service.fetchMerchantSubscriptions().pipe(
          map(response => {
            if (onSucceed) onSucceed(response);
            return succeed(new actions.MyMoneyFetchMerchantSubscriptionsResponseAction(taskId, response));
          }),
          catchError(error => {
            if (onError) onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchAvailableMerchantSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyFetchAvailableMerchantSubscriptionsRequestAction>(
        EVENT.SUBSCRIPTIONS_AVAILABLE_MERCHANT_LIST_REQUESTED,
      ),
      switchMap(({ onSucceed, taskId }) =>
        this.service.fetchSubscriptionAvailableMerchantSubscriptions().pipe(
          map(response => {
            if (onSucceed) {
              onSucceed(response);
            }
            return succeed(new actions.MyMoneyFetchAvailableMerchantSubscriptionsResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  addMerchantSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyAddMerchantSubscriptionsRequestAction>(EVENT.SUBSCRIPTIONS_MERCHANT_ADD_REQUESTED),
      switchMap(({ onSucceed, request, taskId }) =>
        this.service.addMerchantSubscription(request).pipe(
          switchMap(response => {
            if (onSucceed) {
              onSucceed(response);
            }
            const fetchSubscriptionsTaskId = `fetch-merchant-subscriptions-${uuidv4()}`;
            return [
              started(new actions.MyMoneyFetchMerchantSubscriptionsRequestAction(fetchSubscriptionsTaskId)),
              succeed(new actions.MyMoneyAddMerchantSubscriptionsResponseAction(taskId, response)),
            ];
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchDashboardWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyFetchDashboardWidgetRequest>(EVENT.DASHBOARD_WIDGET_REQUESTED),
      switchMap(({ taskId, widgetType, onError }) =>
        this.service.fetchDashboardWallet(widgetType).pipe(
          map(response => succeed(new actions.MyMoneyFetchDashboardWidgetResponse(taskId, response))),
          catchError(error => {
            if (onError) onError(error);
            return of(failed(new HttpErrorAction(taskId, error)));
          }),
        ),
      ),
    ),
  );

  payByCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByCardRequestAction>(EVENT.PAY_BY_CARD_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.payByCard(flowId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyPayByCardResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByPointsOnly$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByPointsOnlyRequestAction>(EVENT.PAY_BY_POINTS_ONLY_REQUESTED),
      switchMap(({ taskId, flowId, onSucceed }) =>
        this.service.payByPointsOnly(flowId).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.MyMoneyPayByPointsOnlyResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByWallet$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByWalletRequestAction>(EVENT.PAY_BY_WALLET_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.payByWallet(flowId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyPayByWalletResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payForFree$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayForFreeRequestAction>(EVENT.PAY_FOR_FREE_REQUESTED),
      switchMap(({ taskId, flowId, onSucceed }) =>
        this.service.payForFree(flowId).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.MyMoneyPayForFreeResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByEft$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByEftRequestAction>(EVENT.PAY_BY_EFT_REQUESTED),
      switchMap(({ taskId, flowId, onSucceed }) =>
        this.service.payByEft(flowId).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.MyMoneyPayByEftResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByAmex$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByAmexRequestAction>(EVENT.PAY_BY_AMEX_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.payByAmex(flowId, request).pipe(
          map(data => {
            onSucceed(data);
            return succeed(new actions.MyMoneyPayByAmexResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  payByCredit$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyPayByCreditRequestAction>(EVENT.PAY_BY_CREDIT_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.payByCredit(flowId, request).pipe(
          map(data => {
            onSucceed(data);
            return succeed(new actions.MyMoneyPayByCreditResponseAction(taskId));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  topUpAndPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.MyMoneyTopUpAndPayRequestAction>(EVENT.TOPUP_AND_PAY_REQUESTED),
      switchMap(({ taskId, flowId, request, onSucceed }) =>
        this.service.topUpAndPay(flowId, request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.MyMoneyTopUpAndPayResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: WalletService) {}
}
