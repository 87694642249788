import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface IUIItem {
  id?: string;
  title?: string;
  description?: string;
  image?: string;
  icon?: string;
  canChat?: boolean;
  favourite?: boolean;
  share?: boolean;
  rating?: number;
  reviewsNumber?: number;
  notes?: number;
  iconAlign?: string;
  pictureIcon?: string;
  layered?: boolean;
  layers?: string;
}

@Component({
  selector: 'ui-item',
  templateUrl: './item.component.html',
})
export class ItemComponent {
  @Input() data: IUIItem;
  @Input() image: string;
  @Input() class: string;
  @Input() border: string;
  @Input() size: string;
  @Input() iconSize: string;
  @Input() iconAlign: string;
  @Input() hasShadow: boolean;
  @Input() roundCorners: boolean;
  @Input() hasCheckBox: boolean;
  @Input() checkBoxSelected: boolean;
  @Input() hasClose: boolean;
  @Input() pictureIcon: boolean;
  @Input() compact: boolean;
  @Input() fullWidth: boolean;
  @Input() header: string;
  @Input() noBackground: boolean;

  /** Flag if cursor pointer is applied to whole component */
  @Input() pointer = true;

  @Output() onClick = new EventEmitter<IUIItem>();
}
