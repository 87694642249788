import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { LayoutBaseContainer } from '../layout.base.container';
import { IMenuItem } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-avo-business-sidebar-menu-item',
  templateUrl: './sidebar-menu-item.component.html',
  styleUrls: ['./sidebar-menu-item.component.scss'],
})
export class SidebarMenuItemComponent extends LayoutBaseContainer {
  @Input() item: IMenuItem;
  @Input() isActive: boolean;
  @Input() isExpandable: boolean;
  @Input() isOpen = false;
  @Output() clickItem = new EventEmitter<string>();

  constructor(injector: Injector) {
    super(injector);
  }

  handleItem = (): void => {
    this.isOpen = !this.isOpen;
  };
}
