import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import {
  IPaymentRequestStatementFilter,
  IRequestDetailResponse,
  IRequestFilter,
  IRequestPayDeclineResponse,
} from '../../models/request.model';
import { ApiService } from '../api/api.service';
import * as endpoints from './request.endpoints';

@Injectable()
export class RequestService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  private getApiUrl = (useMockApi: boolean): string =>
    useMockApi ? this.appConfig.getMockApiUrl() : this.appConfig.backendConfig.apiUrl;

  public fetchOutgoingRequests(filter: IRequestFilter, useMockApi = false): Observable<IRequestDetailResponse[]> {
    return this.api.get<IRequestDetailResponse[]>(
      endpoints.outgoingRequest(this.getApiUrl(useMockApi), filter ? filter.status : null),
    );
  }

  public fetchOutgoingPaymentRequestDetail(id: string, useMockApi = false): Observable<IRequestDetailResponse> {
    return this.api.get<IRequestDetailResponse>(endpoints.outgoingPaymentRequestDetail(this.getApiUrl(useMockApi), id));
  }

  public downloadStatement(statementFilter: IPaymentRequestStatementFilter, useMockApi = false): Observable<any> {
    return this.api.post<IPaymentRequestStatementFilter>(
      endpoints.outgoingStatement(this.getApiUrl(useMockApi)),
      statementFilter,
      'blob',
    );
  }

  public payPaymentRequest(
    walletId: string,
    paymentRequestId: string,
    useMockApi = false,
  ): Observable<IRequestPayDeclineResponse> {
    return this.api.post<any>(endpoints.requestPay(walletId, paymentRequestId, this.getApiUrl(useMockApi)));
  }

  public declinePaymentRequest(
    walletId: string,
    paymentRequestId: string,
    useMockApi = false,
  ): Observable<IRequestPayDeclineResponse> {
    return this.api.post<any>(endpoints.requestDecline(walletId, paymentRequestId, this.getApiUrl(useMockApi)));
  }
}
