export const ASSETS_UPLOAD_FILE_REQUEST = 'ASSETS/UPLOAD_FILE/REQUESTED';
export const ASSETS_UPLOAD_FILE_RECEIVED = 'ASSETS/UPLOAD_FILE/RECEIVED';

export const ASSETS_UPLOAD_FILE_PROGRESS_REQUEST = 'ASSETS/UPLOAD_FILE_PROGRESS/REQUESTED';
export const ASSETS_UPLOAD_FILE_PROGRESS_RECEIVED = 'ASSETS/UPLOAD_FILE_PROGRESS/RECEIVED';

export const ASSETS_STATUS_FILE_REQUEST = 'ASSETS/STATUS_FILE/REQUESTED';
export const ASSETS_STATUS_FILE_RECEIVED = 'ASSETS/STATUS_FILE/RECEIVED';

export const ASSETS_FAILED = 'ASSETS/ERROR';
