import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ErrorUtility, ITaskAction, IOrchestrationError } from '@wakanda/wakanda-core';
import { TranslateFacade } from '@wakanda/zentity-translate-store';
import { Subscription } from 'rxjs';

@Pipe({ name: 'getFailureDialogError' })
export class GetFailureDialogError implements PipeTransform, OnDestroy {
  walletId: string;
  //
  subscriptions = new Subscription();

  private getTranslation = (key: string) => {
    let translation;
    this.subscriptions.add(
      this.translateFacade.getTranslateByKey$(key).subscribe(translatedString => (translation = translatedString)),
    );
    return translation;
  };

  constructor(private translateFacade: TranslateFacade) {}

  transform = (task: ITaskAction): string => {
    const orchestrationError = task?.error?.error as IOrchestrationError;

    const defaultErrorMessage = this.getTranslation('error.general_body');

    const messageCode = orchestrationError?.error?.messageCode;
    const errorMessage = orchestrationError?.error?.message || defaultErrorMessage;

    if (messageCode === 'groceries_insufficient_data_for_location') {
      return 'It looks like some of your address info is missing. Please update your address under ‘Profile’ and fill in all the required fields.';
    }

    if (messageCode === 'task.agent_chat.agent_exists') {
      return 'This chat has been already assigned to agent.';
    }

    if (messageCode === 'email_not_verified') {
      return this.getTranslation('task.email_not_verified');
    }

    if (messageCode === 'otp_expired_new_sms_sent') {
      return this.getTranslation('enroll.otp.task.otp_expired');
    }

    if (messageCode === 'otp_failed_new_sms_sent') {
      return this.getTranslation('enroll.otp.task.otp_failed');
    }

    if (messageCode) {
      return this.getTranslation(messageCode);
    }

    if (errorMessage.includes('code=CRE002')) {
      return ErrorUtility.getMessageFromErrorMessage(errorMessage);
    }

    return errorMessage;
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
