export const MY_PROFILE_ACTIVITY_HISTORY_ONGOING_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/ONGOING/REQUEST';
export const MY_PROFILE_ACTIVITY_HISTORY_ONGOING_RESPONSE = 'MY-PROFILE/ACTIVITY_HISTORY/ONGOING/RECEIVED';

export const MY_PROFILE_ACTIVITY_HISTORY_COMPLETED_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/COMPLETED/REQUEST';
export const MY_PROFILE_ACTIVITY_HISTORY_COMPLETED_RESPONSE = 'MY-PROFILE/ACTIVITY_HISTORY/COMPLETED/RECEIVED';

export const MY_PROFILE_ACTIVITY_HISTORY_DETAIL_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/DETAIL/REQUEST';
export const MY_PROFILE_ACTIVITY_HISTORY_DETAIL_RESPONSE = 'MY-PROFILE/ACTIVITY_HISTORY/DETAIL/RECEIVED';

export const MY_PROFILE_ACTIVITY_HISTORY_SEARCH_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/SEARCH/REQUEST';
export const MY_PROFILE_ACTIVITY_HISTORY_SEARCH_RECEIVED = 'MY-PROFILE/ACTIVITY_HISTORY/SEARCH/RECEIVED';

export const MY_PROFILE_ACTIVITY_HISTORY_SEND_RATING_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/SEND_RATING/REQUEST';
export const MY_PROFILE_ACTIVITY_HISTORY_SEND_RATING_RECEIVED = 'MY-PROFILE/ACTIVITY_HISTORY/SEND_RATING/RECEIVED';

export const MY_PROFILE_ACTIVITY_HISTORY_CANCEL_ORDER_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/CANCEL_ORDER/REQUEST';
export const MY_PROFILE_ACTIVITY_HISTORY_CANCEL_ORDER_RECEIVED = 'MY-PROFILE/ACTIVITY_HISTORY/CANCEL_ORDER/RECEIVED';

export const MY_PROFILE_ACTIVITY_HISTORY_CANCEL_REASONS_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/CANCEL_REASONS/REQUEST';
export const MY_PROFILE_ACTIVITY_HISTORY_CANCEL_REASONS_RECEIVED =
  'MY-PROFILE/ACTIVITY_HISTORY/CANCEL_REASONS/RECEIVED';

export const MY_PROFILE_ACTIVITY_RELEASE_PAYMENT_REQUEST = 'MY-PROFILE/ACTIVITY_HISTORY/RELEASE_PAYMENT/REQUEST';
export const MY_PROFILE_ACTIVITY_RELEASE_PAYMENT_RECEIVED = 'MY-PROFILE/ACTIVITY_HISTORY/RELEASE_PAYMENT/RECEIVED';

export const MY_PROFILE_RMA_DATA_SET = 'MY-PROFILE/RMA-DATA/SET';

export const MY_PROFILE_RMA_CANCEL_REASONS_REQUEST = 'MY-PROFILE/RMA/CANCEL-REASONS/REQUEST';
export const MY_PROFILE_RMA_CANCEL_REASONS_RECEIVED = 'MY-PROFILE/RMA/CANCEL-REASONS/RECEIVED';

export const MY_PROFILE_RMA_REQUEST = 'MY-PROFILE/RMA/REQUEST';
export const MY_PROFILE_RMA_RECEIVED = 'MY-PROFILE/RMA/RECEIVED';

export const MY_PROFILE_RMA_LIST_REQUEST = 'MY-PROFILE/RMA-LIST/REQUEST';
export const MY_PROFILE_RMA_LIST_RECEIVED = 'MY-PROFILE/RMA-LIST/RECEIVED';

export const MY_PROFILE_FETCH_RMA_DETAIL_REQUEST = 'MY-PROFILE/FETCH-RMA-DETAIL/REQUEST';
export const MY_PROFILE_FETCH_RMA_DETAIL_RECEIVED = 'MY-PROFILE/FETCH-RMA-DETAIL/RECEIVED';

export const MY_PROFILE_ORDER_MERCHANT_DETAIL_REQUEST = 'MY-PROFILE/ORDER_MERCHANT_DETAIL/REQUESTED';
export const MY_PROFILE_ORDER_MERCHANT_DETAIL_RECEIVED = 'MY-PROFILE/ORDER_MERCHANT_DETAIL/RECEIVED';
