import { IAmount } from './common.model';

export interface IRequestFilter {
  status?: string;
}

export interface IRequestDetailResponse {
  paymentRequestId: string;
  amount: IAmount;
  status?: IRequestFilter;
  created: string;
  expiresAt: string;
  paidAt: string;
  description?: string;
  incoming: boolean;
  recipientPhone?: string;
  recipientName?: string;
  recipientPhoto?: string;
  referenceData?: string;
}

export interface IRequestPayDeclineResponse {
  transactionId: string;
  date: string;
  paymentMode: string;
}

export enum RequestStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export interface IRequestState {
  outgoingPaymentRequests?: IRequestDetailResponse[];
  outgoingPaymentRequestDetail?: IRequestDetailResponse;
}

export interface IPaymentRequestStatementFilter {
  status?: RequestStatus;
  format: StatementFormat;
}

export enum StatementFormat {
  PDF = 'PDF',
  CSV = 'CSV',
}
