import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IMatrixMessage,
  IMessageEventLocation,
  IMessageEventLocationSet,
  MessageEventLocationButtonType,
  MessageEventType,
} from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-location',
  styleUrls: ['./chat-widget-location.component.scss'],
  template: `
    <div class="ui-chat-message" [ngClass]="{ 'incoming': true, 'hey-ned': heyNedTheme }">
      <p class="small margin-bottom">{{ message?.content?.body }}</p>
      <div class="address" *ngIf="message?.content?.locationId">
        <p class="small bold">{{ message?.content?.addressString }}</p>
        <p class="small light" zen-translate>chat.location.saved</p>
      </div>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ incoming: true }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>

    <div class="ui-chat-message row no-background" [ngClass]="{ outgoing: true }">
      <ui-button
        *ngIf="message?.content?.locationId"
        [content]="message?.content?.buttonConfirmLabel"
        [selectButton]="pairedMessageButtonType === ButtonType.COFIRM"
        (onClick)="selectPredefinedLocation()"
      ></ui-button>
      <ui-divider [compact]="true" [vertical]="true"></ui-divider>
      <ui-button
        [content]="message?.content?.buttonSetLabel | translate"
        [selectButton]="pairedMessageButtonType === ButtonType.SET"
        (onClick)="selectCustomLocation()"
      ></ui-button>
    </div>
    <ng-container *ngIf="message?.origin_server_ts as date">
      <div class="ui-chat-message-date" [ngClass]="{ outgoing: true }">
        <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
      </div>
    </ng-container>
  `,
})
export class ChatWidgetLocationComponent {
  @Input() message: IMatrixMessage<IMessageEventLocation>;
  @Input() heyNedTheme: boolean;
  @Output() locationSet = new EventEmitter<IMessageEventLocationSet>();
  @Output() setNewLocation = new EventEmitter<string>();

  MessageEventType = MessageEventType;
  ButtonType = MessageEventLocationButtonType;

  get pairedMessageButtonType(): MessageEventLocationButtonType | undefined {
    return this.message.content.pairedMessage?.buttonType;
  }

  get hasPairedMessage(): boolean {
    return !!this.message.content.pairedMessage;
  }

  selectPredefinedLocation(): void {
    if (this.hasPairedMessage) {
      return;
    }

    this.locationSet.emit({
      msgtype: MessageEventType.LOCATION_SET,
      body: '',
      actionId: this.message.content.actionId,
      addressString: this.message.content.addressString,
      buttonType: this.ButtonType.COFIRM,
      locationId: this.message.content.locationId,
      locationName: this.message.content.addressString,
    });
  }

  selectCustomLocation(): void {
    if (this.hasPairedMessage) {
      return;
    }
    this.setNewLocation.emit(this.message.content.actionId);
  }
}
