import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../configs/app.config';
import {
  ICoupon,
  ILoyaltyBalance,
  ILoyaltyFxConvertRequest,
  ILoyaltyFxConvertResponse,
  ILoyaltyFxLinkResponse,
  ILoyaltyFxPartner,
  ILoyaltyFxQuoteRequest,
  ILoyaltyFxQuoteResponse,
  ILoyaltyPoints,
  ILoyaltyWalletTransaction,
} from '../../models/reward.model';
import { ApiService } from '../api/api.service';
import * as endpoints from './my-rewards.endpoints';

@Injectable()
export class MyRewardsService {
  constructor(private api: ApiService, private appConfig: AppConfig) {}

  public fetchTransactions(walletId: string): Observable<ILoyaltyWalletTransaction[]> {
    return this.api.get<ILoyaltyWalletTransaction[]>(
      endpoints.transactions(walletId, this.appConfig.backendConfig.apiUrl),
    );
  }

  public fetchPoints(): Observable<ILoyaltyPoints> {
    return this.api.get<ILoyaltyPoints>(endpoints.points(this.appConfig.backendConfig.apiUrl));
  }

  public fetchBalance(walletId: string): Observable<ILoyaltyBalance> {
    return this.api.get<ILoyaltyBalance>(endpoints.balance(walletId, this.appConfig.backendConfig.apiUrl));
  }

  public fetchCoupons(): Observable<ICoupon[]> {
    return this.api.get<ICoupon[]>(endpoints.coupons(this.appConfig.backendConfig.apiUrl));
  }

  public fetchCouponDetail(couponId: string): Observable<ICoupon> {
    return this.api.get<ICoupon>(endpoints.couponsDetail(couponId, this.appConfig.backendConfig.apiUrl));
  }

  public fetchPartners(): Observable<ILoyaltyFxPartner[]> {
    return this.api.get<ILoyaltyFxPartner[]>(endpoints.partners(this.appConfig.backendConfig.apiUrl));
  }

  public linkPartner(partnerId: string): Observable<ILoyaltyFxLinkResponse> {
    return this.api.post(endpoints.linkPartner(partnerId, this.appConfig.backendConfig.apiUrl), {});
  }

  public unlinkPartner(partnerId: string): Observable<any> {
    return this.api.remove(endpoints.unlinkPartner(partnerId, this.appConfig.backendConfig.apiUrl), {});
  }

  public convertPoints(partnerId: string, payload: ILoyaltyFxConvertRequest): Observable<ILoyaltyFxConvertResponse> {
    return this.api.post<ILoyaltyFxConvertRequest>(
      endpoints.convertPoints(partnerId, this.appConfig.backendConfig.apiUrl),
      payload,
    );
  }

  public quotePoints(partnerId: string, payload: ILoyaltyFxQuoteRequest): Observable<ILoyaltyFxQuoteResponse> {
    return this.api.post<ILoyaltyFxQuoteRequest>(
      endpoints.quotePoints(partnerId, this.appConfig.backendConfig.apiUrl),
      payload,
    );
  }
}
