import { Injectable, OnDestroy } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { interval, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerUpdaterService implements OnDestroy {
  newVersion$ = new Observable<boolean>();
  intervalSubscription = new Subscription();
  checkIntervalInMin = 5;
  constructor(private swUpdate: SwUpdate) {
    this.intervalSubscription = interval(this.checkIntervalInMin * 60 * 1000).subscribe(() => this.checkForUpdate());
    this.newVersion$ = this.swUpdate?.versionUpdates?.pipe(
      filter((data: VersionEvent) => data.type === 'VERSION_READY'),
      map(data => {
        console.log('New update available >> ', data);
        return !!data;
      }),
    );
  }

  checkForUpdate(): void {
    if (this.swUpdate && this.swUpdate.isEnabled) {
      this.swUpdate
        .checkForUpdate()
        .then(() => {
          console.log('Checking for updates...');
        })
        .catch(err => {
          console.warn('Error when checking for update', err);
        });
    }
  }

  updateApp(): void {
    console.log('Updating PWA version...');
    this.swUpdate
      .activateUpdate()
      .then(() => {
        this.clearCaches();
        console.log('Update reload...');
        document.location.reload();
      })
      .catch(err => {
        console.warn('Error when trying to update PWA version', err);
        this.clearCaches();
        document.location.reload();
      });
  }

  clearCaches(): void {
    caches.keys().then(function (names) {
      for (const name of names) {
        console.log('Removing old cache: ' + name);
        caches.delete(name);
      }
    });
  }

  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }
}
