import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiIconComponent } from './ui-icon.component';
import { SrcPipe } from './src.pipe';

@NgModule({
  declarations: [UiIconComponent, SrcPipe],
  exports: [UiIconComponent],
  imports: [CommonModule],
})
export class UiIconModule {}
