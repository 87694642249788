import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  CommonForm,
  CustomValidators,
  ILocationV3,
  ILocationFormV3,
  ISelect,
  LocationV3AddressType,
} from '@wakanda/wakanda-core';
import { TranslateFacade } from '@wakanda/zentity-translate-store';

interface ISetLocationFieldBase {
  name?: string;
  title?: string;
  placeholder?: string;
  status?: boolean;
  errors?: boolean;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
  width?: string;
  isDropdown?: boolean;
  dropdownValues?: ISelect[];
  showForType?: string[];
}

interface ISetLocationField extends ISetLocationFieldBase {
  inRow?: ISetLocationFieldBase[];
}

@Component({
  selector: 'ui-set-location',
  template: `
    <ng-container *ngIf="withContainer; else formTemplate">
      <ui-container [noTopPadding]="true" borders="none" class="full-height" [withNavigation]="withNavigation">
        <ng-template [ngTemplateOutlet]="formTemplate"></ng-template>
      </ui-container>
    </ng-container>
    <ng-template #formTemplate>
      <div class="ui-set-location-header" *ngIf="showHeader">
        <div class="row">
          <ui-icon icon="back" size="1rem" (click)="onNavigateBack.emit()"></ui-icon>
          <ui-divider [compact]="true" [vertical]="true"></ui-divider>
          <h3 class="ui3_h4">{{ title }}</h3>
        </div>
      </div>

      <ng-container *ngIf="!isFormValid()">
        <ui-item [border]="'pink'" [roundCorners]="true" [compact]="true">
          <div class="row left">
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <ui-icon icon="warning" size="1.5rem"></ui-icon>
            <ui-divider [vertical]="true" [compact]="true"></ui-divider>
            <p class="small" [style.whiteSpace]="'break-spaces'" zen-translate>customer.settings.edit.prompt</p>
          </div>
        </ui-item>
        <ui-divider [compact]="true"></ui-divider>
      </ng-container>

      <ng-container *ngIf="!!fields && fields?.length > 0">
        <ng-container *ngFor="let field of fields">
          <ng-container *ngIf="!!field?.showForType ? field?.showForType?.includes(getFormValue('addressType')) : true">
            <ng-container *ngTemplateOutlet="field?.inRow ? labelRowTemplate : labelTemplate"></ng-container>
            <ui-divider [compact]="true"></ui-divider>
          </ng-container>

          <ng-template #labelRowTemplate>
            <div class="row between top">
              <ng-container *ngFor="let rowField of field?.inRow; let i = index">
                <ng-container
                  *ngIf="!!rowField?.showForType ? rowField?.showForType?.includes(getFormValue('addressType')) : true"
                >
                  <ui-labeled-input
                    [style.width]="rowField?.width || ''"
                    [placeholder]="rowField?.placeholder | translate"
                    [title]="rowField?.title | translate"
                    [name]="rowField?.name"
                    [value]="getFormValue(rowField?.name)"
                    [readonly]="rowField?.readOnly"
                    [required]="rowField?.required"
                    [disabled]="rowField?.disabled"
                    [status]="rowField?.status ? getFormValueStatus(rowField?.name) : null"
                    [errors]="rowField?.errors ? getErrors(rowField?.name) : null"
                    (onChange)="onChange($event.name, $event.value)"
                  ></ui-labeled-input>
                  <ui-divider *ngIf="i + 1 < field?.inRow?.length" [compact]="true" [vertical]="true"></ui-divider>
                </ng-container>
              </ng-container>
            </div>
          </ng-template>

          <ng-template #labelTemplate>
            <ui-labeled-input
              *ngIf="!field?.isDropdown"
              [style.width]="field?.width || ''"
              [placeholder]="field?.placeholder || field?.title | translate"
              [title]="field?.title | translate"
              [name]="field?.name"
              [value]="getFormValue(field?.name)"
              [readonly]="field?.readOnly"
              [required]="field?.required"
              [disabled]="field?.disabled"
              [status]="field?.status ? getFormValueStatus(field?.name) : null"
              [errors]="field?.errors ? getErrors(field?.name) : null"
              (onChange)="onChange($event.name, $event.value)"
            ></ui-labeled-input>

            <ui-labeled-input
              *ngIf="field?.isDropdown"
              [style.width]="field?.width || ''"
              [placeholder]="field?.placeholder || field?.title | translate"
              [title]="field?.title | translate"
              [name]="field?.name"
              [value]="getFormValue(field?.name)"
              [readonly]="field?.readOnly"
              [required]="field?.required"
              [disabled]="field?.disabled"
              [dropdown]="true"
              [options]="field?.dropdownValues"
              [bindValue]="'id'"
              [bindLabel]="'text'"
              [status]="field?.status ? getFormValueStatus(field?.name) : null"
              [errors]="field?.errors ? getErrors(field?.name) : null"
              (onChange)="onChange($event.name, $event.id)"
            ></ui-labeled-input>
          </ng-template>
        </ng-container>
      </ng-container>

      <ui-divider></ui-divider>

      <ui-button
        [callToActionButton]="true"
        [selectButton]="true"
        aligned="bottom"
        [content]="'customer.settings.locations.add.cta_button' | translate"
        [disabled]="!isFormValid()"
        (onClick)="handleSubmit()"
      ></ui-button>
    </ng-template>
  `,
})
export class SetLocationComponent extends CommonForm implements OnInit {
  translate: TranslateFacade;
  fields: ISetLocationField[];
  @Input() searchMapForm: ILocationV3;
  @Input() withNavigation: boolean;
  @Input() editMode: boolean;
  @Input() title: string;
  @Input() withContainer = true;
  @Input() showHeader = true;
  @Input() validateOnChange: boolean;
  @Output() onNavigateBack = new EventEmitter();
  @Output() onSubmit = new EventEmitter<ILocationFormV3>();

  constructor(injector: Injector) {
    super(injector);
    this.translate = injector.get(TranslateFacade);
  }

  getTranslatedKey = (key: string): string => {
    let result: string;
    this.translate.getTranslateByKey$(key).subscribe(translatedKey => (result = translatedKey));
    return result;
  };

  private createInitForm = (): void => {
    if (!this.searchMapForm || !this.searchMapForm.address || !this.searchMapForm.location) return;

    const {
      postalCode,
      addressString,
      streetName,
      streetNumber,
      city,
      complex,
      stateOrProvince,
      suburb,
      country,
      houseNumber,
      building,
      estate,
      unitNumber,
      addressType,
    } = this.searchMapForm.address;
    const { name, instructions } = this.searchMapForm;

    const { latitude, longitude } = this.searchMapForm.location;

    this.createForm([
      {
        initialValue: {
          latitude,
          longitude,
        },
        name: 'location',
        validators: [Validators.required],
      },
      {
        name: 'addressType',
        initialValue: addressType,
        validators: [Validators.required],
      },
      {
        initialValue: addressString || this.getTranslatedKey('settings.location.no_address_name'),
        name: 'addressString',
        validators: [Validators.required],
      },
      {
        initialValue: name,
        name: 'name',
        validators: [CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)],
      },
      {
        initialValue: streetName,
        name: 'streetName',
        validators: [CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)],
      },
      {
        initialValue: streetNumber,
        name: 'streetNumber',
        validators: [CustomValidators.noWhitespaceValidator(1)],
      },
      {
        initialValue: houseNumber,
        name: 'houseNumber',
      },
      {
        initialValue: building,
        name: 'building',
      },
      {
        initialValue: estate,
        name: 'estate',
      },
      {
        initialValue: unitNumber,
        name: 'unitNumber',
      },
      {
        initialValue: complex,
        name: 'complex',
        validators: [Validators.maxLength(64)],
      },
      {
        initialValue: suburb,
        name: 'suburb',
        validators: [CustomValidators.noWhitespaceValidator(1), Validators.maxLength(64)],
      },
      {
        initialValue: city,
        name: 'city',
        validators: [CustomValidators.noWhitespaceValidator(1)],
      },
      {
        initialValue: country,
        name: 'country',
        validators: [CustomValidators.noWhitespaceValidator(1)],
      },
      {
        initialValue: instructions,
        name: 'instructions',
      },
      {
        initialValue: postalCode,
        name: 'postalCode',
        validators: [
          Validators.required,
          CustomValidators.patternValidator(
            CustomValidators.valalidators.postalCode,
            CustomValidators.errors.postalCode,
          ),
        ],
      },
      {
        initialValue: stateOrProvince,
        name: 'stateOrProvince',
        validators: [CustomValidators.noWhitespaceValidator(1)],
      },
    ]);
    if (this.validateOnChange) {
      this.checkForValidations();
    }
  };

  handleSubmit = (): void => {
    this.onSubmit.emit(this.getForm<ILocationFormV3>());
  };

  ngOnInit(): void {
    this.fields = [
      {
        title: 'customer.settings.locations.edit.address',
        name: 'addressString',
        required: true,
        errors: true,
        disabled: this.editMode,
        readOnly: true,
      },
      {
        title: 'customer.settings.locations.edit.address_type',
        name: 'addressType',
        isDropdown: true,
        errors: true,
        required: true,
        disabled: this.editMode,
        readOnly: this.editMode || false,
        dropdownValues: [
          {
            id: LocationV3AddressType.HOME,
            text: this.getTranslatedKey('customer.settings.locations.edit.home_type'),
          },
          {
            id: LocationV3AddressType.OFFICE,
            text: this.getTranslatedKey('customer.settings.locations.edit.office_type'),
          },
          {
            id: LocationV3AddressType.BUSINESSPARK,
            text: this.getTranslatedKey('customer.settings.locations.edit.business_park_type'),
          },
          {
            id: LocationV3AddressType.ESTATE,
            text: this.getTranslatedKey('customer.settings.locations.edit.estate_type'),
          },
        ],
      },
      {
        placeholder: 'customer.settings.locations.edit.nickname_hint',
        title: 'customer.settings.locations.edit.nickname',
        name: 'name',
        status: true,
        errors: true,
        required: true,
        showForType: [
          LocationV3AddressType.HOME,
          LocationV3AddressType.OFFICE,
          LocationV3AddressType.BUSINESSPARK,
          LocationV3AddressType.ESTATE,
        ],
      },
      {
        inRow: [
          {
            title: 'customer.settings.locations.edit.streetNumber',
            name: 'streetNumber',
            status: true,
            errors: true,
            required: true,
            width: '40%',
            showForType: [
              LocationV3AddressType.HOME,
              LocationV3AddressType.OFFICE,
              LocationV3AddressType.BUSINESSPARK,
              LocationV3AddressType.ESTATE,
            ],
          },
          {
            title: 'customer.settings.locations.edit.streetName',
            name: 'streetName',
            status: true,
            errors: true,
            required: true,
            width: '55%',
            disabled: this.editMode,
            readOnly: this.editMode || false,
            showForType: [
              LocationV3AddressType.HOME,
              LocationV3AddressType.OFFICE,
              LocationV3AddressType.BUSINESSPARK,
              LocationV3AddressType.ESTATE,
            ],
          },
        ],
      },
      {
        title: 'customer.settings.locations.edit.house_number',
        name: 'houseNumber',
        status: true,
        errors: true,
        required: false,
        disabled: this.editMode,
        readOnly: this.editMode || false,
        showForType: [LocationV3AddressType.HOME],
      },
      {
        title: 'customer.settings.locations.edit.building',
        name: 'building',
        status: true,
        errors: true,
        required: true,
        disabled: this.editMode,
        readOnly: this.editMode || false,
        showForType: [LocationV3AddressType.OFFICE],
      },
      {
        inRow: [
          {
            title: 'customer.settings.locations.edit.unit_number',
            name: 'unitNumber',
            status: true,
            errors: true,
            required: true,
            width: '40%',
            disabled: this.editMode,
            readOnly: this.editMode || false,
            showForType: [LocationV3AddressType.BUSINESSPARK],
          },
          {
            title: 'customer.settings.locations.edit.business_park',
            placeholder: 'customer.settings.locations.edit.complex_hint',
            name: 'complex',
            status: true,
            errors: true,
            required: true,
            width: '55%',
            showForType: [LocationV3AddressType.BUSINESSPARK],
          },
        ],
      },
      {
        inRow: [
          {
            title: 'customer.settings.locations.edit.house_number',
            name: 'houseNumber',
            status: true,
            errors: true,
            required: true,
            width: '40%',
            disabled: this.editMode,
            readOnly: this.editMode || false,
            showForType: [LocationV3AddressType.ESTATE],
          },
          {
            title: 'customer.settings.locations.edit.estate',
            name: 'estate',
            status: true,
            errors: true,
            required: true,
            width: '55%',
            showForType: [LocationV3AddressType.ESTATE],
          },
        ],
      },
      {
        title: 'customer.settings.locations.edit.suburb',
        name: 'suburb',
        status: true,
        errors: true,
        required: true,
        disabled: this.editMode,
        readOnly: this.editMode || false,
        showForType: [
          LocationV3AddressType.HOME,
          LocationV3AddressType.OFFICE,
          LocationV3AddressType.BUSINESSPARK,
          LocationV3AddressType.ESTATE,
        ],
      },
      {
        inRow: [
          {
            title: 'customer.settings.locations.edit.city',
            name: 'city',
            status: true,
            errors: true,
            required: true,
            width: '55%',
            disabled: this.editMode,
            readOnly: this.editMode || false,
            showForType: [
              LocationV3AddressType.HOME,
              LocationV3AddressType.OFFICE,
              LocationV3AddressType.BUSINESSPARK,
              LocationV3AddressType.ESTATE,
            ],
          },
          {
            title: 'customer.settings.locations.edit.postalCode',
            name: 'postalCode',
            status: true,
            errors: true,
            required: true,
            width: '40%',
            disabled: this.editMode,
            readOnly: this.editMode || false,
            showForType: [
              LocationV3AddressType.HOME,
              LocationV3AddressType.OFFICE,
              LocationV3AddressType.BUSINESSPARK,
              LocationV3AddressType.ESTATE,
            ],
          },
        ],
      },
      {
        title: 'customer.settings.locations.edit.stateOrProvince',
        name: 'stateOrProvince',
        status: true,
        errors: true,
        required: true,
        disabled: this.editMode,
        readOnly: this.editMode || false,
        showForType: [
          LocationV3AddressType.HOME,
          LocationV3AddressType.OFFICE,
          LocationV3AddressType.BUSINESSPARK,
          LocationV3AddressType.ESTATE,
        ],
      },
      {
        title: 'customer.settings.locations.edit.country',
        name: 'country',
        status: true,
        errors: true,
        required: true,
        disabled: this.editMode,
        readOnly: true,
        showForType: [
          LocationV3AddressType.HOME,
          LocationV3AddressType.OFFICE,
          LocationV3AddressType.BUSINESSPARK,
          LocationV3AddressType.ESTATE,
        ],
      },
      {
        title: 'customer.settings.locations.edit.instructions',
        name: 'instructions',
        status: true,
        errors: true,
        required: true,
        disabled: this.editMode,
        readOnly: this.editMode || false,
        showForType: [
          LocationV3AddressType.HOME,
          LocationV3AddressType.OFFICE,
          LocationV3AddressType.BUSINESSPARK,
          LocationV3AddressType.ESTATE,
        ],
      },
    ];

    this.createInitForm();
    this.onValidateAddressType();
  }

  onChange(name: string, value: string): void {
    this.setFormValue(name, value);
    if (name === 'addressType') {
      this.onValidateAddressType();
    }
  }

  onValidateAddressType(): void {
    const addressType = this.getFormValue('addressType');
    const streetName = this.form.get('streetName');
    const houseNumber = this.form.get('houseNumber');
    const building = this.form.get('building');
    const unitNumber = this.form.get('unitNumber');
    const complex = this.form.get('complex');
    const estate = this.form.get('estate');
    switch (addressType) {
      case LocationV3AddressType.HOME:
        streetName.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        houseNumber.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        building.setValidators([]);
        unitNumber.setValidators([]);
        complex.setValidators([]);
        estate.setValidators([]);
        break;
      case LocationV3AddressType.OFFICE:
        streetName.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        houseNumber.setValidators([]);
        building.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        unitNumber.setValidators([]);
        complex.setValidators([]);
        estate.setValidators([]);
        break;
      case LocationV3AddressType.BUSINESSPARK:
        streetName.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        houseNumber.setValidators([]);
        building.setValidators([]);
        unitNumber.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        complex.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        estate.setValidators([]);
        break;
      case LocationV3AddressType.ESTATE:
        streetName.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        houseNumber.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        building.setValidators([]);
        unitNumber.setValidators([]);
        complex.setValidators([]);
        estate.setValidators([CustomValidators.noWhitespaceValidator(1), Validators.maxLength(50)]);
        break;
      default:
        break;
    }

    streetName.updateValueAndValidity();
    houseNumber.updateValueAndValidity();
    building.updateValueAndValidity();
    unitNumber.updateValueAndValidity();
    complex.updateValueAndValidity();
    estate.updateValueAndValidity();

    if (this.validateOnChange) {
      this.checkForValidations();
    }
  }

  checkForValidations(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
  }
}
