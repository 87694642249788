import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  IMatrixMessage,
  IMessageEventButtonPressed,
  IMessageEventButton,
  MessageEventType,
  IMessageEventButtonBody,
} from '@wakanda/agent-chat-model';

@Component({
  selector: 'ui-chat-widget-button',
  styles: [],
  template: `
    <ng-container *ngIf="!!message?.content?.body">
      <div class="ui-chat-message" [ngClass]="{ 'incoming': !message.sender_self, 'hey-ned': heyNedTheme }">
        <p class="small">{{ message?.content?.body }}</p>
      </div>
      <ng-container *ngIf="message?.origin_server_ts as date">
        <div class="ui-chat-message-date" [ngClass]="{ incoming: true }">
          <p *ngIf="!!date" class="small light date">{{ date | chatDate }}</p>
        </div>
      </ng-container>
    </ng-container>

    <div class="column right">
      <ng-container *ngFor="let button of message?.content?.buttons; let last = last">
        <ui-button
          [content]="button?.label"
          [selectButton]="pairedButtonId === button?.id"
          (onClick)="buttonClicked(button)"
        >
        </ui-button>
        <ng-container *ngIf="!last">
          <ui-divider [compact]="true"></ui-divider>
        </ng-container>
      </ng-container>
    </div>

    <ui-divider [compact]="true"></ui-divider>
  `,
})
export class ChatWidgetButtonComponent {
  @Input() message: IMatrixMessage<IMessageEventButton>;
  @Input() heyNedTheme: boolean;
  @Output() buttonSelect = new EventEmitter<IMessageEventButtonPressed>();

  MessageEventType = MessageEventType;

  get pairedButtonId(): string | undefined {
    return this.message.content.pairedMessage?.buttonId;
  }

  buttonClicked(selectedButton: IMessageEventButtonBody): void {
    if (this.message.content.pairedMessage) {
      return;
    }

    this.buttonSelect.emit({
      msgtype: MessageEventType.BUTTON_PRESSED,
      body: '',
      actionId: this.message.content.actionId,
      buttonId: selectedButton.id,
    });
  }
}
