import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-divider',
  templateUrl: './divider.component.html',
})
export class DividerComponent {
  @Input() solid: boolean;

  @Input() dotted: boolean;

  @Input() vertical: boolean;

  @Input() compact: boolean;

  @Input() large: boolean;

  @Input() padded: boolean;

  @Input() fullWidth: boolean;
}
