import { createAction, props } from '@ngrx/store';
import {
  ITask,
  ITransactionSigningAuthenticators,
  ITransactionSigningAuthorizationHeaders,
  ITransactionSigningAuthorizeRequest,
  ITransactionSigningNextStepResponse,
} from '../models/store.models';

export const transactionSigningStartRequestAction = createAction(
  '[Application/Error] Transaction signing start request action',
  props<{
    transactionAuthId: string;
    taskMetadata?: ITask;
    onSucceed?: (data?: ITransactionSigningAuthorizationHeaders) => void;
  }>(),
);

export const transactionSigningStartResponseAction = createAction(
  '[Application/Error] Transaction signing start response action',
  props<{ data: ITransactionSigningNextStepResponse; taskMetadata?: ITask }>(),
);

export const transactionSigningAuthorizeRequestAction = createAction(
  '[Application/Error] Transaction signing authorize request action',
  props<{ payload: ITransactionSigningAuthorizeRequest; taskMetadata?: ITask }>(),
);

export const transactionSigningAuthorizeResponseAction = createAction(
  '[Application/Error] Transaction signing authorize response action',
  props<{ data: ITransactionSigningNextStepResponse; taskMetadata?: ITask }>(),
);

export const transactionSigningCancelRequestAction = createAction(
  '[Application/Error] Transaction signing cancel request action',
  props<{ transactionAuthId: string; onSucceed?: () => void; taskMetadata?: ITask }>(),
);

export const transactionSigningCancelResponseAction = createAction(
  '[Application/Error] Transaction signing cancel response action',
  props<{
    taskMetadata?: ITask;
  }>(),
);

export const transactionSaveAuthenticatorsAction = createAction(
  '[Application/Error] Transaction signing save authenticators response action',
  props<{
    data: ITransactionSigningAuthenticators;
  }>(),
);
