import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as TransactionsActions from './transactions.actions';
import * as TransactionsSelectors from './transactions.selectors';
import {
  ITransactionSigningAuthenticators,
  ITransactionSigningAuthorizationHeaders,
  ITransactionSigningAuthorizeRequest,
} from '../models/store.models';

@Injectable({ providedIn: 'root' })
export class TransactionsFacade {
  getTransactionSigningNextStep$ = this.store.pipe(select(TransactionsSelectors.getTransactionSigningNextStep));
  getTransactionSigningAuthenticators$ = this.store.pipe(
    select(TransactionsSelectors.getTransactionSigningAuthenticators),
  );
  getLastTransactionId$ = this.store.pipe(select(TransactionsSelectors.getLastTransactionId));

  constructor(private readonly store: Store) {}

  transactionSigningStart = (
    transactionAuthId: string,
    onSucceed?: (data?: ITransactionSigningAuthorizationHeaders) => void,
  ): void => {
    this.store.dispatch(TransactionsActions.transactionSigningStartRequestAction({ transactionAuthId, onSucceed }));
  };

  transactionSigningAuthorize = (payload: ITransactionSigningAuthorizeRequest): void => {
    this.store.dispatch(
      TransactionsActions.transactionSigningAuthorizeRequestAction({
        payload,
      }),
    );
  };

  transactionSigningCancel = (transactionAuthId: string, onSucceed: () => void): void => {
    this.store.dispatch(TransactionsActions.transactionSigningCancelRequestAction({ transactionAuthId, onSucceed }));
  };

  transactionSigningSaveAuthenticators = (data: ITransactionSigningAuthenticators): void => {
    this.store.dispatch(TransactionsActions.transactionSaveAuthenticatorsAction({ data }));
  };
}
