import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ApplicationFacade,
  ChatSendMessageId,
  failed,
  IChatSendMessage,
  started,
  succeed,
  WebSocketService,
} from '@wakanda/wakanda-core';
import { of } from 'rxjs';
import { catchError, delay, finalize, map, switchMap } from 'rxjs/operators';
import { ChatService } from '../services/chat.service';
import * as actions from './chat.actions';
import * as EVENT from './chat.events';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class ChatEffects {
  fetchRoomIdForSupport$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GetChatRoomIdForSupportRequestAction>(EVENT.CHAT_GET_ROOM_ID_SUPPORT_REQUEST),
      switchMap(({ taskId, onSucceed }) =>
        this.service.getRoomIdForSupport().pipe(
          map(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            return succeed(new actions.GetChatRoomIdForSupportResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchRoomIdForConcierge$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GetChatRoomIdForConciergeRequestAction>(EVENT.CHAT_GET_ROOM_ID_CONCIERGE_REQUEST),
      switchMap(({ taskId, onSucceed }) =>
        this.service.getRoomIdForConcierge().pipe(
          map(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            return succeed(new actions.GetChatRoomIdForConciergeResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  createRoomIdForConcierge$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.CreateChatRoomIdForConciergeRequestAction>(EVENT.CHAT_CREATE_ROOM_ID_CONCIERGE_REQUEST),
      switchMap(({ taskId, onSucceed }) =>
        this.service.createRoomIdForConcierge().pipe(
          map(response => {
            if (typeof onSucceed === 'function') onSucceed(response);
            return succeed(new actions.CreateChatRoomIdForConciergeResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchRoomIdForJob$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GetChatRoomIdForJobRequestAction>(EVENT.CHAT_GET_ROOM_ID_JOB_REQUEST),
      switchMap(({ taskId, jobId }) =>
        this.service.getRoomIdForJob(jobId).pipe(
          map(response => succeed(new actions.GetChatRoomIdForJobResponseAction(taskId, response))),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchRoomIdForJobAndMerchant$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GetChatRoomIdForJobAndMerchantRequestAction>(EVENT.CHAT_GET_ROOM_ID_JOB_MERCHANT_REQUEST),
      switchMap(({ taskId, jobId, merchantId, onSucceed }) =>
        this.service.getRoomIdForJobAndMerchant(jobId, merchantId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.GetChatRoomIdForJobAndMerchantResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.GetChatRoomRequestAction>(EVENT.CHAT_GET_ROOM_REQUEST),
      switchMap(({ taskId, roomId }) =>
        this.service.getRoom(roomId).pipe(
          map(response => succeed(new actions.GetChatRoomResponseAction(taskId, response))),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  sendEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatSendEventRequestAction>(EVENT.CHAT_SEND_EVENT_REQUEST),
      switchMap(({ taskId, request }) =>
        this.service.sendEvent(request).pipe(
          map(response => succeed(new actions.ChatSendEventResponseAction(taskId, response))),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.FetchMessagesByRoomIdRequest>(EVENT.CHAT_GET_MESSAGES_REQUEST),
      switchMap(({ taskId, request }) =>
        this.service.getMessages(request).pipe(
          map(response => succeed(new actions.FetchMessagesByRoomIdReceived(taskId, response))),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  uploadImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatUploadImageRequestAction>(EVENT.CHAT_UPLOAD_IMAGE_REQUEST),
      switchMap(({ taskId, image, onSucceed }) =>
        this.service.uploadImage(image).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ChatUploadImageResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchInfPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatGetInfPaymentStatusRequestAction>(EVENT.CHAT_INF_PAYMENT_STATUS_REQUEST),
      switchMap(({ taskId, jobId, quoteId, onSucceed }) =>
        this.service.fetchInfPayment(jobId, quoteId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ChatGetInfPaymentStatusResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error, true)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  sendWebsocketMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SendWebSocketMessageRequest>(EVENT.CHAT_SEND_WEBSOCKET_MESSAGE_REQUEST),
      switchMap(({ taskId, message }) =>
        of(this.webSocketService.sendMessage(message)).pipe(
          delay(1500),
          switchMap(() => {
            const roomId = message?.message?.roomId;
            return [
              started(
                new actions.FetchMessagesByRoomIdRequest(`fetch-messages-${uuidv4()}`, { roomId }),
                'Fetching messages',
                true,
              ),
              succeed(new actions.SendWebSocketMessageSucceeded(taskId)),
            ];
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error, true)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  sendWebsocketAttachment$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType<actions.SendWebSocketAttachmentRequest>(EVENT.CHAT_SEND_WEBSOCKET_ATTACHMENT_REQUEST),
        switchMap(({ taskId, roomId, payload }) =>
          this.service.uploadImage(payload.data).pipe(
            map(response => {
              const message: IChatSendMessage = {
                id: ChatSendMessageId.SENDMESSAGE,
                message: {
                  content: {
                    msgtype: payload.messageType,
                    body: payload.name,
                    url: response.url,
                    uploadedFileId: response.id,
                  },
                  roomId,
                },
              };

              this.webSocketService.sendMessage(message);

              return succeed(new actions.SendWebSocketAttachmentSucceeded(taskId, roomId));
            }),
            catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
            finalize(() => this.appFacade.finalize(taskId)),
          ),
        ),
      )
      .pipe(
        ofType<actions.SendWebSocketAttachmentSucceeded>(EVENT.CHAT_SEND_WEBSOCKET_ATTACHMENT_SUCCEEDED),
        delay(1500),
        map(({ roomId }) =>
          started(
            new actions.FetchMessagesByRoomIdRequest(`fetch-messages-${uuidv4()}`, { roomId }),
            'Fetching messages',
            true,
          ),
        ),
      ),
  );

  createHeyNedRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatCreateHeyNedRoomRequestAction>(EVENT.CHAT_CREATE_HEYNED_ROOM_REQUEST),
      switchMap(({ taskId, request, onSucceed }) =>
        this.service.createHeyNedRoom(request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ChatCreateHeyNedRoomResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  createHeyNedHealthRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatCreateHeyNedHealthRoomRequestAction>(EVENT.CHAT_CREATE_HEYNED_HEALTH_ROOM_REQUEST),
      switchMap(({ taskId, request, onSucceed }) =>
        this.service.createHeyNedHealthRoom(request).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ChatCreateHeyNedHealthRoomResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  heyNedOpenedTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.HeyNedOpenedTaskRequestAction>(EVENT.CHAT_HEYNED_OPENED_TASK_REQUEST),
      switchMap(({ taskId, onSucceed }) =>
        this.service.heyNedOpenedTask().pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.HeyNedOpenedTaskResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  sendPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatSendPaymentRequestAction>(EVENT.CHAT_SEND_PAYMENT_REQUEST),
      switchMap(({ taskId, roomId, paymentId, usedPoints }) =>
        this.service.sendPayment(roomId, paymentId, usedPoints).pipe(
          map(() => succeed(new actions.ChatSendPaymentSucceededAction(taskId, roomId))),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchPaycheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatFetchPayCheckRequestAction>(EVENT.CHAT_FETCH_PAYCHECK_REQUEST),
      switchMap(({ taskId, roomId, paymentId, onSucceed }) =>
        this.service.fetchPayCheck(roomId, paymentId).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ChatFetchPayCheckSucceededAction(taskId));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  checkHeyNedSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatCheckHeyNedSubscriptionRequestAction>(EVENT.CHECK_HEYNED_SUBSCRIPTION_REQUEST),
      switchMap(({ taskId, onSucceed }) =>
        this.service.heyNedSubscriptionCheck().pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ChatCheckHeyNedSubscriptionResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  closeChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatCloseChatRequestAction>(EVENT.CHAT_CLOSE_CHAT_REQUEST),
      switchMap(({ taskId, chatId, onSucceed }) =>
        this.service.closeChatRoom(chatId).pipe(
          map(() => {
            onSucceed();
            return succeed(new actions.ChatCloseChatResponseAction(taskId));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  searchChatHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatHistorySearchRequestAction>(EVENT.CHAT_HISTORY_SEARCH_REQUEST),
      switchMap(({ taskId, query, onSucceed }) =>
        this.service.searchChatHistory(query).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ChatHistorySearchResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchChatHistoryOngoing$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatHistoryOngoingRequestAction>(EVENT.CHAT_HISTORY_ONGOING_REQUEST),
      switchMap(({ taskId, page, size, onSucceed }) =>
        this.service.fetchChatHistoryOngoing(page, size).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ChatHistoryOngoingResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  fetchChatHistoryCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatHistoryCompletedRequestAction>(EVENT.CHAT_HISTORY_COMPLETED_REQUEST),
      switchMap(({ taskId, page, size, onSucceed }) =>
        this.service.fetchChatHistoryCompleted(page, size).pipe(
          map(response => {
            onSucceed(response);
            return succeed(new actions.ChatHistoryCompletedResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  checkHeyNedEnabled$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.ChatCheckHeyNedEnabledRequestAction>(EVENT.CHECK_HEYNED_ENABLED_REQUEST),
      switchMap(({ taskId, onSucceed }) =>
        this.service.heyNedEnabledCheck().pipe(
          map(response => {
            if (onSucceed) {
              onSucceed(response);
            }
            return succeed(new actions.ChatCheckHeyNedEnabledResponseAction(taskId, response));
          }),
          catchError(error => of(failed(new actions.ChatErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );

  constructor(
    private service: ChatService,
    private webSocketService: WebSocketService,
    private actions$: Actions,
    private appFacade: ApplicationFacade,
  ) {}
}
