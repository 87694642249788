import { createFeatureSelector, createSelector } from '@ngrx/store';
import filter from 'lodash-es/filter';
import map from 'lodash-es/map';
import size from 'lodash-es/size';
import dayjs from 'dayjs';
import { NotificationItem } from '../../models/notifications.model';
import { INotificationsState, NOTIFICATIONS_FEATURE_KEY } from './notifications.reducer';

// Lookup the 'Notifications' feature state managed by NgRx
const state = createFeatureSelector<INotificationsState>(NOTIFICATIONS_FEATURE_KEY);

export const getNotifications = createSelector(state, ({ data }) => {
  if (!data) return [];
  return data
    .filter(notification => !notification.hidden)
    .sort((a, b) => (dayjs(a.created).isBefore(dayjs(b.created)) ? 1 : -1));
});

export const getRewardNotification = createSelector(state, ({ data }) => {
  if (!data) return null;
  return data.find(notification => !notification.hidden && notification.type === NotificationItem.GIFTING);
});

export const getNotificationsHasLoadedAll = createSelector(state, ({ hasLoadedAll }) => hasLoadedAll);

export const getNotificationsLastLoadedPage = createSelector(state, ({ lastLoadedPage }) => lastLoadedPage);

export const getHasNewJobs = createSelector(state, ({ belongsToJobs }) => belongsToJobs && size(belongsToJobs) > 0);

export const getNewJobs = createSelector(state, ({ belongsToJobs }) =>
  map(
    filter(belongsToJobs, n => n.type === 'NEW'),
    item => item.id,
  ),
);

export const getActiveJobs = createSelector(state, ({ belongsToJobs }) =>
  map(
    filter(belongsToJobs, n => n.type === 'ACTIVE'),
    item => item.id,
  ),
);

export const getInterestedJobs = createSelector(state, ({ belongsToJobs }) =>
  map(
    filter(belongsToJobs, n => n.type === 'INTERESTED'),
    item => item.id,
  ),
);
