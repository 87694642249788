import { Pipe, PipeTransform } from '@angular/core';
import map from 'lodash-es/map';
import size from 'lodash-es/size';
import sortBy from 'lodash-es/sortBy';
import * as dayjs from 'dayjs';
import { IGroupedWalletTransactions, IWalletTransaction } from '../models/wallet.model';
import { chain } from '../utils/chain.util';

@Pipe({
  name: 'groupedTransactionsByDate',
  pure: true,
})
export class GroupedTransactionsByDatePipe implements PipeTransform {
  transform = (data: IWalletTransaction[]): IGroupedWalletTransactions[] => this.getGroupedTransactions(data);

  private getGroupedTransactions = (data: IWalletTransaction[]): IGroupedWalletTransactions[] => {
    if (!data || !size(data)) {
      return [];
    }
    const getGroupedDataByDate = chain(data)
      .sortBy(d => d.date)
      .reverse()
      .groupBy(({ date }) => dayjs(date).format('MMMM YYYY'))
      .map((transactions: IWalletTransaction[], title: string) => ({ transactions, title }))
      .value();
    return map(getGroupedDataByDate, ({ title, transactions }) => ({
      title,
      transactions: sortBy(transactions, ({ date }) => date).reverse(),
    }));
  };
}
