import { Pipe, PipeTransform } from '@angular/core';
import { IStepProgressBarItems } from './step-progress-bar.component';

@Pipe({
  name: 'stepIcon',
})
export class StepIconPipe implements PipeTransform {
  transform(step: IStepProgressBarItems): string {
    if (step?.active) {
      return 'ui-dot_1';
    } else if (step?.checked) {
      return 'ui-dot_3';
    } else if (step?.pending) {
      return 'ui-dot_4';
    } else if (step?.cancelled) {
      return 'ui-dot_5';
    } else {
      return 'ui-dot_2';
    }
  }
}
