import { Pipe, PipeTransform } from '@angular/core';
import { ILoyaltyPoint } from '../models/wallet.model';

@Pipe({
  name: 'avoPointsMax',
})
export class AvoPointsMaxPipe implements PipeTransform {
  public transform(points: ILoyaltyPoint, price: number): number {
    if (!points) return 0;
    const rate = points?.pointsRate ?? 1;
    const pointsAVOValue = points?.points * rate;
    return Math.min(pointsAVOValue, price);
  }
}
