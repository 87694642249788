import { Component, Injector } from '@angular/core';
import { CommonComponent } from '@wakanda/wakanda-core';

@Component({
  selector: 'wakanda-avo-business-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss'],
})
export class DownloadAppComponent extends CommonComponent {
  constructor(injector: Injector) {
    super(injector);
  }

  openAppStore(): void {
    const url = 'https://apps.apple.com/us/app/avo-business-by-nedbank/id1492452267';
    window.open(url, '_blank');
  }

  openGooglePlay(): void {
    const url = 'https://play.google.com/store/apps/details?id=za.co.nedbank.avobusiness&hl=en_IN&gl=US';
    window.open(url, '_blank');
  }
}
