import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IB2bBenefitPanelItem } from '@wakanda/avo-business-model';

@Component({
  selector: 'wakanda-avo-business-benefit-panel',
  templateUrl: './benefit-panel.component.html',
  styleUrls: ['./benefit-panel.component.scss'],
})
export class BenefitPanelComponent implements AfterViewInit {
  @ViewChild('offset') offsetElement: ElementRef;
  @Input() title: string;
  @Input() benefit: IB2bBenefitPanelItem;
  @Input() backgroundColor = '#EDF9F3';
  @Input() last = false;
  @Output() setTopOffset = new EventEmitter();
  isOpen = true;

  handlePanel(): void {
    this.isOpen = !this.isOpen;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setOffset();
    }, 0);
  }

  private setOffset(): void {
    this.offsetElement = this.offsetElement?.nativeElement?.getBoundingClientRect()?.top;
    if ((this.offsetElement as unknown as number) < 0 || typeof this.offsetElement !== 'number') return;
    this.setTopOffset.emit((this.offsetElement as unknown as number) - 150);
  }
}
