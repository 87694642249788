import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  showBackButton = true;
  showCloseButton = false;

  enableBackButton(enable = true): void {
    this.showBackButton = enable;
  }

  enableCloseButton(enable = true): void {
    this.showCloseButton = enable;
  }
}
