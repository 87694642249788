import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceOsService {
  deviceOS: string;
  playStore = 'http://play.google.com/store';
  appStore = 'http://www.apple.com/ios/app-store';

  getOS = (): string => {
    const userAgent = this.getUserAgent();
    let device = 'unknown';

    if (/android/i.test(userAgent)) {
      device = 'Android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      device = 'iOS';
    }

    this.deviceOS = device;
    return device;
  };

  getStoreUrl = (): string => {
    const os = this.getOS();
    if (os !== 'unknown') {
      if (os === 'iOS') {
        return this.appStore;
      } else if (os === 'Android') {
        return this.playStore;
      }
    }
    return null;
  };

  getUserAgent = (): string => {
    return navigator.userAgent || navigator.vendor || (window as any).opera;
  };

  isFacebookBrowser(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
    return userAgent.indexOf('FBAN') > -1 || userAgent.indexOf('FBAV') > -1;
  }
}
