import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICommonOnChangeOutput } from '@wakanda/wakanda-core';

@Component({
  selector: 'ui-toggle',
  template: `
    <div class="ui-toggle-wrapper" [ngClass]="{ 'with-title': !!title, 'compact': !!compact }" (click)="handleChange()">
      <p *ngIf="!!title">{{ title }}</p>
      <div class="ui-toggle" [ngClass]="{ active: !!checked, disabled: !!disabled }">
        <div class="toggle-control"></div>
      </div>
    </div>
  `,
})
export class ToggleComponent {
  @Input() disabled = false;
  @Input() checked: boolean;
  @Input() name: string;
  @Input() title: string;
  @Input() compact: boolean;
  @Output() onChange = new EventEmitter<ICommonOnChangeOutput>();

  handleChange = (): void => {
    if (this.disabled) return;
    this.onChange.emit({ name: this.name, value: !this.checked });
  };
}
