import { IPosition } from '../../models/location.model';

const stores = (baseUrl: string): string => `${baseUrl}/stores/v1`;

// merchant (buyer)
export const buyerLocations = (baseUrl: string, buyerId: string): string =>
  `${stores(baseUrl)}/buyers/${buyerId}/locations`;

// customer
export const fetchLocations = (baseUrl: string): string => `${baseUrl}/profile/v3/customer/location`;
export const deleteLocation = (id: string, baseUrl: string): string => `${baseUrl}/profile/v3/customer/location/${id}`;
export const editLocation = (id: string, baseUrl: string): string => `${baseUrl}/profile/v3/customer/location/${id}`;
export const setCurrentLocation = (baseUrl: string): string => `${baseUrl}/profile/v3/customer/currentLocation`;
export const fetchCurrentLocation = (baseUrl: string): string => `${baseUrl}/profile/v3/customer/currentLocation`;
export const fetchNearestLocation = (baseUrl: string, location: IPosition): string =>
  `${baseUrl}/profile/v3/customer/currentLocation/nearest?latitude=${location ? location.latitude : null}&longitude=${
    location ? location.longitude : null
  }`;
