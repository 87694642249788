export interface AuthEntity {
  id: string | number; // Primary ID
  name: string;
}

export interface IAuthRefreshTokenStartRequest {
  refreshToken: string;
}

export interface IAuthNextStep {
  sessionId: string;
  nextStep: AuthNextStepType;
  nextStepData?: IAuthNextStepOtpData | IAuthNextStepProfileData | IAuthNextStepLinkAccountData;
  token: IAuthAccessToken;
}

export enum AuthNextStepType {
  OTP = 'OTP',
  NEW_PROFILE = 'NEW_PROFILE',
  PROFILE_DATA = 'PROFILE_DATA',
  INTERESTS = 'INTERESTS',
  TERMS_CONDITIONS = 'TERMS_CONDITIONS',
  FINISHED = 'FINISHED',
  REDIRECT = 'REDIRECT',
  LINK_ACCOUNTS = 'LINK_ACCOUNTS',
}

export interface IAuthNextStepOtpData {
  otpSentTo: string;
  allowSwitchToEmail: boolean;
  allowSwitchToPhone: boolean;
}

export interface IAuthNextStepProfileData {
  requestNickname: boolean;
  requestPhone: boolean;
  requestEmail: boolean;
  requirePhone: boolean;
  requireEmail: boolean;
  prefillNickname: string;
  prefillEmail: string;
  prefillPhone: string;
}

export interface IAuthNextStepLinkAccountData {
  conflictingContactType: AuthConflictContactType;
  conflictingContact: string;
  linkingMethod: AuthLinkingMethod;
  lastUsed: string;
}

export interface IAuthAccessToken {
  access_token: string;
  expiresIn: number;
  expiresAt: string;
  refresh_token?: string;
  scope?: string;
  token_type: string;
  refreshToken?: string; // backward compatibility
}

export interface IAuthAvoClientInfo {
  id: string;
  accessToken?: string;
  expiresIn?: number;
  linkedToBank: boolean;
  loginCreated: boolean;
  walletCreated: boolean;
  walletSeen: boolean;
  displayName: string;
  name?: string;
  surname?: string;
  phone?: string;
  email?: string;
  photo?: string;
  newlyCreated?: boolean;
  autoReg?: boolean;
  nationality?: string;
  saId?: string;
  addressLine1?: string;
  country?: string;
}

export interface IAuthBankClientInfo {
  walletAvailable: boolean;
  upgradeWallet: boolean;
}

export enum AuthConflictContactType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export enum AuthLinkingMethod {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  APPLE = 'APPLE',
  APPLECODE = 'APPLECODE',
  NEDBANK_ID = 'NEDBANK_ID',
}

export interface IAuthDevice {
  id: string;
  name: string;
  lastActive: string;
  current: boolean;
  trusted: boolean;
}
