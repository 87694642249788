import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-hamburger',
  template: `
    <div class="ui-hamburger" [ngClass]="{ open: isOpen, dark: isDark }" (click)="onClick.emit(!isOpen)">
      <div></div>
    </div>
  `,
})
export class HamburgerComponent {
  @Input() isOpen: boolean;
  @Input() isDark = false;
  @Output() onClick = new EventEmitter<boolean>();
}
