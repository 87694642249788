export interface ILocationDTO {
  id: string;
  name: string;
  position: IPositionDTO;
}

export interface IPositionDTO {
  latitude: number;
  longitude: number;
  name?: string;
}

export interface IAvoInfoDTO {
  id: string;
  linkedToBank: boolean;
  loginCreated: boolean;
  walletCreated: boolean;
  displayName: string;
  name?: string;
  surname?: string;
  phone?: string;
  email?: string;
  photo?: string;
  nationality?: string;
  saId?: string;
  addressLine1?: string;
  country?: string;
}

export enum DashboardWidgetType {
  WELLNESS_CAROUSEL = 'WELLNESS_CAROUSEL',
  STATIC_QUICK_ACTIONS = 'STATIC_QUICK_ACTIONS',
  QUICK_ACTIONS = 'QUICK_ACTIONS',
  DISCOVER_AVO = 'DISCOVER_AVO',
  STORES_NEAR_ME = 'STORES_NEAR_ME',
  CONTENT_CARD_CAROUSEL = 'CONTENT_CARD_CAROUSEL',
  SHOP_BY_CATEGORY = 'SHOP_BY_CATEGORY',
  SHOP_BY_BRAND = 'SHOP_BY_BRAND',
  SHOP_BY_STORE = 'SHOP_BY_STORE',
  PRODUCT_LIST = 'PRODUCT_LIST',
  PAST_BUYS = 'PAST_BUYS',
  PAYMENT_CAROUSEL = 'PAYMENT_CAROUSEL',
  REWARD_CARD = 'REWARD_CARD',
  REWARD_PARTNER = 'REWARD_PARTNER',
  WELCOME_HEADER = 'WELCOME_HEADER',
  REDEEM_GIFT_VOUCHER = 'REDEEM_GIFT_VOUCHER',
  PREPAIDS_ICONS = 'PREPAIDS_ICONS',
  VOUCHERS_LIST = 'VOUCHERS_LIST',
  HEADERS = 'HEADERS',
  CONTENT_BUBBLE_CAROUSEL = 'CONTENT_BUBBLE_CAROUSEL',
}

export interface IEnvironment {
  baseUrl?: string;
  production: boolean;
  googleMapApiKey: string;
  adobe: string;
  showEnrollment?: boolean;
  clientId: string;
  scopes: string;
  responseType: string;
  translateApplicationName: string;
  groceriesUrl: string;
  cardTopupSuccessCallbackUrl: string;
  cardTopupFailCallbackUrl: string;
  cardTopupTryAgainCallbackUrl: string;
  cardTopupErrorCallbackUrl: string;
  addCardTopupSuccessCallbackUrl: string;
  addCardTopupFailCallbackUrl: string;
  addCardTopupTryAgainCallbackUrl: string;
  addCardTopupErrorCallbackUrl: string;
  cardPaymentSuccessCallbackUrl: string;
  cardPaymentFailCallbackUrl: string;
  cardPaymentTryAgainCallbackUrl: string;
  cardPaymentErrorCallbackUrl: string;
  apiaryBaseUrl: string;
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
  appType: string;
  nedbankMoneyAndroidStoreLink: string;
  nedbankMoneyIosStoreLink: string;
  nedbankMoneyHuawaiGalleryStoreLink: string;
  facebookClientId: string;
  appleClientId: string;
  googleClientId: string;
  datadog?: IDatadog;
}

export interface IDatadog {
  applicationId: string;
  clientToken: string;
  site: string;
  service: string;
}
