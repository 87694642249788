import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { AppConfigService } from '../../services/app-config/app-config.service';
import { ApplicationFacade } from '../application/application.facade';
import { failed, HttpErrorAction, succeed } from '../application/task.actions';
import * as actions from './app-config.actions';
import { EVENT } from './app-config.events';

@Injectable({
  providedIn: 'root',
})
export class AppConfigEffects {
  constructor(private actions$: Actions, private appFacade: ApplicationFacade, private service: AppConfigService) {}

  fetchAppConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.AppConfigRequestAction>(EVENT.APP_CONFIG_REQUEST),
      switchMap(({ taskId }) =>
        this.service.fetchAppConfig().pipe(
          map(response => succeed(new actions.AppConfigReceivedAction(taskId, response))),
          catchError(error => of(failed(new HttpErrorAction(taskId, error)))),
          finalize(() => this.appFacade.finalize(taskId)),
        ),
      ),
    ),
  );
}
