import { Injectable } from '@angular/core';

// todo to be deleted together with avo2
export interface IAuthenticators {
  xAuthTXId?: string;
  xAuthTXToken?: string;
}

@Injectable()
export class AuthenticationService {
  private authenticators: IAuthenticators;

  setAuthenticators = (authenticators: IAuthenticators): void => {
    this.authenticators = authenticators;
  };

  getAuthenticators = (): IAuthenticators => {
    return this.authenticators;
  };

  clearAuthenticators = (): void => {
    this.authenticators = null;
  };
}
