/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  AppConfig,
  INewToProductOffer,
  IPersonalLoansStart,
  IPersonalLoansValidateResponse,
  IRateUsRequest,
  IShoppingBuyerCartDetail,
  IShoppingCart,
  IWallet,
  IWalletPaymentLinkResponse,
  IWalletTopUpPayRequest,
  IWalletTransactionDetail,
} from '@wakanda/wakanda-core';
import { IAdobeActivePage, IAdobeData, IAdobeEntryPoint } from '@avo/shared/adobe-analytics';
import { IGroceriesCartResponse, ITakeawayCart } from '@wakanda/wakanda-customer-model';
import { IZenAuthUserInfo, SocialNetworkType, ZenAuthFacade } from '@wakanda/zenauth';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { EVENT, IEventOptions, SUBSECTION, TARGET_FUNCTION } from '../events/adobe.event';
import { BANK_STATE, IAdobeProductData, IAdobeProductDataLayer, PROPERTY } from '../properties/adobe.property';
import { parseApiResponse } from '../utilities/adobe.utility';
import { IStoreWallet } from '@wakanda/wakanda-merchant-model';
import { ApplicationFacade } from '@avo/shared/core';
import { ADOBE_CONFIG } from '../tokens';
import { AdobeConfig } from '../configs';

interface IMoneyAppOnboardingQuery {
  avocode?: string;
  cid?: string;
  pfrm?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AdobeService {
  private previousPageSection: string;
  private onboardingSaid: string;
  private sourceOfIncome: any;
  private personalLoanFindingMethod: string;
  private personalLoanNtpData: any;
  private paymentMethod: string;
  private onboardingSocialMedia: SocialNetworkType;
  private userWallet: IWallet | IStoreWallet;
  private moneyAppOnboardingQuery: IMoneyAppOnboardingQuery;
  private loginExternalQuery: any;
  private subscriptions = new Subscription();
  private routerParams: Params;
  private authUserInfo: IZenAuthUserInfo;
  private isAuthorized: boolean;
  public EVENT = EVENT;
  public SUBSECTION = SUBSECTION;
  public siteSections = {
    APPLICATION_START: 'ApplicationStart',
    ONBOARDING: 'Onboarding',
    POST_ONBOARDING: 'PostOnboarding',
    ENROLLMENT: 'Enrollment',
    HOMEPAGE: 'Homepage',
    SEARCH: 'Search',
    CATEGORIES: 'Categories',
    ORDER_SERVICE: 'OrderService',
    JOB_CARD: 'JobCard',
    RECOVERY: 'Recovery',
    OFFER_SERVICE: 'Offer a service',
    MY_ACCOUNT: 'MyAccount',
    USER_PROFILE: 'UserProfile',
    USER_SETTINGS_AND_OTHERS: 'UserSettingsAndOthers',
    EDIT_CUSTOMER_PROFILE: 'EditCustomerProfile',
    BANK_SERVICES: 'BankServices',
    HOME_BUYING_TOOLKIT: 'HomeBuyingToolkit',
    PERSONAL_LOAN: 'PersonalLoan',
    WALLET: 'Wallet',
    CUSTOMER_PROFILE: 'Customer Profile',
    PROFILE_SETTINGS: 'Customer Settings',
    PRODUCT_CATALOGUE: 'Product Catalogue',
    SHOPPING_CART: 'Cart',
    UPGRADE_WALLET: 'Upgrade wallet',
    NOTIFICATIONS: 'Notifications',
    ACTIVITY_HISTORY: 'Activity history',
    PREPAIDS: 'Prepaids',
    PREPAID_AIRTIME_DATA: 'PrepaidAirtimeData',
    PREPAID_ELECTRICITY: 'PrepaidElectricity',
    BUY_VOUCHER: 'BuyVoucher',
    RESTAURANT: 'Takeaways',
    BUY_FOOD: 'Groceries',
    AVO_SELLER: 'AvoSeller',
    AVO_BUYER: 'AvoBuyer',
    HEALTHCARE: 'Healthcare',
    FUNERAL: 'FuneralCover',
    EDUCATION: 'Education',
    CHAT: 'Chat',
    HEYNED: 'HeyNed',
    REWARDS: 'Rewards',
    AVO_BUSINESS: 'AvoBusiness',
    GROCERIES: 'Groceries',
    ESHOP: 'EShop',
  };

  private readonly window = window;

  constructor(
    @Optional() @Inject(ADOBE_CONFIG) private config: AdobeConfig,
    private appConfig: AppConfig,
    private authFacade: ZenAuthFacade,
    private applicationFacade: ApplicationFacade,
    private activeRouter: ActivatedRoute,
  ) {
    if (!this.config) {
      this.config = {
        app: 'customer',
      };
    }

    this.subscriptions.add(
      this.activeRouter.queryParams.subscribe(params => {
        if (params) this.routerParams = params;
      }),
    );

    if (this.config.app !== 'avo-business') {
      this.subscriptions.add(this.authFacade.getUserInfo$.subscribe(data => (this.authUserInfo = data)));
      this.subscriptions.add(this.authFacade.isAuthorized$.subscribe(isAuth => (this.isAuthorized = isAuth)));
    }
  }

  get adobeData(): IAdobeData {
    return this.window['adobeData'];
  }

  set adobeData(data: IAdobeData) {
    this.window['adobeData'] = data;
  }

  get activePage(): IAdobeActivePage {
    return this.adobeData?.activePage;
  }

  set activePage(activePage: IAdobeActivePage) {
    this.adobeData = { ...this.adobeData, activePage };
  }

  get entryPoint(): IAdobeEntryPoint {
    return this.adobeData?.entryPoint;
  }

  set entryPoint(entryPoint: IAdobeEntryPoint) {
    this.adobeData = { ...this.adobeData, entryPoint };
  }

  setOnboardingSourceOfIncome = (sourceOfIncome: string): void => {
    this.sourceOfIncome = sourceOfIncome || 'No';
  };
  setMoneyAppOnboardingQuery = (queryObject: IMoneyAppOnboardingQuery): void => {
    this.moneyAppOnboardingQuery = queryObject;
  };
  getMoneyAppOnboardingQuery = (): IMoneyAppOnboardingQuery => this.moneyAppOnboardingQuery;
  setLoginExternalQuery = (queryObject: any): void => {
    this.loginExternalQuery = queryObject;
  };
  setPersonalLoanNtpData = (ntpData: any): void => {
    this.personalLoanNtpData = ntpData;
  };
  setPaymentMethod = (paymentMethod: string): void => {
    this.paymentMethod = paymentMethod;
  };

  getOnboardingAdditionalInfo = (): any => {
    const said = this.onboardingSaid;
    const age = said ? Math.abs(dayjs(said.substr(0, 6), 'YYMMDD').diff(dayjs(), 'years')) : undefined;
    const gender = this.getGenderFromSaId(said);
    const employed = this.sourceOfIncome || 'No';
    return { age, gender, employed };
  };

  setPersonalLoanFindingMethod = (previousPageSection: string): void => {
    let method = previousPageSection || undefined;
    if (previousPageSection) {
      if (previousPageSection === this.siteSections.WALLET) method = 'Wallet';
      if (previousPageSection === this.siteSections.SHOPPING_CART) method = 'Cart';
      if (previousPageSection === this.siteSections.HOMEPAGE) method = 'Dashboard';
      if (previousPageSection === this.siteSections.BANK_SERVICES) method = 'Categories';
    }
    this.personalLoanFindingMethod = method;
  };
  setOnboardingSocialMedia = (type: SocialNetworkType): void => {
    this.onboardingSocialMedia = type;
  };

  logout = (): void => {
    this.onboardingSaid = undefined;
    this.sourceOfIncome = undefined;
    this.personalLoanFindingMethod = undefined;
    this.onboardingSocialMedia = undefined;
    this.userWallet = undefined;
    this.moneyAppOnboardingQuery = undefined;
  };

  // ------------ Helper methods --------------
  private getActiveAdobePageName = (): string =>
    this.activePage && this.activePage.pageInfo && this.activePage.pageInfo.pageName
      ? this.activePage.pageInfo.pageName
      : '';
  private getActiveAdobePageSection = (): string =>
    this.activePage && this.activePage.pageInfo && this.activePage.pageInfo.siteSection
      ? this.activePage.pageInfo.siteSection
      : '';

  private satelliteTrack = (trackEvent: string, digitalData?: any): void => {
    if (localStorage?.getItem('adobeDebug') === 'true') console.log('---ADOBE--- v2', trackEvent, digitalData);
    if (
      Object.prototype.hasOwnProperty.call(this.window, PROPERTY.SATELLITE) &&
      typeof this.window[PROPERTY.SATELLITE].track === 'function'
    ) {
      if (digitalData) {
        this.window[PROPERTY.DIGITAL_DATA] = {
          link: {},
          component: {},
          ...digitalData,
        };
      }
      this.window[PROPERTY.SATELLITE].track(trackEvent);
    }
  };

  private adobeTarget =
    (targetFunction: string): any =>
    (functionArguments): any => {
      if (
        Object.prototype.hasOwnProperty.call(this.window, PROPERTY.ADOBE) &&
        this.window[PROPERTY.ADOBE].target &&
        typeof this.window[PROPERTY.ADOBE].target[targetFunction] === 'function'
      ) {
        this.window[PROPERTY.ADOBE].target[targetFunction](functionArguments);
      }
    };

  private getBankState(): string {
    if (!this.authUserInfo || !this.authUserInfo.avoClientInfo) return BANK_STATE.UNKNOWN;
    else if (!this.authUserInfo.avoClientInfo.linkedToBank) return BANK_STATE.NONE;
    else {
      if (!this.authUserInfo.avoClientInfo.walletCreated) return BANK_STATE.LINKED;
      else if (!this.authUserInfo.bankClientInfo) return BANK_STATE.LINKED_UNKNOWN;
      else if (this.authUserInfo.avoClientInfo.walletCreated && !this.authUserInfo.bankClientInfo.walletAvailable)
        return BANK_STATE.WALLET_INPROGRESS;
      else if (
        this.authUserInfo.avoClientInfo.walletCreated &&
        this.authUserInfo.bankClientInfo.walletAvailable &&
        this.authUserInfo.bankClientInfo.upgradeWallet
      )
        return BANK_STATE.LIMITED_WALLET;
      else if (
        this.authUserInfo.avoClientInfo.walletCreated &&
        this.authUserInfo.bankClientInfo.walletAvailable &&
        !this.authUserInfo.bankClientInfo.upgradeWallet
      )
        return BANK_STATE.FULL_WALLET;
      return BANK_STATE.UNKNOWN;
    }
  }

  // ------------ Common | satellite methods --------------
  setEntryPoint = (entryPoint?: IAdobeEntryPoint) => {
    this.entryPoint = entryPoint;
  };

  executeAdobeLoadEvent = ({ viewName, siteSection, subSiteSection, additionalObject }: IEventOptions): void => {
    const previousPage = this.getActiveAdobePageName();
    const previousPageSection = this.getActiveAdobePageSection();

    let resolvedEntryPoint: IAdobeEntryPoint =
      siteSection === previousPageSection
        ? {
            action: this.activePage?.pageInfo?.entryPointAction,
            value: this.activePage?.pageInfo?.entryPointValue,
          }
        : undefined;
    // Utilize previously set entry point, and clear it from memory
    if (this.entryPoint?.action) {
      resolvedEntryPoint = { ...this.entryPoint }; // spread so that object is cloned
      this.setEntryPoint();
    }

    if (viewName === previousPage && siteSection !== this.siteSections.HOMEPAGE) return;

    this.adobeTarget(TARGET_FUNCTION.TRIGGER_VIEW)(viewName);
    const object = {
      page: {
        pageInfo: {
          pageName: viewName,
          previousPage,
          siteSection,
          subSiteSection,
          entrySubChannel: resolvedEntryPoint?.subChannel,
          entryPointAction: resolvedEntryPoint?.action,
          entryPointValue: resolvedEntryPoint?.value,
          pageUrl: this.window.location.href,
        },
        user: {
          wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
          loginState: this.isAuthorized ? 'authenticated' : 'non-authenticated',
          bankState: this.getBankState(),
          autoReg: this.authUserInfo?.avoClientInfo?.autoReg ? '1' : '0',
          newlyCreated: this.authUserInfo?.avoClientInfo?.newlyCreated ? '1' : '0',
          sessionId: this.appConfig.backendConfig.sessionId || undefined,
          userSocialMedia: this.onboardingSocialMedia,
        },
        techDetail: {
          countryCode: this.activePage?.techDetail?.countryCode || '',
          userPlatform: 'PWA',
          serviceVersion: '3.36',
          avoComponent: 'v2',
        },
      },
      cmpid: this.routerParams?.['cmpid'] ? this.routerParams?.['cmpid'] : undefined,
      ...additionalObject,
    };
    if (object.cmpid) this.applicationFacade.setCmpidValue(object.cmpid);
    this.previousPageSection = previousPageSection;
    this.activePage = object.page;
    this.satelliteTrack(EVENT.COMMON_PAGE_LOAD, object);
    if (siteSection === this.siteSections.CUSTOMER_PROFILE) this.satelliteTrack(EVENT.PROFILE_PAGE);
    if (siteSection === this.siteSections.PROFILE_SETTINGS) this.satelliteTrack(EVENT.PROFILE_SETTINGS);
    if (siteSection === this.siteSections.ORDER_SERVICE) {
      const orderServiceDataLayer: any = {};
      if (additionalObject && additionalObject.serviceType) {
        orderServiceDataLayer.serviceType = additionalObject.serviceType;
      }
      if (additionalObject && additionalObject.jobId) orderServiceDataLayer.jobId = additionalObject.jobId;
      this.satelliteTrack(EVENT.ORDER_SERVICE, orderServiceDataLayer);
    }
  };

  executeAdobeClickEvent = (linkName: string, assetAction = 'click', options?: any): void => {
    if (!linkName) return;
    const object = {
      page: this.activePage,
      link: {
        linkName,
        assetAction,
        ...options,
      },
    };
    this.satelliteTrack(EVENT.LINK_CLICK, object);
  };

  executeAdobeErrorEvent = (errorType: string, request?: any, response?: any): void => {
    if (!errorType) return;
    const object = {
      page: this.activePage,
      link: {
        errorType,
        details: { request, response },
      },
    };
    this.satelliteTrack(EVENT.ERROR, object);
  };

  executeAdobeDownloadEvent = (filename: string): void => {
    if (!filename) return;
    const object = {
      page: this.activePage,
      popEvent: {
        eventName: 'download',
        filename,
      },
    };
    this.satelliteTrack(EVENT.FILE_DOWNLOAD, object);
  };

  executeAdobeFormEvent = (eventType: EVENT.FORM_INITIATED | EVENT.FORM_COMPLETE, formName: string): void => {
    const object = { formName, componentEvent: {} };
    this.satelliteTrack(eventType, object);
  };

  // ------------ Onboarding | satellite methods --------------
  onboardingSignUp = (stepUp?: boolean): void => {
    this.satelliteTrack(EVENT.ONBOARDING_SIGNUP);
    if (stepUp) this.satelliteTrack(EVENT.ONBOARDING_STEP_UP);
  };
  onboardingSignUpConfirmation = (): void => {
    const object = { page: this.activePage };
    this.satelliteTrack(EVENT.ONBOARDING_SIGNUP_CONFIRMATION, object);
  };
  onboardingEndStep = (endReason: string): void => {
    const object = {
      page: this.activePage,
      endReason,
    };
    this.satelliteTrack(EVENT.ONBOARDING_END_STEP, object);
  };
  onboardingOtp = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_OTP, object);
  };
  onboardingSetLogin = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_SET_LOGIN, object);
  };
  onboardingFirstNameLastName = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_FIRST_NAME, object);
  };
  onboardingWalletSuccess = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_WALLET_SUCCESS, object);
  };
  onboardingWalletInProgress = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_WALLET_IN_PROGRESS, object);
  };
  onboardingPersonalData = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_PERSONAL_DATA, object);
  };
  onboardingResidentialAddress = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_RESIDENTIAL_ADDRESS, object);
  };
  onboardingSocialStart = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_SOCIAL_START, object);
  };
  onboardingBasic = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_BASIC, object);
  };
  onboardingOutstandingTcs = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_OUTSTANDING_TCS, object);
  };
  onboardingIdNumber = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.ONBOARDING_ID_NUMBER, object);
  };
  postOnboardingBusinessDetails = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.POST_ONBOARDING_BUSINESS_DETAILS, object);
  };
  postOnboardingBusinessAddress = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.POST_ONBOARDING_BUSINESS_ADDRESS, object);
  };
  postOnboardingTradingName = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.POST_ONBOARDING_TRADING_NAME, object);
  };
  postOnboardingUpdateCategory = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.POST_ONBOARDING_UPDATE_CATEGORY, object);
  };

  // ------------ Login --------------
  loginSocial = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.LOGIN_SOCIAL, object);
  };
  loginUsername = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.LOGIN_USERNAME, object);
  };
  loginPassword = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.LOGIN_PASSWORD, object);
  };
  loginOtp = (apiResponse: any): void => {
    const object = { apiResponse: parseApiResponse(apiResponse) };
    this.satelliteTrack(EVENT.LOGIN_OTP, object);
  };

  // ------------ Wallet | satellite methods --------------
  executeAdobeWalletPageEvent = (wallet: IStoreWallet): void => {
    if (!wallet || !wallet.balance) return;
    this.userWallet = wallet;
    const object = {
      user: {
        wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
        walletStatus: 'AVAILABLE',
        nedBankUser: 'Yes',
      },
      statementDetails: {
        mobiwalletbalance: this.userWallet.balance,
      },
    };
    this.satelliteTrack(EVENT.WALLET_PAGE, object);
  };
  walletShareStatement = (): void => {
    const object = {
      page: this.activePage,
      popEvent: {
        eventName: 'Share',
      },
    };
    this.satelliteTrack(EVENT.WALLET_SHARE_SUCCESS, object);
  };
  walletRefundSuccess = (transaction: IWalletTransactionDetail): void => {
    const object = {
      user: {
        wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
        walletStatus: 'AVAILABLE',
        nedBankUser: 'Yes',
      },
      transaction: {
        refundAmount: `${transaction.amount.currencyCode} ${transaction.amount.amount}`,
        refundPaymentMode: transaction.paymentMode,
      },
    };
    this.satelliteTrack(EVENT.WALLET_REFUND_SUCCESS, object);
  };
  walletRequestMoneySuccess = (transaction: any, apiResponse: any): void => {
    const object = {
      user: {
        wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
        walletStatus: 'AVAILABLE',
        nedBankUser: 'Yes',
      },
      payeeUser: {},
      transaction: transaction
        ? {
            requestAmount: `${transaction.amount.currencyCode} ${transaction.amount.amount}`,
            paymentMode: transaction.paymentMode,
          }
        : '',
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.WALLET_REQUEST_MONEY_SUCCESS, object);
  };
  walletReceiveMoneySuccess = (transaction: IWalletPaymentLinkResponse, apiResponse: any): void => {
    const object = {
      user: {
        wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
        walletStatus: 'AVAILABLE',
        nedBankUser: 'Yes',
      },
      transaction: transaction
        ? {
            requestAmount: `${transaction.amount.currencyCode} ${transaction.amount.amount}`,
            paymentMode: transaction.type,
          }
        : '',
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.WALLET_RECEIVE_MONEY_SUCCESS, object);
  };

  walletPayMoneyStart = (apiResponse: any): void => {
    const object = {
      payeeUser: {
        wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
        walletStatus: 'AVAILABLE',
        nedBankUser: 'Yes',
      },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.WALLET_PAY_START, object);
  };
  walletPayMoneyAuthenticate = (apiResponse: any): void => {
    const object = {
      user: {
        wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
        walletStatus: 'AVAILABLE',
        nedBankUser: 'Yes',
      },
      payeeUser: {},
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.WALLET_PAY_AUTHENTICATE, object);
  };
  walletPayMoneySuccess = (transaction: any, apiResponse: any): void => {
    const object = {
      user: {
        wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
        walletStatus: 'AVAILABLE',
        nedBankUser: 'Yes',
      },
      payeeUser: {},
      transaction: transaction
        ? {
            paidAmount: transaction.amount ? `${transaction.amount.currencyCode} ${transaction.amount.amount}` : '-',
            paymentMode: transaction.paymentMode,
          }
        : '',
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.WALLET_PAY_SUCCESS, object);
  };
  walletAddMoneyStart = (): void => {
    const object = {
      user: {
        wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
        walletStatus: 'AVAILABLE',
        nedBankUser: 'Yes',
      },
    };
    this.satelliteTrack(EVENT.WALLET_ADD_MONEY_START, object);
  };
  walletAddMoneySuccess = (payTopUp: IWalletTopUpPayRequest, apiResponse: any): void => {
    const object = {
      user: {
        wakandaId: this.authUserInfo ? this.authUserInfo.avoClientInfo?.id : undefined,
        walletStatus: 'AVAILABLE',
        nedBankUser: 'Yes',
      },
      transaction: {
        addAmount: payTopUp?.amount ? `${payTopUp.amount.currencyCode} ${payTopUp.amount.amount}` : '-',
        addmoneyMode: 'credit card (manual)',
      },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.WALLET_ADD_MONEY_SUCCESS, object);
  };
  walletRequestMoneyStart = (): void => this.satelliteTrack(EVENT.WALLET_REQUEST_MONEY_START);

  // ------------ Shopping | satellite methods and custom tagging --------------
  private targetGetOffer = (mbox: string, params: any, callback?: any, onSuccess?: any) => {
    this.adobeTarget(TARGET_FUNCTION.GET_OFFER)({
      mbox,
      params,
      success: (offer): void => {
        if (callback) callback(offer);
        this.adobeTarget(TARGET_FUNCTION.APPLY_OFFER)({ mbox, offer });
        if (onSuccess) setTimeout(onSuccess, 200);
      },
      error: () => null,
    });
  };

  initMerchantRecommendedProductsWidget = (callback?: (offer: any) => void): void => {
    this.targetGetOffer('target-custom-mbox-b2b01', {}, callback);
  };

  initMerchantMostPopularWidget = (callback?: (offer: any) => void): void => {
    this.targetGetOffer('target-custom-mbox-b2b02', {}, callback);
  };

  getCartItemsDataLayer = cart => {
    const transformStoreToItems = store =>
      store?.items?.map(product => {
        const amount =
          product.pricePerUnit?.withTax?.amount ||
          product.pricing?.price?.amount ||
          product.price?.amount ||
          product.price ||
          product.amount?.amount ||
          product.amount;
        return {
          productInfo: {
            productId: product.sku,
            productSku: product.adobeSku || product.sku,
            productName: product.name || product.title,
            storeName: store.storeName || product.sellerName || product.seller?.sellerRealName || store.label,
            brand: product.storeName || product.sellerName || product.seller?.name || store.storeName,
            amount,
            totalAmount: amount * (product.quantity || 1),
            quantity: product.quantity,
            vertical: product.vertical || cart.vertical,
          },
        };
      });
    const fulfillments = cart?.fulfillments || cart?.fulfilments; // TODO: should use one variant across hundreds of files...
    return fulfillments?.flatMap(fulfillment => {
      if (fulfillment?.malls) return fulfillment?.malls?.flatMap(mall => mall?.stores?.flatMap(transformStoreToItems));
      if (fulfillment?.stores) return fulfillment?.stores?.flatMap(transformStoreToItems);
      return transformStoreToItems(fulfillment);
    });
  };

  getProductDetailsDataLayer(product: IAdobeProductData): IAdobeProductDataLayer[] {
    return [
      {
        productInfo: {
          ...product,
        },
      },
    ];
  }

  shoppingCartAdd = (productData: IAdobeProductData, apiResponse: any): void => {
    const object = {
      page: this.activePage,
      cart: {
        item: this.getProductDetailsDataLayer(productData),
      },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.SHOPPING_CART_ADD, object);
  };

  shoppingCartRemove = (productData: IAdobeProductData, apiResponse: any): void => {
    const object = {
      page: this.activePage,
      cart: {
        item: this.getProductDetailsDataLayer(productData),
      },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.SHOPPING_CART_REMOVE, object);
  };

  cartV3CheckoutClick = (cart: IShoppingBuyerCartDetail, apiResponse: any): void => {
    const object = {
      promocode: cart.promoCodes ? cart.promoCodes.map(promo => promo.promoCode).join(',') : undefined,
      cart: { item: this.getCartItemsDataLayer(cart) },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.CART_CHECKOUT_CLICK, object);
  };

  cartPayment = (
    cart: IShoppingCart | IGroceriesCartResponse | ITakeawayCart,
    paymentMethod: string,
    apiResponse: any,
  ): void => {
    this.setPaymentMethod(paymentMethod);
    const object = {
      promocode: cart?.promos ? cart?.promos.map(promo => promo.code).join(',') : undefined,
      cart: {
        paymentMethod,
        item: this.getCartItemsDataLayer(cart),
      },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.CART_PAYMENT, object);
  };

  // ------------ Banking - personal loan | custom tagging --------------

  bankingPersonalLoanStart = (loanStart: IPersonalLoansStart): void => {
    this.setPersonalLoanFindingMethod(this.previousPageSection);
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        loanSuggestedAmount: loanStart && loanStart.limitMax ? loanStart.limitMax.amount : undefined,
        isStaffMember: loanStart ? loanStart.staffMember : undefined,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_START, object);
  };

  bankingPersonalLoanFinancialInfo = (loanStart: IPersonalLoansStart): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        loanSuggestedAmount: loanStart && loanStart.limitMax ? loanStart.limitMax.amount : undefined,
        isStaffMember: loanStart ? loanStart.staffMember : undefined,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_FINANCIAL_INFO, object);
  };

  bankingPersonalLoanOffer = (loanStart: IPersonalLoansStart, incomeType: string, formDataFilled: number): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        loanSuggestedAmount: loanStart && loanStart.limitMax ? loanStart.limitMax.amount : undefined,
        isStaffMember: loanStart ? loanStart.staffMember : undefined,
        incomeType,
        formDataFilled,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_OFFER, object);
  };

  bankingPersonalLoanDetail = (loanStart: IPersonalLoansStart, incomeType: string, formDataFilled: number): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        loanSuggestedAmount: loanStart && loanStart.limitMax ? loanStart.limitMax.amount : undefined,
        isStaffMember: loanStart ? loanStart.staffMember : undefined,
        incomeType,
        formDataFilled,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_DETAIL, object);
  };

  bankingPersonalLoanDeclaration = (
    loanStart: IPersonalLoansStart,
    loanValidate: IPersonalLoansValidateResponse,
  ): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        loanSuggestedAmount: loanStart && loanStart.limitMax ? loanStart.limitMax.amount : undefined,
        loanApprovedAmount: 'not-available-need-to-clarify',
        isStaffMember: loanStart ? loanStart.staffMember : undefined,
        monthlyPayment: loanValidate.monthlyPayment ? loanValidate.monthlyPayment.amount : undefined,
        noOfInstallments: loanValidate.installments,
        interestRate: loanValidate.interestRate,
        incomeType: 'Fixed',
        formDataFilled: loanValidate.loanAmount ? loanValidate.loanAmount.amount : undefined,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_DECLARATION, object);
  };

  bankingPersonalLoanSuccess = (loanStart: IPersonalLoansStart, loanValidate: IPersonalLoansValidateResponse): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        loanSuggestedAmount: loanStart && loanStart.limitMax ? loanStart.limitMax.amount : undefined,
        loanApprovedAmount: 'not-available-need-to-clarify',
        isStaffMember: loanStart ? loanStart.staffMember : undefined,
        monthlyPayment: loanValidate.monthlyPayment ? loanValidate.monthlyPayment.amount : undefined,
        noOfInstallments: loanValidate.installments,
        interestRate: loanValidate.interestRate,
        incomeType: 'Fixed',
        formDataFilled: loanValidate.loanAmount ? loanValidate.loanAmount.amount : undefined,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_SUCCESS, object);
  };

  bankingPersonalLoanDecline = (offerId: string, loanEndReason: string): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        loanApplicationNumber: offerId,
        loanEndReason,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_DECLINE, object);
  };

  bankingNTPLoanStart = (): void => {
    this.setPersonalLoanFindingMethod(this.previousPageSection);
  };

  bankingNTPLoanOffer = (loanAmount: number, loanSuggestedAmount: number): void => {
    this.setPersonalLoanNtpData({ loanSuggestedAmount, loanAmount });
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_OFFER, object);
  };

  bankingNTPLoanTaxObligations = (): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_TAX_OBLIGATIONS, object);
  };

  bankingNTPLoanBankDetails = (userBank: string): void => {
    this.setPersonalLoanNtpData({ ...this.personalLoanNtpData, userBank });
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_BANK_DETAILS, object);
  };

  bankingNTPLoanConsent = (): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_CONSENT, object);
  };

  bankingNTPLoanFinancialInfo = (): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_FINANCIAL_INFO, object);
  };

  bankingNTPLoanDetails = (currentOffer: INewToProductOffer): void => {
    this.setPersonalLoanNtpData({ ...this.personalLoanNtpData, ...currentOffer });
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_DETAIL, object);
  };

  bankingNTPLoanDeclaration = (): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_DECLARATION, object);
  };

  bankingNTPLoanSuccess = (): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_SUCCESS, object);
  };

  bankingNTPLoanResultExisting = (): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
        loanEndReason: 'Looks like you’ve already applied',
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_RESULT_EXISTING, object);
  };

  bankingNTPLoanResultDecline = (loanEndReason: string): void => {
    const object = {
      loan: {
        findingMethod: this.personalLoanFindingMethod,
        ...this.personalLoanNtpData,
        loanEndReason,
      },
    };
    this.satelliteTrack(EVENT.BANKING_PERSONAL_LOAN_DECLINE, object);
  };

  // ------------ User Profile | custom tagging --------------
  profileEvent = (eventName: string): void => {
    const object = {
      event: { eventName },
    };
    this.satelliteTrack(EVENT.PROFILE_EVENT, object);
  };
  profileOtherEvent = (eventName: string): void => {
    const object = {
      event: { eventName },
    };
    this.satelliteTrack(EVENT.PROFILE_OTHER_EVENT, object);
  };

  // ------------ Rate Us --------------
  rateUs = (request: IRateUsRequest): void => {
    const object = {
      component: {
        componentName: 'rating popup success',
        componentEvent: 'rate us',
        componentStartSource: 'home',
      },
      starRating: request.stars,
      recommendation: request.recommendation,
      comment: request.description,
      journey: 'Profile',
    };
    this.satelliteTrack(EVENT.RATE_US, object);
  };

  // --------------------------
  // Merchant events
  // --------------------------
  requestForQuotation = (
    data: {
      additionalComments?: string;
      items?: { manualSku?: string; sku?: string; title: string; quantity: number | string }[];
      deliveryMethod?: string;
    },
    apiResponse,
  ): void => {
    const object = {
      sellerInformation: {
        additionalComments: data?.additionalComments,
      },
      item: data?.items?.map(item => ({
        productInfo: {
          productId: item.sku || item.manualSku,
          productName: item.title,
          productQuantity: Number(item.quantity),
        },
      })),
      deliveryMethod: {
        link: {
          linkName: data?.deliveryMethod,
        },
      },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.FORM_COMPLETE, object);
  };

  rejectQuote = (data: { selectReason?: string; remarks?: string }, apiResponse): void => {
    const object = {
      component: {
        componentName: 'Reason to reject',
        componentEvent: 'Select reject reason',
        componentStartSource: 'Home',
      },
      reasonToReject: { selectReason: data.selectReason, remarks: data.remarks },
      apiResponse: { apiResponse: parseApiResponse(apiResponse) },
    };
    this.satelliteTrack(EVENT.REJECT_QUOTE, object);
  };

  private getGenderFromSaId(said: string): string | undefined {
    if (!said) return undefined;

    const saIdGenderPart = said.substr(6, 4);

    return parseInt(saIdGenderPart, 10) >= 5000 ? 'M' : 'F';
  }
}
